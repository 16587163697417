import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, SimpleGrid } from '@chakra-ui/react';
import {
    DatePicker,
    CustomInput,
    CustomDropdown,
    CustomButton,
    CustomTextarea,
    MultiSelect,
    TypeAhead,
} from '../../../../../common/components';
import { Resolver, useForm, Controller } from 'react-hook-form';
import createPostRestSchema, { CreateRestFormValues } from '../validation/createPostRest.schema';
import dayjs from 'dayjs';
import { CreateJobPostPayload } from '../../../types';
import { ClockIcon } from '../../../../../assets/icons';
import { colors } from '../../../../../common/constants';
import {
    combineDateAndTime,
    carryAddressLoadOptions,
    getStateCodes,
    convertTimeIn24HoursFormat,
} from '../../../../../common/utils';
import { StateDirectoryItem } from '../../../../../common/types';

interface IRestFormProps {
    currentStates: string[];
    statesType: StateDirectoryItem[];
    searchByCities?: boolean;
    requestValue: string;
    onCreatePost: (value: CreateJobPostPayload) => void;
    createPostLoading: boolean;
    setRequestValue: (value: string) => void;
}

export const RestForm: React.FC<IRestFormProps> = ({
    currentStates,
    statesType,
    searchByCities = false,
    requestValue,
    onCreatePost,
    createPostLoading,
    setRequestValue,
}) => {
    const [finalPrice, setFinalPrice] = useState(0);
    const [feePrice, setFeePrice] = useState(0);
    const [filterStateCode, setFilterStateCode] = useState<string[]>([]);

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        clearErrors,
        control,
        watch,
    } = useForm<CreateRestFormValues>({
        resolver: createPostRestSchema as Resolver<CreateRestFormValues>,
    });

    const [date, price] = watch(['date', 'price']);

    useEffect(() => {
        if (price) {
            const totalPrice = (Number(price) + Number(price) * 0.015).toFixed(2);
            const feePrice = (Number(price) * 0.015).toFixed(2);
            setFinalPrice(Number(totalPrice));
            setFeePrice(Number(feePrice));
        } else {
            setFinalPrice(0);
            setFeePrice(0);
        }
    }, [price]);

    const onSubmit = (data: CreateRestFormValues) => {
        const fromTime = convertTimeIn24HoursFormat(data.fromTime);

        const payload = {
            clientTypeId: data.clientTypeId,
            price: Number(data.price),
            fromTime: fromTime,
            states: data.states,
            description: data.description,
            clientName: data.clientName,
            clientPhone: data.clientPhone,
            privateNote: data.privateNote,
            addresses: [{ name: data.address }],
            typeId: +requestValue,
            date: combineDateAndTime(
                data.date ? dayjs(+data.date).toISOString() : dayjs().toISOString(),
                fromTime
                    ? dayjs(new Date())
                          .set('hour', +fromTime.split(':')[0])
                          .set('minute', +fromTime.split(':')[1])
                          .set('seconds', 0)
                          .set('milliseconds', 0)
                          .toISOString()
                    : dayjs().toISOString(),
            ),
        };
        onCreatePost(payload);
    };

    // useEffect(() => {
    //     if (currentStates.length !== 0 && statesType.length !== 0) {
    //         setFilterStateCode(getStateCodes(statesType, currentStates));
    //     }
    // }, [currentStates, statesType]);

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid columns={2} gap="50px" rowGap="0px">
                    <CustomDropdown
                        options={[
                            { label: 'Showing', value: '1', id: 1 },
                            { label: 'Home Inspection', value: '2', id: 2 },
                            { label: 'Final Walk-Through', value: '3', id: 3 },
                            { label: 'Other', value: '4', id: 4 },
                            { label: 'Appraisal', value: '5', id: 5 },
                            { label: 'Referral', value: '7', id: 7 },
                        ]}
                        label="Request type:*"
                        register={{
                            value: requestValue,
                            onChange: (ev: any) => {
                                setRequestValue(ev.target.value);
                            },
                        }}
                        errors={''}
                        width="350px"
                    />
                    <Controller
                        render={({ field }) => (
                            <MultiSelect
                                label="State:*"
                                options={currentStates.map((state, i) => ({
                                    value: state,
                                    label: state,
                                }))}
                                value={field.value}
                                onChange={field.onChange}
                                errors={errors.states && (errors.states as any).message}
                                width="350px"
                                // defaultValue={[
                                //     { value: currentStates[0], label: currentStates[0] },
                                // ]}
                            />
                        )}
                        control={control}
                        name="states"
                    />
                    <DatePicker
                        label="Date:*"
                        displayValue={date ? dayjs.utc(+date).format('MM/DD/YY') : ''}
                        value={Number(date) || dayjs.utc().valueOf()}
                        setValue={value => {
                            setValue('date', value.toString());
                            clearErrors('date');
                        }}
                        removeBottomMargin
                        placeholder="MM/DD/YY"
                        errMessage={errors.date?.message}
                        borderRadius="50px"
                        formControlProps={{
                            margin: errors.date ? '0 10px 0 0' : '0 10px 26px 0',
                        }}
                    />
                    <CustomInput
                        register={{ ...register('fromTime') }}
                        label="Start Time*"
                        placeholder="From Time"
                        type="time-12-hours"
                        defaultValue="00:00 AM"
                        errors={errors.fromTime}
                        rightIcon={<ClockIcon color={colors.secondaryFontDarker} />}
                        margin={errors.fromTime ? '0' : '0 0 26px 0'}
                        width="350px"
                    />
                    <CustomInput
                        register={{ ...register('price') }}
                        type="number"
                        label="Offer Price:*"
                        errors={errors.price}
                        rightIcon={<Text color="secondaryFontDarker">$</Text>}
                        placeholder="100"
                        width="350px"
                        margin={errors.price ? '0' : '0 0 26px 0 !important'}
                    />
                </SimpleGrid>
                <Flex flexDirection="column">
                    <Controller
                        render={() => (
                            <TypeAhead
                                placeholder="Enter"
                                label={'Address:*'}
                                onStateChange={ev => {
                                    setValue(`address`, ev?.label || '');
                                    clearErrors(`address`);
                                }}
                                errors={errors.address?.message}
                                loadOptions={carryAddressLoadOptions(false, filterStateCode)}
                            />
                        )}
                        name={`address`}
                        control={control}
                        defaultValue={''}
                    />
                    <CustomTextarea
                        register={{ ...register('description') }}
                        label="Description"
                        placeholder="Enter"
                        errors={errors.description}
                        height="75px"
                    />
                    <SimpleGrid columns={2} gap="50px" rowGap="0px">
                        <Box pt="20px">
                            <CustomDropdown
                                label="The client is:*"
                                options={[
                                    { id: 1, label: 'A Couple', value: '1' },
                                    { id: 2, label: 'A Family', value: '2' },
                                    { id: 3, label: 'An Individual', value: '3' },
                                ]}
                                placeholder="Select"
                                register={{
                                    onChange: (ev: any) => {
                                        setValue('clientTypeId', ev.target.value);
                                    },
                                }}
                                errors={errors.clientTypeId}
                                width="350px"
                                margin={errors.clientTypeId ? '0' : '0 0 26px 0'}
                            />
                        </Box>
                        <Box pt="20px">
                            <CustomInput
                                register={{ ...register('clientName') }}
                                type="text"
                                label="Client name:"
                                errors={errors.clientName}
                                placeholder="Enter"
                                width="350px"
                                margin={errors.clientName ? '0' : '0 0 26px 0 !important'}
                            />
                        </Box>
                    </SimpleGrid>
                    <CustomInput
                        register={{ ...register('clientPhone') }}
                        type="tel"
                        mask="999-999-9999"
                        label="Client phone number:"
                        errors={errors.clientPhone}
                        placeholder="Enter"
                        margin={errors.clientPhone ? '0' : '0 0 26px 0 !important'}
                    />
                    <CustomTextarea
                        register={{ ...register('privateNote') }}
                        label="Private Notes To The Chosen Agent:"
                        placeholder="Enter"
                        errors={errors.privateNote}
                        height="50px"
                    />
                </Flex>
                <Flex pt="20px" flexDir="column" alignItems="flex-end">
                    <Flex justifyContent="space-between" width="350px">
                        <Text fontSize="13px" color="secondaryFontDarker">
                            Fee Price
                        </Text>
                        <Text fontSize="16px" fontWeight={500} color="secondaryFontDarker">
                            ${feePrice}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between" width="350px" mt="10px">
                        <Text fontSize="13px" color="secondaryFontDarker">
                            Offer Price
                        </Text>
                        <Text fontSize="16px" fontWeight={500} color="secondaryFontDarker">
                            ${price ? price : 0}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between" width="350px" mt="10px" mb="40px">
                        <Text fontSize="13px" color="brand" fontWeight={600}>
                            Eastimated Cost
                        </Text>
                        <Text fontSize="19px" fontWeight={500} color="brand">
                            ${finalPrice}
                        </Text>
                    </Flex>
                    <CustomButton
                        width="350px"
                        height="60px"
                        type="submit"
                        isLoading={createPostLoading}
                        loadingText="Sending..."
                        text="Post"
                        bgColor="brand"
                        mb="50px"
                    />
                </Flex>
            </form>
        </Box>
    );
};
