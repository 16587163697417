import React from 'react';
import {
    Box,
    Text,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    CloseButton,
} from '@chakra-ui/react';

interface IModal {
    isOpen: boolean;
    onClose: () => void;
    modalHeader?: JSX.Element;
    modalBody?: JSX.Element;
    modalSearch?: JSX.Element;
}

const ModalWrapper: React.FC<IModal> = ({
    isOpen,
    onClose,
    modalHeader,
    modalBody,
    modalSearch,
}) => (
    <>
        <Modal isOpen={isOpen} onClose={onClose} size="5xl" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent borderRadius={'18px'} h={'85vh'} bgColor="#EBF2F8" alignItems="center">
                <ModalHeader
                    d="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box w="100%">{modalHeader}</Box>
                    <CloseButton onClick={onClose} />
                </ModalHeader>
                <ModalBody d="flex" w="100%" flexDirection="column">
                    <Text color="brand" fontWeight={600} fontSize="18px" lineHeight="25px">
                        Search
                    </Text>
                    {modalSearch}
                    <Divider borderColor="#D1D2DB" mt="25px" />
                    {modalBody}
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
);

export default ModalWrapper;
