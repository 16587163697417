import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { FundingSourceBox, PaymentsModalWrapper } from '../components';
import { Button, Flex } from '@chakra-ui/react';
import {
    GetFundingSourcesResponse,
    GetProcessorTokenPayload,
    PlaidLinkTokenResponse,
} from '../types';
import { Loader } from '../../../../../common/components';
import { useDispatch, useSelector } from 'react-redux';
import { initVerificationFundingSourceRequest, removeFundingSourceRequest } from '../store/actions';
import { RootStore } from '../../../../../store';

interface IFundingSourcesModal {
    isOpen: boolean;
    title: string;
    onClose: () => void;
    fundingSourcesLoading: boolean;
    fundingSources: GetFundingSourcesResponse[];
    onCheckAddNewFundingSource: () => void;
    checkAddFundingSourceLoading: boolean;
    setFundingSourceId: Dispatch<SetStateAction<string>>;
    setVerifyFundingSourceId: Dispatch<SetStateAction<string>>;
    setEditBankNameId: Dispatch<SetStateAction<string>>;
    onGetPlaidLinkToken: (payload: boolean) => void;
    onGetProcessorToken: (payload: GetProcessorTokenPayload) => void;
    plaidLinkTokenLoading: boolean;
    plaidLinkTokenData: PlaidLinkTokenResponse;
}

export const FundingSourcesModal: React.FC<IFundingSourcesModal> = ({
    isOpen,
    title,
    onClose,
    fundingSourcesLoading,
    fundingSources,
    onCheckAddNewFundingSource,
    checkAddFundingSourceLoading,
    setFundingSourceId,
    setVerifyFundingSourceId,
    setEditBankNameId,
}) => {
    const dispatch = useDispatch();
    const { loading: removeFundingSourceLoading } = useSelector(
        (state: RootStore) => state.payments.removeFundingSource,
    );
    const { loading: initVerificationLoading } = useSelector(
        (state: RootStore) => state.payments.initVerificationFundingSource,
    );

    const onRemoveFundingSource = (id: string) => {
        dispatch(removeFundingSourceRequest(id));
    };
    const onInitVerificationFundingSource = (id: string) => {
        dispatch(initVerificationFundingSourceRequest(id));
    };

    return (
        <PaymentsModalWrapper trapFocus={false} isOpen={isOpen} title={title} onClose={onClose}>
            <Flex direction={'column'} h={'70vh'}>
                {fundingSourcesLoading ? (
                    <Loader spinnerSize={'md'} centerHeight={'100%'} />
                ) : fundingSources.length > 0 ? (
                    fundingSources.map(item => (
                        <FundingSourceBox
                            setEditBankNameId={setEditBankNameId}
                            setVerifyFundingSourceId={setVerifyFundingSourceId}
                            onInitVerificationFundingSource={onInitVerificationFundingSource}
                            initVerificationLoading={initVerificationLoading}
                            setFundingSourceId={setFundingSourceId}
                            removeFundingSourceLoading={removeFundingSourceLoading}
                            onRemoveFundingSource={onRemoveFundingSource}
                            key={item.dwollaId}
                            item={item}
                        />
                    ))
                ) : (
                    //Empty Funding Source Box
                    <Flex
                        align={'center'}
                        letterSpacing={'.2px'}
                        lineHeight={'24px'}
                        fontSize={'16px'}
                        color={'#888DAB'}
                        mt={'53px'}
                        bg={'#E1E8F0'}
                        borderRadius={'10px'}
                        px={'22px'}
                        h={'83px'}
                    >
                        No Methods Yet
                    </Flex>
                )}

                <Flex justifyContent="end" mt="auto" justify="space-between">
                    <Button
                        w={'47%'}
                        isLoading={checkAddFundingSourceLoading}
                        onClick={onCheckAddNewFundingSource}
                        variant={'primary'}
                    >
                        Add Funding Manually
                    </Button>
                </Flex>
            </Flex>
        </PaymentsModalWrapper>
    );
};
