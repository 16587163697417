import React from 'react';
import {
    Button,
    Center,
    Modal,
    Text,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
} from '@chakra-ui/react';
import { Typography } from '../../Typography/Typography';
import { BackArrow } from '../../../../../assets/icons';
import { SearchUsersCardItem } from './SearchUsersCardItem';
import { AgentsType, ToggleUserProfileModalPayload } from '../../../../types';
import { InfiniteScroll } from '../../InfiniteScroll/InfiniteScroll';
import { Loader } from '../../Loader/Loader';

interface ISubscriptionsModal {
    isOpen: boolean;
    onClose: () => void;
    loading: boolean;
    loadMoreLoading: boolean;
    agents: AgentsType[];
    loadMoreItems: () => void;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
}

export const SearchUsersModal: React.FC<ISubscriptionsModal> = ({
    isOpen,
    onClose,
    loading,
    loadMoreLoading,
    agents,
    loadMoreItems,
    onOpenProfileImage,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
            <ModalOverlay />
            <ModalContent mt="70px" h="80vh">
                <ModalHeader pt={'40px'} px={'40px'}>
                    <Button
                        leftIcon={<BackArrow />}
                        variant="unstyled"
                        onClick={onClose}
                        _focus={{}}
                    />
                    <Typography size={24} weight={700} ml="20px">
                        Search Results
                    </Typography>
                </ModalHeader>
                <ModalBody overflowY={'auto'}>
                    {loading ? (
                        <Loader spinnerSize="md" centerHeight="100%" />
                    ) : agents.length ? (
                        <InfiniteScroll onScroll={loadMoreItems} height="calc(90vh - 180px)">
                            <SimpleGrid columns={2} spacing={10}>
                                {agents.map(agent => (
                                    <SearchUsersCardItem
                                        key={agent.id}
                                        agent={agent}
                                        onOpenProfileImage={onOpenProfileImage}
                                    />
                                ))}
                            </SimpleGrid>
                            {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
                        </InfiniteScroll>
                    ) : (
                        <Center h="50vh">
                            <Text color="brand">There is no users with that name</Text>
                        </Center>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
