import { put, call, takeEvery } from 'redux-saga/effects';
import { getAgencyRequests, getAgencyRequestsSuccess, getAgencyRequestsError } from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithCustomValue } from '../../../../common/types';
import { SentRequestType, MyRequestsType } from '../../types';

function* workerGetAgencyRequests(action: ReturnType<typeof getAgencyRequests>) {
    try {
        const result: IResponseWithCustomValue<{
            assistRequests: SentRequestType[];
            receivedAssistRequests: MyRequestsType[];
            totalCount: number;
        }> = yield call(API.post, '/api/request/sent', action.payload);
        console.log('getSentRequests action', result, action);
        if (result.success) {
            yield put(
                getAgencyRequestsSuccess({
                    ...result.value,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getAgencyRequestsError(result.errors));
        }
    } catch (error) {
        console.log('error workerGetAgencyRequests', error);
    }
}

export default function* watchGetAgencyRequestsSaga() {
    yield takeEvery(getAgencyRequests.type, workerGetAgencyRequests);
}
