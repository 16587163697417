import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import { getRequestCategories } from '../../store/actions/directories.actions';
import { Activity } from './Activity';
import {
    addFeedbackRequest,
    getAgentActivityRequest,
    getCurrentActivityRequest,
    setDefaultRequest,
} from './store/actions';
import {
    AddFeedbackPayload,
    GetAgentActivityPayload,
    GetCurrentActivityPayload,
    SelectedActivity,
} from './types';
import { useDisclosure } from '@chakra-ui/react';
import { toggleUserProfileModal } from '../../store/actions/profile.actions';
import { ToggleUserProfileModalPayload } from '../../common/types';
import { createChat } from '../../store/actions/messages.actions';

export const ActivityContainer: React.FC = () => {
    const dispatch = useDispatch();

    const [showInProgressActivityModal, setShowInProgressActivityModal] = useState(false);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState<SelectedActivity>({
        id: 0,
        isMyActivity: undefined,
    });
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [typeFilter, setTypeFilter] = useState<number[]>([]);
    const [activeTab, setActiveTab] = useState(0);

    const getCurrentActivity = (payload: GetCurrentActivityPayload) =>
        dispatch(getCurrentActivityRequest(payload));
    const getAgentActivity = (payload: GetAgentActivityPayload) =>
        dispatch(getAgentActivityRequest(payload));
    const addFeedback = (payload: AddFeedbackPayload) => dispatch(addFeedbackRequest(payload));
    const onOpenProfileImage = (payload: ToggleUserProfileModalPayload) => {
        dispatch(toggleUserProfileModal(payload));
    };
    const onCreateChat = (userId: number) => {
        dispatch(createChat(userId));
    };

    const actualDuration = useSelector(
        (state: RootStore) => state.requests.requestInfo.value.actualDuration,
    );

    const { activity, totalCount, loading, loadMoreLoading } = useSelector(
        (state: RootStore) => state.activity.activity,
    );
    const requestAddresses = useSelector(
        (state: RootStore) => state.activity.requestAddresses.addresses,
    );
    const { request: requestById, loading: requstByIdLoading } = useSelector(
        (state: RootStore) => state.activity.requestById,
    );

    const requestCategories = useSelector(
        (state: RootStore) => state.directories.requestCategories,
    );

    const { isOpen: isOpenIssue, onClose: onCloseIssue, onOpen: onOpenIssue } = useDisclosure();

    useEffect(() => {
        dispatch(getRequestCategories());
    }, []);

    useEffect(() => {
        if (requestById.id) {
            setShowInProgressActivityModal(true);
        }
    }, [requestById, requestAddresses]);

    const loadMoreItems = () => {
        if (activity.length >= totalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPage((prev: number) => prev + 1);
        }
    };

    useEffect(() => {
        if (selectedActivityId.id !== 0) {
            setShowInProgressActivityModal(true);
        }
    }, [selectedActivityId]);

    const fetchItems = (page?: number) => {
        if (activeTab === 0) {
            getCurrentActivity({
                pageNumber: page || currentPage,
                pageSize: 10,
                date: '',
                categoryIds: typeFilter,
            });
        }
        if (activeTab === 1) {
            getAgentActivity({
                pageNumber: page || currentPage,
                pageSize: 10,
                date: '',
                categoryIds: typeFilter,
            });
        }
    };

    useEffect(() => {
        fetchItems();
    }, [currentPage, activeTab, typeFilter]);

    const setTab = (tabId: number) => {
        setActiveTab(tabId);
        setCurrentPage(1);
    };
    return (
        <Activity
            loadMoreItems={loadMoreItems}
            loadMoreLoading={loadMoreLoading}
            loading={loading}
            activity={activity}
            tabIndex={activeTab}
            setTabIndex={setTab}
            setTypeFilter={setTypeFilter}
            selectedActivityId={selectedActivityId}
            setSelectedActivityId={setSelectedActivityId}
            showInProgressActivityModal={showInProgressActivityModal}
            setShowInProgressActivityModal={setShowInProgressActivityModal}
            requestAddresses={requestAddresses}
            requestCategories={requestCategories}
            actualDuration={actualDuration}
            isOpenIssue={isOpenIssue}
            onCloseIssue={onCloseIssue}
            onOpenIssue={onOpenIssue}
            showFeedbackModal={showFeedbackModal}
            setShowAddFeedback={setShowFeedbackModal}
            addFeedback={addFeedback}
            requestInfo={requestById}
            fetchItems={fetchItems}
            requestByIdLoading={requstByIdLoading}
            onSetDefaultRequest={() => dispatch(setDefaultRequest())}
            onOpenProfileImage={onOpenProfileImage}
            onCreateChat={onCreateChat}
        />
    );
};
