import React, { useEffect, useState } from 'react';
import { sliceIntoChunks, getDaysNewWay, monthLabels, dayLabels } from './utils';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { CustomDropdown } from '../../blocks';
import { ItemType } from '../../../types';

const yearsList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(10), (val, index) => index + year).map((year, i) => ({
        value: year.toString(),
        label: year.toString(),
        id: i,
    }));
};

interface CalendarForDatePicker {
    setValue: (value: number) => void;
    onClose: () => void;
    value?: number;
    maximumDate?: Date;
    minimumDate?: Date;
    customYearList?: () => ItemType[];
}

const getColor = (
    selectedItem: number[] | number | null,
    selectedMonth: number,
    selectedYear: number,
    day: number,
) => {
    if (
        dayjs.utc().month(selectedMonth).year(selectedYear).format('MMMM YYYY') ===
        dayjs.utc(day).format('MMMM YYYY')
    ) {
        if (day === selectedItem) {
            return 'white';
        } else {
            return 'darkest';
        }
    } else {
        return 'darkGrey';
    }
};

const getBg = (
    day: number,
    selectedItem: number[] | number | null,
    selectedMonth: number,
    selectedYear: number,
) => {
    if (day === selectedItem) {
        return 'brand';
    }
    if (dayjs.utc(day).isToday()) {
        if (
            dayjs.utc().month(selectedMonth).year(selectedYear).format('MMMM YYYY') !==
            dayjs.utc(day).format('MMMM YYYY')
        ) {
            return 'calendarBlueOpacity';
        } else {
            return 'calendarBlue';
        }
    }
    return '';
};

const getAllowedInRange = (
    day: number,
    minimumDate: Date | undefined,
    maximumDate: Date | undefined,
) => {
    if (minimumDate && !maximumDate) {
        return dayjs.utc(day).isSameOrAfter(dayjs.utc(minimumDate), 'day');
    }
    if (maximumDate && !minimumDate) {
        return dayjs.utc(day).isSameOrBefore(dayjs.utc(maximumDate), 'day');
    }
    if (maximumDate && minimumDate) {
        return (
            dayjs.utc(day).isSameOrAfter(dayjs.utc(minimumDate), 'day') &&
            dayjs.utc(day).isSameOrBefore(dayjs.utc(maximumDate), 'day')
        );
    }
    return true;
};

export const CalendarForDatePicker = React.forwardRef<null, CalendarForDatePicker>(
    ({ setValue, onClose, value, maximumDate, minimumDate, customYearList }, ref) => {
        const [selectedItem, setSelectedItem] = useState<number[] | number | null>(null);
        const [selectedMonth, setSelectedMonth] = useState(dayjs.utc(value).month());

        const [selectedYear, setSelectedYear] = useState(dayjs.utc(value).year());

        useEffect(() => {
            if (typeof selectedItem === 'number') {
                setValue(selectedItem);
                onClose();
            }
        }, [selectedItem]);

        return (
            <Box
                p={'16px'}
                borderRadius={'16px'}
                boxShadow={'0px 6px 16px rgba(59, 41, 2, 0.16)'}
                bg={'#FFFFFF'}
            >
                {/*Month List Header*/}
                <Flex justifyContent={'space-between'}>
                    <Box w={'55%'}>
                        <CustomDropdown
                            label={''}
                            options={monthLabels.map((month, indx) => ({
                                value: indx.toString(),
                                label: month,
                                id: indx,
                            }))}
                            register={{
                                onChange: (ev: any) => {
                                    setSelectedMonth(+ev.target.value);
                                },
                                defaultValue: selectedMonth,
                            }}
                            errors={''}
                        />
                    </Box>
                    <Box w={'40%'}>
                        <CustomDropdown
                            label={''}
                            options={customYearList?.() || yearsList()}
                            register={{
                                onChange: (ev: any) => setSelectedYear(+ev.target.value),
                                defaultValue: selectedYear,
                            }}
                            errors={''}
                        />
                    </Box>
                </Flex>
                <Flex py="10px" borderBottom="1px solid #EBEBEB">
                    {dayLabels.map(label => (
                        <Box key={label.toLowerCase()} flex="1">
                            <Text fontSize="14px" color="darkGrey" textAlign="center">
                                {label.slice(0, 3)}
                            </Text>
                        </Box>
                    ))}
                </Flex>
                <Box>
                    {sliceIntoChunks(getDaysNewWay(selectedMonth, selectedYear), 7).map(
                        (week: number[], index: number) => (
                            <Flex key={index} borderRadius="8px">
                                {week.map((day: number) => (
                                    <Button
                                        variant="unstyled"
                                        key={day}
                                        flex="1"
                                        fontSize="15px"
                                        lineHeight="24px"
                                        fontWeight="500"
                                        disabled={!getAllowedInRange(day, minimumDate, maximumDate)}
                                        color={getColor(
                                            selectedItem,
                                            selectedMonth,
                                            selectedYear,
                                            day,
                                        )}
                                        height="40px"
                                        onClick={() => {
                                            if (selectedItem === day) {
                                                setSelectedItem(0);
                                            } else {
                                                setSelectedItem(day);
                                            }
                                        }}
                                        bg={getBg(day, selectedItem, selectedMonth, selectedYear)}
                                    >
                                        {dayjs.utc(day).format('D')}
                                    </Button>
                                ))}
                            </Flex>
                        ),
                    )}
                </Box>
            </Box>
        );
    },
);

CalendarForDatePicker.displayName = 'CalendarForDatePicker';
