import React, {useEffect, useState} from 'react'
import {Box} from "@chakra-ui/react";
import {PlaidLinkOptions, usePlaidLink} from "react-plaid-link";
import {useDispatch} from "react-redux";
import {getProcessorToken} from "../Settings/pages/Payments/store/actions";
import {useHistory} from "react-router-dom";

export const OauthPage:React.FC = () => {
    const [token, setToken] = useState<string>('');
    const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

    const dispatch = useDispatch()
    const history = useHistory();
    console.log(token, 'token')

    useEffect(() => {
       if(isOAuthRedirect){
           const storedTokenData = localStorage.getItem('linkTokenData')

           if(storedTokenData !== null){
               const linkToken = JSON.parse(storedTokenData)
               setToken(linkToken)
           }
       }

    },[])

    const _CONFIG: PlaidLinkOptions = {
        token: token,
        onSuccess: (publicToken,metadata) => {
            console.log(metadata, 'metadata')
            console.log('ON SUCCESS REDIRECT')
            history.push('/settings/payments')
            dispatch(getProcessorToken({
                accountId: metadata.accounts[0].id,
                publicToken
            }))

        },
        onExit: (exit) => console.log(exit, 'EXIT'),
        receivedRedirectUri: window.location.href
    }

    const {
        open,
        ready,
        // error,
        // exit
    } = usePlaidLink(_CONFIG);

    useEffect(() => {
        if(isOAuthRedirect && ready && token !== ''){
            open()
        }

    },[ready,open, isOAuthRedirect, token])

    return (
        <Box h={'100vh'} bgColor={'brand'}/>

    )
}
