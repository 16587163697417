import React from 'react';
import { Box, Flex, Text, Input, Textarea, Container } from '@chakra-ui/react';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { BackButton, CustomButton } from '../../common/components';
import { WriteToSupportPayloadType } from './types';

interface IHelp {
    titleValue: string;
    messageValue: string;
    handleChangeTitle: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeMessage: (arg0: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleResetTexts: () => void;
    onWriteToSupportRequest: (payload: WriteToSupportPayloadType) => void;
    checkText: boolean;
    loading: boolean;
}

const Help: React.FC<IHelp> = ({
    titleValue,
    messageValue,
    handleChangeMessage,
    handleChangeTitle,
    handleResetTexts,
    onWriteToSupportRequest,
    checkText,
    loading,
}) => (
    <Flex backgroundColor="brand" flexDirection="column" alignItems="center" height="100vh">
        <DashboardHeader />
        <Container
            d="flex"
            max="1000px"
            bgColor="#FFFFFF"
            mt="15px"
            borderRadius="10px"
            p="50px"
            flexDirection="column"
        >
            <Flex direction="row" align="center">
                <BackButton mb="0" text="" />
                <Text
                    fontSize="22px"
                    fontWeight="700"
                    color="brand"
                    textTransform="capitalize"
                    align="center"
                    ml="50px"
                >
                    Write to support
                </Text>
            </Flex>
            <Flex py="20px" align="center" w="100%">
                <Box w="100%">
                    <Text color="brand" fontSize="13px" fontWeight={300} lineHeight="20px">
                        Title
                    </Text>
                    <Input
                        h="50px"
                        w="100%"
                        color="##F6F7FB"
                        variant="filled"
                        placeholder="Enter"
                        value={titleValue}
                        onChange={handleChangeTitle}
                        borderRadius="80px"
                    />
                </Box>
            </Flex>
            <Flex py="20px" align="center">
                <Textarea
                    h="150px"
                    color="##F6F7FB"
                    variant="filled"
                    placeholder="Enter message"
                    value={messageValue}
                    onChange={handleChangeMessage}
                    borderRadius="24px"
                />
            </Flex>
            <Flex py="20px" align="center" justifyContent="flex-end">
                <CustomButton
                    type="submit"
                    loadingText="Sending..."
                    isLoading={loading}
                    text="Send"
                    width="300px"
                    height="50px"
                    bgColor="brand"
                    disabled={checkText || loading}
                    onClick={() => {
                        onWriteToSupportRequest({
                            title: titleValue,
                            description: messageValue,
                        });
                        handleResetTexts();
                    }}
                />
            </Flex>
        </Container>
    </Flex>
);

export default Help;
