import React, { useEffect, useState } from 'react';
import { Flex, SimpleGrid, Text, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CustomButton, CustomDropdown, CustomInput } from '../../../../../common/components';
import { addLicenseResolver } from '../../../../../common/schemes';
import {
    AddLicensePayload,
    LicenseDraftForm,
    LicenseDraftType,
    LicenseType,
    SaveLicenseDraftPayload,
} from '../../../types';
import { StateDirectoryItem, AgenciesItem, GetCitiesResponse } from '../../../../../common/types';
import { SearchCitiesSelect } from '../../../../../common/components/blocks/SearchCitiesSelect';
import { EditIcon } from '../../../../../assets/icons';

interface ILicense {
    license: LicenseType;
    states: StateDirectoryItem[];
    agencies: AgenciesItem[];
    draft: LicenseDraftType;
    addLicenseLoading: boolean;
    saveDraftLoading: boolean;
    index: number;
    showLicenseEditor: boolean;
    accessToken: string;
    stateId: string;
    choosenItem: number | null;
    setState: (v: string) => void;
    saveLicenseDraft: (v: SaveLicenseDraftPayload) => void;
    addLicense: (v: AddLicensePayload) => void;
    handleAddLicense: () => void;
    setChoosenItem: (id: number | null) => void;
}

export const LicenseItem: React.FC<ILicense> = ({
    license,
    states,
    agencies,
    draft,
    saveDraftLoading,
    index,
    accessToken,
    stateId,
    choosenItem,
    setState,
    saveLicenseDraft,
    setChoosenItem,
}) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
        watch,
        setValue,
    } = useForm<LicenseDraftForm>({ resolver: addLicenseResolver });

    const [typeIdsSelected, setTypeIdsSelected] = useState<GetCitiesResponse[]>(
        license.areasOfServiceModels,
    );
    const [stateIdValue, setStateIdValue] = useState(0);

    const [modalType, setModalType] = useState('existing');

    useEffect(() => {
        if (choosenItem === index) {
            setModalType('new');
        } else {
            setModalType('existing');
        }
    }, [index, choosenItem]);

    const onSubmit = (data: LicenseDraftForm) => {
        const editPayload: SaveLicenseDraftPayload = {
            state: data.state,
            licenseNumber: data.licenseNumber,
            mlsId: data.mlsId,
            preferredAreasOfServices: typeIdsSelected,
            agencyId: +data.agency || license.agencyId,
            changeLicensePreferredAreasOfServiceModel: [
                {
                    licenseId: license.id,
                    preferredAreasOfServiceModels: typeIdsSelected,
                },
            ],
        };
        saveLicenseDraft(editPayload);
        setChoosenItem(null);
    };

    useEffect(() => {
        setState(license.state);
    }, []);

    useEffect(() => {
        setValue('state', license.state);
        setValue('licenseNumber', license.license);
        setValue('mlsId', license.mlsId);
    }, []);

    useEffect(() => {
        setStateIdValue(Number(stateId));
        setTypeIdsSelected(license.areasOfServiceModels);
    }, []);

    const resetFields = () => {
        reset({
            mlsId: license.mlsId,
            licenseNumber: license.license,
            state: license.state,
        });

        setChoosenItem(null);
    };

    return (
        <Flex flexDirection="column" borderTop={index && '1px solid #D1D2DB'}>
            <Flex>
                <Text mt="20px" fontSize="18px" fontWeight="600">
                    {license.state}
                </Text>
                {!license.isVerified && (
                    <Text
                        ml="20px"
                        mt="20px"
                        fontSize="18px"
                        fontWeight="600"
                        textDecoration={'underline'}
                        color="#F2C94C"
                    >
                        Verification Processing
                    </Text>
                )}
            </Flex>

            <Flex direction="column" align="center">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SimpleGrid columns={2} gap="100px" rowGap="0px" mt="10px">
                        <Flex w="100%" mb="15px">
                            <CustomDropdown
                                options={states?.map((item: any) => ({
                                    value: item.name,
                                    label: item.name,
                                    id: item.id,
                                }))}
                                label="State"
                                register={{
                                    ...register('state'),
                                    onChange: (value: any) => {
                                        register('state').onChange(value);
                                        setState(value.target.value);
                                    },
                                    isDisabled: modalType === 'existing',
                                }}
                                errors={errors.state}
                                width="450px"
                                height="50px"
                                fontSize="16px"
                                labelSize="16px"
                                placeholder={license.state}
                            />
                        </Flex>
                        <Flex w="100%" mb="15px">
                            <CustomDropdown
                                options={agencies?.map((item: any) => ({
                                    value: item.name,
                                    label: item.name,
                                    id: item.id,
                                }))}
                                label="Agency"
                                register={{
                                    ...register('agency'),
                                    onChange: (value: any) => {
                                        register('agency').onChange(value);
                                    },
                                    isDisabled: modalType === 'existing',
                                }}
                                errors={errors.agency}
                                width="100%"
                                height="50px"
                                fontSize="16px"
                                labelSize="16px"
                                placeholder={license.agencyName}
                            />
                        </Flex>
                        <Flex w="100%" mb="15px">
                            <CustomInput
                                label="RE License"
                                placeholder="RE License"
                                register={{ ...register('licenseNumber') }}
                                width="100%"
                                defaultValue={
                                    modalType === 'existing'
                                        ? license.license
                                        : modalType === 'draft'
                                            ? draft.licenseNumber
                                            : ''
                                }
                                errors={errors.licenseNumber}
                                disabled={modalType === 'existing'}
                                height="50px"
                                fontSize="16px"
                                textSize="16px"
                            />
                        </Flex>
                        <Flex w="100%" flexDir="column">
                            <Text
                                fontWeight="300"
                                fontSize="16px"
                                textSize="16px"
                                fontFamily="poppins"
                            >
                                Areas of Service
                            </Text>
                            <SearchCitiesSelect
                                stateIdValue={stateIdValue}
                                setTypeIdsSelected={setTypeIdsSelected}
                                typeIdsSelected={typeIdsSelected}
                                // disabled={modalType === 'existing'}
                                token={accessToken}
                            />
                        </Flex>
                        <Flex w="100%" mb="15px">
                            <CustomInput
                                label="MLS ID"
                                placeholder="MLS ID"
                                register={{ ...register('mlsId') }}
                                width="100%"
                                defaultValue={
                                    modalType === 'existing'
                                        ? license.mlsId
                                        : modalType === 'draft'
                                            ? draft.mlsId
                                            : ''
                                }
                                errors={errors.mlsId}
                                disabled={modalType === 'existing'}
                                height="50px"
                                fontSize="16px"
                                textSize="16px"
                            />
                        </Flex>

                        <Flex w="100%" justify="flex-end" mt="20px">
                            {modalType === 'new' && (<CustomButton
                                text="Cancel"
                                isLoading={saveDraftLoading}
                                width="100px"
                                height="50px"
                                bgColor="#fff"
                                borderColor="brand"
                                color="brand"
                                margin="0 0 20px 0"
                                onClick={resetFields}
                            />)}
                            <CustomButton
                                text="Save"
                                type="submit"
                                width="100px"
                                height="50px"
                                bgColor="brand"
                                margin="0 0 20px 20px"
                                isLoading={saveDraftLoading}
                            />
                        </Flex>
                    </SimpleGrid>
                </form >
            </Flex >
        </Flex >
    );
};
