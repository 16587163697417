import React from 'react';
import { Box, TabList, Tab, Text } from '@chakra-ui/react';
import { colors } from '../../../constants';

const Indicator: React.FC = () => (
    <Box
        bg="indicatorGreen"
        position="absolute"
        w="90%"
        h="3px"
        boxShadow={`0px -1px 3px ${colors.indicatorGreenShadow}`}
        bottom={0}
        left="5%"
    />
);

interface ITabsSwitcherProps {
    setTabIndex: (value: number) => void;
    tabIndex: number;
    tabsOptions: { index: number; label: string }[];
}

export const TabsSwitcher: React.FC<ITabsSwitcherProps> = ({
    setTabIndex,
    tabIndex,
    tabsOptions,
}) => {
    return (
        <>
            <TabList bg="inputBackground" borderRadius="20px" h="55px">
                {tabsOptions.map(({ index, label }) => (
                    <Tab
                        w="210px"
                        p="0"
                        onClick={() => setTabIndex(index)}
                        position="relative"
                        key={index}
                    >
                        <Text
                            fontSize="14px"
                            color={tabIndex === index ? 'brand' : 'secondaryFontDarker'}
                            fontWeight={600}
                        >
                            {label}
                        </Text>
                        {tabIndex === index && <Indicator />}
                    </Tab>
                ))}
            </TabList>
        </>
    );
};
