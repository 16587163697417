import React from 'react';
import { Flex, Box, HStack, Avatar, Text } from '@chakra-ui/react';
import { OpenHouseInfoSection } from '../../../../../common/components';
import { OpenHouseInfoType } from '../../../../../common/types';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getAvatarPicture } from '../../../../../common/utils';

interface IInfoTabProps {
    openHouseInfo: OpenHouseInfoType;
}

export const InfoTab: React.FC<IInfoTabProps> = ({ openHouseInfo }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    return (
        <Flex>
            <OpenHouseInfoSection
                openHouseInfo={openHouseInfo}
                optionalContainerProps={{ borderRight: 'none' }}
            />
            <Box flexShrink={0} width="35%">
                {isLoaded ? (
                    <GoogleMap
                        options={{ disableDefaultUI: true }}
                        mapContainerStyle={{
                            height: '275px',
                            width: '100%',
                            borderRadius: '20px',
                        }}
                        center={{
                            lat: Number(openHouseInfo?.latitude),
                            lng: Number(openHouseInfo?.longitude),
                        }}
                        zoom={10}
                    >
                        <Marker
                            position={{
                                lat: Number(openHouseInfo?.latitude),
                                lng: Number(openHouseInfo?.longitude),
                            }}
                        />
                    </GoogleMap>
                ) : (
                    <></>
                )}
                <HStack spacing={2} mt="20px">
                    <Avatar
                        name={openHouseInfo?.agentName}
                        src={getAvatarPicture(openHouseInfo?.avatar)}
                        w="54px"
                        h="54px"
                    />
                    <Box>
                        <Text color="brand" fontWeight={700} fontSize="16px" lineHeight="23px">
                            {openHouseInfo?.agentName}
                        </Text>
                        <Text color="secondaryFontDarker" fontSize="12px" lineHeight="18px">
                            MLS Id: {openHouseInfo?.agentMlsId}
                        </Text>
                    </Box>
                </HStack>
                <Text
                    mt="10px"
                    fontSize="16px"
                    color="secondaryFontDarker"
                    lineHeight="23px"
                    fontWeight={500}
                >
                    {openHouseInfo?.agentOfficeName}
                </Text>
            </Box>
        </Flex>
    );
};
