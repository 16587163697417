import React from 'react';
import { Box, Text, HStack, Avatar, Flex } from '@chakra-ui/react';
import {
    EmailIcon,
    PhoneIcon,
    CheckIcon,
    CloseButton,
    DollarSignIcon,
} from '../../../../../assets/icons';
import { CustomButton } from '../../../../../common/components';
import { ApplicantType } from '../../../types';
import { getAvatarPicture, roundAplicantPrices } from '../../../../../common/utils';

interface IApplicantItemProps extends ApplicantType {
    onAccept: () => void;
    onReject: () => void;
}

export const ApplicantItem: React.FC<IApplicantItemProps> = ({
    agency,
    avatar,
    email,
    fullName,
    id,
    isConfirmed,
    phone,
    onAccept,
    onReject,
    fee,
    price,
}) => {
    return (
        <Box mt="15px">
            <Box bg="white" borderRadius="20px" mb="20px">
                <HStack spacing="30px" p="20px">
                    <Avatar name={fullName} src={getAvatarPicture(avatar)} w="73px" h="73px" />
                    <Box>
                        <Text fontSize="13px" color="brand" fontWeight="bold" lineHeight="19px">
                            {fullName}
                        </Text>
                        <Text fontSize="13px" color="brand" lineHeight="19px" fontWeight="500">
                            {agency}
                        </Text>
                        <HStack>
                            <EmailIcon />
                            <Text fontSize="12px" color="brand" lineHeight="18px">
                                {phone}
                            </Text>
                        </HStack>
                        <HStack>
                            <PhoneIcon />
                            <Text fontSize="12px" color="brand" lineHeight="18px">
                                {email}
                            </Text>
                        </HStack>
                    </Box>
                </HStack>
                <HStack borderTop="1px solid #D1D2DB">
                    <Flex
                        flex={1}
                        borderRight="1px solid #D1D2DB"
                        direction="column"
                        align="center"
                        height="70px"
                        justify="center"
                    >
                        <DollarSignIcon />
                        <Text color="brand" mt="5px" fontWeight="semibold">
                            Price ${roundAplicantPrices(price)}
                        </Text>
                    </Flex>
                    <Flex flex={1} direction="column" align="center" justify="center" height="70px">
                        <DollarSignIcon />
                        <Text color="brand" mt="5px" fontWeight="semibold">
                            Fee ${roundAplicantPrices(fee)}
                        </Text>
                    </Flex>
                </HStack>
            </Box>
            {!isConfirmed ? (
                <HStack spacing="20px">
                    <CustomButton
                        text="Reject"
                        color="#FE805C"
                        bgColor="#EEE1E1"
                        height="68px"
                        borderRadius="8px"
                        icon={<CloseButton color="#FE805C" width="16" height="16" />}
                        onClick={onReject}
                        hoverColor="#EEC7C7"
                    />
                    <CustomButton
                        text="Confirm"
                        color="#6FCF97"
                        bgColor="#D9EDEA"
                        height="68px"
                        borderRadius="8px"
                        icon={<CheckIcon />}
                        onClick={onAccept}
                        hoverColor="#C3EDDA"
                    />
                </HStack>
            ) : null}
        </Box>
    );
};
