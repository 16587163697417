import React from 'react';

export const ListCheckmark: React.FC = () => (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.7741 1.83337L6.06996 9.57421C5.93392 9.7547 5.73107 9.87296 5.50698 9.90242C5.28289 9.93188 5.05636 9.87007 4.8783 9.73087L0.804962 6.47421C0.445517 6.18656 0.387314 5.66199 0.674962 5.30254C0.962611 4.94309 1.48718 4.88489 1.84663 5.17254L5.2433 7.89004L10.4325 0.84754C10.6026 0.592198 10.8993 0.451025 11.2048 0.480071C11.5103 0.509117 11.7751 0.703681 11.8941 0.986517C12.013 1.26935 11.967 1.59469 11.7741 1.83337Z"
            fill="white"
        />
    </svg>
);
