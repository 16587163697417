import React from 'react';
import { Flex, Box, Text, IconButton, Container } from '@chakra-ui/react';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { BackArrow } from '../../assets/icons';
import { NotificationsList } from './components';
import { useHistory } from 'react-router';
import { Notification } from './types';
import { Loader } from '../../common/components';

interface INotifications {
    currentPage: number;
    setCurrentPage: (v: number) => void;
    items: Notification[];
    userNotificationsLastReadTime: string;
    loading: boolean;
    totalCount: number;
    handlePressNotification: (value: Notification) => void;
}

export const Notifications: React.FC<INotifications> = ({
    currentPage,
    setCurrentPage,
    items,
    loading,
    userNotificationsLastReadTime,
    totalCount,
    handlePressNotification,
}) => {
    const history = useHistory();
    return (
        <Flex backgroundColor="brand" h="100vh" flexDirection="column" alignItems="center">
            <DashboardHeader />
            <Container
                maxW="container.lg"
                bgColor="#FFFFFF"
                h="85vh"
                mt="15px"
                borderRadius="10px"
                py="20px"
            >
                <Flex direction="column" h="100%" px="30px" align="center">
                    <Flex justify="space-between" align="center" mb="30px" width="100%">
                        <Flex align="center" w="100%" justify="space-between">
                            <IconButton
                                aria-label="back"
                                variant="unstyled"
                                onClick={history.goBack}
                            >
                                <BackArrow />
                            </IconButton>
                            <Text fontSize="22px" fontWeight="700" color="brand">
                                Notifications
                            </Text>
                            <Box w="40px" />
                        </Flex>
                    </Flex>
                    <Flex align="center" direction="column" h="100%" width="100%" overflow="auto">
                        {loading ? (
                            <Loader centerHeight="100%" />
                        ) : (
                            <NotificationsList
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                items={items}
                                userNotificationsLastReadTime={userNotificationsLastReadTime}
                                totalCount={totalCount}
                                handlePressNotification={handlePressNotification}
                            />
                        )}
                    </Flex>
                </Flex>
            </Container>
        </Flex>
    );
};
