import React, { FC } from 'react';

import { Box, Container } from '@chakra-ui/react';

import { DropDownInfoList } from './DropDownInfoList';

interface IProps {
    dataArr: {
        header: string;
        text: string;
    }[];
}

export const DropDownInfoBlock: FC<IProps> = ({ dataArr }) => {
    return (
        <Box align="center" p="0">
            <Container
                align="left"
                w="100%"
                maxW="1255px"
                p="0"
                border="1px solid #D1D2DB"
                borderRadius="15px"
            >
                {dataArr.map(({ header, text }, index) => (
                    <DropDownInfoList
                        key={index}
                        header={header}
                        text={text}
                        borderBetween={index !== dataArr.length - 1}
                    />
                ))}
            </Container>
        </Box>
    );
};
