import React, { useEffect, useState } from 'react';
import ModalWrapper from '../../blocks/ModalWrapper/ModalWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { getCalendarOpportunitiesRequest } from '../../../../pages/Calendar/store/actions';
import { CalendarModalHeader, CalendarModalBody } from '../../../components/blocks';
import { PostDetailsModal } from '../../../../pages/Opportunities/modals';
import { OpportunitiesModalSearch } from './components';
import { CalendarModalItem } from '../../../../pages/Calendar/types';
interface IOpportunitiesModalProps {
    isOpen: boolean;
    onClose: () => void;
    startEndConfig: { start: string; end: string };
}

const OpportunitiesModal: React.FC<IOpportunitiesModalProps> = ({
    isOpen,
    onClose,
    startEndConfig,
}) => {
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
    const [showPostDetailsModal, setShowPostDetailsModal] = useState(false);
    const [postId, setPostId] = useState(0);
    const [typeIdsSelected, setTypeIdsSelected] = useState<number[]>([]);

    const { loading, items, loadMoreLoading, totalCount } = useSelector(
        (state: RootStore) => state.calendar.opportunitiesModal,
    );

    const getItems = (page?: number) => {
        dispatch(
            getCalendarOpportunitiesRequest({
                categoryId: 2,
                fromTime: startEndConfig.start,
                pageNumber: page || currentPage,
                pageSize: 10,
                toTime: startEndConfig.end,
                date: startEndConfig.start,
                typeIds: typeIdsSelected,
            }),
        );
    };

    useEffect(() => {
        if (isOpen) {
            getItems();
        }
    }, [isOpen, currentPage]);

    useEffect(() => {
        if (postId) {
            setShowPostDetailsModal(true);
        }
    }, [postId]);

    const loadMoreItems = () => {
        const itemsFullLength = items.reduce(
            (prevValue: number, currValue: CalendarModalItem) =>
                prevValue + currValue.models.length,
            0,
        );
        if (itemsFullLength >= totalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPage((prev: number) => prev + 1);
        }
    };

    return (
        <>
            <ModalWrapper
                isOpen={isOpen}
                onClose={onClose}
                modalHeader={
                    <CalendarModalHeader
                        bgColor="calendarYellowOpacity"
                        borderLeftColor="calendarYellow"
                        startDate={startEndConfig.start}
                        title="Opportunities Starting"
                    />
                }
                modalBody={
                    <CalendarModalBody
                        bgColor="calendarYellowOpacity"
                        borderLeftColor="calendarYellow"
                        items={items}
                        loadMoreItems={loadMoreItems}
                        loadMoreLoading={loadMoreLoading}
                        loading={loading}
                        placeholder="There is no available opportunities"
                        setPostId={setPostId}
                    />
                }
                modalSearch={
                    <OpportunitiesModalSearch
                        getItems={getItems}
                        setTypeIdsSelected={setTypeIdsSelected}
                        typeIdsSelected={typeIdsSelected}
                    />
                }
            />
            <PostDetailsModal
                isOpen={showPostDetailsModal}
                onClose={() => {
                    setShowPostDetailsModal(false);
                    setPostId(0);
                }}
                postId={postId}
                getItems={getItems}
            />
        </>
    );
};

export { OpportunitiesModal };
