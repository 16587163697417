import { createSlice } from '@reduxjs/toolkit';
import { PayloadError, IdNameTypes } from '../../../common/types';
import { FindJobPostsResponseType } from '../types';
import {
    createJobPostRequest,
    createJobPostSuccess,
    createJobPostError,
    findJobPostsRequest,
    findJobPostsSuccess,
    findJobPostsError,
    submitJobProposalRequest,
    submitJobProposalSuccess,
    submitJobProposalError,
    clearJobPostsList,
    getReferralOpportunityAgenciesSuccess,
} from './actions';

type InitialState = {
    createJobPost: {
        loading: boolean;
        errors: PayloadError[];
    };
    jobPosts: {
        loading: boolean;
        errors: PayloadError[];
        items: FindJobPostsResponseType[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
    submitJobProposal: {
        loading: boolean;
        errors: PayloadError[];
    };
    agenciesForState: IdNameTypes[];
};
const initialState: InitialState = {
    createJobPost: {
        loading: false,
        errors: [],
    },
    jobPosts: {
        loading: false,
        items: [],
        errors: [],
        totalCount: 0,
        loadMoreLoading: false,
    },
    submitJobProposal: {
        loading: false,
        errors: [],
    },
    agenciesForState: [],
};

const opportunitiesSlice = createSlice({
    name: 'opportunities',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(createJobPostRequest, state => {
                state.createJobPost.loading = true;
                state.createJobPost.errors = [];
            })
            .addCase(createJobPostSuccess, state => {
                state.createJobPost.loading = false;
                state.createJobPost.errors = [];
            })
            .addCase(createJobPostError, (state, action) => {
                state.createJobPost.loading = false;
                state.createJobPost.errors = action.payload;
            })
            .addCase(findJobPostsRequest, (state, action) => {
                if (action.payload.pageNumber === 1) {
                    state.jobPosts.loading = true;
                } else {
                    state.jobPosts.loadMoreLoading = true;
                }
                state.jobPosts.errors = [];
            })
            .addCase(findJobPostsSuccess, (state, action) => {
                state.jobPosts.loading = false;
                state.jobPosts.loadMoreLoading = false;
                state.jobPosts.items =
                    action.payload.pageNumber === 1
                        ? action.payload.jobListModels
                        : [...state.jobPosts.items, ...action.payload.jobListModels];
                state.jobPosts.totalCount = action.payload.totalCount;
            })
            .addCase(findJobPostsError, (state, action) => {
                state.jobPosts.loading = false;
                state.jobPosts.loadMoreLoading = false;
                state.jobPosts.errors = action.payload;
            })
            .addCase(clearJobPostsList, state => {
                state.jobPosts.items = [];
                state.jobPosts.totalCount = 0;
                state.jobPosts.errors = [];
            })
            // Submit Job Proposal
            .addCase(submitJobProposalRequest, state => {
                state.submitJobProposal.loading = true;
            })
            .addCase(submitJobProposalSuccess, state => {
                state.submitJobProposal.loading = false;
                state.submitJobProposal.errors = [];
            })
            .addCase(submitJobProposalError, (state, action) => {
                state.submitJobProposal.loading = false;
                state.submitJobProposal.errors = action.payload;
            })
            .addCase(getReferralOpportunityAgenciesSuccess, (state, action) => {
                state.agenciesForState = action.payload;
            });
    },
});

export default opportunitiesSlice.reducer;
