import { createAction } from '@reduxjs/toolkit';
import {
    PayloadError,
    IGetBonusTypesSuccessResponse,
    IGetClientTypesSuccessResponse,
    IGetPostTagsSuccessResponse,
    IGetReportTypesSuccessResponse,
    IGetRequestCategoriesSuccessResponse,
    IGetRequestTypesSuccessResponse,
    IGetStatesSuccessResponse,
    IdNameTypes,
    IResponseWithCustomValue,
    GetCitiesPayload,
} from '../../common/types';

export const getStates = createAction('GET_STATES');
export const getStatesSuccess =
    createAction<IResponseWithCustomValue<IGetStatesSuccessResponse>>('GET_STATES_SUCCESS');
export const getStatesError = createAction<PayloadError[]>('GET_STATES_ERROR');

export const getBonusTypes = createAction('GET_BONUS_TYPES');
export const getBonusTypesSuccess =
    createAction<IResponseWithCustomValue<IGetBonusTypesSuccessResponse>>(
        'GET_BONUS_TYPES_SUCCESS',
    );
export const getBonusTypesError = createAction<PayloadError[]>('GET_BONUS_TYPES_ERROR');

export const getClientTypes = createAction('GET_CLIENT_TYPES');
export const getClientTypesSuccess = createAction<
    IResponseWithCustomValue<IGetClientTypesSuccessResponse>
>('GET_CLIENT_TYPES_SUCCESS');
export const getClientTypesError = createAction<PayloadError[]>('GET_CLIENT_TYPES_ERROR');

export const getRequestTypes = createAction('GET_REQUEST_TYPES');
export const getRequestTypesSuccess = createAction<
    IResponseWithCustomValue<IGetRequestTypesSuccessResponse>
>('GET_REQUEST_TYPES_SUCCESS');
export const getRequestTypesError = createAction<PayloadError[]>('GET_REQUEST_TYPES_ERROR');

export const getRequestCategories = createAction('GET_REQUEST_CATEGORIES');
export const getRequestCategoriesSuccess = createAction<
    IResponseWithCustomValue<IGetRequestCategoriesSuccessResponse>
>('GET_REQUEST_CATEGORIES_SUCCESS');
export const getRequestCategoriesError = createAction<PayloadError[]>(
    'GET_REQUEST_CATEGORIES_ERROR',
);

export const getAgenciesTypesRequest = createAction('GET_AGENCIES_TYPES_REQUEST');
export const getAgenciesTypesSuccess = createAction<IdNameTypes[]>('GET_AGENCIES_TYPES_SUCCESS');
export const getAgenciesTypesError = createAction<PayloadError[]>('GET_AGENCIES_TYPES_ERROR');

export const getPostTags = createAction('GET_POST_TAGS');
export const getPostTagsSuccess =
    createAction<IResponseWithCustomValue<IGetPostTagsSuccessResponse>>('GET_POST_TAGS_SUCCESS');
export const getPostTagsError = createAction<PayloadError[]>('GET_POST_TAGS_ERROR');

export const getReportTypes = createAction('GET_REPORT_TYPES');
export const getReportTypesSuccess = createAction<
    IResponseWithCustomValue<IGetReportTypesSuccessResponse>
>('GET_REPORT_TYPES_SUCCESS');
export const getReportTypesError = createAction<PayloadError[]>('GET_REPORT_TYPES_ERROR');

export const getOpenHouseTypes = createAction('GET_OPEN_HOUSE_TYPES');
export const getOpenHouseTypesSuccess = createAction<{
    types: Array<IdNameTypes>;
}>('GET_OPEN_HOUSE_TYPES_SUCCESS');
export const getOpenHouseTypesError = createAction<PayloadError[]>('GET_OPEN_HOUSE_TYPES_ERROR');

export const getOpenHouseHostTypes = createAction('GET_OPEN_HOUSE_HOST_TYPES');
export const getOpenHouseHostTypesSuccess = createAction<{
    types: Array<IdNameTypes>;
}>('GET_OPEN_HOUSE_HOST_TYPES_SUCCESS');
export const getOpenHouseHostTypesError = createAction<PayloadError[]>(
    'GET_OPEN_HOUSE_HOST_TYPES_ERROR',
);

export const getOpenHouseAudiences = createAction('GET_OPEN_HOUSE_AUDIENCES');
export const getOpenHouseAudiencesSuccess = createAction<{
    audiences: Array<IdNameTypes>;
}>('GET_OPEN_HOUSE_AUDIENCES_SUCCESS');
export const getOpenHouseAudiencesError = createAction<PayloadError[]>(
    'GET_OPEN_HOUSE_AUDIENCES_ERROR',
);

export const getCities = createAction<GetCitiesPayload>('GET_CITIES')
export const getCitiesSuccess = createAction<IdNameTypes[]>('GET_CITIES_SUCCESS')
export const getCitiesError = createAction<PayloadError[]>('GET_CITIES_ERROR')
