import { call, put, takeEvery } from 'redux-saga/effects';
import { changePrivateNote, changePrivateNoteError, changePrivateNoteSuccess } from '../actions';
import { IResponseWithoutValue } from '../../../../common/types';
import API from '../../../../common/api/api.base';
import { Alerter } from './../../../../common/utils/Alerter';

function* workerChangePrivateNote(action: ReturnType<typeof changePrivateNote>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            '/api/requests/change-private-note',
            action.payload,
        );
        if (result.success) {
            yield put(changePrivateNoteSuccess(result));
            Alerter.success('Private notes changed successfully');
        } else {
            yield put(changePrivateNoteError(result.errors));
        }
    } catch (error) {
        console.log(error, 'workerChangePrivateNote error');
    }
}

export default function* watchChangePrivateNoteSaga() {
    yield takeEvery(changePrivateNote.type, workerChangePrivateNote);
}
