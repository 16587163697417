import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { fonts } from '../../utils';

interface IMainPagePointProps {
    icon: JSX.Element;
    title: string;
    description: string;
    index: number;
}

export const MainPagePoint: React.FC<IMainPagePointProps> = ({
    description,
    icon,
    title,
    index,
}) => (
    <Box
        bg="#FFFDD0"
        borderRadius="16px"
        p={{ base: '20px', lg: '48px 35px' }}
        flex={1}
        zIndex={10}
        m={index === 1 ? { base: '25px 0', md: '0 25px' } : 0}
    >
        {icon}
        <Text
            fontSize={{ base: '16px', md: '20px' }}
            color="brand"
            lineHeight="34px"
            fontWeight="700"
            mt="30px"
            mb="16px"
            fontFamily={fonts.montserrat}
        >
            {title}
        </Text>
        <Text
            color="brand"
            fontSize="16px"
            lineHeight="26px"
            fontFamily={fonts.montserrat}
            fontWeight="400"
        >
            {description}
        </Text>
    </Box>
);
