import React, { useEffect } from 'react';
import { Box, Button, Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { CustomInput, CustomDropdown, DatePicker, TypeAhead } from '../../../common/components';
import { useForm } from 'react-hook-form';
import { SearchBy, FindAssistancePayloadType } from '../types';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import {
    agentPreferrences,
    getInspectionItems,
    TypeIds,
} from '../../../common/constants/dropdown.consatnts';
import { homeInspectionResolver } from '../../../common/schemes';
import {
    combineDateAndTime,
    formatHoursToDateTime,
    getPlaceInfo,
    carryAddressLoadOptions,
    agentLoadOptions,
    convertTimeIn24HoursFormat,
} from '../../../common/utils';
import { ClockIcon } from '../../../assets/icons';
import { SelectValue } from '../../../common/types';

type RequestInspectionFormValues = {
    dateTime: string;
    fromTime: string;
    states: string;
    address: string;
    searchBy: SearchBy;
    isShowingScheduled: string;
    clientTypeId: number;
    preferences: string;
    yesOrNo: string;
    privateNote: string;
    generalNote: string;
    longitude: number;
    latitude: number;
    clientPhone: string;
    clientName: string;
    startingAddress: string;
    preferredAgentId: string;
    approximateDuration: number;
};

interface IHomeInspectionFormProps {
    states: SelectValue[];
    searchBy: SearchBy;
    preferredAgentId?: string;
    filterStateCode: string;
    onFindAssistance: (data: FindAssistancePayloadType) => void;
    requestValue: TypeIds;
    assistanceLoading: boolean;
}

export const HomeInspectionForm: React.FC<IHomeInspectionFormProps> = ({
    states,
    searchBy,
    preferredAgentId,
    filterStateCode,
    onFindAssistance,
    requestValue,
    assistanceLoading,
}) => {
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        clearErrors,
        watch,
        reset,
    } = useForm<RequestInspectionFormValues>({
        resolver: homeInspectionResolver,
    });
    const history = useHistory();

    const onSubmit = (data: RequestInspectionFormValues) => {
        const fromTime = convertTimeIn24HoursFormat(data.fromTime);

        onFindAssistance({
            ...data,
            states: [data.states],
            addresses: [{ name: data.startingAddress }],
            dateTime: combineDateAndTime(
                dayjs(+data.dateTime).toISOString(),
                formatHoursToDateTime(fromTime).toISOString(),
            ),
            requestTypeId: requestValue,
            isSearchByLocation: searchBy === SearchBy.Location,
            preferredAgentId:
                preferredAgentId && data.preferences === '2'
                    ? preferredAgentId
                    : data.preferences === '3'
                    ? data.preferredAgentId
                    : null,
            isShowingScheduled:
                data.isShowingScheduled === 'Yes, I Have Scheduled The Home Inspection',
        });
        history.push('/app');
    };
    const [dateForm, preferrencesValue] = watch(['dateTime', 'preferences']);

    useEffect(() => {
        if (preferredAgentId) {
            reset({ preferences: '2' });
        }
    }, [preferredAgentId]);

    useEffect(() => {
        setValue('preferredAgentId', '');
    }, [preferrencesValue]);

    useEffect(() => {
        setValue('searchBy', searchBy);
    }, [searchBy]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={2} columnGap="60px">
                <GridItem>
                    <CustomDropdown
                        options={states?.map((item: any, i: any) => ({
                            ...item,
                            id: i,
                        }))}
                        label="State: *"
                        register={{ ...register('states') }}
                        errors={errors.states}
                        width="100%"
                        placeholder="Select"
                        margin={errors.states ? '0' : '0 0 26px 0'}
                    />
                    <DatePicker
                        label="Date: *"
                        displayValue={
                            searchBy === SearchBy.Location
                                ? dayjs.utc().format('MM/DD/YY')
                                : dateForm
                                ? dayjs.utc(+dateForm).format('MM/DD/YY')
                                : ''
                        }
                        value={Number(dateForm) || dayjs.utc().valueOf()}
                        setValue={value => {
                            setValue('dateTime', value.toString());
                            clearErrors('dateTime');
                        }}
                        removeBottomMargin
                        placeholder="MM/DD/YY"
                        errMessage={errors.dateTime?.message}
                        borderRadius="50px"
                        margin={errors.dateTime ? '0 10px 0 0' : '0 10px 26px 0'}
                        isDisabled={searchBy === SearchBy.Location}
                    />
                </GridItem>
                <GridItem>
                    <Flex justifyContent={'space-between'}>
                        <CustomInput
                            width={'47%'}
                            label="Time: *"
                            register={{ ...register('fromTime') }}
                            type="time-12-hours"
                            defaultValue="00:00 AM"
                            rightIcon={<ClockIcon color="#888DAB" />}
                            errors={errors.fromTime}
                        />
                        <CustomInput
                            width={'47%'}
                            label="Est. Duration: *"
                            placeholder="Enter"
                            register={{ ...register('approximateDuration') }}
                            errors={errors.approximateDuration}
                            type="number"
                            rightIcon={
                                <Text fontSize="16px" color="gray">
                                    h
                                </Text>
                            }
                        />
                    </Flex>
                </GridItem>
            </SimpleGrid>
            <Box w={'full'} mt={'26px'}>
                <TypeAhead
                    placeholder="Select location"
                    label={searchBy !== SearchBy.Location ? 'Address: *' : 'Starting Location: *'}
                    // stateFilterValue={filterStateCode}
                    loadOptions={carryAddressLoadOptions(false, filterStateCode)}
                    onStateChange={async ev => {
                        const placeInfo = ev?.value ? await getPlaceInfo(ev.value) : null;
                        if (placeInfo) {
                            setValue('latitude', placeInfo?.lat);
                            setValue('longitude', placeInfo?.lng);
                        } else {
                            setValue('latitude', 0);
                            setValue('longitude', 0);
                        }
                        setValue('startingAddress', ev?.label || '');
                        clearErrors('startingAddress');
                    }}
                    errors={errors.startingAddress?.message}
                />
            </Box>
            <SimpleGrid columns={2} columnGap={'60px'}>
                <GridItem>
                    <CustomInput
                        label="Description:"
                        width="100%"
                        placeholder="Enter"
                        register={{ ...register('generalNote') }}
                        errors={errors.generalNote}
                        margin={errors.generalNote ? '0' : '0 0 26px 0'}
                    />
                    <CustomDropdown
                        label="The Client is: *"
                        options={[
                            { id: 1, label: 'A Couple', value: '1' },
                            { id: 2, label: 'A Family', value: '2' },
                            { id: 3, label: 'An Individual', value: '3' },
                        ]}
                        placeholder="Select"
                        register={{ ...register('clientTypeId') }}
                        errors={errors.clientTypeId?.message}
                        width="100%"
                        margin={errors.clientTypeId ? '0' : '0 0 26px 0'}
                    />
                    <CustomInput
                        register={{ ...register('clientPhone') }}
                        label="Client Phone Number:"
                        placeholder="Enter"
                        errors={errors.clientPhone}
                        type="tel"
                        mask="999-999-9999"
                        margin={errors.clientPhone ? '0' : '0 0 26px 0'}
                    />
                </GridItem>
                <GridItem>
                    <CustomDropdown
                        options={getInspectionItems(TypeIds[requestValue])}
                        label={`Is the ${TypeIds[requestValue]} scheduled? *`}
                        register={{ ...register('isShowingScheduled') }}
                        errors={errors.isShowingScheduled}
                        placeholder="Select"
                        width="100%"
                        margin={errors.isShowingScheduled ? '0' : '0 0 26px 0'}
                    />
                    <CustomInput
                        label="Client Name"
                        width="100%"
                        placeholder="Enter"
                        register={{ ...register('clientName') }}
                        errors={errors.clientName}
                        margin={errors.clientName ? '0' : '0 0 26px 0'}
                    />
                    <CustomDropdown
                        label="I have a preference for: *"
                        options={
                            preferredAgentId
                                ? agentPreferrences
                                : agentPreferrences.filter(elem => elem.value !== '2')
                        }
                        placeholder="Select"
                        register={{ ...register('preferences') }}
                        errors={errors.preferences?.message}
                        width="100%"
                        margin={errors.preferences ? '0' : '0 0 26px 0'}
                    />
                    {preferrencesValue === '3' ? (
                        <TypeAhead
                            label="Previous Agent:"
                            placeholder="Previous Agent"
                            loadOptions={agentLoadOptions}
                            onStateChange={ev => {
                                setValue('preferredAgentId', ev?.value || '');
                                clearErrors('preferredAgentId');
                            }}
                            errors={errors.preferredAgentId?.message}
                        />
                    ) : null}
                </GridItem>
                <GridItem colSpan={2}>
                    <CustomInput
                        label="Private Notes to the Chosen Agent"
                        width="100%"
                        register={{ ...register('privateNote') }}
                        placeholder="Enter"
                        errors={errors.privateNote}
                        margin={errors.privateNote ? '0' : '0 0 26px 0'}
                    />
                </GridItem>
                <GridItem gridColumn={'2/3'}>
                    <Button
                        variant={'primary'}
                        isFullWidth
                        text="Search"
                        type="submit"
                        isLoading={assistanceLoading}
                    >
                        Search
                    </Button>
                </GridItem>
            </SimpleGrid>
        </form>
    );
};
