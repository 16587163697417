import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    Box,
    ModalHeader,
    Text,
    CloseButton,
    ModalBody,
    Flex,
    Button,
} from '@chakra-ui/react';

import { PaymentStatus } from '../../../../pages/Settings/pages/Payments/types';
import { CheckIcon } from '../../../../assets/icons';

interface IPaymentStatusModalProps {
    isOpen: boolean;
    onClose: () => void;
    paymentStatus: PaymentStatus;
    onRetry: () => void;
}

export const PaymentStatusModal: React.FC<IPaymentStatusModalProps> = ({
    isOpen,
    onClose,
    paymentStatus,
    onRetry,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent borderRadius="18px">
                <Flex w="100%" h="40vh" flexDirection="column">
                    <ModalHeader
                        d="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <CloseButton onClick={onClose} />
                    </ModalHeader>
                    <ModalBody overflowY="auto">
                        {typeof paymentStatus === 'string' ? (
                            <Flex h={'full'} alignItems={'center'} flexDirection={'column'}>
                                <Box>
                                    <Flex alignItems={'center'} justify={'center'}>
                                        {paymentStatus === 'success' ? (
                                            <CheckIcon color={'#4FD1C5'} />
                                        ) : (
                                            <CloseButton color={'#FE805C'} />
                                        )}

                                        <Text
                                            ml={'14px'}
                                            fontWeight={600}
                                            fontSize="21px"
                                            color={
                                                paymentStatus === 'success' ? '#4FD1C5' : '#FE805C'
                                            }
                                        >
                                            {paymentStatus === 'success' ? 'Success' : 'Failed'}
                                        </Text>
                                    </Flex>
                                    <Text
                                        fontSize="15px"
                                        fontWeight={400}
                                        mt={'20px'}
                                        color="brand"
                                    >
                                        {paymentStatus === 'success'
                                            ? 'Your payment was successfully Sent!'
                                            : ' Your payment was Denied! Please try a different payment method or contact your bank to resolve this issue.'}
                                    </Text>
                                </Box>
                                {paymentStatus === 'success' ? (
                                    <Button
                                        isFullWidth
                                        variant={'outlinePrimary'}
                                        mt={'44px'}
                                        onClick={onClose}
                                    >
                                        Ok
                                    </Button>
                                ) : (
                                    <Flex
                                        alignItems={'center'}
                                        justify={'space-between'}
                                        mt={'44px'}
                                        w={'full'}
                                    >
                                        <Button
                                            w={'47%'}
                                            variant={'outlinePrimary'}
                                            onClick={onClose}
                                        >
                                            Ok
                                        </Button>

                                        <Button w={'47%'} variant={'primary'} onClick={onRetry}>
                                            Retry
                                        </Button>
                                    </Flex>
                                )}
                            </Flex>
                        ) : null}
                    </ModalBody>
                </Flex>
            </ModalContent>
        </Modal>
    );
};
