import {
    getCalendarDataRequest,
    getCalendarDataSuccess,
    getCalendarDateError,
    getCalendarOpportunitiesRequest,
    getCalendarOpportunitiesSuccess,
    getCalendarOpportunitiesError,
    getCalendarOpenHousesRequest,
    getCalendarOpenHousesSuccess,
    getCalendarOpenHousesError,
    getCalendarAssistanceRequestsRequest,
    getCalendarAssistanceRequestsError,
    getCalendarAssistanceRequestsSuccess,
    getCalendarMyActivitiesRequest,
    getCalendarMyActivitiesSuccess,
    getCalendarMyActivitiesError,
    getCalendarOtherAgentActivitiesRequest,
    getCalendarOtherAgentsSuccess,
    getCalendarOtherAgentsActivitiesError,
} from './actions';
import { put, call, takeEvery } from 'redux-saga/effects';
import API from '../../../common/api/api.base';
import { IResponseWithCustomValue } from '../../../common/types';
import {
    CalendarItemsResponse,
    AvailableForApplicationResponse,
    OpenHousesResponse,
} from '../types';

function* workerGetCalendarData(action: ReturnType<typeof getCalendarDataRequest>) {
    try {
        const result: IResponseWithCustomValue<CalendarItemsResponse> = yield call(
            API.post,
            '/api/requests/calendar',
            action.payload,
        );
        if (result.success) {
            yield put(getCalendarDataSuccess(result.value));
        } else {
            yield put(getCalendarDateError(result.errors));
            throw result;
        }
    } catch (error) {
        console.log(error, 'workerGetCalendarData error');
    }
}

function* workerGetCalendarOpportunities(
    action: ReturnType<typeof getCalendarOpportunitiesRequest>,
) {
    try {
        const result: IResponseWithCustomValue<{
            totalCount: number;
            models: { startTime: string; models: AvailableForApplicationResponse[] }[];
        }> = yield call(
            API.post,
            '/api/requests/calendar/available-for-application',
            action.payload,
        );
        if (result.success) {
            yield put(
                getCalendarOpportunitiesSuccess({
                    models: result.value.models,
                    pageNumber: action.payload.pageNumber,
                    totalCount: result.value.totalCount,
                }),
            );
        } else {
            yield put(getCalendarOpportunitiesError(result.errors));
            throw result;
        }
    } catch (error) {
        console.log(error, 'workerGetCalendarOpportunities error');
    }
}

function* workerGetCalendarOpenHouses(action: ReturnType<typeof getCalendarOpenHousesRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            totalCount: number;
            models: { startTime: string; models: AvailableForApplicationResponse[] }[];
        }> = yield call(
            API.post,
            '/api/requests/calendar/available-for-application',
            action.payload,
        );

        if (result.success) {
            yield put(
                getCalendarOpenHousesSuccess({
                    models: result.value.models,
                    pageNumber: action.payload.pageNumber,
                    totalCount: result.value.totalCount,
                }),
            );
        } else {
            yield put(getCalendarOpenHousesError(result.errors));
            throw result;
        }
    } catch (error) {
        console.log(error, 'workerGetCalendarOpenHouses error');
    }
}

function* workerGetCalendarAssistanceRequests(
    action: ReturnType<typeof getCalendarAssistanceRequestsRequest>,
) {
    console.log(action.payload);
    try {
        const result: IResponseWithCustomValue<{
            totalCount: number;
            models: { startTime: string; models: AvailableForApplicationResponse[] }[];
        }> = yield call(
            API.post,
            'api/requests/calendar/available-for-application',
            action.payload,
        );
        if (result.success) {
            yield put(
                getCalendarAssistanceRequestsSuccess({
                    models: result.value.models,
                    pageNumber: action.payload.pageNumber,
                    totalCount: result.value.totalCount,
                }),
            );
        } else {
            yield put(getCalendarAssistanceRequestsError(result.errors));
            throw result;
        }
    } catch (error) {
        console.log(error, 'workerGetCalendarAssistanceRequests error');
    }
}

function* workerGetMyActivities(action: ReturnType<typeof getCalendarMyActivitiesRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            totalCount: number;
            models: { startTime: string; models: AvailableForApplicationResponse[] }[];
        }> = yield call(API.post, '/api/requests/calendar/my-activities', action.payload);
        if (result.success) {
            yield put(
                getCalendarMyActivitiesSuccess({
                    ...result.value,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getCalendarMyActivitiesError(result.errors));
        }
        console.log(result, 'workerGetMyActivities result');
    } catch (error) {
        console.log(error, 'workerGetMyActivities error');
    }
}

function* workerGetOtherAgentActivities(
    action: ReturnType<typeof getCalendarOtherAgentActivitiesRequest>,
) {
    try {
        const result: IResponseWithCustomValue<{
            totalCount: number;
            models: { startTime: string; models: AvailableForApplicationResponse[] }[];
        }> = yield call(API.post, '/api/requests/calendar/other-agent-activities', action.payload);
        if (result.success) {
            yield put(
                getCalendarOtherAgentsSuccess({
                    ...result.value,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getCalendarOtherAgentsActivitiesError(result.errors));
        }
    } catch (error) {
        console.log(error, 'workerGetOtherAgentActivities error');
    }
}

export default function* watchCalendarSaga() {
    yield takeEvery(getCalendarDataRequest.type, workerGetCalendarData);
    yield takeEvery(getCalendarOpportunitiesRequest.type, workerGetCalendarOpportunities);
    yield takeEvery(getCalendarOpenHousesRequest.type, workerGetCalendarOpenHouses);
    yield takeEvery(getCalendarAssistanceRequestsRequest.type, workerGetCalendarAssistanceRequests);
    yield takeEvery(getCalendarMyActivitiesRequest.type, workerGetMyActivities);
    yield takeEvery(getCalendarOtherAgentActivitiesRequest.type, workerGetOtherAgentActivities);
}
