import React from 'react';
import { Box, Divider, Flex, Stack } from '@chakra-ui/react';

import { SidebarDropdown } from './SidebarDropdown/SidebarDropdown';
import { SidebarButton } from '../../common/components/blocks/SidebarButton/SidebarButton';
import { SidebarAgencyButton } from '../../common/components/blocks/SidebarAgencyButton/SidebarAgencyButton';

import { SetCurrentAgencyPayloadType } from './types';
import {
    GetHelpNow,
    Activity,
    RequestsIcon,
    NewOpportunity,
    NewOffMarketNeed,
    NewBlast,
} from '../../assets/icons/SidebarIcons';

type ModalOptionsProps = {
    type: '' | 'getHelpNow' | 'newPostModal' | 'newBlast';
}

interface ISidebar {
    isRequestModalOpen: boolean;
    setIsRequestModalOpen: (arg0: boolean) => void;
    history: any;
    agencies: any;
    onSetCurrentAgency: (payload: SetCurrentAgencyPayloadType) => void;
    token: string;
    currentAgency: number;
    isAccessAllowed: boolean;
    setOpenModal: (type: ModalOptionsProps ) => void;
}

const Sidebar: React.FC<ISidebar> = ({
    agencies,
    onSetCurrentAgency,
    token,
    currentAgency,
    isAccessAllowed,
    setOpenModal,
}) => {
    return (
        <>
            <Box pl="20px" pr="30px" pt="25px">
                <Flex d="flex" alignItems="flex-start" flexDirection="column">
                    <Stack
                        spacing={3}
                        d="flex"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <SidebarDropdown
                            options={agencies}
                            onSetCurrentAgency={onSetCurrentAgency}
                            token={token}
                            currentAgency={currentAgency}
                        />
                    </Stack>
                    <Divider border="1px solid #CDDFEF" mt="35px" mb="35px" />
                    <Stack
                        spacing={3}
                        d="flex"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <SidebarButton
                            text="Get Help Now"
                            icon={<GetHelpNow />}
                            onClick={() => setOpenModal({ type: 'getHelpNow' })}
                            isAccessAllowed={isAccessAllowed}
                        />
                        <SidebarButton
                            text="+ New Blast"
                            icon={<NewBlast />}
                            onClick={() => setOpenModal({ type: 'newBlast' })}
                            isAccessAllowed={isAccessAllowed}
                        />
                        {/* <SidebarButton
                            text="+New Off-Market-Need"
                            icon={<NewOffMarketNeed />}
                            onClick={() => null}
                            isAccessAllowed={isAccessAllowed}
                        /> */}
                        <SidebarButton
                            text="+ New Opportunity"
                            icon={<NewOpportunity />}
                            isAccessAllowed={isAccessAllowed}
                            onClick={() => setOpenModal({ type: 'newPostModal' })}
                        />
                    </Stack>
                    <Divider borderBottom="1px solid #CDDFEF" mt="35px" mb="35px" />
                    <Stack
                        spacing={3}
                        d="flex"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <SidebarButton
                            text="Requests"
                            body="Sent and Received Requests"
                            asLink
                            href="/requests"
                            icon={<RequestsIcon />}
                            isAccessAllowed={isAccessAllowed}
                        />
                        <SidebarButton
                            href={'/activity'}
                            asLink
                            text="Activity"
                            body="Ongoing Activity"
                            icon={<Activity />}
                            isAccessAllowed={isAccessAllowed}
                        />
                    </Stack>
                    <Divider borderBottom="1px solid #CDDFEF" mt="35px" mb="35px" />
                    <SidebarAgencyButton
                        text="My Agency"
                        href="/my-agency"
                        isAccessAllowed={isAccessAllowed}
                    />
                    <SidebarAgencyButton
                        text="My Stuff"
                        href="/my-stuff"
                        isAccessAllowed={isAccessAllowed}
                    />
                </Flex>
            </Box>
            {/* {isRequestModalOpen ? (
                <Requests
                    isOpen={isRequestModalOpen}
                    onClose={() => setIsRequestModalOpen(false)}
                />
            ) : null} */}
        </>
    );
};

export default Sidebar;
