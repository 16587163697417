import React, { useEffect, useState } from 'react';
import { TransactionHistory } from './TransactionHistory';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../../store';
import { getTransactionHistory } from '../../store/actions';
import { GetTransactionHistoryPayload } from '../../types';
import { toggleUserProfileModal } from '../../../../../../store/actions/profile.actions';
import { ToggleUserProfileModalPayload } from '../../../../../../common/types';

interface ITransactionHistoryContainer {
    // tabIndex: number;
}

export const TransactionHistoryContainer: React.FC<ITransactionHistoryContainer> = ({
    // tabIndex,
}) => {
    const dispatch = useDispatch()
    const onGetTransactionHistory = (payload: GetTransactionHistoryPayload) => dispatch(getTransactionHistory(payload))
    const onOpenProfileImage = (payload: ToggleUserProfileModalPayload) => dispatch(toggleUserProfileModal(payload))

    const items = useSelector((state: RootStore) => state.payments.transactionHistory.items);
    const totalCount = useSelector((state: RootStore) => state.payments.transactionHistory.totalCount);

    const [page, setPage] = useState<number>(1)
    const [isIncome, setIsIncome] = useState<boolean>(true)
    const [tabIndex, setTabIndex] = useState<number>(1)
    const [onEndTransactionHistoryCalled, setEndTransactionHistoryCalled] = useState<boolean>(false)

    const loadNewTransactions = () => {
        if (items.length >= totalCount) {
            setEndTransactionHistoryCalled(true)
        } else if (!onEndTransactionHistoryCalled) {
            setPage((prev: number) => prev + 1)
        }
    }

    useEffect(() => {
        onGetTransactionHistory({
            pageNumber: page,
            pageSize: 10,
            isIncome: isIncome,
        })
    }, [page, isIncome])

    useEffect(() => {
        setPage(1)
        tabIndex === 1 ? setIsIncome(true) : setIsIncome(false)
    }, [tabIndex])


    return (
        <TransactionHistory
            loadNewTransactions={loadNewTransactions}
            items={items}
            setIsIncome={setIsIncome}
            isIncome={isIncome}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            onOpenProfileImage={onOpenProfileImage}
        />
    );
};
