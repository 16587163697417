import { Notification } from '../../pages/Notifications/types';
import {
    setNotificationsAvailableRequestModal,
    setNotificationsMyRequestsModal,
} from '../../pages/Notifications/store/actions';
import { Dispatch } from 'react';
import { getRequest } from '../../pages/Requests/store/actions';

export const handlePressNotification = (item: Notification, dispatch: Dispatch<any>) => {
    switch (item.notificationTypeId) {
        // request related
        case 2:
            if (item.assistRequestId) {
                dispatch(
                    setNotificationsMyRequestsModal({
                        id: item.assistRequestId,
                        isOpen: true,
                        toApplicants: false,
                    }),
                );
            }
            break;
        // new Repsonse to request
        case 3:
            if (item.assistRequestId) {
                dispatch(
                    setNotificationsMyRequestsModal({
                        id: item.assistRequestId,
                        isOpen: true,
                        toApplicants: true,
                    }),
                );
            }
            break;
        // are you available
        case 4:
            if (item.assistRequestId) {
                dispatch(
                    setNotificationsAvailableRequestModal({
                        isOpen: true,
                    }),
                );
                dispatch(getRequest(item.assistRequestId));
            }
            break;
        // deletion
        case 5:
            break;
        default:
            break;
    }
};
