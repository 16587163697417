import React from 'react';


export const SuspendIcon: React.FC = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5013 14.284V13.784H9.5013V14.284H10.5013ZM9.5013 14.2973V14.7973H10.5013V14.2973H9.5013ZM9.5013 5.61735V10.9507H10.5013V5.61735H9.5013ZM9.5013 14.284V14.2973H10.5013V14.284H9.5013ZM10.0013 19.1173C5.12279 19.1173 1.16797 15.1625 1.16797 10.284H0.167969C0.167969 15.7148 4.5705 20.1173 10.0013 20.1173V19.1173ZM18.8346 10.284C18.8346 15.1625 14.8798 19.1173 10.0013 19.1173V20.1173C15.4321 20.1173 19.8346 15.7148 19.8346 10.284H18.8346ZM10.0013 1.45068C14.8798 1.45068 18.8346 5.4055 18.8346 10.284H19.8346C19.8346 4.85322 15.4321 0.450684 10.0013 0.450684V1.45068ZM10.0013 0.450684C4.5705 0.450684 0.167969 4.85322 0.167969 10.284H1.16797C1.16797 5.4055 5.12279 1.45068 10.0013 1.45068V0.450684Z" fill="#F2C94C"/>
    </svg>


);
