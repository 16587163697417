import { createSlice } from '@reduxjs/toolkit';
import { PayloadError } from '../../../common/types';
import { writeToSupportRequest, writeToSupportSuccess, writeToSupportError } from './actions';

type InitialState = {
    errors: PayloadError[];
    loading: boolean;
};

export const initialState: InitialState = {
    errors: [],
    loading: false,
};

const writeToSupportSlice = createSlice({
    name: 'writeToSupport',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(writeToSupportRequest, state => {
                state.errors = [];
                state.loading = true;
            })
            .addCase(writeToSupportSuccess, state => {
                state.loading = false;
            })
            .addCase(writeToSupportError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            });
    },
});

export default writeToSupportSlice.reducer;
