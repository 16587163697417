import React from 'react'
import { EditPaymentMethodForm, PaymentsModalWrapper} from "../components";

interface IEditPaymentMethodModalProps {
    isOpen:boolean
    title: string
    onClose: () => void
    fundingSourceId:string
    editPaymentMethodLoading: boolean

}

export const EditPaymentMethodModal:React.FC<IEditPaymentMethodModalProps> = ({
    isOpen,
    title,
    onClose,
    fundingSourceId,
    editPaymentMethodLoading

}) => {
    return (
        <PaymentsModalWrapper
            isCentered
            isOpen={isOpen}
            title={title}
            onClose={onClose}
        >
            <EditPaymentMethodForm
                fundingSourceId={fundingSourceId}
                editPaymentMethodLoading={editPaymentMethodLoading}/>

        </PaymentsModalWrapper>
    )
}
