import React, { useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Box,
    Flex,
    CloseButton,
    Text,
} from '@chakra-ui/react';
import { colors } from '../../../../../common/constants';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../../../store';
import { Loader, OpenHouseInfoSection } from '../../../../../common/components';
import { getListingInfo } from '../../../../../store/actions/listings.actions';

interface IAgentListingInfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    listingId: number;
}

export const AgentListingInfoModal: React.FC<IAgentListingInfoModalProps> = ({
    isOpen,
    onClose,
    listingId,
}) => {
    const dispatch = useDispatch();

    const { loading, value: listingInfo } = useSelector(
        (state: RootStore) => state.listings.listingInfo,
    );

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    useEffect(() => {
        if (isOpen) {
            dispatch(getListingInfo(listingId));
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent
                h="85vh"
                borderRadius="18px"
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <ModalHeader display="flex" flexDirection="row" w="100%" alignItems="center" justifyContent="space-between">
                    <Text
                        fontWeight="bold"
                        fontSize="16px"
                        lineHeight="24px"
                        color="brand"
                    >
                        Request
                    </Text>
                    <CloseButton onClick={onClose} />
                </ModalHeader>
                <ModalBody d="flex" flexDirection="column" w="100%">
                    <>
                        {loading ? (
                            <Loader centerHeight="100%" />
                        ) : (
                            <Flex px="20px">
                                <OpenHouseInfoSection openHouseInfo={listingInfo} />
                                <Box flexShrink={0} width="35%">
                                    <Text
                                        fontWeight="bold"
                                        fontSize="16px"
                                        lineHeight="24px"
                                        color="brand"
                                        mb="10px"
                                    >
                                        Details
                                    </Text>
                                    {isLoaded ? (
                                        <GoogleMap
                                            options={{ disableDefaultUI: true }}
                                            mapContainerStyle={{
                                                height: '275px',
                                                width: '100%',
                                                borderRadius: '20px',
                                            }}
                                            center={{
                                                lat: Number(listingInfo?.latitude),
                                                lng: Number(listingInfo?.longitude),
                                            }}
                                            zoom={10}
                                        >
                                            <Marker
                                                position={{
                                                    lat: Number(listingInfo?.latitude),
                                                    lng: Number(listingInfo?.longitude),
                                                }}
                                            />
                                        </GoogleMap>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                            </Flex>
                        )}
                    </>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
