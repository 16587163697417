import React from 'react';
import {
    Box,
    Button, Center,
    CloseButton, Flex, HStack,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay, Text
} from "@chakra-ui/react";
import {Typography} from "../../blocks/Typography/Typography";
import {AppleIcon, BackArrow, GooglePlayIcon, ListCheckmark} from "../../../../assets/icons";
import {fonts} from "../../../utils";

interface ISubscriptionsModal{
    isOpen: boolean
    onClose: () => void
}

export const points = [
    'Showing Assistance',
    'Home Inspection Assistance',
    'Listings Management',
    'Open House',
    'Agency Networking',
];


const PriceInfoBox:React.FC<{type?:"month"|"year"}> = ({type= 'month'}) => (
    <Flex
        borderRadius={'26px'}
        bg={type === 'month' ? '#867AFA' : '#F2C84C'}
        h={'120px'}
        w={'47%'}
        flexDirection={'column'}
        justify={'center'}
        align={'center'}
    >
        <Typography
            lineHeight={'46px'}
            size={'39px'}
            weight={800}
            color={'white'}
            fontFamily={fonts.avenirRoman}
        >
            ${`${type === 'month'? '7.99' : '59.99'}`}

        </Typography>
        <Typography
            lineHeight={'20px'}
            size={'15px'}
            weight={500}
            color={'white'}
            fontFamily={fonts.avenirRoman}
        >
            {`${type === 'month'? '/month' : '/year'}`}

        </Typography>


    </Flex>
)

export const SubscriptionsModal:React.FC<ISubscriptionsModal> = ({
    isOpen,
    onClose

}) => {
    return (
        <Modal
            isCentered
            // onOverlayClick={onClose}
            isOpen={isOpen}
            onClose={onClose}
            size={'3xl'}
        >
            <ModalOverlay/>
            <ModalContent  h={'80vh'}>
                <ModalHeader
                    pt={'38px'}
                    px={'38px'}
                    d="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                >
                    <Button
                        leftIcon={<BackArrow />}
                        variant="unstyled"
                        fontSize="14px"
                        color="secondaryFontDarker"
                        fontWeight="normal"
                        onClick={onClose}

                    />
                    <Typography size={22} weight={700} lineHeight={'18px'}>
                        Subscription
                    </Typography>
                    <CloseButton onClick={onClose} />
                </ModalHeader>

                <ModalBody overflow={'auto'} p={'0px'}>
                    <Box px={'38px'} pb={'38px'} mt={'48px'}>
                        <Typography size={'18px'} weight={600} lineHeight={'27px'}>
                            You don’t have subscription yet. Download the app First to subscribe
                        </Typography>
                        <HStack mt="15px" mb={'40px'}>
                            <Button
                                leftIcon={<GooglePlayIcon />}
                                bg="rgba(44, 51, 92, 0.1)"
                                borderRadius="25px"
                                height="44px"
                                color="brand"
                                fontSize="12px"
                                fontWeight={400}
                                letterSpacing="0.1em"
                                w="160px"
                                as={Link}
                                target={'_blank'}
                                href="https://play.google.com/store/apps/details?id=com.zootpro"
                            >
                                Google Play
                            </Button>
                            <Button
                                leftIcon={<AppleIcon />}
                                bg="rgba(44, 51, 92, 0.1)"
                                borderRadius="25px"
                                height="44px"
                                color="brand"
                                fontSize="12px"
                                fontWeight={400}
                                letterSpacing="0.1em"
                                w="160px"
                                as={Link}
                                target={'_blank'}
                                href="https://apps.apple.com/app/zootpro/id1601250757"
                            >
                                App Store
                            </Button>
                        </HStack>
                        <Typography size={18} weight={600} lineHeight={'27px'}>
                            Available options for Subscription
                        </Typography>
                        <Box my={'30px'} px={'34px'} py={'14px'} bg={'#4FD1C5'} borderRadius={'26px'}>
                            <Typography
                                color={'white'}
                                weight={800}
                                size={'20px'}
                                fontFamily={fonts.avenirRoman}
                            >
                                Subscription Include:
                            </Typography>
                            {points.map(elem => (
                                <HStack key={elem} mt="10px">
                                    <Center w="20px" h="20px" borderRadius="10px" bg="rgba(255,255,255, .1)">
                                        <ListCheckmark />
                                    </Center>
                                    <Typography
                                        color="white"
                                        size={15}
                                        lineHeight="20px"
                                        fontWeight={500}
                                        fontFamily={fonts.avenirRoman}
                                    >
                                        {elem}
                                    </Typography>
                                </HStack>
                            ))}

                        </Box>

                        <Flex justify={'space-between'}>
                            <PriceInfoBox/>
                            <PriceInfoBox type={'year'}/>

                        </Flex>

                    </Box>

                </ModalBody>
            </ModalContent>

        </Modal>
    );
};
