import React from 'react';
import { PieChart as ReactPieChart } from 'react-minimal-pie-chart';
import { Box, Flex } from '@chakra-ui/react';
import { PieChartLegendItem } from './PieChartLegendItem';
import { ProfileImageType } from '../../../../../common/types';
import { colors } from '../../../../constants';

interface IPieChartProps {
    userProfileImage: ProfileImageType;
}

export const PieChart: React.FC<IPieChartProps> = ({ userProfileImage }) => {
    const getPercentsFromNumber = () => {
        const assistHired = userProfileImage?.assistHired;
        const assistGiven = userProfileImage?.assistGiven;
        const openHousesMade = userProfileImage?.openHousesMade;
        const openHousesConducted = userProfileImage?.openHousesConducted;
        const sum = assistHired + assistGiven + openHousesMade + openHousesConducted;

        const assistHiredPercents = assistHired !== 0 ? (assistHired * 100) / sum : 0;
        const assistGivenPercents = assistGiven !== 0 ? (assistGiven * 100) / sum : 0;
        const openHousesMadePercents = openHousesMade !== 0 ? (openHousesMade * 100) / sum : 0;
        const openHousesConductedPercents =
            openHousesConducted !== 0 ? (openHousesConducted * 100) / sum : 0;

        if (sum !== 0) {
            return [
                {
                    value: assistHiredPercents,
                    color: colors.pink,
                    title: 'Assistance Hired',
                    legendValue: assistHired,
                },
                {
                    value: assistGivenPercents,
                    color: colors.cherry,
                    title: 'Assistance Given',
                    legendValue: assistGiven,
                },
                {
                    value: openHousesMadePercents,
                    color: colors.azure,
                    title: 'Open Houses Done',
                    legendValue: openHousesMade,
                },
                {
                    value: openHousesConductedPercents,
                    color: colors.lime,
                    title: 'Open Houses Made',
                    legendValue: openHousesConducted,
                },
            ];
        }

        return [{ value: 100, color: 'white', title: 'None', legendValue: 0 }];
    };

    return (
        <Flex alignItems="center" justify="space-between" mt="30px">
            <Box>
                {getPercentsFromNumber().map(elem => (
                    <PieChartLegendItem
                        key={elem.color}
                        text={elem.title}
                        color={elem.color}
                        number={elem.legendValue}
                    />
                ))}
            </Box>
            <Box h="100%" w="200px">
                <ReactPieChart segmentsShift={0.15} data={getPercentsFromNumber()} />
            </Box>
        </Flex>
    );
};
