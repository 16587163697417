import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../common/api/api.base';

import {
    getOpenHouseInfo,
    getOpenHouseInfoSuccess,
    getOpenHouseInfoError,
    getMyListings,
    getMyListingsSuccess,
    getMyListingsError,
    getMyClients,
    getMyClientsSuccess,
    getMyClientsError,
    addListingTimeSlot,
    addListingTimeSlotSuccess,
    addListingTimeSlotError,
    recreateExternalTimeSlot,
    recreateExternalTimeSlotSuccess,
    recreateExternalTimeSlotError,
    updateListingTimeSlot,
    updateListingTimeSlotSuccess,
    updateListingTimeSlotError,
    deleteListingTimeSlot,
    deleteListingTimeSlotSuccess,
    deleteListingTimeSlotError,
    getListingInfo,
    getListingInfoSuccess,
    getListingInfoError,
    getAgentOpenHouses,
    getAgentOpenHousesSuccess,
    getAgentOpenHousesError,
    getListingTypesSuccess,
    getListingTypesError,
    getListingTypesRequest,
    getAgencyListingsRequest,
    getAgencyListingsSuccess,
    getAgencyListingsError,
    getAgencyRoasterRequest,
    getAgencyRoasterSuccess,
    getAgencyRoasterError,
    getAgencyCities,
    getAgencyCitiesSuccess,
    getAgencyCitiesError,
    selectMultipleTimeSlotsRequest,
    selectMutlipleTimeSlotsSuccess,
    selectMultipleTimeSlotsError,
} from '../actions/listings.actions';
import {
    GetOpenHouseInfoSuccessResponse,
    ListingsType,
    PersonCardType,
    ListingInfoType,
    GetListingTypesResponse,
    GetMyListingsResponse,
    GetMyClientsResponse,
    IResponseWithCustomValue,
    IResponseWithoutValue,
} from '../../common/types';

function* workerGetOpenHouseInfo(action: ReturnType<typeof getOpenHouseInfo>) {
    try {
        const result: IResponseWithCustomValue<GetOpenHouseInfoSuccessResponse> = yield call(
            API.get,
            `/api/listings/openhouses/${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getOpenHouseInfoSuccess(result));
        } else {
            yield put(getOpenHouseInfoError(result.errors));
        }
    } catch (error) {
        console.log('workerGetOpenHouseInfo error', error);
    }
}

function* workerGetMyListings(action: ReturnType<typeof getMyListings>) {
    try {
        const result: IResponseWithCustomValue<GetMyListingsResponse> = yield call(
            API.get,
            `/api/listings/get-my-listings`,
            {},
        );
        if (result.success) {
            yield put(getMyListingsSuccess(result.value));
        } else {
            yield put(getMyListingsError(result.errors));
        }
    } catch (error) {
        console.log('workerGetMyListings error', error);
    }
}

function* workerGetMyClients(action: ReturnType<typeof getMyClients>) {
    try {
        const result: IResponseWithCustomValue<GetMyClientsResponse> = yield call(
            API.get,
            `/api/agency/get-my-clients`,
            action.payload,
        );
        if (result.success) {
            yield put(
                getMyClientsSuccess({ ...result.value, pageNumber: action.payload.pageNumber }),
            );
        } else {
            yield put(getMyClientsError(result.errors));
        }
    } catch (error) {
        console.error('workerGetMyClients error', error);
    }
}

function* workerGetAgencyListings(action: ReturnType<typeof getAgencyListingsRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            listings: Array<ListingsType>;
            totalCount: number;
        }> = yield call(API.post, '/api/listings/agency', action.payload);
        if (result.success) {
            yield put(
                getAgencyListingsSuccess({
                    ...result.value,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getAgencyListingsError(result.errors));
        }
    } catch (error) {
        console.log('workerGetAgencyListings error', error);
    }
}

function* workerGetAgencyRoaster(action: ReturnType<typeof getAgencyRoasterRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            users: Array<PersonCardType>;
            totalCount: number;
        }> = yield call(API.get, '/api/agency/get-current-agency-users', action.payload);
        if (result.success) {
            yield put(
                getAgencyRoasterSuccess({
                    ...result.value,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getAgencyRoasterError(result.errors));
        }
    } catch (error) {
        console.error('workerGetAgencyRoaster error', error);
    }
}

function* workerAddListingTimeSlot(action: ReturnType<typeof addListingTimeSlot>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            '/api/listings/add-time-slots',
            action.payload,
        );
        if (result.success) {
            yield put(addListingTimeSlotSuccess(action.payload.listingId));
        } else {
            yield put(addListingTimeSlotError(result.errors));
        }
    } catch (error) {
        console.log('addListingTimeSlotError', error);
    }
}

function* workerRecreateExternalTimeSlot(action: ReturnType<typeof recreateExternalTimeSlot>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            '/api/listings/recreate-external-time-slot',
            action.payload,
        );
        if (result.success) {
            yield put(recreateExternalTimeSlotSuccess(action.payload.listingId));
        } else {
            yield put(recreateExternalTimeSlotError(result.errors));
        }
    } catch (error) {
        console.log('recreateExternalTimeSlotError', error);
    }
}

function* workerUpdateListingTimeSlot(action: ReturnType<typeof updateListingTimeSlot>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.patch,
            '/api/listings/time-slot',
            action.payload,
        );
        if (result.success) {
            yield put(updateListingTimeSlotSuccess());
        } else {
            yield put(updateListingTimeSlotError(result.errors));
        }
    } catch (error) {
        console.log('updateListingTimeSlotError', error);
    }
}

function* workerDeleteListingTimeSlot(action: ReturnType<typeof deleteListingTimeSlot>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.delete,
            `/api/listings/time-slot?Id=${action.payload.id}&ListingId=${action.payload.listingId}`,
        );
        if (result.success) {
            yield put(deleteListingTimeSlotSuccess(action.payload));
        } else {
            yield put(deleteListingTimeSlotError(result.errors));
        }
    } catch (error) {
        console.log('deleteListingTimeSlotError', error);
    }
}

function* workerGetListingInfo(action: ReturnType<typeof getListingInfo>) {
    try {
        const result: IResponseWithCustomValue<{ listing: ListingInfoType }> = yield call(
            API.get,
            `/api/listings/get-listing-by-id?ListingId=${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getListingInfoSuccess(result.value.listing));
        } else {
            yield put(getListingInfoError(result.errors));
        }
    } catch (error) {
        console.log('getListingInfoError', error);
    }
}

function* workerGetAgentOpenHouses(action: ReturnType<typeof getAgentOpenHouses>) {
    try {
        const result: IResponseWithCustomValue<{ listings: ListingsType[]; totalCount: number }> =
            yield call(
                API.get,
                `/api/agents/${action.payload.id}/listings/?showAll=${action.payload.showAll}`,
                {},
            );
        console.log(result, action, 'GetAgentOpenHousesSuccessResponse');
        if (result.success) {
            yield put(getAgentOpenHousesSuccess(result.value));
        } else {
            yield put(getAgentOpenHousesError(result.errors));
        }
    } catch (error) {
        console.log('getAgentOpenHousesError', error);
    }
}

function* workerGetListingTypes(action: ReturnType<typeof getListingTypesRequest>) {
    try {
        const result: IResponseWithCustomValue<GetListingTypesResponse> = yield call(
            API.get,
            '/api/listings/get-listing-types',
            {},
        );
        if (result.success) {
            yield put(getListingTypesSuccess(result.value));
        } else {
            yield put(getListingTypesError(result.errors));
        }
    } catch (error) {
        console.log('getListingTypesError', error);
    }
}

function* workerGetAgencyCities() {
    try {
        const result: IResponseWithCustomValue<{
            cities: Array<string>;
        }> = yield call(API.get, '/api/listings/agency-cities', {});
        if (result.success) {
            yield put(getAgencyCitiesSuccess(result.value.cities));
        } else {
            yield put(getAgencyCitiesError(result.errors));
        }
    } catch (err) {
        console.log('workerGetAgencyCities error', err);
    }
}

function* workerSelectMultipleTimeSlots(action: ReturnType<typeof selectMultipleTimeSlotsRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            '/api/listings/select-multiple-time-slots',
            { timeSlots: action.payload },
        );
        if (result.success) {
            yield put(selectMutlipleTimeSlotsSuccess());
        } else {
            yield put(selectMultipleTimeSlotsError(result.errors));
        }
    } catch (error) {
        console.log('workerSelectMultipleTimeSlots error', error);
    }
}

export default function* watchListingsSaga() {
    yield takeEvery(getOpenHouseInfo.type, workerGetOpenHouseInfo);
    yield takeEvery(getMyListings.type, workerGetMyListings);
    yield takeEvery(getMyClients.type, workerGetMyClients);
    yield takeEvery(addListingTimeSlot.type, workerAddListingTimeSlot);
    yield takeEvery(recreateExternalTimeSlot.type, workerRecreateExternalTimeSlot);
    yield takeEvery(updateListingTimeSlot.type, workerUpdateListingTimeSlot);
    yield takeEvery(deleteListingTimeSlot.type, workerDeleteListingTimeSlot);
    yield takeEvery(getListingInfo.type, workerGetListingInfo);
    yield takeEvery(getAgentOpenHouses.type, workerGetAgentOpenHouses);
    yield takeEvery(getListingTypesRequest.type, workerGetListingTypes);
    yield takeEvery(getAgencyListingsRequest.type, workerGetAgencyListings);
    yield takeEvery(getAgencyRoasterRequest.type, workerGetAgencyRoaster);
    yield takeEvery(getAgencyCities.type, workerGetAgencyCities);
    yield takeEvery(selectMultipleTimeSlotsRequest.type, workerSelectMultipleTimeSlots);
}
