//transformStringToDate
import dayjs from "dayjs";

export const transformStringToDate = (str: string) =>
   dayjs(new Date())
            .set('hour', +str.split(':')[0] + 2)
            .set('minute', +str.split(':')[1])
            .set('seconds', 0)
            .set('milliseconds', 0)
            .toISOString()

export const createWorkHoursItem = (dayNumber:number,fromTime:string, toTime:string) => {
    return {
        dayOfWeek: dayNumber,
        from: fromTime ? transformStringToDate(fromTime) : null,
        to: toTime ? transformStringToDate(toTime) : null
    }
}
