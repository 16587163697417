import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../common/api/api.base';
import {
    getUserProfileImage,
    getUserProfileImageError,
    getUserProfileImageSuccess,
    changeAdditionalProfileInfo
} from '../actions/profile.actions';
import { getUserInfoRequest } from '../actions/user.actions';
import {
    IResponseWithCustomValue,
    ProfileImageType,
    IResponseWithoutValue,
} from '../../common/types';
import { Alerter } from './../../common/utils';

function* workerGetUserProfileImage(action: ReturnType<typeof getUserProfileImage>) {
    try {
        const result: IResponseWithCustomValue<{ profileImage: ProfileImageType }> = yield call(
            API.get,
            `/api/profile/get-user-profile-image?id=${action.payload}`,
            {},
        );
        console.log('getUserProfileImage', result, action);
        if (result.success) {
            yield put(getUserProfileImageSuccess(result.value.profileImage));
        } else {
            yield put(getUserProfileImageError({ errors: result.errors, userId: action.payload }));
        }
    } catch (error) {
        console.log('getUserProfileImageError', error);
    }
}

function* workerChangeAdditionalProfleInfo(action: ReturnType<typeof changeAdditionalProfileInfo>) {
    const data = action.payload;
    try {
        const result: IResponseWithoutValue = yield call(API.put, `/api/profile/additional`, {
            firstLicensedYear: data.FirstLicensedYear,
            websiteLink: data.WebsiteLink,
            biography: data.Biography,
        });
        console.log('CHANGE ADDITIONAL INFO', action.payload, result);
        if (result.success) {
            // Alerter.success(result.messages[0].messageText);
            yield put(getUserInfoRequest());
        }
    } catch (error) {
        Alerter.error('Something went wrong, try again later');
    }
}

export default function* watchProfileSaga() {
    yield takeEvery(getUserProfileImage.type, workerGetUserProfileImage);
    yield takeEvery(changeAdditionalProfileInfo.type, workerChangeAdditionalProfleInfo);
}

