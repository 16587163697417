import React, { ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { fonts } from '../../../common/utils';
import { colors } from '../../../common/constants';

type BorderIconBoxType = {
    text: string;
    icon: ReactElement;
    isFirst?: boolean;
};

export const BorderIconBox: React.FC<BorderIconBoxType> = ({ text, icon, isFirst }) => (
    <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="0 20px"
        borderRight="1px solid black"
        borderLeft={isFirst ? '1px solid black' : 'none'}
        borderColor={colors.borderGray}
        minW="100px"
    >
        {icon}
        <Text
            mt={2}
            color="brand"
            fontWeight={500}
            fontSize="12px"
            lineHeight="18px"
            fontFamily={fonts.montserrat}
        >
            {text}
        </Text>
    </Flex>
);
