import React, { useCallback, useEffect, useState } from 'react';
import { CustomModalWrapper, ImageUpload, InputTextArea } from '../../components';
import { Box, HStack, ModalBody, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { CustomButton, CustomInput, Loader } from '../../../../common/components';
import createPostResolver, { CreatePostFormValue } from '../validation/createPost.schema';
import { Resolver, useForm } from 'react-hook-form';
import { colors } from '../../../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getPostTags } from '../../../../store/actions/directories.actions';
import { RootStore } from '../../../../store';
import { IdNameTypes } from '../../../../common/types';
import { CreatePostPayload } from '../../types';

interface IProps {
    size: string;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    onCreatePost: (payload: CreatePostPayload) => void;
    createPostLoading: boolean;
}

export const CreateNewPostModal: React.FC<IProps> = ({
    size,
    isOpen,
    onClose,
    title,
    onCreatePost,
    createPostLoading,
}) => {
    const dispatch = useDispatch();

    const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
    const [uploadedFile, setUploadedFile] = useState<string | ArrayBuffer | null>(null);
    const [photoError, setPhotoError] = useState('');

    const { postTags, loading } = useSelector((state: RootStore) => state.directories);

    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        setValue,
    } = useForm<CreatePostFormValue>({
        resolver: createPostResolver as Resolver<CreatePostFormValue>,
    });
    const onSubmit = (data: CreatePostFormValue) => {
        console.log(data.photo, 'data.photo');
        const formData = new FormData();
        formData.append('Photo', data.photo);
        onCreatePost({
            Tags: selectedTags,
            Photo: formData,
            Title: data.title,
            Body: data.body,
        });
    };

    const onDrop = useCallback(acceptedFiles => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (allowedTypes.includes(acceptedFiles[0].type)) {
            setUploadedFile(acceptedFiles[0]);
            setValue('photo', acceptedFiles[0]);
            setPhotoError('');
        } else {
            setUploadedFile(null);
            setPhotoError('Incorrect file format. Please select .jpg, .jpeg or .png');
        }
    }, []);

    useEffect(() => {
        dispatch(getPostTags());
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setUploadedFile(null);
            setSelectedTags([]);
            setPhotoError('');
            reset({});
        }
    }, [isOpen]);

    return (
        <CustomModalWrapper size={size} isOpen={isOpen} title={title} onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <HStack spacing={'55px'} align={'baseline'}>
                        {/*LEFT SIDE*/}
                        <Box w={'100%'}>
                            <CustomInput
                                register={register('title')}
                                placeholder={'Write Post Title'}
                                label={'Post title'}
                                errors={errors.title}
                            />
                            <InputTextArea
                                {...register('body')}
                                label={'Post Text'}
                                maxLength={1000}
                                errorMsg={errors.body?.message}
                            />
                        </Box>
                        {/*RIGHT SIDE*/}
                        <Box w={'100%'}>
                            {/*TAGS CONTAINER*/}
                            <Text fontWeight={400} fontSize={'14px'} lineHeight={'21px'}>
                                Tags
                            </Text>
                            <Box
                                mb={'15px'}
                                bgColor={colors.inputBackground}
                                borderRadius={'24px'}
                                p={'16px'}
                            >
                                {loading ? (
                                    <Loader centerHeight={'100%'} spinnerSize={'md'} />
                                ) : (
                                    <Wrap spacing={'12px'}>
                                        {postTags.map((tag: IdNameTypes) => {
                                            const isSelected = selectedTags.includes(tag.id);
                                            return (
                                                <WrapItem key={tag.id}>
                                                    <Tag
                                                        onClick={() => {
                                                            if (!selectedTags.includes(tag.id)) {
                                                                setSelectedTags(prev => [
                                                                    ...prev,
                                                                    tag.id,
                                                                ]);
                                                            } else {
                                                                setSelectedTags(prev =>
                                                                    prev.filter(t => t !== tag.id),
                                                                );
                                                            }
                                                        }}
                                                        type={'button'}
                                                        as={'button'}
                                                        bg={isSelected ? colors.brand : 'none'}
                                                        color={
                                                            isSelected
                                                                ? colors.white
                                                                : colors.secondaryFontDarker
                                                        }
                                                        fontSize={'14px'}
                                                        p={'12px 16px'}
                                                        variant={'outline'}
                                                        h={'40px'}
                                                        borderRadius={'60px'}
                                                    >
                                                        {tag.name}
                                                    </Tag>
                                                </WrapItem>
                                            );
                                        })}
                                    </Wrap>
                                )}
                            </Box>
                            {/* IMAGE UPLOAD*/}
                            <ImageUpload
                                setUploadedFile={setUploadedFile}
                                onDrop={onDrop}
                                uploadedFile={uploadedFile}
                            />
                            {photoError && (
                                <Text mt={'8px'} fontSize={'12px'} color={'#EC7474'}>
                                    {photoError}
                                </Text>
                            )}
                            {/*Submit Post Button*/}
                            <Box mt={'30px'}>
                                <CustomButton
                                    isLoading={createPostLoading}
                                    bgColor="brand"
                                    height={'50px'}
                                    type={'submit'}
                                    text={'Post'}
                                    borderRadius="4px"
                                    fontWeight={300}
                                />
                            </Box>
                        </Box>
                    </HStack>
                </ModalBody>
            </form>
        </CustomModalWrapper>
    );
};
