import React from 'react';

import {
    Box,
    Image,
    Text,
    Heading,
    Stack,
    ListItem,
    UnorderedList,
    useMediaQuery,
} from '@chakra-ui/react';

import { fonts } from '../../../../common/utils';
import { colors } from '../../../../common/constants';

import accentDots from '../../../../assets/landing/accent_dots.svg';

interface IWhyBlockProps {
    position: string;
    image: string;
    title?: string;
    paragraphs: string[];
    hasDots?: 'top' | 'bottom';
}

export const WhyBlock: React.FC<IWhyBlockProps> = ({
    position,
    image,
    title,
    paragraphs,
    hasDots,
}) => {
    const [isMobile] = useMediaQuery('(max-width: 561px)');
    const [dotsChanging] = useMediaQuery('(max-width: 991px)');

    const renderDots = () => {
        if (hasDots === 'top') {
            if (dotsChanging) {
                return (
                    <Box position="absolute" bottom="-30px" right="-20px" zIndex={-1}>
                        <Image src={accentDots} />
                    </Box>
                );
            }

            return (
                <Box position="absolute" top="-80px" left="-50px" zIndex={-1}>
                    <Image src={accentDots} />
                </Box>
            );
        }

        if (hasDots === 'bottom' && !dotsChanging) {
            return (
                <Box position="absolute" bottom="-40px" right="-40px" zIndex={-1}>
                    <Image src={accentDots} />
                </Box>
            );
        }

        return null;
    };

    return (
        <Stack
            marginTop={{ base: '0', lg: '50px' }}
            mb={{ base: '30px', lg: '0' }}
            padding={{ base: '0', lg: '40px 0' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            spacing={{ base: '30px', lg: '75px' }}
            direction={{ base: 'column', lg: position === 'right' ? 'row' : 'row-reverse' }}
            position="relative"
        >
            <Box d="flex" flexDir="column" flex={1} p="0" w={{ base: '100%', lg: '50%' }}>
                {title && (
                    <Heading
                        color={colors.brand}
                        fontFamily={fonts.mulish}
                        fontSize={{ base: '18px', sm: '20px' }}
                        lineHeight="18px"
                        fontWeight="700"
                    >
                        {title}
                    </Heading>
                )}

                {paragraphs.length && (
                    <Text
                        color={colors.brand}
                        fontFamily={fonts.mulish}
                        fontSize="15px"
                        lineHeight="25px"
                        fontWeight="400"
                    >
                        <UnorderedList>
                            {paragraphs.map((text, index) => (
                                <ListItem
                                    key={index}
                                    mb={index === paragraphs.length - 1 ? '0' : '20px'}
                                >
                                    {text}
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </Text>
                )}
            </Box>

            <Box position="relative" h="fit-content" w={{ base: '100%', lg: '50%' }} maxW="561px">
                <Image src={image} />

                {renderDots()}
            </Box>
        </Stack>
    );
};
