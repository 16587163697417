import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {ErrorMessages} from "../../../../../../common/schemes/messages";

export type VerifyMicroDepositsFormValues = {
    firstDepositValue: number,
    secondDepositValue: number
};


const verifyMicroDepositsResolver = yup.object().shape({
    firstDepositValue: yup.number()
        .transform((_, originValue) => Number(originValue.replace(/,/, '.')))
        .required(ErrorMessages.REQUIRED)
        .positive(ErrorMessages.POSITIVE_NUMBER)
        .typeError(ErrorMessages.NUMBER_INVALID),
    secondDepositValue: yup.number().required(ErrorMessages.REQUIRED)
        .transform((_, originValue) => Number(originValue.replace(/,/, '.')))
        .required(ErrorMessages.REQUIRED)
        .positive(ErrorMessages.POSITIVE_NUMBER)
        .typeError(ErrorMessages.NUMBER_INVALID),
});

export default yupResolver(verifyMicroDepositsResolver)
