import React from 'react';
import { Button, Flex, ButtonProps } from '@chakra-ui/react';
import { ReportAnIssueIcon } from '../../../../assets/icons';
import { Typography } from '../../../../common/components/blocks/Typography/Typography';

interface IReportIssueButtonProps extends ButtonProps {
    onClick: () => void;
}

export const ReportIssueButton: React.FC<IReportIssueButtonProps> = ({ onClick, ...rest }) => (
    <Button
        fontWeight={400}
        bg="transparent"
        height="60px"
        borderColor="brand"
        borderWidth={1}
        color="brand"
        borderRadius="20px"
        colorScheme="blackAlpha"
        onClick={onClick}
        {...rest}
    >
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <ReportAnIssueIcon />
            <Typography size={13}>Report an issue</Typography>
        </Flex>
    </Button>
);
