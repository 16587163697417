import React from 'react';
import {
    Box,
    Flex,
    IconButton,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import { BackArrow, CloseButton } from '../../../../../assets/icons';
import { colors } from '../../../../../common/constants';
import { fonts } from '../../../../../common/utils';

interface IProps {
    isOpen: boolean;
    title: string;
    size?: string;
    onClose: () => void;
    isCentered?: boolean;
    trapFocus?: boolean;
}

export const PaymentsModalWrapper: React.FC<IProps> = ({
    isOpen,
    title,
    size = 'xl',
    onClose,
    children,
    isCentered = false,
    trapFocus,
}) => {
    return (
        <Modal
            trapFocus={trapFocus}
            isCentered={isCentered}
            isOpen={isOpen}
            size={size}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent maxW={'830px'} bgColor={colors.modalBackground}>
                <Box p={'40px'}>
                    <Flex justifyContent={'space-between'} align={'center'}>
                        <IconButton
                            onClick={onClose}
                            aria-label={'close modal'}
                            icon={<BackArrow />}
                            variant="ghost"
                        />
                        <Text
                            fontFamily={fonts.montserrat}
                            fontWeight={700}
                            fontSize={'21px'}
                            lineHeight={'18px'}
                            textTransform={'capitalize'}
                            color="brand"
                        >
                            {title}
                        </Text>
                        <IconButton
                            onClick={onClose}
                            aria-label={'close modal'}
                            icon={<CloseButton />}
                            variant="ghost"
                        />
                    </Flex>

                    <ModalBody overflow={'auto'} p="0">
                        {children}
                    </ModalBody>
                </Box>
            </ModalContent>
        </Modal>
    );
};
