import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import { SignUpFillData } from './SignUpFillData';
import { SubmitHandler } from 'react-hook-form';
import { SignUpFillDataPayload, SignUpGetAgencies } from '../../types';
import {
    addNewAgencyRequest,
    handleAddNewAgencyModal,
    removeDraftAgency,
    signUpFillDataRequest,
    signUpGetAgencies,
} from '../../store/actions';
import { AddNewAgencyDataPayload, IAuthContainerProps } from '../../../../../common/types';

export const SignUpFillDataContainer: React.FC<IAuthContainerProps> = () => {
    const loading = useSelector((state: RootStore) => state.signUp.loading);
    const errors = useSelector((state: RootStore) => state.signUp.errors);
    const accessToken = useSelector((state: RootStore) => state.signUp.accessToken);
    const agencies = useSelector((state: RootStore) => state.signUp.agencies);
    const { loading: addNewAgencyLoading, value: agencyDraftData } = useSelector(
        (state: RootStore) => state.signUp.addNewAgency,
    );
    const { isAddedDraftAgency, addNewAgencyModalVisible } = useSelector(
        (state: RootStore) => state.signUp,
    );

    const dispatch = useDispatch();
    const onFillData: SubmitHandler<SignUpFillDataPayload> = payload =>
        dispatch(signUpFillDataRequest(payload));
    const onGetAgencies: SubmitHandler<SignUpGetAgencies> = payload =>
        dispatch(signUpGetAgencies(payload));
    const onAddNewAgency: SubmitHandler<AddNewAgencyDataPayload> = payload =>
        dispatch(
            addNewAgencyRequest({
                data: payload,
                verifyToken: accessToken || '',
            }),
        );
    const handleNewAgencyModal = (payload: boolean) => dispatch(handleAddNewAgencyModal(payload));
    const onRemoveDraftAgency = () => dispatch(removeDraftAgency());

    return (
        <SignUpFillData
            loading={loading}
            stateErrors={errors}
            onFillData={onFillData}
            accessToken={accessToken}
            onGetAgencies={onGetAgencies}
            agencies={agencies}
            addAgencyModal={addNewAgencyModalVisible}
            onAddNewAgency={onAddNewAgency}
            addNewAgencyLoading={addNewAgencyLoading}
            agencyDraftData={agencyDraftData}
            handleNewAgencyModal={handleNewAgencyModal}
            onRemoveDraftAgency={onRemoveDraftAgency}
            isAddedDraftAgency={isAddedDraftAgency}
        />
    );
};
