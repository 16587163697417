import { call, put, takeEvery } from 'redux-saga/effects';
import { changeClientPhone, changeClientPhoneError, changeClientPhoneSuccess } from '../actions';
import { IResponseWithoutValue } from '../../../../common/types';
import API from '../../../../common/api/api.base';
import { Alerter } from './../../../../common/utils/Alerter';

function* workerChangeClientPhoneSaga(action: ReturnType<typeof changeClientPhone>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            '/api/requests/change-client-phone',
            action.payload,
        );
        if (result.success) {
            yield put(changeClientPhoneSuccess(result));
            Alerter.success('Client phone number changed successfully');
        } else {
            yield put(changeClientPhoneError(result.errors));
        }
    } catch (error) {
        console.log(error, 'workerChangeClientPhoneSaga');
    }
}

export default function* watchChangeClientPhoneSaga() {
    yield takeEvery(changeClientPhone.type, workerChangeClientPhoneSaga);
}
