import { createAction } from '@reduxjs/toolkit';
import { GetNotificationsPayload, NotificationsResponse } from '../types';
import { PayloadError } from '../../../common/types';

export const getNotificationsRequest = createAction<GetNotificationsPayload>(
    'GET_NOTIFICATIONS_REQUEST',
);
export const getNotificationsSuccess = createAction<NotificationsResponse>(
    'GET_NOTIFICATIONS_SUCCESS',
);
export const getNotificationsError = createAction<PayloadError[]>('GET_NOTIFICATIONS_ERROR');

export const getUnreadNotifications = createAction('SET_UNREAD_NOTIFICATION');
export const getUnreadNotificationsSuccess = createAction<number>(
    'SET_UNREAD_NOTIFICATION_SUCCESS',
);

export const setNotificationsAvailableRequestModal = createAction<{ isOpen: boolean }>(
    'SET_NOTIFICATIONS_AVAILABLE_REQUEST_MODAL',
);

export const setConnectPaymentModalOpen = createAction<{ isOpen: boolean }>(
    'SET_CONNECT_PAYMENT_MODAL',
);

export const setNotificationsMyRequestsModal = createAction<{
    isOpen: boolean;
    id: number;
    toApplicants: boolean;
}>('SET_NOTIFICATIONS_MY_REQUEST_MODAL');
