import React, { useEffect, useState } from 'react';
import { Flex, Text, Box } from '@chakra-ui/layout';
import { BackArrow, CloseButton } from '../../../assets/icons';
import { useHistory } from 'react-router';
import {
    TabSwitcher,
    GoogleCalendarList,
    ManualCalendarList,
    AddGoogleCalendarModal,
    ManualCalendarEditor,
} from '../components';
import { useDispatch, useSelector } from 'react-redux';
import {
    addCalendarIdRequest,
    addCalendarRequest,
    getCalendarRequest,
    getGoogleCalendarRequest,
} from '../store/actions';
import { AddCalendarIdPayload, AddCalendarPayload } from '../types';
import { RootStore } from '../../../store';

export const CalendarAndAvailability: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(1);
    const [showAddGoogleCalendarModal, setShowAddGoogleCalendarModal] = useState(false);
    const [showManualCalendarEditor, setManualCalendarEditor] = useState(false);
    const [workHoursItem, setWorkHoursItem] = useState({});
    console.log(workHoursItem, 'workHoursItem')

    const googleCalendar = useSelector((state: RootStore) => state.settings.googleCalendar);
    const workHours = useSelector((state: RootStore) => state.settings.workHours);
    const isCalendarCahnged = useSelector((state: RootStore) => state.settings.isCalendarChanged);

    const getGoogleCalendar = () => dispatch(getGoogleCalendarRequest());
    const addGoogleCalendar = (payload: AddCalendarIdPayload) =>
        dispatch(addCalendarIdRequest(payload));
    const getCalendar = () => dispatch(getCalendarRequest());
    const addCalendar = (payload: AddCalendarPayload) => dispatch(addCalendarRequest(payload));

    const handleAddGoogleCalendar = () => {
        setShowAddGoogleCalendarModal(!showAddGoogleCalendarModal);
    };

    const handleEditManualCalendar = (item: any) => {
        setManualCalendarEditor(!showManualCalendarEditor);
        setWorkHoursItem(item);
    };

    const handleEditManualClose = () => {
        setManualCalendarEditor(!showManualCalendarEditor);
    };

    useEffect(() => {
        if (activeTab === 1) {
            getGoogleCalendar();
        }
        if (activeTab === 2) {
            getCalendar();
        }
    }, [activeTab, isCalendarCahnged]);

    return (
        <Flex direction="column" px="50px" py="20px" align="center">
            <Flex justify="space-between" align="center" mb="40px" width="100%">
                <Flex align={'center'}>
                    <Box as="button" onClick={history.goBack} mr={'36px'}>
                        <BackArrow />
                    </Box>
                    <Text fontSize="22px" fontWeight="700">
                        Calendar & Availability
                    </Text>
                </Flex>
                <Box onClick={() => history.goBack()} _hover={{ cursor: 'pointer' }}>
                    <CloseButton />
                </Box>
            </Flex>
            <TabSwitcher activeTab={activeTab} onClick={setActiveTab} />
            {activeTab === 1 && (
                <GoogleCalendarList
                    showModal={handleAddGoogleCalendar}
                    setCalendar={addGoogleCalendar}
                    calendar={googleCalendar}
                />
            )}
            {activeTab === 2 && (
                <ManualCalendarList showModal={handleEditManualCalendar} workHours={workHours} />
            )}
            <AddGoogleCalendarModal
                isOpen={showAddGoogleCalendarModal}
                onClose={handleAddGoogleCalendar}
                setCalendar={addGoogleCalendar}
            />
            <ManualCalendarEditor
                isOpen={showManualCalendarEditor}
                onClose={handleEditManualClose}
                workHours={workHoursItem}
                addCalendar={addCalendar}
                setWorkHoursItem={setWorkHoursItem}
            />
        </Flex>
    );
};
