import React from 'react';
import { Box, Text, Button, Link, Flex } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { fonts } from '../../../utils';
import { colors } from '../../../constants';

import { SmallPassword } from '../../../../assets/icons';

interface ICustomButtonProps {
    text: string;
    href: string;
    isAccessAllowed: boolean;
}

const SidebarAgencyButton: React.FC<ICustomButtonProps> = ({ text, href, isAccessAllowed }) => (
    <Button
        _hover={{
            textDecoration: 'none',
            background: '#E2E6F0',
        }}
        pt="11px"
        pb="11px"
        d="block"
        w="250px"
        color={colors.brand}
        fontFamily={fonts.montserrat}
        fontSize="15px"
        fontWeight="500"
        mb="25px"
        as={isAccessAllowed ? NavLink : undefined}
        to={href || ''}
        overflow={'hidden'}
        _disabled={{
            background: '#FFFFFF',
        }}
        disabled={!isAccessAllowed}
    >
        <Flex justifyContent="space-between">
            <Text>{text}</Text>
            <Text>&#10095;</Text>
        </Flex>

        {!isAccessAllowed && (
            <Flex
                style={{
                    backdropFilter: 'blur(3px)',
                }}
                justifyContent={'center'}
                alignItems={'center'}
                right={0}
                left={0}
                top={0}
                h={'100%'}
                w={'100%'}
                position={'absolute'}
            >
                <SmallPassword />
            </Flex>
        )}
    </Button>
);

export { SidebarAgencyButton };
