/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

export type CreateOtherFormValues = {
    states: Array<string>;
    date: string;
    fromTime: string;
    price: number;
    generalNote?: string;
    clientTypeId: number;
    clientName?: string;
    clientPhone?: string;
    privateNote?: string;
    longitude: number;
    latitude: number;
    startingAddress: string;
    isShowingScheduled: string;
    involvesProperty: string;
};

export const createPostOtherSchema = yup.object().shape({
    involvesProperty: yup.string().default('yes'),
    states: yup.array().required(ErrorMessages.REQUIRED),
    date: yup.string().required(ErrorMessages.REQUIRED),
    fromTime: yup.string().required(ErrorMessages.REQUIRED),
    price: yup
        .number()
        .required(ErrorMessages.REQUIRED)
        .positive()
        .integer('Should be integer')
        .typeError('Must be a number'),
    generalNote: yup.string().default(''),
    longitude: yup.number().when('involvesProperty', {
        is: 'yes',
        then: yup.number().required(ErrorMessages.REQUIRED),
    }),
    clientTypeId: yup.number().required(ErrorMessages.REQUIRED),
    clientName: yup.string(),
    clientPhone: yup.string(),
    privateNote: yup.string().default(''),
    isShowingScheduled: yup.string().when('involvesProperty', {
        is: 'yes',
        then: yup.string().required(ErrorMessages.REQUIRED),
    }),
});

export default yupResolver(createPostOtherSchema, { stripUnknown: true, abortEarly: false });
