import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/react';
import { EditIcon } from '../../../assets/icons';

interface IManualCalendarList {
    showModal: (v: any) => void;
    workHours: any;
}

export const ManualCalendarList: React.FC<IManualCalendarList> = ({ showModal, workHours }) => (
    <Flex width="100%" direction="column">
        {workHours?.map((item: any, index: any) => (
            <Flex
                justify="space-between"
                align="center"
                py="30px"
                borderBottom="1px solid #D6D8E0"
                width="100%"
                key={index}
            >
                <Text fontSize="18px" fontWeight="500">
                    {item.agency}
                </Text>
                {item.workHours.length > 0 ? (
                    <Button
                        variant="outline"
                        mr="15px"
                        width="150px"
                        onClick={() => showModal(item)}
                    >
                        <EditIcon color="#888DAB" />
                        <Text
                            ml="10px"
                            fontSize="16px"
                            fontWeight="300"
                            color="secondaryFontDarker"
                        >
                            Change
                        </Text>
                    </Button>
                ) : (
                    <Button
                        variant="outline"
                        mr="15px"
                        width="150px"
                        onClick={() => showModal(item)}
                    >
                        <Text
                            ml="10px"
                            fontSize="16px"
                            fontWeight="300"
                            color="secondaryFontDarker"
                        >
                            +{''} Add
                        </Text>
                    </Button>
                )}
            </Flex>
        ))}
    </Flex>
);
