import { put, call, takeEvery } from 'redux-saga/effects';
import { editRequest, editRequestSuccess, editRequestError } from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithoutValue } from './../../../../common/types';

function* workerEditRequest(action: ReturnType<typeof editRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            '/api/request/edit',
            action.payload,
        );
        console.log('editRequest', result, action);
        if (result.success) {
            yield put(editRequestSuccess());
        } else {
            yield put(editRequestError(result.errors));
            console.log('editRequestError', result);
        }
    } catch (error) {
        console.error(error);
    }
}

export default function* watchEditRequestSaga() {
    yield takeEvery(editRequest.type, workerEditRequest);
}
