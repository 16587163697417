import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import { IResponseWithCustomValue } from '../../../common/types';
import {
    getCities,
    getCitiesSuccess,
    getCitiesError,
} from '../../actions/directories.actions';

function* workerGetCities(action: ReturnType<typeof getCities>) {
    try {
        const result: IResponseWithCustomValue<any> = yield call(
            Api.get,
            `/api/profile/get-cities?UserId=${action.payload.userId}&City=${action.payload.cityString}`,
            {},
        );
        if (result.success) {
            yield put(getCitiesSuccess(result.value.cities));
        } else {
            yield put(getCitiesError(result.errors));
        }
    } catch (error) {
        console.log(error);
    }
};

export function* watchGetCities() {
    yield takeEvery(getCities.type, workerGetCities);
};
