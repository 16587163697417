import React from 'react';
import { Box, Flex, FormControl, FormErrorMessage, HStack } from '@chakra-ui/react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import quillEmoji from 'quill-emoji';
import 'quill-emoji/dist/quill-emoji.css';
import './styles.css';
import { CustomButton } from '../../../../common/components';

Quill.register(
    {
        'formats/emoji': quillEmoji.EmojiBlot,
        'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    },
    true,
);

interface IPostEditor {
    onChange: (value: string) => void;
    errorMsg?: string;
    placeholder?: string;
    value?: string;
    addCommentLoading: boolean;
}

const modules = {
    toolbar: '#toolbar',
    'emoji-toolbar': true,
};

export const PostEditor = React.forwardRef<null, IPostEditor>(
    ({ onChange, errorMsg, placeholder, value = '', addCommentLoading }, ref) => {
        return (
            <FormControl isInvalid={!!errorMsg}>
                <ReactQuill
                    modules={modules}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    style={{
                        border: `1px solid ${errorMsg ? '#E53E3E' : 'transparent'}`,
                        boxShadow: errorMsg ? '0 0 0 1px #E53E3E' : 'none',
                        borderRadius: '24px',
                    }}
                />
                <FormErrorMessage>{errorMsg}</FormErrorMessage>
                {/*Toolbar*/}
                <Flex
                    justifyContent={'space-between'}
                    mt={'16px'}
                    p={'8px 8px 8px 16px'}
                    h={'56px'}
                    bg={'#F6F7FB'}
                    align={'center'}
                    borderRadius={'80px'}
                >
                    <Box id="toolbar">
                        <HStack spacing={'32px'}>
                            <button className="ql-bold" />
                            <button className="ql-italic" />
                            <button className="ql-strike" />
                            <button className="ql-emoji" />
                        </HStack>
                    </Box>
                    <Box>
                        <CustomButton
                            isLoading={addCommentLoading}
                            fontSize={'16px'}
                            p={'8px 32px'}
                            bgColor="brand"
                            height={'40px'}
                            type={'submit'}
                            text={'Send'}
                            borderRadius="32px"
                            fontWeight={400}
                        />
                    </Box>
                </Flex>
            </FormControl>
        );
    },
);

PostEditor.displayName = 'PostEditor';
