import { createSlice } from '@reduxjs/toolkit';
import {
    checkPolicyViewedRequest,
    checkPolicyViewedResult,
    checkTermsViewedRequest,
    checkTermsViewedResult,
} from '../actions/onboarding.actions';
import { logout } from '../actions/auth/auth.actions';

type InitialState = {
    acceptTermsLoading: boolean;
    acceptPolicyLoading: boolean;
};

export const initialState: InitialState = {
    acceptPolicyLoading: false,
    acceptTermsLoading: false,
};

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(checkPolicyViewedRequest, state => {
                state.acceptPolicyLoading = true;
            })
            .addCase(checkPolicyViewedResult, state => {
                state.acceptPolicyLoading = false;
            })
            .addCase(checkTermsViewedRequest, state => {
                state.acceptTermsLoading = true;
            })
            .addCase(checkTermsViewedResult, state => {
                state.acceptTermsLoading = false;
            })
            .addCase(logout, state => {
                state = { ...initialState };
            });
    },
});

export default onboardingSlice.reducer;
