import React, { useEffect, useState } from 'react';
import ModalWrapper from '../../blocks/ModalWrapper/ModalWrapper';
import { Box, Text, HStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { getCalendarAssistanceRequestsRequest } from '../../../../pages/Calendar/store/actions';
import { ListBlock } from './components/ListBlock';
import { InfiniteScroll, Loader, CustomButton } from '../../../components/blocks';
import { AvailableForApplicationResponse } from '../../../../pages/Calendar/types';
import dayjs from 'dayjs';
import { TestSearchMenu } from './components/TestSearchMenu';
import {
    acceptRequest,
    deleteRequestAfterTimer,
    getRequest,
} from '../../../../pages/Requests/store/actions';
import { AcceptRequestsPayload } from '../../../../pages/Requests/types';
import { defaultRequestTypesToFilter } from '../../../../common/constants';
import {
    AvailableRequestDetailsModal,
    OpenHouseRequestDetailsModal,
} from '../../../../pages/Requests/modals';

interface IOpportunitiesModalProps {
    isOpen: boolean;
    onClose: () => void;
    startEndConfig: { start: string; end: string };
}

const AssistanceRequestsModal: React.FC<IOpportunitiesModalProps> = ({
    isOpen,
    onClose,
    startEndConfig,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
    const [postId, setPostId] = useState(0);
    const [typeIdsSelected, setTypeIdsSelected] = useState<number[]>([]);
    const [isAvailableModalOpen, setIsAvailableModalOpen] = useState(false);
    const [createdAt, setCreatedAt] = useState('');

    const { loading, items, loadMoreLoading, totalCount } = useSelector(
        (state: RootStore) => state.calendar.assistanceRequestsModal,
    );
    const requestInfoLoading = useSelector(
        (state: RootStore) => state.requests.requestInfo.loading,
    );
    const requestInfo = useSelector((state: RootStore) => state.requests.requestInfo.value);
    const success = useSelector((state: RootStore) => state.requests.acceptRequest);

    const dispatch = useDispatch();
    const onAcceptRequest = (data: AcceptRequestsPayload) => dispatch(acceptRequest(data));
    const onDeleteRequestAfterTimer = (id: number) => dispatch(deleteRequestAfterTimer(id));
    const onGetRequest = (id: number) => dispatch(getRequest(id));

    const getItems = (page?: number) => {
        dispatch(
            getCalendarAssistanceRequestsRequest({
                categoryId: 1,
                fromTime: startEndConfig.start,
                pageNumber: page || currentPage,
                pageSize: 10,
                toTime: startEndConfig.end,
                date: startEndConfig.start,
                typeIds: typeIdsSelected,
            }),
        );
    };

    const handleOpenAvailableModal = (id: number) => {
        setIsAvailableModalOpen(true);
        onGetRequest(id);
    };

    const handleAcceptRequest = (IsAccepted: boolean, RequestId: number) => {
        onAcceptRequest({
            RequestId,
            IsAccepted,
            PageSize: 10,
            includeOtherAgent: true,
            categoryIds: [],
            date: createdAt,
        });
    };
    useEffect(() => {
        if (isOpen) {
            getItems();
        }
    }, [isOpen, currentPage]);

    useEffect(() => {
        if (isOpen && success) {
            getItems();
        }
    }, [isOpen, success]);

    const loadMoreItems = () => {
        if (items.length >= totalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPage((prev: number) => prev + 1);
        }
    };

    const modalHeader: JSX.Element = (
        <Box
            borderLeftColor="calendarLical"
            borderLeftWidth="8px"
            bgColor="calendarLicalOpacity"
            w="99%"
        >
            <Text fontWeight={400} fontSize="20px" ml="10px">
                Assistance Requests {dayjs(startEndConfig.start).format('ddd DD MMM')}
            </Text>
        </Box>
    );

    const modalSearch: JSX.Element = (
        <HStack>
            <TestSearchMenu
                menuValues={defaultRequestTypesToFilter}
                setTypeIdsSelected={setTypeIdsSelected}
                typeIdsSelected={typeIdsSelected}
            />
            <CustomButton
                text="Search"
                bgColor="brand"
                width="30%"
                height="50px"
                onClick={() => getItems(1)}
            />
        </HStack>
    );

    const ModalBody: React.FC = () => (
        <Box height="100%">
            {loading ? (
                <Loader spinnerSize="md" centerHeight="100%" />
            ) : (
                <InfiniteScroll onReachBottom={loadMoreItems}>
                    {items.map(
                        (elem: {
                            startTime: string;
                            models: AvailableForApplicationResponse[];
                        }) => (
                            <ListBlock
                                key={elem.startTime}
                                startTime={startEndConfig.start}
                                models={elem.models}
                                setPostId={setPostId}
                                onAcceptRequest={onAcceptRequest}
                                onDeleteRequestAfterTimer={onDeleteRequestAfterTimer}
                                setCreatedAt={setCreatedAt}
                                handleOpenAvailableModal={handleOpenAvailableModal}
                            />
                        ),
                    )}
                    {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
                </InfiniteScroll>
            )}
        </Box>
    );

    return (
        <>
            <ModalWrapper
                isOpen={isOpen}
                onClose={onClose}
                modalHeader={modalHeader}
                modalBody={<ModalBody />}
                modalSearch={modalSearch}
            />
            <AvailableRequestDetailsModal
                isOpen={isAvailableModalOpen}
                onClose={setIsAvailableModalOpen}
                requestInfo={requestInfo}
                requestInfoLoading={requestInfoLoading}
                handleAcceptRequest={handleAcceptRequest}
            />
            <OpenHouseRequestDetailsModal isOpen={false} onClose={setIsAvailableModalOpen} />
        </>
    );
};

export { AssistanceRequestsModal };
