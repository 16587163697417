import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    ModalHeader,
    CloseButton,
    Flex,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, cancelAcceptedWork } from '../../store/actions';
import { getBonusTypes, getClientTypes } from '../../../../store/actions/directories.actions';
import { getOpenHouseInfo } from '../../../../store/actions/listings.actions';
import { RootStore } from '../../../../store';
import {
    OpenHouseSummary,
    HireAssistanceSummary,
    OpportunitiesRepliesSummary,
} from './request-types';
import { ConfirmAlert } from '../../../../common/components/blocks';
import { useDelta } from 'react-delta';
import { colors } from '../../../../common/constants';
import { shouldShowCancellationMessage } from '../../../../common/utils';

interface IAgencyRequestInfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    sentRequestToShowId: number;
    onGetAgencyRequests?: (value?: number) => void;
}

export const AgencyRequestInfoModal: React.FC<IAgencyRequestInfoModalProps> = ({
    isOpen,
    onClose,
    sentRequestToShowId,
    onGetAgencyRequests,
}) => {
    const dispatch = useDispatch();

    const [cancelWorkAlertOpen, setCancelWorkAlertOpen] = useState(false);
    const [toCancelWorkId, setToCancelWorkId] = useState(0);

    const { value: requestInfo, loading: requestInfoLoading } = useSelector(
        (state: RootStore) => state.requests.requestInfo,
    );
    const { value: openHouseInfo, loading: openHouseInfoLoading } = useSelector(
        (state: RootStore) => state.listings.openHouseInfo,
    );
    const { errors: cancelAcceptedWorkErrors, loading: cancelAcceptedWorkLoading } = useSelector(
        (state: RootStore) => state.requests.cancelAcceptedWork,
    );
    const clientTypes = useSelector((state: RootStore) => state.directories.clientTypes);
    const bonusTypes = useSelector((state: RootStore) => state.directories.bonusTypes);

    const cancelAcceptedWorkLoadingDelta = useDelta(cancelAcceptedWorkLoading);

    useEffect(() => {
        dispatch(getClientTypes());
        dispatch(getBonusTypes());
    }, []);

    useEffect(() => {
        if (isOpen && sentRequestToShowId) {
            dispatch(getRequest(sentRequestToShowId));
        }
    }, [isOpen]);

    useEffect(() => {
        if (requestInfo.timeSlot.id !== 0) {
            dispatch(getOpenHouseInfo(requestInfo.timeSlot.listingId));
        }
    }, [requestInfo]);

    useEffect(() => {
        if (toCancelWorkId) {
            setCancelWorkAlertOpen(true);
        }
    }, [toCancelWorkId]);

    useEffect(() => {
        if (cancelAcceptedWorkLoadingDelta && cancelAcceptedWorkLoadingDelta.prev) {
            if (
                cancelAcceptedWorkLoadingDelta.prev &&
                !cancelAcceptedWorkLoadingDelta.curr &&
                !cancelAcceptedWorkErrors.length
            ) {
                setCancelWorkAlertOpen(false);
                setToCancelWorkId(0);
                onClose();
                onGetAgencyRequests?.(1);
            }
        }
    }, [cancelAcceptedWorkLoadingDelta]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="5xl" scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent
                    h="85vh"
                    borderRadius="18px"
                    bgColor={colors.modalBackground}
                    alignItems="center"
                >
                    {requestInfo.requestCategoryId === 1 ? (
                        <HireAssistanceSummary
                            requestInfo={requestInfo}
                            loading={requestInfoLoading}
                            clientTypes={clientTypes}
                            bonusTypes={bonusTypes}
                            setToCancelWorkId={setToCancelWorkId}
                            onClose={onClose}
                        />
                    ) : null}
                    {requestInfo.requestCategoryId === 2 ? (
                        <OpportunitiesRepliesSummary
                            requestInfo={requestInfo}
                            loading={requestInfoLoading}
                            setToCancelWorkId={setToCancelWorkId}
                            bonusTypes={bonusTypes}
                            onClose={onClose}
                        />
                    ) : null}
                    {requestInfo.requestCategoryId === 3 ? (
                        <OpenHouseSummary
                            openHouseInfo={openHouseInfo}
                            requestInfo={requestInfo}
                            setToCancelWorkId={setToCancelWorkId}
                            loading={openHouseInfoLoading}
                            requestLoading={requestInfoLoading}
                            onClose={onClose}
                        />
                    ) : null}
                </ModalContent>
            </Modal>
            <ConfirmAlert
                isOpen={cancelWorkAlertOpen}
                onClose={() => {
                    setCancelWorkAlertOpen(false);
                    setToCancelWorkId(0);
                }}
                onConfirm={() => dispatch(cancelAcceptedWork(toCancelWorkId))}
                message={`Are you sure you want cancel this work? ${
                    shouldShowCancellationMessage(
                        requestInfo.dateTime,
                        requestInfo.timeSlot.openHouseHostTypeId,
                    )
                        ? 'You will be charged a $30 Cancellation Fee as this cancellation is within 1 hour of the start time'
                        : ''
                }`}
                confirmLoading={cancelAcceptedWorkLoading}
            />
        </>
    );
};

export const RequestModalHeader: React.FC<{ title: string; onClose: () => void }> = ({
    onClose,
    title,
}) => (
    <ModalHeader w="100%" px="70px">
        <Flex align="center" justify="space-between" my="20px">
            <Text fontSize="22px" lineHeight="18px" color="brand" fontWeight="bold">
                {title}
            </Text>
            <CloseButton color="brand" onClick={onClose} />
        </Flex>
    </ModalHeader>
);
