import dayjs from 'dayjs';
export const getTimeByTimeZone = (date: string | number, timeZone?: string) => {
    const dayjsObj = dayjs(date);

    if (!dayjsObj.isValid()) {
        throw new Error(`Invalid date for getting Date object: ${date}`);
    }

    return timeZone ? dayjsObj.tz(timeZone) : dayjsObj;
};
