import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/react';
import { CheckIcon, Disconnect, EditIcon } from '../../../assets/icons';
import { AddCalendarIdPayload } from '../types';
import { CustomButton } from '../../../common/components';

interface IGoogleCalendarList {
    showModal: () => void;
    setCalendar: (v: AddCalendarIdPayload) => void;
    calendar: any;
}

export const GoogleCalendarList: React.FC<IGoogleCalendarList> = ({
    showModal,
    setCalendar,
    calendar,
}) => (
    <Flex width="100%">
        {!calendar?.length ? (
            <Flex pt="60px">
                <CustomButton
                    width="342px"
                    height="60px"
                    text="Add Calendar"
                    bgColor="brand"
                    boxShadow="0px 3px 3px grey"
                    onClick={showModal}
                />
            </Flex>
        ) : (
            <Flex w="100%" pt="30px" direction="column">
                <Flex
                    justify="space-between"
                    px="20px"
                    py="20px"
                    bgColor="#F5F6F8"
                    w="100%"
                    borderRadius="10px"
                >
                    <Text fontSize="16px" fontWeight="600">
                        {calendar}
                    </Text>
                    <Flex align="center">
                        <CheckIcon />
                        <Text color="#6FCF97" fontSize="16px" fontWeight="600" ml="10px">
                            Connected
                        </Text>
                    </Flex>
                </Flex>
                <Flex justify="flex-end" mt="20px">
                    <Button
                        variant="outline"
                        mr="15px"
                        onClick={() => setCalendar({ calendarId: null })}
                    >
                        {' '}
                        <Disconnect />{' '}
                        <Text
                            ml="10px"
                            fontSize="18px"
                            fontWeight="300"
                            color="secondaryFontDarker"
                        >
                            Disconnect
                        </Text>
                    </Button>
                    <Button variant="outline" onClick={showModal}>
                        {' '}
                        <EditIcon color="#888DAB" />{' '}
                        <Text
                            ml="10px"
                            fontSize="18px"
                            fontWeight="300"
                            color="secondaryFontDarker"
                        >
                            Change
                        </Text>
                    </Button>
                </Flex>
            </Flex>
        )}
    </Flex>
);
