import React, { LegacyRef } from 'react';
import {
    Box,
    NumberInput,
    NumberInputField,
    Text,
    InputGroup,
    InputRightElement,
    FormErrorMessage,
    FormControl,
    TextProps,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { fonts } from '../../../utils';

interface ICustomInputProps {
    labelProps?: TextProps;
    props?: any;
    label?: string;
    width?: string | { base?: string; sm?: string; md?: string; lg?: string };
    leftIcon?: any;
    rightIcon?: any;
    placeholder?: string;
    margin?: string | number;
    register?: any;
    errors?: any;
    isPassword?: boolean;
    bgColor?: string;
    isBorder?: boolean;
    disabled?: boolean;
    defaultValue?: string | number;
    isReadOnly?: boolean;
    color?: string;
    labelColor?: string;
    mask?: string;
    pattern?: string;
    value?: string;
    cursor?: string;
    onChange?: (arg0: any) => void;
    fontWeight?: number;
    fontSize?: string;
    fontFamily?: string;
    textSize?: string;
    placeholderColor?: string;
    height?: string;
    min?: number;
    max?: number;
    type?:
        | 'none'
        | 'text'
        | 'tel'
        | 'url'
        | 'email'
        | 'number'
        | 'decimal'
        | 'search'
        | 'password'
        | 'time'
        | undefined;
}

const CustomNumberInput: React.FC<ICustomInputProps> = React.forwardRef(
    (
        {
            label,
            width,
            rightIcon,
            props,
            placeholder,
            isBorder = true,
            margin,
            register,
            bgColor,
            errors,
            mask,
            disabled,
            defaultValue,
            isReadOnly = false,
            color,
            labelColor,
            value,
            onChange,
            labelProps,
            placeholderColor,
            fontWeight = '300',
            fontSize = '13px',
            fontFamily = fonts.poppins,
            textSize = '13px',
            height = '50px',
            min,
            max,
        },
        ref: LegacyRef<HTMLInputElement>,
    ) => {
        return (
            <Box w={width} textAlign="left" color="#fff" m={margin}>
                <FormControl isInvalid={!!errors}>
                    {label && (
                        <Text
                            textTransform={'capitalize'}
                            color={labelColor || 'brand'}
                            fontWeight={fontWeight}
                            fontSize={fontSize}
                            fontFamily={fontFamily}
                            {...labelProps}
                        >
                            {label}
                        </Text>
                    )}
                    <InputGroup>
                        <NumberInput min={min} max={max}>
                            <NumberInputField
                                as={mask && InputMask}
                                mask={mask && mask}
                                ref={ref}
                                h={height}
                                bg={bgColor || '#F6F7FB'}
                                borderRadius="80px"
                                borderWidth={isBorder ? '1px' : '0'}
                                color={color || 'brand'}
                                placeholder={placeholder}
                                _placeholder={{
                                    color: placeholderColor ? 'black' : null,
                                }}
                                disabled={disabled}
                                defaultValue={defaultValue}
                                value={value}
                                isReadOnly={isReadOnly}
                                onChange={onChange ? onChange : null}
                                fontWeight={fontWeight}
                                fontSize={textSize}
                                fontFamily={fontFamily}
                                {...register}
                                {...props}
                            />
                        </NumberInput>
                        {rightIcon && (
                            <InputRightElement zIndex={0} h="100%" mr="5px" children={rightIcon} />
                        )}
                    </InputGroup>
                    {errors && (
                        <FormErrorMessage fontSize={fontSize}>{errors.message}</FormErrorMessage>
                    )}
                </FormControl>
            </Box>
        );
    },
);

CustomNumberInput.displayName = 'CustomNumberInput';

export { CustomNumberInput };
