import React, { useState } from 'react';

import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import * as yup from 'yup';

import {
    CustomButton,
    CustomDropdown,
    CustomDropdownEdit,
    CustomInput,
    CustomInputEdit,
    Loader,
    RequestQuickInfo,
} from '../../../../../common/components';

import { colors, yesOrNo } from '../../../../../common/constants';
import { ErrorMessages } from '../../../../../common/schemes/messages';

import { fonts } from '../../../../../common/utils';

import {
    ChangeClientName,
    ChangeClientPhone,
    ChangeClientType,
    ChangePrivateNote,
    RequestInfoType,
} from '../../../types';
import { IdNameTypes } from '../../../../../common/types';

interface IOpportunitiesInfo {
    requestInfoLoading: boolean;
    requestInfo: RequestInfoType;
    onDeleteRequest: (value: number) => void;
    onChangeClientName: (payload: ChangeClientName) => void;
    onChangeClientPhone: (payload: ChangeClientPhone) => void;
    onChangeClientType: (payload: ChangeClientType) => void;
    onChangePrivateNote: (payload: ChangePrivateNote) => void;
    bonusTypes: IdNameTypes[];
    clientTypes: IdNameTypes[];
}
export const OpportunitiesInfo: React.FC<IOpportunitiesInfo> = ({
    requestInfo,
    requestInfoLoading,
    onChangeClientName,
    onChangeClientPhone,
    onChangeClientType,
    onChangePrivateNote,
    onDeleteRequest,
    bonusTypes,
    clientTypes,
}) => {
    const [clientTypeValue, setClientTypeValue] = useState(requestInfo.clientTypeId);

    const fee = requestInfo.totalPrice ? Number((requestInfo.totalPrice * 0.015).toFixed(2)) : 0;
    const estimatedCoast = requestInfo.totalPrice ? requestInfo.totalPrice + fee : 0;

    return (
        <>
            {requestInfoLoading ? (
                <Loader spinnerSize="md" centerHeight="100%" />
            ) : (
                <>
                    {/*Main Box*/}
                    <Flex justifyContent="space-between" h={'100%'}>
                        <Box pr={'20px'} flex={1}>
                            {requestInfo.typeId !== 7 ? (
                                <RequestQuickInfo
                                    bg={colors.white}
                                    margin={0}
                                    date={requestInfo.dateTime}
                                    fromTime={requestInfo.fromTime}
                                    duration={requestInfo.duration}
                                    propertiesToShow={requestInfo.propertiesToShow}
                                    requestTypeName={requestInfo.requestTypeName}
                                    totalPrice={requestInfo.totalPrice || 0}
                                    isShowTotalPrice={false}
                                />
                            ) : (
                                <VStack
                                    align={'stretch'}
                                    bg="white"
                                    borderRadius="24px"
                                    padding="20px"
                                >
                                    <Text
                                        fontSize="14px"
                                        fontWeight={400}
                                        lineHeight="26px"
                                        color="secondaryFontDarker"
                                    >
                                        State
                                    </Text>
                                    <Text
                                        fontSize="14px"
                                        fontFamily={fonts.montserrat}
                                        fontWeight={600}
                                        lineHeight="26px"
                                        color="brand"
                                    >
                                        {`${requestInfo.states.join('')}`}
                                    </Text>
                                    <Text
                                        fontSize="14px"
                                        fontWeight={400}
                                        lineHeight="26px"
                                        color="secondaryFontDarker"
                                    >
                                        Agencies
                                    </Text>
                                    {requestInfo.agencies.map((agency, index) => (
                                        <Text
                                            key={`${agency}${index}`}
                                            fontSize="14px"
                                            fontFamily={fonts.montserrat}
                                            fontWeight={600}
                                            lineHeight="26px"
                                            color="brand"
                                        >
                                            {agency}
                                        </Text>
                                    ))}
                                </VStack>
                            )}
                            <Box mt={6}>
                                {requestInfo.states &&
                                    requestInfo.typeId !== 7 &&
                                    requestInfo.states.map((state: string) => (
                                        <CustomInput
                                            bgColor={colors.white}
                                            key={state}
                                            placeholder="State"
                                            label="State"
                                            register={{ value: state }}
                                            isBorder={false}
                                            isReadOnly
                                            margin="0 0 26px 0"
                                        />
                                    ))}
                                {requestInfo.addresses && requestInfo.addresses.length !== 0 ? (
                                    <>
                                        <Text
                                            fontFamily={fonts.montserrat}
                                            fontSize="18px"
                                            lineHeight="18px"
                                            fontWeight={700}
                                            color="brand"
                                            mb="5px"
                                        >
                                            {requestInfo.typeId === 1 ? 'Addresses' : 'Address'}
                                        </Text>
                                        {requestInfo.addresses.map(item => (
                                            <CustomInput
                                                bgColor={colors.white}
                                                key={item}
                                                register={{ value: item }}
                                                isBorder={false}
                                                isReadOnly
                                                margin="0 0 11px 0"
                                            />
                                        ))}
                                    </>
                                ) : null}
                                {requestInfo.generalNote ? (
                                    <>
                                        <Text
                                            fontFamily={fonts.montserrat}
                                            fontSize="18px"
                                            lineHeight="18px"
                                            fontWeight={700}
                                            color="brand"
                                        >
                                            Description:
                                        </Text>
                                        <Text
                                            color="secondaryFontDarker"
                                            fontWeight={400}
                                            fontSize="16px"
                                            mt={2}
                                            lineHeight="26px"
                                            fontFamily={fonts.poppins}
                                        >
                                            {requestInfo.generalNote}
                                        </Text>
                                    </>
                                ) : null}
                            </Box>
                        </Box>
                        <Box flex={1} pl={'20px'}>
                            <Text
                                fontFamily={fonts.montserrat}
                                fontSize="18px"
                                lineHeight="18px"
                                fontWeight={700}
                                color="brand"
                                mb="15px"
                            >
                                More details:
                            </Text>
                            {requestInfo.typeId === 1 ? (
                                <>
                                    <CustomInput
                                        bgColor={colors.white}
                                        label={`Is The ${requestInfo.requestTypeName} Scheduled?`}
                                        register={{
                                            value: requestInfo.isShowingScheduled ? 'Yes' : 'No',
                                        }}
                                        isBorder={false}
                                        isReadOnly
                                        margin="0 0 11px 0"
                                    />
                                    {requestInfo.wouldLikeToOfferBonus ? (
                                        <>
                                            <CustomDropdown
                                                label="I would like to offer a Bonus if a contract is ratified from the showing list within 48-hours after the showing:"
                                                options={yesOrNo}
                                                register={{
                                                    value: requestInfo.wouldLikeToOfferBonus
                                                        ? 'Yes'
                                                        : 'No',
                                                    isReadOnly: true,
                                                    pointerEvents: 'none',
                                                }}
                                                errors=""
                                                margin="0 0 10px 0"
                                                bgColor="white"
                                            />
                                            <Flex justifyContent={'space-between'}>
                                                <CustomDropdown
                                                    register={{
                                                        value: requestInfo.bonusTypeId,
                                                        isReadOnly: true,
                                                        pointerEvents: 'none',
                                                    }}
                                                    options={bonusTypes.map((elem, index) => ({
                                                        label: elem.name,
                                                        value: elem.id,
                                                        id: index,
                                                    }))}
                                                    label="Bonus Type"
                                                    errors=""
                                                    margin="0 0 10px 0"
                                                    bgColor="white"
                                                    width={'47%'}
                                                />
                                                <CustomInput
                                                    width={'47%'}
                                                    label="Bonus"
                                                    register={{
                                                        value:
                                                            requestInfo.amount?.toString() ||
                                                            undefined,
                                                        isReadOnly: true,
                                                    }}
                                                    margin="0 0 10px 0"
                                                    bgColor="white"
                                                />
                                            </Flex>
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                            <CustomDropdownEdit
                                label="The Client is:"
                                value={clientTypeValue}
                                onChange={setClientTypeValue}
                                options={clientTypes.map((elem, index) => ({
                                    label: elem.name,
                                    value: elem.id,
                                    id: index,
                                }))}
                                errors=""
                                margin="0 0 10px 0"
                                editFunction={value => {
                                    onChangeClientType?.({
                                        clientTypedId: value,
                                        requestId: requestInfo.id,
                                    });
                                }}
                                bgColor="white"
                            />
                            <CustomInputEdit
                                label="Client Name:"
                                defaultValue={requestInfo.referralClient?.name || undefined} // because of React error if null
                                editFunction={value => {
                                    onChangeClientName?.({
                                        clientName: value,
                                        requestId: requestInfo.id,
                                    });
                                }}
                                margin="0 0 10px 0"
                                schema={yup.string().required(ErrorMessages.REQUIRED)}
                                errorMsg={ErrorMessages.REQUIRED}
                                bgColor="white"
                            />
                            <CustomInputEdit
                                label="Client Phone Number"
                                type="tel"
                                width="100%"
                                mask="999-999-9999"
                                placeholder="Client Phone Number"
                                defaultValue={requestInfo.referralClient?.phone || undefined}
                                margin="0 0 10px 0"
                                editFunction={value => {
                                    onChangeClientPhone?.({
                                        clientPhoneNumber: value,
                                        requestId: requestInfo.id,
                                    });
                                }}
                                schema={yup
                                    .string()
                                    .required(ErrorMessages.REQUIRED)
                                    .test(
                                        'phone-number',
                                        ErrorMessages.PHONE_NUMBER_INCOMPLETE,
                                        function (value?: string) {
                                            return value?.replace(/[-_]/g, '').length === 10;
                                        },
                                    )}
                                errorMsg={ErrorMessages.REQUIRED}
                                bgColor="white"
                            />
                            <CustomInputEdit
                                label="Private Notes To The Chosen Agent:"
                                errors=""
                                defaultValue={requestInfo.privateNote || ''}
                                margin="0 0 10px 0"
                                editFunction={value => {
                                    onChangePrivateNote?.({
                                        privateNote: value,
                                        requestId: requestInfo.id,
                                    });
                                }}
                                schema={yup.string().required(ErrorMessages.REQUIRED)}
                                errorMsg={ErrorMessages.REQUIRED}
                                bgColor="white"
                            />
                        </Box>
                    </Flex>
                    {/*Footer*/}
                    <Flex mt="30px">
                        <Box flex={1} pr={'20px'}>
                            {requestInfo.typeId !== 7 ? (
                                <>
                                    <HStack justifyContent={'space-between'}>
                                        <Text
                                            fontSize="13px"
                                            color="brand"
                                            fontWeight={400}
                                            lineHeight="20px"
                                        >
                                            Offer Price:
                                        </Text>
                                        <Text
                                            fontSize="16px"
                                            color="secondaryFontDarker"
                                            lineHeight="24px"
                                            fontWeight={500}
                                        >
                                            ${requestInfo.totalPrice ?? 0}
                                        </Text>
                                    </HStack>
                                    <HStack justifyContent={'space-between'} my="12px">
                                        <Text
                                            fontSize="13px"
                                            color="brand"
                                            fontWeight={400}
                                            lineHeight="20px"
                                        >
                                            Fee:
                                        </Text>
                                        <Text
                                            fontSize="16px"
                                            color="secondaryFontDarker"
                                            lineHeight="24px"
                                            fontWeight={500}
                                        >
                                            ${fee}
                                        </Text>
                                    </HStack>
                                    <HStack justifyContent={'space-between'}>
                                        <Text
                                            fontSize="13px"
                                            color="brand"
                                            fontWeight={600}
                                            lineHeight="20px"
                                        >
                                            Estimated Cost:
                                        </Text>
                                        <Text
                                            fontWeight="700"
                                            fontSize="19px"
                                            lineHeight="28px"
                                            color="brand"
                                        >
                                            ${estimatedCoast}
                                        </Text>
                                    </HStack>
                                </>
                            ) : null}
                        </Box>
                        <Flex flex={1} pl={'20px'}>
                            <CustomButton
                                alignSelf="flex-end"
                                text="Delete"
                                height="60px"
                                bgColor="brand"
                                onClick={() => onDeleteRequest(requestInfo.id)}
                            />
                        </Flex>
                    </Flex>
                </>
            )}
        </>
    );
};
