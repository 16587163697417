import { createAction } from '@reduxjs/toolkit';
import {
    LoginPayload,
    LoginResponseSuccessType,
    PayloadError,
    RefreshTokenPayload,
    RefreshTokenResponseSuccess,
} from '../../../common/types';

export const login = createAction<LoginPayload>('LOGIN');
export const loginSuccess = createAction<LoginResponseSuccessType>('LOGIN_SUCCESS');
export const loginError = createAction<PayloadError[]>('LOGIN_ERROR');

export const refreshToken = createAction<RefreshTokenPayload>('REFRESH_TOKEN');
export const refreshTokenSuccess =
    createAction<RefreshTokenResponseSuccess>('REFRESH_TOKEN_SUCCESS');

export const removeAuthLoading = createAction('REMOVE_AUTH_LOADING');

export const logout = createAction('LOGOUT');
