import React, { useEffect, useState } from 'react';
import Help from './Help';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../store';
import { writeToSupportRequest } from './store/actions';
import { WriteToSupportPayloadType } from './types';
import { SentSuccessModal } from './SentSuccessModal';
import { useDelta } from 'react-delta';

export const HelpContainer: React.FC = () => {
    const dispatch = useDispatch();
    const [titleValue, setTitleValue] = useState<string>('');
    const [messageValue, setMessageValue] = useState<string>('');
    const [checkText, setCheckText] = useState<boolean>(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const onWriteToSupportRequest = (payload: WriteToSupportPayloadType) =>
        dispatch(writeToSupportRequest(payload));

    const { loading, errors } = useSelector((state: RootStore) => state.writeToSupport);

    const loadingDelta = useDelta(loading);

    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) =>
        setTitleValue(event.target.value);
    const handleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
        setMessageValue(event.target.value);
    const handleResetTexts = () => {
        setMessageValue('');
        setTitleValue('');
    };

    useEffect(() => {
        if (messageValue && titleValue) {
            setCheckText(false);
        } else {
            setCheckText(true);
        }
    }, [messageValue, titleValue]);

    useEffect(() => {
        if (loadingDelta && loadingDelta.prev) {
            if (loadingDelta.prev && !loadingDelta.curr && !errors.length) {
                setSuccessModalOpen(true);
            }
        }
    }, [loadingDelta]);

    return (
        <>
            <Help
                titleValue={titleValue}
                messageValue={messageValue}
                handleChangeTitle={handleChangeTitle}
                handleChangeMessage={handleChangeMessage}
                handleResetTexts={handleResetTexts}
                onWriteToSupportRequest={onWriteToSupportRequest}
                checkText={checkText}
                loading={loading}
            />
            <SentSuccessModal
                isOpen={successModalOpen}
                onClose={() => setSuccessModalOpen(false)}
            />
        </>
    );
};
