import { createSlice } from '@reduxjs/toolkit';
import {
    setLocation,
    setLocationSuccess,
    setLocationError,
    getLocationSuccess,
    getLocation,
    getLocationError,
    setNeverUseLocation,
    setNeverUseLocationError,
    setNeverUseLocationSuccess,
} from './actions';
import { SetLocationPayload, PayloadError } from '../../../../../common/types';

type InitialState = {
    loading: boolean;
    errors: PayloadError[];
    value: SetLocationPayload;
    saveLoading: boolean;
};

const initialState: InitialState = {
    loading: false,
    errors: [],
    value: {
        checkBox: null,
    } as SetLocationPayload,
    saveLoading: false,
};

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {},
    extraReducers: builder =>
        builder
            .addCase(setLocation, state => {
                state.errors = [];
                state.saveLoading = true;
            })
            .addCase(setLocationSuccess, (state, action) => {
                state.errors = [];
                state.value = action.payload;
                state.saveLoading = false;
            })
            .addCase(setLocationError, (state, action) => {
                state.errors = action.payload;
                state.saveLoading = false;
            })
            .addCase(getLocation, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getLocationSuccess, (state, action) => {
                state.loading = false;
                state.value = action.payload.value;
            })
            .addCase(getLocationError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            .addCase(setNeverUseLocation, state => {
                state.loading = true;
                state.errors = [];
                state.saveLoading = true;
            })
            .addCase(setNeverUseLocationSuccess, state => {
                state.loading = false;
                state.errors = [];
                state.value.checkBox = 0;
                state.saveLoading = false;
            })
            .addCase(setNeverUseLocationError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
                state.saveLoading = false;
            }),
});
export default locationSlice.reducer;
