import React from 'react'
import {AddPaymentMethodForm, PaymentsModalWrapper} from "../components";

interface IAddPaymentMethodModalProps {
    isOpen:boolean
    title: string
    onClose: () => void
    addNewBankAccountLoading: boolean
}

export const AddPaymentMethodModal:React.FC<IAddPaymentMethodModalProps> = ({
    isOpen,
    title,
    onClose, addNewBankAccountLoading

}) => {
    return (
        <PaymentsModalWrapper
            isCentered
            isOpen={isOpen}
            title={title}
            onClose={onClose}
        >
           <AddPaymentMethodForm addNewBankAccountLoading={addNewBankAccountLoading}/>

        </PaymentsModalWrapper>
    )
}
