import { createSlice } from '@reduxjs/toolkit';

import { logout } from '../actions/auth/auth.actions';
import { PayloadError, IdNameTypes, StateDirectoryItem } from '../../common/types';
import {
    getBonusTypes,
    getBonusTypesError,
    getBonusTypesSuccess,
    getClientTypes,
    getClientTypesError,
    getClientTypesSuccess,
    getRequestCategories,
    getRequestCategoriesError,
    getRequestCategoriesSuccess,
    getRequestTypes,
    getRequestTypesError,
    getRequestTypesSuccess,
    getStates,
    getStatesError,
    getStatesSuccess,
    getAgenciesTypesRequest,
    getAgenciesTypesSuccess,
    getAgenciesTypesError,
    getPostTags,
    getPostTagsSuccess,
    getPostTagsError,
    getReportTypes,
    getReportTypesSuccess,
    getReportTypesError,
    getOpenHouseTypes,
    getOpenHouseTypesSuccess,
    getOpenHouseTypesError,
    getOpenHouseHostTypes,
    getOpenHouseHostTypesSuccess,
    getOpenHouseHostTypesError,
    getOpenHouseAudiences,
    getOpenHouseAudiencesSuccess,
    getOpenHouseAudiencesError,
    getCitiesSuccess
} from '../actions/directories.actions';

export const initialState: {
    loading: boolean;
    errors: PayloadError[];
    states: StateDirectoryItem[];
    bonusTypes: Array<IdNameTypes>;
    reportTypes: Array<IdNameTypes>;
    clientTypes: Array<IdNameTypes>;
    requestTypes: Array<IdNameTypes>;
    requestCategories: Array<IdNameTypes>;
    agenciesTypes: Array<IdNameTypes>;
    postTags: Array<IdNameTypes>;
    openHouseTypes: Array<IdNameTypes>;
    openHouseHostTypes: Array<IdNameTypes>;
    openHouseAudiences: Array<IdNameTypes>;
    cities: Array<IdNameTypes>;
} = {
    loading: false,
    errors: [],
    states: [],
    reportTypes: [],
    bonusTypes: [],
    clientTypes: [],
    postTags: [],
    requestTypes: [],
    requestCategories: [],
    agenciesTypes: [],
    openHouseAudiences: [],
    openHouseTypes: [],
    openHouseHostTypes: [],
    cities: [],
};

const directoriesSlice = createSlice({
    name: 'directories',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getStates, state => {
                state.errors = [];
            })
            .addCase(getStatesSuccess, (state, action) => {
                state.errors = [];
                state.states = action.payload.value.states;
            })
            .addCase(getStatesError, (state, action) => {
                state.errors = action.payload;
            })

            .addCase(getBonusTypes, state => {
                state.errors = [];
            })
            .addCase(getBonusTypesSuccess, (state, action) => {
                state.errors = [];
                state.bonusTypes = action.payload.value.bonusTypes;
            })
            .addCase(getBonusTypesError, (state, action) => {
                state.errors = action.payload;
            })

            .addCase(getClientTypes, state => {
                state.errors = [];
            })
            .addCase(getClientTypesSuccess, (state, action) => {
                state.errors = [];
                state.clientTypes = action.payload.value.clientTypes;
            })
            .addCase(getClientTypesError, (state, action) => {
                state.errors = action.payload;
            })

            .addCase(getRequestTypes, state => {
                state.errors = [];
            })
            .addCase(getRequestTypesSuccess, (state, action) => {
                state.errors = [];
                state.requestTypes = action.payload.value.requestTypes;
            })
            .addCase(getRequestTypesError, (state, action) => {
                state.errors = action.payload;
            })

            .addCase(getRequestCategories, state => {
                state.errors = [];
            })
            .addCase(getRequestCategoriesSuccess, (state, action) => {
                state.errors = [];
                state.requestCategories = action.payload.value.requestCategories;
            })
            .addCase(getRequestCategoriesError, (state, action) => {
                state.errors = action.payload;
            })

            .addCase(getPostTags, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getPostTagsSuccess, (state, action) => {
                state.loading = false;
                state.errors = [];
                state.postTags = action.payload.value.tags;
            })
            .addCase(getPostTagsError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })

            .addCase(getAgenciesTypesRequest, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getAgenciesTypesSuccess, (state, action) => {
                state.loading = false;
                state.agenciesTypes = action.payload;
            })
            .addCase(getAgenciesTypesError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })

            .addCase(getReportTypes, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getReportTypesSuccess, (state, action) => {
                state.loading = false;
                state.reportTypes = action.payload.value.types;
            })
            .addCase(getReportTypesError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            .addCase(getOpenHouseTypes, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getOpenHouseTypesSuccess, (state, action) => {
                state.openHouseTypes = action.payload.types;
                state.loading = false;
            })
            .addCase(getOpenHouseTypesError, (state, action) => {
                state.errors = action.payload;
                state.loading = false;
            })
            .addCase(getOpenHouseHostTypes, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getOpenHouseHostTypesSuccess, (state, action) => {
                state.openHouseHostTypes = action.payload.types;
                state.loading = false;
            })
            .addCase(getOpenHouseHostTypesError, (state, action) => {
                state.errors = action.payload;
                state.loading = false;
            })
            .addCase(getOpenHouseAudiences, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getOpenHouseAudiencesSuccess, (state, action) => {
                state.openHouseAudiences = action.payload.audiences;
                state.loading = false;
            })
            .addCase(getOpenHouseAudiencesError, (state, action) => {
                state.errors = action.payload;
                state.loading = false;
            })
            .addCase(getCitiesSuccess, (state, action) => {
                state.cities = action.payload;
                state.loading = false;
            })

            .addCase(logout, state => {
                state.loading = false;
                state.errors = [];
                state.states = [];
                state.bonusTypes = [];
                state.clientTypes = [];
                state.requestTypes = [];
                state.agenciesTypes = [];
                state.postTags = [];
                state.openHouseAudiences = [];
                state.openHouseHostTypes = [];
                state.openHouseTypes = [];
            });
    },
});

export default directoriesSlice.reducer;
