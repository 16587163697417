import React from 'react';
import { Text, VStack, Menu, MenuButton, MenuList, MenuItem, HStack } from '@chakra-ui/react';
import { Filter2Icon } from '../../../assets/icons';
import dayjs from 'dayjs';

interface IYearFilterProps {
    selectedYear: number;
    setSelectedYear: (value: number) => void;
}

export const YearFilter: React.FC<IYearFilterProps> = ({ selectedYear, setSelectedYear }) => (
    <Menu autoSelect={false} strategy="fixed" placement="left">
        <MenuButton p="7px">
            <HStack>
                <Text fontSize="18px" color="brand" mr="10px">
                    {selectedYear}
                </Text>
                <Filter2Icon />
            </HStack>
        </MenuButton>
        <MenuList>
            <VStack align="flex-start" p="15px">
                {Array(11)
                    .fill('')
                    .map((elem, index) => (
                        <MenuItem
                            key={index}
                            color="brand"
                            background={
                                dayjs().get('y') - 5 + index === selectedYear ? '#D1D2DB' : ''
                            }
                            borderRadius="8px"
                            onClick={() => setSelectedYear(dayjs().get('y') - 5 + index)}
                        >
                            {dayjs().get('y') - 5 + index}
                        </MenuItem>
                    ))}
            </VStack>
        </MenuList>
    </Menu>
);
