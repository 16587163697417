import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
    CloseButton,
} from '@chakra-ui/react';
import { colors } from '../../../../common/constants';
import { ConfirmWorkPayload, SelectedActivity } from '../../types';
import { RequestInfoType } from '../../../Requests/types';
import { AgentInfoTab, InfoTab } from './tabs';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../../store';
import { useDelta } from 'react-delta';
import { getRequestAddressesRequest, getRequestByIdRequest } from '../../store/actions';
import { getApplicantsToRequest } from '../../../Requests/store/actions';
import { Loader } from '../../../../common/components';
import { ToggleUserProfileModalPayload } from '../../../../common/types';

const tabs = [
    { index: 0, name: 'Info' },
    { index: 1, name: 'Agent Info' },
];

interface IInProgressActivityModal {
    isOpen: boolean;
    onClose: () => void;
    activity: RequestInfoType;
    requestAddresses: any;
    actualDuration: any;
    isOpenIssue: boolean;
    onCloseIssue: () => void;
    onOpenIssue: () => void;
    setShowAddFeedback: (v: boolean) => void;
    fetchItems: (page?: number) => void;
    selectedActivityId: SelectedActivity;
    requestByIdLoading: boolean;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
    onCreateChat: (userId: number) => void;
}

export const InProgressActivityModal: React.FC<IInProgressActivityModal> = ({
    isOpen,
    onClose,
    activity,
    requestAddresses,
    actualDuration,
    isOpenIssue,
    onCloseIssue,
    onOpenIssue,
    setShowAddFeedback,
    fetchItems,
    selectedActivityId,
    requestByIdLoading,
    onOpenProfileImage,
    onCreateChat,
}) => {
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);

    const { loading: deleteLoading, errors: deleteErrors } = useSelector(
        (state: RootStore) => state.requests.deleteRequest,
    );

    const applicants = useSelector((state: RootStore) => state.requests.applicants.items);

    const deleteLoadingDelta = useDelta(deleteLoading);

    useEffect(() => {
        if (deleteLoadingDelta && deleteLoadingDelta.prev) {
            if (deleteLoadingDelta.prev && !deleteLoadingDelta.curr && !deleteErrors.length) {
                onClose();
                fetchItems(1);
            }
        }
    }, [deleteLoadingDelta]);

    useEffect(() => {
        if (isOpen && selectedActivityId) {
            dispatch(getRequestByIdRequest({ id: selectedActivityId.id }));
            if (!selectedActivityId.isMyActivity) {
                dispatch(getApplicantsToRequest(selectedActivityId.id));
            }
        }
        if (!isOpen) {
            setTabIndex(0);
        }
    }, [isOpen, selectedActivityId]);

    useEffect(() => {
        if (activity.typeId === 1) {
            dispatch(getRequestAddressesRequest({ id: selectedActivityId.id }));
        }
    }, [activity]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            {!isOpenIssue && (
                <ModalContent
                    borderRadius={'18px'}
                    h={'95vh'}
                    mt="20px"
                    bgColor={colors.modalBackground}
                    alignItems="center"
                    overflowY="auto"
                >
                    <Flex
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-between"
                        w="100%"
                        px="70px"
                    >
                        <Flex justify="space-between" w="100%" pt="30px" pb="10px">
                            <Text color="brand" fontSize="22px" fontWeight="bold" lineHeight="19px">
                                {activity?.requestTypeName}
                            </Text>
                            <CloseButton zIndex={10} onClick={onClose} />
                        </Flex>
                    </Flex>

                    <ModalBody d="flex" flexDirection="column" w="100%" px="70px">
                        {requestByIdLoading ? (
                            <Loader centerHeight="100%" />
                        ) : (
                            <Tabs variant="unstyled" index={tabIndex} h={'full'}>
                                <TabList>
                                    {tabs.map(elem => (
                                        <Tab
                                            key={elem.index}
                                            border={
                                                tabIndex === elem.index
                                                    ? `1px solid ${colors.brand}`
                                                    : `1px solid ${colors.secondaryFontDarker}`
                                            }
                                            borderRadius="20px"
                                            minW="150px"
                                            h="35px"
                                            onClick={() => setTabIndex(elem.index)}
                                            bg={
                                                tabIndex === elem.index
                                                    ? colors.brand
                                                    : 'transparent'
                                            }
                                            mr="10px"
                                        >
                                            <Text
                                                fontSize="14px"
                                                color={
                                                    tabIndex === elem.index
                                                        ? 'white'
                                                        : 'secondaryFontDarker'
                                                }
                                                fontWeight={400}
                                            >
                                                {elem.name}
                                            </Text>
                                        </Tab>
                                    ))}
                                </TabList>

                                <TabPanels height="calc(100% - 40px)">
                                    <TabPanel p="0" h="100%" pt="10px">
                                        <InfoTab
                                            activity={activity}
                                            selectedActivityId={selectedActivityId}
                                            requestAddresses={requestAddresses}
                                            onClose={onClose}
                                            fetchItems={fetchItems}
                                            setShowAddFeedback={setShowAddFeedback}
                                            onOpenIssue={onOpenIssue}
                                        />
                                    </TabPanel>
                                    <TabPanel p="0" h="100%" pt="10px">
                                        <AgentInfoTab
                                            activity={activity}
                                            onClose={onClose}
                                            applicants={applicants}
                                            onOpenProfileImage={onOpenProfileImage}
                                            onCreateChat={onCreateChat}
                                        />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        )}
                    </ModalBody>
                </ModalContent>
            )}
        </Modal>
    );
};
