import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Avatar, HStack, Text, chakra, IconButton, Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import { RootStore } from '../../../../../../store';
import { toggleUserProfileModal } from '../../../../../../store/actions/profile.actions';

import { BorderIconBox } from '../../../../../../pages/Requests/components';

import { fonts, getAvatarPicture } from '../../../../../utils';
import { ISentRequestItem } from '../dashboardItemsTypes';
import { IdNameTypes } from '../../../../../types';
import { colors } from '../../../../../constants';

import {
    LocationIcon,
    DropdownArrowIcon,
    ConsultMethodIcon,
    Home,
    Calendar,
    Clock,
} from '../../../../../../assets/icons';

export const SentRequestItem: React.FC<ISentRequestItem> = ({
    addresses,
    categoryId,
    dateTime,
    duration,
    onOpenModal,
    ownerAvatar,
    ownerId,
    fromTime,
    id,
    assistRequestId,
    potentialEarnings,
    requestType,
    propertiesToShow,
    requestTypeName,
    timeSlot,
    typeId,
    state,
    title,
    ownerFullName,
}) => {
    const dispatch = useDispatch();
    const requestCategories = useSelector(
        (state: RootStore) => state.directories.requestCategories,
    );
    const category = requestCategories?.filter((item: IdNameTypes) => item.id === categoryId)[0]
        ?.name;
    return (
        <>
            <Text color="#4FD1C5" fontWeight={500} fontSize="15px" lineHeight="18px">
                {category}{' '}
            </Text>
            <Box bg="inputBackground" borderRadius="14px" p="15px" mb="20px" mt="10px">
                <Text
                    mb="15px"
                    fontSize="20px"
                    lineHeight="25px"
                    color={colors.brand}
                    fontFamily={fonts.poppins}
                    textAlign="left"
                    fontStyle="normal"
                    fontWeight="500"
                >
                    {title}
                </Text>
                <Flex>
                    <HStack align="flex-start" alignSelf="center" width="40%">
                        <Box>
                            <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={800} fontFamily={fonts.montserrat}>
                                {requestTypeName}
                            </Text>
                            <Flex alignItems="center" mt={1} width="430px">
                                <LocationIcon />
                                <Text
                                    ml={1}
                                    color="brand"
                                    fontSize="12px"
                                    lineHeight="16px"
                                    fontWeight={500}
                                    noOfLines={1}
                                >
                                    {addresses?.join('')}
                                </Text>
                            </Flex>
                        </Box>
                    </HStack>
                    <Flex
                        width="360px"
                        // borderLeft={`1px solid ${colors.secondaryFont}`}
                        // borderRight={`1px solid ${colors.secondaryFont}`}
                    >
                        {' '}
                        {typeId === 6 ? (
                            <>
                                {/* <BorderIconBox
                                    icon={<Calendar />}
                                    text={dayjs.utc(timeSlot.date).format('MM/DD/YY')}
                                    isFirst
                                />
                                <BorderIconBox
                                    icon={<Clock />}
                                    text={dayjs(timeSlot.startTime).format('hh:mmA')}
                                />
                                <BorderIconBox
                                    icon={<Clock />}
                                    text={dayjs(timeSlot.endTime).format('hh:mmA')}
                                /> */}
                            </>
                        ) : typeId === 7 ? (
                            <BorderIconBox
                                icon={
                                    <Text
                                        color="brand"
                                        fontSize="12px"
                                        fontWeight="500"
                                        fontFamily={fonts.montserrat}
                                    >
                                        State:
                                    </Text>
                                }
                                text={state}
                                isFirst
                            />
                        ) : (
                            <>
                                <BorderIconBox
                                    icon={<Calendar />}
                                    text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                                    isFirst
                                />
                                <BorderIconBox
                                    icon={<Clock />}
                                    text={dayjs(dateTime).format('hh:mm A')}
                                />
                                <BorderIconBox
                                    icon={
                                        typeId === 1 ? (
                                            <Home color={colors.brand} width="16" height="16" />
                                        ) : (
                                            <ConsultMethodIcon />
                                        )
                                    }
                                    text={
                                        typeId === 1
                                            ? `${propertiesToShow} ${pluralize(
                                                  'house',
                                                  propertiesToShow,
                                              )}`
                                            : `${duration} ${pluralize('hour', duration)}`
                                    }
                                />
                            </>
                        )}
                    </Flex>
                    <IconButton
                        onClick={() => onOpenModal(id)}
                        bg="transparent"
                        ml="auto"
                        alignSelf="center"
                        aria-label="More for job"
                        icon={
                            <chakra.span transform="rotate(-90deg)">
                                <DropdownArrowIcon />
                            </chakra.span>
                        }
                    />
                </Flex>
                <Stack mt="25px" mb="32px" border="1px solid #E5E6F0" />
                <Flex alignItems="center" mb="17px">
                    <Avatar
                        cursor="pointer"
                        src={getAvatarPicture(ownerAvatar)}
                        w="24px"
                        h="24px"
                        mr="10px"
                        onClick={() => {
                            dispatch(
                                toggleUserProfileModal({
                                    value: true,
                                    userId: ownerId,
                                }),
                            );
                        }}
                    />
                    <Text
                        color={colors.secondaryFontDarker}
                        fontFamily={fonts.montserrat}
                        fontSize="14px"
                        lineHeight="16px"
                        fontWeight={500}
                    >
                        {ownerFullName}
                    </Text>
                </Flex>
            </Box>
        </>
    );
};
