import React from 'react';
import { Flex, Text, HStack, Image, chakra, Button, Box, Stack, Avatar } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { IconDescription } from '../../../IconDescription/IconDescription';
import { CustomButton } from '../../../CustomButton/CustomButton';
import { BorderIconBox } from '../../../../../../pages/Requests/components';

import { ReactComponent as House } from '../../../../../../assets/icons/house.svg';
import { ReactComponent as Price } from '../../../../../../assets/icons/price.svg';
import { ReactComponent as LocationLime } from '../../../../../../assets/icons/location.svg';
import {
    Calendar,
    Clock,
    ConsultMethodIcon,
    DropdownArrowIcon,
    EyeIcon,
    Location,
    PricingFeatureItemIcon,
} from '../../../../../../assets/icons';

import { IOpenHouseItem } from '../dashboardItemsTypes';
import { colors } from '../../../../../constants';
import { fonts, getAvatarPicture, getTimeByTimeZone } from '../../../../../utils';
import { getDuration } from '../../../DatePicker/utils';
import { toggleUserProfileModal } from '../../../../../../store/actions/profile.actions';
import { dispatch } from '../../../../../../store';

export const OpenHouseItem: React.FC<IOpenHouseItem> = ({
    address,
    city,
    id,
    isOpenHouse,
    onOpenModal = () => {},
    onOpenManage = () => {},
    photo,
    postalCode,
    price,
    stateCode,
    type,
    timeSlot,
    timeZone = '',
    isMyListings,
    listingStatusId,
    onScheduleOpenHouse,
    title,
    ownerFullName,
    ownerAvatar,
    ownerId,
}) => {
    const duration = getDuration(timeSlot.endTime, timeSlot.startTime);
    const startTimeWithTimeZone = getTimeByTimeZone(timeSlot.startTime, timeZone).format('hh:mmA');

    return (
        <>
            <Box bg="inputBackground" borderRadius="8px" mb="20px" p="15px">
                <Flex flexDirection="column">
                    <Text
                        mb="15px"
                        fontSize="20px"
                        lineHeight="25px"
                        color={colors.brand}
                        fontFamily={fonts.poppins}
                        textAlign="left"
                        fontWeight="500"
                        fontStyle="normal"
                    >
                        {title}
                    </Text>
                    <Flex>
                        <Flex w="375px">
                            <Image w="58px" h="58px" borderRadius="16px" src={photo} />
                            <Flex ml="10px" alignSelf="center" flexDirection="column">
                                <Text
                                    color="brand"
                                    fontWeight="800"
                                    lineHeight="18px"
                                    w="300px"
                                    fontSize="15px"
                                >
                                    {address}, {city}, {stateCode} {postalCode}
                                </Text>
                                <HStack>
                                    <Location />
                                    <Text
                                        color="brand"
                                        fontSize="12px"
                                        lineHeight="16px"
                                        fontWeight={500}
                                    >
                                        {stateCode}
                                    </Text>
                                </HStack>
                            </Flex>
                        </Flex>
                        <Flex justify="center" w="370px">
                            <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
                                <IconDescription
                                    icon={<Price />}
                                    text={`$${price
                                        ?.toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                />
                                <IconDescription
                                    icon={<House />}
                                    text={type?.split('/').join(', ')}
                                />
                                <IconDescription icon={<LocationLime />} text={city} />
                            </Flex>
                        </Flex>
                        <Flex w="23%" justify="center">
                            <Button
                                onClick={() => onOpenModal(id)}
                                bg="transparent"
                                alignSelf="center"
                                leftIcon={<EyeIcon />}
                                fontSize="14px"
                                fontWeight={400}
                                color="brand"
                                textDecorationLine="underline"
                            >
                                See Details
                            </Button>
                            {!isOpenHouse && isMyListings && listingStatusId === 1 ? (
                                <CustomButton
                                    text="Schedule Open House"
                                    height="50px"
                                    bgColor="brand"
                                    w="220px"
                                    alignSelf="center"
                                    onClick={() => onScheduleOpenHouse?.(id)}
                                />
                            ) : isOpenHouse && isMyListings && listingStatusId === 1 ? (
                                <CustomButton
                                    text="Manage Open House"
                                    height="50px"
                                    bgColor="brand"
                                    alignSelf="center"
                                    w="220px"
                                    onClick={() => onOpenManage?.(id)}
                                />
                            ) : null}
                        </Flex>
                        <Flex align="center" as="button" onClick={() => onOpenModal(id)}>
                            <chakra.span transform="rotate(-90deg)">
                                <DropdownArrowIcon />
                            </chakra.span>
                        </Flex>
                    </Flex>
                    <Stack mt="25px" mb="25px" border="1px solid #E5E6F0" />
                    <Flex alignItems="center" pt="8px" pb="17px">
                        <Flex w="370px" alignItems="center">
                            <Avatar
                                cursor="pointer"
                                name={ownerFullName}
                                src={getAvatarPicture(ownerAvatar)}
                                w="24px"
                                h="24px"
                                mr="10px"
                                onClick={() => {
                                    dispatch(
                                        toggleUserProfileModal({
                                            value: true,
                                            userId: ownerId,
                                        }),
                                    );
                                }}
                            />
                            <Text
                                color={colors.secondaryFontDarker}
                                fontFamily={fonts.montserrat}
                                fontSize="14px"
                                lineHeight="16px"
                                fontWeight={500}
                                textAlign="center"
                            >
                                {ownerFullName}
                            </Text>
                        </Flex>
                        <Flex alignItems="center" w="32%">
                            <BorderIconBox
                                icon={<Calendar />}
                                text={dayjs.utc(timeSlot.date).format('MM/DD/YY')}
                                isFirst
                            />
                            <BorderIconBox icon={<Clock />} text={startTimeWithTimeZone} />
                            <BorderIconBox
                                icon={<ConsultMethodIcon />}
                                text={`${duration.hours()} hrs`}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        </>
    );
};
