import React from 'react';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { fonts } from '../../utils';
import { colors } from '../../constants';
import dots from '../../../assets/landing/dots.svg';
import { ReactComponent as AccentDots } from '../../../assets/landing/accent_dots.svg';

interface IMainPageInfoBlockProps {
    image: string;
    heading: string;
    paragraph: string;
    position: 'right' | 'left';
    index: number;
}

export const MainPageInfoBlock: React.FC<IMainPageInfoBlockProps> = ({
    image,
    heading,
    paragraph,
    position = 'right',
    index,
}) => (
    <Flex
        width="100%"
        maxW="1240px"
        padding={{ base: '0', lg: '35px 0' }}
        mb={{ base: '0', sm: '70px', lg: 0 }}
        direction={{ base: 'column', md: position === 'right' ? 'row' : 'row-reverse' }}
        justifyContent={{ md: 'space-between' }}
        alignItems="space-between"
    >
        <Box
            d="flex"
            flexDir="column"
            flex={1}
            padding={{ base: '35px 0 35px 0', md: '0' }}
            w={{ base: '100%', md: '48%' }}
        >
            <Text
                color={colors.brand}
                fontFamily={fonts.montserrat}
                fontSize={{ base: '20px', lg: '28px' }}
                lineHeight={{ base: '32px', lg: '40px' }}
                fontWeight="700"
            >
                {heading}
            </Text>
            <Box
                w="60px"
                height="4px"
                borderRadius="10px"
                bg={colors.calendarBlue}
                my={{ base: '14px', lg: '26px' }}
            />
            <Text
                color={colors.brand}
                fontFamily={{ base: fonts.montserrat, lg: fonts.mulish }}
                fontSize="16px"
                lineHeight="24px"
                fontWeight="400"
            >
                {paragraph}
            </Text>
        </Box>
        <Box w={{ base: '0', md: '30px', lg: '4%' }} />
        <Box position="relative" w={{ base: '100%', md: '48%' }} height="fit-content">
            <Image src={image} zIndex={10} position="relative" w="100%" maxW="558px" />
            <Box
                display={{ base: 'block', lg: 'none' }}
                position="absolute"
                zIndex={1}
                top="-25px"
                left="-15px"
                sx={{
                    svg: {
                        height: '70px',
                        width: '70px',
                    },
                }}
            >
                <Image src={dots} />
            </Box>
            {position === 'left' ? (
                <Box
                    position="absolute"
                    display={{ base: 'none', lg: 'block' }}
                    top="-45px"
                    left="-50px"
                    zIndex={1}
                >
                    <Image src={dots} />
                </Box>
            ) : (
                <Box
                    position="absolute"
                    bottom={{ base: '-15px', lg: '-50px' }}
                    right={{ base: '-15px', xl: '-50px' }}
                    zIndex={1}
                    display={{ base: 'none', lg: 'block' }}
                >
                    {index === 1 ? <AccentDots /> : <Image src={dots} />}
                </Box>
            )}
        </Box>
    </Flex>
);
