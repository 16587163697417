import React from 'react';
import { Box } from '@chakra-ui/react';

import { colors } from '../../../constants';
import { ResizeIcon } from '../../../../assets/icons';

const Resizer: React.FC = () => (
    <Box
        pos="absolute"
        h="44px"
        w="44px"
        bg={colors.brand}
        opacity={0.7}
        borderRadius="7px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        top="15px"
        left="15px"
        cursor="pointer"
        _hover={{
            opacity: 0.5,
        }}
    >
        <ResizeIcon />
    </Box>
);

export { Resizer };
