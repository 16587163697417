import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Center, IconButton, Image, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { CloseButton, PicImageIcon } from '../../../assets/icons';

interface IImageUploadProps {
    onDrop: (acceptedFiles: any) => void;
    uploadedFile: any;
    setUploadedFile: Dispatch<SetStateAction<string | ArrayBuffer | null>>;
}

export const ImageUpload: React.FC<IImageUploadProps> = ({
    onDrop,
    uploadedFile,
    setUploadedFile,
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <Box
            {...getRootProps()}
            height={'220px'}
            overflow={'hidden'}
            borderRadius={'24px'}
            bg={isDragActive ? '#F6F7FB' : ''}
            border={'1px dashed #888DAB'}
            position={'relative'}
        >
            <Center flexDirection={'column'} h={'100%'}>
                <input {...getInputProps()} />

                {uploadedFile ? (
                    <Image
                        objectFit={'contain'}
                        alt={'post photo'}
                        src={
                            typeof uploadedFile === 'string'
                                ? uploadedFile
                                : URL.createObjectURL(uploadedFile)
                        }
                    />
                ) : (
                    <>
                        <PicImageIcon />
                        <Box mt={'25px'}>
                            {isDragActive ? (
                                <Text>Drop the file here</Text>
                            ) : (
                                <Button color="brand" variant={'outline'}>
                                    Upload Cover Photo
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </Center>
            {uploadedFile && (
                <IconButton
                    onClick={e => {
                        e.stopPropagation();
                        setUploadedFile(null);
                    }}
                    top={'10px'}
                    right={'10px'}
                    position={'absolute'}
                    aria-label={'delete image'}
                    icon={<CloseButton color={'white'} />}
                    variant="ghost"
                    _hover={{
                        background: 'gray',
                    }}
                />
            )}
        </Box>
    );
};
