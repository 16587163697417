import React from 'react';
import Countdown, { CountdownRendererFn, zeroPad } from 'react-countdown';

export const countdownRenderer: CountdownRendererFn = ({ minutes, seconds }) => {
    return (
        <span>
            {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
    );
};
