import { createAction } from '@reduxjs/toolkit';
import { PayloadError, IResponseWithCustomValue } from '../../../common/types';
import { GetAgentOpenHousesSuccessResponse, GetFilteredOpenHousesPayload } from '../types';

export const getMyAgencyOpenHouses = createAction<GetFilteredOpenHousesPayload>(
    'GET_MY_AGENCY_OPEN_HOUSES',
);
export const getMyAgencyOpenHousesSuccess = createAction<{
    pageNumber: number;
    result: IResponseWithCustomValue<GetAgentOpenHousesSuccessResponse>;
}>('GET_MY_AGENCY_OPEN_HOUSES_SUCCESS');
export const getMyAgencyOpenHousesError = createAction<PayloadError[]>(
    'GET_MY_AGENCY_OPEN_HOUSES_ERROR',
);

export const getPartnerAgencyOpenHouses = createAction<GetFilteredOpenHousesPayload>(
    'GET_PARTNER_AGENCY_OPEN_HOUSES',
);
export const getPartnerAgencyOpenHousesSuccess = createAction<{
    pageNumber: number;
    result: IResponseWithCustomValue<GetAgentOpenHousesSuccessResponse>;
}>('GET_PARTNER_AGENCY_OPEN_HOUSES_SUCCESS');
export const getPartnerAgencyOpenHousesError = createAction<PayloadError[]>(
    'GET_PARTNER_AGENCY_OPEN_HOUSES_ERROR',
);

export const startLoadMyListings = createAction('START_LOAD_MY_LISTINGS');
