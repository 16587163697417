import React, { Dispatch, SetStateAction } from 'react';
import {
    Avatar,
    Box,
    Divider,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Image,
    Text,
} from '@chakra-ui/react';

import { fonts } from '../../../../common/utils';
import { colors } from '../../../../common/constants';
import { CloseButton, LocationIcon } from '../../../../assets/icons';
import { CustomButton, Resizer } from '../../../../common/components';
import { HouseDetailsInfo, MappedHouseDetailsInfo } from '../../components';

interface IOpenHouseRequestDetailsModal {
    isOpen: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
}

export const OpenHouseRequestDetailsModal: React.FC<IOpenHouseRequestDetailsModal> = ({
    isOpen,
    onClose,
}) => {
    /*const buildingAndConstructions = MappedHouseDetailsInfo(openHouseInfo?.buildingAndConstructionsFeatureText, 'Building and Construction:');
    const heatingAndCooling = MappedHouseDetailsInfo(openHouseInfo?.heatingAndCooling, 'Heating and Cooling:');
    const roadFrontageType = MappedHouseDetailsInfo(openHouseInfo?.roadFrontageType, 'Road Frontage Type:');
    const landInfo = MappedHouseDetailsInfo(openHouseInfo?.landInfo, 'Land Info:');
    const homeownersAssociation = MappedHouseDetailsInfo(openHouseInfo?.homeownersAssociation, 'Homeowners Association');
    const schoolDistrict = MappedHouseDetailsInfo(openHouseInfo?.schoolDistrict, 'School District:');
    const otherPropertyInfo = MappedHouseDetailsInfo(openHouseInfo?.otherPropertyInfo, 'Other Property Info:');
    const utilities = MappedHouseDetailsInfo(openHouseInfo?.utilities, 'Utilities:');*/

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose(!isOpen)}
            size="large"
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent
                w={'60vw'}
                h={'85vh'}
                borderRadius={'18px'}
                bgColor="#EBF2F8"
                alignItems="center"
            >
                <Flex mt="36px" alignItems="center" justifyContent="space-between" w="50vw">
                    <Text
                        color={colors.brand}
                        fontWeight={700}
                        fontSize="22px"
                        lineHeight="18px"
                        fontFamily={fonts.montserrat}
                    >
                        Open House
                    </Text>
                    <HStack spacing="6px">
                        <CustomButton
                            height="34px"
                            width="170px"
                            text="Info"
                            bgColor={colors.brand}
                        />
                        <CustomButton
                            height="34px"
                            width="170px"
                            text="Applicants"
                            bgColor="transparent"
                            color={colors.secondaryFontDarker}
                            borderColor={colors.secondaryFontDarker}
                        />
                    </HStack>
                    <Box as="button" onClick={() => onClose(!isOpen)}>
                        <CloseButton />
                    </Box>
                </Flex>
                <ModalBody d="flex" w="50vw" flexDirection="column" p={0} mt={10}>
                    <Flex>
                        <Box>
                            <Box pos="relative">
                                <Resizer />
                                <Image
                                    height="280px"
                                    w="500px"
                                    src="https://bit.ly/sage-adebayo"
                                    alt="Segun Adebayo"
                                />
                            </Box>
                            <Flex mt={4} alignItems="center" justifyContent="space-between">
                                <Text
                                    color={colors.brand}
                                    fontWeight={700}
                                    fontSize="22px"
                                    lineHeight="33px"
                                    fontFamily={fonts.poppins}
                                >
                                    $1,500,000
                                </Text>
                                <Flex>
                                    <HStack pr={3}>
                                        <Text
                                            color={colors.brand}
                                            fontSize="13px"
                                            lineHeight="20px"
                                            fontFamily={fonts.poppins}
                                        >
                                            4 bd
                                        </Text>
                                        <Divider
                                            orientation="vertical"
                                            borderColor={colors.secondaryFontDarker}
                                        />
                                    </HStack>
                                    <HStack pr={3}>
                                        <Text
                                            color={colors.brand}
                                            fontSize="13px"
                                            lineHeight="20px"
                                            fontFamily={fonts.poppins}
                                        >
                                            2 ba
                                        </Text>
                                        <Divider
                                            orientation="vertical"
                                            borderColor={colors.secondaryFontDarker}
                                        />
                                    </HStack>
                                    <HStack>
                                        <Text
                                            color={colors.brand}
                                            fontSize="13px"
                                            lineHeight="20px"
                                            fontFamily={fonts.poppins}
                                        >
                                            2,126 sqft
                                        </Text>
                                    </HStack>
                                </Flex>
                            </Flex>
                            <HStack spacing={2} mt={2}>
                                <LocationIcon
                                    color={colors.secondaryFontDarker}
                                    height="20px"
                                    width="20px"
                                />
                                <Text
                                    color={colors.secondaryFontDarker}
                                    fontSize="13px"
                                    lineHeight="20px"
                                    fontFamily={fonts.poppins}
                                >
                                    555 Sunset Blvd, Los Angeles, CA 90210
                                </Text>
                            </HStack>
                            <HStack spacing={2} mt="33px">
                                <Avatar name="John Doe" w="54px" h="54px" />
                                <Box>
                                    <Text
                                        color={colors.brand}
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="23px"
                                        fontFamily={fonts.poppins}
                                    >
                                        Natalie Vauge
                                    </Text>
                                    <Text
                                        color={colors.secondaryFontDarker}
                                        fontSize="12px"
                                        lineHeight="18px"
                                        fontFamily={fonts.poppins}
                                    >
                                        MLS Id: 374623
                                    </Text>
                                </Box>
                            </HStack>
                            <Text
                                mt="30px"
                                color={colors.brand}
                                fontWeight={700}
                                fontSize="16px"
                                lineHeight="24px"
                                fontFamily={fonts.poppins}
                            >
                                About house
                            </Text>
                            <Text
                                mt={1}
                                color={colors.secondaryFontDarker}
                                fontSize="16px"
                                lineHeight="26px"
                                fontFamily={fonts.poppins}
                            >
                                Great home for a great price! Remodeled for modern living, all wood
                                flooring, updated bath with tiled shower, and fresh paint.
                                Completely private fenced yard with shed, great corner lot. Best Buy
                                on the market. Licensed Michigan Realtor to be physically present
                                for the entire duration of all showings! Buyers agent to verify all
                                info & measurements! Follow all CDC recommendations when showing!
                            </Text>
                        </Box>
                        {/*<Box>
                            <HouseDetailsInfo detail={openHouseInfo?.stateCode} name="'State code:'" />
                            {ListingDetailBox(openHouseInfo?.stateCode, 'State code:')}
                            {ListingDetailBox(openHouseInfo?.country, 'Country:')}
                            {ListingDetailBox(openHouseInfo?.line, 'Address:')}
                        </Box>
                        <Box>
                            {ListingDetailBox(openHouseInfo?.postalCode, 'Postal code:')}
                            {ListingDetailBox(openHouseInfo?.city, 'City:')}
                            {ListingDetailBox(openHouseInfo?.county, 'County:')}
                        </Box>*/}
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
