import React from 'react';
import { colors } from '../../common/constants';

export const SearchBoldIcon: React.FC = () => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.1566 13.6434C17.3905 11.8996 17.9354 9.76089 17.6862 7.63928C17.437 5.51768 16.4114 3.56336 14.8071 2.15295C13.2027 0.742533 11.1331 -0.0241713 8.99703 0.000581158C6.86099 0.0253336 4.80969 0.839791 3.23843 2.28701C1.66716 3.73422 0.687137 5.71177 0.487199 7.83858C0.287261 9.96539 0.881561 12.0909 2.15558 13.8056C3.42959 15.5203 5.29316 16.7028 7.38717 17.1252C9.48117 17.5477 11.6574 17.1801 13.4966 16.0934L19.8735 22.4324C20.2041 22.7805 20.6595 22.983 21.1394 22.9954C21.6193 23.0078 22.0845 22.829 22.4325 22.4984C22.7806 22.1678 22.9832 21.7125 22.9955 21.2326C23.0079 20.7527 22.8291 20.2875 22.4985 19.9394C22.4765 19.9164 22.4555 19.8954 22.4325 19.8734L16.1566 13.6434ZM9.09255 14.2344C7.98962 14.235 6.91128 13.9086 5.9938 13.2965C5.07633 12.6844 4.36091 11.814 3.93798 10.7954C3.51504 9.77678 3.40357 8.65565 3.61765 7.5737C3.83173 6.49175 4.36175 5.49755 5.14073 4.71675C5.9197 3.93596 6.91267 3.40362 7.99412 3.18702C9.07556 2.97042 10.197 3.07928 11.2165 3.49985C12.2361 3.92041 13.1082 4.6338 13.7224 5.54985C14.3367 6.46589 14.6656 7.54347 14.6676 8.6464C14.6687 9.37946 14.5254 10.1056 14.2458 10.7832C13.9663 11.4609 13.5558 12.0768 13.0381 12.5958C12.5203 13.1147 11.9054 13.5265 11.2284 13.8077C10.5514 14.0889 9.82562 14.2339 9.09255 14.2344Z"
            fill={colors.brand}
        />
    </svg>
);
