export const transformAddress = (array: any) => {
    const state = array.filter((item: any) => item.types.includes('administrative_area_level_1'));
    const area = array.filter((item: any) => item.types.includes('locality'));

    if (area.length === 0) {
        alert('You need pick city');
        return {
            state: '',
            area: '',
        };
    }

    return {
        state: state?.[0]?.short_name,
        area: area?.[0].long_name,
    };
};
