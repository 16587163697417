import React, { Dispatch } from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';

import { CustomDropdown } from '../../../CustomDropdown/CustomDropdown';

import { FilterIcon } from '../../../../../../assets/icons';
import { colors, sortByTypesDashboard } from '../../../../../constants';
import { fonts } from '../../../../../utils';
import { FilterState } from '../../../../../types/dashboard.types';

type ModalOptionsProps = {
    type: '' | 'modalFilterDashboard';
};

interface IFiltersBlockProps {
    setFilterState: Dispatch<React.SetStateAction<FilterState>>
    sortingType: number;
    setOpenModal: (type: ModalOptionsProps) => void;
}

export const FiltersBlock: React.FC<IFiltersBlockProps> = ({
    sortingType,
    setFilterState,
    setOpenModal,
}) => {
    return (
        <Flex mt="20px">
            <Flex flexDirection="column">
                <Text
                    fontSize="14px"
                    fontFamily={fonts.poppins}
                    fontWeight="500"
                    lineHeight="21px"
                    color={colors.brand}
                    mb="12px"
                >
                    Sort By
                </Text>
                <CustomDropdown
                    label=""
                    options={sortByTypesDashboard}
                    register={{
                        onChange: (ev: React.ChangeEvent<HTMLSelectElement>) => {
                            setFilterState((prev: FilterState) => ({
                                ...prev,
                                sortingType: +ev.target.value,
                            }));
                        },
                        value: sortingType,
                    }}
                />
            </Flex>
            <Flex flexDirection="column">
                <Text
                    fontSize="14px"
                    fontFamily={fonts.poppins}
                    fontWeight="500"
                    lineHeight="21px"
                    color={colors.brand}
                    mb="12px"
                    ml="20px"
                >
                    Filter By
                </Text>
                <IconButton
                    onClick={() => setOpenModal({ type: 'modalFilterDashboard' })}
                    aria-label="filter"
                    icon={<FilterIcon />}
                    ml="20px"
                    h="50px"
                    w="50px"
                />
            </Flex>
        </Flex>
    );
};