import ByTheDatesNovemberSvg from '../../../../assets/landing/by-the-dates-november.svg';
import ByTheDatesMarchSvg from '../../../../assets/landing/by-the-dates-march.svg';
import ByTheDatesAprilSvg from '../../../../assets/landing/by-the-dates-april.svg';

export const firstSectionText =
    'Founded by real estate and compliance experts, ZootPro is the first networking and collaboration software solution for the real estate industry that effectively provides instant access to fellow affiliated agents for collaboration and networking on clients, and listings, among other real estate activities. There is a notable dearth of technology tools for real estate agents looking to collaborate with other agents and third party providers. ZootPro was especially created to provide a legally sound and compliant tool for collaboration, outsourcing, productivity, and networking that is currently absent in the marketplace. Growing a real estate business can get really hectic without help from supporting licensed agents, assistants, vendors, and the larger industry. ZootPro makes all the difference for agents motivated towards success in their business.';

export const familySectionTextArr = [
    'For the entrepreneurial agent who seeks the independence of not having to manage a full time employee or split commissions as part of a team, ZootPro helps you find, connect, hire and pay affiliated agents who are ready, available, and willing to assist in any activity related to your business at the touch of a button.',
    'The peace of mind that is gained from not having to worry about violating any compliance rules for the delegation of tasks is a powerful one, and the instant fiduciary leverage that is gained for all of your clients by placing the entire roster of agents in your brokerage at your fingertips is immeasurable.',
    'With ZootPro, agents can spend more time on the things that matter most, family, their passions, or simply generating and closing more deals. ZootPro even helps agents find business that would otherwise be unavailable from within the brokerage.',
];

export const joinTeamAdditionalText =
    'We are always looking for fast-paced people with bright ideas to come aboard. Whether you’re a fast learner, technologist, real estate enthusiast, marketer, researcher or designer, we are seeking ambitious, self-starters who are ready to grow with our company.';

export const whyItWorksCardsContent = [
    {
        header: 'Real Estate Agent Collaboration Tool',
        text: 'Use ZootPro as the ultimate real estate agent collaboration tool.  Find like-minded affiliated agents who can join forces with you so that you can juggle the many other clients, properties, and commitments that you would otherwise avoid taking on.  Whether you’re needing extra time or extra hands, the real estate agent collaboration tool can help.',
    },
    {
        header: 'Real Estate Agent Showings Tool',
        text: 'With the real estate agent showings tool ZootPro can help you find a licensed and affiliated agent to help out with those last-minute showings, open house and calendar conflicts that may arise with clients, listings and more in the span of 5-minutes.  You don’t have to worry about an extra employee with ZootPro, just vetted agents who are willing to help.',
    },
    {
        header: 'Real Estate Off Market Listings Tool',
        text: 'Whether you have a client who is wanting to test the waters of the market, or you have a client looking for a more private sale, finding off-market listings requires an extensive network of agents.  With the ZootPro off market listings tool, you’ll be a pro at finding exactly what your clients need from within your agency, or within your larger market area.',
    },
    {
        header: 'Real Estate Inspections, Walkthroughs & Open Houses',
        text: 'Helping your clients buy or sell a home is hard work, and there are many steps involved. With ZootPro you can save time and increase your profit margin by having help as quickly as you might need it, with real estate inspections, walkthroughs, and open houses, all without the added hassle of hiring help.',
    },
];

export const ourFoundersTextFirst =
    'ZootPro was founded in 2020, however Yvonne and Sharif, the husband-and-wife team that dreamed it up had been working separately, Sharif, as a real estate agent and Yvonne, as a technologist turned corporate and compliance lawyer.  They began working side by side since 2017.  The opportunity for synergy between a real estate agent and an attorney was clear. With a clear vision for helping people build wealth through real estate, they embarked on a joint real estate venture.';

export const ourFoundersTextSecond = [
    'They decided to work together to build a real estate business that caters to new house buyers seeking sharp and expert guidance to navigate the real estate market while Yvonne’s role was to serve to help make sure deals were meeting their legal and regulatory requirements.',
    'The business began to grow and the clients slowly came flowing in. However, not too long after client needs started to increase, they saw a definitive gap in the industry. Whenever Sharif was overextended and unable to get help for real estate tasks and resources, he would turn to his brokerage office to only find that there were no effective resources available to him without his hiring a full time real estate agent employee or joining a team.',
];

export const ourFoundersTextThird =
    'It happened multiple times where help was needed without a reasonable or viable solution; while on vacation and a client needed to gain access for a re-inspection, or juggling multiple clients meetings, or trying to fit multiple listings into the schedule on the same day as a final walkthrough, or getting called by agents who needed help with a showing or an open house and being unavailable to assist... It was very stressful to try to run a people business without also running yourself into the ground and being constantly exhausted for it.  Something needed to give.';

export const ourFoundersTextFourth = [
    'What Sharif and Yvonne realized early on was that the benefit of being able to rely on someone else when licensed real estate help was needed was necessary and unnegotiable. The fact that filling this need had to be done in a way that complied with applicable real estate laws and regulations as well as rules of agency for different states was a priority for Yvonne. This person needed to also be a licensed agent, and most importantly, they needed to also be affiliated to the same brokerage.',
    'As an agent licensed in multiple states, where a few of these states followed common law rules while the other did not and were Commonwealth states, it became clear why the real estate industry had remained fragmented for such a long time that this pressing need of real estate agents in the industry was not being met.',
];

export const ourFoundersTextFive = [
    'Whether it was an agent having more information and knowledge in certain areas yet being unable to effectively spread this knowledge for a lack of time, or a real estate assistant helping with scheduling open houses and overbookings yet finding that more could still be done to engage fully with the entire book of real estate clients and listings, it was obvious that networking and having assistance was what would make their real estate business successful.',
    'Defining these needs helped them create the ZootPro system so that real estate professionals all over the country would have a trusted and dedicated community to turn to for the resources they need to be able to complete licensed real estate activities, in a legally sound and compliant manner, quickly and effectively.',
];

export const byTheDatesCardsContent = [
    {
        img: ByTheDatesNovemberSvg,
        header: 'November 2020',
        text: ' Company founded',
    },
    {
        img: ByTheDatesMarchSvg,
        header: 'March 2022',
        text: 'Mobile & web applications published',
    },
    {
        img: ByTheDatesAprilSvg,
        header: 'April 2022',
        text: 'First user joins  ZootPro',
    },
];
