import React from 'react';
import { Flex, Text, Switch } from '@chakra-ui/react';

interface ITaskAvailabilityItemProps {
    label: string;
    value: number;
    isChecked: boolean;
    loading: boolean;
    onChange: (item: { value: number; label: string }) => void;
}

export const TaskAvailabilityItem: React.FC<ITaskAvailabilityItemProps> = ({
    label,
    value,
    isChecked,
    loading,
    onChange,
}) => {
    return (
        <Flex justifyContent="space-between" align="center" mb="26px">
            <Text fontSize="18px" fontWeight={300} lineHeight="27px" color="brand">
                {label}
            </Text>
            <Switch
                isChecked={isChecked}
                onChange={e => onChange({ label, value })}
                disabled={loading}
                mt="5px"
                colorScheme="switchScheme"
                sx={{
                    '.chakra-switch__track': {
                        background: '#888DAB',
                    },
                }}
            />
        </Flex>
    );
};
