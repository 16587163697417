import { call, put, takeEvery } from '@redux-saga/core/effects';
import API from '../../../common/api/api.base';
import { setCurrentAgencyError, setCurrentAgencyRequest } from './actions';
import { IResponseWithCustomValue } from '../../../common/types';
import { getUserInfoRequest } from '../../../store/actions/user.actions';

function* workerSetCurrentAgency(action: ReturnType<typeof setCurrentAgencyRequest>) {
    try {
        const result: IResponseWithCustomValue<number> = yield call(
            API.put,
            `/api/profile/set-current-agency?AgencyId=${action.payload.id}`,
            {},
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${action.payload.token}`,
            },
        );
        if (result.success) {
            yield put(getUserInfoRequest());
        }
    } catch (err) {
        yield put(setCurrentAgencyError());
    }
}

export default function* watchSetCurrentAgencySaga() {
    yield takeEvery(setCurrentAgencyRequest.type, workerSetCurrentAgency);
}
