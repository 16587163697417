import React from 'react';
import { Modal, ModalOverlay, ModalContent, Box } from '@chakra-ui/react';
import { RequestInfoType, RequestAddressesType } from '../../../../pages/Requests/types';
import { FinishWorkPayload } from '../../../../pages/Activity/types';
import { PropertiesSelectionConfirm } from './PropertiesSelectionConfirm';
import { DurationConfirmation } from './DurationConfirmation';

interface IFinishWorkModalProps {
    isOpen: boolean;
    onClose: () => void;
    requestInfo: RequestInfoType;
    onFinishWork: (data: FinishWorkPayload) => void;
    requestAddresses: RequestAddressesType[];
    finishWorkLoading: boolean;
}

export const FinishWorkModal: React.FC<IFinishWorkModalProps> = ({
    isOpen,
    onClose,
    requestInfo,
    onFinishWork,
    requestAddresses,
    finishWorkLoading,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
            <ModalOverlay />
            <ModalContent
                flexDirection="column"
                alignItems="center"
                borderRadius="20px"
                p="40px 50px"
            >
                {requestInfo.typeId === 1 && requestInfo.requestCategoryId === 1 ? (
                    <PropertiesSelectionConfirm
                        requestAddresses={requestAddresses}
                        requestInfo={requestInfo}
                        onFinishWork={onFinishWork}
                        onClose={onClose}
                        finishWorkLoading={finishWorkLoading}
                    />
                ) : (
                    <DurationConfirmation
                        onClose={onClose}
                        requestInfo={requestInfo}
                        onFinishWork={onFinishWork}
                        finishWorkLoading={finishWorkLoading}
                    />
                )}
            </ModalContent>
        </Modal>
    );
};
