import dayjs from 'dayjs';

export const combineDateAndTime = (date: string, time: string): string => {
    const fromTime = dayjs.utc(time || undefined);
    return dayjs
        .utc(date || undefined)
        .set('hour', fromTime.get('hour'))
        .set('minute', fromTime.get('minute'))
        .toISOString();
};

export const formatHoursToDateTime = (time: string) =>
    dayjs(new Date())
        .set('hour', +time.split(':')[0])
        .set('minute', +time.split(':')[1])
        .set('seconds', 0)
        .set('milliseconds', 0);
