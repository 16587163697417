import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { ErrorMessages } from './messages';

export type CreatePostShowingFormValues = {
    date: string;
    states: Array<string>;
    addresses: Array<{ name: string }>;
    fromTime: string;
    propertiesToShow: number;
    pricePerDoor: number;
    description: string;
    isShowingScheduled: string;
    clientTypeId: number;
    wouldLikeToOfferBonus: string;
    bonusTypeId: string;
    amount: number;
    privateNote: string;
    clientName: string;
    clientPhone: string;
    price: number;
};

const addressModelSchema = {
    name: yup.string().required(ErrorMessages.REQUIRED),
};

const createPostShowingSchema = yup.object().shape({
    date: yup
        .string()
        .required(ErrorMessages.REQUIRED)
        .test('in_the_future', ErrorMessages.PAST_DATE, function (value?: string) {
            return dayjs.utc(value ? +value : undefined).isSameOrAfter(dayjs(), 'day');
        })
        .test('not_after_thirty_days', ErrorMessages.NOT_AFTER_30_DAYS, function (value?: string) {
            return dayjs
                .utc(value ? +value : undefined)
                .isSameOrBefore(dayjs.utc().add(30, 'day'), 'day');
        }),
    states: yup.array().required(ErrorMessages.REQUIRED),
    addresses: yup.array().of(yup.object().shape(addressModelSchema)),
    fromTime: yup.string().required(ErrorMessages.REQUIRED),
    propertiesToShow: yup
        .number()
        .required(ErrorMessages.REQUIRED)
        .min(1)
        .max(15)
        .integer('Should be integer')
        .typeError('Must be a number'),
    pricePerDoor: yup
        .number()
        .required(ErrorMessages.REQUIRED)
        .positive()
        .typeError('Must be a number'),
    description: yup.string(),
    isShowingScheduled: yup.string().required(ErrorMessages.REQUIRED),
    clientTypeId: yup.number().required(ErrorMessages.REQUIRED),
    wouldLikeToOfferBonus: yup.string().required(ErrorMessages.REQUIRED),
    bonusTypeId: yup.number(),
    amount: yup.number().positive().typeError('Must be a number'),
    privateNote: yup.string(),
    clientName: yup.string(),
    clientPhone: yup.string(),
    price: yup.number().required(ErrorMessages.REQUIRED),
});

export default yupResolver(createPostShowingSchema);
