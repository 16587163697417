import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OpenHouseDetailsOpenConfig } from '../../common/types';
import { RootStore } from '../../store';
import { getListingTypesRequest, getMyListings } from '../../store/actions/listings.actions';
import { AddTimeSlotModal, ListingDetailsModal, OpenhouseDetailsModal } from '../Listings/modals';
import { HostOpenHouse } from './HostOpenHouse';
import {
    getMyAgencyOpenHouses,
    getPartnerAgencyOpenHouses,
    startLoadMyListings,
} from './store/actions';
import { GetFilteredOpenHousesPayload } from './types';

const defaultOpenHouseDetailsOpenConfig = {
    isOpen: false,
    isMyListings: true,
    isManageOpenHouse: false,
    listingStatusId: 0,
};

export const HostOpenHouseContainer: React.FC = () => {
    const loading = useSelector((state: RootStore) => state.listings.loading);
    const {
        myAgenciesLoading,
        partnerAgenciesLoading,
        partnerAgencyOpenHousesTotalCount,
        partnerAgencyOpenHouses,
    } = useSelector((state: RootStore) => state.listings);
    const openHouses = useSelector((state: RootStore) => state.listings.myAgencyOpenHouses);
    const myAgencyOpenHousesTotalCount = useSelector(
        (state: RootStore) => state.listings.myAgencyOpenHousesTotalCount,
    );
    const { loading: listingTypesLoading, items: listingTypes } = useSelector(
        (state: RootStore) => state.listings.listingTypes,
    );
    const { openHouseTypes, openHouseAudiences, openHouseHostTypes, openHouseTypesLoading } =
        useSelector((state: RootStore) => state.directories);
    const { loading: addSlotLoading, errors: addSlotErrors } = useSelector(
        (state: RootStore) => state.listings.addTimeSlot,
    );

    const dispatch = useDispatch();
    const onStartLoadMyListings = () => dispatch(startLoadMyListings());
    const onGetMyAgencyOpenHouses = (data: GetFilteredOpenHousesPayload) => {
        dispatch(getMyAgencyOpenHouses(data));
    };
    const onGetPartnerAgencyOpenHouses = (data: GetFilteredOpenHousesPayload) => {
        dispatch(getPartnerAgencyOpenHouses(data));
    };
    const onGetListingTypes = () => {
        dispatch(getListingTypesRequest());
    };

    const [showOpenhouseInfoModal, setShowOpenhouseInfoModal] =
        useState<OpenHouseDetailsOpenConfig>(defaultOpenHouseDetailsOpenConfig);
    const [showListingInfoModal, setShowListingInfoModal] = useState(false);
    const [toShowListingId, setToShowListingId] = useState(0);
    const [showAddTimeSlotModal, setShowAddTimeSlotModal] = useState(false);

    return (
        <>
            <HostOpenHouse
                loading={loading}
                openHouses={openHouses}
                onStartLoadMyListings={onStartLoadMyListings}
                onGetMyAgencyOpenHouses={onGetMyAgencyOpenHouses}
                onGetListingTypes={onGetListingTypes}
                listingTypesLoading={listingTypesLoading}
                myAgenciesLoading={myAgenciesLoading}
                listingTypes={listingTypes}
                myAgencyOpenHousesTotalCount={myAgencyOpenHousesTotalCount}
                onGetPartnerAgencyOpenHouses={onGetPartnerAgencyOpenHouses}
                partnerAgenciesLoading={partnerAgenciesLoading}
                partnerAgencyOpenHousesTotalCount={partnerAgencyOpenHousesTotalCount}
                partnerAgencyOpenHouses={partnerAgencyOpenHouses}
                setToShowListingId={setToShowListingId}
                setShowOpenhouseInfoModal={setShowOpenhouseInfoModal}
                setShowListingInfoModal={setShowListingInfoModal}
            />
            <OpenhouseDetailsModal
                showOpenhouseInfoModal={showOpenhouseInfoModal}
                onClose={() => {
                    setShowOpenhouseInfoModal(defaultOpenHouseDetailsOpenConfig);
                    setToShowListingId(0);
                }}
                listingId={toShowListingId}
                setShowAddTimeSlotModal={setShowAddTimeSlotModal}
                getItems={() => dispatch(getMyListings())}
            />
            <AddTimeSlotModal
                isOpen={showAddTimeSlotModal}
                onClose={() => setShowAddTimeSlotModal(false)}
                listingId={toShowListingId}
                loading={addSlotLoading}
                openHouseTypesLoading={openHouseTypesLoading}
                openHouseHostTypes={openHouseHostTypes}
                openHouseAudiences={openHouseAudiences}
                openHouseTypes={openHouseTypes}
            />
            <ListingDetailsModal
                isOpen={showListingInfoModal}
                onClose={() => {
                    setShowListingInfoModal(false);
                    setToShowListingId(0);
                }}
                listingId={toShowListingId}
            />
        </>
    );
};
