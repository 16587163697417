import React from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';
import { fonts } from '../../../../common/utils';
import { colors } from '../../../../common/constants';
import dots from '../../../../assets/landing/dots.svg';
import accentDots from '../../../../assets/landing/accent_dots.svg';

interface IWhyBlockProps {
    img: string;
    header: string;
    text: string;
    hasDots?: 'top' | 'bottom';
}

export const ByTheDatesCard: React.FC<IWhyBlockProps> = ({ img, text, header, hasDots }) => {
    const renderDots = () => {
        if (hasDots === 'top') {
            return (
                <Box
                    position="absolute"
                    top="-30px"
                    left="-30px"
                    zIndex={-1}
                    display={{ base: 'none', md: 'block' }}
                >
                    <Image src={dots} />
                </Box>
            );
        }

        if (hasDots === 'bottom') {
            return (
                <Box
                    position="absolute"
                    display={{ base: 'block', md: 'none' }}
                    bottom={{ base: '-50px', '2xl': '25%' }}
                    right={{ base: '-10px', '2xl': '-60px' }}
                    zIndex={-1}
                >
                    <Image src={accentDots} />
                </Box>
            );
        }

        return null;
    };

    return (
        <Box
            w="380px"
            m={{ base: '10px 0', md: '10px' }}
            bgColor="#DFEDFD"
            color={colors.brand}
            fontFamily={fonts.montserrat}
            padding="40px"
            align="left"
            borderRadius="15px"
            position="relative"
        >
            <Image src={img} alt="by the dates image" pb="50px" />
            <Heading fontSize="28px" weight="700" lineHeight="34px" pb="15px">
                {header}
            </Heading>

            <Text fontSize="16px" lineHeight="26px">
                {text}
            </Text>

            {renderDots()}
        </Box>
    );
};
