import React from 'react';
import { colors } from '../../common/constants';

export const CheckUnsubscribeIcon: React.FC = () => (
    <svg width="57" height="39" viewBox="0 0 57 39" fill="none">
        <path
            d="M2 18.3077L20.3462 36.6538L55 2"
            stroke={colors.brand}
            strokeWidth="2"
            strokeLinecap="square"
        />
    </svg>
);
