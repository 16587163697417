import React, { useState } from 'react';
import { Box, Flex, VStack, Text, Checkbox, Link } from '@chakra-ui/react';
import {
    CustomButton,
    CustomDropdown,
    CustomInput,
    CustomTextarea,
} from '../../../../common/components';
import { combineDateAndTime, fonts } from '../../../../common/utils';
import { useForm } from 'react-hook-form';
import { contactResolver } from '../../../../common/schemes/contact.schema';
import { SendContactFormType } from '../../../../common/types';
import { Typography } from '../../../../common/components/blocks/Typography/Typography';

interface ContactForm {
    firstName: string;
    lastName: string;
    workEmail: string;
    mobilePhone: string;
    companyOrganizationName: string;
    reasonForYourInquiry: number;
    howCanWeHelp: string;
}

interface IContactForm {
    onSendContactForm: (payload: SendContactFormType) => void;
    sendingContactLoading: boolean;
}

export const ContactForm: React.FC<IContactForm> = ({
    onSendContactForm,
    sendingContactLoading,
}) => {
    const [checkBox, setCheckBox] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<ContactForm>({ resolver: contactResolver });

    const onSubmit = (data: SendContactFormType) => {
        const payload = {
            firstName: data.firstName,
            lastName: data.lastName,
            workEmail: data.workEmail,
            mobilePhone: `+1${data.mobilePhone.replace(/-/g, '')}`,
            companyOrganizationName: data.companyOrganizationName,
            reasonForYourInquiry: Number(data.reasonForYourInquiry),
            howCanWeHelp: data?.howCanWeHelp || '',
        };

        onSendContactForm(payload);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
                <Text
                    fontSize="20px"
                    textTransform="uppercase"
                    fontFamily={fonts.montserrat}
                    fontWeight={600}
                    color="brand"
                >
                    Contact us
                </Text>
                <Flex
                    justifyContent={'space-between'}
                    paddingTop="30px"
                    direction={{ base: 'column', lg: 'row' }}
                >
                    {/*Left Side*/}
                    <VStack
                        align={'stretch'}
                        spacing={'14px'}
                        w={{ base: '100%', lg: '47%' }}
                        mb={{ base: '14px', lg: 0 }}
                    >
                        <CustomInput
                            placeholder={'Enter'}
                            label={'First Name *'}
                            fontWeight={400}
                            fontSize="14px"
                            fontFamily={fonts.montserrat}
                            textSize="16px"
                            register={{ ...register('firstName') }}
                            isBorder={false}
                            errors={errors?.firstName}
                            height="50px"
                        />
                        <CustomInput
                            placeholder={'Enter'}
                            label={'Work Email *'}
                            fontWeight={400}
                            fontSize="14px"
                            fontFamily={fonts.montserrat}
                            textSize="16px"
                            register={{ ...register('workEmail') }}
                            isBorder={false}
                            errors={errors?.workEmail}
                            height="50px"
                        />
                    </VStack>
                    {/*Right Side */}
                    <VStack w={{ base: '100%', lg: '47%' }} align={'stretch'} spacing={'14px'}>
                        <CustomInput
                            placeholder={'Enter'}
                            label={'Last Name *'}
                            fontWeight={400}
                            fontSize="14px"
                            fontFamily={fonts.montserrat}
                            textSize="16px"
                            register={{ ...register('lastName') }}
                            isBorder={false}
                            errors={errors?.lastName}
                            height="50px"
                        />
                        <CustomInput
                            placeholder={'Enter'}
                            label={'Mobile Phone *'}
                            fontWeight={400}
                            fontSize="14px"
                            type="tel"
                            mask="999-999-9999"
                            fontFamily={fonts.montserrat}
                            textSize="16px"
                            register={{ ...register('mobilePhone') }}
                            isBorder={false}
                            errors={errors?.mobilePhone}
                            height="50px"
                        />
                    </VStack>
                </Flex>
                <VStack align={'stretch'} spacing={'14px'} mt={'14px'}>
                    <CustomInput
                        placeholder={'Enter'}
                        label={'Company Organization Name *'}
                        fontWeight={400}
                        fontSize="14px"
                        fontFamily={fonts.montserrat}
                        textSize="16px"
                        register={{ ...register('companyOrganizationName') }}
                        isBorder={false}
                        errors={errors?.companyOrganizationName}
                        height="50px"
                    />
                    <CustomDropdown
                        options={[
                            { value: 0, label: 'Sales', id: 1 },
                            { value: 1, label: 'Support', id: 2 },
                            { value: 2, label: 'Other', id: 3 },
                        ]}
                        label={'Reason For Your Inquiry *'}
                        fontFamily={fonts.montserrat}
                        register={{ border: 'none', ...register('reasonForYourInquiry') }}
                        labelSize="14px"
                        fontSize="16px"
                        errors={errors?.reasonForYourInquiry}
                        height="50px"
                    />
                    <CustomTextarea
                        placeholder={'Write Text'}
                        minH={'130px'}
                        label={'How can we help?'}
                        fontWeight={400}
                        fontSize="14px"
                        fontFamily={fonts.montserrat}
                        textSize="16px"
                        register={{ ...register('howCanWeHelp') }}
                        border="none"
                        errors={errors?.howCanWeHelp}
                    />
                </VStack>

                {/* Checkbox*/}
                <Flex mt={{ base: '20px', lg: '45px' }} mb={{ base: '20px', lg: '55px' }}>
                    <Box justifyContent={'flex-start'} paddingTop={1}>
                        <Checkbox onChange={() => setCheckBox(!checkBox)} isChecked={checkBox} />
                    </Box>
                    <Box paddingLeft={5}>
                        <Text
                            fontWeight={400}
                            fontSize="14px"
                            color="brand"
                            fontFamily={fonts.montserrat}
                        >
                            I hereby authorize ZootPro to inform me occasionally about
                            ZootPro&apos;s services, products, and events. I am aware that I will
                            have the ability to opt out should I decide to at a later date and time.
                            My data will be managed in accordance with ZootPro&apos;s{' '}
                            <Link to={'/privacy-policy'}>
                                <Typography
                                    fontFamily={fonts.montserrat}
                                    textDecoration={'underline'}
                                >
                                    Privacy Policy
                                </Typography>
                            </Link>
                            .
                        </Text>
                    </Box>
                </Flex>
                <Box textAlign={'center'}>
                    <CustomButton
                        width={{ base: '100%', lg: '210px' }}
                        text="Send"
                        type="submit"
                        height="50px"
                        bgColor="#2C335C"
                        disabled={!checkBox}
                        isLoading={sendingContactLoading}
                    />
                </Box>
            </Box>
        </form>
    );
};
