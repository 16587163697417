import API from '../api/api.base';
import { IResponseWithCustomValue, SelectValue, PrevAgent } from '../../common/types';

const filterByState = (array: any[], stateValue: string | string[] = '') => {
    if (array && typeof stateValue === 'string' && stateValue !== '') {
        return array.filter(item => item.description.split(', ').includes(stateValue));
    }
    if (typeof stateValue === 'object' && stateValue.length !== 0) {
        return array.filter(item =>
            item.description.split(', ').some((value: string) => stateValue.includes(value)),
        );
    }
    if (stateValue === '' || stateValue.length === 0) {
        return array;
    }
    return [];
};

export const carryAddressLoadOptions = (
    searchByCities?: boolean,
    stateFilterValue?: string | string[],
): ((value: string) => Promise<SelectValue[]>) => {
    return function (inputValue: string) {
        return new Promise(async resolve => {
            try {
                const result = await API.get(
                    `/api/v1/generic/google/autocomplete?searchQuery=${inputValue}&isSearchByCities=${searchByCities}`,
                    {},
                );
                const filtered = filterByState(result.predictions, stateFilterValue);
                resolve(filtered.map(item => ({ value: item.place_id, label: item.description })));
            } catch (error) {
                console.log(error, 'Error occured');
            }
        });
    };
};

export const agentLoadOptions = (inputValue: string): Promise<SelectValue[]> =>
    new Promise(async resolve => {
        try {
            const result: IResponseWithCustomValue<{ totalCount: number; agents: PrevAgent[] }> =
                await API.get(
                    `/api/get-available-users/get-last-agents?Name=${inputValue}&PageNumber=1&PageSize=10`,
                    {},
                );
            resolve(
                result.value.agents.map(item => ({
                    value: item.id + '',
                    label: `${item.firstName} ${item.lastName}`,
                })),
            );
        } catch (error) {
            console.log('Error occured');
        }
    });
