import React from 'react';
import { CommentType } from '../../../types';
import { colors } from '../../../../../common/constants';
import { Text, VStack } from '@chakra-ui/react';
import { CommentBox } from './CommentBox';

interface ICommentsProps {
    comments: Array<CommentType>;
    postId: number;
}

export const Comments: React.FC<ICommentsProps> = ({ comments, postId }) => {
    return (
        <>
            <Text color={colors.brand} lineHeight={'27px'} fontSize={'18px'} fontWeight={'400'}>
                {`Comments (${comments.length})`}
            </Text>
            {!!comments.length && (
                <VStack spacing={'30px'} my={'24px'} align={'stretch'}>
                    {comments.map(comment => (
                        <CommentBox key={comment.id} comment={comment} />
                    ))}
                </VStack>
            )}
            {/*    Send Comment*/}
        </>
    );
};
