import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, Flex } from '@chakra-ui/react';
import {
    LegalWarning,
    PaymentConfirmationHomeInspection,
    PaymentConfirmationOpportunitiesOh,
    PaymentConfirmationShowing,
    PropertiesListToConfirm,
} from './components';
import { RequestInfoType } from '../../../../pages/Requests/types';
import { useDispatch, useSelector } from 'react-redux';
import { getFundingSourcesRequest } from '../../../../pages/Settings/pages/Payments/store/actions';
import { GetFundingSourcesResponse } from '../../../../pages/Settings/pages/Payments/types';
import { RootStore } from '../../../../store';
import {
    getRequestDurationsRequest,
    getRequestAddressesRequest,
    confirmWorkRequest,
    closePaymentStatusModal,
} from '../../../../pages/Activity/store/actions';
import { PaymentStatusModal } from '../PaymentStatusModal/PaymentsStatusModal';

interface IConfirmApplicantWorkModalProps {
    isOpen: boolean;
    onClose: () => void;
    requestInfo: RequestInfoType;
    onOpenIssue: () => void;
}

export const ConfirmApplicantWorkModal: React.FC<IConfirmApplicantWorkModalProps> = ({
    isOpen,
    onClose,
    requestInfo,
    onOpenIssue,
}) => {
    const dispatch = useDispatch();
    const [showingStep, setShowingStep] = useState(0);
    const [paymentValue, setPaymentValue] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState<GetFundingSourcesResponse>(
        {} as GetFundingSourcesResponse,
    );

    const { items, loading } = useSelector((state: RootStore) => state.payments.fundingSources);
    const { pricePerHour, fee, duration } = useSelector(
        (state: RootStore) => state.activity.requestDurations,
    );
    const { pricePerDoor, addresses: requestAddresses } = useSelector(
        (state: RootStore) => state.activity.requestAddresses,
    );
    const { paymentStatus } = useSelector((state: RootStore) => state.activity);
    const { loading: confirmLoading } = useSelector(
        (state: RootStore) => state.activity.confirmWork,
    );

    useEffect(() => {
        dispatch(getFundingSourcesRequest(true));
        dispatch(getRequestDurationsRequest(requestInfo.id));
        if (requestInfo.typeId === 1) {
            dispatch(getRequestAddressesRequest({ id: requestInfo.id }));
        }
    }, []);

    const onConfirmAndPayFinal = () => {
        dispatch(
            confirmWorkRequest({
                fundingSourceId: paymentMethod.dwollaId,
                workId: requestInfo.id,
            }),
        );
    };

    const getShowingSteps = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <PropertiesListToConfirm
                        onClose={onClose}
                        requestAddresses={requestAddresses || []}
                        setShowingStep={setShowingStep}
                        onOpenIssue={onOpenIssue}
                    />
                );
            case 1:
                return (
                    <PaymentConfirmationShowing
                        onClose={onClose}
                        fundingSources={items}
                        loading={loading}
                        numberOfProperties={requestAddresses?.length || 0}
                        pricePerDoor={pricePerDoor}
                        fee={fee}
                        onConfirm={(paymentVal, paymentMeth) => {
                            setPaymentValue(paymentVal);
                            setPaymentMethod(paymentMeth);
                            setShowingStep(2);
                        }}
                    />
                );
            case 2:
                return (
                    <LegalWarning
                        onClose={onClose}
                        paymentValue={paymentValue}
                        onConfirm={onConfirmAndPayFinal}
                        confirmLoading={confirmLoading}
                    />
                );
            default:
                return null;
        }
    };

    const getOpportunitiesOrInspectionSteps = (step: number, component: JSX.Element) => {
        switch (step) {
            case 0:
                return component;
            case 1:
                return (
                    <LegalWarning
                        onClose={onClose}
                        paymentValue={paymentValue}
                        onConfirm={onConfirmAndPayFinal}
                        confirmLoading={confirmLoading}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
                <ModalOverlay />
                <ModalContent
                    flexDirection="column"
                    alignItems="center"
                    borderRadius="20px"
                    p="40px 50px"
                >
                    <Flex w="100%" flexDirection="column" >
                        {requestInfo.typeId === 1 && requestInfo.requestCategoryId === 1 ? (
                            <>{getShowingSteps(showingStep)}</>
                        ) : requestInfo.requestCategoryId === 2 ||
                          requestInfo.requestCategoryId === 3 ? (
                            getOpportunitiesOrInspectionSteps(
                                showingStep,
                                <PaymentConfirmationOpportunitiesOh
                                    onClose={onClose}
                                    fundingSources={items}
                                    requestInfo={requestInfo}
                                    loading={loading}
                                    fee={fee}
                                    onConfirm={(paymentVal, paymentMeth) => {
                                        setPaymentValue(paymentVal);
                                        setPaymentMethod(paymentMeth);
                                        setShowingStep(1);
                                    }}
                                />,
                            )
                        ) : (
                            getOpportunitiesOrInspectionSteps(
                                showingStep,
                                <PaymentConfirmationHomeInspection
                                    onClose={onClose}
                                    fee={fee}
                                    loading={loading}
                                    duration={duration}
                                    pricePerHour={pricePerHour}
                                    fundingSources={items}
                                    requestInfo={requestInfo}
                                    onConfirm={(paymentVal, paymentMeth) => {
                                        setPaymentValue(paymentVal);
                                        setPaymentMethod(paymentMeth);
                                        setShowingStep(1);
                                    }}
                                />,
                            )
                        )}
                    </Flex>
                </ModalContent>
                <PaymentStatusModal
                    isOpen={paymentStatus.modalOpen}
                    paymentStatus={paymentStatus.status}
                    onClose={() => dispatch(closePaymentStatusModal())}
                    onRetry={onConfirmAndPayFinal}
                />
            </Modal>
        </>
    );
};
