import React, { useEffect, useState } from 'react';
import { Box, Container, Flex, HStack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { BackButton } from '../../../../common/components';
import { NotificationsSettingsItem } from './components/NotificationSettingsItem';

import { fonts } from '../../../../common/utils';
import { RootStore } from '../../../../store';
import { getNotificationSettingsRequest, setNotificationSettingsRequest } from '../../store/actions';

export const NotificationsSettings: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedCases, setSelectedCases] = useState<number[]>([]);
    const {cases, loading}  = useSelector((state: RootStore) => state.settings.availableCases);

    const onSetAvailableRequests = (cases: number[]) =>
        dispatch(setNotificationSettingsRequest({ cases }));

    useEffect(() => {
        dispatch(getNotificationSettingsRequest());
    }, []);

    useEffect(() => {
        setSelectedCases(cases);
    }, [cases]);

    const notificationSettings = [
        {
            title: 'Opportunity Notifications',
            settings: [{ value: 1, label: 'Push messages' }],
        },
        {
            title: 'Assistance Notifications',
            settings: [
                { value: 3, label: 'Text messages' },
                { value: 4, label: 'Push messages' },
            ],
        },
        {
            title: 'Chat Notifications',
            settings: [{ value: 2, label: 'Push messages' }],
        },
        {
            title: 'Off Market Needs Notifications',
            settings: [{ value: 10, label: 'Push messages' }],
        },
        {
            title: 'Account Notifications',
            settings: [
                { value: 7, label: 'Text messages' },
                { value: 8, label: 'Push messages' },
                { value: 9, label: 'Email messages' },
            ],
        },
        {
            title: 'Listings & Open House Notifications',
            settings: [
                { value: 5, label: 'Text messages' },
                { value: 6, label: 'Push messages' },
            ],
        },
    ];

    const onChangeAvailability = (item: { value: number; label: string }) => {
        const index = selectedCases.findIndex(t => t === item.value);
        if (index > -1) {
            const filteredItems = selectedCases.filter(t => t !== item.value);
            setSelectedCases(filteredItems);
            onSetAvailableRequests(filteredItems);
        } else {
            const addedItems = [...selectedCases, item.value];
            onSetAvailableRequests(addedItems);
            setSelectedCases(addedItems);
        }
    };

    return (
        <Container maxW="container.lg">
            <HStack mb="16px" pt="30px">
                <BackButton mb="0" text="" />
                <Text
                    fontSize="22px"
                    lineHeight="18px"
                    color="brand"
                    fontWeight="bold"
                    fontFamily={fonts.montserrat}
                >
                    Notifications
                </Text>
            </HStack>
            <Flex flexWrap="wrap" mb="30px">
                {notificationSettings.map(elem => {
                    return (
                        <Flex key={elem.title} flexDirection="column" flexBasis="50%" pb="15px">
                            <Box
                                bg="#F6F7FB"
                                borderRadius="10px"
                                p="25px 40px 0px"
                                mr="15px"
                                height="205px"
                            >
                                <Text
                                    fontSize="18px"
                                    lineHeight="27px"
                                    color="brand"
                                    mb="20px"
                                    fontWeight="600"
                                    fontFamily={fonts.poppins}
                                >
                                    {elem.title}
                                </Text>
                                {elem.settings.map(el =>
                                        <NotificationsSettingsItem
                                            key={el.value}
                                            {...el}
                                            loading={loading}
                                            onChange={onChangeAvailability}
                                            isChecked={!selectedCases.some(t => t === el.value)}
                                        />
                                    )}
                            </Box>
                        </Flex>
                    );
                })}
            </Flex>
        </Container>
    );
}