import React, { useEffect, useState } from 'react';
import { Flex, Text, Box, Input, Avatar, IconButton, HStack, SimpleGrid } from '@chakra-ui/react';
import { BackArrow, EditIcon, PhoneIcon } from '../../../assets/icons';
import { CustomInput, CustomButton, CustomInputEdit } from '../../../common/components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {
    changeEmailRequest,
    changeFullNameRequest,
    changeLicensePhotoRequest,
    changePasswordRequest,
    changeUserAvatarRequest,
    clearFields,
    sendCodeToEmailRequest,
    updatePhoneNumberRequest,
    verifyEmailCodeRequest,
    verifyEmailRequest,
    verifyPhoneNumberRequest,
} from '../store/actions';
import {
    ChangeEmailPayload,
    ChangeFullNamePayload,
    ChangeLicencePhotoPayload,
    ChangePasswordPayload,
    ChangeUserAvatarPayload,
    UpdatePhoneNumberPayload,
    VerifyEmailCodePayload,
    VerifyEmailPayload,
    VerifyPhoneNumberPayload,
} from '../types';
import { getUserAvatarRequest, getUserInfoRequest } from '../../../store/actions/user.actions';
import { UserAvatarPayload } from '../../../common/types';
import {
    EnterCodeForEditModal,
    EditProfileModal,
    ChangeLicenseModal,
    EditEmailModal,
    EditNameModal,
    EditPhoneNumberModal,
    EditPasswordModal,
} from '../components';
import { getAvatarPicture } from '../../../common/utils';
import { changeAdditionalProfileInfo } from '../../../store/actions/profile.actions';
import * as yup from 'yup';

const linkRegexp =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const EditProfile: React.FC = () => {
    const [enterCodeModal, setEnterCodeModal] = useState(false);
    const [editProfileModal, setEditProfileModal] = useState(false);
    const [changeLicenseModal, setChangeLicenseModal] = useState(false);
    const [editEmailModal, setEditEmailModal] = useState(false);
    const [editNameModal, setEditNameModal] = useState(false);
    const [editPhoneModal, setEditPhoneModal] = useState(false);
    const [editPasswordModal, setEditPassowrdModal] = useState(false);
    const [photo, setPhoto] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const sendCodeToEmail = () => dispatch(sendCodeToEmailRequest());
    const verifyEmailCode = (payload: VerifyEmailCodePayload) =>
        dispatch(verifyEmailCodeRequest(payload));
    const changeFullName = (payload: ChangeFullNamePayload) =>
        dispatch(changeFullNameRequest(payload));
    const editPhoneNumber = (payload: UpdatePhoneNumberPayload) =>
        dispatch(updatePhoneNumberRequest(payload));
    const verifyPhoneNumber = (payload: VerifyPhoneNumberPayload) =>
        dispatch(verifyPhoneNumberRequest(payload));
    const changeEmail = (payload: ChangeEmailPayload) => dispatch(changeEmailRequest(payload));
    const verifyEmail = (payload: VerifyEmailPayload) => dispatch(verifyEmailRequest(payload));
    const changePassword = (payload: ChangePasswordPayload) =>
        dispatch(changePasswordRequest(payload));
    const changeLicensePhoto = (payload: ChangeLicencePhotoPayload) =>
        dispatch(changeLicensePhotoRequest(payload));
    const clear = () => dispatch(clearFields());
    const getUserPhoto = (payload: UserAvatarPayload) => {
        dispatch(getUserAvatarRequest(payload));
    };
    const changeAvatar = (payload: ChangeUserAvatarPayload) =>
        dispatch(changeUserAvatarRequest(payload));

    const user = useSelector((state: RootStore) => state.user.user);
    const { isCodeVerified, isFieldChanged, isPhotoChanged, loading, errors } = useSelector(
        (state: RootStore) => state.settings,
    );
    const { loading: verifyEmailLoading, errors: verifyEmailErrors } = useSelector(
        (state: RootStore) => state.settings.verifyEmail,
    );
    const { loading: verifyPhoneNumberLoading, errors: verifyPhoneNumberErrors } = useSelector(
        (state: RootStore) => state.settings.verifyPhoneNumber,
    );
    const userAvatar = useSelector((state: RootStore) => state.user.userPhoto);

    useEffect(() => {
        dispatch(getUserInfoRequest());
    }, [isCodeVerified, isFieldChanged]);

    const { id, email, firstName, lastName, phoneNumber } = user;

    useEffect(() => {
        if (user.id !== 0 || isPhotoChanged) {
            getUserPhoto(id);
        }
    }, [user, isPhotoChanged]);

    useEffect(() => {
        if (isPhotoChanged) {
            getUserPhoto(id);
        }
    }, [isPhotoChanged]);

    const handleHandleEnterCodeModal = () => {
        setEnterCodeModal(!enterCodeModal);
    };

    const handleEditProfileModal = () => {
        setEditProfileModal(!editProfileModal);
    };

    const handleChangeLicenseModal = () => {
        setChangeLicenseModal(!changeLicenseModal);
    };

    const handleEditEmailModal = () => {
        setEditEmailModal(!editEmailModal);
    };

    const handleEditNameModal = () => {
        setEditNameModal(!editNameModal);
    };

    const handleEditPhoneModal = () => {
        setEditPhoneModal(!editPhoneModal);
    };

    const handleEditPasswordModal = () => {
        setEditPassowrdModal(!editPasswordModal);
    };

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = (): void => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };

    const handleChange = (event: any): void => {
        setPhoto(event.target.files[0]);
        event.target.value = null;
    };

    const handleChangeAdditionalUserInfo = (property: string, value: string) => {
        const data = {
            FirstLicensedYear: user.firstLicensedYear || '',
            WebsiteLink: user.websiteLink || '',
            Biography: user.biography || '',
        };
        dispatch(changeAdditionalProfileInfo({ ...data, [property]: value }));
    };

    useEffect(() => {
        if (photo !== null) {
            changeAvatar({ photo: photo });
        }
    }, [photo]);

    return (
        <Flex direction="column" align="center" h="100%">
            <HStack mb="40px" w="100%" spacing="50px">
                <IconButton
                    onClick={history.goBack}
                    aria-label="Back"
                    variant="unstyled"
                    d="flex"
                    alignItems="center"
                >
                    <BackArrow />
                </IconButton>
                <Text fontSize="22px" fontWeight="700" color="brand">
                    Account
                </Text>
            </HStack>
            <Flex align="center" direction="column" w="100%">
                <Box position="relative">
                    <Avatar
                        w="120px"
                        h="120px"
                        mb="20px"
                        border="3px solid #27AE60"
                        src={getAvatarPicture(userAvatar.avatar)}
                    ></Avatar>
                    <Input
                        h="336px"
                        w="343px"
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        display="none"
                    />
                    <IconButton
                        aria-label="Edit avatar image"
                        variant="unstyled"
                        position="absolute"
                        top="-6px"
                        right="-6px"
                        bgColor="white"
                        p="4px"
                        borderRadius="7px"
                        d="flex"
                        align="center"
                        onClick={handleClick}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
                <SimpleGrid columns={2} columnGap="60px" w="100%" px="90px">
                    <CustomInput
                        label="Full Name"
                        placeholder="Full Name"
                        register={''}
                        width="100%"
                        props={{
                            value: `${firstName} ${lastName}`,
                        }}
                        disabled
                        margin="0 0 15px 0"
                    />
                    <CustomInputEdit
                        label="In What year were you first licensed?"
                        editFunction={value =>
                            handleChangeAdditionalUserInfo('FirstLicensedYear', value)
                        }
                        defaultValue={user?.firstLicensedYear}
                        placeholder="Enter"
                        schema={yup
                            .string()
                            .test('year-valid', 'Invalid year format', function (value) {
                                if (value) {
                                    if (value.length !== 4) {
                                        return false;
                                    }
                                    if (isNaN(+value)) {
                                        return false;
                                    }
                                }
                                return true;
                            })}
                        errorMsg="Invalid year format"
                        margin="0 0 15px 0"
                    />
                    <CustomInput
                        label="Phone Number"
                        width="100%"
                        leftIcon={<PhoneIcon />}
                        placeholder="Phone Number"
                        register=""
                        props={{
                            value: phoneNumber,
                        }}
                        disabled
                        margin="0 0 15px 0"
                    />
                    <CustomInputEdit
                        label="Website Link"
                        editFunction={value => handleChangeAdditionalUserInfo('WebsiteLink', value)}
                        defaultValue={user.websiteLink || ''}
                        schema={yup.string().matches(linkRegexp, {
                            message: 'Link is not valid',
                            excludeEmptyString: true,
                        })}
                        errorMsg="Link is not valid"
                        placeholder="Enter"
                        margin="0 0 15px 0"
                    />
                    <CustomInput
                        label="Email"
                        placeholder="Email"
                        register={''}
                        width="100%"
                        props={{
                            value: email,
                        }}
                        disabled
                        margin="0 0 15px 0"
                    />
                    <CustomInputEdit
                        label="Bio"
                        editFunction={value => handleChangeAdditionalUserInfo('Biography', value)}
                        defaultValue={user.biography || ''}
                        placeholder="Please enter a bio (140 characters)"
                        schema={yup.string().max(120, 'Max length is 120 characters')}
                        errorMsg="Max length is 120 characters"
                        margin="0 0 15px 0"
                    />
                    <CustomInput
                        label="Password"
                        placeholder="Password"
                        type="password"
                        register={''}
                        width="100%"
                        defaultValue="qqqqqqq"
                        disabled
                        margin="0 0 15px 0"
                    />

                    <CustomButton
                        text="Edit Settings"
                        icon={<EditIcon color="white" />}
                        bgColor="brand"
                        width="200px"
                        height="50px"
                        alignSelf="center"
                        justifySelf="end"
                        onClick={handleHandleEnterCodeModal}
                    />
                </SimpleGrid>
            </Flex>
            <EnterCodeForEditModal
                isOpen={enterCodeModal}
                onClose={handleHandleEnterCodeModal}
                showEditProfileModal={handleEditProfileModal}
                sendCodeToEmail={sendCodeToEmail}
                verifyEmailCode={verifyEmailCode}
                isCodeVerified={isCodeVerified}
                clear={clear}
            />
            <EditProfileModal
                isOpen={editProfileModal}
                onClose={handleEditProfileModal}
                showEditNameModal={handleEditNameModal}
                showEditEmailModal={handleEditEmailModal}
                showEditPhoneNumberModal={handleEditPhoneModal}
                showEditPasswordModal={handleEditPasswordModal}
                showEditLicenseModal={handleChangeLicenseModal}
                currentUser={user}
                avatar={userAvatar.avatar}
            />
            <ChangeLicenseModal
                isOpen={changeLicenseModal}
                onClose={handleChangeLicenseModal}
                licensePhoto={user.idLicensePhoto}
                changeLicensePhoto={changeLicensePhoto}
                isFieldChanged={isFieldChanged}
                clear={clear}
            />
            <EditEmailModal
                isOpen={editEmailModal}
                onClose={handleEditEmailModal}
                changeEmail={changeEmail}
                verifyEmail={verifyEmail}
                isCodeVerified={isCodeVerified}
                clear={clear}
                loading={verifyEmailLoading}
                stateErrors={verifyEmailErrors}
            />
            <EditNameModal
                isOpen={editNameModal}
                onClose={handleEditNameModal}
                changeFullName={changeFullName}
                currentUser={user}
                isFieldChanged={isFieldChanged}
                clear={clear}
            />
            <EditPhoneNumberModal
                isOpen={editPhoneModal}
                onClose={handleEditPhoneModal}
                editPhoneNumber={editPhoneNumber}
                verifyPhoneNumber={verifyPhoneNumber}
                isCodeVerified={isCodeVerified}
                clear={clear}
                loading={verifyPhoneNumberLoading}
                stateErrors={verifyPhoneNumberErrors}
            />
            <EditPasswordModal
                isOpen={editPasswordModal}
                onClose={handleEditPasswordModal}
                changePassword={changePassword}
                isFieldChanged={isFieldChanged}
                clear={clear}
                loading={loading}
            />
        </Flex>
    );
};
