import React from 'react';

export const BottomCity: React.FC<{ height?: string }> = ({ height = '287' }) => (
    <svg
        width="1950"
        height={height}
        viewBox="0 0 1950 287"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.4">
            <g opacity="0.2">
                <path
                    opacity="0.3"
                    d="M447.636 142.484V111.004H428.943V142.484H406.807V93.7856H390.575V57.3853H377.292V20H349.746V57.3853H336.464V93.7856H320.723V50.0062H311.869V27.8702H306.462V50.0062H274.977V189.709H250.873V174.947H236.112V189.709H212.012V142.484H168.725V111.004H150.032V142.484H149.049V202.991H129.371V167.573H105.76V202.989H86.0827V93.7856H70.8348V57.3853H14.7612V93.7856H0V286.12H469.772V142.484H447.636Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M30.0793 104.358H9.61816V116.05H30.0793V104.358Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M56.3856 116.05H76.8467V104.358H56.3856V116.05Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M30.0793 129.204H9.61816V140.896H30.0793V129.204Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M56.3856 140.896H76.8467V129.204H56.3856V140.896Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M30.0793 151.126H9.61816V162.819H30.0793V151.126Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M56.3856 162.818H76.8467V151.126H56.3856V162.818Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M30.0793 174.51H9.61816V186.202H30.0793V174.51Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M56.3856 186.203H76.8467V174.511H56.3856V186.203Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M30.0793 197.895H9.61816V209.587H30.0793V197.895Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M56.3856 209.586H76.8467V197.894H56.3856V209.586Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M30.0793 221.279H9.61816V232.971H30.0793V221.279Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M56.3856 232.971H76.8467V221.279H56.3856V232.971Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M30.0793 243.201H9.61816V254.893H30.0793V243.201Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M56.3856 254.894H76.8467V243.202H56.3856V254.894Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M141.154 215.433H94.3857V221.279H141.154V215.433Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M141.154 224.202H94.3857V230.048H141.154V224.202Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M141.154 232.971H94.3857V238.817H141.154V232.971Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M268.305 218.355H218.614V224.201H268.305V218.355Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M268.305 227.125H218.614V232.971H268.305V227.125Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M268.305 235.894H218.614V241.74H268.305V235.894Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M141.154 241.74H94.3857V247.586H141.154V241.74Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M141.154 250.509H94.3857V256.355H141.154V250.509Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M141.154 259.278H94.3857V265.124H141.154V259.278Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M173.307 154.049H158.692V171.587H173.307V154.049Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M187.923 171.587H201.076V154.049H187.923V171.587Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M173.307 181.818H158.692V197.894H173.307V181.818Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M187.923 197.895H201.076V181.818H187.923V197.895Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M173.307 209.586H158.692V225.663H173.307V209.586Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M187.923 225.663H201.076V209.587H187.923V225.663Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M173.307 235.894H158.692V253.432H173.307V235.894Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M187.923 253.432H201.076V235.894H187.923V253.432Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M291.688 60.5132H274.15V83.8973H291.688V60.5132Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M291.688 94.1274H274.15V116.05H291.688V94.1274Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M291.688 126.281H274.15V148.203H291.688V126.281Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M291.688 158.434H274.15V181.818H291.688V158.434Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M291.688 192.048H274.15V213.971H291.688V192.048Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M291.688 224.202H274.15V246.124H291.688V224.202Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M396.724 107.561H330.809V126.744H396.724V107.561Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M396.918 148.203H331.15V168.664H396.918V148.203Z"
                    fill="white"
                />
                <path opacity="0.4" d="M397.215 190.2H331.3V209.383H397.215V190.2Z" fill="white" />
                <path
                    opacity="0.4"
                    d="M396.918 231.509H331.15V250.509H396.918V231.509Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M24.2335 67.8203H14.0029V86.8199H24.2335V67.8203Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M469.993 154.049H448.07V181.818H469.993V154.049Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M469.993 194.972H448.07V224.202H469.993V194.972Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M469.993 235.894H448.07V265.124H469.993V235.894Z"
                    fill="white"
                />
                <path
                    d="M195.568 195.213L194.984 195.641C195.199 195.527 195.396 195.383 195.568 195.213Z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M196.025 195.675L195.441 196.103C195.656 195.989 195.853 195.845 196.025 195.675Z"
                    fill="url(#paint1_linear)"
                />
                <path
                    d="M196.025 195.213L195.441 195.641C195.656 195.527 195.853 195.383 196.025 195.213Z"
                    fill="url(#paint2_linear)"
                />
                <path
                    d="M204.707 82.1972C201.397 78.8723 206.405 83.9042 204.707 82.1972V82.1972Z"
                    fill="#72351C"
                />
                <path
                    opacity="0.05"
                    d="M214.908 64.4735L208.743 63.2695L208.7 63.4919L214.865 64.6958L214.908 64.4735Z"
                    fill="black"
                />
                <path
                    d="M171.503 186.828C171.838 186.667 171.936 186.174 171.721 185.729C171.506 185.283 171.06 185.053 170.725 185.214C170.39 185.376 170.292 185.868 170.507 186.314C170.722 186.76 171.168 186.99 171.503 186.828Z"
                    fill="#F2F2F2"
                />
                <path
                    opacity="0.3"
                    d="M950.919 131.617V97.7879H930.834V131.617H907.045V79.2882H889.603V40.1724H875.33V0H845.732V40.1724H831.46V79.2882H814.545V32.2438H805.032V8.45774H799.218V32.2438H765.387V182.361H739.486V166.504H723.626V182.361H697.728V131.617H651.212V97.7879H631.126V131.617H630.069V196.633H608.925V158.573H583.553V196.631H562.41V79.2882H546.025V40.1724H485.766V79.2882H469.908V285.964H974.713V131.617H950.919Z"
                    fill="white"
                />
                <path opacity="0.4" d="M502.23 90.647H480.243V103.21H502.23V90.647Z" fill="white" />
                <path
                    opacity="0.4"
                    d="M530.499 103.211H552.486V90.6483H530.499V103.211Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M502.23 117.346H480.243V129.909H502.23V117.346Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M530.499 129.909H552.486V117.346H530.499V129.909Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M502.23 140.902H480.243V153.465H502.23V140.902Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M530.499 153.465H552.486V140.902H530.499V153.465Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M502.23 166.03H480.243V178.593H502.23V166.03Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M530.499 178.593H552.486V166.03H530.499V178.593Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M502.23 191.158H480.243V203.721H502.23V191.158Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M530.499 203.721H552.486V191.158H530.499V203.721Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M502.23 216.286H480.243V228.849H502.23V216.286Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M530.499 228.849H552.486V216.286H530.499V228.849Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M502.23 239.842H480.243V252.405H502.23V239.842Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M530.499 252.407H552.486V239.844H530.499V252.407Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M621.585 210.002H571.33V216.284H621.585V210.002Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M621.585 219.426H571.33V225.708H621.585V219.426Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M621.585 228.849H571.33V235.13H621.585V228.849Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M758.217 213.145H704.821V219.426H758.217V213.145Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M758.217 222.567H704.821V228.848H758.217V222.567Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M758.217 231.989H704.821V238.271H758.217V231.989Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M621.585 238.272H571.33V244.554H621.585V238.272Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M621.585 247.695H571.33V253.976H621.585V247.695Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M621.585 257.117H571.33V263.399H621.585V257.117Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M656.138 144.043H640.433V162.889H656.138V144.043Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M671.841 162.889H685.976V144.043H671.841V162.889Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M656.138 173.883H640.433V191.158H656.138V173.883Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M671.841 191.158H685.976V173.883H671.841V191.158Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M656.138 203.721H640.433V220.996H656.138V203.721Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M671.841 220.996H685.976V203.721H671.841V220.996Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M656.138 231.989H640.433V250.835H656.138V231.989Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M671.841 250.835H685.976V231.989H671.841V250.835Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M783.345 43.5322H764.499V68.6599H783.345V43.5322Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M783.345 79.6538H764.499V103.21H783.345V79.6538Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M783.345 114.204H764.499V137.76H783.345V114.204Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M783.345 148.755H764.499V173.883H783.345V148.755Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M783.345 184.876H764.499V208.433H783.345V184.876Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M783.345 219.426H764.499V242.983H783.345V219.426Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M896.211 94.0874H825.381V114.702H896.211V94.0874Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M896.42 137.762H825.749V159.749H896.42V137.762Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M896.739 182.89H825.908V203.505H896.739V182.89Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M896.42 227.279H825.749V247.695H896.42V227.279Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M495.949 51.3853H484.955V71.801H495.949V51.3853Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M974.941 144.043H951.385V173.883H974.941V144.043Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M974.941 188.017H951.385V219.426H974.941V188.017Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M974.941 231.989H951.385V263.398H974.941V231.989Z"
                    fill="white"
                />
                <path
                    d="M680.056 188.276C679.648 188.568 679.428 188.736 679.428 188.736C679.658 188.614 679.87 188.459 680.056 188.276Z"
                    fill="url(#paint3_linear)"
                />
                <path
                    d="M680.548 188.772C680.14 189.064 679.92 189.232 679.92 189.232C680.15 189.11 680.362 188.956 680.548 188.772Z"
                    fill="url(#paint4_linear)"
                />
                <path
                    d="M680.548 188.276C680.14 188.568 679.92 188.736 679.92 188.736C680.15 188.614 680.362 188.459 680.548 188.276Z"
                    fill="url(#paint5_linear)"
                />
                <path
                    d="M689.88 66.8331C686.322 63.2597 691.702 68.6673 689.88 66.8331V66.8331Z"
                    fill="#72351C"
                />
                <path
                    opacity="0.05"
                    d="M700.839 47.7905L694.214 46.4966L694.167 46.7347L700.793 48.0286L700.839 47.7905Z"
                    fill="black"
                />
            </g>
            <g opacity="0.2">
                <path
                    opacity="0.3"
                    d="M1421.77 142.484V111.004H1403.08V142.484H1380.95V93.7856H1364.71V57.3853H1351.43V20H1323.88V57.3853H1310.6V93.7856H1294.86V50.0062H1286.01V27.8702H1280.6V50.0062H1249.12V189.709H1225.01V174.947H1210.25V189.709H1186.15V142.484H1142.86V111.004H1124.17V142.484H1123.19V202.991H1103.51V167.573H1079.9V202.989H1060.22V93.7856H1044.97V57.3853H988.9V93.7856H974.139V286.12H1443.91V142.484H1421.77Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1004.22 104.358H983.757V116.05H1004.22V104.358Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1030.53 116.05H1050.99V104.358H1030.53V116.05Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1004.22 129.204H983.757V140.896H1004.22V129.204Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1030.53 140.896H1050.99V129.204H1030.53V140.896Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1004.22 151.126H983.757V162.819H1004.22V151.126Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1030.53 162.818H1050.99V151.126H1030.53V162.818Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1004.22 174.51H983.757V186.202H1004.22V174.51Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1030.53 186.203H1050.99V174.511H1030.53V186.203Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1004.22 197.895H983.757V209.587H1004.22V197.895Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1030.53 209.586H1050.99V197.894H1030.53V209.586Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1004.22 221.279H983.757V232.971H1004.22V221.279Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1030.53 232.971H1050.99V221.279H1030.53V232.971Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1004.22 243.201H983.757V254.893H1004.22V243.201Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1030.53 254.894H1050.99V243.202H1030.53V254.894Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1115.29 215.433H1068.52V221.279H1115.29V215.433Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1115.29 224.202H1068.52V230.048H1115.29V224.202Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1115.29 232.971H1068.52V238.817H1115.29V232.971Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1242.44 218.355H1192.75V224.201H1242.44V218.355Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1242.44 227.125H1192.75V232.971H1242.44V227.125Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1242.44 235.894H1192.75V241.74H1242.44V235.894Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1115.29 241.74H1068.52V247.586H1115.29V241.74Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1115.29 250.509H1068.52V256.355H1115.29V250.509Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1115.29 259.278H1068.52V265.124H1115.29V259.278Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1147.45 154.049H1132.83V171.587H1147.45V154.049Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1162.06 171.587H1175.21V154.049H1162.06V171.587Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1147.45 181.818H1132.83V197.894H1147.45V181.818Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1162.06 197.895H1175.21V181.818H1162.06V197.895Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1147.45 209.586H1132.83V225.663H1147.45V209.586Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1162.06 225.663H1175.21V209.587H1162.06V225.663Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1147.45 235.894H1132.83V253.432H1147.45V235.894Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1162.06 253.432H1175.21V235.894H1162.06V253.432Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1265.83 60.5132H1248.29V83.8973H1265.83V60.5132Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1265.83 94.1274H1248.29V116.05H1265.83V94.1274Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1265.83 126.281H1248.29V148.203H1265.83V126.281Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1265.83 158.434H1248.29V181.818H1265.83V158.434Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1265.83 192.048H1248.29V213.971H1265.83V192.048Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1265.83 224.202H1248.29V246.124H1265.83V224.202Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1370.86 107.561H1304.95V126.744H1370.86V107.561Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1371.06 148.203H1305.29V168.664H1371.06V148.203Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1371.35 190.2H1305.44V209.383H1371.35V190.2Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1371.06 231.509H1305.29V250.509H1371.06V231.509Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M998.373 67.8203H988.143V86.8199H998.373V67.8203Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1444.13 154.049H1422.21V181.818H1444.13V154.049Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1444.13 194.972H1422.21V224.202H1444.13V194.972Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1444.13 235.894H1422.21V265.124H1444.13V235.894Z"
                    fill="white"
                />
                <path
                    d="M1169.71 195.213L1169.12 195.641C1169.34 195.527 1169.53 195.383 1169.71 195.213Z"
                    fill="url(#paint6_linear)"
                />
                <path
                    d="M1170.16 195.675L1169.58 196.103C1169.79 195.989 1169.99 195.845 1170.16 195.675Z"
                    fill="url(#paint7_linear)"
                />
                <path
                    d="M1170.16 195.213L1169.58 195.641C1169.79 195.527 1169.99 195.383 1170.16 195.213Z"
                    fill="url(#paint8_linear)"
                />
                <path
                    d="M1178.85 82.1972C1175.54 78.8723 1180.54 83.9042 1178.85 82.1972V82.1972Z"
                    fill="#72351C"
                />
                <path
                    opacity="0.05"
                    d="M1189.05 64.4735L1182.88 63.2695L1182.84 63.4919L1189 64.6958L1189.05 64.4735Z"
                    fill="black"
                />
                <path
                    d="M1145.64 186.828C1145.98 186.667 1146.07 186.174 1145.86 185.729C1145.64 185.283 1145.2 185.053 1144.86 185.214C1144.53 185.376 1144.43 185.868 1144.65 186.314C1144.86 186.76 1145.31 186.99 1145.64 186.828Z"
                    fill="#F2F2F2"
                />
                <path
                    opacity="0.3"
                    d="M1925.06 131.617V97.7879H1904.97V131.617H1881.18V79.2882H1863.74V40.1724H1849.47V0H1819.87V40.1724H1805.6V79.2882H1788.68V32.2438H1779.17V8.45774H1773.36V32.2438H1739.53V182.361H1713.62V166.504H1697.76V182.361H1671.87V131.617H1625.35V97.7879H1605.26V131.617H1604.21V196.633H1583.06V158.573H1557.69V196.631H1536.55V79.2882H1520.16V40.1724H1459.9V79.2882H1444.05V285.964H1948.85V131.617H1925.06Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1476.37 90.647H1454.38V103.21H1476.37V90.647Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1504.64 103.211H1526.62V90.6483H1504.64V103.211Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1476.37 117.346H1454.38V129.909H1476.37V117.346Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1504.64 129.909H1526.62V117.346H1504.64V129.909Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1476.37 140.902H1454.38V153.465H1476.37V140.902Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1504.64 153.465H1526.62V140.902H1504.64V153.465Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1476.37 166.03H1454.38V178.593H1476.37V166.03Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1504.64 178.593H1526.62V166.03H1504.64V178.593Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1476.37 191.158H1454.38V203.721H1476.37V191.158Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1504.64 203.721H1526.62V191.158H1504.64V203.721Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1476.37 216.286H1454.38V228.849H1476.37V216.286Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1504.64 228.849H1526.62V216.286H1504.64V228.849Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1476.37 239.842H1454.38V252.405H1476.37V239.842Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1504.64 252.407H1526.62V239.844H1504.64V252.407Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1595.72 210.002H1545.47V216.284H1595.72V210.002Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1595.72 219.426H1545.47V225.708H1595.72V219.426Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1595.72 228.849H1545.47V235.13H1595.72V228.849Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1732.36 213.145H1678.96V219.426H1732.36V213.145Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1732.36 222.567H1678.96V228.848H1732.36V222.567Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1732.36 231.989H1678.96V238.271H1732.36V231.989Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1595.72 238.272H1545.47V244.554H1595.72V238.272Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1595.72 247.695H1545.47V253.976H1595.72V247.695Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1595.72 257.117H1545.47V263.399H1595.72V257.117Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1630.28 144.043H1614.57V162.889H1630.28V144.043Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1645.98 162.889H1660.11V144.043H1645.98V162.889Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1630.28 173.883H1614.57V191.158H1630.28V173.883Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1645.98 191.158H1660.11V173.883H1645.98V191.158Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1630.28 203.721H1614.57V220.996H1630.28V203.721Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1645.98 220.996H1660.11V203.721H1645.98V220.996Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1630.28 231.989H1614.57V250.835H1630.28V231.989Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1645.98 250.835H1660.11V231.989H1645.98V250.835Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1757.48 43.5322H1738.64V68.6599H1757.48V43.5322Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1757.48 79.6538H1738.64V103.21H1757.48V79.6538Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1757.48 114.204H1738.64V137.76H1757.48V114.204Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1757.48 148.755H1738.64V173.883H1757.48V148.755Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1757.48 184.876H1738.64V208.433H1757.48V184.876Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1757.48 219.426H1738.64V242.983H1757.48V219.426Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1870.35 94.0874H1799.52V114.702H1870.35V94.0874Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1870.56 137.762H1799.89V159.749H1870.56V137.762Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1870.88 182.89H1800.05V203.505H1870.88V182.89Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1870.56 227.279H1799.89V247.695H1870.56V227.279Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1470.09 51.3853H1459.09V71.801H1470.09V51.3853Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1949.08 144.043H1925.52V173.883H1949.08V144.043Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1949.08 188.017H1925.52V219.426H1949.08V188.017Z"
                    fill="white"
                />
                <path
                    opacity="0.4"
                    d="M1949.08 231.989H1925.52V263.398H1949.08V231.989Z"
                    fill="white"
                />
                <path
                    d="M1654.19 188.276C1653.79 188.568 1653.57 188.736 1653.57 188.736C1653.8 188.614 1654.01 188.459 1654.19 188.276Z"
                    fill="url(#paint9_linear)"
                />
                <path
                    d="M1654.69 188.772C1654.28 189.064 1654.06 189.232 1654.06 189.232C1654.29 189.11 1654.5 188.956 1654.69 188.772Z"
                    fill="url(#paint10_linear)"
                />
                <path
                    d="M1654.69 188.276C1654.28 188.568 1654.06 188.736 1654.06 188.736C1654.29 188.614 1654.5 188.459 1654.69 188.276Z"
                    fill="url(#paint11_linear)"
                />
                <path
                    d="M1664.02 66.8331C1660.46 63.2597 1665.84 68.6673 1664.02 66.8331V66.8331Z"
                    fill="#72351C"
                />
                <path
                    opacity="0.05"
                    d="M1674.98 47.7905L1668.35 46.4966L1668.31 46.7347L1674.93 48.0286L1674.98 47.7905Z"
                    fill="black"
                />
                <path
                    d="M1677.24 60.7944C1689.35 60.7944 1699.16 50.9793 1699.16 38.8718C1699.16 26.7643 1689.35 16.9492 1677.24 16.9492C1665.13 16.9492 1655.32 26.7643 1655.32 38.8718C1655.32 50.9793 1665.13 60.7944 1677.24 60.7944Z"
                    fill="white"
                />
            </g>
        </g>
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="194.984"
                y1="195.429"
                x2="195.568"
                y2="195.429"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="195.441"
                y1="195.886"
                x2="196.03"
                y2="195.886"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="195.441"
                y1="195.429"
                x2="196.03"
                y2="195.429"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint3_linear"
                x1="679.428"
                y1="188.509"
                x2="680.056"
                y2="188.509"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint4_linear"
                x1="679.92"
                y1="189"
                x2="680.553"
                y2="189"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint5_linear"
                x1="679.92"
                y1="188.509"
                x2="680.553"
                y2="188.509"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint6_linear"
                x1="1169.12"
                y1="195.429"
                x2="1169.71"
                y2="195.429"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint7_linear"
                x1="1169.58"
                y1="195.886"
                x2="1170.17"
                y2="195.886"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint8_linear"
                x1="1169.58"
                y1="195.429"
                x2="1170.17"
                y2="195.429"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint9_linear"
                x1="1653.57"
                y1="188.509"
                x2="1654.19"
                y2="188.509"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint10_linear"
                x1="1654.06"
                y1="189"
                x2="1654.69"
                y2="189"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
            <linearGradient
                id="paint11_linear"
                x1="1654.06"
                y1="188.509"
                x2="1654.69"
                y2="188.509"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#808080" stopOpacity="0.251" />
                <stop offset="0.54" stopColor="#808080" stopOpacity="0.122" />
                <stop offset="1" stopColor="#808080" stopOpacity="0.102" />
            </linearGradient>
        </defs>
    </svg>
);
