import { extendTheme, ThemeConfig, theme as base } from '@chakra-ui/react';
import { colors } from '../common/constants';
import {Button, Checkbox} from "./components";
const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
};

export const theme = extendTheme({
    config,
    components: {
        Button,
        Checkbox
    },
    colors: {
        white: colors.white,
        black: colors.black,
        primaryFont: colors.primaryFont,
        secondaryFont: colors.secondaryFont,
        secondaryFontDarker: colors.secondaryFontDarker,
        brand: colors.brand,
        inputBackground: colors.inputBackground,
        green: colors.green,
        calendarBlue: colors.calendarBlue,
        calendarBlueOpacity: colors.calendarBlueOpacity,
        calendarGreen: colors.calendarGreen,
        calendarGreenOpacity: colors.calendarGreenOpacity,
        calendarRed: colors.calendarRed,
        calendarRedOpacity: colors.calendarRedOpacity,
        calendarYellow: colors.calendarYellow,
        calendarYellowOpacity: colors.calendarYellowOpacity,
        calendarLical: colors.calendarLical,
        calendarLicalOpacity: colors.calendarLicalOpacity,
        calendarBackGround: colors.calendarBackGround,
        calendarDateBackGround: colors.calendarDateBackGround,
        calendarCurrentDateBackground: colors.calendarCurrentDateBackground,
        calendarCurrentDateTextBackground: colors.calendarCurrentDateTextBackground,
        calendarPastDatesBackground: colors.calendarPastDatesBackground,
        indicatorGreen: colors.indicatorGreen,
        chakraErrorRed: colors.chakraErrorRed,
        orange: colors.orange,
        checkboxScheme: {
            500: colors.brand,
            600: colors.secondaryFontDarker,
        },
        switchScheme: {
            500: colors.switchGreen,
        },
    },
    fonts: {
        body: `Poppins, ${base.fonts.body}`,
    },
});
