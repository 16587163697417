import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';
import { JobItem } from '../components';
import { FindJobPostsResponseType } from '../types';
import { InfiniteScroll, Loader } from '../../../common/components/blocks';

interface IJobTabProps {
    loading: boolean;
    posts: FindJobPostsResponseType[];
    setJobId: (value: number) => void;
    loadMoreItems: () => void;
    loadMoreLoading: boolean;
}

export const JobTab: React.FC<IJobTabProps> = ({
    loading,
    posts,
    setJobId,
    loadMoreItems,
    loadMoreLoading,
}) => (
    <Box>
        {loading ? (
            <Loader spinnerSize="md" centerHeight="calc(90vh - 180px)" />
        ) : (
            <InfiniteScroll onReachBottom={loadMoreItems} height="calc(90vh - 180px)">
                {posts.length ? (
                    <>
                        {posts.map(elem => (
                            <JobItem
                                key={elem.id}
                                {...elem}
                                onOpenModal={() => setJobId(elem.id)}
                            />
                        ))}
                    </>
                ) : (
                    <Center h="100%">
                        <Text color="brand" fontSize="16px">
                            There is no opportunities at this time
                        </Text>
                    </Center>
                )}
                {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
            </InfiniteScroll>
        )}
    </Box>
);
