import React, { useState, Dispatch, SetStateAction } from 'react';
import { RequestInfoType } from '../../../../pages/Requests/types';
import { useDispatch } from 'react-redux';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
    RequestQuickInfo,
    CustomInput,
    CustomDropdown,
    CustomButton,
    CustomInputEdit,
    CustomDropdownEdit,
    ReportIssueButton,
} from '../index';
import { deleteRequest } from '../../../../pages/Requests/store/actions';
import { yesOrNo, getInspectionItems } from '../../../../common/constants/dropdown.consatnts';
import { fonts } from '../../../../common/utils';
import {
    changeClientName,
    changeClientPhone,
    changeClientType,
    changePrivateNote,
} from '../../../../pages/Requests/store/actions';
import * as yup from 'yup';
import { ErrorMessages } from '../../../schemes/messages';
import { IdNameTypes } from '../../../types';

interface IHireAssistanceInfoSectionProps {
    requestInfo: RequestInfoType;
    bonusTypes: IdNameTypes[];
    clientTypes: IdNameTypes[];
    deleteLoading: boolean;
    loading?: boolean;
    isActivities: boolean;
    setToCancelWorkId?: (value: number) => void;
    setToFinishWorkId?: (value: number) => void;
    setConfirmApplicantModalOpen?: Dispatch<SetStateAction<boolean>>;
}

export const HireAssistanceInfoSection: React.FC<IHireAssistanceInfoSectionProps> = ({
    bonusTypes,
    clientTypes,
    deleteLoading,
    loading,
    requestInfo,
    isActivities,
    setToCancelWorkId,
    setToFinishWorkId,
    setConfirmApplicantModalOpen,
}) => {
    const dispatch = useDispatch();
    const [clientTypeValue, setClientTypeValue] = useState(requestInfo.clientTypeId);

    const handleEditClientName = (clientName: string) => {
        dispatch(
            changeClientName({
                requestId: requestInfo.id,
                clientName,
            }),
        );
    };

    const handleEditClientPhone = (clientPhoneNumber: string) => {
        dispatch(
            changeClientPhone({
                requestId: requestInfo.id,
                clientPhoneNumber,
            }),
        );
    };

    const handleEditClientType = (clientTypedId: number) => {
        dispatch(
            changeClientType({
                requestId: requestInfo.id,
                clientTypedId,
            }),
        );
    };

    const handleEditPrivateNote = (privateNote: string) => {
        dispatch(
            changePrivateNote({
                requestId: requestInfo.id,
                privateNote,
            }),
        );
    };

    const returnEditInput = (item: any, value: any) => {
        if (isActivities) {
            if (value) {
                return item;
            } else {
                return null;
            }
        } else {
            return item;
        }
    };

    return (
        <>
            <Flex>
                <Box flex={1} pr="20px">
                    <RequestQuickInfo
                        date={requestInfo.dateTime}
                        fromTime={requestInfo.fromTime}
                        duration={requestInfo.duration}
                        propertiesToShow={requestInfo.propertiesToShow}
                        requestTypeName={requestInfo.requestTypeName}
                        totalPrice={requestInfo.totalPrice || null}
                        margin={0}
                    />
                    <Box mt={6}>
                        {requestInfo.states &&
                            requestInfo.typeId !== 7 &&
                            requestInfo.states.map((state: string) => (
                                <CustomInput
                                    key={state}
                                    placeholder="State"
                                    label="State"
                                    register={{ value: state }}
                                    isBorder={false}
                                    isReadOnly
                                    margin="0 0 10px 0"
                                    bgColor="white"
                                />
                            ))}
                        <Box flex={1}>
                            <Text
                                color="brand"
                                fontWeight={700}
                                fontSize="16px"
                                lineHeight="24px"
                                fontFamily={fonts.poppins}
                            >
                                Addresses
                            </Text>
                            {requestInfo.addresses &&
                                requestInfo.addresses.map((address: string) => (
                                    <CustomInput
                                        key={address}
                                        placeholder="Address"
                                        // textSize="12px"
                                        // fontWeight={500}
                                        label=""
                                        register={{ value: address }}
                                        isReadOnly
                                        margin="5px"
                                        bgColor="white"
                                    />
                                ))}
                            <Text
                                color="brand"
                                fontWeight={700}
                                fontSize="16px"
                                mt={2}
                                lineHeight="18px"
                                fontFamily={fonts.montserrat}
                            >
                                Description:
                            </Text>
                            <Text
                                color="secondaryFontDarker"
                                fontWeight={400}
                                fontSize="16px"
                                mt={2}
                                lineHeight="26px"
                                fontFamily={fonts.poppins}
                            >
                                {requestInfo.generalNote || 'No description'}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Box flex={1} pl="20px">
                    <Text
                        color="brand"
                        fontWeight={700}
                        fontSize="16px"
                        lineHeight="18px"
                        fontFamily={fonts.montserrat}
                    >
                        More details:
                    </Text>
                    {requestInfo.typeId !== 4 ? (
                        <CustomDropdown
                            label={`Is the ${requestInfo.requestTypeName} scheduled?`}
                            options={getInspectionItems(requestInfo.requestTypeName)}
                            register={{
                                value: requestInfo.isShowingScheduled
                                    ? `Yes, I Have Scheduled The ${requestInfo.requestTypeName}`
                                    : `No, the Agent Will Schedule The ${requestInfo.requestTypeName}`,
                                isReadOnly: true,
                                pointerEvents: 'none',
                            }}
                            errors=""
                            margin="20px 0 10px 0"
                            bgColor="white"
                        />
                    ) : (
                        <CustomDropdown
                            label={`Is access to The Property scheduled?`}
                            options={yesOrNo}
                            register={{
                                value: requestInfo.isShowingScheduled ? 'Yes' : 'No',
                                isReadOnly: true,
                                pointerEvents: 'none',
                            }}
                            errors=""
                            margin="20px 0 10px 0"
                            bgColor="white"
                        />
                    )}

                    {requestInfo.typeId === 1 && requestInfo.wouldLikeToOfferBonus ? (
                        <>
                            <CustomDropdown
                                label="I would like to offer a Bonus if a contract is ratified from the showing list within 48-hours after the showing:"
                                options={yesOrNo}
                                register={{
                                    value: requestInfo.wouldLikeToOfferBonus ? 'Yes' : 'No',
                                    isReadOnly: true,
                                    pointerEvents: 'none',
                                }}
                                errors=""
                                margin="0 0 10px 0"
                                bgColor="white"
                            />
                            <Flex>
                                <CustomDropdown
                                    register={{
                                        value: requestInfo.bonusTypeId,
                                        isReadOnly: true,
                                        pointerEvents: 'none',
                                    }}
                                    options={bonusTypes.map((elem, index) => ({
                                        label: elem.name,
                                        value: elem.id,
                                        id: index,
                                    }))}
                                    label="Bonus Type"
                                    errors=""
                                    margin="0 0 10px 0"
                                    bgColor="white"
                                />
                                <Box w="20px" />
                                <CustomInput
                                    label="Bonus"
                                    register={{
                                        value: requestInfo.amount?.toString(),
                                        isReadOnly: true,
                                    }}
                                    margin="0 0 10px 0"
                                    bgColor="white"
                                />
                            </Flex>
                        </>
                    ) : null}
                    {returnEditInput(
                        <CustomDropdownEdit
                            label="The Client is:"
                            isReadOnly={isActivities}
                            disabled={isActivities}
                            value={clientTypeValue}
                            onChange={setClientTypeValue}
                            options={clientTypes.map((elem, index) => ({
                                label: elem.name,
                                value: elem.id,
                                id: index,
                            }))}
                            errors=""
                            margin="0 0 10px 0"
                            editFunction={handleEditClientType}
                            bgColor="white"
                        />,
                        clientTypeValue,
                    )}
                    {returnEditInput(
                        <CustomInputEdit
                            label="Client Name:"
                            editFunction={handleEditClientName}
                            defaultValue={requestInfo.referralClient?.name}
                            disabled={isActivities}
                            margin="0 0 10px 0"
                            schema={yup.string().required(ErrorMessages.REQUIRED)}
                            errorMsg={ErrorMessages.REQUIRED}
                            bgColor="white"
                        />,
                        requestInfo.referralClient?.name,
                    )}
                    {returnEditInput(
                        <CustomInputEdit
                            label="Client Phone Number"
                            disabled={isActivities}
                            type="tel"
                            width="100%"
                            mask="999-999-9999"
                            placeholder="Client Phone Number"
                            defaultValue={requestInfo.referralClient?.phone}
                            margin="0 0 10px 0"
                            editFunction={handleEditClientPhone}
                            schema={yup
                                .string()
                                .required(ErrorMessages.REQUIRED)
                                .test(
                                    'phone-number',
                                    ErrorMessages.PHONE_NUMBER_INCOMPLETE,
                                    function (value?: string) {
                                        return value?.replace(/[-_]/g, '').length === 10;
                                    },
                                )}
                            errorMsg={ErrorMessages.REQUIRED}
                            bgColor="white"
                        />,
                        requestInfo.referralClient?.phone,
                    )}
                    {returnEditInput(
                        <CustomInputEdit
                            label="Private Notes To The Chosen Agent:"
                            disabled={isActivities}
                            errors=""
                            defaultValue={requestInfo.privateNote || ''}
                            margin="0 0 10px 0"
                            editFunction={handleEditPrivateNote}
                            schema={yup.string().required(ErrorMessages.REQUIRED)}
                            errorMsg={ErrorMessages.REQUIRED}
                            bgColor="white"
                        />,
                        requestInfo.privateNote,
                    )}
                </Box>
            </Flex>
            <Flex
                w="100%"
                flexDirection="row-reverse"
                justifyContent="space-between"
                alignItems="center"
            >
                {setToFinishWorkId && isActivities && (
                    <CustomButton
                        text="Finish"
                        height="60px"
                        w={isActivities ? '30%' : '45%'}
                        bgColor="brand"
                        onClick={() => setToFinishWorkId(requestInfo.id)}
                    />
                )}
                {setToCancelWorkId ? (
                    !setConfirmApplicantModalOpen && (
                        <CustomButton
                            text="Cancel"
                            height="60px"
                            bgColor="transparent"
                            color="brand"
                            borderColor="brand"
                            w={setToFinishWorkId && isActivities ? '30%' : '45%'}
                            onClick={() => setToCancelWorkId(requestInfo.id)}
                        />
                    )
                ) : (
                    <CustomButton
                        text="Delete"
                        bgColor="brand"
                        height="60px"
                        w="calc(50% - 20px)"
                        ml="auto"
                        isLoading={deleteLoading}
                        onClick={() => dispatch(deleteRequest(requestInfo.id))}
                    />
                )}
                {setConfirmApplicantModalOpen ? (
                    <CustomButton
                        height="60px"
                        bg={'#4FD1C5'}
                        onClick={() => setConfirmApplicantModalOpen(true)}
                        text="Ready"
                        w="45%"
                        color="white"
                    />
                ) : null}
                {isActivities && (
                    <ReportIssueButton
                        onClick={() => console.log('REPORT')}
                        w={setToFinishWorkId && isActivities ? '30%' : '45%'}
                    />
                )}
            </Flex>
        </>
    );
};
