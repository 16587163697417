import React from 'react';
import {
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    CloseButton,
    Box,
    Button,
} from '@chakra-ui/react';
import { HouseIcon } from '../../../../../../../assets/icons';
import { TransactionHistoryItem } from '../../../types';
import { colors } from '../../../../../../../common/constants';
import { CustomButton } from '../../../../../../../common/components';
import { ToggleUserProfileModalPayload } from '../../../../../../../common/types';

interface ITransactionHistoryModal {
    id: number | string;
    isOpen: boolean;
    onClose: () => void;
    item: TransactionHistoryItem;
    dateString: string;
    time: string;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
}

export const TransactionHistoryModal: React.FC<ITransactionHistoryModal> = ({
    id,
    isOpen,
    onClose,
    item,
    dateString,
    time,
    onOpenProfileImage,
}) => (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent borderRadius={'18px'} alignItems="center">
            <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                w="100%"
                px="50px"
            >
                <Flex
                    justify="center"
                    align="center"
                    w="100%"
                    pt="30px"
                    pb="10px"
                    position="relative"
                >
                    <Text color="brand" fontSize="18px" fontWeight="bold" lineHeight="22px">
                        Details
                    </Text>
                    <CloseButton
                        position="absolute"
                        right="0"
                        onClick={onClose}
                    />
                </Flex>
            </Flex>
            <ModalBody d="flex" w="100%" flexDirection="column" overflowY="auto" px="50px">
                <Flex
                    pb="20px"
                    borderBottom="1px #D1D2DB solid"
                    justifyContent="flex-start"
                    alignItems="center">
                    <HouseIcon color={item.isIncome ? colors.lime : colors.orange} />
                    <Text ml="5px" fontWeight={600} color={item.isIncome ? colors.lime : colors.orange}>
                        {
                            item.requestTypeId === 1 ?
                                'Cancellation Fee' :
                                item.assistRequestTypeName
                        }
                    </Text>
                </Flex>
                {item.status === 'failed' && <Box pb="5px" borderBottom="1px #D1D2DB solid">
                    <Flex my="10px" justifyContent="space-between" alignitems="center">
                        <Text color="secondaryFontDarker">
                            Status
                        </Text>
                        <Text fontWeight={500} color="#F2C94C">
                            Failed
                        </Text>
                    </Flex>
                </Box>}
                <Box pb="10px" borderBottom="1px #D1D2DB solid">
                    {!item.isIncome && <Flex my="10px" justifyContent="space-between" alignitems="center">
                        <Text color="secondaryFontDarker">
                            Funding Source
                        </Text>
                        <Text fontWeight={500} color="brand">
                            {item.fundingSource}
                        </Text>
                    </Flex>}
                    <Flex my="10px" justifyContent="space-between" alignitems="center">
                        <Text color="secondaryFontDarker">
                            Date
                        </Text>
                        <Text fontWeight={500} color="brand">
                            {dateString}
                        </Text>
                    </Flex>
                    <Flex my="10px" justifyContent="space-between" alignitems="center">
                        <Text color="secondaryFontDarker">
                            Time
                        </Text>
                        <Text fontWeight={500} color="brand">
                            {time}
                        </Text>
                    </Flex>
                    <Flex my="10px" justifyContent="space-between" alignitems="center">
                        <Text color="secondaryFontDarker">
                            Agent
                        </Text>
                        <Button
                            onClick={() => onOpenProfileImage({
                                userId: item.relatedUserId,
                                value: true,
                            })}
                            d="block"
                            padding="0px"
                            bg="transparent"
                            variant="link"
                            _focus={{ boxShadow: "none" }}
                        >
                            <Text fontWeight={500} color="brand" textDecoration="underline">
                                {item.relatedUserFullName}
                            </Text>
                        </Button>
                    </Flex>
                </Box>
                <Box pb="10px">
                    {!item.isIncome && <Flex my="10px" justifyContent="space-between" alignitems="center">
                        <Text color="secondaryFontDarker">
                            Cost
                        </Text>
                        <Text fontWeight={600} color="brand">
                            ${item.amountOfMoney}
                        </Text>
                    </Flex>}
                    {!item.isIncome && <Flex my="10px" justifyContent="space-between" alignitems="center">
                        <Text color="secondaryFontDarker">
                            Fee
                        </Text>
                        <Text fontWeight={600} color="brand">
                            ${item.fee}
                        </Text>
                    </Flex>}
                    <Flex my="10px" justifyContent="space-between" alignitems="center">
                        <Text color="secondaryFontDarker">
                            Total
                        </Text>
                        <Text fontWeight={600} color="brand">
                            ${item.amountOfMoney + item.fee}
                        </Text>
                    </Flex>
                </Box>
                {item.status === 'failed' && <CustomButton
                    text="Retry"
                    color="white"
                    bgColor='brand'
                    height="55px"
                    margin="20px 0 10px 0"
                />}
            </ModalBody>
        </ModalContent>
    </Modal>
)
