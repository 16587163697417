import React from 'react';
import {
    Container,
    Box,
    Heading,
    Text,
    Flex,
    SimpleGrid,
    useMediaQuery,
    Image,
} from '@chakra-ui/react';
import {
    LandingHeader,
    LandingFooter,
    HowItWorksBlock,
    TrialBanner,
} from '../../common/components/landing';
import { fonts } from '../../common/utils';
import HowItWorksBG from '../../assets/landing/how-it-works-header-bg.png';
import { howItWorksPoints } from '../../common/constants';

export const HowItWorks: React.FC = () => {
    const [isLargerThan600] = useMediaQuery('(min-width: 600px');

    return (
        <Box>
            <LandingHeader />
            <Box
                h="450px"
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={HowItWorksBG}
                position="relative"
                backgroundSize="cover"
                overflowY="hidden"
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />
                <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                    <Flex
                        direction="column"
                        height="100%"
                        justify="center"
                        pb={isLargerThan600 ? '70px' : '300px'}
                    >
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={isLargerThan600 ? '52px' : '24px'}
                            lineHeight="62px"
                            fontWeight="bold"
                            w="60%"
                        >
                            How It Works
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={isLargerThan600 ? '16px' : '13px'}
                            lineHeight="28px"
                            my={isLargerThan600 ? '23px' : '0'}
                        >
                            The web and mobile applications have features that are very easy to use.
                        </Text>
                    </Flex>
                </Container>
            </Box>
            <Box
                bg="white"
                borderTopRadius={isLargerThan600 ? '70px' : '40px'}
                mt={isLargerThan600 ? '-70px' : '-300px'}
                zIndex={10}
                position="relative"
            >
                <Container maxW="container.xl" py={isLargerThan600 ? '75px' : '40px'} px="25px">
                    <SimpleGrid columns={1} columnGap="100px" rowGap="50px">
                        {howItWorksPoints.map((feature, index) => (
                            <HowItWorksBlock key={feature.title} index={index} {...feature} />
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>
            <TrialBanner title="Grow your real estate business through by leveraging affilliated agents" buttonTitle='Start' />
            <LandingFooter />
        </Box>
    );
};
