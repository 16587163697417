import React, { useEffect, useState } from 'react';
import { PaymentMethodTab } from './PaymentMethodTab';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../../store';
import {
    checkUserAddFundingSourceRequest,
    getFundingSourcesRequest,
    getPlaidLinkToken,
    getProcessorToken,
    setIsUserCanAddBankAccount,
    setReceivingFundingSourceRequest,
    setSendingFundingSourceRequest,
} from '../../store/actions';
import {
    AddBankAccountViaPlaidModal,
    AddPaymentMethodModal,
    EditBankNameModal,
    EditPaymentMethodModal,
    FundingSourcesModal,
    VerifyPaymentMethodModal,
} from '../../modals';
import { useDelta } from 'react-delta';
import { GetProcessorTokenPayload } from '../../types';

interface IPaymentMethodTabContainerProps {
    tabIndex: number;
}

export const PaymentMethodTabContainer: React.FC<IPaymentMethodTabContainerProps> = ({
    tabIndex,
}) => {
    const [showFundingSourceModal, setShowFundingSourceModal] = useState(false);
    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);
    const [showEditPaymentMethodModal, setShowEditPaymentMethodModal] = useState(false);
    const [showVerifyPaymentMethodModal, setShowVerifyPaymentMethodModal] = useState(false);
    const [showEditBankNameModal, setShowEditBankNameModal] = useState(false);
    const [showBankAccountViaPlaid, setShowBankAccountViaPlaid] = useState(false);
    const [fundingSourceId, setFundingSourceId] = useState('');
    const [verifyFundingSourceId, setVerifyFundingSourceId] = useState('');
    const [editBankNameId, setEditBankNameId] = useState('');

    const dispatch = useDispatch();
    const { loading: fundingSourcesLoading, items: fundingSources } = useSelector(
        (state: RootStore) => state.payments.fundingSources,
    );

    const {
        value: isUserAddPaymentMethod,
        loading: checkAddFundingSourceLoading,
        // errors: checkAddFundingSourceErrors
    } = useSelector((state: RootStore) => state.payments.isUserAddNewFundingSource);
    const { errors: addNewBankAccountErrors, loading: addNewBankAccountLoading } = useSelector(
        (state: RootStore) => state.payments.addNewBankAccount,
    );

    const { errors: editPaymentMethodErrors, loading: editPaymentMethodLoading } = useSelector(
        (state: RootStore) => state.payments.updatePaymentMethod,
    );
    const { errors: verifyMicroDepositsErrors, loading: verifyMicroDepositsLoading } = useSelector(
        (state: RootStore) => state.payments.verifyMicroDeposits,
    );

    const { loading: plaidLinkTokenLoading, value: plaidLinkTokenData } = useSelector(
        (state: RootStore) => state.payments.plaidLinkToken,
    );
    const {
        loading: getProcessorTokenLoading,
        processorToken,
        errors: processorTokenErrors,
    } = useSelector((state: RootStore) => state.payments.getProcessorToken);

    const addNewBankAccountDelta = useDelta(addNewBankAccountLoading);
    const editPaymentMethodDelta = useDelta(editPaymentMethodLoading);
    const showFundingSourceModalDelta = useDelta(showFundingSourceModal);
    const verifyMicroDepositsDelta = useDelta(verifyMicroDepositsLoading);
    const getProcessorTokenDelta = useDelta(getProcessorTokenLoading);

    const onSetSendingFundingSource = (id: string) => {
        dispatch(setSendingFundingSourceRequest(id));
    };
    const onSetReceivingFundingSource = (id: string) => {
        dispatch(setReceivingFundingSourceRequest(id));
    };
    const onCheckAddNewFundingSource = () => {
        dispatch(checkUserAddFundingSourceRequest());
    };
    const onGetPlaidLinkToken = (payload: boolean) => {
        dispatch(getPlaidLinkToken(payload));
    };
    const onGetProcessorToken = (payload: GetProcessorTokenPayload) => {
        dispatch(getProcessorToken(payload));
    };

    useEffect(() => {
        if (tabIndex === 1) {
            dispatch(getFundingSourcesRequest(true));
        }
    }, [tabIndex]);

    useEffect(() => {
        if (showFundingSourceModal) {
            dispatch(getFundingSourcesRequest(false));
        }
    }, [showFundingSourceModal]);
    useEffect(() => {
        if (showFundingSourceModalDelta?.prev && !showFundingSourceModalDelta.curr) {
            dispatch(getFundingSourcesRequest(true));
        }
    }, [showFundingSourceModalDelta]);

    useEffect(() => {
        if (isUserAddPaymentMethod) {
            console.log('RUN');
            setShowAddPaymentMethodModal(true);
        }
    }, [isUserAddPaymentMethod]);
    useEffect(() => {
        if (
            !addNewBankAccountDelta?.prev &&
            addNewBankAccountDelta?.curr &&
            !addNewBankAccountErrors.length
        ) {
            setShowAddPaymentMethodModal(false);
            dispatch(setIsUserCanAddBankAccount());
        }
    }, [addNewBankAccountDelta]);
    useEffect(() => {
        if (
            !editPaymentMethodDelta?.prev &&
            editPaymentMethodDelta?.curr &&
            !editPaymentMethodErrors.length
        ) {
            setShowEditPaymentMethodModal(false);
            setFundingSourceId('');
        }
    }, [editPaymentMethodDelta]);
    useEffect(() => {
        if (
            !verifyMicroDepositsDelta?.prev &&
            verifyMicroDepositsDelta?.curr &&
            !verifyMicroDepositsErrors.length
        ) {
            setShowVerifyPaymentMethodModal(false);
            setVerifyFundingSourceId('');
        }
    }, [verifyMicroDepositsDelta]);

    useEffect(() => {
        if (
            getProcessorTokenDelta?.prev &&
            !getProcessorTokenDelta.curr &&
            !processorTokenErrors.lenght
        ) {
            setShowBankAccountViaPlaid(true);
        }
    }, [getProcessorTokenDelta]);

    useEffect(() => {
        if (fundingSourceId) {
            setShowEditPaymentMethodModal(true);
        }
    }, [fundingSourceId]);

    useEffect(() => {
        if (verifyFundingSourceId) {
            setShowVerifyPaymentMethodModal(true);
        }
    }, [verifyFundingSourceId]);
    useEffect(() => {
        if (editBankNameId) {
            setShowEditBankNameModal(true);
        }
    }, [editBankNameId]);

    return (
        <>
            <PaymentMethodTab
                setShowFundingSourceModal={setShowFundingSourceModal}
                onSetReceivingFundingSource={onSetReceivingFundingSource}
                onSetSendingFundingSource={onSetSendingFundingSource}
                fundingSources={fundingSources}
                fundingSourcesLoading={fundingSourcesLoading}
            />
            {/* Funding Source Modal */}
            <FundingSourcesModal
                plaidLinkTokenData={plaidLinkTokenData}
                onGetProcessorToken={onGetProcessorToken}
                plaidLinkTokenLoading={plaidLinkTokenLoading}
                onGetPlaidLinkToken={onGetPlaidLinkToken}
                setEditBankNameId={setEditBankNameId}
                setVerifyFundingSourceId={setVerifyFundingSourceId}
                setFundingSourceId={setFundingSourceId}
                checkAddFundingSourceLoading={checkAddFundingSourceLoading}
                onCheckAddNewFundingSource={onCheckAddNewFundingSource}
                fundingSources={fundingSources}
                fundingSourcesLoading={fundingSourcesLoading}
                isOpen={showFundingSourceModal}
                title={'Funding Sources'}
                onClose={() => {
                    setShowFundingSourceModal(false);
                }}
            />
            {/* Add Payment Method Modal */}
            <AddPaymentMethodModal
                addNewBankAccountLoading={addNewBankAccountLoading}
                isOpen={showAddPaymentMethodModal}
                title={'Add Method'}
                onClose={() => {
                    setShowAddPaymentMethodModal(false);
                    dispatch(setIsUserCanAddBankAccount());
                }}
            />
            {/* Edit Payment Method Modal*/}
            <EditPaymentMethodModal
                editPaymentMethodLoading={editPaymentMethodLoading}
                fundingSourceId={fundingSourceId}
                isOpen={showEditPaymentMethodModal}
                title={'Edit Method'}
                onClose={() => {
                    setShowEditPaymentMethodModal(false);
                    setFundingSourceId('');
                }}
            />
            {/*Verify Payment Method Modal */}
            <VerifyPaymentMethodModal
                verifyMicroDepositsLoading={verifyMicroDepositsLoading}
                verifyFundingSourceId={verifyFundingSourceId}
                isOpen={showVerifyPaymentMethodModal}
                title={'Check'}
                onClose={() => {
                    setShowVerifyPaymentMethodModal(false);
                    setVerifyFundingSourceId('');
                }}
            />
            {/* Edit Bank Name Modal */}
            <EditBankNameModal
                editBankNameId={editBankNameId}
                isOpen={showEditBankNameModal}
                title={'Edit'}
                onClose={() => {
                    setShowEditBankNameModal(false);
                    setEditBankNameId('');
                }}
            />
            {/* Add Bank Account Via Plaid */}
            <AddBankAccountViaPlaidModal
                isOpen={showBankAccountViaPlaid}
                onClose={() => setShowBankAccountViaPlaid(false)}
                plaidToken={processorToken}
            />
        </>
    );
};
