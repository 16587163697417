import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    CloseButton,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text,
    Center,
} from '@chakra-ui/react';
import { colors } from '../../../../../common/constants';
import { CustomButton } from '../../../../../common/components';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { PlacesTypeahead } from '../components';
import { transformAddress } from '../../../../../common/utils';
import { GeoPositionShortType, AddressLocationType } from '../../../../../common/types';
import { getAddressFromCoords } from '../helpers/getAddressFromCoords';

interface ISelectLocationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onGetCoords: (obj: GeoPositionShortType) => void;
    onGetAddress: (obj: AddressLocationType) => void;
    regionPosition: any;
    onSaveManuallyLocation: () => void;
    saveLoading: boolean;
}

export const SelectLocationModal: React.FC<ISelectLocationModalProps> = ({
    isOpen,
    onClose,
    onGetAddress,
    onGetCoords,
    regionPosition,
    onSaveManuallyLocation,
    saveLoading,
}) => {
    const [address, setAddress] = useState('');

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then((results: any) => {
                onGetAddress(transformAddress(results[0].address_components));
                return getLatLng(results[0]);
            })
            .then((latLng: any) => {
                onGetCoords(latLng);
            })
            .catch((error: any) => console.error('Error', error));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent
                h="85vh"
                borderRadius="18px"
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <ModalHeader
                    display="flex"
                    flexDirection="row"
                    w="100%"
                    justifyContent="space-between"
                >
                    <Text fontSize="22px" fontWeight="700" color="brand">
                        Setup Location
                    </Text>
                    <CloseButton onClick={onClose} />
                </ModalHeader>
                <ModalBody d="flex" flexDirection="column" w="100%">
                    {isLoaded ? (
                        <GoogleMap
                            options={{ disableDefaultUI: true }}
                            mapContainerStyle={{
                                height: '90%',
                                width: '100%',
                                borderRadius: '20px',
                            }}
                            center={regionPosition}
                            zoom={10}
                            onClick={e => {
                                getAddressFromCoords(
                                    e.latLng?.lat() || 0,
                                    e.latLng?.lng() || 0,
                                    onGetAddress,
                                    onGetCoords,
                                );
                            }}
                        >
                            <PlacesTypeahead
                                address={address}
                                handleSelect={handleSelect}
                                setAddress={setAddress}
                            />
                            <Marker position={regionPosition} />
                        </GoogleMap>
                    ) : (
                        <></>
                    )}
                    <Center mt="20px">
                        <CustomButton
                            text="Save"
                            bgColor="brand"
                            onClick={onSaveManuallyLocation}
                            isLoading={saveLoading}
                            height="50px"
                            width="300px"
                        />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
