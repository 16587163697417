import {
    IResponseWithCustomValue,
    IdNameTypes,
    UserAvatarSuccessResponse,
    UserInfoSuccessResponse, SearchAgentByAreaResponse,
} from './../../common/types';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import API from '../../common/api/api.base';
import {
    getUserAvatarError,
    getUserAvatarRequest,
    getUserAvatarSuccess,
    getUserInfoError,
    getUserInfoRequest,
    getUserInfoSuccess,
    getProfileAgencies,
    getProfileAgenciesSuccess,
    getProfileAgenciesError, searchAgentsByArea, searchAgentsByAreaSuccess, searchAgentsByAreaError,
} from '../actions/user.actions';
import {Alerter} from "../../common/utils";

function* workerGetUserInfo() {
    try {
        const result: IResponseWithCustomValue<UserInfoSuccessResponse> = yield call(
            API.get,
            `/api/profile`,
            {},
        );
        if (result.success) {
            yield put(getUserInfoSuccess(result.value));
        } else {
            yield put(getUserInfoError());
        }
    } catch (err) {
        yield put(getUserInfoError());
    }
}

function* workerGetUserAvatar(action: ReturnType<typeof getUserAvatarRequest>) {
    try {
        const result: IResponseWithCustomValue<UserAvatarSuccessResponse> = yield call(
            API.get,
            `/api/profile/get-user-profile-image?id=${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getUserAvatarSuccess(result));
        } else {
            yield put(getUserAvatarError());
        }
    } catch (err) {
        yield put(getUserAvatarError());
    }
}

function* workerGetProfileAgencies(action: ReturnType<typeof getProfileAgencies>) {
    try {
        const result: IResponseWithCustomValue<{ agencies: IdNameTypes[] }> = yield call(
            API.get,
            `/api/profile/agencies/?id=${action.payload.id}`,
            {},
        );
        if (result.success) {
            yield put(getProfileAgenciesSuccess(result.value.agencies));
        }
    } catch (error) {
        console.log(error, 'workerGetProfileAgencies error');
    }
}
function* workerSearchAgentsByArea(action: ReturnType<typeof searchAgentsByArea>) {
    try {
        console.log("SEARCH BY AREA", action.payload)
        const result: IResponseWithCustomValue<{ users: SearchAgentByAreaResponse[] }> = yield call(
            API.get,
            `/api/profile/get-users-by-city?UserId=${action.payload.userId}&CityId=${action.payload.cityId}`,
            {},
        );
        if (result.success) {
            yield put(searchAgentsByAreaSuccess(result.value.users));
        } else {
            yield put(searchAgentsByAreaError(result.errors))
        }
    } catch (error) {
        Alerter.error('Something went wrong, try again later');
    }
}

export default function* watchUserInfoSaga() {
    yield takeEvery(getUserInfoRequest.type, workerGetUserInfo);
    yield takeEvery(getUserAvatarRequest.type, workerGetUserAvatar);
    yield takeEvery(getProfileAgencies.type, workerGetProfileAgencies);
    yield takeEvery(searchAgentsByArea.type, workerSearchAgentsByArea);
}

