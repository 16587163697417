import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    Input,
    Image,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    IconButton,
} from '@chakra-ui/react';
import { DragAndDropIcon, UploadItemIcon, BackArrow } from '../../../../../assets/icons';
import { CustomButton } from '../../../../../common/components';
import { ISignUpUploadPhotoProps, SignUpUploadLicensePayload } from '../../types';
import axios from 'axios';
import { baseUrl } from '../../../../../common/api/api.base';
import { Typography } from '../../../../../common/components/blocks/Typography/Typography';
import { useHistory } from 'react-router-dom';

export const SignUpUploadPhoto: React.FC<ISignUpUploadPhotoProps> = ({
    loading,
    stateErrors,
    label,
    onSubmit,
    token,
    subLabel,
    infoText,
    isAddedDraftAgency,
    onGetLicensesDraft,
    licenseDraft,
}) => {
    const history = useHistory();
    const [photo, setPhoto] = useState<any>(null);
    const [license, setLicense] = useState<any>([]);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const getLicenses = async () => {
        try {
            const result: any = await axios.get(`${baseUrl}/api/profile/get-licenses`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (result) {
                const validLicense = result.data.value.licenseModels.filter(
                    (item: any) => item.photoId === null,
                );
                setLicense(validLicense);
            } else {
                setLicense([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClick = (): void => {
        hiddenFileInput.current && hiddenFileInput.current.click();
    };

    const handleChange = (event: any): void => {
        setPhoto(event.target.files[0]);
        event.target.value = null;
    };

    const getLicense = () => {
        if (label === 'Add Real Estate License') {
            if (isAddedDraftAgency) {
                if (onGetLicensesDraft) {
                    onGetLicensesDraft();
                }
            } else {
                getLicenses();
            }
        }
    };

    const draftLicense = () => {
        if (isAddedDraftAgency) {
            if (licenseDraft) {
                return licenseDraft[0];
            }
        } else if (Array.isArray(license)) {
            return license[0]?.id;
        }
        return 0;
    };

    const postLicensePhoto = () => {
        switch (label) {
            case 'Add Real Estate License':
                const realEstatePayload: SignUpUploadLicensePayload = {
                    license: draftLicense(),
                    body: photo,
                    token: token,
                };
                photo ? onSubmit(realEstatePayload) : console.log(realEstatePayload);
                break;
            case 'Add Identification':
                const driverPayload: SignUpUploadLicensePayload = {
                    body: photo,
                    token: token,
                };
                photo ? onSubmit(driverPayload) : console.log(driverPayload);
                break;
        }
    };

    useEffect(() => {
        getLicense();
    }, [label, token]);

    return (
        <Box
            borderRadius={20}
            backgroundColor="white"
            px={{ base: '20px', md: '20%' }}
            position="relative"
            w="100%"
        >
            <IconButton
                aria-label="Back"
                variant="unstyled"
                position="absolute"
                top={{ base: '20px', lg: '30px' }}
                left={{ base: '15px', lg: '30px' }}
                d="flex"
                alignItems="center"
                onClick={() => history.goBack()}
            >
                <BackArrow />
            </IconButton>
            <Box h="100%">
                <Flex
                    mt="40px"
                    mb={subLabel ? '0px' : '40px'}
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Text
                        fontSize={{ base: '18px', lg: '35px' }}
                        fontWeight="900"
                        color="brand"
                        mr={'15px'}
                        ml={{ base: '50px', md: 0 }}
                    >
                        {label}
                    </Text>
                    <Box>
                        <Popover>
                            <PopoverTrigger>
                                <Flex
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    w={'20px'}
                                    h={'20px'}
                                    borderRadius={'50%'}
                                    backgroundColor="brand"
                                >
                                    <Typography
                                        lineHeight={'16px'}
                                        weight={600}
                                        color={'white'}
                                        size={11}
                                    >
                                        i
                                    </Typography>
                                </Flex>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverBody fontSize={'14px'} color="secondaryFontDarker">
                                    {infoText}
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </Box>
                </Flex>

                {subLabel ? (
                    <Box my={'15px'}>
                        <Typography size={16}>{subLabel}</Typography>
                    </Box>
                ) : null}
                {}

                {photo ? (
                    <Image
                        src={URL.createObjectURL(photo)}
                        w="100%"
                        mb="40px"
                        borderRadius="10px"
                        objectFit={'contain'}
                    />
                ) : (
                    <Flex
                        h={{ base: '200px', md: '300px' }}
                        w="100%"
                        justifyContent="center"
                        alignItems="center"
                        bg="#F6F7FB"
                        mb="40px"
                        borderRadius="10px"
                    >
                        <DragAndDropIcon />
                    </Flex>
                )}
                <Flex alignItems="center">
                    <Input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        display="none"
                    />
                    <Button
                        w="50px"
                        h="50px"
                        bg="brand"
                        justifyContent="center"
                        alignItems="center"
                        mr="15px"
                        onClick={() => handleClick()}
                    >
                        <UploadItemIcon />
                    </Button>
                    <Text
                        fontSize="15px"
                        fontWeight="400"
                        color="brand"
                        textDecoration="underline"
                        onClick={handleClick}
                        cursor="pointer"
                    >
                        Upload Photo From Gallery
                    </Text>
                </Flex>
                <Box mt="30px">
                    <CustomButton
                        isLoading={false}
                        loadingText="Signing up..."
                        text="Try Again"
                        width="100%"
                        height="60px"
                        bgColor="brand"
                        boxShadow="0px 3px 3px grey"
                        onClick={() => setPhoto(null)}
                    />
                </Box>
                <Box m="10px 0 30px 0">
                    <CustomButton
                        isLoading={loading}
                        loadingText="Signing up..."
                        text="Next"
                        width="100%"
                        height="60px"
                        bgColor="brand"
                        boxShadow="0px 3px 3px grey"
                        onClick={() => postLicensePhoto()}
                    />
                </Box>
            </Box>
        </Box>
    );
};
