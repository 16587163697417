import React from 'react';
import { Flex, Box, Container } from '@chakra-ui/react';
//components
import { DashboardHeader } from '../../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { ConversationsList } from './components/CoversationsList/CoversationsList';
import { ChatContainer } from '../Chat/Chat.container';
import { IMainChatProps, IMainChatState } from '../types';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { getTwillioToken } from '../utils';
const Conversations = require('@twilio/conversations');

export class MainChat extends React.Component<IMainChatProps, IMainChatState> {
    conversationsClient: any;

    constructor(props: any) {
        super(props);
        this.state = {
            conversations: [],
            currentConversation: {},
            filterInput: '',
            connectionStatus: 'disconnected',
            conversationReady: false,
        };
    }

    componentDidMount = () => {
        getTwillioToken(this.props.accessToken, this.initConversations);
    };

    componentDidUpdate = () => {
        const { participantId, userId } = this.props;

        const check = this.state.conversations.find((item: any) => {
            const participant = item.channelState.attributes.filter((item: any) => {
                return item.Id !== userId;
            })[0];

            return participant.Id == participantId;
        });

        check &&
            !this.state.currentConversation.sid &&
            this.setState({ currentConversation: check });
    };

    initConversations = async (token: string) => {
        try {
            this.conversationsClient = await Conversations.Client.create(token);
            this.conversationsClient.on('connectionStateChanged', (state: string) => {
                console.log(state);
                if (state === 'connecting') {
                    this.setState({ connectionStatus: 'connecting', conversationReady: false });
                } else if (state === 'connected') {
                    this.setState({ connectionStatus: 'connected', conversationReady: true });
                } else if (state === 'disconnecting') {
                    this.setState({ connectionStatus: 'disconnecting', conversationReady: false });
                } else if (state === 'disconnected') {
                    this.setState({ connectionStatus: 'disconnected', conversationReady: false });
                } else if (state === 'denied') {
                    this.setState({ connectionStatus: 'denied', conversationReady: false });
                }
            });
            this.conversationsClient.on('conversationJoined', (conversation: Conversation) => {
                this.setState((prevState: IMainChatState) => {
                    return { conversations: [...prevState.conversations, conversation] };
                });
                this.setState({ connectionStatus: 'connected' });
            });
            this.conversationsClient.on('conversationLeft', (thisConversation: Conversation) => {
                this.setState((prevState: IMainChatState) => {
                    return {
                        conversations: [
                            ...prevState.conversations.filter(
                                (it: Conversation) => it !== thisConversation,
                            ),
                        ],
                    };
                });
            });
            this.conversationsClient.on('tokenAboutToExpire', async () => {
                getTwillioToken(this.props.accessToken, this.conversationsClient.updateToken);
            });
            this.conversationsClient.on('tokenExpired', async () => {
                getTwillioToken(this.props.accessToken, this.conversationsClient.updateToken);
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    onSetCurrentConversation = (conversation: Conversation) => {
        this.setState({
            currentConversation: conversation,
        });
    };

    setFilterInput = (text: string) => {
        this.setState({ filterInput: text });
    };

    render() {
        const filteredConversations = this.state.conversations
            .filter((item: any) => {
                const correctItem = item.channelState.attributes.filter((item: any) => {
                    return item.Id !== this.props.userId;
                })[0];
                const name = `${correctItem.FirstName} ${correctItem.LastName}`.toLocaleLowerCase();

                if (!this.state.filterInput) return true;
                if (name.includes(this.state.filterInput.toLocaleLowerCase())) {
                    return true;
                }
            })
            .sort((a: any, b: any) => {
                return (
                    new Date(b.lastMessage?.dateCreated).valueOf() -
                    new Date(a.lastMessage?.dateCreated).valueOf()
                );
            });
        return (
            <Flex backgroundColor="brand" flexDirection="column" h="100vh" alignItems="center">
                <DashboardHeader />
                <Container
                    d="flex"
                    maxW="container.xl"
                    bgColor="#FFFFFF"
                    h="90vh"
                    mt="15px"
                    borderRadius="10px"
                    px="0"
                >
                    <Box w="30%" h="100%" position="relative">
                        <ConversationsList
                            conversations={filteredConversations}
                            currentConversation={this.state.currentConversation}
                            setConversation={this.onSetCurrentConversation}
                            userId={this.props.userId}
                            setFilterInput={this.setFilterInput}
                            filterInput={this.state.filterInput}
                            conversationReady={this.state.conversationReady}
                        />
                    </Box>
                    <Box
                        w="70%"
                        h="100%"
                        bg="#EBF2F8"
                        borderBottomRightRadius="10px"
                        borderTopRightRadius="10px"
                    >
                        <ChatContainer
                            currentConversation={this.state.currentConversation}
                            conversations={this.state.conversations}
                            userId={this.props.userId}
                            conversationReady={this.state.conversationReady}
                        />
                    </Box>
                </Container>
            </Flex>
        );
    }
}
