import React from 'react';

type CloseButtonType = {
    color?: string;
    height?: string;
    width?: string;
};

export const CloseButton: React.FC<CloseButtonType> = ({ color, height = '20', width = '20' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M2 2L18 18M2 18L18 2" stroke={color || '#403845'} />
    </svg>
);
