import styled from '@emotion/styled';
import { CalendarConfigT, Event, CalendarItemType } from './types';
import dayjs from 'dayjs';
import { calendarCategoriesColors, colors } from '../../common/constants';
import { v4 as uuidv4 } from 'uuid';

export const StyleWrapper = styled.div`
    width: 100%;
    height: 62vh;
    padding-left: 28px;
    padding-right: 28px;
    font-family: Poppins, sans-serif;
    position: relative;

    .fc-scrollgrid {
        border: none !important;
    }
    .fc-scrollgrid td:last-of-type {
        border-right: none !important;
        border-bottom: none !important;
    }
    .fc-timegrid-axis {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.087);
    }
    .fc {
        border: none;
    }
    .fc-timegrid {
        background-color: ${colors.calendarBackGround};
    }
    .fc-toolbar {
        display: none;
    }
    .fc-col-header-cell {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.087);
        height: 50px;
    }
    .fc-day-past {
        background-color: ${colors.calendarPastDatesBackground} !important;
    }
    .fc-day-today {
        background-color: ${colors.calendarCurrentDateBackground} !important;
    }
    .fc-day-today a {
        padding: 1px 10px;
        background-color: ${colors.calendarCurrentDateTextBackground};
        border-radius: 15px;
    }
    .fc-scrollgrid-sync-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }
    .fc-col-header-cell-cushion {
        font-weight: 600;
        color: ${colors.brand};
        text-transform: uppercase;
        font-size: 10px;
    }
    .fc-timegrid-slot-label-cushion {
        font-weight: 600;
        color: ${colors.brand};
        text-transform: uppercase;
        font-size: 10px;
        margin-right: 12px;
    }
    .fc-scrollgrid-shrink {
        border: none;
    }
    .fc-timegrid-slot {
        border: 1px dashed rgba(79, 209, 197, 0.15);
    }
    .fc-timegrid-col {
        border: 1px dashed rgba(79, 209, 197, 0.15);
    }
    .fc-timegrid-slot-label {
        border: none !important;
    }
    .fc-timegrid-event-harness-inset {
        border-radius: 1px;
    }
    .fc-event-title-container {
        border: none !important;
        font-size: 8px;
        font-weight: 600;
        color: ${colors.brand};
    }
    .fc-event-today {
        border-radius: 3px !important;
        padding: 0 !important;
    }
    .fc-event-past {
        background-color: #d1d2db !important;
        border: none !important;
    }
    .fc-timegrid-slot {
        height: 50px !important;
    }
    .fc {
        height: 85%;
    }
`;

export const CalendarConfig: CalendarConfigT = {
    slotMinTime: '07:00:00',
    slotMaxTime: '23:00:00',
    dayHeaderFormat: {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        omitCommas: true,
    },
    slotDuration: '01:00:00',
};

export const combineDateAndTime = (date: string, time: string) => {
    return dayjs(date)
        .set('hour', dayjs(time.replace('0001', new Date().getFullYear() + '')).get('hour'))
        .set('minute', dayjs(time.replace('0001', new Date().getFullYear() + '')).get('minute'))
        .set('seconds', 0)
        .set('milliseconds', 0)
        .toISOString();
};

export const generateEventsFromDate = (
    data: CalendarItemType[],
    forceCategory?: keyof typeof calendarCategoriesColors,
): Event[] =>
    data.map((elem: CalendarItemType) => ({
        id: uuidv4(),
        title: elem.assistRequestIds.length + '',
        start: combineDateAndTime(elem.date, elem.fromTime),
        end: combineDateAndTime(elem.date, elem.toTime),
        color: calendarCategoriesColors[
            forceCategory || (elem.categoryId as keyof typeof calendarCategoriesColors)
        ].color,
        borderColor:
            calendarCategoriesColors[
                forceCategory || (elem.categoryId as keyof typeof calendarCategoriesColors)
            ].color,
        type: calendarCategoriesColors[
            forceCategory || (elem.categoryId as keyof typeof calendarCategoriesColors)
        ].type,
    }));
