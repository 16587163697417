import React from 'react';
import { Button, Flex, VStack, ModalBody, Text } from '@chakra-ui/react';
import { Header } from './Header';
import { roundAplicantPrices } from '../../../../utils';
interface ILegalWarningProps {
    paymentValue: number;
    onClose: () => void;
    onConfirm: () => void;
    confirmLoading: boolean;
}

export const LegalWarning: React.FC<ILegalWarningProps> = ({
    paymentValue,
    onClose,
    onConfirm,
    confirmLoading,
}) => (
    <>
        <Header onClose={onClose} title="Confirmation" />
        <ModalBody overflowY="auto">
            <Flex flexDirection={'column'} h={'full'}>
                <VStack spacing={'20px'}>
                    <Text fontSize="16px" color="brand" fontWeight={400} lineHeight="22px">
                        By submitting this form, you hereby authorize ZootPro to take all
                        commercially reasonable and necessary means to verify the veracity of
                        information entered and to confirm that your ownership of the US state or
                        federally-chartered bank or credit union account (“Bank Account”) submitted.
                    </Text>
                    <Text fontSize="16px" color="brand" fontWeight={400} lineHeight="22px">
                        You hereby acknowledge and authorize the third party payment service
                        provider specified or approved by ZootPro (the “PSP”) to make or receive
                        payments and to originate this funds transfer.
                    </Text>
                    <Text fontSize="16px" color="brand" fontWeight={400} lineHeight="22px">
                        You also agree and acknowledge that there is a 1.5% transfer fee for this
                        payment.
                    </Text>
                </VStack>

                <Button
                    isFullWidth
                    variant={'primary'}
                    onClick={onConfirm}
                    mt="30px"
                    isLoading={confirmLoading}
                >
                    {`Pay $${roundAplicantPrices(paymentValue)}`}
                </Button>
            </Flex>
        </ModalBody>
    </>
);
