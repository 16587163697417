import { call, put, takeEvery } from 'redux-saga/effects';
import API from '../../common/api/api.base';
import { createChat } from '../actions/messages.actions';
import { push } from 'connected-react-router';
import {
    toggleUserProfileModal,
} from '../actions/profile.actions';

type mockType = {
    value: string,
    success: boolean,
    errors: any,
    messages: any
}

function* workerCreateChat(action: ReturnType<typeof createChat>) {
    try {
        const result: mockType = yield call(API.post, `/api/chat/${action.payload}`, {});
        yield put(push(`messages/${action.payload}`));
        yield put(toggleUserProfileModal({ value: false, userId: action.payload }))
    } catch (error) {
        console.log('workerGetOpenHouseInfo', error);
    }
}

export function* watchCreateChat() {
    yield takeEvery(createChat.type, workerCreateChat);
}
