import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    Text,
    CloseButton,
    Flex,
    Tabs,
    TabPanels,
    TabPanel,
    Tab,
    TabList,
} from '@chakra-ui/react';
import { colors } from '../../../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import {
    getRequest,
    getApplicantsToRequest,
    getPartnerApplicantsRequest,
    deleteRequest,
    changeClientName,
    changeClientPhone,
    changeClientType,
    changePrivateNote,
} from '../../store/actions';
import { getOpenHouseInfo } from '../../../../store/actions/listings.actions';
import { getBonusTypes, getClientTypes } from '../../../../store/actions/directories.actions';
import { Applicants, Info } from './tabs';
import { useDelta } from 'react-delta';
import {
    ChangeClientName,
    ChangeClientPhone,
    ChangeClientType,
    ChangePrivateNote,
} from '../../types';

interface IMyRequestInfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    pendingRequestToShowId: number;
    onGetMyRequests?: (value?: number) => void;
    toApplicants?: boolean;
}

export const MyRequestInfoModal: React.FC<IMyRequestInfoModalProps> = ({
    isOpen,
    onClose,
    pendingRequestToShowId,
    onGetMyRequests,
    toApplicants,
}) => {
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);

    const { value: requestInfo, loading: requestInfoLoading } = useSelector(
        (state: RootStore) => state.requests.requestInfo,
    );
    const bonusTypes = useSelector((state: RootStore) => state.directories.bonusTypes);
    const clientTypes = useSelector((state: RootStore) => state.directories.clientTypes);

    const { loading: deleteLoading, errors: deleteErrors } = useSelector(
        (state: RootStore) => state.requests.deleteRequest,
    );
    const {
        items: applicants,
        partnerApplicants,
        loading: applicantsLoading,
    } = useSelector((state: RootStore) => state.requests.applicants);
    const { value: openHouseInfo, loading: openHouseInfoLoading } = useSelector(
        (state: RootStore) => state.listings.openHouseInfo,
    );

    const deleteLoadingDelta = useDelta(deleteLoading);

    useEffect(() => {
        if (deleteLoadingDelta && deleteLoadingDelta.prev) {
            if (deleteLoadingDelta.prev && !deleteLoadingDelta.curr && !deleteErrors.length) {
                onClose();
                onGetMyRequests?.(1);
            }
        }
    }, [deleteLoadingDelta]);

    useEffect(() => {
        if (isOpen && pendingRequestToShowId) {
            dispatch(getRequest(pendingRequestToShowId));
        } else {
            setTabIndex(0);
        }
    }, [isOpen]);

    useEffect(() => {
        if (toApplicants) {
            setTabIndex(1);
        }
    }, [toApplicants]);

    useEffect(() => {
        if (requestInfo.timeSlot.id !== 0) {
            dispatch(getOpenHouseInfo(requestInfo.timeSlot.listingId));
        }
    }, [requestInfo]);

    useEffect(() => {
        dispatch(getClientTypes());
        dispatch(getBonusTypes());
    }, []);

    useEffect(() => {
        if (tabIndex === 1) {
            dispatch(getApplicantsToRequest(pendingRequestToShowId));
            dispatch(getPartnerApplicantsRequest(pendingRequestToShowId));
        }
    }, [tabIndex]);

    const onDeleteRequest = (id: number) => dispatch(deleteRequest(id));
    const onChangeClientName = (payload: ChangeClientName) => {
        dispatch(changeClientName(payload));
    };
    const onChangeClientPhone = (payload: ChangeClientPhone) => {
        dispatch(changeClientPhone(payload));
    };
    const onChangeClientType = (payload: ChangeClientType) => {
        dispatch(changeClientType(payload));
    };
    const onChangePrivateNote = (payload: ChangePrivateNote) => {
        dispatch(changePrivateNote(payload));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay />
            <ModalContent
                h="85vh"
                borderRadius="18px"
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Tabs
                    variant="unstyled"
                    index={tabIndex}
                    w="100%"
                    h="100%"
                    py="20px"
                    px="70px"
                    overflow="auto"
                >
                    <ModalHeader>
                        <Flex align="center" justify="space-between" my="20px">
                            <Text fontSize="22px" lineHeight="18px" color="brand" fontWeight="bold">
                                {requestInfo.requestTypeName}
                            </Text>
                            <CloseButton color="brand" onClick={onClose} zIndex="10" />
                        </Flex>
                        <TabList>
                            <Tab
                                border={tabIndex === 0 ? '1px solid #2C335C' : '1px solid #888DAB'}
                                borderRadius="20px"
                                minW="150px"
                                height="35px"
                                onClick={() => setTabIndex(0)}
                                bg={tabIndex === 0 ? '#2C335C' : 'transparent'}
                                mr="10px"
                            >
                                <Text
                                    fontSize="14px"
                                    color={tabIndex === 0 ? 'white' : 'secondaryFontDarker'}
                                    fontWeight={400}
                                >
                                    Info
                                </Text>
                            </Tab>
                            <Tab
                                border={tabIndex === 1 ? '1px solid #2C335C' : '1px solid #888DAB'}
                                borderRadius="20px"
                                minW="150px"
                                height="35px"
                                onClick={() => setTabIndex(1)}
                                bg={tabIndex === 1 ? '#2C335C' : 'transparent'}
                            >
                                <Text
                                    fontSize="14px"
                                    color={tabIndex === 1 ? 'white' : 'secondaryFontDarker'}
                                    fontWeight={400}
                                >
                                    Applicants
                                </Text>
                            </Tab>
                        </TabList>
                    </ModalHeader>
                    <ModalBody w="100%">
                        <TabPanels>
                            <TabPanel p="0" h="100%" pt="10px">
                                <Info
                                    requestInfo={requestInfo}
                                    requestInfoLoading={requestInfoLoading}
                                    deleteLoading={deleteLoading}
                                    bonusTypes={bonusTypes}
                                    clientTypes={clientTypes}
                                    openHouseInfo={openHouseInfo}
                                    openHouseInfoLoading={openHouseInfoLoading}
                                    onDeleteRequest={onDeleteRequest}
                                    onChangeClientName={onChangeClientName}
                                    onChangeClientPhone={onChangeClientPhone}
                                    onChangeClientType={onChangeClientType}
                                    onChangePrivateNote={onChangePrivateNote}
                                />
                            </TabPanel>
                            <TabPanel p="0" h="100%" pt="10px">
                                <Applicants
                                    items={applicants}
                                    loading={applicantsLoading}
                                    partnerApplicants={partnerApplicants}
                                    requestId={requestInfo.id}
                                />
                            </TabPanel>
                        </TabPanels>
                    </ModalBody>
                </Tabs>
            </ModalContent>
        </Modal>
    );
};
