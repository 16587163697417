import React from 'react';
import { Flex, Heading, Text, Link, Button } from '@chakra-ui/react';
import { colors } from '../../constants';
import { fonts } from '../../utils';

interface TrialBannerProps {
    title?: string;
    buttonTitle?: string;
    textUnderButton?: string;
}

export const TrialBanner: React.FC<TrialBannerProps> = ({
    title,
    buttonTitle,
    textUnderButton,
}) => (
    <Flex
        bg={colors.calendarBlue}
        direction="column"
        justifyContent="center"
        align={{ base: 'center', md: 'center' }}
        p="0 15px"
        fontFamily={fonts.montserrat}
        minH="345px"
    >
        <Heading
            m="0"
            fontFamily={fonts.montserrat}
            fontWeight="bold"
            fontSize={{ base: '20px', lg: '32px' }}
            lineHeight={{ base: '32px', lg: '52px' }}
            color="white"
            textAlign={{ base: 'center', md: 'center' }}
            maxWidth={1200}
        >
            {title ||
                'Leverage the full power of technology, your brokerage, and the MLS to work for your business.'}
        </Heading>

        <Button
            fontWeight="700"
            fontSize={{ base: '14px', lg: '16px' }}
            width={{ base: '260px', md: '306px' }}
            height={{ base: '50px', lg: '60px' }}
            backgroundColor="#FFBF00"
            color={colors.brand}
            borderRadius="25px"
            my="20px"
            _hover={{
                textDecoration: 'none',
            }}
            as={Link}
            href="/auth/signup/createaccount"
        >
            {buttonTitle || 'Activate your free account'}
        </Button>
        {textUnderButton && (
            <Text
                textTransform="capitalize"
                fontWeight={400}
                fontSize="16px"
                lineHeight="26px"
                color="white"
            >
                {textUnderButton}
            </Text>
        )}
    </Flex>
);
