import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Text, Avatar, HStack, Flex, IconButton, chakra } from '@chakra-ui/react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import { IconDescription } from '../../../common/components/blocks';

import {
    getAvatarPicture,
    fonts,
    formatDateTime,
    convertTimeIn12Hours,
} from '../../../common/utils';
import { toggleUserProfileModal } from '../../../store/actions/profile.actions';

import { colors } from '../../../common/constants';

import {
    Calendar,
    Location,
    Clock,
    Home,
    ConsultMethodIcon,
    DropdownArrowIcon,
} from '../../../assets/icons';

import { ActivitiesResponseType } from '../types';
import { IdNameTypes } from '../../../common/types';

interface IMyActivityItemProps extends ActivitiesResponseType {
    onOpenModal: () => void;
    requestCategories: Array<IdNameTypes>;
}

export const MyActivityItem: React.FC<IMyActivityItemProps> = ({
    requestTypeName,
    states,
    ownerFullName,
    ownerId,
    addresses,
    dateTime,
    propertiesToShow,
    duration,
    ownerAvatar,
    categoryId,
    onOpenModal,
    requestCategories,
    typeId,
    state,
    timeSlot,
    timeZone,
}) => {
    const dispatch = useDispatch();
    const categoryName = requestCategories.find((item: any) => item.id === categoryId)?.name;

    const openHouseDate = timeSlot && formatDateTime(timeSlot.date).date;
    const openHouseTimeFrom = timeSlot && convertTimeIn12Hours(timeSlot.startTime, timeZone);
    const openHouseTimeTo = timeSlot && convertTimeIn12Hours(timeSlot.endTime, timeZone);

    const getLocationSubtext = () => {
        switch (typeId) {
            case 1:
            case 7:
                return states.join(',');
            case 4:
            case 2:
            case 3:
            case 5:
            case 6:
                return addresses.length ? addresses?.join('') : null;
            default:
                return null;
        }
    };

    return (
        <Box>
            <Text fontSize="15px" fontWeight="500" color="#4FD1C5" alignSelf="flex-start" mb="5px">
                {categoryName}
            </Text>
            <Flex h="90px" bg="inputBackground" borderRadius="14px" px="25px" mb="10px">
                <HStack align="flex-start" alignSelf="center" width="40%">
                    <Box>
                        <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={600}>
                            {requestTypeName}
                        </Text>
                        {getLocationSubtext() ? (
                            <HStack>
                                <Location />
                                <Text
                                    color="brand"
                                    fontSize="12px"
                                    lineHeight="16px"
                                    fontWeight={500}
                                >
                                    {getLocationSubtext()}
                                </Text>
                            </HStack>
                        ) : null}
                    </Box>
                </HStack>
                <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
                    {typeId === 7 ? (
                        <IconDescription
                            icon={
                                <Text
                                    color="brand"
                                    fontSize="12px"
                                    fontWeight="500"
                                    fontFamily={fonts.montserrat}
                                >
                                    State:
                                </Text>
                            }
                            text={state || ''}
                        />
                    ) : typeId === 6 ? (
                        <>
                            <IconDescription icon={<Calendar />} text={openHouseDate} />
                            <IconDescription icon={<Clock />} text={openHouseTimeFrom} />
                            <IconDescription icon={<Clock />} text={openHouseTimeTo} />
                        </>
                    ) : (
                        <>
                            <IconDescription
                                icon={<Calendar />}
                                text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                            />
                            <IconDescription
                                icon={<Clock />}
                                text={dayjs(dateTime).format('hh:mmA')}
                            />
                            <IconDescription
                                icon={
                                    requestTypeName === 'Showing' ? (
                                        <Home color={colors.brand} width="16" height="16" />
                                    ) : (
                                        <ConsultMethodIcon />
                                    )
                                }
                                text={
                                    requestTypeName === 'Showing'
                                        ? `${propertiesToShow} ${pluralize(
                                              'house',
                                              propertiesToShow,
                                          )}`
                                        : `${duration} ${pluralize('hour', duration)}`
                                }
                            />
                        </>
                    )}
                </Flex>
                <Flex alignItems="center" w="40%">
                    <Avatar
                        name={ownerFullName}
                        src={getAvatarPicture(ownerAvatar)}
                        cursor="pointer"
                        w="30px"
                        h="30px"
                        ml="auto"
                        onClick={() => {
                            dispatch(
                                toggleUserProfileModal({
                                    value: true,
                                    userId: ownerId,
                                }),
                            );
                        }}
                    />
                    <IconButton
                        onClick={onOpenModal}
                        bg="transparent"
                        ml="25px"
                        alignSelf="center"
                        aria-label="More for job"
                        icon={
                            <chakra.span transform="rotate(-90deg)">
                                <DropdownArrowIcon />
                            </chakra.span>
                        }
                    />
                </Flex>
            </Flex>
        </Box>
    );
};
