import React from 'react';
import { colors } from '../../common/constants';

export const RechargeIcon: React.FC = () => {
    return (
        <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.5 1.5L10.5 5.5M6.5 1.5L2.5 5.5M6.5 1.5V12M0 13.5H13"
                stroke={colors.brand}
            />
        </svg>
    );
};
