import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {ErrorMessages} from "../../../../../../common/schemes/messages";

export type EditDwollaAccountFormValues = {
    city: string,
    state: string,
    postalCode:string,
    address1:string
    address2:string
    email:string
};

const EditDwollaAccountResolver = yup.object().shape({
    city: yup.string().required(ErrorMessages.REQUIRED),
    state: yup.string().required(ErrorMessages.REQUIRED),
    postalCode: yup.string().required(ErrorMessages.REQUIRED).matches(/^[0-9]{5}$/, 'Must be exactly 5 digits'),
    address1: yup.string().required(ErrorMessages.REQUIRED),
    address2: yup.string().required(ErrorMessages.REQUIRED),
    email: yup.string().email(ErrorMessages.EMAIL_INVALID).required(ErrorMessages.REQUIRED),
});
export default yupResolver(EditDwollaAccountResolver);
