import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import { IGetClientTypesSuccessResponse, IResponseWithCustomValue } from '../../../common/types';
import {
  getClientTypes,
  getClientTypesError,
  getClientTypesSuccess,
} from '../../actions/directories.actions';

function* workerGetClientTypes() {
  try {
    const result: IResponseWithCustomValue<IGetClientTypesSuccessResponse> = yield call(
      Api.get,
      '/api/directories/get-client-types',
      {},
    );
    if (result.success) {
      yield put(getClientTypesSuccess(result));
    } else {
      yield put(getClientTypesError(result.errors));
      console.log('getClientTypesError', result);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetClientTypesDirectorySaga() {
  yield takeEvery(getClientTypes.type, workerGetClientTypes);
}
