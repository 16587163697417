import React from 'react';

export const Activity: React.FC = () => (
    <svg width="35" height="35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35Z"
            fill="#B65CA1"
        />
        <path
            d="M10 23.533h14M15.133 21.2v-5.6m3.734 5.6v-8.4m3.733 8.4V10m-11.2 8.4v2.8"
            stroke="#fff"
        />
    </svg>
);
