import React from 'react';
import {
    Box,
    Flex,
    Text,
    Accordion,
    chakra,
    HStack,
    Center,
    Link as ChakraLink,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { BackArrow } from '../../../../assets/icons';
import { SupportItem } from './components/SupportItem';
import { Link } from 'react-router-dom';

const SECTIONS = [
    {
        title: 'Frequently Asked Questions',
        content: (
            <>
                <Box mb="10px">
                    <Text fontWeight="bold" color="brand" mb="5px">
                        What do I need to do in order to use ZootPro?
                    </Text>
                    <Text color="brand">
                        Anyone who has a valid real estate sales or broker license in the US can use
                        the system. Simply download the app and sign up, or sign up for free through
                        the website.
                    </Text>
                </Box>
                <Box mb="10px">
                    <Text fontWeight="bold" color="brand" mb="5px">
                        There are only 2 agents in my brokerage, can we use ZootPro?
                    </Text>
                    <Text color="brand">
                        Yes, ZootPro can be used by large or small brokers. ZootPro is the only
                        minimal-risk way to outsource assistance for licensed activities.
                    </Text>
                </Box>
                <Box mb="10px">
                    <Text fontWeight="bold" color="brand" mb="5px">
                        Can I use ZootPro in Europe?
                    </Text>
                    <Text color="brand">ZootPro is currently available only in the US.</Text>
                </Box>
                <Box mb="10px">
                    <Text fontWeight="bold" color="brand" mb="5px">
                        Where in the US can I use ZootPro?
                    </Text>
                    <Text color="brand">
                        ZootPro is available to licensed real estate agents and brokers in every US
                        state. Sign up for free today and begin to leverage the services of other
                        agents right away!
                    </Text>
                </Box>
                <Box mb="10px">
                    <Text fontWeight="bold" color="brand" mb="5px">
                        I am a Broker, how do I get all of my agents on this system?
                    </Text>
                    <Text color="brand">
                        For brokers looking to onboard 5 or more agents, we have made the process
                        very easy. Simply send an email to{' '}
                        <ChakraLink href="mailto:info@zootpro.com">info@zootpro.com</ChakraLink> to
                        begin the process and we will take the process from there.
                    </Text>
                </Box>
                <Box>
                    <Text fontWeight="bold" color="brand" mb="5px">
                        I am real estate agent licensed in two different states, which state would I
                        be able to use ZootPro in?
                    </Text>
                    <Text color="brand">
                        ZootPro was designed and developed with the many configurable situations an
                        agent finds herself in, and you can use your licenses in the two states you
                        are licensed in.
                    </Text>
                </Box>
            </>
        ),
    },
    {
        title: 'Privacy Policy',
        content: (
            <Link to={`/privacy-policy`}>
                <Text color="brand">Go to Privacy Policy</Text>
            </Link>
        ),
    },
    {
        title: 'Terms of Service',
        content: (
            <Link to={`/terms-and-conditions`}>
                <Text color="brand">Go to Terms of Service</Text>
            </Link>
        ),
    },
    {
        title: 'Cancellation Policy',
        content: (
            <Text color="brand">
                If you cancel a confirmed activity{' '}
                <chakra.span d="inline" fontWeight="bold">
                    within 1 hour
                </chakra.span>{' '}
                of its scheduled start time, you{' '}
                <chakra.span d="inline" fontWeight="bold">
                    may be charged a one-hour cancellation fee
                </chakra.span>{' '}
                of $30. We have this policy because time and business relationships deserving
                commitment are at stake and we want to ensure all agents uphold both sides of a work
                commitment.Cancellations may be reviewed internally to determine if a fee is
                applicable.
            </Text>
        ),
    },
    {
        title: 'How Can I Deactivate my Account?',
        content: (
            <Text color="brand">
                To deactivate your account, please send an email to{' '}
                <ChakraLink href="mailto:registrations@zootpro.com">
                    registrations@zootpro.com
                </ChakraLink>
                .
            </Text>
        ),
    },
];

export const Support: React.FC<any> = () => {
    const history = useHistory();

    return (
        <Box minh={'85vh'}>
            <Flex
                justify="space-between"
                align="center"
                mb="40px"
                width="100%"
                h="50px"
                position="relative"
            >
                <HStack spacing="70px">
                    <Box onClick={() => history.goBack()} as="button" position="absolute" left="0">
                        <BackArrow />
                    </Box>
                    <Text fontSize="22px" fontWeight="700" color="brand">
                        Support
                    </Text>
                </HStack>

                <Center
                    bg="brand"
                    color="white"
                    h="100%"
                    p="0 20px"
                    borderRadius="10px"
                    as={Link}
                    to="/help"
                >
                    Write to a Support
                </Center>
            </Flex>
            <Accordion allowMultiple>
                {SECTIONS.map((item, index) => (
                    <SupportItem key={index} title={item.title} content={item.content} />
                ))}
            </Accordion>
        </Box>
    );
};
