import React from 'react';
import { HStack, useRadio, RadioProps, Box, Text } from '@chakra-ui/react';

export const RadioCard: React.FC<RadioProps> = props => {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <HStack as="label">
            <Box>
                <input {...input} />
                <Box
                    {...checkbox}
                    cursor="pointer"
                    borderWidth="1px"
                    borderRadius="50%"
                    borderColor="#888DAB"
                    _checked={{
                        color: 'white',
                        borderColor: 'teal.600',
                    }}
                    _focus={{
                        boxShadow: 'outline',
                    }}
                    p={'10px'}
                    position="relative"
                >
                    {(input as any).checked && (
                        <Box
                            position="absolute"
                            _checked={{ bg: 'red', w: '7px', h: '7px' }}
                            w="14px"
                            top="3px"
                            right="3px"
                            h="14px"
                            zIndex="10"
                            bg="brand"
                            borderRadius="50%"
                        />
                    )}
                </Box>
            </Box>
            <Text
                fontSize="18px"
                lineHeight="27px"
                fontWeight="300"
                color={(input as any).checked ? 'brand' : 'secondaryFontDarker'}
            >
                {props.children}
            </Text>
        </HStack>
    );
};
