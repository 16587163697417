import { call, put, takeEvery } from 'redux-saga/effects';
import {
    getLocation,
    getLocationSuccess,
    getLocationError,
    setLocation,
    setLocationSuccess,
    setLocationError,
    setNeverUseLocation,
    setNeverUseLocationSuccess,
    setNeverUseLocationError,
} from './actions';
import {
    IResponseWithoutValue,
    SetLocationPayload,
    IResponseWithCustomValue,
} from './../../../../../common/types';
import API from '../../../../../common/api/api.base';

function* workerSetLocation(action: ReturnType<typeof setLocation>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            '/api/profile/set-location',
            action.payload,
        );
        console.log('SETLOCATION', action, result);
        if (result.success) {
            yield put(setLocationSuccess(action.payload));
        } else {
            yield put(setLocationError(result.errors));
        }
    } catch (error) {
        console.log(error, 'workerSetLocation');
    }
}

function* workerGetLocation() {
    try {
        const result: IResponseWithCustomValue<SetLocationPayload> = yield call(
            API.get,
            '/api/profile/get-location',
            {},
        );
        if (result.success) {
            yield put(getLocationSuccess(result));
            console.log('getLocationSuccess', result);
        } else {
            yield put(getLocationError(result.errors));
            console.log('getLocationError', result.errors);
        }
    } catch (e) {
        console.log(e, 'workerGetLocation');
    }
}

function* workerSetLocationNever() {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            '/api/profile/set-location-use-never',
            {},
        );
        console.log(result, 'setNeverUseLocation');
        if (result.success) {
            yield put(setNeverUseLocationSuccess(result));
        } else {
            yield put(setNeverUseLocationError(result.errors));
        }
    } catch (e) {
        console.log(e, 'workerSetLocationNever');
    }
}

export default function* watchLocationSaga() {
    yield takeEvery(setLocation.type, workerSetLocation);
    yield takeEvery(getLocation.type, workerGetLocation);
    yield takeEvery(setNeverUseLocation.type, workerSetLocationNever);
}
