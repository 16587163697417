import React, { FC, useState } from 'react';

import { Container, Box, Flex, Heading, Text, Image, Collapse } from '@chakra-ui/react';

import DropDownArrowIcon from '../../../../assets/landing/dropdown-arrow-icon.svg';

import { fonts } from '../../../utils';
import { colors } from '../../../constants';

interface IProps {
    header: string;
    text: string;
    borderBetween?: boolean;
}

export const DropDownInfoList: FC<IProps> = ({ header, text, borderBetween }) => {
    const [isOpened, setIsOpened] = useState(false);

    const rotate = isOpened ? 'rotate(90deg)' : 'rotate(0deg)';

    return (
        <Box
            padding="0"
            borderBottom={borderBetween ? '1px solid #D1D2DB' : 'none'}
            overflow="hidden"
        >
            <Flex
                padding="25px"
                justifyContent="space-between"
                alignItems="start"
                cursor="pointer"
                onClick={() => setIsOpened(prev => !prev)}
            >
                <Heading
                    fontFamily={fonts.ubuntu}
                    color={colors.brand}
                    fontSize="20px"
                    fontWeight="700"
                >
                    {header}
                </Heading>

                <Image
                    src={DropDownArrowIcon}
                    alt="arrow"
                    mt="2px"
                    transform={rotate}
                    transitionDuration="200ms"
                />
            </Flex>

            <Collapse in={isOpened}>
                <Text maxW="675px" padding="25px" fontFamily={fonts.mulish} lineHeight="26px">
                    {text}
                </Text>
            </Collapse>
        </Box>
    );
};
