import React from 'react';
import { colors } from '../../common/constants';

export const GooglePlayIcon: React.FC = () => (
    <svg
        width="1vw"
        height="22px"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.7422 8.33558L10.9147 5.5497L3.4056 1.21709C3.17534 1.08241 2.91482 1.00795 2.64813 1.0006C2.38145 0.993254 2.11722 1.05325 1.87988 1.17505L12.4233 11.7014L15.7422 8.33558Z"
            fill={colors.brand}
            stroke="white"
            strokeOpacity="0.6"
            strokeWidth="1.60143"
            strokeMiterlimit="10"
        />
        <path
            d="M1.87985 2.17529C1.37173 2.43297 1 2.95485 1 3.60558V20.9383C1 21.9165 1.83997 22.6039 2.72077 22.5394L12.4232 12.7015L1.87985 2.17529Z"
            fill={colors.brand}
            stroke="white"
            strokeOpacity="0.6"
            strokeWidth="1.60143"
            strokeMiterlimit="10"
        />
        <path
            d="M18.4242 10.884L15.7427 9.33655L12.4238 12.7019L15.2301 15.5036L18.4242 13.6594C19.4934 13.0429 19.4934 11.5005 18.4242 10.884Z"
            fill={colors.brand}
            stroke="white"
            strokeOpacity="0.6"
            strokeWidth="1.60143"
            strokeMiterlimit="10"
        />
        <path
            d="M2.7207 22.5389C2.96215 22.5208 3.19632 22.448 3.4055 22.3261L10.9146 17.9928L15.2295 15.5031L12.4232 12.7014L2.7207 22.5389Z"
            fill={colors.brand}
            stroke="white"
            strokeOpacity="0.6"
            strokeWidth="1.60143"
            strokeMiterlimit="10"
        />
    </svg>
);
