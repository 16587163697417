import React, { useState, useEffect } from 'react';
import { Flex, Box, HStack, Text, IconButton, chakra } from '@chakra-ui/react';
import {
    Location,
    Calendar,
    Clock,
    Home,
    ConsultMethodIcon,
    DropdownArrowIcon,
    ClockIcon,
    CloseButton,
    CheckIcon,
} from '../../../../../assets/icons';
import { colors } from '../../../../../common/constants';
import { IconDescription } from '../../../../components/blocks';
import { AvailableForApplicationResponse } from '../../../../../pages/Calendar/types';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { CountDownTimer } from '../../../blocks/CountDownTimer/CountDownTimer';
import { AcceptRequestsPayload } from '../../../../../pages/Requests/types';

interface IListItemProps extends AvailableForApplicationResponse {
    onPressDetails: () => void;
    onDeleteRequestAfterTimer: (id: number) => void;
    onAcceptRequest: (data: AcceptRequestsPayload) => void;
    setCreatedAt: (arg0: string) => void;
    handleOpenAvailableModal: (id: number) => void;
}

export const ListItem: React.FC<IListItemProps> = ({
    addresses,
    canBeAcceptedTill,
    city,
    createdAt,
    dateTime,
    duration,
    fromTime,
    houseType,
    id,
    ownerId,
    photo,
    postalCode,
    price,
    propertiesToShow,
    relatedUserAvatar,
    requestCategoryId,
    requestTypeId,
    requestTypeName,
    states,
    timeSlot,
    onPressDetails,
    onDeleteRequestAfterTimer,
    onAcceptRequest,
    setCreatedAt,
    handleOpenAvailableModal,
}) => {
    const [timeLeft, setTimeLeft] = useState(() => {
        const date1 = dayjs(createdAt);
        const date2 = dayjs();
        const timeDiff = date2.diff(date1, 'seconds');
        return 3600 - timeDiff;
    });

    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    useEffect(() => {
        if (timeLeft <= 0) {
            onDeleteRequestAfterTimer(id);
            setButtonsDisabled(true);
        }
    }, [timeLeft]);

    useEffect(() => {
        setCreatedAt(createdAt);
    }, []);

    const handleAcceptRequest = (IsAccepted: boolean) => {
        onAcceptRequest({
            RequestId: id,
            IsAccepted,
            PageSize: 10,
            includeOtherAgent: true,
            categoryIds: [],
            date: createdAt,
        });
        setButtonsDisabled(true);
        setTimeLeft(0);
    };

    return (
        <>
            <Text color="#FE805C">Are you available?</Text>
            <Flex
                h="70px"
                bg="inputBackground"
                borderRadius="14px"
                justifyContent="space-between"
                px="25px"
                mb="10px"
            >
                <HStack align="flex-start" alignSelf="center" width="40%">
                    <Box>
                        <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={600}>
                            {requestTypeName}
                        </Text>
                        <HStack>
                            <Location />
                            <Text color="brand" fontSize="12px" lineHeight="16px" fontWeight={500}>
                                {requestTypeName === 'Showing'
                                    ? states?.join(',')
                                    : `${states?.join('')}, ${addresses?.join('')}`}
                            </Text>
                        </HStack>
                    </Box>
                </HStack>
                <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
                    <IconDescription
                        icon={<Calendar />}
                        text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                    />
                    <IconDescription
                        icon={<Clock />}
                        text={`Fr: ${dayjs(dateTime).format('hh:mm A')}`}
                    />
                    <IconDescription
                        icon={
                            requestTypeName === 'Showing' ? (
                                <Home color={colors.brand} width="16" height="16" />
                            ) : (
                                <ConsultMethodIcon />
                            )
                        }
                        text={
                            requestTypeName === 'Showing'
                                ? `${propertiesToShow} ${pluralize('house', propertiesToShow)}`
                                : `${duration} ${pluralize('hour', duration)}`
                        }
                    />
                    <Box w="27vw" h="100%" d="flex" flexDirection="row" alignItems="center">
                        {!buttonsDisabled && (
                            <Box
                                d="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                                h="100%"
                                w="10vw"
                            >
                                <ClockIcon color="#FE805C" />
                                <Box
                                    color="#FE805C"
                                    fontWeight={500}
                                    fontSize="16px"
                                    lineHeight="16px"
                                    ml="10px"
                                >
                                    <CountDownTimer
                                        size="15px"
                                        color="orange"
                                        timeLeft={timeLeft}
                                        setTimeLeft={setTimeLeft}
                                    />
                                </Box>
                            </Box>
                        )}
                        {!buttonsDisabled && (
                            <Flex
                                h="100%"
                                w="8vw"
                                as="button"
                                bgColor="rgba(254,128,92, 0.15)"
                                alignItems="center"
                                justifyContent="center"
                                onClick={() => handleAcceptRequest(false)}
                                disabled={buttonsDisabled}
                            >
                                <CloseButton height="12" width="12" color={colors.calendarRed} />
                                <Text color="#FE805C" ml={2}>
                                    No
                                </Text>
                            </Flex>
                        )}
                        {!buttonsDisabled && (
                            <Flex
                                h="100%"
                                w="8vw"
                                as="button"
                                bgColor="rgba(111,207,151, 0.15)"
                                alignItems="center"
                                justifyContent="center"
                                onClick={() => handleAcceptRequest(true)}
                                disabled={buttonsDisabled}
                            >
                                <CheckIcon color={colors.green} />
                                <Text color="#6FCF97" ml={2}>
                                    Yes
                                </Text>
                            </Flex>
                        )}
                    </Box>
                </Flex>
                <IconButton
                    onClick={() => handleOpenAvailableModal(id)}
                    bg="transparent"
                    alignSelf="center"
                    aria-label="More for job"
                    ml="auto"
                    icon={
                        <chakra.span transform="rotate(-90deg)">
                            <DropdownArrowIcon />
                        </chakra.span>
                    }
                />
            </Flex>
        </>
    );
};
