import React from 'react';
import { useSelector } from 'react-redux';

import { Flex, Button } from '@chakra-ui/react';

import AgentInfoCard from '../../../../../common/components/blocks/AgentInfoCard';

import { RootStore } from '../../../../../store';

import { ApplicantType, RequestInfoType } from '../../../../Requests/types';
import { ToggleUserProfileModalPayload } from '../../../../../common/types';

interface IAgentInfoTabProps {
    activity: RequestInfoType;
    onClose: () => void;
    applicants: ApplicantType[];
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
    onCreateChat: (userId: number) => void;
}

export const AgentInfoTab: React.FC<IAgentInfoTabProps> = ({ activity, onClose, onCreateChat }) => {
    const authorizedProfile = useSelector((state: RootStore) => state.user);

    const isMyActivity = activity.ownerId === authorizedProfile.user.id;

    const personType = isMyActivity ? 'assistant' : 'owner';

    return (
        <Flex flexDirection={'column'} h={'full'}>
            <AgentInfoCard
                avatar={activity[isMyActivity ? 'assistantAvatar' : 'avatar']}
                fullName={activity[`${personType}FullName` as keyof RequestInfoType] as string}
                agencyName={activity[`agencyName` as keyof RequestInfoType] as string}
                phoneNumber={
                    activity[`${personType}PhoneNumber` as keyof RequestInfoType] as string
                }
                email={activity[`${personType}Email` as keyof RequestInfoType] as string}
                userId={activity[`${personType}Id` as keyof RequestInfoType] as number}
            />

            <Button
                onClick={() => {
                    onCreateChat(activity[`${personType}Id` as keyof RequestInfoType] as number);
                    onClose();
                }}
                alignSelf={'flex-end'}
                mt={'auto'}
                w={'380px'}
                variant={'primary'}
            >
                Send Message
            </Button>
        </Flex>
    );
};
