import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, Text, Box, HStack, Avatar } from '@chakra-ui/react';
import * as yup from 'yup';

import { RequestInfoType } from '../../../types';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import {
    getOpenHouseHostTypes,
    getClientTypes,
    getBonusTypes,
    getOpenHouseAudiences,
    getOpenHouseTypes,
} from '../../../../../store/actions/directories.actions';
import { deleteListingTimeSlot } from '../../../../../store/actions/listings.actions';
import { changeClientType, changePrivateNote } from '../../../store/actions';

import { getAvatarPicture, getTimeByTimeZone } from '../../../../../common/utils';

import {
    CustomInput,
    CustomButton,
    Loader,
    OpenHouseInfoSection,
    CustomDropdownEdit,
    CustomInputEdit,
} from '../../../../../common/components';

import { ClockIcon, Calendar, IWillPayAgentForHostingIcon } from '../../../../../assets/icons';

import { ErrorMessages } from '../../../../../common/schemes/messages';

import { RootStore } from '../../../../../store';

import { IdNameTypes, OpenHouseInfoType } from '../../../../../common/types';

interface IOpenHouseInfoProps {
    openHouseInfo: OpenHouseInfoType;
    requestInfo: RequestInfoType;
    loading: boolean;
    requestLoading?: boolean;
}

export const OpenHouseInfo: React.FC<IOpenHouseInfoProps> = ({
    loading,
    openHouseInfo,
    requestInfo,
    requestLoading,
}) => {
    const dispatch = useDispatch();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    const [clientTypeValue, setClientTypeValue] = useState(requestInfo.clientTypeId);

    const { loading: deleteLoading, id: deleteId } = useSelector(
        (state: RootStore) => state.listings.deleteTimeSlot,
    );

    const { timeZone } = openHouseInfo;

    const startTimeValue = getTimeByTimeZone(requestInfo.timeSlot.startTime, timeZone).format(
        'hh:mm A',
    );
    const endTimeValue = getTimeByTimeZone(requestInfo.timeSlot.endTime, timeZone).format(
        'hh:mm A',
    );

    const onDeleteTimeSlot = () =>
        dispatch(
            deleteListingTimeSlot({
                id: requestInfo.timeSlot.id,
                listingId: requestInfo.timeSlot.listingId,
            }),
        );

    const { openHouseHostTypes, clientTypes, bonusTypes, openHouseAudiences, openHouseTypes } =
        useSelector((state: RootStore) => state.directories);

    useEffect(() => {
        dispatch(getOpenHouseHostTypes());
        dispatch(getClientTypes());
        dispatch(getBonusTypes());
        dispatch(getOpenHouseAudiences());
        dispatch(getOpenHouseTypes());
    }, []);

    const handleEditClientType = (clientTypedId: number) => {
        dispatch(
            changeClientType({
                requestId: requestInfo.id,
                clientTypedId,
            }),
        );
    };

    const handleEditPrivateNote = (privateNote: string) => {
        dispatch(
            changePrivateNote({
                requestId: requestInfo.id,
                privateNote,
            }),
        );
    };

    return (
        <>
            {loading || requestLoading ? (
                <Loader centerHeight="65vh" />
            ) : (
                <Flex position="relative">
                    <OpenHouseInfoSection
                        openHouseInfo={openHouseInfo}
                        optionalContainerProps={{
                            width: '60%',
                            mr: 0,
                            pr: '30px',
                            borderRight: 'none',
                        }}
                    />
                    <Box
                        bg="#D4E3EF"
                        position="absolute"
                        right="-70px"
                        w="calc(40% + 70px)"
                        height="calc(100% + 165px)"
                        top="-145px"
                        pt="145px"
                        pr="70px"
                        pl="30px"
                    >
                        <HStack spacing={2}>
                            <Avatar
                                name={openHouseInfo?.agentName}
                                src={getAvatarPicture(openHouseInfo?.avatar)}
                                w="54px"
                                h="54px"
                            />
                            <Box>
                                <Text
                                    color="brand"
                                    fontWeight={700}
                                    fontSize="16px"
                                    lineHeight="23px"
                                >
                                    {openHouseInfo?.agentName}
                                </Text>
                                <Text color="secondaryFontDarker" fontSize="12px" lineHeight="18px">
                                    MLS Id: {openHouseInfo?.agentMlsId}
                                </Text>
                            </Box>
                        </HStack>
                        <Text
                            mt="10px"
                            fontSize="16px"
                            color="secondaryFontDarker"
                            lineHeight="23px"
                            fontWeight={500}
                            mb="25px"
                        >
                            {openHouseInfo?.agentOfficeName}
                        </Text>
                        {isLoaded ? (
                            <GoogleMap
                                options={{ disableDefaultUI: true }}
                                mapContainerStyle={{
                                    height: '275px',
                                    width: '100%',
                                    borderRadius: '20px',
                                }}
                                center={{
                                    lat: Number(openHouseInfo?.latitude),
                                    lng: Number(openHouseInfo?.longitude),
                                }}
                                zoom={10}
                            >
                                <Marker
                                    position={{
                                        lat: Number(openHouseInfo?.latitude),
                                        lng: Number(openHouseInfo?.longitude),
                                    }}
                                />
                            </GoogleMap>
                        ) : (
                            <></>
                        )}
                        <Text
                            color="brand"
                            fontWeight="semibold"
                            fontSize="13px"
                            lineHeight="19px"
                            mt="30px"
                            mb="15px"
                        >
                            Selected Open House time
                        </Text>
                        <CustomInput
                            label="Select Date"
                            placeholder="Select Date"
                            register={{
                                value: getTimeByTimeZone(
                                    requestInfo.timeSlot.date,
                                    timeZone,
                                ).format('MM/DD/YY'),
                            }}
                            rightIcon={<Calendar />}
                            isReadOnly={true}
                            margin="0 0 26px 0"
                        />
                        <HStack mb="26px">
                            <CustomInput
                                register={{
                                    value: startTimeValue,
                                }}
                                label="Start time"
                                placeholder="From time"
                                type="text"
                                rightIcon={<ClockIcon color="#888DAB" />}
                                width="100%"
                                isReadOnly={true}
                            />
                            <CustomInput
                                register={{
                                    value: endTimeValue,
                                }}
                                label="End time"
                                placeholder="End time"
                                type="text"
                                width="100%"
                                rightIcon={<ClockIcon color="#888DAB" />}
                                isReadOnly={true}
                            />
                        </HStack>
                        {requestInfo.timeSlot.openHouseHostTypeId ? (
                            <CustomInput
                                label="Who Will Host This House?"
                                isReadOnly={true}
                                register={{
                                    value:
                                        openHouseHostTypes.find(
                                            (elem: IdNameTypes) =>
                                                elem.id ===
                                                requestInfo.timeSlot.openHouseHostTypeId,
                                        )?.name || '',
                                }}
                                margin="0 0 26px 0"
                            />
                        ) : null}
                        {requestInfo.clientTypeId ? (
                            <CustomDropdownEdit
                                options={clientTypes.map((item: IdNameTypes) => ({
                                    value: item.id,
                                    label: item.name,
                                    id: item.id,
                                }))}
                                label="The Seller is:"
                                value={clientTypeValue}
                                isReadOnly={true}
                                onChange={setClientTypeValue}
                                editFunction={handleEditClientType}
                                margin="0 0 26px 0"
                            />
                        ) : null}

                        {requestInfo.wouldLikeToOfferBonus ? (
                            <>
                                <CustomInput
                                    label="I Would Like To Offer A Bonus If A Contract Is Ratified From The Open House Within 48-Hours After The Showing:"
                                    isReadOnly={true}
                                    register={{
                                        value: requestInfo.wouldLikeToOfferBonus ? 'Yes' : 'No',
                                    }}
                                    margin="0 0 26px 0"
                                />
                                <Flex>
                                    <CustomInput
                                        label="Bonus Type:"
                                        isReadOnly={true}
                                        register={{
                                            value: requestInfo.bonusTypeId
                                                ? bonusTypes.find(
                                                      (elem: IdNameTypes) =>
                                                          elem.id === requestInfo.bonusTypeId,
                                                  )?.name
                                                : '',
                                        }}
                                        margin="0 0 26px 0"
                                    />
                                    <Box w="20px" />
                                    <CustomInput
                                        label="Bonus:"
                                        isReadOnly={true}
                                        register={{
                                            value: requestInfo.amount?.toString() || '',
                                        }}
                                        margin="0 0 26px 0"
                                    />
                                </Flex>
                            </>
                        ) : null}
                        <CustomInput
                            defaultValue={requestInfo?.privateNote || ''}
                            label="Private Note"
                            placeholder="Enter"
                            margin="0 0 26px 0"
                            isReadOnly={true}
                        />

                        {requestInfo.timeSlot.openHouseAudienceId ? (
                            <CustomInput
                                label="Audience for Open House:"
                                isReadOnly={true}
                                register={{
                                    value:
                                        openHouseAudiences.find(
                                            (elem: IdNameTypes) =>
                                                elem.id ===
                                                requestInfo.timeSlot.openHouseAudienceId,
                                        )?.name || '',
                                }}
                                margin="0 0 26px 0"
                            />
                        ) : null}

                        {requestInfo.timeSlot.openHouseTypeId ? (
                            <CustomInput
                                label="Type of Open House:"
                                isReadOnly={true}
                                register={{
                                    value:
                                        openHouseTypes.find(
                                            (elem: IdNameTypes) =>
                                                elem.id === requestInfo.timeSlot.openHouseTypeId,
                                        )?.name || '',
                                }}
                                margin="0 0 26px 0"
                            />
                        ) : null}

                        {requestInfo.timeSlot.virtualOpenHouseLink ? (
                            <CustomInput
                                label="Link To Virtual Open House:"
                                isReadOnly={true}
                                register={{ value: requestInfo.timeSlot.virtualOpenHouseLink }}
                                margin="0 0 26px 0"
                            />
                        ) : null}

                        {requestInfo.iWillPayAgentForHosting && (
                            <Flex align="center" mb="85px">
                                <IWillPayAgentForHostingIcon />
                                <Text color="brand" fontWeight="300" fontSize="14px" ml="8px">
                                    I Will Pay Agent For Hosting This O/H
                                </Text>
                            </Flex>
                        )}

                        <CustomButton
                            text="Delete"
                            bgColor="brand"
                            height="60px"
                            onClick={onDeleteTimeSlot}
                            isLoading={deleteLoading && deleteId === requestInfo.timeSlot.id}
                        />
                    </Box>
                </Flex>
            )}
        </>
    );
};
