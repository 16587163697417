import React, { useEffect } from 'react';
import { PaymentsModalWrapper } from '../components';
import { VStack, Text } from '@chakra-ui/react';
import { CustomButton, CustomInput } from '../../../../../common/components';
import { Resolver, useForm } from 'react-hook-form';
import verifyMicroDepositsResolver, {
    VerifyMicroDepositsFormValues,
} from '../components/validation/verifyMicroDeposits.schema';
import { useDispatch } from 'react-redux';
import { verifyMicroDepositsRequest } from '../store/actions';

interface IVerifyPaymentMethodModalProps {
    isOpen: boolean;
    title: string;
    onClose: () => void;
    verifyFundingSourceId: string;
    verifyMicroDepositsLoading: boolean;
}

export const VerifyPaymentMethodModal: React.FC<IVerifyPaymentMethodModalProps> = ({
    isOpen,
    title,
    onClose,
    verifyFundingSourceId,
    verifyMicroDepositsLoading,
}) => {
    const {
        handleSubmit,
        formState: { errors },
        register,
        reset,
    } = useForm<VerifyMicroDepositsFormValues>({
        resolver: verifyMicroDepositsResolver as Resolver<VerifyMicroDepositsFormValues>,
    });

    const dispatch = useDispatch();

    const onSubmit = (data: VerifyMicroDepositsFormValues) => {
        dispatch(
            verifyMicroDepositsRequest({
                ...data,
                fundingSourceId: verifyFundingSourceId,
            }),
        );
    };

    useEffect(() => {
        if (!isOpen) {
            reset({});
        }
    }, [isOpen]);

    return (
        <PaymentsModalWrapper isCentered isOpen={isOpen} title={title} onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack
                    mx={'auto'}
                    align={'stretch'}
                    maxW={'342px'}
                    spacing={'30px'}
                >
                    <Text
                        fontSize={'16px'}
                        lineHeight={'24px'}
                        fontWeight={'600'}
                        textAlign={'center'}
                        textTransform={'capitalize'}
                    >
                        Please enter amount of micro deposits that you’ve got on your bank account
                    </Text>
                    <CustomInput
                        label={''}
                        register={{ ...register('firstDepositValue') }}
                        errors={errors.firstDepositValue}
                        placeholder={'Enter'}
                    />
                    <CustomInput
                        errors={errors.secondDepositValue}
                        register={{ ...register('secondDepositValue') }}
                        label={''}
                        placeholder={'Enter'}
                    />
                    <CustomButton
                        isLoading={verifyMicroDepositsLoading}
                        text="Check"
                        type="submit"
                        height="60px"
                        bgColor="brand"
                    />
                </VStack>
            </form>
        </PaymentsModalWrapper>
    );
};
