import React from 'react';
import { Box, HStack, Text, Center, Button, Link, Image } from '@chakra-ui/react';
import { GooglePlayIcon, AppleIcon, ListCheckmark } from '../../../../assets/icons';
import SubscriptionPentagon from '../../../../assets/img/subscribe_pentagon.png';
import { fonts } from '../../../../common/utils';

export const points = [
    'Showing Assistance',
    'Home Inspection Assistance',
    'Listings Management',
    'Open House',
    'Agency Networking',
];

interface IPriceBoxProps {
    color: string;
    amount: string;
    duration: string;
}

const PriceBox: React.FC<IPriceBoxProps> = ({ amount, color, duration }) => (
    <Center bg={color} borderRadius="16px" w="100%" h="120px">
        <Box>
            <Text
                fontSize="39px"
                color="white"
                textAlign="center"
                lineHeight="46px"
                fontWeight="600"
                fontFamily={fonts.avenirRoman}
            >
                ${amount}
            </Text>
            <Text
                fontSize="15px"
                color="white"
                lineHeight="20px"
                fontWeight={500}
                textAlign="center"
                fontFamily={fonts.avenirRoman}
            >
                {duration}
            </Text>
        </Box>
    </Center>
);

export const AvailableOptionsOverview: React.FC = () => (
    <>
        <Text
            color="brand"
            fontSize="18px"
            fontWeight={600}
            lineHeight="27px"
            textTransform="capitalize"
        >
            You are not subscribed. Download the mobile app In order to create a subscription for
            your account.
        </Text>
        <HStack mt="15px">
            <Button
                leftIcon={<GooglePlayIcon />}
                bg="rgba(44, 51, 92, 0.1)"
                borderRadius="25px"
                height="44px"
                color="brand"
                fontSize="12px"
                fontWeight={400}
                letterSpacing="0.1em"
                w="160px"
                as={Link}
                target={'_blank'}
                href="https://play.google.com/store/apps/details?id=com.zootpro"
            >
                Google Play
            </Button>
            <Button
                leftIcon={<AppleIcon />}
                bg="rgba(44, 51, 92, 0.1)"
                borderRadius="25px"
                height="44px"
                color="brand"
                fontSize="12px"
                fontWeight={400}
                letterSpacing="0.1em"
                w="160px"
                as={Link}
                target={'_blank'}
                href="https://apps.apple.com/app/zootpro/id1601250757"
            >
                App Store
            </Button>
        </HStack>
        <Text
            color="brand"
            fontSize="18px"
            fontWeight={600}
            lineHeight="27px"
            mt="40px"
            mb="10px"
            textTransform="capitalize"
        >
            Available options for Subscription
        </Text>
        <Box
            bg="#4FD1C5"
            borderRadius="26px"
            p="30px"
            position="relative"
            overflow="hidden"
            mb="30px"
        >
            <Text
                fontSize="26px"
                lineHeight="26px"
                fontWeight={800}
                color="white"
                mb="20px"
                fontFamily={fonts.avenirRoman}
            >
                Subscription Include:
            </Text>
            {points.map(elem => (
                <HStack key={elem} mb="10px">
                    <Center w="20px" h="20px" borderRadius="10px" bg="rgba(255,255,255, .1)">
                        <ListCheckmark />
                    </Center>
                    <Text
                        color="white"
                        fontSize="15px"
                        lineHeight="20px"
                        fontWeight={500}
                        fontFamily={fonts.avenirRoman}
                    >
                        {elem}
                    </Text>
                </HStack>
            ))}
            <Image src={SubscriptionPentagon} position="absolute" top="0" left="200px" />
        </Box>
        <HStack spacing="30px">
            <PriceBox amount="7.99" color="#867AFA" duration="/month" />
            <PriceBox amount="59.99" color="#f2c94c" duration="/year" />
        </HStack>
    </>
);
