import { createAction } from '@reduxjs/toolkit';
import { PayloadError } from '../../../common/types';
import {
    CalendarRequestPayload,
    CalendarItemsResponse,
    AvailableForApplicationPayload,
    AvailableForApplicationResponse,
    OpenHousesPayload,
    OpenHousesResponse,
} from '../types';

export const getCalendarDataRequest = createAction<CalendarRequestPayload>(
    '@calendar/GET_CALENDAR_DATA_REQUEST',
);
export const getCalendarDataSuccess = createAction<CalendarItemsResponse>(
    '@calendar/GET_CALENDAR_DATA_SUCCESS',
);
export const getCalendarDateError = createAction<PayloadError[]>(
    '@calendar/GET_CALENDAR_DATA_ERROR',
);

// OPPORTUNITIES
export const getCalendarOpportunitiesRequest = createAction<AvailableForApplicationPayload>(
    '@calendar/GET_OPPORTUITIES_DATA_REQUEST',
);
export const getCalendarOpportunitiesSuccess = createAction<{
    totalCount: number;
    models: { startTime: string; models: AvailableForApplicationResponse[] }[];
    pageNumber: number;
}>('@calendar/GET_OPPORTUNITIES_DATA_SUCCESS');
export const getCalendarOpportunitiesError = createAction<PayloadError[]>(
    '@calendar/GET_OPPORTINITIES_DATA_ERROR',
);

//Open Houses
export const getCalendarOpenHousesRequest = createAction<OpenHousesPayload>(
    '@calendar/GET_OPEN_HOUSES_DATA_REQUEST',
);
export const getCalendarOpenHousesSuccess = createAction<{
    totalCount: number;
    models: { startTime: string; models: AvailableForApplicationResponse[] }[];
    pageNumber: number;
}>('@calendar/GET_OPEN_HOUSES_DATA_SUCCESS');
export const getCalendarOpenHousesError = createAction<PayloadError[]>(
    '@calendar/GET_OPEN_HOUSES_DATA_ERROR',
);

//Assistance requests
export const getCalendarAssistanceRequestsRequest = createAction<AvailableForApplicationPayload>(
    '@calendar/GET_ASSISTANCE_REQUESTS_DATA_REQUEST',
);
export const getCalendarAssistanceRequestsSuccess = createAction<{
    totalCount: number;
    models: { startTime: string; models: AvailableForApplicationResponse[] }[];
    pageNumber: number;
}>('@calendar/GET__ASSISTANCE_REQUESTS__DATA_SUCCESS');
export const getCalendarAssistanceRequestsError = createAction<PayloadError[]>(
    '@calendar/GET__ASSISTANCE_REQUESTS__DATA_ERROR',
);

// My Activities
export const getCalendarMyActivitiesRequest = createAction<AvailableForApplicationPayload>(
    '@calendar/GET_MY_ACTIVITIES_REQUEST',
);
export const getCalendarMyActivitiesSuccess = createAction<{
    totalCount: number;
    models: { startTime: string; models: AvailableForApplicationResponse[] }[];
    pageNumber: number;
}>('@calendar/GET_MY_ACTIVITIES_SUCCESS');
export const getCalendarMyActivitiesError = createAction<PayloadError[]>(
    '@calendar/GET_MY_ACTIVITIES_ERROR',
);

// Other agent activities
export const getCalendarOtherAgentActivitiesRequest = createAction<AvailableForApplicationPayload>(
    '@calendar/GET_OTHER_AGENT_ACTIVITIES_REQUEST',
);
export const getCalendarOtherAgentsSuccess = createAction<{
    totalCount: number;
    models: { startTime: string; models: AvailableForApplicationResponse[] }[];
    pageNumber: number;
}>('@calendar/GET_OTHER_AGENTS_ACTIVITIES_SUCCESS');
export const getCalendarOtherAgentsActivitiesError = createAction<PayloadError[]>(
    '@calendar/GET_OTHER_AGENTS_ACTIVITIES_ERROR',
);
