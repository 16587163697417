export const notificationsKeyWordParse = (content: string) =>
    content
        .replace(
            /\bconfirm\b|\badded\b|\bavailable\b|\bresponse\b|\bupdated\b|\bposted\b|\bno longer available\b/gi,
            match => `<span class="bold">${match}</span>`,
        )
        .replace(
            /\bconfirmed\b|\bcomplete\b|\bfinished\b/gi,
            match => `<span class="green">${match}</span>`,
        )
        .replace(/\bsuspended\b|\bexpires\b/gi, match => `<span class="orange">${match}</span>`);
