import { put, call, takeEvery } from 'redux-saga/effects';
import {
    getOpenHouseDetails,
    getOpenHouseDetailsSuccess,
    getOpenHouseDetailsError,
} from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../../common/types';

function* workerGetOpenHouseDetails(action: ReturnType<typeof getOpenHouseDetails>) {
    console.log(action, 'getOpenHouseDetails');
    try {
        const result: IResponseWithoutValue = yield call(
            API.get,
            `/api/listings/openhouses/${action.payload}`,
            action.payload,
        );
        if (result.success) {
            yield put(getOpenHouseDetailsSuccess(result));
        } else {
            yield put(getOpenHouseDetailsError(result.errors));
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* watchGetOpenHouseDetailsSaga() {
    yield takeEvery(getOpenHouseDetails.type, workerGetOpenHouseDetails);
}
