import { call, put, takeEvery } from 'redux-saga/effects';
import { changeClientType, changeClientTypeError, changeClientTypeSuccess } from '../actions';
import { IResponseWithoutValue } from '../../../../common/types';
import API from '../../../../common/api/api.base';
import { Alerter } from './../../../../common/utils/Alerter';

function* workerChangeClientType(action: ReturnType<typeof changeClientType>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            '/api/requests/change-client-type',
            action.payload,
        );
        if (result.success) {
            yield put(changeClientTypeSuccess(result));
            Alerter.success('Client type changed successfully');
        } else {
            yield put(changeClientTypeError(result.errors));
        }
    } catch (error) {
        console.log(error, 'workerChangeClientType');
    }
}

export default function* watchChangeClientTypeSaga() {
    yield takeEvery(changeClientType.type, workerChangeClientType);
}
