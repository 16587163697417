import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { RootStore } from '../../../../../store';
import { SubmitHandler } from 'react-hook-form';
import { IAuthContainerProps } from '../../../../../common/types';
import { SignInEnterNewPassword } from './SignInEnterNewPassword';
import { PasswordConfirm } from '../../types';
import { setNewPassword } from '../../store/actions';

export const SignInEnterNewPasswordContainer: React.FC<IAuthContainerProps> = ({ nestedPath }) => {
    const loading = useSelector((state: RootStore) => state.auth.loading);

    const userId = useSelector((state: RootStore) => state.changePassword.value.userId);
    const token = useSelector((state: RootStore) => state.changePassword.value.token);

    const dispatch = useDispatch();

    const onSetNewPassword: SubmitHandler<PasswordConfirm> = payload => dispatch(setNewPassword(payload));

    return (
        <SignInEnterNewPassword loading={loading} token={token} userId={userId} onSetNewPassword={onSetNewPassword} />
    );
};
