import React from 'react';
import { Box, Text, Avatar, HStack, Flex, IconButton, chakra } from '@chakra-ui/react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import { dispatch } from '../../../../../../store';
import { toggleUserProfileModal } from '../../../../../../store/actions/profile.actions';
import { IconDescription } from './IconDescription/IconDescription';


import { IOpportunityItem } from '../dashboardItemsTypes';
import { fonts, getAvatarPicture } from '../../../../../utils';
import { colors } from '../../../../../constants';

import {
    Calendar,
    Clock,
    ConsultMethodIcon,
    DollarSignIcon,
    DropdownArrowIcon,
    Home,
    Location,
} from '../../../../../../assets/icons';

export const OpportunityItem: React.FC<IOpportunityItem> = ({
    requestTypeName,
    states,
    ownerFullName,
    agencyName,
    addresses,
    dateTime,
    propertiesToShow,
    duration,
    ownerAvatar,
    onOpenModal,
    typeId,
    state,
    finalPrice,
    title,
    ownerId,
}) => (
    <Box bg="inputBackground" borderRadius="8px" p="15px" mb="10px">
        <Text
            mb="15px"
            fontSize="20px"
            lineHeight="25px"
            color={colors.brand}
            fontFamily={fonts.poppins}
            textAlign="left"
            fontStyle="normal"
            fontWeight="500"
        >
            {title}
        </Text>
        <Flex>
            <HStack align="flex-start" alignSelf="center" width="40%">
                <Avatar
                    cursor="pointer"
                    name={ownerFullName}
                    src={getAvatarPicture(ownerAvatar)}
                    w="42px"
                    h="42px"
                    onClick={() => {
                        dispatch(
                            toggleUserProfileModal({
                                value: true,
                                userId: ownerId,
                            }),
                        );
                    }}
                />
                <Box>
                    <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={600} mb="2px">
                        {requestTypeName}
                    </Text>
                    <HStack>
                        <Location />
                        <Text
                            color="secondaryFontDarker"
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight={500}
                        >
                            {typeId === 1 || typeId === 7
                                ? states.join(',')
                                : `${states.join('')}, ${addresses?.join('')}`}
                        </Text>
                    </HStack>
                    <HStack mt="5px">
                        <Text color="brand" fontSize="12px" lineHeight="16px" fontWeight={500}>
                            {ownerFullName}
                        </Text>
                        <Text
                            color="secondaryFontDarker"
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight={500}
                        >
                            {agencyName}
                        </Text>
                    </HStack>
                </Box>
            </HStack>
            <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
                {typeId === 7 ? (
                    <IconDescription
                        icon={
                            <Text
                                color="brand"
                                fontSize="12px"
                                fontWeight="500"
                                fontFamily={fonts.montserrat}
                            >
                                State:
                            </Text>
                        }
                        text={state || ''}
                    />
                ) : (
                    <>
                        <IconDescription
                            icon={<Calendar />}
                            text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                        />
                        <IconDescription icon={<Clock />} text={dayjs(dateTime).format('hh:mmA')} />
                        <IconDescription
                            icon={
                                requestTypeName === 'Showing' ? (
                                    <Home color={colors.brand} width="16" height="16" />
                                ) : (
                                    <ConsultMethodIcon />
                                )
                            }
                            text={
                                requestTypeName === 'Showing'
                                    ? `${propertiesToShow} ${pluralize('house', propertiesToShow)}`
                                    : `${duration} ${pluralize('hour', duration)}`
                            }
                        />
                        <IconDescription icon={<DollarSignIcon />} text={finalPrice} />
                    </>
                )}
            </Flex>
            <IconButton
                onClick={onOpenModal}
                bg="transparent"
                ml="auto"
                alignSelf="center"
                aria-label="More for job"
                icon={
                    <chakra.span transform="rotate(-90deg)">
                        <DropdownArrowIcon />
                    </chakra.span>
                }
            />
        </Flex>
    </Box>
);
