import dayjs from 'dayjs';

export const getTimeOnService = (regDate: string) => {
    const date1 = dayjs(regDate);
    const date2 = dayjs();

    const daysDiff = date2.diff(date1, 'days');
    const monthDiff = date2.diff(date1, 'months');
    const yearsDiff = date2.diff(date1, 'years');

    if (yearsDiff !== 0) return `${yearsDiff} Years In Service`;
    if (monthDiff !== 0) return `${monthDiff} Month In Service`;
    if (daysDiff !== 0) return `${daysDiff} Days In Service`;

    return '0 Days In Service';
};
