import {createSlice} from "@reduxjs/toolkit";
import {PayloadError} from "../../../common/types";
import {unsubscribeEmailError, unsubscribeEmailRequest, unsubscribeEmailSuccess} from "./actions";

const initialState: { loading: boolean, errors: PayloadError[],isUnsubscribed: boolean } = {
    loading: false,
    errors: [],
    isUnsubscribed: false
}

const unsubscribeEmailSlice = createSlice({
    name: 'unsubscribeEmail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(unsubscribeEmailRequest, state => {
            state.loading = true
            state.errors = []
        }).addCase(unsubscribeEmailSuccess, state => {
            state.loading = false
            state.isUnsubscribed =  true
        }).addCase(unsubscribeEmailError, (state, action) => {
            state.loading = false
            state.errors = action.payload
        })
    }
})

export default unsubscribeEmailSlice.reducer
