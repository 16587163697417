import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {ErrorMessages} from "../../../../common/schemes/messages";

export type CreatePostFormValue = {
    title: string,
    body: string,
    photo: any
};

const createPostSchema = yup.object().shape({
    title: yup.string().max(200).required(ErrorMessages.REQUIRED),
    body: yup.string().max(1000).required(ErrorMessages.REQUIRED),
});

export default yupResolver(createPostSchema);
