import { all } from 'redux-saga/effects';
import { watchLoginSaga, watchLogoutSaga, watchRefreshTokenSaga } from './auth/auth.saga';
import {
    watchChangePasswordSaga,
    watchSendPinCodeSaga,
    watchSetNewPasswordSaga,
} from '../../pages/AuthPages/SignIn/store/saga';
import watchGetLicensesDraftSaga, {
    watchSignUpFillData,
    watchSignUpAddRealEstateLicencePhoto,
    watchSignUpAddDriverLicencePhoto,
    watchSignUpSendEmailCode,
    watchSignUpConfirmEmailCode,
    watchSignUpSendPhoneCode,
    watchSignUpConfirmPhoneCode,
    watchSignUpSignUpGetAgencies,
    watchAddNewAgencySaga,
} from '../../pages/AuthPages/SignUp/store/saga';
import watchOpportunitiesSaga from '../../pages/Opportunities/store/saga';
import watchOrderSaga from './order.saga';
import {
    watchGetAgenciesTypeDirectorySaga,
    watchGetBonusTypesDirectorySaga,
    watchGetClientTypesDirectorySaga,
    watchGetPostTagsDirectorySaga,
    watchGetReportTypesDirectorySaga,
    watchGetRequestCategoriesDirectorySaga,
    watchGetRequestTypesDirectorySaga,
    watchGetStatesDirectorySaga,
    watchGetCities,
} from './directories';
import { watchCreateChat } from '../sagas/messages.saga';
import watchRequestsSaga from '../../pages/Requests/store/sagas';
import watchListingsSaga from './listings.saga';
import watchDashboardSaga from './dashboard.saga';
import watchFindAssistanceSaga from '../../pages/FindAssistance/store/saga';
import watchSettingsSaga from '../../pages/Settings/store/sagas';
import watchUserInfoSaga from './user.saga';
import watchCalendarSaga from '../../pages/Calendar/store/saga';
import watchServiceHistorySaga from '../../pages/ServiceHistory/store/sagas';
import watchActivitySaga from '../../pages/Activity/store/saga';
import watchPostsSaga from '../../pages/Updates/store/saga';
import watchLocationSaga from '../../pages/Settings/pages/Location/store/sagas';
import watchNotificationsSaga from '../../pages/Notifications/store/saga';
import watchWriteToSupportSaga from '../../pages/ProfileHelp/store/sagas';
import watchSendIssueSaga from '../../common/components/modals/ActivityIssueModal/store/sagas';
import watchSetCurrentAgencySaga from '../../pages/Sidebar/store/sagas';
import watchProfileSaga from './profile.sagas';
import watchPaymentsSaga from '../../pages/Settings/pages/Payments/store/saga';
import watchGetOpenHouseTypesSaga from './directories/get-open-house-types.saga';
import watchGetOpenHouseHostTypesSaga from './directories/get-open-house-host-types.saga';
import watchGetOpenHouseAudiencesSaga from './directories/get-open-house-audiences.saga';
import watchOnboardingSaga from './onboarding.sagas';
import watchLandingSaga from './landing.saga';
import watchUnsubscribeEmailSaga from '../../pages/Unsubscribe/store/saga';
import watchGetMyAgencyOpenHousesSaga from '../../pages/HostOpenHouse/store/saga';
import watchSubscriptionsSaga from '../../pages/Settings/pages/Subscription/store/sagas';
import watchGetAgentsBySearchSaga from '../../common/components/blocks/SearchUsers/store/saga';

export default function* rootSaga() {
    yield all([
        watchLoginSaga(),
        watchLogoutSaga(),
        watchRefreshTokenSaga(),
        watchChangePasswordSaga(),
        watchSendPinCodeSaga(),
        watchSetNewPasswordSaga(),
        watchSignUpFillData(),
        watchSignUpAddRealEstateLicencePhoto(),
        watchSignUpAddDriverLicencePhoto(),
        watchSignUpSendEmailCode(),
        watchSignUpConfirmEmailCode(),
        watchSignUpSendPhoneCode(),
        watchSignUpConfirmPhoneCode(),
        watchSignUpSignUpGetAgencies(),
        watchOpportunitiesSaga(),
        watchOrderSaga(),
        watchGetAgenciesTypeDirectorySaga(),
        watchGetBonusTypesDirectorySaga(),
        watchGetClientTypesDirectorySaga(),
        watchGetPostTagsDirectorySaga(),
        watchGetReportTypesDirectorySaga(),
        watchGetRequestCategoriesDirectorySaga(),
        watchGetRequestTypesDirectorySaga(),
        watchGetStatesDirectorySaga(),
        watchRequestsSaga(),
        watchListingsSaga(),
        watchDashboardSaga(),
        watchFindAssistanceSaga(),
        watchSettingsSaga(),
        watchUserInfoSaga(),
        watchCalendarSaga(),
        watchServiceHistorySaga(),
        watchActivitySaga(),
        watchPostsSaga(),
        watchLocationSaga(),
        watchNotificationsSaga(),
        watchWriteToSupportSaga(),
        watchSendIssueSaga(),
        watchSetCurrentAgencySaga(),
        watchProfileSaga(),
        watchPaymentsSaga(),
        watchGetOpenHouseTypesSaga(),
        watchGetOpenHouseHostTypesSaga(),
        watchGetOpenHouseAudiencesSaga(),
        watchOnboardingSaga(),
        watchCreateChat(),
        watchLandingSaga(),
        watchAddNewAgencySaga(),
        watchGetLicensesDraftSaga(),
        watchUnsubscribeEmailSaga(),
        watchGetMyAgencyOpenHousesSaga(),
        watchGetCities(),
        watchSubscriptionsSaga(),
        watchGetAgentsBySearchSaga(),
    ]);
}
