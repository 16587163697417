import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { colors } from '../../../../common/constants';

interface IIconDescriptionProps {
    text: string | number;
    icon: JSX.Element;
}

export const IconDescription: React.FC<IIconDescriptionProps> = ({ icon, text }) => (
    <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        h="100%"
        borderRight={`1px solid ${colors.secondaryFont}`}
        minW="100px"
    >
        {icon}
        <Text
            color="brand"
            marginTop="10px"
            fontSize="13px"
            lineHeight="16px"
            px="5px"
            fontWeight={600}
            width="100px"
            textAlign="center"
            isTruncated
        >
            {text}
        </Text>
    </Flex>
);
