import React, { useEffect, useState } from 'react';
import { useDelta } from 'react-delta';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootStore } from '../../../../store';
import { getStates } from '../../../../store/actions/directories.actions';
import { signUpGetAgencies } from '../../../AuthPages/SignUp/store/actions';
import {
    addLicenseRequest,
    getLicenseDraftRequest,
    getLicensesRequest,
    saveLicenseDraftRequest,
} from '../../store/actions';
import {
    AddLicensePayload,
    SaveLicenseDraftPayload,
} from '../../types';
import { Licenses } from './Licenses';

export const LicensesContainer: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const states = useSelector((state: RootStore) => state.directories.states);
    const licenses = useSelector((state: RootStore) => state.settings.licenses);
    const draftLicense = useSelector((state: RootStore) => state.settings.draftLicense);
    const agencies = useSelector((state: RootStore) => state.signUp.agencies);
    const accessToken = useSelector((state: RootStore) => state.auth.user.token);
    const { loading: addLicenseLoading, errors: addLicenseErrors } = useSelector(
        (state: RootStore) => state.settings.addLicense,
    );
    const { loading: saveDraftLoading, errors: saveDraftErrors } = useSelector(
        (state: RootStore) => state.settings.saveDraft,
    );

    const addLicenseLoadingDelta = useDelta(addLicenseLoading);
    const saveDraftLoadingDelta = useDelta(saveDraftLoading);

    const saveLicenseDraft = (payload: SaveLicenseDraftPayload) =>
        dispatch(saveLicenseDraftRequest(payload));
    const addLicense = (payload: AddLicensePayload) => dispatch(addLicenseRequest(payload));

    const [showLicenseEditor, setShowLicenseEditor] = useState(false);
    const [licenseItem, setLicenseItem] = useState<any>({});
    const [state, setState] = useState('');
    const [stateId, setStateId] = useState('1');

    useEffect(() => {
        if (addLicenseLoadingDelta && addLicenseLoadingDelta.prev) {
            if (
                addLicenseLoadingDelta.prev &&
                !addLicenseLoadingDelta.curr &&
                !addLicenseErrors.length
            ) {
                setShowLicenseEditor(false);
                setLicenseItem({});
                dispatch(getLicensesRequest());
                dispatch(getLicenseDraftRequest());
            }
        }
    }, [addLicenseLoadingDelta]);

    useEffect(() => {
        if (saveDraftLoadingDelta && saveDraftLoadingDelta.prev) {
            if (
                saveDraftLoadingDelta.prev &&
                !saveDraftLoadingDelta.curr &&
                !saveDraftErrors.length
            ) {
                setShowLicenseEditor(false);
                setLicenseItem({});
            }
        }
    }, [saveDraftLoadingDelta]);

    const handleAddLicense = () => {
        setShowLicenseEditor(!showLicenseEditor)
    };

    useEffect(() => {
        dispatch(getLicensesRequest());
        dispatch(getLicenseDraftRequest());
    }, []);

    useEffect(() => {
        dispatch(getStates());
    }, []);

    useEffect(() => {
        if (state.length !== 0) {
            setStateId(states.find((st: any) => st.name === state)?.id || 1);
            dispatch(signUpGetAgencies({ token: accessToken.accessToken, stateId }));
        }
    }, [state]);

    return (
        <Licenses
            handleAddLicense={handleAddLicense}
            saveLicenseDraft={saveLicenseDraft}
            addLicense={addLicense}
            setState={setState}
            licenses={licenses}
            agencies={agencies}
            states={states}
            draft={draftLicense}
            addLicenseLoading={addLicenseLoading}
            saveDraftLoading={saveDraftLoading}
            showLicenseEditor={showLicenseEditor}
            accessToken={accessToken.accessToken}
            stateId={stateId}
        />
    );
};
