import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import addNewAgencyResolver from '../../../schemes/add-new-agency.schema';
import { colors } from '../../../constants';
import { CustomButton, CustomInput } from '../../blocks';
import { AddNewAgencyDataPayload, AddNewAgencyFormValues } from '../../../types';

interface IAddNewAgencyModalProps {
    onClose: () => void;
    isOpen: boolean;
    addNewAgencyLoading: boolean;
    onAddNewAgency: (payload: AddNewAgencyDataPayload) => void;
    stateId: number;
}

export const AddNewAgencyModal: React.FC<IAddNewAgencyModalProps> = ({
    onClose,
    isOpen,
    stateId,
    addNewAgencyLoading,
    onAddNewAgency,
}) => {
    const {
        formState: { errors: errorsModal },
        handleSubmit: handleSubmitModal,
        register: registerModal,
        clearErrors: clearErrorsModal,
        reset: resetModal,
    } = useForm<AddNewAgencyFormValues>({
        resolver: addNewAgencyResolver as Resolver<AddNewAgencyFormValues>,
    });

    const onSubmitModal = (data: AddNewAgencyFormValues) => {
        const payload: AddNewAgencyFormValues = {
            phoneNumber: data.phoneNumber.replace(/-/g, ''),
            name: data.name,
            email: data.email,
            address: data.address,
            licenseNumber: data.licenseNumber,
            principalBrokerName: data.principalBrokerName,
            principalBrokerLicenseNumber: data.principalBrokerLicenseNumber,
            contactPerson: data.contactPerson,
        };
        onAddNewAgency({ ...payload, stateId });
    };

    useEffect(() => {
        if (!isOpen) {
            resetModal({});
            clearErrorsModal();
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <ModalHeader color="brand">Can’t Find Your Brokerage?</ModalHeader>
                <ModalCloseButton />
                <ModalBody d="flex" flexDirection="column">
                    <form onSubmit={handleSubmitModal(onSubmitModal)} key="add-agency">
                        <Flex flexDirection="row">
                            <Flex flexDirection="column" p="25px" w="300px">
                                <CustomInput
                                    label="Brokerage Name *"
                                    margin="0 0 26px 0"
                                    placeholder="Enter"
                                    register={{ ...registerModal('name') }}
                                    errors={errorsModal.name}
                                />
                                <CustomInput
                                    label="Email *"
                                    margin="0 0 26px 0"
                                    placeholder="Enter"
                                    register={{ ...registerModal('email') }}
                                    errors={errorsModal.email}
                                />
                                <CustomInput
                                    label="Principal Broker Name"
                                    margin="0 0 26px 0"
                                    placeholder="Enter"
                                    register={{ ...registerModal('principalBrokerName') }}
                                    errors={errorsModal.principalBrokerName}
                                />
                                <CustomInput
                                    label="Brokerage License Number *"
                                    margin="0 0 26px 0"
                                    placeholder="Enter"
                                    type="number"
                                    register={{ ...registerModal('licenseNumber') }}
                                    errors={errorsModal.licenseNumber}
                                />
                            </Flex>
                            <Flex flexDirection="column" p="25px" w="300px">
                                <CustomInput
                                    label="Brokerage Address *"
                                    margin="0 0 26px 0"
                                    placeholder="Enter"
                                    register={{ ...registerModal('address') }}
                                    errors={errorsModal.address}
                                />
                                <CustomInput
                                    label="Phone Number *"
                                    margin="0 0 26px 0"
                                    placeholder="Enter"
                                    type="tel"
                                    mask="999-999-9999"
                                    register={{ ...registerModal('phoneNumber') }}
                                    errors={errorsModal.phoneNumber}
                                />
                                <CustomInput
                                    label="Principal Broker License Number"
                                    margin="0 0 26px 0"
                                    placeholder="Enter"
                                    register={{ ...registerModal('principalBrokerLicenseNumber') }}
                                    errors={errorsModal.principalBrokerLicenseNumber}
                                />
                                <CustomInput
                                    label="Contact Person"
                                    margin="0 0 26px 0"
                                    placeholder="Enter"
                                    register={{ ...registerModal('contactPerson') }}
                                    errors={errorsModal.contactPerson}
                                />
                            </Flex>
                        </Flex>
                        <Flex mb="15px" justifyContent="center">
                            <CustomButton
                                type="submit"
                                text="Confirm"
                                bgColor="brand"
                                height="60px"
                                width="300px"
                                isLoading={addNewAgencyLoading}
                            />
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
