import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';
import { login } from '../../../store/actions/auth/auth.actions';
import { RootStore } from '../../../store';

import { IAuthContainerProps, LoginPayload } from '../../../common/types';
import { SignIn } from './SignIn';

export const SignInContainer: React.FC<IAuthContainerProps> = ({ nestedPath }) => {
    const loading = useSelector((state: RootStore) => state.auth.loading);

    const dispatch = useDispatch();

    const onSubmit: SubmitHandler<LoginPayload> = payload => dispatch(login(payload));

    return <SignIn loading={loading} onSubmit={onSubmit} nestedPath={nestedPath} />;
};
