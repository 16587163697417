import React from 'react';

type BellIcon = {
    width?: string;
    height?: string;
};

export const Bell: React.FC<BellIcon> = ({ width, height }) => (
    <svg
        width={width || '24'}
        height={height || '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.59998 16.8H22.4M3.99998 16.8V8.80005C3.99998 4.38177 7.5817 0.800049 12 0.800049C16.4183 0.800049 20 4.38177 20 8.80005V16.8M8.79998 19.2V20C8.79998 21.7674 10.2327 23.2 12 23.2C13.7673 23.2 15.2 21.7674 15.2 20V19.2"
            stroke="white"
        />
    </svg>
);
