import React, { Dispatch, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Center, Image, Text } from '@chakra-ui/react';
import { CustomButton } from '../../../../../common/components';
import { UploadItemIcon } from '../../../../../assets/icons';

interface IUploadLicensePhotoProps {
    onDrop: (acceptedFiles: any) => void;
    uploadedFile: any;
    setUploadedFile: Dispatch<SetStateAction<string | ArrayBuffer | null>>;
    error: string;
    uploadNewDocumentLoading: boolean;
    onUploadLicenseDocument: () => void;
}

export const UploadLicensePhoto: React.FC<IUploadLicensePhotoProps> = ({
    onDrop,
    uploadedFile,
    setUploadedFile,
    error,
    uploadNewDocumentLoading,
    onUploadLicenseDocument,
}) => {
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Box maxW={'343px'} mt={'50px'} mx={'auto'}>
            {/* Image Box */}
            <Box
                overflow={'hidden'}
                bg={'#888DAB'}
                borderRadius={'10px'}
                h={'336px'}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {uploadedFile && (
                    <Image
                        objectFit={'cover'}
                        alt={'license image'}
                        src={
                            typeof uploadedFile === 'string'
                                ? uploadedFile
                                : URL.createObjectURL(uploadedFile)
                        }
                    />
                )}
            </Box>
            {error && (
                <Text mt={'8px'} fontSize={'12px'} color={'#EC7474'}>
                    {error}
                </Text>
            )}
            {/* Upload Photo */}
            <CustomButton
                {...getRootProps()}
                mt={'44px'}
                _hover={{
                    background: 'none',
                }}
                variant={'custom'}
                textDecoration={'underline'}
                fontWeight={'400'}
                color={'brand'}
                fontSize={'16px'}
                lineHeight={'24px'}
                leftIcon={
                    <Center p={'18px'} bg={'brand'}>
                        <UploadItemIcon />
                    </Center>
                }
                text={'Upload photo from gallery'}
                width={'auto'}
            />
            {/* Send Button*/}
            <CustomButton
                isLoading={uploadNewDocumentLoading}
                onClick={onUploadLicenseDocument}
                mt={'80px'}
                text="Send"
                height="60px"
                bgColor="#2C335C"
            />
        </Box>
    );
};
