import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../../../../../../common/schemes/messages';

export type CreateDwollaAccountFormValues = {
    city: string;
    state: string;
    postalCode: string;
    address1: string;
    address2: string;
    ssn: string;
    dateOfBirth: string;
};

const createDwollaAccountResolver = yup.object().shape({
    city: yup.string().required(ErrorMessages.REQUIRED),
    state: yup.string().required(ErrorMessages.REQUIRED),
    postalCode: yup
        .string()
        .required(ErrorMessages.REQUIRED)
        .matches(/^[0-9]{5}$/, 'Must be exactly 5 digits'),
    address1: yup.string().required(ErrorMessages.REQUIRED),
    ssn: yup
        .string()
        .required(ErrorMessages.REQUIRED)
        .matches(/^[0-9]{4}$/, 'Must be exactly 4 digits'),
    dateOfBirth: yup.string().required(ErrorMessages.REQUIRED),
});

export default yupResolver(createDwollaAccountResolver);
