import React from 'react';

export const PhoneWithWavesIcon:React.FC<{fill?: string}> = ({fill}) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 20 20"
            fill={fill || 'none'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.19748 11.6971C6.9158 10.4916 6.06793 8.89676 5.78548 7.16008C5.77217 7.05678 5.78284 6.95181 5.81666 6.85331C5.85048 6.75481 5.90653 6.66542 5.98048 6.59208L7.50948 5.06508C7.61929 4.95632 7.68898 4.81354 7.70714 4.66005C7.72531 4.50657 7.69088 4.35146 7.60948 4.22008L5.17148 0.440078C5.08129 0.296288 4.94079 0.191232 4.77737 0.145384C4.61394 0.0995366 4.4393 0.116183 4.28748 0.192078L0.379478 2.03208C0.255103 2.09321 0.152413 2.19094 0.0852 2.31214C0.017987 2.43334 -0.0105303 2.5722 0.00347824 2.71008C0.533394 7.07215 2.56744 11.113 5.75548 14.1371C8.77913 17.3258 12.8202 19.3603 17.1825 19.8901C17.3202 19.9042 17.459 19.8758 17.5802 19.8088C17.7014 19.7417 17.7992 19.6393 17.8605 19.5151L19.7035 15.6061C19.7792 15.4545 19.7958 15.2802 19.7502 15.117C19.7045 14.9538 19.5998 14.8134 19.4565 14.7231L15.6765 12.2891C15.5451 12.2077 15.39 12.1733 15.2365 12.1914C15.083 12.2096 14.9402 12.2793 14.8315 12.3891L13.3035 13.9141C13.2301 13.988 13.1407 14.0441 13.0422 14.0779C12.9437 14.1117 12.8388 14.1224 12.7355 14.1091C10.9992 13.8264 9.40468 12.9786 8.19948 11.6971H8.19748Z"
                fill={fill || 'white'}
            />
            <path
                d="M15.7011 10.6791C15.5199 10.6791 15.3462 10.6071 15.2181 10.479C15.09 10.351 15.0181 10.1772 15.0181 9.99608C15.0165 8.63893 14.4766 7.33781 13.517 6.37816C12.5573 5.4185 11.2562 4.87867 9.89906 4.87708C9.72257 4.87033 9.55556 4.79547 9.43308 4.66821C9.31061 4.54096 9.24219 4.3712 9.24219 4.19458C9.24219 4.01797 9.31061 3.84821 9.43308 3.72096C9.55556 3.5937 9.72257 3.51884 9.89906 3.51208C11.6182 3.51394 13.2663 4.19767 14.4819 5.41325C15.6975 6.62884 16.3812 8.27699 16.3831 9.99608C16.3831 10.1771 16.3112 10.3506 16.1834 10.4787C16.0555 10.6067 15.882 10.6788 15.7011 10.6791Z"
                fill={fill || 'white'}
            />
            <path
                d="M19.116 10.6791C18.9349 10.6791 18.7611 10.6072 18.6331 10.4791C18.505 10.351 18.433 10.1773 18.433 9.99612C18.4304 7.73411 17.5306 5.5655 15.9311 3.96601C14.3316 2.36652 12.163 1.46677 9.90101 1.46412C9.72452 1.45736 9.55751 1.3825 9.43504 1.25525C9.31256 1.12799 9.24414 0.95824 9.24414 0.781621C9.24414 0.605002 9.31256 0.43525 9.43504 0.307995C9.55751 0.180741 9.72452 0.105878 9.90101 0.0991211C12.526 0.10124 15.0429 1.14495 16.899 3.0011C18.7552 4.85725 19.7989 7.37413 19.801 9.99912C19.801 10.1803 19.7291 10.354 19.601 10.4821C19.4729 10.6102 19.2992 10.6821 19.118 10.6821L19.116 10.6791Z"
                fill={fill || 'white'}
            />
        </svg>
    );
};
