import { Box, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CustomButton, CustomInput, DatePicker } from '../../../common/components';
import { SearchCitiesPicker } from '../../../common/components/blocks/SearchCitiesPicker';
import { SearchMenu } from '../../../common/components/modals/OpenHousesModal/components/SearchMenu';
import { IdNameTypes } from '../../../common/types';
import { GetFilteredOpenHousesPayload } from '../types';

interface IHostOpenHouseSearchProps {
    myAgenciesLoading: boolean;
    onGetMyAgencyOpenHouses: (data: GetFilteredOpenHousesPayload) => void;
    listingTypes: IdNameTypes[];
}

export const HostOpenHouseSearch: React.FC<IHostOpenHouseSearchProps> = ({
    myAgenciesLoading,
    onGetMyAgencyOpenHouses,
    listingTypes,
}) => {
    const [typeIdsSelected, setTypeIdsSelected] = useState<number[]>([]);
    const [selectedCity, setSelectedCity] = useState<string | number>(0);

    const {
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        watch,
    } = useForm();

    const values = watch();

    const onSearch = (data: any) => {
        console.log(data)
        onGetMyAgencyOpenHouses({
            ...data,
            listingTypes: typeIdsSelected,
            cityId: selectedCity || null,
            date: data.dateTime
                ? dayjs(+data.dateTime).toISOString()
                : null,
        });
    };

    return (
        <form onSubmit={handleSubmit(onSearch)}>
            <Flex alignItems="flex-end" justifyContent="space-between" pt="10px">
                <SearchCitiesPicker minW="150px" setValue={setSelectedCity} />
                <Box ml="10px">
                    <SearchMenu
                        width="130px"
                        typeIdsSelected={typeIdsSelected}
                        setTypeIdsSelected={setTypeIdsSelected}
                        menuValues={listingTypes.map(type => {
                            return { label: type.name, value: type.id }
                        })}
                        isBorder
                    />
                </Box>
                <Box ml="10px">
                    <DatePicker
                        width="130px"
                        label="Select Date"
                        displayValue={
                            Number(values.dateTime)
                                ? dayjs.utc(Number(values.dateTime)).format('MM/DD/YY')
                                : ''
                        }
                        value={Number(values.dateTime) || dayjs.utc().valueOf()}
                        setValue={value => setValue('dateTime', value ? value.toString() : null)}
                        removeBottomMargin
                        placeholder="MM/DD/YY"
                        errMessage={errors.dateTime?.message}
                        borderRadius="50px"
                    />
                </Box>
                <CustomInput
                    label="Min. Price (in $1000s)"
                    width="150px"
                    placeholder="0"
                    margin="0 10px"
                    register={{ ...register('fromPrice') }}
                    type="number"
                />
                <CustomInput
                    label="Max. Price (in $1000s)"
                    width="150px"
                    placeholder="0"
                    margin="0 10px"
                    register={{ ...register('toPrice') }}
                    type="number"
                />
                <CustomButton
                    text="Search"
                    type="submit"
                    width="150px"
                    height="50px"
                    bgColor="brand"
                    isLoading={myAgenciesLoading}
                    disabled={myAgenciesLoading}
                />
            </Flex>
        </form>
    );
};
