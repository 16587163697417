import React from 'react';
import { Container, Box, Heading, Text, Flex, SimpleGrid } from '@chakra-ui/react';
import {
    LandingHeader,
    LandingFooter,
    FeatureBlock,
    FeaturesBanner,
} from '../../common/components/landing';
import { fonts } from '../../common/utils';
import FeaturesBG from '../../assets/landing/features-header-bg.png';
import { featurePoints } from '../../common/constants';

export const Features: React.FC = () => {
    return (
        <Box>
            <LandingHeader />
            <Box
                h={{ base: '300px', lg: '450px' }}
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={FeaturesBG}
                position="relative"
                backgroundSize="cover"
                backgroundPosition="center"
                overflowY="hidden"
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />
                <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                    <Flex direction="column" height="100%" justify="center" pb="70px">
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={{ base: '28px', lg: '54px' }}
                            lineHeight={{ base: '40px', lg: '70px' }}
                            fontWeight={{ base: '700', lg: '600' }}
                            w={{ base: '100%', lg: '60%' }}
                        >
                            Features
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize="16px"
                            lineHeight="24px"
                            my="23px"
                        >
                            Our platform is packed with powerful and innovative features.
                        </Text>
                    </Flex>
                </Container>
            </Box>
            <Box
                bg="white"
                borderTopRadius={{ base: '30px', md: '70px' }}
                mt="-70px"
                zIndex={10}
                position="relative"
            >
                <Container maxW="container.xl" py="75px">
                    <SimpleGrid
                        columns={{ base: 1, md: 2 }}
                        columnGap={{ base: '50px', lg: '100px' }}
                        rowGap="50px"
                    >
                        {featurePoints.map(feature => (
                            <FeatureBlock key={feature.title} {...feature} />
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>
            <FeaturesBanner />
            <LandingFooter />
        </Box>
    );
};
