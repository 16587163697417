import React from 'react';
import {
    Textarea,
    FormControl,
    Text,
    InputGroup,
    FormErrorMessage,
    TextareaProps,
} from '@chakra-ui/react';
import { fonts } from '../../../utils';

interface ICustomTextarea extends TextareaProps {
    errors?: any;
    register?: any;
    label: string;
    placeholder?: string;
    fontWeight?: number;
    fontSize?: string;
    fontFamily?: string;
    textSize?: string;
    width?: string;
}

export const CustomTextarea = React.forwardRef<null, ICustomTextarea>(
    (
        {
            label,
            errors,
            register,
            placeholder,
            textSize = '13px',
            fontFamily = fonts.poppins,
            fontSize = '13px',
            fontWeight = '300',
            width = '100%',
            ...props
        },
        ref,
    ) => (
        <FormControl isInvalid={!!errors}>
            <Text color="brand" fontWeight={fontWeight} fontSize={fontSize} fontFamily={fontFamily}>
                {label}
            </Text>
            <InputGroup>
                <Textarea
                    ref={ref}
                    bg="#F6F7FB"
                    borderRadius="20px"
                    color="brand"
                    placeholder={placeholder}
                    fontWeight={fontWeight}
                    fontSize={textSize}
                    fontFamily={fontFamily}
                    width={width}
                    {...register}
                    {...props}
                />
            </InputGroup>
            {errors && <FormErrorMessage fontSize={fontSize}>{errors.message}</FormErrorMessage>}
        </FormControl>
    ),
);

CustomTextarea.displayName = 'CustomTextarea';
