import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, VStack, useRadioGroup, Center } from '@chakra-ui/react';
import { CloseButton, BackArrow } from '../../../../assets/icons';
import { useHistory } from 'react-router';
import { CustomButton } from '../../../../common/components/blocks';
import { RadioCard, ManuallyLocation, SaveCurrentLocation } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { getLocation, setNeverUseLocation } from './store/actions';
import { RootStore } from '../../../../store';
import { AddressLocationType } from '../../../../common/types';

const initialState = {
    lat: 34.0522,
    lng: -118.2436,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
};

export const Location: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [selectedCategory, setSelectedCategory] = useState('1');
    const [regionPosition, setRegionPosition] = useState(initialState);
    const [address, setAddress] = useState<AddressLocationType>({ state: '', area: '' });

    const { loading, value: data, saveLoading } = useSelector((state: RootStore) => state.location);

    const options = [
        { id: '1', label: 'Use Current Location' },
        { id: '2', label: 'Manually Enter Location' },
        { id: '0', label: 'Never' },
    ];

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        onChange: (value: string) => setSelectedCategory(value),
        value: selectedCategory,
    });

    const group = getRootProps();

    const onGetCoords = (location: { lat: number; lng: number }) => {
        setRegionPosition(prev => ({
            ...prev,
            ...location,
        }));
    };

    useEffect(() => {
        dispatch(getLocation());
    }, []);

    useEffect(() => {
        setSelectedCategory(data.checkBox + '');
        if (data.checkBox === 2) {
            setRegionPosition(prev => ({ ...prev, lat: data.latitude, lng: data.longitude }));
        }
    }, [data]);

    return (
        <>
            <Box>
                <Flex justify="space-between" align="center" mb="40px" width="100%">
                    <Flex align={'center'}>
                        <Box as="button" onClick={history.goBack} mr={'36px'}>
                            <BackArrow />
                        </Box>
                        <Text fontSize="22px" fontWeight="700">
                            Location
                        </Text>
                    </Flex>
                    <Box onClick={() => history.goBack()} as="button">
                        <CloseButton />
                    </Box>
                </Flex>
                <Box>
                    <VStack align="flex-start" spacing="20px" {...group}>
                        {options.map(value => {
                            const radio = getRadioProps({ value: value.id });
                            return (
                                <RadioCard key={value.id} {...radio}>
                                    {value.label}
                                </RadioCard>
                            );
                        })}
                    </VStack>
                    {selectedCategory === '1' && (
                        <SaveCurrentLocation
                            onGetAddress={(val: { state: string; area: string }) => setAddress(val)}
                            onGetCoords={onGetCoords}
                            address={address}
                            regionPosition={regionPosition}
                            saveLoading={saveLoading}
                        />
                    )}
                    {selectedCategory === '2' && (
                        <ManuallyLocation
                            data={data}
                            regionPosition={regionPosition}
                            onGetCoords={onGetCoords}
                            setAddress={setAddress}
                            address={address}
                            saveLoading={saveLoading}
                        />
                    )}
                    {selectedCategory === '0' && (
                        <Center>
                            <CustomButton
                                bgColor="brand"
                                text="Save"
                                height="50px"
                                width="300px"
                                onClick={() => dispatch(setNeverUseLocation())}
                                isLoading={saveLoading}
                            />
                        </Center>
                    )}
                </Box>
            </Box>
        </>
    );
};
