import { put, call, takeEvery } from 'redux-saga/effects';
import API from '../../../common/api/api.base';
import {
    getPostsSuccess,
    getPostsRequest,
    getPostsError,
    deletePostRequest,
    deletePostSuccess,
    deletePostError,
    getDetailsPostRequest,
    getDetailsPostSuccess,
    getDetailsPostError,
    addCommentToPostError,
    addCommentToPostRequest,
    addCommentToPostSuccess,
    addReportRequest,
    addReportSuccess,
    addReportError,
    createPostRequest,
    createPostSuccess,
    createPostError,
    updatePostRequest,
    updatePostSuccess,
    updatePostError,
    saveTogglePostRequest,
    saveTogglePostSuccess,
    saveTogglePostError,
    getAdvertisingPostsRequest,
    getAdvertisingPostsSuccess,
    getAdvertisingPostsError,
    getAdvertisingDetailsPostSuccess,
    getAdvertisingDetailsPostError,
    getAdvertisingDetailsPostRequest,
} from './actions';
import { IResponseWithCustomValue, IResponseWithoutValue } from '../../../common/types';
import { AdvertisingPostTypeResponse, CommentType, PostDetailResponse, PostTypeResponse } from '../types';

function* workerCreatePost(action: ReturnType<typeof createPostRequest>) {
    const urlTags = action.payload.Tags.map(tag => `&Tags=${tag}`).join('');
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/posts/create-post?Title=${action.payload.Title}&Body=${action.payload.Body}${urlTags}`,
            action.payload.Photo,
        );
        if (result.success) {
            yield put(createPostSuccess());
        } else {
            yield put(createPostError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerCreatePost');
    }
}
function* workerGetPosts(action: ReturnType<typeof getPostsRequest>) {
    const urlTags = action.payload.Tags.map(tag => `&Tags=${tag}`).join('');
    try {
        const result: IResponseWithCustomValue<{
            posts: PostTypeResponse[];
        }> = yield call(
            API.get,
            `/api/posts/get-posts?Category=${action.payload.Category}&OrderType=${action.payload.OrderType}${urlTags}&Date=${action.payload.Date}`,
            {},
        );
        if (result.success) {
            yield put(getPostsSuccess(result.value));
        } else {
            yield put(getPostsError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetPosts');
    }
}
function* workerGetAdvertisingPosts(action: ReturnType<typeof getAdvertisingPostsRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            posts: AdvertisingPostTypeResponse[];
        }> = yield call(API.get, 'api/agency/advertising-posts', {});
        if (result.success) {
            yield put(getAdvertisingPostsSuccess(result.value));
        } else {
            yield put(getAdvertisingPostsError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetPosts');
    }
}

function* workerSavePost(action: ReturnType<typeof saveTogglePostRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/posts/save-post?PostId=${action.payload.PostId}`,
            {},
        );
        if (result.success) {
            yield put(
                saveTogglePostSuccess({
                    ...action.payload,
                }),
            );
        } else {
            yield put(saveTogglePostError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerSavePost');
    }
}

function* workerDeletePost(action: ReturnType<typeof deletePostRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.delete,
            `api/posts/delete-post?Id=${action.payload}`,
        );
        if (result.success) {
            yield put(deletePostSuccess());
        } else {
            yield put(deletePostError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerDeletePost');
    }
}
function* workerGetDetailsPost(action: ReturnType<typeof getDetailsPostRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            post: PostDetailResponse;
        }> = yield call(API.get, `/api/posts/get-post-by-id?Id=${action.payload}`, {});
        if (result.success) {
            yield put(getDetailsPostSuccess(result.value));
        } else {
            yield put(getDetailsPostError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetDetailsPostError');
    }
}

function* workerGetDetailsAdvertisingPost(action: ReturnType<typeof getAdvertisingDetailsPostRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            post: AdvertisingPostTypeResponse;
        }> = yield call(API.get, `api/agency/advertising-posts/${action.payload}`, {});
        if (result.success) {
            yield put(getAdvertisingDetailsPostSuccess(result.value));
        } else {
            yield put(getAdvertisingDetailsPostError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetDetailsPostError');
    }
}

function* workerEditPost(action: ReturnType<typeof updatePostRequest>) {
    const urlTags = action.payload.Tags.map(tag => `&Tags=${tag}`).join('');
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/posts/update-post?Title=${action.payload.Title}&Body=${action.payload.Body}&Id=${action.payload.Id}&IsPhotoUpdated=${action.payload.isPhotoUpdated}${urlTags}`,
            action.payload.Photo,
        );
        if (result.success) {
            yield put(updatePostSuccess());
        } else {
            yield put(updatePostError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerEditPostError');
    }
}
function* workerAddReportPost(action: ReturnType<typeof addReportRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `api/posts/add-report?PostId=${action.payload.PostId}&Text=${action.payload.Text}&TypeId=${action.payload.TypeId}`,
            {},
        );
        if (result.success) {
            yield put(addReportSuccess());
        } else {
            yield put(addReportError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerAddReportPost');
    }
}
function* workerAddCommentToPost(action: ReturnType<typeof addCommentToPostRequest>) {
    try {
        const result: IResponseWithCustomValue<CommentType> = yield call(
            API.post,
            `/api/posts/add-comment?Text=${action.payload.Text}&PostId=${action.payload.PostId}&Styles=${action.payload.Styles}`,
            {},
        );
        if (result.success) {
            yield put(addCommentToPostSuccess(result.value));
        } else {
            yield put(addCommentToPostError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerAddCommentToPost');
    }
}

export default function* watchPostsSaga() {
    yield takeEvery(getPostsRequest.type, workerGetPosts);
    yield takeEvery(getAdvertisingPostsRequest.type, workerGetAdvertisingPosts);
    yield takeEvery(saveTogglePostRequest.type, workerSavePost);
    yield takeEvery(createPostRequest.type, workerCreatePost);
    yield takeEvery(deletePostRequest.type, workerDeletePost);
    yield takeEvery(getDetailsPostRequest.type, workerGetDetailsPost);
    yield takeEvery(getAdvertisingDetailsPostRequest.type, workerGetDetailsAdvertisingPost);
    yield takeEvery(updatePostRequest.type, workerEditPost);
    yield takeEvery(addReportRequest.type, workerAddReportPost);
    yield takeEvery(addCommentToPostRequest.type, workerAddCommentToPost);
}
