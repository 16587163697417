import React, {Dispatch, SetStateAction} from 'react'
import {Box, Tab, TabList, Text} from "@chakra-ui/react";
import {colors} from "../../../../../common/constants";

interface IPaymentsTabsSwitcherProps{
    setTabIndex: Dispatch<SetStateAction<number>>
    tabIndex: number
    labels: Array<string>
}

export const PaymentsTabsSwitcher:React.FC<IPaymentsTabsSwitcherProps> = ({
    setTabIndex,
    tabIndex,
    labels
 }) => {
    return (
        <TabList
            w="100%"
            h="55px"
            borderRadius="20px"
            bg={'#F5F6F8'}
        >
            {
                labels.map((label, index) => (
                    <Tab
                        p={'0'}
                        w={'100%'}
                        onClick={() => setTabIndex(index)}
                        key={label}
                        position={'relative'}
                    >
                        <Text
                            fontSize={'14px'}
                            fontWeight={400}
                            lineHeight={'21px'}
                            letterSpacing={.2}
                            color={
                                tabIndex === index ? '#2C335C' : '#888DAB'
                            }
                        >
                            {label}
                        </Text>
                        {tabIndex === index &&
                        (
                            <Box
                                bg="indicatorGreen"
                                position="absolute"
                                w="90%"
                                h="3px"
                                boxShadow={`0px -1px 3px ${colors.indicatorGreen}`}
                                bottom={0}
                                left="5%"
                            />
                        )
                        }

                    </Tab>
                ))

            }

        </TabList>
    )
}
