import React from 'react';
import { Text, Image, Tooltip, Flex, IconButton } from '@chakra-ui/react';
import { OpenHousesResponse } from '../../../../../pages/Calendar/types';
import Position from '../../../../../assets/icons/Position.svg';
import house from '../../../../../assets/icons/house.svg';
import priceImg from '../../../../../assets/icons/price.svg';
import { ReactComponent as PlayIcon } from '../../../../../assets/icons/PlayIcon.svg';
import location from '../../../../../assets/icons/location.svg';
import clock from '../../../../../assets/icons/clock.svg';
import dayjs from 'dayjs';

interface IListItemProps extends OpenHousesResponse {
    onPressDetails?: () => void;
}

export const ListItem: React.FC<IListItemProps> = ({
    id,
    requestTypeId,
    requestTypeName,
    requestCategoryId,
    ownerId,
    dateTime,
    fromTime,
    toTime,
    createdAt,
    duration,
    propertiesToShow,
    states,
    city,
    addresses,
    postalCode,
    houseType,
    photo,
    price,
    relatedUserAvatar,
    timeSlot,
    canBeAcceptedTill,
    onPressDetails,
}) => {
    return (
        <Flex
            h="65px"
            bgColor="white"
            borderRadius="15px"
            mt="15px"
            flexDirection="row"
            alignItems="center"
        >
            <Flex flexDirection="row" alignItems="center" w="40%">
                <Image ml="8px" borderRadius="10px" boxSize="60px" src={photo} />
                <Flex ml="16px" flexDirection="column" justifyContent="center">
                    <Text
                        color="brand"
                        fontWeight={600}
                        fontSize="14px"
                        lineHeight="18px"
                        isTruncated
                    >
                        {addresses[0]}
                    </Text>
                    <Flex flexDirection="row" alignItems="center">
                        <Image borderRadius="15px" boxSize="13px" src={Position} />
                        <Text
                            ml="4px"
                            color="brand"
                            fontWeight={500}
                            fontSize="12px"
                            lineHeight="16px"
                        >
                            {states[0]}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                h="100%"
                borderLeftColor="#D1D2DB"
                borderLeftWidth="1px"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                w="100px"
            >
                <Image ml="8px" borderRadius="15px" boxSize="23px" src={priceImg} />
                <Text color="brand" fontWeight={400} fontSize="16px" lineHeight="24px">
                    {`$ ${price.toLocaleString()}`}
                </Text>
            </Flex>
            <Flex
                h="100%"
                borderLeftColor="#D1D2DB"
                borderLeftWidth="1px"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                w="100px"
            >
                <Image ml="8px" borderRadius="15px" boxSize="23px" src={house} />
                <Text color="brand" fontWeight={400} fontSize="15px" lineHeight="24px" isTruncated>
                    <Tooltip label={houseType} aria-label="A tooltip">
                        {houseType}
                    </Tooltip>
                </Text>
            </Flex>
            <Flex
                h="100%"
                borderLeftColor="#D1D2DB"
                borderLeftWidth="1px"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                w="100px"
            >
                <Image ml="8px" borderRadius="15px" boxSize="23px" src={location} />
                <Text color="brand" fontWeight={400} fontSize="15px" lineHeight="24px">
                    {city}
                </Text>
            </Flex>
            <Flex
                h="100%"
                borderLeftColor="#D1D2DB"
                borderLeftWidth="1px"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
                pr="5px"
            >
                <Flex flexDirection="column" alignItems="center" justifyContent="center" ml="25px">
                    <Image ml="8px" borderRadius="15px" boxSize="23px" src={clock} />
                    <Text color="brand" fontWeight={400} fontSize="15px" lineHeight="24px">
                        To: {dayjs(toTime).format('hh:mm A')}
                    </Text>
                </Flex>
                <IconButton
                    aria-label="Calendar open house details"
                    variant="unstyled"
                    d="flex"
                    align="center"
                    onClick={() => onPressDetails?.()}
                >
                    <PlayIcon />
                </IconButton>
            </Flex>
        </Flex>
    );
};
