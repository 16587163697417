import React, { useState } from 'react';
import { Box, Flex, VStack, HStack, Text } from '@chakra-ui/react';
import { CustomButton, CustomInput, CustomTextarea, CustomDropdown } from '../../common/components';
import { UploadItemIcon } from '../../assets/icons';
import { colors } from '../../common/constants';
import { fonts, Alerter } from '../../common/utils';
import { StateDirectoryItem } from '../../common/types';
import { useForm } from 'react-hook-form';
import { CooperationSelect, UploadedFileItem } from './components';
import { FileUpload } from '../../common/components/landing';
import { cooperationDetailsResolver } from '../../common/schemes';

interface IAgencyRelationshipFormProps {
    states: StateDirectoryItem[];
    onSendCooperationDetail: (data: FormData) => void;
    sendingDetailsLoading: boolean;
}

interface AgencyRelationshipForm {
    AgencyName: string;
    YourName: string;
    ContactEmail: string;
    ContactPhone: string;
    TypeOfCooperation: string[];
    StateId: string;
    MessageTitle: string;
    Message: string;
    PartnerAgencyName: string;
    PartnerName: string;
    PartnerContactPhone: string;
    PartnerContactEmail: string;
}

const acceptFiles =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, .jpeg, image/png';
const acceptedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'png'];

export const AgencyRelationshipForm: React.FC<IAgencyRelationshipFormProps> = ({
    states,
    onSendCooperationDetail,
    sendingDetailsLoading,
}) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        clearErrors,
    } = useForm<AgencyRelationshipForm>({
        resolver: cooperationDetailsResolver,
        defaultValues: { TypeOfCooperation: [] },
    });

    const [typesOfCooperationSelected, setTypesOfCooperation] = useState<number[]>([]);
    const [agreementFile, setAgreementFile] = useState<File | null>(null);
    const [supportingDocumentFile, setSupportingDocumentFile] = useState<File | null>(null);

    const validateFile = (file: File | null, setFile: (data: File) => void) => {
        if (file && acceptedExtensions.includes(file?.name?.split('.').pop() || '')) {
            setFile(file);
        } else {
            Alerter.error(
                'File format is invalid. Please choose file with following extensions: pdf, doc, docx, xls, xlsx, jpeg, png',
                5000,
            );
        }
    };

    const onSubmit = (data: AgencyRelationshipForm) => {
        const formData = new FormData();

        if (agreementFile) {
            formData.append('Agreement', agreementFile as Blob);
        }
        if (supportingDocumentFile) {
            formData.append('SupportingDocuments', supportingDocumentFile as Blob);
        }
        formData.append('AgencyName', data.AgencyName);
        formData.append('YourName', data.YourName);
        formData.append('ContactEmail', data.ContactEmail);
        formData.append('ContactPhone', `+1${data.ContactPhone.replace('-', '')}`);
        for (let i = 0; i < data.TypeOfCooperation.length; i++) {
            formData.append('TypesOfCooperation[]', data.TypeOfCooperation[i]);
        }
        formData.append('StateId', data.StateId);
        formData.append('MessageTitle', data.MessageTitle);
        formData.append('Message', data.Message);
        formData.append('PartnerAgencyName', data.PartnerAgencyName);
        formData.append('PartnerName', data.PartnerName);
        formData.append('PartnerContactEmail', data.PartnerContactEmail);
        formData.append('PartnerContactPhone', `+1${data.PartnerContactPhone.replace('-', '')}`);

        onSendCooperationDetail(formData);
    };

    return (
        <Box
            border={{ base: 'none', lg: '1px solid #D1D2DB' }}
            px={{ base: '0', md: '20%' }}
            py={{ base: '30px', md: '80px' }}
        >
            <Text
                color={colors.brand}
                fontFamily={fonts.montserrat}
                fontSize="20px"
                lineHeight="24px"
                textTransform="uppercase"
                fontWeight="600"
                mb="40px"
            >
                Brokerage Cooperation Details
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex justifyContent="space-between" direction={{ base: 'column', lg: 'row' }}>
                    {/*Left Side*/}
                    <VStack
                        align="stretch"
                        spacing="14px"
                        w={{ base: '100%', lg: '48%' }}
                        mb={{ base: '14px', lg: 0 }}
                    >
                        <CustomInput
                            placeholder="Enter"
                            label="Agency Name *"
                            fontSize="14px"
                            textSize="16px"
                            fontFamily={fonts.montserrat}
                            isBorder={false}
                            register={{ ...register('AgencyName') }}
                            errors={errors.AgencyName}
                            height="50px"
                        />
                        <CustomInput
                            placeholder="Enter"
                            label="Your Name *"
                            fontSize="14px"
                            textSize="16px"
                            fontFamily={fonts.montserrat}
                            isBorder={false}
                            register={{ ...register('YourName') }}
                            errors={errors.YourName}
                            height="50px"
                        />
                        <CustomInput
                            placeholder="Enter"
                            label="Contact Email *"
                            fontSize="14px"
                            textSize="16px"
                            fontFamily={fonts.montserrat}
                            isBorder={false}
                            type="email"
                            register={{ ...register('ContactEmail') }}
                            errors={errors.ContactEmail}
                            height="50px"
                        />
                        <CustomInput
                            placeholder="Enter"
                            label="Contact Phone *"
                            fontSize="14px"
                            textSize="16px"
                            fontFamily={fonts.montserrat}
                            isBorder={false}
                            type="tel"
                            mask="999-999-9999"
                            register={{ ...register('ContactPhone') }}
                            errors={errors.ContactPhone}
                            height="50px"
                        />
                    </VStack>
                    {/*Right Side */}
                    <VStack w={{ base: '100%', lg: '48%' }} align="stretch" spacing="14px">
                        <CustomInput
                            placeholder="Enter"
                            label="Partner Agency Name *"
                            fontSize="14px"
                            textSize="16px"
                            fontFamily={fonts.montserrat}
                            isBorder={false}
                            register={{ ...register('PartnerAgencyName') }}
                            errors={errors.PartnerAgencyName}
                            height="50px"
                        />
                        <CustomInput
                            placeholder="Enter"
                            label="Partner Name *"
                            fontSize="14px"
                            textSize="16px"
                            fontFamily={fonts.montserrat}
                            isBorder={false}
                            register={{ ...register('PartnerName') }}
                            errors={errors.PartnerName}
                            height="50px"
                        />
                        <CustomInput
                            placeholder="Enter"
                            label="Partner Contact Email* "
                            fontSize="14px"
                            textSize="16px"
                            fontFamily={fonts.montserrat}
                            isBorder={false}
                            type="email"
                            register={{ ...register('PartnerContactEmail') }}
                            errors={errors.PartnerContactEmail}
                            height="50px"
                        />
                        <CustomInput
                            placeholder="Enter"
                            label="Partner Contact Phone *"
                            fontSize="14px"
                            textSize="16px"
                            fontFamily={fonts.montserrat}
                            isBorder={false}
                            type="tel"
                            mask="999-999-9999"
                            register={{ ...register('PartnerContactPhone') }}
                            errors={errors.PartnerContactPhone}
                            height="50px"
                        />
                    </VStack>
                </Flex>
                <VStack align="stretch" spacing="14px" mt="14px">
                    <CooperationSelect
                        menuValues={[
                            { label: 'Showing Assistance', value: 0 },
                            { label: 'Host Open House', value: 1 },
                        ]}
                        typeIdsSelected={typesOfCooperationSelected}
                        setTypeIdsSelected={value => {
                            setTypesOfCooperation(value);
                            setValue(
                                'TypeOfCooperation',
                                value.map(elem => elem.toString()),
                            );
                            clearErrors('TypeOfCooperation');
                        }}
                        label="Type of Cooperation *"
                        fontFamily={fonts.montserrat}
                        errors={(errors.TypeOfCooperation as any)?.message}
                    />
                    <CustomDropdown
                        options={states.map(elem => ({
                            label: elem.name,
                            value: elem.id + '',
                            id: elem.id,
                        }))}
                        label="State *"
                        register={{ border: 'none', fontSize: '16px', ...register('StateId') }}
                        fontFamily={fonts.montserrat}
                        errors={errors.StateId}
                        placeholder="Enter"
                        labelSize="14px"
                        height="50px"
                    />
                    <CustomInput
                        placeholder="Write Post Title"
                        label="Message Title"
                        fontFamily={fonts.montserrat}
                        isBorder={false}
                        register={{ ...register('MessageTitle') }}
                        fontSize="14px"
                        textSize="16px"
                        height="50px"
                    />
                    <CustomTextarea
                        placeholder="Write Text"
                        minH="130px"
                        label="Enter Message"
                        border="none"
                        register={{ ...register('Message') }}
                        fontSize="14px"
                        textSize="16px"
                    />
                </VStack>

                {/* Upload Buttons Box*/}
                <Flex
                    justifyContent="space-between"
                    mt="45px"
                    mb="55px"
                    direction={{ base: 'column', lg: 'row' }}
                >
                    <VStack align="flex-start">
                        <FileUpload
                            onChange={file => {
                                validateFile(file, setAgreementFile);
                            }}
                            accept={acceptFiles}
                        >
                            <HStack mb={{ base: '20px', lg: 0 }}>
                                <Flex
                                    bg="brand"
                                    w="50px"
                                    h="50px"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexShrink={0}
                                >
                                    <UploadItemIcon />
                                </Flex>
                                <Text
                                    fontSize="16px"
                                    textDecoration="underline"
                                    color="brand"
                                    fontFamily={fonts.montserrat}
                                >
                                    Upload Agency Cooperation Agreement
                                </Text>
                            </HStack>
                        </FileUpload>
                        {agreementFile ? (
                            <UploadedFileItem
                                fileName={agreementFile.name}
                                onRemove={() => setAgreementFile(null)}
                            />
                        ) : null}
                    </VStack>
                    <VStack align="flex-start">
                        <FileUpload
                            onChange={file => {
                                validateFile(file, setSupportingDocumentFile);
                            }}
                            accept={acceptFiles}
                        >
                            <HStack>
                                <Flex
                                    bg="brand"
                                    w="50px"
                                    h="50px"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexShrink={0}
                                >
                                    <UploadItemIcon />
                                </Flex>
                                <Text
                                    fontSize="16px"
                                    textDecoration="underline"
                                    color="brand"
                                    fontFamily={fonts.montserrat}
                                >
                                    Upload Supporting Documents
                                </Text>
                            </HStack>
                        </FileUpload>
                        {supportingDocumentFile ? (
                            <UploadedFileItem
                                fileName={supportingDocumentFile.name}
                                onRemove={() => setSupportingDocumentFile(null)}
                            />
                        ) : null}
                    </VStack>
                </Flex>
                <Box textAlign={'right'}>
                    <CustomButton
                        width={{ base: '100%', lg: '50%' }}
                        text="Send"
                        type="submit"
                        height="60px"
                        bgColor="brand"
                        isLoading={sendingDetailsLoading}
                    />
                </Box>
            </form>
        </Box>
    );
};
