import { put, takeEvery, call } from 'redux-saga/effects';
import API from '../../../../../common/api/api.base';
import {
    getCurrentSubscriptionRequest,
    getCurrentSubscriptionSuccess,
    getCurrentSubscriptionError,
    getSubscriptionsHistoryRequest,
    getSubscriptionsHistorySuccess,
    getSubscriptionsHistoryError,
} from './actions';
import { IResponseWithCustomValue } from './../../../../../common/types/api.types';
import { CurrentSubscription, SubscriptionHistoryType } from '../types';

function* workerGetCurrentSubscription() {
    try {
        const result: IResponseWithCustomValue<CurrentSubscription> = yield call(
            API.get,
            `/api/subscriptions/current`,
            {},
        );
        if (result.success) {
            yield put(getCurrentSubscriptionSuccess(result.value));
        } else {
            yield put(getCurrentSubscriptionError(result.errors));
        }
    } catch (error) {
        console.log(error, 'workerGetCurrentSubscription error');
    }
}

function* workerGetSubscriptionsHistory() {
    try {
        const result: IResponseWithCustomValue<{
            subscriptionsHistory: SubscriptionHistoryType[];
        }> = yield call(API.get, '/api/subscriptions/history', {});
        if (result.success) {
            yield put(getSubscriptionsHistorySuccess(result.value.subscriptionsHistory));
        } else {
            yield put(getSubscriptionsHistoryError(result.errors));
        }
    } catch (error) {
        console.log(error, 'workerGetSubscriptionsHistory error');
    }
}

export default function* watchSubscriptionsSaga() {
    yield takeEvery(getCurrentSubscriptionRequest.type, workerGetCurrentSubscription);
    yield takeEvery(getSubscriptionsHistoryRequest.type, workerGetSubscriptionsHistory);
}
