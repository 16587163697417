import React from 'react';
import { Flex, Text } from '@chakra-ui/layout';

interface IMoneyContainer {
    amount: number;
    type: string;
}

export const MoneyContainer: React.FC<IMoneyContainer> = ({ amount, type }) => (
    <Flex
        direction="column"
        align="center"
        bgColor="#F3F4F7"
        borderRadius="8px"
        width="48.7%"
        py="15px"
    >
        <Text color="brand" fontSize="32px" fontWeight="600">
            ${amount}
        </Text>
        <Text color={type === 'Incoming' ? 'green' : '#FE805C'} fontSize="16px" fontWeight="700">
            {type}
        </Text>
    </Flex>
);
