import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import {
    getOpenHouseTypes,
    getOpenHouseTypesSuccess,
    getOpenHouseTypesError,
} from '../../actions/directories.actions';
import { IResponseWithCustomValue, IdNameTypes } from '../../../common/types';

function* workerGetOpenHouseTypes() {
    try {
        const result: IResponseWithCustomValue<{
            types: Array<IdNameTypes>;
        }> = yield call(Api.get, '/api/directories/open-house-types', {});

        if (result.success) {
            yield put(getOpenHouseTypesSuccess(result.value));
        } else {
            yield put(getOpenHouseTypesError(result.errors));
            console.log('ERROR', result);
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* watchGetOpenHouseTypesSaga() {
    yield takeEvery(getOpenHouseTypes.type, workerGetOpenHouseTypes);
}
