import React, { useState } from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    VStack,
    Checkbox,
    Text,
    IconButton,
    Box,
    Button,
} from '@chakra-ui/react';
import { FilterIcon } from '../../../assets/icons';
import { CustomButton } from '../../../common/components';

interface ITypesFilterProps {
    items: {
        categoryTitle: string;
        categories: { label: string; value: string | number | boolean }[];
    }[];
    onApply: (value: { [key: string]: string[] | number[] | boolean[] }) => void;
    onReset?: () => void;
    label: string;
}

export const MultipleFilter: React.FC<ITypesFilterProps> = ({ items, onApply, onReset, label }) => {
    const [selectedItems, setSelectedItems] = useState<any>(
        Object.fromEntries(items.map(elem => [elem.categoryTitle, []])),
    );

    const onSelectItem = (value: string | number | boolean, categoryTitle: string) => {
        if (selectedItems[categoryTitle].includes(value)) {
            setSelectedItems({
                ...selectedItems,
                [categoryTitle]: selectedItems[categoryTitle].filter(
                    (elem: string | number | boolean) => elem !== value,
                ),
            });
        } else {
            setSelectedItems({
                ...selectedItems,
                [categoryTitle]: [...selectedItems[categoryTitle], value],
            });
        }
    };

    return (
        <Popover>
            <PopoverTrigger>
                <Box>
                    <Text
                        textTransform={'capitalize'}
                        color="brand"
                        fontWeight={300}
                        fontSize="13px"
                    >
                        {label}
                    </Text>
                    <IconButton aria-label="filter" icon={<FilterIcon />} h="50px" w="50px" />
                </Box>
            </PopoverTrigger>
            <PopoverContent>
                <Box p="20px" maxH="50vh" overflowY="auto">
                    <Button
                        variant="unstyled"
                        fontSize="14px"
                        fontWeight="400"
                        textDecorationLine="underline"
                        position="absolute"
                        right="20px"
                        top="15px"
                        color="brand"
                        _focus={{
                            boxShadow: 'none',
                        }}
                        onClick={() => {
                            onReset?.();
                            setSelectedItems(
                                Object.fromEntries(items.map(elem => [elem.categoryTitle, []])),
                            );
                        }}
                    >
                        Reset
                    </Button>
                    <Box>
                        {items.map(elem => (
                            <Box key={elem.categoryTitle} mb="15px">
                                <Text color="brand" fontSize="16px" fontWeight={600} mb="5px">
                                    {elem.categoryTitle}
                                </Text>
                                <VStack align="flex-start">
                                    {elem.categories.map(cat => (
                                        cat.label && <Checkbox
                                            key={cat.label}
                                            // defaultIsChecked={elem.id === 0}
                                            isChecked={selectedItems[elem.categoryTitle].includes(
                                                cat.value,
                                            )}
                                            onChange={ev =>
                                                onSelectItem(cat.value, elem.categoryTitle)
                                            }
                                            colorScheme="checkboxScheme"
                                        >
                                            <Text color="brand" fontSize="14px">
                                                {cat.label}
                                            </Text>
                                        </Checkbox>

                                    ))}
                                </VStack>
                            </Box>
                        ))}
                    </Box>
                    <CustomButton
                        text="Apply"
                        height="50px"
                        bgColor="brand"
                        onClick={() => onApply(selectedItems)}
                    />
                </Box>
            </PopoverContent>
        </Popover>
    );
};
