import React from 'react';
import { Box, Text, Avatar, HStack, Flex, IconButton, chakra } from '@chakra-ui/react';
import {
    Calendar,
    Location,
    Clock,
    Home,
    DollarSignIcon,
    ConsultMethodIcon,
    DropdownArrowIcon,
} from '../../../assets/icons';
import { FindJobPostsResponseType } from '../types';
import dayjs from 'dayjs';
import { colors } from '../../../common/constants';
import { IconDescription } from '../../../common/components/blocks';
import pluralize from 'pluralize';
import { getAvatarPicture } from '../../../common/utils';
import { fonts } from '../../../common/utils';

interface IJobItemProps extends FindJobPostsResponseType {
    onOpenModal: () => void;
}

export const JobItem: React.FC<IJobItemProps> = ({
    requestTypeName,
    states,
    ownerFullName,
    agencyName,
    addresses,
    dateTime,
    propertiesToShow,
    duration,
    ownerAvatar,
    onOpenModal,
    typeId,
    state,
    finalPrice,
}) => (
    <Flex h="100px" bg="inputBackground" borderRadius="14px" px="25px" mb="10px">
        <HStack align="flex-start" alignSelf="center" width="40%">
            <Avatar name={ownerFullName} src={getAvatarPicture(ownerAvatar)} w="30px" h="30px" />
            <Box>
                <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={600}>
                    {requestTypeName}
                </Text>
                <HStack>
                    <Location />
                    <Text
                        color="secondaryFontDarker"
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight={500}
                    >
                        {typeId === 1 || typeId === 7
                            ? states.join(',')
                            : `${states.join('')}, ${addresses?.join('')}`}
                    </Text>
                </HStack>
                <HStack>
                    <Text color="brand" fontSize="12px" lineHeight="16px" fontWeight={500}>
                        {ownerFullName}
                    </Text>
                    <Text
                        color="secondaryFontDarker"
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight={500}
                    >
                        {agencyName}
                    </Text>
                </HStack>
            </Box>
        </HStack>
        <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
            {typeId === 7 ? (
                <IconDescription
                    icon={
                        <Text
                            color="brand"
                            fontSize="12px"
                            fontWeight="500"
                            fontFamily={fonts.montserrat}
                        >
                            State:
                        </Text>
                    }
                    text={state || ''}
                />
            ) : (
                <>
                    <IconDescription
                        icon={<Calendar />}
                        text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                    />
                    <IconDescription icon={<Clock />} text={dayjs(dateTime).format('hh:mmA')} />
                    <IconDescription
                        icon={
                            requestTypeName === 'Showing' ? (
                                <Home color={colors.brand} width="16" height="16" />
                            ) : (
                                <ConsultMethodIcon />
                            )
                        }
                        text={
                            requestTypeName === 'Showing'
                                ? `${propertiesToShow} ${pluralize('house', propertiesToShow)}`
                                : `${duration} ${pluralize('hour', duration)}`
                        }
                    />
                    <IconDescription icon={<DollarSignIcon />} text={finalPrice} />
                </>
            )}
        </Flex>
        <IconButton
            onClick={onOpenModal}
            bg="transparent"
            ml="auto"
            alignSelf="center"
            aria-label="More for job"
            icon={
                <chakra.span transform="rotate(-90deg)">
                    <DropdownArrowIcon />
                </chakra.span>
            }
        />
    </Flex>
);
