import React from 'react';
import { ModalHeader, Text, CloseButton } from '@chakra-ui/react';

interface IConfirmHeaderProps {
    onClose: () => void;
    title: string;
}

export const ConfirmHeader: React.FC<IConfirmHeaderProps> = ({ onClose, title }) => (
    <ModalHeader
        d="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        w="100%"
    >
        <Text color="brand" fontSize="18px" fontWeight="600">
            {title}
        </Text>
        <CloseButton onClick={onClose} />
    </ModalHeader>
);
