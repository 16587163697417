import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    CloseButton,
    ModalHeader,
    Text,
    HStack,
} from '@chakra-ui/react';
import { CustomButton } from '../../blocks';

interface IBonusConfirmModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    amount: string;
    type: string;
}

export const BonusConfirmModal: React.FC<IBonusConfirmModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    amount,
    type,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay />
            <ModalContent borderRadius={'18px'} bgColor="white" alignItems="center">
                <ModalHeader d="flex" w="100%" justifyContent="end" px="35px" mt="15px">
                    <CloseButton onClick={onClose} />
                </ModalHeader>
                <ModalBody d="flex" flexDirection="column" px="50px" w="100%">
                    <Text
                        color="brand"
                        fontSize="16px"
                        lineHeight="23px"
                        fontWeight="400"
                        mt="5px !important"
                        w="100%"
                    >
                        Please confirm that you intend to pay the Agent {amount} if a contract is
                        ratified within 48-hours of this {type}? This information will be submitted
                        to your Agency for execution.
                    </Text>

                    <HStack my="45px" gap="25px">
                        <CustomButton
                            text="Cancel"
                            variant="outline"
                            color="brand"
                            height="60px"
                            borderColor="brand"
                            onClick={onClose}
                        />
                        <CustomButton
                            text="Confirm"
                            bgColor="brand"
                            height="60px"
                            onClick={() => {
                                onConfirm();
                                onClose();
                            }}
                        />
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
