import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { ListingsType, OpenHouseDetailsModalOpen } from '../../../common/types';
import { InfiniteScroll, Loader } from '../../../common/components/blocks';
import { ListingSection } from '../components';

interface IMyListingsTabProps {
    loading: boolean;
    activeListings: ListingsType[];
    closedListings: ListingsType[];
    pendingListings: ListingsType[];
    onOpenModal: OpenHouseDetailsModalOpen;
    onScheduleOpenHouse: (value: number) => void;
}

export const MyListingsTab: React.FC<IMyListingsTabProps> = ({
    activeListings,
    closedListings,
    loading,
    pendingListings,
    onOpenModal,
    onScheduleOpenHouse,
}) => {
    return (
        <Box>
            {loading ? (
                <Loader spinnerSize="md" centerHeight="calc(90vh - 180px)" />
            ) : (
                <InfiniteScroll height="calc(90vh - 180px)">
                    <ListingSection
                        name="Active"
                        items={activeListings}
                        onOpenModal={onOpenModal}
                        onScheduleOpenHouse={onScheduleOpenHouse}
                    />
                    <ListingSection
                        name="Pending"
                        items={pendingListings}
                        onOpenModal={onOpenModal}
                        onScheduleOpenHouse={onScheduleOpenHouse}
                    />
                    <ListingSection
                        name="Sold"
                        items={closedListings}
                        onOpenModal={onOpenModal}
                        onScheduleOpenHouse={onScheduleOpenHouse}
                    />
                    {[...activeListings, ...pendingListings, ...closedListings].length === 0 ? (
                        <Center h="100%">
                            <Text color="brand" fontSize="16px">
                                There is no listings at this time
                            </Text>
                        </Center>
                    ) : null}
                </InfiniteScroll>
            )}
        </Box>
    );
};
