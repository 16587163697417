import React from 'react';
import { Flex, Text, Switch } from '@chakra-ui/react';
import { fonts } from '../../../../../common/utils';

interface INotificationsSettingsItem {
    label: string;
    value: number;
    isChecked?: boolean;
    loading?: boolean;
    onChange: (settings: { value: number; label: string }) => void;
}

export const NotificationsSettingsItem: React.FC<INotificationsSettingsItem> = ({
    label,
    value,
    isChecked,
    loading,
    onChange,
}) => {
    return (
        <Flex justifyContent="space-between" align="center" mb="26px">
            <Text
                fontSize="16px"
                fontWeight={300}
                lineHeight="24px"
                color="brand"
                fontFamily={fonts.poppins}
            >
                {label}
            </Text>
            <Switch
                isChecked={isChecked}
                onChange={() => onChange({ value, label  })}
                disabled={loading}
                colorScheme="switchScheme"
                sx={{
                    '.chakra-switch__track': {
                        background: '#888DAB',
                    },
                }}
            />
        </Flex>
    );
};
