import React from 'react';
import { GetFundingSourcesResponse } from '../types';
import {
    Box,
    Button,
    Center,
    Flex,
    Menu,
    MenuButton,
    Text,
    Image,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import { BankIcon, DownArrowIcon } from '../../../../../assets/icons';

interface IPaymentsDropDown {
    items: Array<GetFundingSourcesResponse>;
    setValue: any;
    value: GetFundingSourcesResponse;
    error?: string;
    loading: boolean;
}

export const PaymentDropDown: React.FC<IPaymentsDropDown> = ({
    items,
    setValue,
    value,
    error,
    loading,
}) => {
    return (
        <Menu matchWidth>
            <MenuButton
                isLoading={loading}
                disabled={items.length === 0 || !value}
                bg={'#F5F6F8'}
                w={'100%'}
                as={Button}
                rightIcon={<DownArrowIcon />}
                px={'17px'}
                py={'12px'}
                h={'83px'}
                textAlign={'left'}
                border={error ? '1px solid red' : undefined}
            >
                {value && value.type === 'Bank' ? (
                    <Flex align={'center'}>
                        <Center mr={'26px'} w={'64px'} h={'64px'} bg={'#E2E2E8'}>
                            <BankIcon />
                        </Center>
                        <Box>
                            <Text fontSize={'16px'} lineHeight={'24px'} color="brand">
                                {value.nickname}
                            </Text>
                            <Text fontSize={'16px'} lineHeight={'24px'} color="brand">
                                {value.bankName}
                            </Text>
                        </Box>
                    </Flex>
                ) : value && value.type === 'Balance' ? (
                    <Flex align={'center'}>
                        <Center mr={'26px'} w={'64px'} h={'64px'} bg={'#E2E2E8'}>
                            <BankIcon />
                        </Center>
                        <Text fontSize={'16px'} lineHeight={'24px'} color="brand">
                            Wallet
                        </Text>
                    </Flex>
                ) : (
                    <Text fontSize={'16px'} lineHeight={'24px'} color="brand">
                        No Methods Yet
                    </Text>
                )}
            </MenuButton>
            {error && <Text color={'red'}>{error}</Text>}
            <MenuList>
                {items.length > 0 &&
                    value &&
                    items.map(item => {
                        const selected = item.dwollaId === value.dwollaId;
                        return (
                            <MenuItem onClick={() => setValue(item)} key={item.dwollaId}>
                                <Flex alignItems={'center'}>
                                    <Flex
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        h={'24px'}
                                        w={'24px'}
                                        border={'1px solid #2C335C'}
                                        borderRadius={'50%'}
                                        mr={'20px'}
                                    >
                                        {selected && (
                                            <Box
                                                h={'12px'}
                                                w={'12px'}
                                                borderRadius={'50%'}
                                                bg="brand"
                                            />
                                        )}
                                    </Flex>

                                    <Text>
                                        {item.type === 'Balance' ? 'Wallet' : item.nickname}
                                    </Text>
                                </Flex>
                            </MenuItem>
                        );
                    })}
            </MenuList>
        </Menu>
    );
};
