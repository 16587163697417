import { createAction } from '@reduxjs/toolkit';
import {
    SignUpFillDataPayload,
    SignUpUploadLicensePayload,
    SignUpPayload,
    SignUpConfirmCodePayload,
    SignUpSendPhoneCodePayload,
    SignUpConfirmPhoneCodePayload,
    SignUpGetAgencies,
    SignUpConfirmEmailSuccess,
    SignUpGetAgenciesSuccess,
} from '../types';
import {
    PayloadError,
    SignUpSuccess,
    AddNewAgencyPayload,
    IdNameTypes,
    IResponseWithCustomValue,
} from '../../../../common/types';

export const signUpSendEmailCode = createAction<SignUpPayload>('SIGN_UP/SEND_EMAIL_CODE');
export const signUpSendEmailCodeSuccess = createAction<SignUpSuccess>(
    'SIGN_UP/SEND_EMAIL_CODE_SUCCESS',
);
export const signUpSendEmailCodeError = createAction<PayloadError[]>(
    'SIGN_UP/SEND_EMAIL_CODE_ERROR',
);

export const signUpConfirmEmailCode = createAction<SignUpConfirmCodePayload>(
    'SIGN_UP/CONFIRM_EMAIL_CODE',
);
export const signUpConfirmEmailCodeSuccess = createAction<
    IResponseWithCustomValue<SignUpConfirmEmailSuccess>
>('SIGN_UP/CONFIRM_EMAIL_CODE_SUCCESS');
export const signUpConfirmEmailCodeError = createAction<PayloadError[]>(
    'SIGN_UP/CONFIRM_EMAIL_CODE_ERROR',
);

export const signUpSendPhoneCode =
    createAction<SignUpSendPhoneCodePayload>('SIGN_UP/SEND_PHONE_CODE');
export const signUpSendPhoneCodeSuccess = createAction<SignUpSuccess>(
    'SIGN_UP/SEND_PHONE_CODE_SUCCESS',
);
export const signUpSendPhoneCodeError = createAction<PayloadError[]>(
    'SIGN_UP/SEND_PHONE_CODEE_ERROR',
);

export const signUpConfirmPhoneCode = createAction<SignUpConfirmPhoneCodePayload>(
    'SIGN_UP/CONFIRM_PHONE_CODE',
);
export const signUpConfirmPhoneCodeSuccess = createAction<SignUpSuccess>(
    'SIGN_UP/CONFIRM_PHONE_CODE_SUCCESS',
);
export const signUpConfirmPhoneCodeError = createAction<PayloadError[]>(
    'SIGN_UP/CONFIRM_PHONE_CODE_ERROR',
);

export const signUpGetAgencies = createAction<SignUpGetAgencies>('SIGN_UP/GET_AGENCIES');
export const signUpGetAgenciesSuccess = createAction<
    IResponseWithCustomValue<SignUpGetAgenciesSuccess>
>('SIGN_UP/GET_AGENCIES_SUCCESS');
export const signUpGetAgenciesError = createAction<PayloadError[]>('SIGN_UP/GET_AGENCIES_ERROR');

export const signUpFillDataRequest = createAction<SignUpFillDataPayload>('SIGN_UP/FILL_DATA');
export const signUpFillDataSuccess = createAction<SignUpSuccess>('SIGN_UP/FILL_DATA_SUCCESS');
export const signUpFillDataError = createAction<PayloadError[]>('SIGN_UP/FILL_DATA_ERROR');

export const addNewAgencyRequest = createAction<AddNewAgencyPayload>('ADD_NEW_AGENCY_REQUEST');
export const addNewAgencySuccess = createAction<IdNameTypes>('ADD_NEW_AGENCY_SUCCESS');
export const addNewAgencyError = createAction<PayloadError[]>('ADD_NEW_AGENCY_ERROR');

export const getLicensesDraft = createAction<string | undefined>('GET_LICENSES_DRAFT');
export const getLicensesDraftSuccess = createAction<IResponseWithCustomValue<any>>(
    'GET_LICENSES_DRAFT_SUCCESS',
);
export const getLicensesDraftError = createAction<PayloadError[]>('GET_LICENSES_DRAFT_ERROR');

export const removeDraftAgency = createAction('REMOVE_DRAFT_AGENCY');
export const handleAddNewAgencyModal = createAction<boolean>('HANDLE_ADD_NEW_AGENCY_MODAL');

export const signUpSetStep = createAction<number>('SIGN_UP/SET_STEP');

export const signUpAddDriverLicencePhoto = createAction<SignUpUploadLicensePayload>(
    'SIGN_UP/ADD_DRIVER_LICENCE_PHOTO',
);

export const signUpAddDriverLicencePhotoSuccess = createAction<SignUpSuccess>(
    'SIGN_UP/ADD_DRIVER_LICENCE_PHOTO_SUCCESS',
);
export const signUpAddDriverLicencePhotoError = createAction<PayloadError[]>(
    'SIGN_UP/ADD_DRIVER_LICENCE_PHOTO_ERROR',
);

export const signUpAddRealEsateLicencePhoto = createAction<SignUpUploadLicensePayload>(
    'SIGN_UP/ADD_REAL_ETSATE_LICENCE_PHOTO',
);
export const signUpAddRealEsateLicencePhotoSuccess = createAction<SignUpSuccess>(
    'SIGN_UP/ADD_REAL_ETSATE_LICENCE_PHOTO_SUCCESS',
);
export const signUpAddRealEsateLicencePhotoError = createAction<PayloadError[]>(
    'SIGN_UP/ADD_REAL_ETSATE_LICENCE_PHOTO_ERROR',
);
