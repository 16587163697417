import React from 'react';
import {
    Avatar,
    Box, Button,
    CloseButton,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text, VStack
} from "@chakra-ui/react";
import {FeedbackInfo} from "../types";
import { getAvatarPicture} from "../../../common/utils";
import {Typography} from "../../../common/components/blocks/Typography/Typography";
import dayjs from "dayjs";
import {Rating} from "../../../common/constants";
import {RateStar} from "../../Activity/components";

interface IFeedbackInfoModal {
    isOpen: boolean
    onClose: () => void
    feedbackInfo: FeedbackInfo
    requestType: string
}

export const FeedbackInfoModal:React.FC<IFeedbackInfoModal> = ({
    isOpen,
    onClose,
    feedbackInfo,
    requestType

 }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset={'slideInBottom'} size={'lg'}>
            <ModalOverlay/>
            <ModalContent h={'70vh'} py={'40px'} borderRadius={'20px'}>
                <Flex
                    px={'50px'}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                    w={'full'}
                >
                    <Flex justify="space-between" align="center" w={'full'} >
                        <Text color="brand" fontSize="18px" fontWeight="bold" lineHeight="22px">
                            Feedback
                        </Text>
                        <CloseButton onClick={onClose} />
                    </Flex>
                </Flex>
                <ModalBody px={'50px'} d={'flex'} flexDirection={'column'} overflow={'auto'} h={'full'}>
                    {/*    Agent Info*/}
                    <Flex mt={'28px'} align={'center'}>
                        <Avatar
                            aria-label={'avatar'}
                            src={getAvatarPicture(feedbackInfo.avatar)}
                            name={feedbackInfo.agent}
                            boxSize={'60px'}
                        />
                        <VStack spacing={'0px'}  align={'stretch'} ml={'13px'}>
                            <Typography size={16} weight={500}>{feedbackInfo.agent}</Typography>
                            <Typography size={12} weight={300} lineHeight={'18px'}>{requestType}</Typography>
                            <Typography size={12} weight={300} lineHeight={'18px'}>
                                {dayjs(feedbackInfo.dateTime).format('MMMM D, YYYY')}
                            </Typography>
                        </VStack>
                    </Flex>
                    <Box bg={'#D1D2DB'} h={'1px'} my={'23px'}/>
                    {/*Request Type */}
                    <Typography size={16} lineHeight={'22px'} weight={600}>
                        {requestType}
                    </Typography>
                    {/*    RateBox*/}

                    <Flex
                        w="100%"
                        mt="16px"
                        mb="20px"
                        direction="column"
                        bg="#F6F7FB"
                        borderRadius="24px"
                        padding="15px 35px"
                    >
                        <Flex justifyContent="space-between">
                            <Text
                                fontSize="13px"
                                lineHeight="20px"
                                fontWeight="light"
                                mb="20px"
                                color="brand"
                            >
                                Rate
                            </Text>
                            <Text
                                fontSize="13px"
                                lineHeight="20px"
                                fontWeight="light"
                                mb="20px"
                                color="brand"
                            >
                                {Rating[feedbackInfo.rate]}
                            </Text>
                        </Flex>
                        <RateStar
                            setStarCount={() => {}}
                            starCount={feedbackInfo.rate}
                        />
                    </Flex>

                    {feedbackInfo.text ?
                        <Flex direction={'column'} spacing={'0px'} align={'stretch'}>
                            <Typography size={12} weight={300} lineHeight={'18px'}>
                                Feedback
                            </Typography>
                            <Typography size={16} mt={'16px'}>
                                {feedbackInfo.text}
                            </Typography>
                        </Flex>
                        : null
                    }
                    <Box marginTop={'30px'}>
                        <Button
                            onClick={onClose}
                            isFullWidth
                            variant={'primary'}
                        >
                            Close
                        </Button>
                    </Box>

                </ModalBody>
            </ModalContent>


        </Modal>
    );
};

