import React, { useEffect, useState } from 'react';
import { PaymentDropDown, PaymentInfoBox, PaymentsModalWrapper } from '../components';
import { Box, Flex, Text } from '@chakra-ui/react';
import { GetFundingSourcesResponse } from '../types';
import { CustomButton, CustomInput, Loader } from '../../../../../common/components';
import { DollarInputIcon } from '../../../../../assets/icons';
import amountBalanceResolver, {
    AmountBalanceFormValue,
} from '../components/validation/amountBalance.schema';
import { Resolver, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createUserToSelfTransferRequest } from '../store/actions';

interface IRechargeModalProps {
    isOpen: boolean;
    title: string;
    onClose: () => void;
    walletBalanceLoading: boolean;
    fundingSourcesLoading: boolean;
    walletBalance: number;
    fundingSources: GetFundingSourcesResponse[];
    rechargeBalanceLoading: boolean;
}

export const RechargeModal: React.FC<IRechargeModalProps> = ({
    isOpen,
    title,
    onClose,
    walletBalanceLoading,
    fundingSourcesLoading,
    walletBalance,
    fundingSources,
    rechargeBalanceLoading,
}) => {
    const [fundingSourceType, setFundingSourceType] = useState<GetFundingSourcesResponse>(
        {} as GetFundingSourcesResponse,
    );

    const {
        register,
        formState: { errors },
        handleSubmit,
        clearErrors,
        setValue,
        reset,
    } = useForm<AmountBalanceFormValue>({
        resolver: amountBalanceResolver as Resolver<AmountBalanceFormValue>,
    });

    const dispatch = useDispatch();

    const onSubmit = (data: AmountBalanceFormValue) => {
        dispatch(
            createUserToSelfTransferRequest({
                ...data,
                isPayout: false,
            }),
        );
    };

    useEffect(() => {
        if (fundingSourceType) {
            setValue('fundingSourceId', fundingSourceType.dwollaId);
            clearErrors('fundingSourceId');
        }
    }, [fundingSourceType]);

    useEffect(() => {
        if (!isOpen) {
            reset({});
            setFundingSourceType({} as GetFundingSourcesResponse);
        }
    }, [isOpen]);

    return (
        <PaymentsModalWrapper isOpen={isOpen} title={title} onClose={onClose}>
            <>
                {fundingSourcesLoading || walletBalanceLoading ? (
                    <Loader spinnerSize={'md'} centerHeight={'100%'} />
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Header Box */}
                        <PaymentInfoBox walletBalance={walletBalance} />
                        {/* Payment Drop Down */}
                        <Box mt={'22px'}>
                            <PaymentDropDown
                                error={errors.fundingSourceId?.message}
                                items={fundingSources}
                                setValue={setFundingSourceType}
                                value={fundingSourceType}
                                loading={fundingSourcesLoading}
                            />
                        </Box>
                        {/* Amount */}
                        <Text
                            my={'20px'}
                            textTransform={'capitalize'}
                            fontWeight={400}
                            fontSize={'16px'}
                            lineHeight={'22px'}
                            color="brand"
                        >
                            Amount
                        </Text>

                        <CustomInput
                            errors={errors.amountOfMoney}
                            register={{ ...register('amountOfMoney') }}
                            width={'342px'}
                            rightIcon={<DollarInputIcon />}
                            label={'Enter amount'}
                            placeholder={'Enter'}
                        />

                        {/* Button */}
                        <Box textAlign={'right'} mt={'166px'}>
                            <CustomButton
                                isLoading={rechargeBalanceLoading}
                                maxW={'342px'}
                                text="Recharge"
                                type="submit"
                                height="60px"
                                bgColor="brand"
                            />
                        </Box>
                    </form>
                )}
            </>
        </PaymentsModalWrapper>
    );
};
