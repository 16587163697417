import React from 'react';
import {
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    VStack,
    Flex,
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import { FilterIcon } from '../../../../assets/icons';

interface ISingleMenuSelectProps {
    selectedItem: number;
    onSelectItem: (value: number) => void;
    menuValues: { label: string; value: number }[];
}

export const SingleMenuSelect: React.FC<ISingleMenuSelectProps> = ({
    menuValues,
    onSelectItem,
    selectedItem,
}) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    return (
        <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
            <PopoverTrigger>
                <Flex
                    bg="white"
                    borderRadius="20px"
                    h="50px"
                    w="100%"
                    align="center"
                    px="20px"
                    justifyContent="flex-start"
                    fontWeight="normal"
                    onClick={onOpen}
                    as={Button}
                >
                    <FilterIcon />
                    <Text
                        fontSize="14px"
                        lineHeight="21px"
                        color="secondaryFontDarker"
                        ml="20px"
                        maxW="12.3vw"
                        isTruncated
                    >
                        {selectedItem
                            ? menuValues.find(elem => elem.value === selectedItem)?.label
                            : 'Filter'}
                    </Text>
                </Flex>
            </PopoverTrigger>
            <PopoverContent borderRadius="20px">
                <VStack align="flex-start" p="15px">
                    {menuValues.map(elem => (
                        <Button
                            key={elem.value}
                            variant="ghost"
                            w="100%"
                            textAlign="left"
                            fontWeight={selectedItem === elem.value ? 'semibold' : 'normal'}
                            fontSize="14px"
                            lineHeight="21px"
                            color={selectedItem === elem.value ? 'brand' : 'secondaryFontDarker'}
                            justifyContent="flex-start"
                            height="30px"
                            onClick={() => {
                                onSelectItem(elem.value);
                                onClose();
                            }}
                        >
                            {elem.label}
                        </Button>
                    ))}
                </VStack>
            </PopoverContent>
        </Popover>
    );
};
