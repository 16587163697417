import React from 'react';
import { colors } from '../../common/constants';

export const ImageLocation: React.FC = () => (
    <svg
        width={'24'}
        height={'25'}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.953 16.825C21.7895 16.7352 21.597 16.714 21.4179 16.7662C21.2388 16.8183 21.0878 16.9395 20.998 17.103C20.9082 17.2665 20.8871 17.4589 20.9392 17.638C20.9913 17.8171 21.1125 17.9682 21.276 18.058C22.114 18.518 22.594 19.028 22.594 19.458C22.594 19.983 21.85 20.797 19.762 21.506C17.2476 22.2728 14.6283 22.6399 12 22.594C9.37172 22.6402 6.75248 22.2734 4.238 21.507C2.15 20.798 1.406 19.984 1.406 19.459C1.406 19.03 1.886 18.52 2.724 18.059C2.80489 18.0145 2.87624 17.9546 2.93397 17.8825C2.99169 17.8105 3.03467 17.7278 3.06043 17.6392C3.0862 17.5506 3.09426 17.4577 3.08415 17.366C3.07404 17.2742 3.04595 17.1854 3.0015 17.1045C2.95705 17.0236 2.8971 16.9522 2.82507 16.8945C2.75305 16.8368 2.67036 16.7938 2.58172 16.768C2.49309 16.7423 2.40025 16.7342 2.3085 16.7443C2.21675 16.7544 2.12789 16.7825 2.047 16.827C1.113 17.34 0 18.213 0 19.459C0 20.414 0.657 21.776 3.786 22.839C6.44617 23.6543 9.21806 24.0461 12 24C14.7819 24.0461 17.5538 23.6543 20.214 22.839C23.343 21.776 24 20.414 24 19.459C24 18.213 22.887 17.34 21.953 16.825Z"
            fill={colors.brand}
        />
        <path
            d="M6.69203 20.49C10.1819 21.3237 13.8191 21.3237 17.309 20.49C19.055 19.997 19.941 19.283 19.941 18.368C19.941 17.453 19.056 16.739 17.309 16.246C16.921 16.137 16.5 16.04 16.052 15.959C15.8087 16.3797 15.5537 16.813 15.287 17.259C15.787 17.333 16.249 17.425 16.672 17.532C17.3567 17.627 17.9995 17.9176 18.523 18.369C17.9995 18.8204 17.3568 19.111 16.672 19.206C15.2195 19.5422 13.733 19.71 12.242 19.706C12.162 19.712 12.082 19.715 12.001 19.715C11.92 19.715 11.84 19.715 11.76 19.706C10.2691 19.71 8.7826 19.5422 7.33003 19.206C6.64532 19.111 6.00258 18.8204 5.47903 18.369C6.00261 17.9176 6.64533 17.627 7.33003 17.532C7.75303 17.424 8.21903 17.332 8.71503 17.259C8.44837 16.8136 8.19337 16.3803 7.95004 15.959C7.50204 16.041 7.08003 16.137 6.69303 16.246C4.94703 16.739 4.06104 17.453 4.06104 18.368C4.06104 19.283 4.95003 19.997 6.69203 20.49Z"
            fill={colors.brand}
        />
        <path
            d="M12.004 18.311C12.3096 18.3122 12.6103 18.2343 12.8769 18.0848C13.1434 17.9353 13.3667 17.7194 13.525 17.458C15.813 13.715 18.54 8.781 18.54 6.536C18.54 4.80254 17.8514 3.14009 16.6257 1.91435C15.3999 0.688612 13.7375 0 12.004 0C10.2706 0 8.6081 0.688612 7.38236 1.91435C6.15662 3.14009 5.46802 4.80254 5.46802 6.536C5.46802 8.781 8.19502 13.714 10.483 17.458C10.6413 17.7194 10.8646 17.9353 11.1312 18.0848C11.3977 18.2343 11.6984 18.3122 12.004 18.311ZM9.37302 6.087C9.37321 5.56764 9.52735 5.06 9.81596 4.62821C10.1046 4.19642 10.5147 3.85986 10.9945 3.66107C11.4743 3.46227 12.0022 3.41016 12.5117 3.51131C13.0211 3.61246 13.4891 3.86234 13.8565 4.22937C14.224 4.59641 14.4744 5.06412 14.5761 5.57342C14.6779 6.08271 14.6263 6.61074 14.4281 7.09077C14.2298 7.5708 13.8938 7.9813 13.4623 8.2704C13.0308 8.55949 12.5234 8.71421 12.004 8.715C11.3067 8.71527 10.6378 8.43857 10.1444 7.94575C9.65101 7.45293 9.37355 6.78435 9.37302 6.087Z"
            fill={colors.brand}
        />
    </svg>
);
