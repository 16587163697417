import React, { useState } from 'react';
import {
    Box,
    Text,
    Select,
    FormControl,
    FormErrorMessage,
    IconButton,
    InputRightElement,
    InputGroup,
    Center,
    Button,
} from '@chakra-ui/react';
import { DropdownArrowIcon, CheckIcon, EditIcon } from '../../../../assets/icons';
import { ItemType, States } from '../../../types';
import { fonts } from '../../../utils';
import { colors } from '../../../constants';

interface ICustomDropdownEditProps {
    options: ItemType[] | States[] | Array<{ value: number | string; label: string }>;
    label: string;
    width?: string;
    margin?: string;
    register?: any;
    errors?: any;
    onChange?: any;
    placeholder?: string;
    value?: string | number;
    fontFamily?: string;
    labelSize?: string;
    fontSize?: string;
    height?: string;
    labelWeigth?: number;
    disabled?: boolean;
    editFunction?: (value: number) => void;
    isReadOnly?: boolean;
    bgColor?: string;
}

export const CustomDropdownEdit: React.FC<ICustomDropdownEditProps> = ({
    options,
    label,
    width,
    margin,
    register,
    errors,
    onChange,
    placeholder,
    value,
    fontFamily = fonts.poppins,
    labelSize = '13px',
    fontSize = '13px',
    height = '50px',
    labelWeigth = 300,
    disabled,
    editFunction,
    isReadOnly = false,
    bgColor = 'inputBackground',
}) => {
    const [editable, setEditable] = useState(false);
    return (
        <Box w={width} textAlign="left" color="#fff" m={margin}>
            <FormControl isInvalid={!!errors}>
                <Text
                    textTransform={'capitalize'}
                    color="brand"
                    fontWeight={labelWeigth}
                    fontSize={labelSize}
                    fontFamily={fontFamily}
                >
                    {label}
                </Text>
                <InputGroup>
                    <Select
                        h={height}
                        bg={bgColor}
                        borderRadius="80px"
                        color="brand"
                        fontSize={fontSize}
                        icon={
                            isReadOnly || disabled ? undefined : (
                                <Center
                                    marginRight={disabled ? 0 : editable ? '120px' : '70px'}
                                    h="100%"
                                >
                                    <DropdownArrowIcon />
                                </Center>
                            )
                        }
                        fontFamily={fontFamily}
                        // defaultValue={options?.[0]}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange ? v => onChange(v.target.value) : () => null}
                        isReadOnly={isReadOnly}
                        pointerEvents={isReadOnly || !editable ? 'none' : 'all'}
                        {...register}
                    >
                        {options?.map((item: any) => (
                            <option key={item.id} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </Select>
                    {!disabled && !isReadOnly && (
                        <InputRightElement h="100%" width="auto" px="5px">
                            {editable ? (
                                <Button
                                    color={colors.calendarGreen}
                                    fontWeight={300}
                                    rightIcon={<CheckIcon />}
                                    variant="link"
                                    fontSize="14px"
                                    onClick={() => {
                                        setEditable(false);
                                        value && editFunction?.(+value);
                                    }}
                                >
                                    Save
                                </Button>
                            ) : (
                                <IconButton
                                    variant="unstyled"
                                    aria-label="Edit field"
                                    d="flex"
                                    alignItems="center"
                                    onClick={() => setEditable(true)}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </InputRightElement>
                    )}
                </InputGroup>
                {errors && (
                    <FormErrorMessage fontSize={labelSize}>{errors.message}</FormErrorMessage>
                )}
            </FormControl>
        </Box>
    );
};
