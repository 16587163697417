import React from 'react';
import { ModalHeader, Text, CloseButton } from '@chakra-ui/react';

interface IHeaderProps {
    onClose: () => void;
    title: string;
}

export const Header: React.FC<IHeaderProps> = ({ onClose, title }) => (
    <ModalHeader d="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Text color="brand" fontSize="18px" lineheight="22px">
            {title}
        </Text>
        <CloseButton onClick={onClose} />
    </ModalHeader>
);
