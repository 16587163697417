import React from 'react';
import { Text, Box, Link } from '@chakra-ui/react';
import { colors } from '../../../common/constants';
import { fonts } from '../../../common/utils';
import { FormattedText, FormattedTextBold } from '../../../common/components/landing';

export const PolicyTexts: React.FC = () => {
    return (
        <Box>
            <Text
                fontFamily={fonts.montserrat}
                fontSize="22px"
                lineHeight="18px"
                color={colors.brand}
                fontWeight="bold"
                mb="30px"
            >
                Privacy Policy
            </Text>
            <FormattedTextBold>Introduction</FormattedTextBold>
            <FormattedText>
                This Privacy Policy (“Privacy Policy”) describes how ZootPro, Inc. and its
                affiliates will gather, use, and maintain your Personal Information on the ZootPro
                Platform. It will also explain your legal rights with respect to that information.
                By using the ZootPro Platform, you confirm that you have read and understand this
                Privacy Policy, and our Global Terms of Service (together referred to herein as the
                “Agreement”). The Agreement governs the use of the ZootPro Platform. ZootPro will
                collect, use, and maintain information consistent with the Agreement.
            </FormattedText>
            <FormattedText>
                If you are a California resident, please see the “Additional Disclosures for
                California Residents”. If you have any questions or wish to exercise your rights and
                choices, please contact us as set out in the “Contact Us” section below.
            </FormattedText>
            <FormattedTextBold>General Terms</FormattedTextBold>
            <FormattedText>In this Privacy Policy:</FormattedText>
            <FormattedText>
                ZootPro, Inc. may be referred to as &quot;ZootPro&quot;, &quot;we&quot;,
                &quot;our&quot;, or &quot;us&quot;.
            </FormattedText>
            <FormattedText>
                We call a user of the ZootPro Platform “User,” “Users,” “you,” “your,” or “Client”
                and “Agent or other User,” as appropriate.
            </FormattedText>
            <FormattedText>
                The community platform that we offer at our website (
                <Link href="www.ZootPro.com">www.ZootPro.com</Link> and{' '}
                <Link href="www.ZootProTM.com">www.ZootProTM.com</Link>) is referred to as the
                “Site(s).”
            </FormattedText>
            <FormattedText>
                The mobile applications (whether on iOS or Android) are referred to as the “Apps.”
            </FormattedText>
            <FormattedText>
                ZootPro’s Sites, Apps and related services, information and communications are
                collectively referred to as the “ZootPro Platform.”
            </FormattedText>
            <FormattedText>
                “Terms of Service” refers to our Terms of Service. This Privacy Policy is
                incorporated into, and considered a part of, the Terms of Service.
            </FormattedText>
            <FormattedTextBold>Information Collection</FormattedTextBold>
            <FormattedText>Information you provide to ZootPro</FormattedText>
            <FormattedText>
                ZootPro collects certain personally identifiable information about you, including
                information that can identify, relate to, describe, be associated with you, or is
                reasonably capable of being associated with you (“Personal Information”). Examples
                of Personal Information that ZootPro collects include but are not limited to:
            </FormattedText>
            <FormattedText tab>
                Contact Information. This may include your first and last name, postal address,
                telephone number, and email address.
            </FormattedText>
            <FormattedText tab>
                Billing Data, including your payment instrument number (such as a credit or debit
                card number), expiration date, and security code as necessary to process your
                payments.
            </FormattedText>
            <FormattedText tab>
                Identity Information. If you are an Agent or other User, we may collect Personal
                Information, such as your date of birth and address, national identification number
                if applicable, together with the result of basic criminal record checks as provided
                by you, or by our Third-Party Agents (as defined below), as applicable and to the
                extent permitted by law in your jurisdiction, as well as such information as may be
                needed to validate your identity.
            </FormattedText>

            <FormattedText tab>
                Financial Information. To help Agents or other Users set up a payment account and
                help Hiring Agents make payments to Agents or other Users and pay fees to ZootPro,
                we may collect financial information, including credit card number, bank routing
                numbers, tax information, taxpayer identification number, and other payment
                information, as applicable. We use Financial Information in order to operate the
                ZootPro Platform and to ensure that Agents or other Users are paid by Hiring Agents.
                We do this as necessary for our legitimate interests in providing our platform and
                services and to fulfill our contracts with all Users. To keep your financial data
                secure, we have contracted with a third party to maintain and process your payment
                information.
            </FormattedText>
            <FormattedText tab>
                Promotional Information. Certain offerings of the ZootPro Platform, such as
                newsletters, surveys, contests, and the like are optional and so you are not
                required to enter them or to give us your data in connection with them. Where you do
                consent to take advantage of ZootPro Platform offerings, we will use your data to
                (as applicable) send you newsletters and other communications that are tailored
                based on information we have about you, or to operate and manage the survey, contest
                or similar offering in connection with our legitimate interest in promoting our
                business and the ZootPro Platform. To opt-out of receiving marketing communications
                from us, please see Section 7, Your Rights and Choices.
            </FormattedText>
            <FormattedText tab>
                Content Information. You also may choose to send ZootPro Personal Information in an
                email or chat message containing inquiries about the ZootPro Platform and we use
                this Information in order to help us respond to your inquiry. We also collect
                content within any messages you exchange with other Users through the ZootPro
                Platform (such as through our chat functionality).
            </FormattedText>
            <FormattedText>
                We require that you furnish certain information when you register an account with us
                in order to provide services through the ZootPro Platform. For example, we collect
                your first and last name, email address, and your zip or postal code in order to
                create and administer your ZootPro account. We also collect additional information
                in order to facilitate your booking request, such as information about the task you
                are seeking, the time, date and location of the task, and Billing Data. If you are
                an Agent or other User, we collect your first and last name, email address, mobile
                phone number and zip or postal code in order to create and administer your ZootPro
                account and facilitate communications between you and your Hiring Agents through the
                ZootPro Platform. We also collect information about your tasks, rates, and skills,
                as well as Identity Information and Financial Information.
            </FormattedText>
            <FormattedText>
                Third Party Information. If you registered through a third-party Platform, they may
                provide us with basic information such as your name, address, email, and purchase
                history related to your Task needs. We may receive additional information about you,
                such as demographic data, Financial Information, or fraud detection information,
                from Third-Party Agents (as defined below) and combine it with other information
                that we have about you, to the extent permitted by law, in order to comply with our
                legal obligations and for the legitimate interest in improving the ZootPro Platform.
                ZootPro may work with Third-Party Agents to perform identity checks and criminal
                background checks on Agents or other Users, if applicable and permitted by local
                law, in order to advance our legitimate interests in ensuring the safety of our
                Users and maintaining the integrity of the ZootPro Platform.
            </FormattedText>
            <FormattedTextBold>Information ZootPro Collects Automatically</FormattedTextBold>
            <FormattedText>
                We automatically collect certain information when you use the ZootPro Platform. The
                categories of information we automatically collect and have collected, including in
                the last 12 months, are as follows: Service Use Data, including data about features
                you use, pages you visit, emails and advertisements you view, portions of the
                ZootPro Platform that you view and interact with, the time of day you browse, and
                your referring and exiting pages.
            </FormattedText>
            <FormattedText tab>
                Service Use Data, including data about features you use, pages you visit, emails and
                advertisements you view, portions of the ZootPro Platform that you view and interact
                with, the time of day you browse, and your referring and exiting pages.
            </FormattedText>
            <FormattedText tab>
                Device Data, including data about the type of device or browser you use, your
                device’s operating system, your internet service provider, your device’s regional
                and language settings, and device identifiers such as IP address and Ad Id. When you
                visit and interact with the ZootPro Platform, ZootPro may collect certain
                information automatically through cookies or other technologies, including, but not
                limited to, the type of computer or mobile device you use, your mobile device’s
                unique device ID, the IP address of your computer or mobile device, your operating
                system, the type(s) of internet browser(s) you use, and information about the way
                you use the ZootPro Platform (“Device Information”). We may use Device Information
                to monitor the geographic regions from which Users navigate the ZootPro Platform,
                and for security and fraud prevention purposes. Use of any IP-masking technologies
                or similar technologies (like the TOR network) may render portions of the ZootPro
                Platform unavailable and are forbidden on the ZootPro Platform.
            </FormattedText>
            <FormattedText tab>
                Location Data, including imprecise location data (such as location derived from an
                IP address or data that indicates a city or postal code level), and, with your
                consent, precise location data (such as latitude/longitude data). When you visit the
                ZootPro Platform via a native mobile application, we use, with your consent when
                required under applicable law, GPS technology (or other similar technology) to
                determine your current location in order to determine the city you are located in
                and display a relevant location map. We will not share your current location
                obtained in this manner with other Users.
            </FormattedText>
            <FormattedText tab>
                Cookies and similar technologies are described in our Cookie Policy, which sets out
                the different categories of cookies and similar technologies that the ZootPro
                Platform uses and why we use them.
            </FormattedText>
            <FormattedTextBold>ZootPro&apos;s Use of Information</FormattedTextBold>
            <FormattedText>
                We collect and use information for business and commercial purposes in accordance
                with the practices described in this Privacy Policy. Our business purposes for
                collecting and using information include: To operate and make available the ZootPro
                Platform. We use your data to connect you with other Users to enable the posting of,
                selection for, completion of, and payment for Tasks, in order to fulfill our
                contracts with Users;
            </FormattedText>
            <FormattedText>
                For billing and fraud prevention, on the basis of our legitimate interests in
                ensuring a safe and secure environment in which Hiring Agents and Agents or other
                Users can meet and conduct business, and in order to comply with our legal
                obligations;
            </FormattedText>
            <FormattedText>
                To conduct identification and criminal background checks, if applicable and to the
                extent permitted by local law, on Users, in order to advance our legitimate
                interests as well as for the substantial public interest of ensuring the safety of
                our Users both online and offline, preventing or detecting unlawful acts, protecting
                the public against dishonesty, and maintaining the integrity of the ZootPro Platform
                given that Agents or other Users often interact directly with Hiring Agents and
                their clients and may enter their homes;
            </FormattedText>
            <FormattedText>
                To contact you and deliver (via email, SMS, push notifications, or otherwise)
                transactional information, administrative notices, marketing notifications, offers
                and communications relevant to your use of the ZootPro Platform, with your consent
                when required under applicable law, as necessary for our legitimate interests in
                proper communication and engagement with our Users, and in promoting our business;
            </FormattedText>
            <FormattedText>
                To provide you with customer support in order to fulfill our contracts with Users;
            </FormattedText>
            <FormattedText>
                For internal market research for our legitimate interest in improving the ZootPro
                Platform to grow our business;
            </FormattedText>
            <FormattedText tab>
                To provide you with customer support in order to fulfill our contracts with Users;
            </FormattedText>
            <FormattedText tab>
                For troubleshooting problems for our legitimate interests in ensuring a safe and
                secure environment in which Users can meet;
            </FormattedText>
            <FormattedText tab>
                To assist Users in the resolution of complaints and disputes between them, as
                necessary for our legitimate interests in facilitating good relations among Users;
            </FormattedText>
            <FormattedText tab>
                To enforce our Terms of Service and our legitimate interests in ensuring our
                Agreement is complied with; and As otherwise set forth in the Agreement.
            </FormattedText>
            <FormattedText tab>
                Interest-Based Advertising. Ads are a standard part of user experience on the
                Internet, and ZootPro believes that targeted advertising enhances this experience.
                ZootPro and affiliate third parties may use cookies and other technologies to place
                ads where they believe interested Users will see them. In addition to banner ads,
                ZootPro may advertise products, companies and events that we think might interest
                you through the email address and/or phone number you provide. We may incorporate
                Tracking Technologies on the ZootPro Platform (including our website and emails) as
                well as into our ads displayed on other websites and services. Some of these
                Tracking Technologies may track your activities across time and services for
                purposes of associating the different devices you use, and delivering relevant ads
                and/or other content to you (“Interest-Based Advertising”).
            </FormattedText>
            <FormattedText>
                For more information and to understand your choices regarding third-party ads,
                please see our Cookie Policy. Advertising and marketing is carried out as necessary
                for our legitimate interests in providing an engaging and relevant experience,
                promoting our services, and growing our business.
            </FormattedText>
            <FormattedText>
                Analytics and Market Analysis. ZootPro may analyze information (“Market Analysis”)
                as necessary for our legitimate interests in providing an engaging and relevant
                experience, and promoting and growing the ZootPro Platform.
            </FormattedText>
            <FormattedText>
                For more information and to understand your choices regarding third-party ads,
                please see our Cookie Policy. Advertising and marketing is carried out as necessary
                for our legitimate interests in providing an engaging and relevant experience,
                promoting our services, and growing our business.
            </FormattedText>
            <FormattedText>
                Analytics and Market Analysis. ZootPro may analyze information (“Market Analysis”)
                as necessary for our legitimate interests in providing an engaging and relevant
                experience, and promoting and growing the ZootPro Platform.
            </FormattedText>
            <FormattedText>
                ZootPro uses information to offer services to Users who express an interest in these
                services, through a poll for example, or to Users who can be presumed to have an
                interest based on results from our Market Analysis. We do this as necessary for our
                legitimate interests in providing an engaging and relevant experience, promoting our
                services, and growing our business.
            </FormattedText>
            <FormattedText>
                Cell Phone Numbers. ZootPro may use your cell phone number to call or send recurring
                text messages to you, using an autodialer or prerecorded voice, in order to provide
                you notifications about Tasks, for marketing purposes (with your consent where
                required by law), and to administer the ZootPro Platform. If you would like more
                information about our policy, or how to opt out, please review the “Your Rights and
                Choices” section below or Section 9 of our Terms of Service. You may be liable for
                standard SMS and per-minute charges by your mobile carrier. ZootPro may also message
                you via push notifications (with your consent when required under applicable law),
                which you can opt-out of on your mobile device. Data rates may apply.
            </FormattedText>
            <FormattedText>
                Call recordings. Calls to or from ZootPro or its Third Party Agents may be monitored
                and recorded for the purposes of quality control and training. Such calls may
                contain Personal Information.
            </FormattedText>
            <FormattedTextBold>Information Sharing</FormattedTextBold>
            <FormattedText>
                We only share the Information we collect about you as described in this Privacy
                Policy or as described at the time of collection or sharing, including as follows:
            </FormattedText>
            <FormattedText tab>
                Third Party Agents. We share information, including Identity Information, with
                entities that process information on our behalf for our business purposes. We
                contractually prohibit our Third-Party Agents from retaining, using, or disclosing
                information about you for any purposes other than performing the services for us,
                although we may permit them to use information that does not identify you (including
                information that has been aggregated or de-identified) for any purpose except as
                prohibited by applicable law.
            </FormattedText>
            <FormattedText>
                To operate the ZootPro Platform, including processing your transactions, we may
                share your Personal Information with our agents, representatives, vendors and
                service providers (“Third Party Agents”) so they can provide us with support
                services as follows:
            </FormattedText>
            <FormattedText tab>Email origination; Identity check;</FormattedText>
            <FormattedText tab>
                Fraud prevention and detection; Receipt, invoice, or support services;
            </FormattedText>
            <FormattedText tab>
                Customer relationship management services; Data analytics;
            </FormattedText>
            <FormattedText tab>
                Marketing and advertising; Website hosting; Communications services; Technical
                support;
            </FormattedText>
            <FormattedText tab>
                Financial transaction fulfillment (in which we are currently supported by Dwolla,
                who processes your Personal Information in accordance with its own privacy policy)
                and related chargeback and collection services; and To otherwise help us provide the
                ZootPro Platform.
            </FormattedText>
            <FormattedText tab>
                Partners. Some ZootPro content is &quot;sponsored by&quot; or &quot;presented
                by&quot; other companies. If you connect to the ZootPro Platform through a third
                party or otherwise participate in one of our partner programs, we may share
                information about your use of the ZootPro Platform with that Partner in order to
                offer the integrated platform and to evaluate the partner program. We may also share
                your Personal Information with our Partners in order to receive additional
                information about you, or in order to create offers that may be of interest to you.
                Please check such Partner’s privacy policy before revealing information about
                yourself. If you don&apos;t want these Partners to have your Personal Information,
                you can choose to not participate.
            </FormattedText>
            <FormattedText tab>
                Promotions. When you voluntarily enter a sweepstakes, contest, or other promotion,
                we share information as set out in the official rules that govern the promotion as
                well as for administrative purposes and as required by law (e.g., on a winners’
                list). By entering a promotion, you agree to the official rules that govern that
                promotion, and may, except where prohibited by applicable law, allow the sponsor
                and/or other entities to use your name, voice, and/or likeness in advertising or
                marketing materials. We may occasionally contact you, if you want us to, with
                information about special events, activities, promotions, contests, submission
                opportunities, and programs. You always have the ability, in your Account, to ask
                ZootPro not to contact you with this type of information. Please see Section 7, Your
                Rights and Choices, for more information.
            </FormattedText>
            <FormattedText tab>
                Matched Ads. We use Matched Ads by sharing personal information with another party
                or incorporating a pixel from another party into our own Sites, and the other party
                matching common factors between our data and their data or other datasets for
                advertising purposes. For instance, we incorporate the Facebook pixel on our Sites
                and may share your email address with Facebook as part of our use of Facebook Custom
                Audiences. Other Users. ZootPro facilitates contact between Hiring Agents and Agents
                or other Users and reserves the right to share one User’s contact information (e.g.,
                name, phone number, email, or postal address) with other Users, or with the
                recipient User’s legal or other authorized representative, in order to: (1)
                facilitate or communicate the resolution of an investigation or dispute related to
                or arising from an interaction between two or more Users of the ZootPro Platform; or
                (2) facilitate the performance of a Task. This exchange of information is essential
                to the operation of the ZootPro Platform.
            </FormattedText>
            <FormattedText tab>
                Legal Obligations. ZootPro and our Third Party Agents may disclose your Personal
                Information or User Generated Content to courts, law enforcement, governmental or
                public authorities, tax authorities or authorized third parties, if and to the
                extent required or permitted to do so by law or where disclosure is reasonably
                necessary to: (i) comply with ZootPro’s legal or regulatory obligations; (ii)
                respond to a court order, warrant or subpoena; (iii) respond to a valid legal
                request relating to an investigation into alleged criminal or illegal activity; or
                (iv) respond to or address any other activity that may expose us to legal or
                regulatory liability.
            </FormattedText>
            <FormattedText>
                In jurisdictions where ZootPro is, according to applicable law, required to collect
                or remit information about Users’ permits, licenses, tax, or social security
                registrations, ZootPro and our Third Party Agents may disclose information,
                including but not limited to, User contact details, identification information,
                transaction dates and amounts, license and permit status, and tax identification
                number(s) to applicable governmental authorities. ZootPro reserves the right to
                disclose Personal Information from both private and public areas of the ZootPro
                Platform in the absence of a court order, warrant, or subpoena, to the extent
                permitted by applicable law, if we are given reason to believe, in our sole
                discretion, that someone is causing injury to or interfering with the rights of
                Users, the general public, or ZootPro or its partners, parents or affiliates.
            </FormattedText>
            <FormattedText>
                It is our policy to provide notice to Users before producing their information in
                response to law enforcement requests unless (i) we are prohibited or otherwise
                constrained by law or court order from doing so, (ii) we have reason to believe the
                User’s account has been compromised such that the notice would go to the wrong
                person, or notice would otherwise be counterproductive or would create a risk to
                safety, or (iii) it is an emergency request and prior notice would be impractical
                (in which case we may provide notice after the fact). Merger or Acquisition. ZootPro
                reserves the right to share information in connection with, or during negotiations
                of, any proposed or actual merger, purchase, sale, or any other type of acquisition
                or business combination of all or any portion of our assets, or transfer of all or a
                portion of our business to another business.
            </FormattedText>
            <FormattedText>
                Public Areas. Your profile on the ZootPro Platform consists of information about you
                and your business, and may include information such as photographs, your years in
                business, skills and expertise, hourly pay rates, feedback/rating information, and
                other information, including your username (“Profile”). The information in your User
                Profile may be visible to all Users and the general public. If you choose to post a
                Task via the ZootPro Platform, the contents of such listing will be viewable to
                Agents or other Users you select on the ZootPro Platform.
            </FormattedText>
            <FormattedTextBold>Your Rights and Choices</FormattedTextBold>
            <FormattedText>
                You may opt-out of receiving promotional communications from us, including
                promotional communications related to the ZootPro Platform, and request the removal
                of your Personal Information from our databases, or deactivate your account, by
                logging on to the Site or App and clicking on the “Account” tab, or by contacting
                us.
            </FormattedText>
            <FormattedText>
                Account Settings. During registration you choose whether to receive marketing
                correspondence from ZootPro. This information remains on your Profile where you can,
                at any point, easily edit it. After logging on, click on the “Account” tab, then
                select “Notifications” to make your preferred selections.
            </FormattedText>
            <FormattedText>
                Push Notifications. You have the option to receive updates and relevant offers via
                push notifications in your app. These notifications can be configured in the
                settings of your mobile device at any time.
            </FormattedText>
            <FormattedText>
                Corrections to Profile. You have the right to access, update, and correct
                inaccuracies in your ZootPro Profile at any time by logging in and clicking on the
                “Account” tab. There, you can view, update, and correct your Account information.
            </FormattedText>
            <FormattedText>
                So that we may protect the integrity of the ZootPro Platform, there are certain
                pieces of information, such as your age, that you cannot alter yourself. For
                example, since children under the age of majority in their jurisdiction of residence
                are not allowed to register as Users, we need to take reasonable measures to
                preserve the accuracy of our Users&apos; ages. You may contact us at{' '}
                {<Link href="mailto:support@zootpro.com">support@zootpro.com</Link>} if there is a
                need to make a correction to such data.
            </FormattedText>
            <FormattedText>
                Promotional Communications. You can opt out of receiving promotional communications
                from ZootPro via the Account tab in your Profile. In addition, you can opt out of
                email marketing by clicking on the unsubscribe link in any message. You can opt out
                of promotional communications from ZootPro sent via text message at any time by
                following the instructions provided in those messages to text the word
                &quot;STOP&quot;. Please note that your opt-out is limited to the phone number used
                and will not affect subsequent subscriptions. If you opt-out of only certain
                communications, other subscription communications may continue. Even if you opt-out
                of receiving promotional communications, ZootPro will continue to send you
                non-promotional communications, such as those about your account, Tasks,
                transactions, servicing, or ZootPro’s ongoing business relationship with you. If you
                receive unsolicited promotional communications from a ZootPro domain, please let us
                know here.
            </FormattedText>
            <FormattedText>
                If you are a California Resident, please see the Additional Disclosures for
                California Residents section below.
            </FormattedText>
            <FormattedTextBold>ZootPro&apos;s Information Retention Policy</FormattedTextBold>
            <FormattedText>
                We retain personal data for as long as you are a User in order to meet our
                contractual obligations to you, and for such longer period as may be in our
                legitimate interests and to comply with our legal obligations (see Exhibit A for
                exemplar document retention periods). We may also retain data from which you cannot
                directly be identified, for example where stored against a randomly-generated
                identifier so we know the data relates to a single User, but we cannot tell who that
                User is. We use this kind of data for research purposes and to help us develop and
                improve our services, and we take appropriate measures to ensure you cannot be
                re-identified from this data.
            </FormattedText>
            <FormattedTextBold>ZootPro’s Security of Collected Information</FormattedTextBold>
            <FormattedText>
                Your ZootPro account is password-protected so that only you and authorized ZootPro
                staff have access to your account information. In order to maintain this protection,
                do not give your password to anyone. Also, if you share a computer, you should sign
                out of your ZootPro account and close the browser window before someone else logs
                on. This will help protect your information entered on public terminals from
                disclosure to third parties.
            </FormattedText>
            <FormattedText>
                ZootPro implements and maintains reasonable administrative, physical, and technical
                security safeguards to help protect information about you from loss, theft, misuse
                and unauthorized access, disclosure, alteration and destruction. ZootPro has staff
                dedicated to maintaining this Privacy Policy and other privacy initiatives,
                periodically reviewing security, and making sure that every ZootPro employee is
                aware of our security practices. Nevertheless, transmission via the internet is not
                completely secure and we cannot guarantee the security of information about you.
            </FormattedText>
            <FormattedTextBold>Notification of Changes</FormattedTextBold>
            <FormattedText>
                ZootPro&apos;s Privacy Policy is periodically reviewed and enhanced as necessary.
                This Privacy Policy might change as ZootPro updates and expands the ZootPro
                Platform. ZootPro will endeavor to notify you of any material changes by email.
                ZootPro also encourages you to review this Privacy Policy periodically.
            </FormattedText>
            <FormattedTextBold>Contacting Us</FormattedTextBold>
            <FormattedText>
                If you have any questions about this Privacy Policy or the manner in which we or our
                Third-Party Agents treat your Personal Information, the practices of the Site, your
                dealings with the ZootPro Platform, or if you have technical problems, you may
                contact us at {<Link href="mailto:support@zootpro.com">support@zootpro.com</Link>}.
            </FormattedText>
            <FormattedText>
                ZootPro&apos;s staff will respond to all mail or email from Users with questions
                about privacy, including the types of Personal Information stored on the ZootPro
                databases, and requests to know, delete or rectify such Personal Information.
            </FormattedText>
            <FormattedTextBold>Jurisdiction-specific Provisions</FormattedTextBold>
            <FormattedText>Residents of the United States of America</FormattedText>
            <FormattedText>
                Information of U.S. Users. Our collection, use, and retention of the Personal
                Information of U.S. Users is in accordance with applicable U.S. laws, as is our
                determination of what is deemed to be “personal data and/or information.”
            </FormattedText>
            <FormattedText>
                ZootPro expressly disclaims any liability that may arise should any other
                individuals obtain the information you submit to the ZootPro Platform.
            </FormattedText>
            <FormattedText>
                Interest-Based Advertising in the United States. For more information about
                interest-based ads, or to opt out of having your web-browsing information used for
                behavioral advertising purposes, please visit www.aboutads.info/choices.
            </FormattedText>
            <FormattedText>
                ZootPro’s Security of Collected Information. While ZootPro will use commercially
                reasonable efforts to ensure the security of all Personal Information, we expressly
                disclaim any liability for any unauthorized access to or use of our secure servers
                and/or any and all Personal Information stored therein, and you agree to hold
                ZootPro harmless for any damages that may result therefrom. If you have any further
                questions on this issue, please refer to the Terms of Service.
            </FormattedText>
            <FormattedTextBold>Additional Disclosures for California Residents</FormattedTextBold>
            <FormattedText>
                These additional disclosures for California residents apply only to individuals who
                reside in California. The California Consumer Privacy Act of 2018 (“CCPA”) provides
                additional rights to know, delete and opt out, and requires business collecting or
                disclosing Personal Information to provide notice of rights California residents
                have and can exercise.
            </FormattedText>
            <FormattedText>
                California Notice of Collection. We have collected Personal Information
                corresponding to the following categories enumerated in the CCPA, including within
                the last 12 months:
            </FormattedText>
            <FormattedText tab>
                Identifiers, including name, address, email address, account name, Social Security
                Number, IP address - and an ID number assigned to your account.
            </FormattedText>
            <FormattedText tab>
                Customer records, phone number, billing address, credit or debit card information,
                employment or education information.
            </FormattedText>
            <FormattedText tab>
                Demographic information, such as your age or gender. This category includes pieces
                of Personal Information that also qualify as protected classification
                characteristics under other pre-existing California or federal laws. Analytics and
                Advertising, including purchases and engagement with the ZootPro Platform.
            </FormattedText>
            <FormattedText tab>
                Internet activity, including history of visiting and interacting with our Service,
                browser type, browser language and other information collected automatically.
            </FormattedText>
            <FormattedText tab>
                Geolocation data, including location enabled services such as WiFi and GPS.
                Inferences, including information about your interests and preferences.
            </FormattedText>
            <FormattedText>
                For more information on what we collect, including the sources we receive
                information from, review Section 3, Information Collection. We collect and use these
                categories of personal information for the business purposes described in Section 5,
                ZootPro’s Use of Information, including to provide and manage the ZootPro Platform.
                ZootPro does not generally sell information as the term “sell” is traditionally
                understood. However, to the extent “sale” under the CCPA is interpreted to include
                advertising technology activities such as those disclosed in Section 5, ZootPro’s
                Use of Information, as a “sale,” we will comply with applicable law as to such
                activity.
            </FormattedText>
            <FormattedText>
                ZootPro discloses the following categories of information for commercial purposes:
                Commercial Information; Demographic Data; Location Data; Identifiers; Inferences;
                and Internet activity.
            </FormattedText>
            <FormattedText>
                We use and partner with different types of entities to assist with our daily
                operations and manage the ZootPro Platform. Please review Section 6, Information
                Sharing, for more detail about the parties with which we share Personal Information.
            </FormattedText>
            <FormattedText>
                Right to Know and Delete. If you are a California resident, you have the right to
                know certain information about our data practices in the preceding 12 months. In
                particular, you have the right to request the following from us:
            </FormattedText>
            <FormattedText tab>
                The categories of Personal Information we have collected about you;
            </FormattedText>
            <FormattedText tab>
                The categories of sources from which the Personal Information was collected;
            </FormattedText>
            <FormattedText tab>
                The categories of Personal Information about you we disclosed for a business purpose
                or sold;
            </FormattedText>
            <FormattedText tab>
                The categories of third parties to whom the Personal Information was disclosed for a
                business purpose or sold;
            </FormattedText>
            <FormattedText tab>
                The business or commercial purpose for collecting or selling the Personal
                Information; and The specific pieces of Personal Information we have collected about
                you.
            </FormattedText>
            <FormattedText>
                In addition, you have the right to delete the Personal Information we have collected
                from you. However, this is not an absolute right and ZootPro may have legal grounds
                for keeping such data.
            </FormattedText>
            <FormattedText>
                To exercise any of these rights, please submit a request at{' '}
                {<Link href="mailto:support@zootpro.com">support@zootpro.com</Link>}. In the
                request, please specify which right you are seeking to exercise and the scope of the
                request. We will confirm receipt of your request within 10 days. We may require
                specific information from you to help us verify your identity and process your
                request. If we are unable to verify your identity, we may deny your requests to know
                or delete. Right to Opt-Out. To the extent ZootPro sells your Personal Information
                as the term “sell” is defined under the CCPA, you have the right to opt-out of the
                sale of your Personal Information by us to third parties at any time. You may
                request to opt-out by clicking Do Not Sell My Personal Information and making your
                choice.
            </FormattedText>
            <FormattedText>
                Authorized Agent. You can designate an authorized agent to submit requests on your
                behalf. However, we will require written proof of the agent’s permission to do so
                and verify your identity directly.
            </FormattedText>
            <FormattedText>
                Right to Non-Discrimination. You have the right to non-discriminatory treatment by
                us, should you exercise any of your rights.
            </FormattedText>
            <FormattedText>
                Shine the Light. Customers who are residents of California may request: a list of
                the categories of Personal Information disclosed by us to third parties during the
                immediately preceding calendar year for those third parties’ own direct marketing
                purposes, and (ii) a list of the categories of third parties to whom we disclosed
                such information. To exercise a request, please contact us at{' '}
                {<Link href="mailto:support@zootpro.com">support@ZootPro.com</Link>} or P.O. Box
                90833, Washington, DC 20002. Requests must include “California Shine the Light
                Request” in the first line of the description and include your name, street address,
                city, state, and ZIP code. We may require additional information from you to allow
                us to verify your identity and are only required to respond to requests once during
                any calendar year. Please note that ZootPro is not required to respond to requests
                made by means other than through the provided email address or mail address.
            </FormattedText>
            <FormattedTextBold>Residents of Canada.</FormattedTextBold>
            <FormattedText>
                Transfer Of Data. We and our affiliates and Third Party Agents primarily store data
                about you, including Personal Information, on servers located and operated within
                the United States. If you reside or are located outside of the U.S., we may send and
                store your Personal Information (also commonly referred to as personal data) to the
                U.S. in order to provide and operate the ZootPro Platform. By accepting the terms of
                this Privacy Policy, you acknowledge the transfer to and processing of your Personal
                Information on servers located in the U.S.
            </FormattedText>
            <FormattedText>
                Custom Audience. We may use services provided by third-party platforms (such as
                social networking or other websites) to serve targeted advertisements on such
                platforms to you, and we may provide a hashed version of your email address or other
                information to the platform provider for such purposes. To opt-out of the use of
                your Personal Information for such purposes, please launch the opt-out tool at{' '}
                {
                    <Link href="https://youradchoices.ca/choices">
                        https://youradchoices.ca/choices
                    </Link>
                }
                .
            </FormattedText>
            <FormattedText>
                Interest-Based Advertising in Canada. For more information about interest-based ads,
                or to opt out of having your web-browsing information used for behavioral
                advertising purposes, please visit{' '}
                {
                    <Link href="https://youradchoices.ca/choices">
                        https://youradchoices.ca/choices
                    </Link>
                }
                .
            </FormattedText>
            <FormattedText>
                To learn more about interest-based advertising in mobile apps and to opt out of this
                type of advertising by third-party advertising companies that participate in the
                DAAC’s AppChoices tool, please download the version of AppChoices for your device at{' '}
                {
                    <Link href="https://youradchoices.ca/appchoices">
                        https://youradchoices.ca/appchoices/
                    </Link>
                }
                .
            </FormattedText>
        </Box>
    );
};
