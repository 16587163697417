import React from 'react';
import { Box, Text, HStack, Flex, Image } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { fonts } from '../../../../common/utils';
import SubscriptionPentagon from '../../../../assets/img/subscribe_pentagon.png';
import { CustomButton } from '../../../../common/components';

interface IFreeTrialProps {
    subscribedTo: string;
    onUpgrade: () => void;
}

export const FreeTrial: React.FC<IFreeTrialProps> = ({ subscribedTo, onUpgrade }) => (
    <Box bg="#4FD1C5" borderRadius="26px" p="30px" position="relative" overflow="hidden" mb="30px">
        <Flex align="center" justify="space-between">
            <Text
                fontSize="17px"
                lineHeight="23px"
                fontWeight={500}
                color="white"
                fontFamily={fonts.avenirRoman}
            >
                Your Subscription
            </Text>
            <Text
                fontSize="17px"
                lineHeight="23px"
                fontWeight={500}
                color="white"
                fontFamily={fonts.avenirRoman}
            >
                (finish {dayjs.utc(subscribedTo).format('Do of MMMM')})
            </Text>
        </Flex>
        <HStack align="baseline" my="18px">
            <Text
                fontSize="36px"
                color="white"
                fontWeight={500}
                fontFamily={fonts.avenirBlack}
                lineHeight="46px"
            >
                Free Trial
            </Text>
            {/* <Text fontSize="17px" color="white" lineHeight="23px" fontFamily={fonts.avenirRoman}>
                /45 days
            </Text> */}
        </HStack>
        <Flex justifyContent="space-between" align="center">
            <Text
                fontSize="17px"
                lineHeight="23px"
                fontWeight={500}
                color="white"
                fontFamily={fonts.avenirRoman}
            >
                (finish {dayjs.utc(subscribedTo).format('Do of MMMM')})
            </Text>
            <CustomButton
                text="Upgrade"
                bgColor="transparent"
                height="50px"
                borderColor="white"
                width="160px"
                onClick={onUpgrade}
            />
        </Flex>
        <Image src={SubscriptionPentagon} position="absolute" top="0" left="200px" />
    </Box>
);
