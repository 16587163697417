import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const loginSchema = yup.object().shape({
    email: yup.string().required(ErrorMessages.REQUIRED).email(ErrorMessages.EMAIL_INVALID),
    password: yup.string().required(ErrorMessages.REQUIRED),
});

export const loginResolver = yupResolver(loginSchema);
