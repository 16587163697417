import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { HostOpenHouseListingItem } from './HostOpenHouseListingItem';
import { InfiniteScroll, Loader } from '../../../common/components';
import { ListingsType, OpenHouseDetailsModalOpen } from '../../../common/types';

interface IListingSectionProps {
    loading: boolean;
    loadMoreLoading: boolean;
    items: ListingsType[];
    name?: string;
    onOpenModal: OpenHouseDetailsModalOpen;
}

export const HostOpenHouseListingSection: React.FC<IListingSectionProps> = ({
    items,
    onOpenModal,
    loadMoreLoading,
    loading,
    name,
}) => (
    <Box w="100%" pb="30px">
        {loading ? (
            <Loader spinnerSize="md" centerHeight="100%" />
        ) : (
            <InfiniteScroll>
                {name && (
                    <Text
                        color="brand"
                        fontSize="18px"
                        fontWeight={600}
                        textTransform="uppercase"
                        mt="20px"
                    >
                        {name}
                    </Text>
                )}
                {items.length ? (
                    items.map(item => (
                        <HostOpenHouseListingItem
                            onOpenManage={value => onOpenModal(value, item, true, true)}
                            onOpenModal={value => onOpenModal(value, item, false, false)}
                            key={item.id}
                            isMyListings={true}
                            {...item}
                        />
                    ))
                ) : (
                    <Center height="100%" mb="50px">
                        <Text color="brand">No items</Text>
                    </Center>
                )}
                {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
            </InfiniteScroll>
        )}
    </Box>
);
