import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../common/api/api.base';
import {
    getCurrentStates,
    getCurrentStatesError,
    getCurrentStatesSuccess,
} from '../actions/order.actions';
import { IResponseWithCustomValue, StatesType } from '../../common/types';

function* workerGetCurrentStates() {
    try {
        const result: IResponseWithCustomValue<StatesType> = yield call(
            Api.get,
            '/api/order-page/states',
            {},
        );
        console.log('getCurrentStates', result);
        if (result.success) {
            yield put(getCurrentStatesSuccess(result));
        } else {
            yield put(getCurrentStatesError(result.errors));
        }
    } catch (error) {
        console.log('getStatesError', error);
    }
}

export default function* watchOrderSaga() {
    yield takeEvery(getCurrentStates.type, workerGetCurrentStates);
}
