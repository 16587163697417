import React from 'react';
import { colors } from '../../common/constants';

export const EditDwollaIcon: React.FC = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.39844 9.3999L1.04488 9.04635L0.898438 9.1928V9.3999H1.39844ZM9.39844 1.3999L9.75199 1.04635C9.55673 0.851087 9.24015 0.851087 9.04488 1.04635L9.39844 1.3999ZM12.5984 4.5999L12.952 4.95346C13.1473 4.75819 13.1473 4.44161 12.952 4.24635L12.5984 4.5999ZM4.59844 12.5999V13.0999H4.80554L4.95199 12.9535L4.59844 12.5999ZM1.39844 12.5999H0.898438C0.898438 12.876 1.1223 13.0999 1.39844 13.0999V12.5999ZM1.75199 9.75346L9.75199 1.75346L9.04488 1.04635L1.04488 9.04635L1.75199 9.75346ZM9.04488 1.75346L12.2449 4.95346L12.952 4.24635L9.75199 1.04635L9.04488 1.75346ZM12.2449 4.24635L4.24488 12.2463L4.95199 12.9535L12.952 4.95346L12.2449 4.24635ZM4.59844 12.0999H1.39844V13.0999H4.59844V12.0999ZM1.89844 12.5999V9.3999H0.898438V12.5999H1.89844Z"
            fill={colors.brand}
        />
    </svg>
);
