import { call, put, takeEvery } from '@redux-saga/core/effects';
import API from '../../../common/api/api.base';
import { IResponseWithCustomValue, IResponseWithoutValue } from '../../../common/types';
import { ActivitiesResponseType, RequestAddresses, RequestDurations } from '../types';
import {
    addFeedbackError,
    addFeedbackRequest,
    addFeedbackSuccess,
    confirmWorkError,
    confirmWorkRequest,
    confirmWorkSuccess,
    getAgentActivityError,
    getAgentActivityRequest,
    getAgentActivitySuccess,
    getCurrentActivityError,
    getCurrentActivityRequest,
    getCurrentActivitySuccess,
    getRequestAddressesError,
    getRequestAddressesRequest,
    getRequestAddressesSuccess,
    getRequestByIdError,
    getRequestByIdRequest,
    getRequestByIdSuccess,
    finishWorkRequest,
    finishWorkSuccess,
    finishWorkError,
    getRequestDurationsRequest,
    getRequestDurationsSuccess,
} from './actions';
import { RequestInfoType } from '../../Requests/types';

function* workerGetCurrentActivity(action: ReturnType<typeof getCurrentActivityRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            activity: ActivitiesResponseType[];
            totalCount: number;
        }> = yield call(
            API.get,
            `/api/request/get-current-activity?${action.payload.categoryIds
                .map(elem => `CategoryIds=${elem}&`)
                .join('')}Date=${action.payload.date}&PageNumber=${
                action.payload.pageNumber
            }&PageSize=${action.payload.pageSize}`,
            {},
        );
        if (result.success) {
            yield put(
                getCurrentActivitySuccess({
                    pageNumber: action.payload.pageNumber,
                    ...result.value,
                }),
            );
        } else {
            yield put(getCurrentActivityError());
        }
    } catch (err) {
        yield put(getCurrentActivityError());
    }
}

function* workerGetAgentActivity(action: ReturnType<typeof getAgentActivityRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            activity: ActivitiesResponseType[];
            totalCount: number;
        }> = yield call(
            API.get,
            `/api/request/get-agent-activity?${action.payload.categoryIds
                .map(elem => `CategoryIds=${elem}&`)
                .join('')}Date=${action.payload.date}&PageNumber=${
                action.payload.pageNumber
            }&PageSize=${action.payload.pageSize}`,
            {},
        );
        if (result.success) {
            yield put(
                getAgentActivitySuccess({
                    pageNumber: action.payload.pageNumber,
                    ...result.value,
                }),
            );
        } else {
            yield put(getAgentActivityError());
        }
    } catch (err) {
        yield put(getAgentActivityError());
    }
}

function* workerConfirmWork(action: ReturnType<typeof confirmWorkRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/request/confirm-work/${action.payload.workId}`,
            action.payload.fundingSourceId,
            { 'Content-Type': 'application/json' },
        );
        if (result.success) {
            yield put(confirmWorkSuccess());
        } else {
            yield put(confirmWorkError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerConfirmWork err');
    }
}

function* workerGetRequestAddresses(action: ReturnType<typeof getRequestAddressesRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            requestAddresses: RequestAddresses[];
            userName: string;
            pricePerDoor: null | number;
        }> = yield call(API.get, `/api/request/get-request-addresses/${action.payload.id}`, {});
        if (result.success) {
            yield put(getRequestAddressesSuccess(result.value));
        } else {
            yield put(getRequestAddressesError());
        }
    } catch (err) {
        yield put(getRequestAddressesError());
    }
}

function* workerAddFeedback(action: ReturnType<typeof addFeedbackRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/request/add-feedback`,
            action.payload,
        );
        if (result.success) {
            yield put(addFeedbackSuccess());
        } else {
            yield put(addFeedbackError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerAddFeedback errors');
        // yield put(addFeedbackError());
    }
}

function* workerGetRequestById(action: ReturnType<typeof getRequestByIdRequest>) {
    try {
        const result: IResponseWithCustomValue<{ requestModel: RequestInfoType }> = yield call(
            API.get,
            `/api/request/${action.payload.id}`,
            {},
        );
        if (result.success) {
            yield put(getRequestByIdSuccess(result.value));
        } else {
            yield put(getRequestByIdError());
        }
    } catch (err) {
        yield put(getRequestByIdError());
    }
}

function* workerFinishWork(action: ReturnType<typeof finishWorkRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            '/api/request/finish-work',
            action.payload,
        );
        console.log('finishWorkSuccess', result, action);
        if (result.success) {
            yield put(finishWorkSuccess());
        } else {
            yield put(finishWorkError(result.errors));
            console.log('finishWorkError', result);
        }
    } catch (error) {
        console.log(error, 'finishWork error');
    }
}

function* workerGetRequestDurations(action: ReturnType<typeof getRequestDurationsRequest>) {
    try {
        const result: IResponseWithCustomValue<RequestDurations> = yield call(
            API.get,
            `/api/request/get-request-duration/${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getRequestDurationsSuccess(result.value));
        }
    } catch (error) {
        console.log('workerGetRequestDurations error', error);
    }
}

export default function* watchActivitySaga() {
    yield takeEvery(getCurrentActivityRequest.type, workerGetCurrentActivity);
    yield takeEvery(getAgentActivityRequest.type, workerGetAgentActivity);
    yield takeEvery(confirmWorkRequest.type, workerConfirmWork);
    yield takeEvery(getRequestAddressesRequest.type, workerGetRequestAddresses);
    yield takeEvery(addFeedbackRequest.type, workerAddFeedback);
    yield takeEvery(getRequestByIdRequest.type, workerGetRequestById);
    yield takeEvery(finishWorkRequest.type, workerFinishWork);
    yield takeEvery(getRequestDurationsRequest.type, workerGetRequestDurations);
}
