import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import { Notifications } from './Notifications';
import { handlePressNotification } from '../../common/utils';
import { getNotificationsRequest } from './store/actions';

export const NotificationsContainer: React.FC = () => {
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);

    const { items, totalCount, userNotificationsLastReadTime, loading } = useSelector(
        (state: RootStore) => state.notifications,
    );

    useEffect(() => {
        dispatch(getNotificationsRequest({ pageSize: 9, pageNumber: currentPage }));
    }, [currentPage]);

    return (
        <Notifications
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            userNotificationsLastReadTime={userNotificationsLastReadTime}
            items={items}
            loading={loading}
            totalCount={totalCount}
            handlePressNotification={item => handlePressNotification(item, dispatch)}
        />
    );
};
