import React from 'react';
import { Box, Container, Flex } from '@chakra-ui/react';
import { CheckUnsubscribeIcon, LogoIcon } from '../../assets/icons';
import { Link } from 'react-router-dom';
import { Typography } from '../../common/components/blocks/Typography/Typography';
import { CustomButton } from '../../common/components';

interface IUnsubscribeProps {
    loadingUnsubscribeEmail: boolean;
    isUnsubscribed: boolean;
    onUnsubscribeEmail: () => void;
}

export const Unsubscribe: React.FC<IUnsubscribeProps> = ({
    loadingUnsubscribeEmail,
    isUnsubscribed,
    onUnsubscribeEmail,
}) => (
    <Box minH={'100vh'} backgroundColor="brand" pt={'80px'}>
        <Container maxWidth={'container.md'}>
            <Flex flexDirection={'column'} alignItems={'center'}>
                <Link to={'/ '} replace>
                    <LogoIcon />
                </Link>
                <Flex
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    mt={'80px'}
                    w={'100%'}
                    h={'40vh'}
                    minW={'360px'}
                    borderRadius={'20px'}
                    backgroundColor={'white'}
                >
                    {isUnsubscribed ? (
                        <>
                            <CheckUnsubscribeIcon />
                            <Typography
                                fontSize={{ base: '14px', md: '18px' }}
                                mt={'30px'}
                                weight={600}
                                color={'#2C335C'}
                            >
                                You have successfully been unsubscribed
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography
                                weight={600}
                                fontSize={{ base: '14px', md: '18px' }}
                                color={'#2C335C'}
                            >
                                Are you sure you want to unsubscribe ?
                            </Typography>
                            <Box mt={'28px'}>
                                <CustomButton
                                    onClick={() => onUnsubscribeEmail()}
                                    isLoading={loadingUnsubscribeEmail}
                                    width={'260px'}
                                    height={{ base: '50px', md: '62px' }}
                                    bgColor="brand"
                                    borderRadius={'8px'}
                                    text={'Unsubscribe'}
                                    fontSize={{ base: '14px', md: '18px' }}
                                />
                            </Box>
                        </>
                    )}
                </Flex>
            </Flex>
        </Container>
    </Box>
);
