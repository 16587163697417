import React from 'react';
import { Text } from '@chakra-ui/react';
import { colors } from '../../constants';
import { fonts } from '../../utils';

const textProps = {
    fontFamily: fonts.montserrat,
    color: colors.brand,
    fontSize: '16px',
    lineHeiight: '26px',
    mb: '20px',
};

export const FormattedText: React.FC<{ tab?: boolean }> = props => (
    <Text {...textProps} marginLeft={props.tab ? '30px' : '0'}>
        {props.children}
    </Text>
);

export const FormattedTextBold: React.FC = props => (
    <Text {...textProps} fontWeight="bold">
        {props.children}
    </Text>
);
