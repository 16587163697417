import React from "react";
import {
    Box,
    Text,
} from '@chakra-ui/react';
//components
import { formatAMPM } from '../../../../../common/utils/formatAMPM';
import { IChatBody } from '../../../types';
import { Message } from "@twilio/conversations/lib/message";
import { MessageBubble } from '../MessageBubble/MessageBubble';


export const ChatBody: React.FC<IChatBody> = ({
    messages,
    userId,
}) => (
    <>
        {
            messages.map((item: any, index: number) => {

                const body = item.state?.body
                const isMy = Number(item.state?.author) === userId
                const time = formatAMPM(item.state?.timestamp)

                return (
                    <MessageBubble
                        key={index}
                        body={body}
                        time={time}
                        item={item}
                        isMy={isMy}
                    />
                )
            })
        }
    </>
)