import React from 'react';
import { Box } from '@chakra-ui/react';
import { fonts } from '../../../../utils';
import { PricingFeatureItemIcon, PricingFeatureItemIconLighter } from '../../../../../assets/icons';

interface IFeatureItem {
    title: string;
    period?: string;
    color?: string;
    bgColor: string;
    icon?: string;
}

export const FeatureItem: React.FC<IFeatureItem> = ({ title, bgColor, color, icon }) => (
    <Box m="10px 0" d="flex" alignItems="center">
        <PricingFeatureItemIcon bgColor={bgColor} />
        <Box fontFamily={fonts.montserrat} fontSize="15px" color={color} ml="5px">
            {title}
        </Box>
    </Box>
);
