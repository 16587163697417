import React from 'react';
import { colors } from '../../common/constants';

export const DollarInputIcon: React.FC = () => (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.47888 9.944C7.47888 10.456 7.35088 10.9467 7.09488 11.416C6.83888 11.8747 6.46021 12.2587 5.95888 12.568C5.46821 12.8773 4.87621 13.0533 4.18288 13.096V14.392H3.31888V13.096C2.34821 13.0107 1.55888 12.7013 0.950875 12.168C0.342875 11.624 0.0335417 10.936 0.0228751 10.104H1.57488C1.61754 10.552 1.78288 10.9413 2.07088 11.272C2.36954 11.6027 2.78554 11.8107 3.31888 11.896V7.896C2.60421 7.71467 2.02821 7.528 1.59088 7.336C1.15354 7.144 0.780208 6.84533 0.470875 6.44C0.161542 6.03467 0.00687504 5.49067 0.00687504 4.808C0.00687504 3.944 0.305542 3.22933 0.902875 2.664C1.51088 2.09867 2.31621 1.784 3.31888 1.72V0.391999H4.18288V1.72C5.08954 1.79467 5.82021 2.088 6.37488 2.6C6.92954 3.10133 7.24954 3.75733 7.33488 4.568H5.78288C5.72954 4.19467 5.56421 3.85867 5.28688 3.56C5.00954 3.25067 4.64154 3.048 4.18288 2.952V6.856C4.88688 7.03733 5.45754 7.224 5.89488 7.416C6.34288 7.59733 6.71621 7.89067 7.01488 8.296C7.32421 8.70133 7.47888 9.25067 7.47888 9.944ZM1.49488 4.728C1.49488 5.25067 1.64954 5.65067 1.95888 5.928C2.26821 6.20533 2.72154 6.43467 3.31888 6.616V2.92C2.76421 2.97333 2.32154 3.15467 1.99088 3.464C1.66021 3.76267 1.49488 4.184 1.49488 4.728ZM4.18288 11.912C4.75888 11.848 5.20688 11.64 5.52688 11.288C5.85754 10.936 6.02288 10.5147 6.02288 10.024C6.02288 9.50133 5.86288 9.10133 5.54288 8.824C5.22288 8.536 4.76954 8.30667 4.18288 8.136V11.912Z"
            fill={colors.secondaryFontDarker}
        />
    </svg>
);
