import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Button,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BackArrow, CloseButton } from '../../../assets/icons';
import { CustomInput } from '../../../common/components';
import { colors } from '../../../common/constants';
import { ChangeFullNamePayload } from '../types';

interface IEditNameModal {
    isOpen: boolean;
    onClose: () => void;
    changeFullName: (v: ChangeFullNamePayload) => void;
    currentUser: any;
    isFieldChanged: boolean;
    clear: () => void;
}

export const EditNameModal: React.FC<IEditNameModal> = ({
    isOpen,
    onClose,
    changeFullName,
    currentUser,
    isFieldChanged,
    clear,
}) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({});

    const onSubmit = (data: { fullName: string }) => {
        let fullName = [];
        if (data.fullName.includes(' ')) {
            fullName.push(...data.fullName.split(' '));
        } else {
            fullName.push(data.fullName);
        }
        const payload = {
            firstName: fullName[0],
            lastName: fullName.length > 1 ? fullName[1] : currentUser.lastName,
            email: currentUser.email,
            phoneNumber: currentUser.phoneNumber,
        };
        changeFullName(payload);
    };

    useEffect(() => {
        if (isFieldChanged && isOpen) {
            onClose();
            clear();
        }
    }, [isFieldChanged]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                w={'71.5vw'}
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Box
                    d="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <Box as="button" onClick={() => onClose()}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text>Edit Name</Text>
                    </ModalHeader>
                    <Box as="button" onClick={() => onClose()}>
                        <CloseButton />
                    </Box>
                </Box>
                <ModalBody d="flex" w="68vw" flexDirection="column" overflowY="auto">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex direction="column" align="center" mt="30px">
                            <Flex w="30%" mb="15px">
                                <CustomInput
                                    label="Enter New Name"
                                    register={{ ...register('fullName') }}
                                    width="100%"
                                />
                            </Flex>
                            <Button variant="main" width="30%" type="submit">
                                Confirm
                            </Button>
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
