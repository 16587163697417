import React, { useState } from 'react';
import { GetFundingSourcesResponse } from '../../../../../pages/Settings/pages/Payments/types';
import { fundingSourceDefault } from '../../../../../pages/Settings/pages/Payments/consts';
import { Box, Button, Flex, HStack, VStack, ModalBody, Text } from '@chakra-ui/react';
import { PaymentDropDown } from '../../../../../pages/Settings/pages/Payments/components';
import { Header } from './Header';
import { RequestInfoType } from '../../../../../pages/Requests/types';
import { roundAplicantPrices } from '../../../../utils';

interface IProps {
    fundingSources: Array<GetFundingSourcesResponse>;
    loading: boolean;
    onClose: () => void;
    requestInfo: RequestInfoType;
    fee: number;
    duration: number;
    pricePerHour: number;
    onConfirm: (pmntVal: number, pmntMeth: GetFundingSourcesResponse) => void;
}

export const PaymentConfirmationHomeInspection: React.FC<IProps> = ({
    fundingSources,
    loading,
    onClose,
    requestInfo,
    duration,
    fee,
    onConfirm,
    pricePerHour,
}) => {
    const [selectedWallet, setSelectedWallet] =
        useState<GetFundingSourcesResponse>(fundingSourceDefault);
    const [notSelectedWalletError, setNotSelectedWalletError] = useState('');

    const total = duration * pricePerHour + fee;

    const confirmAndPay = () => {
        if (selectedWallet.dwollaId) {
            onConfirm(total, selectedWallet);
        } else {
            setNotSelectedWalletError('Please choose your payment method');
        }
    };

    return (
        <>
            <Header onClose={onClose} title="Payment Confirmation" />
            <ModalBody overflowY="auto">
                <Flex flexDirection={'column'} h={'full'}>
                    <PaymentDropDown
                        items={fundingSources}
                        setValue={(value: GetFundingSourcesResponse) => {
                            setSelectedWallet(value);
                            setNotSelectedWalletError('');
                        }}
                        value={selectedWallet}
                        loading={loading}
                        error={notSelectedWalletError}
                    />
                    <VStack
                        borderRadius="8px"
                        w={'100%'}
                        alignItems={'stretch'}
                        p={'30px'}
                        bg={'inputBackground'}
                        mt={'26px'}
                        spacing={'20px'}
                        mb={'26px'}
                    >
                        {/*requestTypeName*/}
                        <Text color="brand" fontSize="16px" fontWeight={700} lineHeight="22px">
                            {requestInfo.requestTypeName}
                        </Text>
                        {/*Duration*/}
                        <Box>
                            <Text
                                fontSize="13px"
                                color="primary"
                                fontWeight={400}
                                lineHeight="16px"
                            >
                                Duration:
                            </Text>
                            <HStack>
                                <Flex
                                    justifyContent={'space-between'}
                                    w={'140px'}
                                    px={'18px'}
                                    py={'13px'}
                                    bg={'white'}
                                    borderRadius={'80px'}
                                >
                                    <Text>{parseInt(duration.toString(), 10)}</Text>
                                    <Text color="secondaryFontDarker">h</Text>
                                </Flex>
                                <Flex
                                    justifyContent={'space-between'}
                                    w={'140px'}
                                    px={'18px'}
                                    py={'13px'}
                                    bg={'white'}
                                    borderRadius={'80px'}
                                >
                                    <Text>{duration % 1 === 0 ? 0 : 30}</Text>
                                    <Text color="secondaryFontDarker">m</Text>
                                </Flex>
                            </HStack>
                        </Box>

                        {/*Price Per Hour:*/}
                        <Flex alignItems={'center'} justify={'space-between'}>
                            <Text fontSize="13px" color="brand" fontWeight={400} lineHeight="20px">
                                Price Per Hour:
                            </Text>
                            <Text
                                fontSize="16px"
                                color="secondaryFontDarker"
                                fontWeight={500}
                                lineHeight="24px"
                            >
                                ${roundAplicantPrices(pricePerHour)}
                            </Text>
                        </Flex>
                        {/*Fee*/}
                        <Flex alignItems={'center'} justify={'space-between'}>
                            <Text fontSize="13px" color="brand" fontWeight={400} lineHeight="20px">
                                Fee:
                            </Text>
                            <Text
                                fontSize="16px"
                                color="secondaryFontDarker"
                                fontWeight={500}
                                lineHeight="24px"
                            >
                                ${roundAplicantPrices(fee)}
                            </Text>
                        </Flex>
                        {/*Estimated Total Cost:*/}
                        <Flex alignItems={'center'} justify={'space-between'}>
                            <Text fontSize="13px" color="brand" fontWeight={600} lineHeight="20px">
                                Estimated Total Cost:
                            </Text>
                            <Text fontSize="19px" fontWeight={600} color="brand" lineHeight="28px">
                                ${roundAplicantPrices(total)}
                            </Text>
                        </Flex>
                    </VStack>
                    <Flex mt="30px" alignItems={'center'} justifyContent={'space-between'}>
                        <Button onClick={onClose} w={'47%'} variant={'outlinePrimary'}>
                            Cancel
                        </Button>
                        <Button onClick={confirmAndPay} w={'47%'} variant={'primary'}>
                            Confirm & Pay
                        </Button>
                    </Flex>
                </Flex>
            </ModalBody>
        </>
    );
};
