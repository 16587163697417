import React from 'react';
import {
    Flex,
    Avatar,
    VStack,
    chakra, IconButton,
} from '@chakra-ui/react';
import {
    PhoneWithWavesIcon,
    EmailIcon,
    DropdownArrowIcon,
} from '../../../../../../../assets/icons';
import { getAvatarPicture } from '../../../../../../../common/utils';
import { Typography } from "../../../../../../../common/components/blocks/Typography/Typography";
import {SearchAgentByAreaResponse, ToggleUserProfileModalPayload} from "../../../../../../../common/types";

interface IAgentItem {
    item: SearchAgentByAreaResponse
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
}

export const AgentItem: React.FC<IAgentItem> = ({
    item,
    onOpenProfileImage
}) => (
    <Flex
        borderRadius={'26px'}
        bg={'inputBackground'}
        px={'16px'}
        py={'20px'}
        alignItems={'center'}
        justifyContent={'space-between'}
    >
         <Flex>
            <Avatar
                boxSize="72px"
                src={getAvatarPicture(item.logo)}
                mr="35px"
                name={`${item.firstName} ${item.lastName}`}
            />
            <VStack alignItems={'stretch'} spacing={'3px'}>
                <Typography size={13} weight={700}>{`${item.firstName} ${item.lastName}`}</Typography>
                <Typography size={13} weight={500}>{item.agency.name}</Typography>
                <Flex alignItems={'center'}>
                    <PhoneWithWavesIcon fill={'#868AA4'} />
                    <Typography ml={'5px'} size={12}>{item.phoneNumber}</Typography>
                </Flex>
                <Flex alignItems={'center'}>
                    <EmailIcon />
                    <Typography ml={'5px'} size={12}>{item.email}</Typography>
                </Flex>
            </VStack>

        </Flex>
        <IconButton
            onClick={() => {
                onOpenProfileImage?.({
                    userId: item.id, value: true
                })
            }}
            bg="transparent"
            alignSelf="center"
            aria-label="Navigate to profile image"
            icon={
                <chakra.span transform="rotate(-90deg)">
                    <DropdownArrowIcon />
                </chakra.span>
            }
        />
        </Flex>
        )
