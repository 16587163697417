import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, Stack, IconButton, Input } from '@chakra-ui/react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
    CustomButton,
    CustomInput,
    CustomSearchableSelect,
} from '../../../../../common/components';
import { Link } from 'react-router-dom';
import { SignUpFillDataForm, ISignUpFillDataProps, SignUpFillDataPayload } from '../../types';
import { signUpFillDataResolver } from '../../../../../common/schemes';
import { states } from '../../../../../common/constants';
import { BackArrow } from '../../../../../assets/icons';
import { useHistory } from 'react-router-dom';
import { AddNewAgencyModal } from '../../../../../common/components/modals/AddAgencyModal/AddAgencyModalContainer';

export const SignUpFillData: React.FC<ISignUpFillDataProps> = ({
    loading,
    stateErrors,
    onFillData,
    accessToken,
    onGetAgencies,
    agencies,
    addAgencyModal,
    onAddNewAgency,
    addNewAgencyLoading,
    agencyDraftData,
    handleNewAgencyModal,
    onRemoveDraftAgency,
    isAddedDraftAgency,
}) => {
    const [agencyValue, setAgencyValue] = useState('');

    const history = useHistory();
    const {
        formState: { errors },
        handleSubmit,
        register,
        watch,
        control,
        setValue,
        clearErrors,
    } = useForm<SignUpFillDataForm>({ resolver: signUpFillDataResolver });

    const state = watch().state?.split(',');

    useEffect(() => {
        state && state[0] !== '' && onGetAgencies({ stateId: state[0], token: accessToken });
    }, [watch().state]);

    const onSubmit: SubmitHandler<SignUpFillDataForm> = data => {
        const payload: SignUpFillDataPayload = {
            payload: {
                state: state[1],
                agencyId: isAddedDraftAgency ? null : Number(agencyValue),
                license: data.licence,
                mlsId: data.mlsID.replace(/[^\d]/g, ''),
                password: data.password,
                confirmPassword: data.repeatPassword,
                agencyDraftId: agencyDraftData.id ? agencyDraftData.id : null,
            },
            token: accessToken,
        };
        onFillData(payload);
    };

    const handleTextInput = (name: any, text: string | null | number) => {
        setValue(name, text);
        clearErrors(name);
    };

    useEffect(() => {
        if (isAddedDraftAgency) {
            handleTextInput('agencyId', null);
        }
    }, [isAddedDraftAgency]);

    return (
        <Box
            borderRadius={20}
            mt={{ base: '30px', lg: '55px' }}
            w="100%"
            backgroundColor="#fff"
            position="relative"
        >
            <IconButton
                aria-label="Back"
                variant="unstyled"
                position="absolute"
                top={{ base: '27px', lg: '30px' }}
                left={{ base: '15px', lg: '30px' }}
                d="flex"
                alignItems="center"
                onClick={() => history.goBack()}
            >
                <BackArrow />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)} key="fill-data">
                <Flex
                    px={{ base: '20px', md: '20%' }}
                    h="100%"
                    flexDirection="column"
                    justifyContent="space-around"
                >
                    <Text
                        fontWeight={900}
                        fontSize={{ base: '18px', lg: '32px' }}
                        color="brand"
                        mt={{ base: '35px', lg: '50px' }}
                        mb="20px"
                        ml={{ base: '50px', md: 0 }}
                    >
                        Details Of Your Affiliation
                    </Text>
                    <Stack spacing="20px">
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <CustomSearchableSelect
                                    onChange={field.onChange}
                                    label={'State of License *'}
                                    items={states}
                                    error={errors.state?.message}
                                />
                            )}
                            name={'state'}
                        />
                        {isAddedDraftAgency ? (
                            <CustomInput
                                placeholder="New Agency (Draft)"
                                rightIcon={
                                    <Box onClick={onRemoveDraftAgency} mr="50px">
                                        <Text color="black">Remove</Text>
                                    </Box>
                                }
                                defaultValue={agencyDraftData.name}
                                disabled={true}
                                label="New Agency (Draft)"
                            />
                        ) : (
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <CustomSearchableSelect
                                        disabled={!state}
                                        onChange={(value: any) => {
                                            setAgencyValue(value);
                                            handleTextInput('agencyId', Number(value));
                                        }}
                                        // value={agencyValue}
                                        label={'Brokerage *'}
                                        items={agencies.map(ag => ({
                                            value: ag.id,
                                            label: ag.name,
                                        }))}
                                        error={errors.agency?.message}
                                        height="50px"
                                        onOpenAgencyModal={() => handleNewAgencyModal(true)}
                                        isAgencyChoise
                                    />
                                )}
                                name={'agency'}
                            />
                        )}
                        <CustomInput
                            label="Real Estate License Number *"
                            width="100%"
                            placeholder="Enter Licence #"
                            register={{ ...register('licence') }}
                            errors={errors.licence}
                            isBorder={false}
                            fontSize="14px"
                            textSize="16px"
                            height="50px"
                        />
                        {/*<CustomInput*/}
                        {/*    label="Find My Agent Account In Zootpro Database *"*/}
                        {/*    width="100%"*/}
                        {/*    placeholder="Enter Licence #"*/}
                        {/*    register={{ ...register('licence') }}*/}
                        {/*    errors={errors.licence}*/}
                        {/*    isBorder={false}*/}
                        {/*    fontSize="14px"*/}
                        {/*    textSize="16px"*/}
                        {/*    height="50px"*/}
                        {/*/>*/}
                        <CustomInput
                            label="MLS ID # (Optional)"
                            width="100%"
                            placeholder="Enter MLS ID #"
                            register={{ ...register('mlsID') }}
                            errors={errors.mlsID}
                            isBorder={false}
                            fontSize="14px"
                            textSize="16px"
                            height="50px"
                        />
                        <CustomInput
                            label="Password *"
                            width="100%"
                            placeholder="Password"
                            register={{ ...register('password') }}
                            errors={errors.password}
                            type="password"
                            isPassword
                            isBorder={false}
                            fontSize="14px"
                            textSize="16px"
                            height="50px"
                        />
                        <CustomInput
                            label="Re-enter Password *"
                            width="100%"
                            placeholder="Repeat Password"
                            register={{ ...register('repeatPassword') }}
                            type="password"
                            isPassword
                            errors={errors.repeatPassword}
                            isBorder={false}
                            fontSize="14px"
                            textSize="16px"
                            height="50px"
                        />
                        <Box>
                            <CustomButton
                                type="submit"
                                isLoading={loading}
                                loadingText="Signing up..."
                                text="Next"
                                width="100%"
                                height="60px"
                                bgColor="brand"
                                boxShadow="0px 3px 3px grey"
                            />
                        </Box>
                    </Stack>
                    <Flex
                        w="100%"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        m="30px 0"
                    >
                        {/* <Text fontWeight="400" color="secondaryFontDarker" fontSize="16px">
                            Already Have An Account?&nbsp;
                        </Text>
                        <Link to="/auth/signin/login" replace>
                            <Text
                                fontWeight="400"
                                color="brand"
                                textDecoration="underline"
                                fontSize="16px"
                            >
                                Sign In
                            </Text>
                        </Link> */}
                    </Flex>
                </Flex>
            </form>
            <AddNewAgencyModal
                stateId={state && state[0] !== '' ? Number(state[0]) : 0}
                addNewAgencyLoading={addNewAgencyLoading}
                onAddNewAgency={onAddNewAgency}
                isOpen={addAgencyModal}
                onClose={() => handleNewAgencyModal(false)}
            />
        </Box>
    );
};
