import React from 'react';

export const HomeWithDoorIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.12137 0.742676L6.44677 0.363047C6.25952 0.202552 5.98322 0.202552 5.79598 0.363047L6.12137 0.742676ZM0.744141 5.35173L0.418745 4.9721L0.244141 5.12176V5.35173H0.744141ZM4.58502 11.4971V11.9971C4.86116 11.9971 5.08502 11.7733 5.08502 11.4971H4.58502ZM7.65773 11.4971H7.15773C7.15773 11.7733 7.38158 11.9971 7.65773 11.9971V11.4971ZM11.4986 5.35173H11.9986V5.12176L11.824 4.9721L11.4986 5.35173ZM1.51232 11.9971H4.58502V10.9971H1.51232V11.9971ZM11.824 4.9721L6.44677 0.363047L5.79598 1.1223L11.1732 5.73136L11.824 4.9721ZM5.79598 0.363047L0.418745 4.9721L1.06954 5.73136L6.44677 1.1223L5.79598 0.363047ZM5.08502 11.4971V9.19261H4.08502V11.4971H5.08502ZM7.15773 9.19261V11.4971H8.15773V9.19261H7.15773ZM7.65773 11.9971H10.7304V10.9971H7.65773V11.9971ZM11.9986 10.729V5.35173H10.9986V10.729H11.9986ZM0.244141 5.35173V10.729H1.24414V5.35173H0.244141ZM6.12137 8.15626C6.69373 8.15626 7.15773 8.62025 7.15773 9.19261H8.15773C8.15773 8.06797 7.24602 7.15626 6.12137 7.15626V8.15626ZM6.12137 7.15626C4.99673 7.15626 4.08502 8.06797 4.08502 9.19261H5.08502C5.08502 8.62025 5.54901 8.15626 6.12137 8.15626V7.15626ZM10.7304 11.9971C11.4308 11.9971 11.9986 11.4294 11.9986 10.729H10.9986C10.9986 10.8771 10.8785 10.9971 10.7304 10.9971V11.9971ZM1.51232 10.9971C1.36421 10.9971 1.24414 10.8771 1.24414 10.729H0.244141C0.244141 11.4294 0.811922 11.9971 1.51232 11.9971V10.9971Z"
      fill="white"
    />
  </svg>
);
