import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Updates } from './Updates';
import { getPostTags, getReportTypes } from '../../store/actions/directories.actions';
import { RootStore } from '../../store';
import { AddReportPayload, CreatePostPayload, UpdatePostPayload } from './types';
import {
    addReportRequest,
    createPostRequest,
    deletePostRequest,
    getAdvertisingPostsRequest,
    getPostsRequest,
    updatePostRequest,
} from './store/actions';
import { CreateNewPostModal, DetailsPostModal, EditPostModal, ReportPostModal } from './modals';
import { useDelta } from 'react-delta';
import dayjs from 'dayjs';
import { AdvertisingDetailsPostModal } from './modals/AdvertisingDetailsPostModal';

const UpdatesContainer: React.FC = () => {
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(1);
    const [dateFilter, setDateFilter] = useState(0);
    const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
    const [showCreatePostModal, setCreatePostModal] = useState(false);
    const [editPostId, setEditPostId] = useState(0);
    const [showEditPostModal, setEditPostModal] = useState(false);
    const [showDetailsPostModal, setDetailsPostModal] = useState(false);
    const [showAdvertisDetailsPostModal, setAdvertisDetailsPostModal] = useState(false);
    const [detailsPostId, setDetailsPostId] = useState(0);
    const [advertisingDetailsPostId, setAdvertisingDetailsPostId] = useState(0);
    const [reportTypeId, setReportTypeId] = useState(0);
    const [reportPostId, setReportPostId] = useState(0);
    const [showReportPostModal, setReportPostModal] = useState(false);

    const { postTags, reportTypes } = useSelector((state: RootStore) => state.directories);
    const { items: posts, loading: postsLoading } = useSelector(
        (state: RootStore) => state.posts.posts,
    );
    const { items: advertisingPosts, loading: advertisingPostsLoading } = useSelector(
        (state: RootStore) => state.posts.advertisingPosts,
    );
    const { loading: createPostLoading, errors: createPostErrors } = useSelector(
        (state: RootStore) => state.posts.createPost,
    );
    const { loading: deletePostLoading, errors: deletePostErrors } = useSelector(
        (state: RootStore) => state.posts.deletePost,
    );
    const { loading: updatePostLoading, errors: updatePostErrors } = useSelector(
        (state: RootStore) => state.posts.updatePost,
    );
    const { loading: addReportLoading, errors: addReportErrors } = useSelector(
        (state: RootStore) => state.posts.addReport,
    );

    const createPostLoadingDelta = useDelta(createPostLoading);
    const deletePostLoadingDelta = useDelta(deletePostLoading);
    const updatePostLoadingDelta = useDelta(updatePostLoading);
    const addReportLoadingDelta = useDelta(addReportLoading);

    const handleSelectTag = (id: number) => {
        if (!selectedTags.includes(id)) {
            setSelectedTags(prev => [...prev, id]);
        } else {
            setSelectedTags(selectedTags.filter(tagId => tagId !== id));
        }
    };
    const onAddReport = (payload: AddReportPayload) => {
        console.log(payload, 'onAddReport');
        dispatch(addReportRequest(payload));
    };
    const onDeletePost = (id: number) => {
        dispatch(deletePostRequest(id));
    };
    const onCreatePost = (payload: CreatePostPayload) => {
        dispatch(createPostRequest(payload));
    };

    const onUpdatePost = (payload: UpdatePostPayload) => {
        dispatch(updatePostRequest(payload));
    };

    const onReloadData = (category: number) => {
        dispatch(
            getPostsRequest({
                Category: category,
                OrderType: false,
                Tags: [],
                Date: '',
            }),
        );
    };
    useEffect(() => {
        dispatch(getAdvertisingPostsRequest());
        dispatch(getPostTags());
        dispatch(getReportTypes());
    }, []);

    useEffect(() => {
        dispatch(
            getPostsRequest({
                Category: tabIndex,
                OrderType: false,
                Tags: selectedTags,
                Date: dateFilter ? dayjs.utc(dateFilter).toISOString() : '',
            }),
        );
    }, [tabIndex, selectedTags, dateFilter]);

    useEffect(() => {
        if (
            createPostLoadingDelta?.prev &&
            !createPostLoadingDelta.curr &&
            !createPostErrors.length
        ) {
            onReloadData(2);
            setTabIndex(2);
            setCreatePostModal(false);
        }
    }, [createPostLoadingDelta]);

    useEffect(() => {
        if (
            deletePostLoadingDelta?.prev &&
            !deletePostLoadingDelta.curr &&
            !deletePostErrors.length
        ) {
            onReloadData(2);
            setTabIndex(2);
        }
    }, [deletePostLoadingDelta]);

    useEffect(() => {
        if (
            updatePostLoadingDelta?.prev &&
            !updatePostLoadingDelta.curr &&
            !updatePostErrors.length
        ) {
            onReloadData(2);
            setEditPostModal(false);
            setEditPostId(0);
        }
    }, [updatePostLoadingDelta]);
    useEffect(() => {
        if (addReportLoadingDelta?.prev && !addReportLoadingDelta.curr && !addReportErrors.length) {
            setReportPostModal(false);
            setReportTypeId(0);
            setReportPostId(0);
        }
    }, [addReportLoadingDelta]);

    useEffect(() => {
        if (editPostId) {
            setEditPostModal(true);
        }
    }, [editPostId]);

    useEffect(() => {
        if (detailsPostId) {
            setDetailsPostModal(true);
        }
    }, [detailsPostId]);

    useEffect(() => {
        if (advertisingDetailsPostId) {
            setAdvertisDetailsPostModal(true);
        }
    }, [advertisingDetailsPostId]);

    useEffect(() => {
        if (reportPostId && reportTypeId) {
            setReportPostModal(true);
        }
    }, [reportPostId, reportTypeId]);

    return (
        <>
            <Updates
                setReportPostId={setReportPostId}
                setReportTypeId={setReportTypeId}
                setDetailsPostId={setDetailsPostId}
                setAdvertisingDetailsPostId={setAdvertisingDetailsPostId}
                onOpenCreateNewPost={() => setCreatePostModal(true)}
                postsLoading={postsLoading}
                advertisingPostsLoading={advertisingPostsLoading}
                posts={posts}
                advertisingPosts={advertisingPosts}
                onDeletePost={onDeletePost}
                onAddReport={onAddReport}
                reportTypes={reportTypes}
                selectedTags={selectedTags}
                handleSelectTag={handleSelectTag}
                postTags={postTags}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                setEditPostId={setEditPostId}
            />
            <CreateNewPostModal
                createPostLoading={createPostLoading}
                onCreatePost={onCreatePost}
                size={'4xl'}
                isOpen={showCreatePostModal}
                onClose={() => {
                    setCreatePostModal(false);
                }}
                title={'Create New Blast'}
            />
            {/* Edit Post Modal*/}
            <EditPostModal
                updatePostLoading={updatePostLoading}
                onUpdatePost={onUpdatePost}
                editPostId={editPostId}
                size={'4xl'}
                isOpen={showEditPostModal}
                onClose={() => {
                    setEditPostModal(false);
                    setEditPostId(0);
                }}
                title={'Edit Post'}
            />
            {/* Details Post Modal */}
            <DetailsPostModal
                detailsPostId={detailsPostId}
                size={'2xl'}
                isOpen={showDetailsPostModal}
                onClose={() => {
                    setDetailsPostModal(false);
                    setDetailsPostId(0);
                }}
                title={'Blast'}
            />
            <AdvertisingDetailsPostModal
                title={'Blast'}
                isOpen={showAdvertisDetailsPostModal}
                detailsPostId={advertisingDetailsPostId}
                onClose={() => {
                    setAdvertisDetailsPostModal(false)
                    setAdvertisingDetailsPostId(0)
                }}
                size={'2xl'}
            />
            {/*    Report Post Modal*/}
            <ReportPostModal
                addReportLoading={addReportLoading}
                onAddReport={onAddReport}
                reportPostId={reportPostId}
                reportTypeId={reportTypeId}
                size={'md'}
                isOpen={showReportPostModal}
                onClose={() => {
                    setReportTypeId(0);
                    setReportPostId(0);
                    setReportPostModal(false);
                }}
                title={'Report Post'}
            />
        </>
    );
};
export default UpdatesContainer;
