import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Box, Text, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Loader } from '../../../../../common/components';

interface IPlacesTypeaheadProps {
    address: string;
    setAddress: (value: string) => void;
    handleSelect: (value: string) => void;
}

export const PlacesTypeahead: React.FC<IPlacesTypeaheadProps> = ({
    address,
    handleSelect,
    setAddress,
}) => (
    <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={{
            componentRestrictions: { country: ['us', 'ca'] },
        }}
    >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <Box position="absolute" w="90%" left="5%" top="20px">
                <InputGroup>
                    <Input
                        w="100%"
                        bg="#F6F7FB"
                        {...getInputProps({
                            placeholder: 'Search Places...',
                        })}
                        borderRadius="80px"
                        color="brand"
                        fontSize="13px"
                        h="50px"
                    />
                    <InputRightElement h="100%">
                        {loading && <Loader spinnerSize="md" />}
                    </InputRightElement>
                </InputGroup>
                <Box borderRadius="10px" bg="white">
                    {suggestions.map(suggestion => (
                        <Box
                            {...getSuggestionItemProps(suggestion)}
                            borderRadius="10px"
                            padding="10px"
                            bg={suggestion.active ? 'brand' : ''}
                            key={suggestion.id}
                            cursor="pointer"
                        >
                            <Text color={suggestion.active ? 'white' : 'brand'} fontSize="16px">
                                {suggestion.description}
                            </Text>
                        </Box>
                    ))}
                </Box>
            </Box>
        )}
    </PlacesAutocomplete>
);
