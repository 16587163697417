import React from 'react';
import { colors } from '../../common/constants';

interface IEditIcon {
    color?: string;
}

export const EditIcon: React.FC<IEditIcon> = ({ color = colors.brand }) => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.652344 10.5L0.29879 10.1464L0.152344 10.2929V10.5H0.652344ZM10.6523 0.5L11.0059 0.146447C10.8106 -0.0488155 10.4941 -0.0488155 10.2988 0.146447L10.6523 0.5ZM14.6523 4.5L15.0059 4.85355C15.2012 4.65829 15.2012 4.34171 15.0059 4.14645L14.6523 4.5ZM4.65234 14.5V15H4.85945L5.0059 14.8536L4.65234 14.5ZM0.652344 14.5H0.152344C0.152344 14.7761 0.376201 15 0.652344 15L0.652344 14.5ZM1.0059 10.8536L11.0059 0.853553L10.2988 0.146447L0.29879 10.1464L1.0059 10.8536ZM10.2988 0.853553L14.2988 4.85355L15.0059 4.14645L11.0059 0.146447L10.2988 0.853553ZM14.2988 4.14645L4.29879 14.1464L5.0059 14.8536L15.0059 4.85355L14.2988 4.14645ZM4.65234 14H0.652344V15H4.65234V14ZM1.15234 14.5V10.5H0.152344V14.5H1.15234Z"
            fill={color}
        />
    </svg>
);
