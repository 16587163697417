import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import { ServiceHistory } from './ServiceHistory';
import { getDetailsReportRequest, getServiceHistoryRequest } from './store/actions';
import { GetDetailsReportPayload, GetServiceHistoryPayload } from './types';
import dayjs from 'dayjs';
import { toggleUserProfileModal } from '../../store/actions/profile.actions';
import {DetailsModal} from "./components/DetailsModal";
import {FeedbackModal} from "../Activity/modals";
import {AddFeedbackPayload} from "../Activity/types";
import {addFeedbackRequest} from "../Activity/store/actions";
import {FeedbackInfoModal} from "./components";

export const ServiceHistoryContainer: React.FC = () => {
    const dispatch = useDispatch();

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [activeCategory, setActiveCategory] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedYear, setSelectedYear] = useState(dayjs().get('y'));
    const [detailsReportId, setDetailsReportId] = useState(0)
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showInfoFeedbackModal, setShowInfoFeedbackModal] = useState(false)

    const {
        serviceHistory:{
            items: serviceHistoryItems,
            loading,
            income,
            outcome,
            totalCount
        },
        detailsReport:{
            item: detailsReportItem,
            loading: detailsReportLoading
        } } = useSelector(

        (state: RootStore) => state.serviceHistory,
    );
    const getServiceHistory = (payload: GetServiceHistoryPayload) =>
        dispatch(getServiceHistoryRequest(payload));
    const getDetailsReport = (payload: GetDetailsReportPayload) =>
        dispatch(getDetailsReportRequest(payload));
    const addFeedback = (payload: AddFeedbackPayload) => dispatch(addFeedbackRequest(payload));

    useEffect(() => {
        fetchServiceHistory();
    }, [currentPage, activeCategory, selectedYear]);

    useEffect(() => {
        if (detailsReportId) {
            getDetailsReport({ id: detailsReportId});
            setShowDetailsModal(true);
        }
    }, [detailsReportId]);

    const handleChangeCategory = (category: number) => {
        setActiveCategory(category);
        setCurrentPage(1);
    };

    const onOpenAgentProfile = (id: number, toFeedback?: boolean) =>
        dispatch(toggleUserProfileModal({ userId: id, value: true, toFeedback }));

    const fetchServiceHistory = () => {
        switch (activeCategory) {
            case 1:
                getServiceHistory({ pageNumber: currentPage, pageSize: 8, year: selectedYear });
                break;
            case 2:
                getServiceHistory({
                    pageNumber: currentPage,
                    pageSize: 8,
                    filter: true,
                    year: selectedYear,
                });
                break;
            case 3:
                getServiceHistory({
                    pageNumber: currentPage,
                    pageSize: 8,
                    filter: false,
                    year: selectedYear,
                });
                break;
            default:
                getServiceHistory({ pageNumber: currentPage, pageSize: 8, year: selectedYear });
        }
    };

    const handlePressDetails = (id: number) => {
        setDetailsReportId(id)
    };

    const handleModalClose = () => {
        setShowDetailsModal(false);
        setDetailsReportId(0)
    };


    return (
        <>
            <ServiceHistory
                activeCategory={activeCategory}
                setActiveCategory={handleChangeCategory}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                showDetailsModal={showDetailsModal}
                setShowDetailsModal={handlePressDetails}
                serviceHistoryItems={serviceHistoryItems}
                income={income}
                outcome={outcome}
                totalCount={totalCount}
                closeModal={handleModalClose}
                requestType={requestType}
                setRequestType={setRequestType}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                loading={loading}

            />
                {/*MODALS*/}
            {
                showDetailsModal &&
                <DetailsModal
                    isOpen={showDetailsModal}
                    onClose={handleModalClose}
                    detailsReportItem={detailsReportItem}
                    requestType={requestType}
                    onOpenAgentProfile={onOpenAgentProfile}
                    detailsReportLoading={detailsReportLoading}
                    setShowFeedbackModal={setShowFeedbackModal}
                    setShowInfoFeedbackModal={setShowInfoFeedbackModal}
                />
            }


                <FeedbackModal
                    isOpen={showFeedbackModal}
                    onClose={() => {
                        setShowFeedbackModal(false)
                    }}
                    assistRequestId={detailsReportId}
                    addFeedback={addFeedback}
                />

            {
                showInfoFeedbackModal &&
                <FeedbackInfoModal
                    requestType={requestType}
                    feedbackInfo={detailsReportItem.feedback}
                    isOpen={showInfoFeedbackModal}
                    onClose={() => {
                        setShowInfoFeedbackModal(false)
                    }}
                />
            }


        </>
    );
};
