import React from 'react';
import { colors } from '../../common/constants';

export const CommentsIcon: React.FC = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.5 19.9999L4.8 16.0999C3.67644 14.4382 3.26999 12.4703 3.65622 10.5622C4.04244 8.65409 5.19506 6.93557 6.89977 5.72617C8.60447 4.51677 10.7453 3.89879 12.9241 3.98714C15.1029 4.07548 17.1715 4.86413 18.7453 6.20646C20.319 7.54878 21.2909 9.35344 21.4801 11.2849C21.6693 13.2163 21.063 15.1431 19.7739 16.707C18.4848 18.2709 16.6007 19.3655 14.4718 19.7873C12.3429 20.209 10.1142 19.9292 8.2 18.9999L3.5 19.9999"
            stroke={colors.brand}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.5 12V12.01"
            stroke={colors.brand}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.5 12V12.01"
            stroke={colors.brand}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.5 12V12.01"
            stroke={colors.brand}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
