import React from 'react';
import { HStack, Flex } from '@chakra-ui/react';
import { CustomInput, CustomButton, DatePicker } from '../../../common/components';
import dayjs from 'dayjs';
import { getTimeByTimeZone } from '../../../common/utils';
import { ClockIcon, TrashIcon, EditIcon2 } from '../../../assets/icons';
import {
    ListintTimeSlotType,
    UpdateListingTimeSlotPayload,
    DeleteListingTimeSlotPayload,
} from '../../../common/types';
interface IEditableTimeSlotProps extends ListintTimeSlotType {
    onDelete: (data: DeleteListingTimeSlotPayload) => void;
    deleteLoading: boolean;
    updateLoading: boolean;
    timeZone: string;
    setShowAddTimeSlotModal: (value: boolean) => void;
    createTimeSlotModalCompletedFieldsData: () => void;
    isFromExternalDb: boolean;
    published: boolean;
}

export const EditableTimeSlot: React.FC<IEditableTimeSlotProps> = ({
    date,
    endTime,
    id,
    listingId,
    startTime,
    onDelete,
    deleteLoading,
    updateLoading,
    timeZone = '',
    setShowAddTimeSlotModal,
    createTimeSlotModalCompletedFieldsData,
    isFromExternalDb,
    published,
}) => {
    const dateValue = getTimeByTimeZone(date, timeZone).valueOf();
    const startTimeValue = getTimeByTimeZone(startTime, timeZone).format('hh:mm A');
    const endTimeValue = getTimeByTimeZone(endTime, timeZone).format('hh:mm A');

    return (
        <>
            <Flex align="flex-start" justifyContent="space-between" mb="20px">
                <DatePicker
                    displayValue={dateValue ? dayjs.utc(dateValue).format('MM/DD/YY') : ''}
                    label="Select Date"
                    placeholder="Select Date"
                    borderRadius="50px"
                    value={dateValue}
                    mbCustom="0"
                    setValue={() => {}}
                    isReadOnly
                />
                <CustomInput
                    label="Start time"
                    defaultValue={startTimeValue}
                    placeholder="From time"
                    type="text"
                    rightIcon={<ClockIcon color="#888DAB" />}
                    width="100%"
                    margin={'0 10px'}
                    isReadOnly
                />
                <CustomInput
                    label="End time"
                    defaultValue={endTimeValue}
                    placeholder="End time"
                    type="text"
                    width="100%"
                    margin={'0 10px'}
                    rightIcon={<ClockIcon color="#888DAB" />}
                    isReadOnly
                />
                <HStack h="70px" pt="20px">
                    {isFromExternalDb && !published ? (
                        <CustomButton
                            text={'Publish'}
                            onClick={() => {
                                setShowAddTimeSlotModal(true);
                                createTimeSlotModalCompletedFieldsData();
                            }}
                            bgColor="brand"
                            height="50px"
                            isLoading={updateLoading}
                            icon={<EditIcon2 color="white" />}
                        />
                    ) : (
                        <>
                            <CustomButton
                                text="Delete"
                                bgColor="brand"
                                height="50px"
                                icon={<TrashIcon />}
                                isLoading={deleteLoading}
                                onClick={() => onDelete({ id, listingId })}
                            />
                            <CustomButton
                                text={'Change'}
                                onClick={() => {
                                    setShowAddTimeSlotModal(true);
                                    createTimeSlotModalCompletedFieldsData();
                                }}
                                bgColor="brand"
                                height="50px"
                                isLoading={updateLoading}
                                icon={<EditIcon2 color="white" />}
                            />
                        </>
                    )}
                </HStack>
            </Flex>
        </>
    );
};
