import React, { useEffect, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { AvailableOptionsOverview } from './AvailableOptionsOverview';
import { SubscriptionStatus } from './SubscriptionStatus';
import { FreeTrial } from './FreeTrial';
import { BackButton, Loader } from '../../../../common/components';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { getCurrentSubscriptionRequest, getSubscriptionsHistoryRequest } from './store/actions';

export const Subscription: React.FC = () => {
    const dispatch = useDispatch();

    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        dispatch(getCurrentSubscriptionRequest());
        dispatch(getSubscriptionsHistoryRequest());
    }, []);

    const { value: currentSubscription, loading: currentSubLoading } = useSelector(
        (state: RootStore) => state.subscriptions.current,
    );
    const { items, loading: historyLoading } = useSelector(
        (state: RootStore) => state.subscriptions.history,
    );

    const getBlock = () => {
        if (!currentSubscription.isSubscribed || showOptions) {
            return <AvailableOptionsOverview />;
        }
        if (currentSubscription.isSubscribed && currentSubscription.subscriptionType !== null) {
            return (
                <SubscriptionStatus
                    items={items}
                    loading={historyLoading}
                    subscribedTo={currentSubscription.subscribedTo}
                    type={currentSubscription.subscriptionType?.name}
                />
            );
        }
        if (currentSubscription.isSubscribed && currentSubscription.subscriptionType === null) {
            return (
                <FreeTrial
                    subscribedTo={currentSubscription.subscribedTo}
                    onUpgrade={() => setShowOptions(true)}
                />
            );
        }

        return null;
    };

    return (
        <Box h="80vh">
            <HStack mb="16px">
                <BackButton mb="0" text="" />
                <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                    Subscription
                </Text>
            </HStack>
            {currentSubLoading ? <Loader centerHeight="500px" /> : getBlock()}
        </Box>
    );
};
