import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    IconButton,
    CloseButton,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { BackArrow } from '../../../assets/icons';
import { CustomButton, CustomInput } from '../../../common/components';
import { colors } from '../../../common/constants';
import { AddCalendarIdPayload } from '../types';

interface IAddGoogleCalendarModal {
    isOpen: boolean;
    onClose: () => void;
    setCalendar: (v: AddCalendarIdPayload) => void;
}

export const AddGoogleCalendarModal: React.FC<IAddGoogleCalendarModal> = ({
    isOpen,
    onClose,
    setCalendar,
}) => {
    const { handleSubmit, register, setValue } = useForm({});

    const onSubmit = (data: { calendarId: string }) => {
        setCalendar(data);
        onClose();
        setValue('calendarId', '');
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <IconButton
                        aria-label="close modal"
                        variant="unstyled"
                        d="flex"
                        alignItems="center"
                        onClick={onClose}
                    >
                        <BackArrow />
                    </IconButton>
                    <ModalHeader flex={1} align="center">
                        <Text color="brand">Add a calendar</Text>
                    </ModalHeader>

                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody>
                    <Flex align="center" my="30px" height="100%" justify="center">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex align="center" direction="column">
                                <Flex direction="column">
                                    <Text
                                        fontSize="16px"
                                        fontWeight="300"
                                        color="secondaryFontDarker"
                                        mb="15px"
                                    >
                                        1. Go to your google calendar, press 3-points button and
                                        open options
                                    </Text>
                                    <Text
                                        fontSize="16px"
                                        fontWeight="300"
                                        color="secondaryFontDarker"
                                        mb="15px"
                                    >
                                        2. Set this calendar as Open to public with ability to see
                                        Busy time
                                    </Text>
                                    <Text
                                        fontSize="16px"
                                        fontWeight="300"
                                        color="secondaryFontDarker"
                                        mb="15px"
                                    >
                                        3. Copy your calendar ID
                                    </Text>
                                    <Text
                                        fontSize="16px"
                                        fontWeight="300"
                                        color="secondaryFontDarker"
                                    >
                                        4. Paste the ID into field below
                                    </Text>
                                </Flex>
                                <Box borderBottom="1px solid #D1D2DB" width="100%" my="35px" />
                                <Flex width="100%">
                                    <CustomInput
                                        label="Enter your Google calendar ID"
                                        register={{ ...register('calendarId') }}
                                        width="100%"
                                        placeholder="Enter"
                                    />
                                </Flex>
                            </Flex>
                            <Flex mt="30px" justify="center">
                                <CustomButton
                                    width="340px"
                                    height="60px"
                                    text="Confirm"
                                    bgColor="brand"
                                    boxShadow="0px 3px 3px grey"
                                    type="submit"
                                />
                            </Flex>
                        </form>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
