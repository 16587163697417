import React from 'react';
import { Container, Box, Flex, Heading, Text } from '@chakra-ui/react';
import { LandingHeader, LandingFooter, TrialBanner } from '../../common/components/landing';
import { fonts } from '../../common/utils';
import PolicyBG from '../../assets/landing/policy-header-bg.png';
import { PolicyTexts } from './texts';

export const NewPolicy: React.FC = () => (
    <Box>
        <LandingHeader />
        <Box
            h={{ base: '250px', lg: '450px' }}
            w="100%"
            backgroundRepeat="no-repeat"
            backgroundImage={PolicyBG}
            position="relative"
            backgroundSize="cover"
            backgroundPosition="center"
            overflowY="hidden"
        >
            <Box
                position="absolute"
                background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                h="100%"
                w="100%"
                top="0"
                left="0"
                opacity={0.7}
                zIndex={1}
            />
            <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                <Flex direction="column" height="100%" justify="center" pb="70px">
                    <Heading
                        color="white"
                        fontFamily={fonts.montserrat}
                        fontSize={{ base: '28px', lg: '52px' }}
                        lineHeight={{ base: '34px', lg: '62px' }}
                        fontWeight="bold"
                        w={{ base: '100%', lg: '60%' }}
                    >
                        Privacy Policy
                    </Heading>
                    <Text
                        color="white"
                        fontFamily={fonts.montserrat}
                        fontSize="16px"
                        lineHeight="28px"
                        my="23px"
                    >
                        Your data and privacy is guarded under this policy.
                    </Text>
                </Flex>
            </Container>
        </Box>
        <Box
            bg="white"
            borderTopRadius={{ base: '30px', md: '70px' }}
            mt="-70px"
            zIndex={10}
            position="relative"
        >
            <Container maxW="container.xl" py={{ base: '30px', lg: '75px' }}>
                <PolicyTexts />
            </Container>
        </Box>
        <TrialBanner />
        <LandingFooter />
    </Box>
);
