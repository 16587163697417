import React from 'react';
import { colors } from '../../common/constants';

export const LockIcon: React.FC = () => (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.4"
            d="M10.317 5.89H9.82603V3.927C9.82603 2.88549 9.4123 1.88665 8.67584 1.15019C7.93939 0.413736 6.94054 0 5.89903 0C4.85753 0 3.85868 0.413736 3.12222 1.15019C2.38577 1.88665 1.97203 2.88549 1.97203 3.927V5.89H1.48203C1.09145 5.89026 0.716941 6.04554 0.440758 6.32172C0.164574 6.59791 0.00929809 6.97242 0.0090332 7.363V14.235C0.00929809 14.6256 0.164574 15.0001 0.440758 15.2763C0.716941 15.5525 1.09145 15.7077 1.48203 15.708H10.318C10.7086 15.7077 11.0831 15.5525 11.3593 15.2763C11.6355 15.0001 11.7908 14.6256 11.791 14.235V7.363C11.7908 6.97224 11.6354 6.59758 11.359 6.32137C11.0826 6.04516 10.7078 5.89 10.317 5.89ZM3.28103 3.927C3.28103 3.23266 3.55686 2.56676 4.04783 2.07579C4.5388 1.58482 5.2047 1.309 5.89903 1.309C6.59337 1.309 7.25927 1.58482 7.75024 2.07579C8.24121 2.56676 8.51703 3.23266 8.51703 3.927V5.89H3.28103V3.927ZM6.55303 10.944V12.435C6.55303 12.6086 6.48408 12.7751 6.36133 12.8978C6.23859 13.0205 6.07212 13.0895 5.89853 13.0895C5.72495 13.0895 5.55847 13.0205 5.43573 12.8978C5.31299 12.7751 5.24403 12.6086 5.24403 12.435V10.944C4.99448 10.7999 4.79945 10.5775 4.68918 10.3113C4.5789 10.0451 4.55956 9.74992 4.63414 9.47158C4.70872 9.19324 4.87306 8.94729 5.10166 8.77187C5.33027 8.59646 5.61038 8.50137 5.89853 8.50137C6.18669 8.50137 6.46679 8.59646 6.6954 8.77187C6.92401 8.94729 7.08835 9.19324 7.16293 9.47158C7.23751 9.74992 7.21816 10.0451 7.10789 10.3113C6.99762 10.5775 6.80258 10.7999 6.55303 10.944Z"
            fill={colors.brand}
        />
    </svg>
);
