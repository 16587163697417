import { createSlice } from '@reduxjs/toolkit';
import {PayloadMessage, IdNameTypes, SearchAgentByAreaResponse, PayloadError} from '../../common/types';
import {
    getUserAvatarRequest,
    getUserAvatarSuccess,
    getUserInfoRequest,
    getUserInfoSuccess,
    getProfileAgenciesSuccess,
    searchAgentsByArea,
    searchAgentsByAreaSuccess,
    searchAgentsByAreaError,
    clearAvailableAgents,
} from '../actions/user.actions';

type InitialState = {
    loading: boolean;
    errors: PayloadMessage[];
    user: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        location: string;
        licenseJurisdiction: string | null;
        rates: string | null;
        brokerOffice: string | null;
        preferredAreasOfService: string | null;
        preferredPayments: string | null;
        agencies: IdNameTypes[];
        currentAgency: number;
        mlsIds: Array<string>;
        idLicensePhoto: {
            id: number;
            itemHash: string;
            itemExtension: string;
        };
        isLicenseEditable: boolean;
        isSubscribed: boolean,
        isFreeSubscriptionAvailable: boolean
    };
    userPhoto: any;
    agencies: IdNameTypes[];
    availableAgents:{
        items: SearchAgentByAreaResponse[],
        loading: boolean
        errors: PayloadError[]
    }

};

export const initialState: InitialState = {
    loading: false,
    errors: [],
    user: {
        agencies: [],
        brokerOffice: null,
        currentAgency: 0,
        email: '',
        firstName: '',
        id: 0,
        idLicensePhoto: {
            id: 0,
            itemHash: '',
            itemExtension: '',
        },
        isLicenseEditable: false,
        lastName: '',
        licenseJurisdiction: null,
        location: '',
        mlsIds: [],
        phoneNumber: '',
        preferredAreasOfService: null,
        preferredPayments: null,
        rates: null,
        isSubscribed: false,
        isFreeSubscriptionAvailable: false
    },
    userPhoto: [],
    agencies: [],
    availableAgents:{
        items:[],
        loading: false,
        errors: []
    }
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getUserInfoRequest, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getUserInfoSuccess, (state, action) => {
                state.loading = true;
                state.errors = [];
                state.user = action.payload;
            })
            .addCase(getUserAvatarRequest, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getUserAvatarSuccess, (state, action) => {
                state.loading = true;
                state.errors = [];
                state.userPhoto = action.payload.value.profileImage;
            })
            .addCase(getProfileAgenciesSuccess, (state, action) => {
                state.agencies = action.payload;
            })
            .addCase(searchAgentsByArea, (state) => {
                state.availableAgents.loading = true
                state.availableAgents.errors = []
            }).addCase(searchAgentsByAreaSuccess, (state, action) => {
            state.availableAgents.loading = false
            state.availableAgents.items = action.payload
        }).addCase(searchAgentsByAreaError, (state, action) =>{
            state.availableAgents.loading = false
            state.availableAgents.errors = action.payload
        }).addCase(clearAvailableAgents, (state) => {
            state.availableAgents.items = []
        })
    },
});

export default userSlice.reducer;
