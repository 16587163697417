import React, { Dispatch, SetStateAction } from 'react';
import { Box, Checkbox, Link, Flex, Text, Button } from '@chakra-ui/react';
import {
    EditDwollaIcon,
    InfoIcon,
    SuspendIcon,
    TextDocumentIcon,
    WalletIcon,
    ClockIcon,
    RechargeIcon,
} from '../../../../../../assets/icons';
import { CustomButton, Loader } from '../../../../../../common/components';
import {CustomerStatus} from "../../types";

interface IBalanceTabProps {
    accountStatus: string;
    accountStatusLoading: boolean;
    walletBalanceLoading: boolean;
    walletBalance: number;
    setShowEditAccountModal: Dispatch<SetStateAction<boolean>>;
    setShowUpdateModal: Dispatch<SetStateAction<boolean>>;
    setShowUploadLicenseModal: Dispatch<SetStateAction<boolean>>;
    setShowRechargeModal: Dispatch<SetStateAction<boolean>>;
    setShowGetPaidModalModal: Dispatch<SetStateAction<boolean>>;
}

const renderStatusDwollaAccount = (
    accountStatus: string,
    walletBalance: number,
    setShowRechargeModal: Dispatch<SetStateAction<boolean>>,
    setShowGetPaidModalModal: Dispatch<SetStateAction<boolean>>,
) => {
    switch (accountStatus) {
        case CustomerStatus.Retry:
            return (
                <Flex align={'center'}>
                    <InfoIcon />
                    <Text
                        ml={'12px'}
                        color={'#FE805C'}
                        fontWeight={'400'}
                        fontSize={'16px'}
                        lineHeight={'24px'}
                    >
                        Not Verified
                    </Text>
                </Flex>
            );
        case CustomerStatus.Document:
        case CustomerStatus.DocumentMany:
            return (
                <Flex align={'center'}>
                    <TextDocumentIcon />
                    <Text
                        ml={'12px'}
                        color={'secondaryFontDarker'}
                        fontWeight={'400'}
                        fontSize={'16px'}
                        lineHeight={'24px'}
                    >
                        Document
                    </Text>
                </Flex>
            );
        case CustomerStatus.Suspended:
            return (
                <Flex align={'center'}>
                    <SuspendIcon />
                    <Text
                        ml={'12px'}
                        color={'#F2C94C'}
                        fontWeight={'400'}
                        fontSize={'16px'}
                        lineHeight={'24px'}
                    >
                        Suspended
                    </Text>
                </Flex>
            );
        case CustomerStatus.Verified:
            return (
                <Flex align={'center'}>
                    {!!walletBalance && (
                        <CustomButton
                            onClick={() => setShowGetPaidModalModal(true)}
                            mr={'33px'}
                            _hover={{
                                background: 'none',
                            }}
                            variant={'custom'}
                            textDecoration={'underline'}
                            fontWeight={'400'}
                            color={'brand'}
                            fontSize={'12px'}
                            leftIcon={<WalletIcon />}
                            text={'Get Paid'}
                            width={'auto'}
                            px="0"
                        />
                    )}

                    <CustomButton
                        onClick={() => setShowRechargeModal(true)}
                        _hover={{
                            background: 'none',
                        }}
                        variant={'custom'}
                        textDecoration={'underline'}
                        fontWeight={'400'}
                        color={'brand'}
                        fontSize={'12px'}
                        leftIcon={<RechargeIcon />}
                        text={'Recharge'}
                        width={'auto'}
                        px="0"
                    />
                </Flex>
            );
        default:
            return (
                <Flex align={'center'}>
                    <ClockIcon color="#F2C94C" width="20" height="20" />
                    <Text
                        ml={'12px'}
                        color={'#F2C94C'}
                        fontWeight={'400'}
                        fontSize={'16px'}
                        lineHeight={'24px'}
                    >
                        Verification processing
                    </Text>
                </Flex>
            );
    }
};

export const BalanceTab: React.FC<IBalanceTabProps> = ({
    accountStatus,
    accountStatusLoading,
    walletBalance,
    walletBalanceLoading,
    setShowEditAccountModal,
    setShowUpdateModal,
    setShowUploadLicenseModal,
    setShowRechargeModal,
    setShowGetPaidModalModal,
}) => {

    return (
        <>
            <Flex
                justifyContent={'space-between'}
                flexDir={'column'}
                w={'100%'}
                borderRadius={'8px'}
                height={'156px'}
                p={'14px 24px'}
                bg="#F9F9FB"
                mt="45px"
            >
                {accountStatusLoading ? (
                    <Loader spinnerSize={'md'} centerHeight={'100%'} />
                ) : (
                    <>
                        {/*    Balance Header*/}
                        <Flex minH={'40px'} align={'center'} justifyContent={'space-between'}>
                            <Text
                                color={'brand'}
                                textTransform={'capitalize'}
                                fontWeight={'400'}
                                fontSize={'14px'}
                                lineHeight={'21px'}
                            >
                                Your balance is :
                            </Text>
                            {accountStatus === 'verified' && (
                                <CustomButton
                                    onClick={() => setShowEditAccountModal(true)}
                                    _hover={{
                                        background: 'none',
                                    }}
                                    variant={'custom'}
                                    textDecoration={'underline'}
                                    fontWeight={'400'}
                                    color={'brand'}
                                    fontSize={'12px'}
                                    lineHeight={'18px'}
                                    leftIcon={<EditDwollaIcon />}
                                    text={'Edit Account'}
                                    width={'auto'}
                                />
                            )}
                        </Flex>
                        {/* Balance */}
                        {walletBalanceLoading ? (
                            <Loader
                                centerProps={{
                                    justifyContent: 'flex-start',
                                }}
                                spinnerSize={'sm'}
                                centerHeight={'100%'}
                            />
                        ) : (
                            <Text
                                color={'#6FCF97'}
                                fontWeight={'500'}
                                fontSize={'29px'}
                                lineHeight={'39px'}
                            >
                                ${walletBalance}
                            </Text>
                        )}
                        <Box>
                            {renderStatusDwollaAccount(
                                accountStatus,
                                walletBalance,
                                setShowRechargeModal,
                                setShowGetPaidModalModal,
                            )}
                        </Box>
                    </>
                )}
            </Flex>
            {/* Footer */}
            {accountStatus === CustomerStatus.Retry && (
                <CustomButton
                    onClick={() => setShowUpdateModal(true)}
                    mt={'50px'}
                    maxW={'342px'}
                    height={'59px'}
                    fontSize={'16px'}
                    lineHeight={'24px'}
                    text={'Retry'}
                    background={'brand'}
                />
            )}
            {(accountStatus === CustomerStatus.Document || accountStatus === CustomerStatus.DocumentMany) && (
                <>
                    <Text
                        textTransform={'capitalize'}
                        mt={'30px'}
                        mb={'18px'}
                        color={'#FE805C'}
                        fontWeight={'400'}
                        fontSize={'16px'}
                        lineHeight={'24px'}
                    >
                        your submission was rejected, upload your driver license for re-submission
                    </Text>

                    <CustomButton
                        _hover={{
                            background: 'none',
                        }}
                        variant={'custom'}
                        onClick={() => setShowUploadLicenseModal(true)}
                        textDecoration={'underline'}
                        fontSize={'16px'}
                        lineHeight={'24px'}
                        px="0"
                        text={'Upload Driver license'}
                        width={'auto'}
                        color={'brand'}
                    />
                </>
            )}
            {/*{accountStatus === 'document_many' && (*/}
            {/*    <>*/}
            {/*        <Text*/}
            {/*            textTransform={'capitalize'}*/}
            {/*            mt={'30px'}*/}
            {/*            mb={'18px'}*/}
            {/*            color={'#FE805C'}*/}
            {/*            fontWeight={'400'}*/}
            {/*            fontSize={'16px'}*/}
            {/*            lineHeight={'24px'}*/}
            {/*        >*/}
            {/*            your submission was rejected, upload your driver license for re-submission*/}
            {/*        </Text>*/}
            {/*        <CustomButton*/}
            {/*            _hover={{*/}
            {/*                background: 'none',*/}
            {/*            }}*/}
            {/*            variant={'custom'}*/}
            {/*            onClick={() => setShowUploadLicenseModal(true)}*/}
            {/*            textDecoration={'underline'}*/}
            {/*            fontSize={'16px'}*/}
            {/*            lineHeight={'24px'}*/}
            {/*            px="0"*/}
            {/*            text={'Upload Driver license'}*/}
            {/*            width={'auto'}*/}
            {/*            color={'brand'}*/}
            {/*        />*/}
            {/*    </>*/}
            {/*)}*/}
            {accountStatus === CustomerStatus.Suspended && (
                <Button
                    as={Link}
                    href="mailto:support@dwolla.com"
                    background={'brand'}
                    fontSize={'16px'}
                    lineHeight={'24px'}
                    mt={'50px'}
                    w={'342px'}
                    color="white"
                    height="60px"
                    _hover={{
                        background: 'gray',
                    }}
                >
                    Contact Support
                </Button>
            )}
        </>
    );
};
