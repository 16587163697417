import { Flex } from '@chakra-ui/react';
import React, { Dispatch, useState } from 'react'

import { DashboardButton } from '../../../DashboardButton/DashboardButton';
import { FiltersBlock } from '../FiltersBlock/FiltersBlock';
import { ModalFilterDashboard } from '../ModalFilterDashboard/ModalFilterDashboard';

import { FilterState } from '../../../../../types/dashboard.types';

interface DashboardContentHeader {
    setFilterState: Dispatch<React.SetStateAction<FilterState>>;
    sortingType: number;
    filterState: FilterState
}

export type ModalOptionsProps = {
    type: '' | 'modalFilterDashboard';
};

export const DashboardContentHeader: React.FC<DashboardContentHeader> = ({
    setFilterState,
    filterState,
}) => {
    const [openModal, setOpenModal] = useState<ModalOptionsProps>({ type: '' });

    const handleModalClose = () => {
        setOpenModal({ type: '' });
    };

    return (
        <>
            <Flex justifyContent="space-between" alignItems="center">
                <Flex mt="80px" mb="30px">
                    <DashboardButton text="Blasts" href="/updates" />
                    {/* <DashboardButton text="Off-Market-Needs" /> */}
                </Flex>
                <FiltersBlock
                    setOpenModal={setOpenModal}
                    setFilterState={setFilterState}
                    sortingType={filterState.sortingType}
                />
            </Flex>
            <ModalFilterDashboard
                isOpen={openModal.type === 'modalFilterDashboard'}
                onClose={handleModalClose}
                setOpenModal={setOpenModal}
                filterState={filterState}
                setFilterState={setFilterState}
            />
        </>
    );
};