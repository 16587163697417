import React from 'react';
import { colors } from '../../common/constants';

export const InfoIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.5013 6.00008V6.50008H10.5013V6.00008H9.5013ZM10.5013 5.98675V5.48675H9.5013V5.98675H10.5013ZM10.5013 14.6667V9.33341H9.5013V14.6667H10.5013ZM10.5013 6.00008V5.98675H9.5013V6.00008H10.5013ZM8.0013 10.5001H10.0013V9.50008H8.0013V10.5001ZM8.0013 14.5001H12.0013V13.5001H8.0013V14.5001ZM10.0013 1.16675C14.8798 1.16675 18.8346 5.12157 18.8346 10.0001L19.8346 10.0001C19.8346 4.56928 15.4321 0.166749 10.0013 0.166748L10.0013 1.16675ZM1.16797 10.0001C1.16797 5.12157 5.12279 1.16675 10.0013 1.16675L10.0013 0.166748C4.5705 0.166748 0.167969 4.56928 0.167969 10.0001H1.16797ZM10.0013 18.8334C5.12279 18.8334 1.16797 14.8786 1.16797 10.0001H0.167969C0.167968 15.4309 4.5705 19.8334 10.0013 19.8334V18.8334ZM10.0013 19.8334C15.4321 19.8334 19.8346 15.4309 19.8346 10.0001L18.8346 10.0001C18.8346 14.8786 14.8798 18.8334 10.0013 18.8334V19.8334Z"
            fill={colors.orange}
        />
    </svg>
);
