import React, { Dispatch, SetStateAction } from 'react';
import { Box, Flex, Text, IconButton } from '@chakra-ui/react';

import { ChevronIcon } from '../../../../assets/icons';
import UsePagination, { DOTS } from '../../helpers/hooks/UsePagination';

interface IDashboardPaginatorProps {
    pageSize: number;
    currentPage: number;
    usersTotalCount: number;
    membersLoading: boolean;
    setCurrentPage: Dispatch<SetStateAction<any>>;
}

const TablePaginator: React.FC<IDashboardPaginatorProps> = ({
    pageSize,
    currentPage,
    setCurrentPage,
    membersLoading,
    usersTotalCount,
}) => {
    const siblingCount = 1;

    const paginationRange = UsePagination({
        usersTotalCount,
        pageSize,
        siblingCount,
        currentPage,
    });
    if (currentPage === 0 || paginationRange!.length < 2) {
        return null;
    }

    const pageCount = Math.ceil(usersTotalCount / pageSize);

    const onPageChanged = (pageNumber: number | string) => {
        setCurrentPage(pageNumber);
    };
    const goToNextPage = () => {
        if (currentPage >= 1 && currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };
    const goToPreviousPage = () => {
        if (currentPage > 1 && currentPage <= pageCount) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Box m="30px 0">
            {!membersLoading
                ? usersTotalCount !== 0 && (
                      <Flex align="center" justify="center">
                          <IconButton
                              aria-label="Previous page"
                              onClick={goToPreviousPage}
                              variant="unstyled"
                              d="flex"
                              alignItems="center"
                          >
                              <ChevronIcon />
                          </IconButton>
                          {paginationRange!.map(page => {
                              if (page === DOTS) {
                                  return <Box>&#8230;</Box>;
                              }
                              return (
                                  <Flex
                                      key={page}
                                      onClick={() => onPageChanged(page)}
                                      bg={currentPage === page ? 'brand' : 'transparent'}
                                      borderRadius="100%"
                                      height="30px"
                                      minWidth="30px"
                                      alignItems="center"
                                      justify="center"
                                      as="button"
                                      padding="5px"
                                      mr="10px"
                                  >
                                      <Text
                                          fontSize="12px"
                                          color={currentPage === page ? 'white' : 'gray'}
                                      >
                                          {page}
                                      </Text>
                                  </Flex>
                              );
                          })}
                          <IconButton
                              aria-label="Next Page"
                              onClick={goToNextPage}
                              transform="rotate(180deg)"
                              variant="unstyled"
                              d="flex"
                              alignItems="center"
                          >
                              <ChevronIcon />
                          </IconButton>
                      </Flex>
                  )
                : null}
        </Box>
    );
};

export default TablePaginator;
