import React from 'react'
import {CreateDwollaAccountForm, PaymentsModalWrapper} from "../components";
import {CreateDwollaAccountPayload} from "../types";

interface ICreateDwollaAccountModalProps{
    isOpen:boolean
    title: string
    size?: string
    onClose: () => void
    onCreateDwollaAccount: (payload: CreateDwollaAccountPayload) => void
    createDwollaAccountLoading: boolean
}

export const CreateDwollaAccountModal:React.FC<ICreateDwollaAccountModalProps> = ({
    isOpen,
    size,
    onClose,
    title,
    onCreateDwollaAccount,
    createDwollaAccountLoading

}) => {
    return (
        <PaymentsModalWrapper size={size} isOpen={isOpen} title={title} onClose={onClose}>
            <CreateDwollaAccountForm
                loading={createDwollaAccountLoading}
                isRetry={false}
                onCreateDwollaAccount={onCreateDwollaAccount}/>
        </PaymentsModalWrapper>

    )
}
