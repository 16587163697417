import { writeToSupportRequest, writeToSupportSuccess, writeToSupportError } from './actions';
import API from '../../../common/api/api.base';
import { call, takeEvery, put } from '@redux-saga/core/effects';
import { IResponseWithoutValue } from '../../../common/types';

function* workerWriteToSupport(action: ReturnType<typeof writeToSupportRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(
            API.post,
            `/api/profile/write-to-support`,
            action.payload,
        );
        if (result.success) {
            yield put(writeToSupportSuccess());
        } else {
            yield put(writeToSupportError(result.errors));
        }
    } catch (err) {
        console.log('workerWriteToSupport error', err);
    }
}

export default function* watchWriteToSupportSaga() {
    yield takeEvery(writeToSupportRequest.type, workerWriteToSupport);
}
