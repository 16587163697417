import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, Flex, IconButton, useDisclosure, Container } from '@chakra-ui/react';
import dayjs from 'dayjs';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { AssistanceRequestsModal, OpenHousesModal, OpportunitiesModal } from '../../../common/components/modals';
import { CalendarButtonItem } from '../../Calendar/components/CalendarButtonItem';
import { ActivitiesModalsContainter } from '../../Calendar/components/ActivitiesModalsContainter';
import { Loader } from '../../../common/components';

import { CalendarCategoriesIds, months } from '../../../common/constants';
import { RootStore } from '../../../store';

import {
    CalendarConfig,
    generateEventsFromDate,
    StyleWrapper,
} from '../../Calendar/Calendar.config';

import { CalendarButtonLeft, CalendarButtonRight, LargePassword } from '../../../assets/icons';
import { getCalendarDataRequest } from '../../Calendar/store/actions';

export const CalendarTab: React.FC = ({ }) => {
    const dispatch = useDispatch();
    const [currentWeek, setCurrcetWeek] = useState<any>(null);
    const [eventTypes, setEventTypes] = useState<string[]>([
        'openHouses',
        'myActivities',
        'agentActivities',
        'opportunities',
        'assistanceRequests',
    ]);
    const [startEndConfig, setStartEndConfig] = useState<{ start: string; end: string }>({
        start: '',
        end: '',
    });

    const calendarRef = useRef<any>(null);
    const startDate = currentWeek?.start.getDate();
    const startMonth = months[currentWeek?.start.getMonth()]?.substring(0, 3).toUpperCase();
    const yesterday = new Date(currentWeek?.end);
    const endDate = yesterday.getDate();
    const endMonth = months[yesterday.getMonth()]?.substring(0, 3).toUpperCase();
    yesterday.setDate(yesterday.getDate() - 1);
    const dateRange = `${startDate} ${startMonth} - ${endDate} ${endMonth}`;

    const { loading, availableForApplication, myActivities, otherAgentActivities } = useSelector(
        (state: RootStore) => state.calendar,
    );

    const setNextWeek = () => {
        const calendarApi = calendarRef?.current?.getApi();
        calendarApi.next();
    };
    const setPrevWeek = () => {
        const calendarApi = calendarRef?.current?.getApi();
        calendarApi.prev();
    };

    const onHandleEvents = (type: string) => {
        if (eventTypes.includes(type)) {
            setEventTypes(prevEventType => prevEventType.filter((item: string) => item !== type));
        } else {
            setEventTypes(prevEventType => [...prevEventType, type]);
        }
    };

    const {
        isOpen: isOpenHousesModalOpen,
        onOpen: onOpenOpenHousesModal,
        onClose: onCloseOpenHousesModal,
    } = useDisclosure();
    const {
        isOpen: isMyActivitiesModalOpen,
        onOpen: onOpenMyActivitiesModal,
        onClose: onCloseMyActivitiesModal,
    } = useDisclosure();
    const {
        isOpen: isOpenOpportunitiesOpen,
        onOpen: onOpenOpportunitiesModal,
        onClose: onCloseOpportunities,
    } = useDisclosure();
    const {
        isOpen: isAssistanceRequestsModalOpen,
        onOpen: onOpenAssistanceRequests,
        onClose: onCloseAssistanceRequests,
    } = useDisclosure();
    const {
        isOpen: isAgencyActivitiesOpen,
        onOpen: onOpenAgencyActivities,
        onClose: onCloseAgencyActivities,
    } = useDisclosure();

    const callModal = (type: string, start: Date | null, end: Date | null) => {
        if (true) {
            if (start !== null) {
                setStartEndConfig({
                    start: dayjs(start).toISOString(),
                    end: end ? dayjs(end).toISOString() : dayjs(new Date()).toISOString(),
                });
            }

            switch (type) {
                case 'openHouses':
                    onOpenOpenHousesModal();
                    break;
                case 'myActivities':
                    onOpenMyActivitiesModal();
                    break;
                case 'agentActivities':
                    onOpenAgencyActivities();
                    break;
                case 'opportunities':
                    onOpenOpportunitiesModal();
                    break;
                case 'assistanceRequests':
                    onOpenAssistanceRequests();
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        if (currentWeek) {
            dispatch(
                getCalendarDataRequest({
                    calendarCategoryIds: eventTypes.map(
                        (elem: string) => +CalendarCategoriesIds[elem as any],
                    ),
                    fromDate: dayjs(currentWeek?.start).set('hour', 0).set('minute', 0).format(),
                    toDate: dayjs(currentWeek?.end).set('hour', 0).set('minute', 0).format(),
                }),
            );
        }
    }, [currentWeek, eventTypes, isAssistanceRequestsModalOpen]);

    return (
        <>
            <StyleWrapper>
                <Flex align="center">
                    <IconButton
                        aria-label="previous week"
                        variant="unstyled"
                        d="flex"
                        justifyContent="center"
                        onClick={setPrevWeek}
                        // disabled={!isAccessAllowed}
                        disabled={false}
                    >
                        <CalendarButtonLeft />
                    </IconButton>
                    <Text fontSize="" color="brand" m="0 10px 0 10px">
                        {dateRange}
                    </Text>
                    <IconButton
                        aria-label="next week"
                        variant="unstyled"
                        onClick={setNextWeek}
                        d="flex"
                        justifyContent="center"
                        // disabled={!isAccessAllowed}
                        disabled={false}
                    >
                        <CalendarButtonRight />
                    </IconButton>
                    {loading && <Loader spinnerSize="md" centerProps={{ marginLeft: '10px' }} />}
                </Flex>
                <Flex justifyContent="space-between" alignItems="center" margin="18px 0">
                    <CalendarButtonItem
                        title="Open Houses"
                        primaryColor="calendarBlue"
                        opacityColor="calendarBlueOpacity"
                        eventTypes={eventTypes}
                        type="openHouses"
                        onClick={onHandleEvents}
                        // isAccessAllowed={isAccessAllowed}
                        isAccessAllowed={true}
                    />
                    <CalendarButtonItem
                        title="My Activities"
                        primaryColor="calendarGreen"
                        opacityColor="calendarGreenOpacity"
                        eventTypes={eventTypes}
                        type="myActivities"
                        onClick={onHandleEvents}
                        // isAccessAllowed={isAccessAllowed}
                        isAccessAllowed={true}
                    />
                    <CalendarButtonItem
                        title="Agent Activities"
                        primaryColor="calendarRed"
                        opacityColor="calendarRedOpacity"
                        eventTypes={eventTypes}
                        type="agentActivities"
                        onClick={onHandleEvents}
                        // isAccessAllowed={isAccessAllowed}
                        isAccessAllowed={true}
                    />
                    <CalendarButtonItem
                        title="Opportunities"
                        primaryColor="calendarYellow"
                        opacityColor="calendarYellowOpacity"
                        eventTypes={eventTypes}
                        type="opportunities"
                        onClick={onHandleEvents}
                        // isAccessAllowed={isAccessAllowed}
                        isAccessAllowed={true}
                    />
                    <CalendarButtonItem
                        title="Assistance Request"
                        primaryColor="calendarLical"
                        opacityColor="calendarLicalOpacity"
                        eventTypes={eventTypes}
                        type="assistanceRequests"
                        onClick={onHandleEvents}
                        // isAccessAllowed={isAccessAllowed}
                        isAccessAllowed={true}
                    />
                </Flex>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[timeGridPlugin, interactionPlugin]}
                    events={[
                        ...generateEventsFromDate(availableForApplication),
                        ...generateEventsFromDate(myActivities, 4),
                        ...generateEventsFromDate(otherAgentActivities, 5),
                    ]}
                    displayEventTime={false}
                    slotMinTime={CalendarConfig.slotMinTime}
                    slotMaxTime={CalendarConfig.slotMaxTime}
                    allDaySlot={false}
                    datesSet={(arg: any) => {
                        setCurrcetWeek(arg);
                    }}
                    firstDay={0}
                    dayHeaderFormat={CalendarConfig.dayHeaderFormat}
                    slotEventOverlap={false}
                    slotDuration={CalendarConfig.slotDuration}
                    dateClick={e => console.log(e.dateStr)}
                    eventClick={e =>
                        callModal(e.event.extendedProps.type, e.event.start, e.event.end)
                    }
                />
                {/* overlay box*/}
                {/* {
                     !isAccessAllowed && (
                        <Flex
                            style={{
                                backdropFilter: 'blur(4px)',
                                zIndex: 5,
                                // background: 'rgba(250, 250, 250, .2)'
                            }}
                            justifyContent={'center'}
                            alignItems={'center'}
                            top={0}
                            right={0}
                            left={0}
                            h={'100%'}
                            w={'100%'}
                            position={'absolute'}
                        >
                            <LargePassword />
                        </Flex>
                    )
                } */}
            </StyleWrapper>
            {/* <MyActivitiesModal
                    isOpen={isMyActivitiesModalOpen}
                    onClose={onCloseMyActivitiesModal}
                    startEndConfig={startEndConfig}
                /> */}
            <OpenHousesModal
                isOpen={isOpenHousesModalOpen}
                onClose={onCloseOpenHousesModal}
                startEndConfig={startEndConfig}
            />
            <OpportunitiesModal
                isOpen={isOpenOpportunitiesOpen}
                onClose={onCloseOpportunities}
                startEndConfig={startEndConfig}
            />
            <AssistanceRequestsModal
                isOpen={isAssistanceRequestsModalOpen}
                onClose={onCloseAssistanceRequests}
                startEndConfig={startEndConfig}
            />
            {/* <AgencyActivitiesModal
                    isOpen={isAgencyActivitiesOpen}
                    onClose={onCloseAgencyActivities}
                    startEndConfig={startEndConfig}
                /> */}
            <ActivitiesModalsContainter
                isMyActivitiesModalOpen={isMyActivitiesModalOpen}
                onCloseMyActivitiesModal={onCloseMyActivitiesModal}
                isAgencyActivitiesOpen={isAgencyActivitiesOpen}
                onCloseAgencyActivities={onCloseAgencyActivities}
                startEndConfig={startEndConfig}
            />
        </>
    );
};
