import React from 'react';
import {
    Box,
    Grid,
    GridItem,
    Text,
    Link,
    Container,
    TextProps,
    VStack,
    HStack,
    Center,
    IconButton,
    useBreakpointValue,
    Image,
    useMediaQuery,
} from '@chakra-ui/react';
import { colors, socials } from '../../constants';
import { fonts } from '../../utils';
import DownloadAppstore from '../../../assets/landing/download-appstore.png';
import DownloadGoogleplay from '../../../assets/landing/download-googleplay.png';
import { BottomCity } from '../../../assets/img';
import { VideoModal } from './VideoModal';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../store';
import { toggleLandingVideoModal } from '../../../store/actions/landing.actions';
import { ReactComponent as YoutubeIcon } from '../../../assets/landing/ic_youtube.svg';

export const LandingFooter: React.FC = () => {
    const [isAgentsVertical] = useMediaQuery('(max-width: 530px)');
    const dispatch = useDispatch();
    const bottomBgHeight = useBreakpointValue({ base: '70', md: '130', lg: '250' });

    const videoModalOpen = useSelector((state: RootStore) => state.landing.videoModalOpen);

    const sectionTitleProps: TextProps = {
        color: 'white',
        fontFamily: fonts.montserrat,
        fontWeight: isAgentsVertical ? '700' : '600',
        fontSize: isAgentsVertical ? '15px' : '16px',
        letterSpacing: '0.1rem',
        textTransform: 'uppercase',
    };

    const linkProps = {
        color: 'white',
        fontFamily: fonts.montserrat,
        fontSize: '13px',
        fontWeight: isAgentsVertical ? '400' : '600',
    };

    return (
        <Box bg={colors.brand} h={{ base: 'auto', lg: '340px' }} overflowX="hidden">
            <Container
                maxW="container.xl"
                h="100%"
                position="relative"
                zIndex={10}
            >
                <Grid
                    templateColumns={{ base: '1fr 1fr', lg: 'repeat(4, 1fr)' }}
                    // templateRows={{ base: '1fr 1fr 1fr', lg: '1fr 1fr' }}
                    h="100%"
                    p="50px 0"
                    rowGap={{ base: '40px', lg: 0 }}
                >
                    <GridItem rowSpan={{ base: 1, lg: 2 }} colSpan={1} h="100%">
                        <Text {...sectionTitleProps}>company</Text>
                        <VStack align="flex-start" mt="10px">
                            <Link href="/about-us" {...linkProps}>
                                About
                            </Link>
                            <Link href="/zoot-pro-edge" {...linkProps}>
                                The ZootPro Edge
                            </Link>
                            <Link href="/terms-and-conditions" {...linkProps}>
                                Terms of Service
                            </Link>
                            <Link href="/privacy-policy" {...linkProps}>
                                Privacy Policy
                            </Link>
                        </VStack>
                    </GridItem>

                    <GridItem rowSpan={{ base: 1, lg: 2 }} colSpan={1} h="100%">
                        <Text {...sectionTitleProps}>resources</Text>
                        <VStack align="flex-start" mt="10px">
                            <Link href="/why-zoot-pro" {...linkProps}>
                                Why ZootPro?
                            </Link>

                            <Link href="/help" {...linkProps}>
                                Help & FAQs
                            </Link>

                            {/* <Link href="/pricing" {...linkProps}>
                                Subscriptions
                            </Link> */}

                            <Link href="/agency-relationship" {...linkProps}>
                                Brokerage Cooperation
                            </Link>

                            <Link href="/contact" {...linkProps}>
                                Contact Us
                            </Link>
                        </VStack>
                    </GridItem>

                    <GridItem rowSpan={{ base: 1, lg: 2 }} colSpan={1} h="100%">
                        <Text {...sectionTitleProps}>product</Text>
                        <VStack align="flex-start" mt="10px">
                            <Link href="/features" {...linkProps}>
                                Features
                            </Link>

                            <Link href="/how-it-works" {...linkProps}>
                                How It Works
                            </Link>
                        </VStack>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={{ base: 2, lg: 1 }}>
                        <Text mb="13px" {...sectionTitleProps}>
                            DOWNLOAD OUR APP
                        </Text>
                        <HStack spacing="25px">
                            <Link
                                w={{ base: '120px' }}
                                target={'_blank'}
                                href="https://apps.apple.com/app/zootpro/id1601250757"
                            >
                                <Image src={DownloadAppstore} />
                            </Link>
                            <Link
                                w={{ base: '135px' }}
                                target={'_blank'}
                                href="https://play.google.com/store/apps/details?id=com.zootpro"
                            >
                                <Image src={DownloadGoogleplay} />
                            </Link>
                        </HStack>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={{ base: 2, lg: 1 }}>
                        <Text {...sectionTitleProps}>Follow us</Text>
                        <HStack mt="15px" spacing={{ base: '25px', sm: '30px' }}>
                            {socials.map(elem => (
                                <Link href={elem.link} key={elem.link}>
                                    {elem.icon}
                                </Link>
                            ))}
                            <IconButton
                                aria-label="video link"
                                variant="unstyled"
                                onClick={() => dispatch(toggleLandingVideoModal(true))}
                            >
                                <YoutubeIcon />
                            </IconButton>
                        </HStack>
                    </GridItem>
                </Grid>
            </Container>
            <Center position="absolute" bottom="0" overflow="hidden" w="100%" zIndex={0}>
                <BottomCity height={bottomBgHeight} />
            </Center>
            <VideoModal
                isOpen={videoModalOpen}
                onClose={() => dispatch(toggleLandingVideoModal(false))}
            />
        </Box>
    );
};
