import React from 'react';
import { Box, Text, Flex, Center } from '@chakra-ui/react';

interface IYearSummaryItemProps {
    label: string;
    amount: number;
}

export const YearSummaryItem: React.FC<IYearSummaryItemProps> = ({ amount, label }) => (
    <Center bg="white" w="100%" h="90px" borderRadius="20px">
        <Box>
            <Text
                color="brand"
                fontWeight="semibold"
                fontSize="23px"
                lineHeight="34px"
                align="center"
            >
                {amount}
            </Text>
            <Text color="#415EB6" fontSize="16px" fontWeight="light" align="center">
                {label}
            </Text>
        </Box>
    </Center>
);
