import React from 'react';
import { LandingFooter, LandingHeader } from '../../../common/components/landing';
import ContactBG from '../../../assets/landing/contact-header.png';
import { Box, Container, Flex, Heading, HStack, Link, Text, IconButton } from '@chakra-ui/react';
import { fonts } from '../../../common/utils';
import { contactPoints, socialsDark } from '../../../common/constants';
import { ContactForm } from './components/ContactForm';
import { ContactInfoBlock } from './components/ContactInfoBlock';
import { ReactComponent as YoutubeDarkIcon } from '../../../assets/landing/ic_youtube_dark.svg';
import { SendContactFormType } from '../../../common/types';

interface IContactProps {
    openVideo: () => void;
    onSendContactForm: (data: SendContactFormType) => void;
    sendingContactLoading: boolean;
}

export const Contact: React.FC<IContactProps> = ({
    openVideo,
    onSendContactForm,
    sendingContactLoading,
}) => {
    return (
        <>
            <LandingHeader />
            <Box
                h={{ base: '300px', lg: '450px' }}
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={ContactBG}
                position="relative"
                backgroundSize="cover"
                overflowY="hidden"
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />
                <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                    <Flex direction="column" height="100%" justify="center" pb="70px">
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={{ base: '28px', lg: '52px' }}
                            lineHeight={{ base: '32px', lg: '62px' }}
                            fontWeight="bold"
                            w={{ base: '100%', lg: '60%' }}
                        >
                            Contact
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize="16px"
                            lineHeight="28px"
                            my="23px"
                        >
                            Get in touch! We would love to hear from you!
                        </Text>
                    </Flex>
                </Container>
            </Box>
            <Box
                bg="white"
                borderTopRadius={{ base: '30px', md: '70px' }}
                mt="-70px"
                zIndex={10}
                position="relative"
            >
                <Container maxW="container.xl">
                    <Flex
                        py={{ base: '30px', lg: '75px' }}
                        justifyContent="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <Box
                            borderWidth={{ base: '0', lg: '1px' }}
                            padding={{ base: '0', md: '55' }}
                            width={{ base: 'auto', lg: '750px' }}
                        >
                            <ContactForm
                                onSendContactForm={onSendContactForm}
                                sendingContactLoading={sendingContactLoading}
                            />
                        </Box>
                        <Box
                            width={{ base: 'auto', lg: '420px' }}
                            mt={{ base: '40px', lg: 0 }}
                            ml={{ base: '0', lg: '70px' }}
                        >
                            {contactPoints.map(contact => (
                                <ContactInfoBlock key={contact.title} {...contact} />
                            ))}
                            <HStack mt="-30px" spacing={{ base: '15px', md: '30px' }}>
                                {socialsDark.map(elem => (
                                    <Link href={elem.link} key={elem.link}>
                                        {elem.icon}
                                    </Link>
                                ))}
                                <IconButton
                                    aria-label="video link"
                                    variant="unstyled"
                                    onClick={openVideo}
                                >
                                    <YoutubeDarkIcon />
                                </IconButton>
                            </HStack>
                        </Box>
                    </Flex>
                </Container>
            </Box>
            {/*<TrialBanner />*/}
            <LandingFooter />
        </>
    );
};
