import React from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { StarIcon } from '../../../assets/icons';

interface IRateStar {
    setStarCount: (v: number) => void;
    starCount: number;
}

export const RateStar: React.FC<IRateStar> = ({ setStarCount, starCount }) => {
    return (
    <Flex>
        {[1, 2, 3, 4, 5].map(elem => (
            <IconButton
                key={`button${elem}`}
                aria-label={`star rating ${elem}`}
                variant="unstyled"
                mr="30px"
                display="flex"
                alignItems="center"
                onClick={() => setStarCount(elem)}
            >
                <StarIcon color={starCount >= elem ? '#FFAA2A' : '#8D97B5'} />
            </IconButton>
        ))}
    </Flex>
)};
