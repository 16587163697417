import { axiosBase } from '../../../../common/api/api.base';


export const getTwillioToken = async (token: string, actionFunction: (token: string) => void) => {
    const res = await fetch(`${axiosBase.defaults.baseURL}/api/twilio/get-chat-token`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        method: "POST"
    })
    const response = await res.json()
    if (response.success) {
        actionFunction(response.value)
    }
}