import React, { useState } from 'react';
import {
    VStack,
    Checkbox,
    Text,
    Box,
    Flex,
} from '@chakra-ui/react';

import { CustomButton } from '../../../CustomButton/CustomButton';

import { fonts } from '../../../../../utils';
import { colors } from '../../../../../constants';

interface ITypesFilterProps {
    items: {
        categoryTitle?: any;
        fieldsKey?: string;
        categories: {
            label: string;
            value: string | number | boolean;
            marginBottom?: string;
            fontWeight?: number;
            color?: string;
        }[];
        defaultValue?: string[] | number[] | boolean[];
        fontSize?: string;
        marginBottom?: string;
    }[];
    onApply: (value: { [key: string]: string[] | number[] | boolean[] }) => void;
    onReset?: () => void;
}



export const MultipleFilter: React.FC<ITypesFilterProps> = ({
    items,
    onApply,
    onReset,
}) => {
    const [selectedItems, setSelectedItems] = useState<any>(
        Object.fromEntries(
            items.map(elem => [elem.fieldsKey || elem.categoryTitle, elem.defaultValue || []]),
        ),
    );

    const onSelectItem = (value: string | number | boolean, categoryTitle: string) => {
        if (selectedItems[categoryTitle].includes(value)) {
            setSelectedItems({
                ...selectedItems,
                [categoryTitle]: selectedItems[categoryTitle].filter(
                    (elem: string | number | boolean) => elem !== value,
                ),
            });
        } else {
            setSelectedItems({
                ...selectedItems,
                [categoryTitle]: [...selectedItems[categoryTitle], value],
            });
        }
    };

    return (
        <>
            <Flex w="500x" p="20px 10px 0px" overflowY="auto" flexDirection="column">
                <Box mb="100px">
                    {items.map(elem => (
                        <>
                            <Box key={elem.fieldsKey} mb="15px">
                                <Text
                                    color={colors.brand}
                                    fontSize={elem.fontSize || '16px'}
                                    fontWeight={600}
                                    mb="10px"
                                    lineHeight="22px"
                                    fonts={fonts.poppins}
                                >
                                    {elem.categoryTitle}
                                </Text>
                                <VStack
                                    align="flex-center"
                                    mb={elem.marginBottom || '0px'}
                                    justifyContent="center"
                                >
                                    {elem.categories.map(function (cat) {
                                        return (
                                            cat.label && (
                                                <Checkbox
                                                    key={cat.label}
                                                    // defaultIsChecked={cat.defaultValue}
                                                    isChecked={selectedItems[
                                                        elem.fieldsKey || elem.categoryTitle
                                                    ].includes(cat.value)}
                                                    onChange={ev =>
                                                        onSelectItem(
                                                            cat.value,
                                                            elem.fieldsKey || elem.categoryTitle,
                                                        )
                                                    }
                                                    colorScheme="checkboxScheme"
                                                >
                                                    <Text
                                                        color={cat.color || colors.brand}
                                                        fontSize={'14px'}
                                                        fontWeight={cat.fontWeight}
                                                        lineHeight="21px"
                                                        fonts={fonts.poppins}
                                                    >
                                                        {cat.label}
                                                    </Text>
                                                </Checkbox>
                                            )
                                        );
                                    })}
                                </VStack>
                            </Box>
                        </>
                    ))}
                    </Box>
                <Flex alignItems="center" justifyContent="space-between">
                    <CustomButton
                        w="225px"
                        mr="12px"
                        text="Reset"
                        height="60px"
                        bgColor="white"
                        textColor={colors.brand}
                        border="1px solid #888DAB"
                        onClick={() => {
                            onReset?.();
                            setSelectedItems(
                                Object.fromEntries(
                                    items.map(elem => [elem.fieldsKey || elem.categoryTitle, []]),
                                ),
                            );
                        }}
                    />
                    <CustomButton
                        w="225px"
                        text="Apply"
                        height="60px"
                        bgColor="brand"
                        onClick={() => {
                            onApply(selectedItems);
                        }}
                    />
                </Flex>
            </Flex>
        </>
    );
};
