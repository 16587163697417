import React from 'react';
import { colors } from '../../common/constants';

export const PagginationLeftArrow: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.02341 9.99999L13.1484 14.125L11.9701 15.3033L6.66675 9.99999L11.9701 4.69666L13.1484 5.87499L9.02341 9.99999Z"
            fill={colors.brand}
        />
    </svg>
);
