import React from 'react';
import { Flex, Text, Center } from '@chakra-ui/react';
import { Notification } from '../../../../../assets/icons';
import '../styles.css';
import { notificationsKeyWordParse } from '../../../../utils';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { Notification as NotificationType } from '../../../../../pages/Notifications/types';

interface INotificationsList {
    notifications: NotificationType[];
    userNotificationsLastReadTime: string;
    handlePressNotification: (value: NotificationType) => void;
}

export const NotificationsList: React.FC<INotificationsList> = ({
    notifications,
    userNotificationsLastReadTime,
    handlePressNotification,
}) => {
    return (
        <Flex direction="column" id="list" p="10px">
            {notifications.length ? (
                notifications?.map((item: NotificationType, index: number) => (
                    <Flex
                        key={index}
                        bgColor="#F6F7FB"
                        w="100%"
                        borderRadius="16px"
                        justify="space-between"
                        align="center"
                        p="15px"
                        id={index.toString()}
                        as="button"
                        onClick={() => handlePressNotification(item)}
                        mb="10px"
                    >
                        <Flex>
                            <Text fontSize="14px" lineHeight="19px" color="brand">
                                {parse(notificationsKeyWordParse(item.content))}
                            </Text>
                        </Flex>
                        {userNotificationsLastReadTime !== null &&
                        dayjs(userNotificationsLastReadTime).isBefore(item.eventDate) ? (
                            <Notification width="15" height="15" />
                        ) : null}
                    </Flex>
                ))
            ) : (
                <Center height="300px">
                    <Text color="brand">There is no notifications yet</Text>
                </Center>
            )}
        </Flex>
    );
};
