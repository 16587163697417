import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { fonts } from '../../utils';

interface MainPageHelpPoint {
    icon: JSX.Element;
    title: string;
    description: string;
    index: number;
}

export const MainPageHelpPoint: React.FC<MainPageHelpPoint> = ({ description, icon, title, index }) => (
    <Flex
        borderRadius="16px"
        p={{ base: '20px 5px', lg: '37px 5px' }}
        flex={1}
        alignItems="center"
        flexDirection="column"
        m={index === 1 ? { base: '25px 0', md: '0 25px' } : 0}
    >
        <Text height={236}>{icon}</Text>
        <Text
            fontSize={{ base: '16px', lg: '20px' }}
            color="brand"
            lineHeight="28px"
            fontWeight="700"
            mb="16px"
            textAlign="center"
            fontFamily={fonts.montserrat}
        >
            {title}
        </Text>
        <Text
            textAlign="center"
            color="brand"
            fontSize="16px"
            lineHeight="26px"
            fontFamily={fonts.mulish}
            fontWeight="400"
        >
            {description}
        </Text>
    </Flex>
);
