import React from 'react';
import {
    Box,
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Checkbox,
    VStack,
    Flex,
    Button,
} from '@chakra-ui/react';
import { FilterIcon } from '../../../../../assets/icons';
import { fonts } from '../../../../utils';

interface ITestSearchMenuProps {
    typeIdsSelected: number[];
    setTypeIdsSelected: (value: number[]) => void;
    menuValues: { label: string; value: number }[];
    width?: string;
    isBorder?: boolean;
}

export const SearchMenu: React.FC<ITestSearchMenuProps> = ({
    menuValues,
    setTypeIdsSelected,
    typeIdsSelected,
    width,
    isBorder
}) => {
    const onChangeFilter = (checked: boolean, value: number) => {
        if (checked) {
            setTypeIdsSelected([...typeIdsSelected, value]);
        } else {
            setTypeIdsSelected(typeIdsSelected.filter(elem => elem !== value));
        }
    };

    return (
        <Flex flexDirection="column">
            <Text color="brand" fontWeight="300" fontSize="13px" fontFamily={fonts.poppins}>
                Property type
            </Text>
            <Popover placement="auto-start">
                <PopoverTrigger>
                    <Flex
                        bg="#F6F7FB"
                        borderRadius="25px"
                        borderWidth={isBorder ? '1px' : '0'}
                        h="50px"
                        w={width}
                        align="center"
                        px="20px"
                        justifyContent="flex-start"
                        fontWeight="normal"
                        as={Button}
                    >
                        <FilterIcon />
                        <Text
                            fontSize="14px"
                            lineHeight="21px"
                            color="secondaryFontDarker"
                            ml="20px"
                            isTruncated
                        >
                            {typeIdsSelected.length
                                ? menuValues
                                      .filter(elem => typeIdsSelected.includes(elem.value))
                                      .map(elem => elem.label)
                                      .join(', ')
                                : 'Filter'}
                        </Text>
                    </Flex>
                </PopoverTrigger>
                <PopoverContent borderRadius="20px">
                    <VStack align="flex-start" p="15px">
                        {menuValues.map(elem => (
                            <Checkbox
                                key={elem.value}
                                checked={typeIdsSelected.includes(elem.value)}
                                onChange={ev => onChangeFilter(ev.target.checked, elem.value)}
                                colorScheme="checkboxScheme"
                            >
                                <Text fontSize="14px" lineHeight="21px" color="secondaryFontDarker">
                                    {elem.label}
                                </Text>
                            </Checkbox>
                        ))}
                    </VStack>
                </PopoverContent>
            </Popover>
        </Flex>
    );
};
