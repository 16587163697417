import React, { Dispatch, SetStateAction } from 'react';
import { Box, Text, Grid, GridItem } from '@chakra-ui/react';

import { Loader } from '../../../../common/components';

import { AdvertisingPostTypeResponse } from '../../types';
import { AdvertisingPost } from '../AdvertisingPost/AdvertisingPost';

interface IPostTabProps {
    advertisingPostsLoading: boolean;
    advertisingPosts: AdvertisingPostTypeResponse[];
    setDetailsPostId: Dispatch<SetStateAction<number>>;
}

export const AdvertisingPosts: React.FC<IPostTabProps> = ({
    advertisingPosts,
    advertisingPostsLoading,
    setDetailsPostId,
}) => (
    <>
        {advertisingPostsLoading ? (
            <Loader spinnerSize={'md'} centerHeight="calc(90vh - 140px)" />
        ) : (
            <Box mt={'32px'}>
                <Text
                    mb={'19px'}
                    fontSize={'16px'}
                    fontWeight={'400'}
                    lineHeight={'25px'}
                    color={'white'}
                    pl="5px"
                >
                    Recommended
                </Text>
                <Grid rowGap={30} columnGap={15} gridTemplateRows={'320px, 1fr'} gridTemplateColumns={'1fr 1fr 1fr'}>
                    {advertisingPosts.map((item, index) => (
                        <GridItem
                            colSpan={index === 0 ? 3 : undefined}
                            key={item.id}
                        >
                            <AdvertisingPost setDetailsPostId={setDetailsPostId} item={item} />
                        </GridItem>
                    ))}
                </Grid>
            </Box>
        )}
    </>
);
