import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { RootStore } from '../../../store';
import { SignUp } from './SignUp';
import { SubmitHandler } from 'react-hook-form';
import {
    SignUpPayload,
    SignUpConfirmCodePayload,
    SignUpSendPhoneCodePayload,
    SignUpConfirmPhoneCodePayload,
} from './types';
import {
    signUpSendEmailCode,
    signUpConfirmEmailCode,
    signUpSendPhoneCode,
    signUpConfirmPhoneCode,
    signUpSetStep,
} from './store/actions';
import { IAuthContainerProps } from '../../../common/types';
import { useHistory } from 'react-router-dom';

export const SignUpContainer: React.FC<IAuthContainerProps> = ({ nestedPath }) => {
    const history = useHistory();
    const loading = useSelector((state: RootStore) => state.signUp.loading);
    const errors = useSelector((state: RootStore) => state.signUp.errors);
    const step = useSelector((state: RootStore) => state.signUp.step);
    const accessToken = useSelector((state: RootStore) => state.signUp.accessToken);

    const dispatch = useDispatch();
    const onSendEmailCode: SubmitHandler<SignUpPayload> = payload =>
        dispatch(signUpSendEmailCode(payload));
    const onConfirmEmail: SubmitHandler<SignUpConfirmCodePayload> = payload =>
        dispatch(signUpConfirmEmailCode(payload));
    const onSendPhoneCode: SubmitHandler<SignUpSendPhoneCodePayload> = payload =>
        dispatch(signUpSendPhoneCode(payload));
    const onConfirmPhone: SubmitHandler<SignUpConfirmPhoneCodePayload> = payload =>
        dispatch(signUpConfirmPhoneCode(payload));
    const onSetStep: (payload: number) => void = payload => dispatch(signUpSetStep(payload));

    return (
        <SignUp
            loading={loading}
            stateErrors={errors}
            onSendEmailCode={onSendEmailCode}
            nestedPath={nestedPath}
            step={step}
            onConfirmEmail={onConfirmEmail}
            onSendPhoneCode={onSendPhoneCode}
            onConfirmPhone={onConfirmPhone}
            accessToken={accessToken}
            onSetStep={onSetStep}
            goBack={() => history.goBack()}
        />
    );
};
