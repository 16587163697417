import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    IconButton,
    CloseButton,
    chakra,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BackArrow } from '../../../assets/icons';
import { CustomInput } from '../../../common/components';
import { colors } from '../../../common/constants';
import { AddCalendarPayload } from '../types';
import { createWorkHoursItem, transformStringToDate } from '../helper';
import { convertTimeIn24HoursFormat } from '../../../common/utils';

interface IManualCalendarEditor {
    isOpen: boolean;
    onClose: () => void;
    workHours: any;
    addCalendar: (v: AddCalendarPayload) => void;
    setWorkHoursItem: (v: any) => void;
}

const yupConvertTimeTo12Hours = (value: string) => convertTimeIn24HoursFormat(value);

//Resolver
const workHoursResolver = yupResolver(
    yup.object().shape({
        from1: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('from-time', 'Should be earlier than End time', function (value) {
                return !(
                    value &&
                    this.parent.to1 &&
                    new Date(transformStringToDate(value)).getTime() >
                        new Date(transformStringToDate(this.parent.to1)).getTime()
                );
            })
            .nullable(),
        to1: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('to-time', 'Should be later than Start Time', function (value) {
                return !(
                    value &&
                    this.parent.from1 &&
                    new Date(transformStringToDate(value)).getTime() <
                        new Date(transformStringToDate(this.parent.from1)).getTime()
                );
            })
            .nullable(),
        from2: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('from-time', 'Should be earlier than End time', function (value) {
                return !(
                    value &&
                    this.parent.to2 &&
                    new Date(transformStringToDate(value)).getTime() >
                        new Date(transformStringToDate(this.parent.to2)).getTime()
                );
            })
            .nullable(),
        to2: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('to-time', 'Should be later than Start Time', function (value) {
                return !(
                    value &&
                    this.parent.from2 &&
                    new Date(transformStringToDate(value)).getTime() <
                        new Date(transformStringToDate(this.parent.from2)).getTime()
                );
            })
            .nullable(),
        from3: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('from-time', 'Should be earlier than End time', function (value) {
                return !(
                    value &&
                    this.parent.to3 &&
                    new Date(transformStringToDate(value)).getTime() >
                        new Date(transformStringToDate(this.parent.to3)).getTime()
                );
            })
            .nullable(),
        to3: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('to-time', 'Should be later than Start Time', function (value) {
                return !(
                    value &&
                    this.parent.from3 &&
                    new Date(transformStringToDate(value)).getTime() <
                        new Date(transformStringToDate(this.parent.from3)).getTime()
                );
            })
            .nullable(),
        from4: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('from-time', 'Should be earlier than End time', function (value) {
                return !(
                    value &&
                    this.parent.to4 &&
                    new Date(transformStringToDate(value)).getTime() >
                        new Date(transformStringToDate(this.parent.to4)).getTime()
                );
            })
            .nullable(),
        to4: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('to-time', 'Should be later than Start Time', function (value) {
                return !(
                    value &&
                    this.parent.from4 &&
                    new Date(transformStringToDate(value)).getTime() <
                        new Date(transformStringToDate(this.parent.from4)).getTime()
                );
            })
            .nullable(),
        from5: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('from-time', 'Should be earlier than End time', function (value) {
                return !(
                    value &&
                    this.parent.to5 &&
                    new Date(transformStringToDate(value)).getTime() >
                        new Date(transformStringToDate(this.parent.to5)).getTime()
                );
            })
            .nullable(),
        to5: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('to-time', 'Should be later than Start Time', function (value) {
                return !(
                    value &&
                    this.parent.from5 &&
                    new Date(transformStringToDate(value)).getTime() <
                        new Date(transformStringToDate(this.parent.from5)).getTime()
                );
            })
            .nullable(),
        from6: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('from-time', 'Should be earlier than End time', function (value) {
                return !(
                    value &&
                    this.parent.to6 &&
                    new Date(transformStringToDate(value)).getTime() >
                        new Date(transformStringToDate(this.parent.to6)).getTime()
                );
            })
            .nullable(),
        to6: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('to-time', 'Should be later than Start Time', function (value) {
                return !(
                    value &&
                    this.parent.from6 &&
                    new Date(transformStringToDate(value)).getTime() <
                        new Date(transformStringToDate(this.parent.from6)).getTime()
                );
            })
            .nullable(),
        from7: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('from-time', 'Should be earlier than End time', function (value) {
                return !(
                    value &&
                    this.parent.to7 &&
                    new Date(transformStringToDate(value)).getTime() >
                        new Date(transformStringToDate(this.parent.to7)).getTime()
                );
            })
            .nullable(),
        to7: yup
            .string()
            .transform(yupConvertTimeTo12Hours)
            .test('to-time', 'Should be later than Start Time', function (value) {
                return !(
                    value &&
                    this.parent.from7 &&
                    new Date(transformStringToDate(value)).getTime() <
                        new Date(transformStringToDate(this.parent.from7)).getTime()
                );
            })
            .nullable(),
    }),
);

export const ManualCalendarEditor: React.FC<IManualCalendarEditor> = ({
    isOpen,
    onClose,
    workHours,
    addCalendar,
    setWorkHoursItem,
}) => {
    const {
        formState: { errors },
        reset,
        handleSubmit,
        register,
        clearErrors,
    } = useForm({
        resolver: workHoursResolver,
    });

    const onSubmit = (data: {
        from1: string;
        to1: string;
        from2: string;
        to2: string;
        from3: string;
        to3: string;
        from4: string;
        to4: string;
        from5: string;
        to5: string;
        from6: string;
        to6: string;
        from7: string;
        to7: string;
    }) => {
        const from: string[] = [];
        const to: string[] = [];

        for (let key in data) {
            if (key.includes('from')) {
                // @ts-ignore
                const fromTime = convertTimeIn24HoursFormat(data[key]);
                from.push(fromTime);
            } else {
                if (key.includes('to')) {
                    // @ts-ignore
                    const toTime = convertTimeIn24HoursFormat(data[key]);
                    to.push(toTime);
                }
            }
        }

        const payload = {
            agencyId: +workHours.agencyId,
            agency: workHours.agency,
            workHours: [
                createWorkHoursItem(0, from[1], to[1]),
                createWorkHoursItem(1, from[2], to[2]),
                createWorkHoursItem(2, from[3], to[3]),
                createWorkHoursItem(3, from[4], to[4]),
                createWorkHoursItem(4, from[5], to[5]),
                createWorkHoursItem(5, from[6], to[6]),
                createWorkHoursItem(6, from[7], to[7]),
            ],
        };
        addCalendar(payload);
        onClose();
        setWorkHoursItem({});
    };

    useEffect(() => {
        if (!isOpen) {
            clearErrors();
            reset({});
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'80vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <IconButton
                        aria-label="close modal"
                        onClick={onClose}
                        d="flex"
                        variant="unstyled"
                        alignItems="center"
                    >
                        <BackArrow />
                    </IconButton>
                    <ModalHeader flex={1} align="center">
                        <Text color="brand">Manually</Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody d="flex" w="100%" flexDirection="column" overflowY="auto">
                    <Flex
                        direction="column"
                        align="center"
                        height="100%"
                        justify="space-between"
                        pb="30px"
                    >
                        <Flex align="center" direction="column" width="100%">
                            <chakra.form width="70%" onSubmit={handleSubmit(onSubmit)}>
                                <Flex width="100%" mb="20px">
                                    <Text fontSize="16px" fontWeight="700">
                                        Work hours
                                    </Text>
                                </Flex>
                                <Flex width="100%" justify="space-between" align="center" mb="15px">
                                    <Text fontSize="18px" fontWeight="300" color="brand">
                                        Monday
                                    </Text>
                                    <Flex>
                                        <Flex mr="15px">
                                            <CustomInput
                                                errors={errors?.from1}
                                                label=""
                                                register={{ ...register('from1') }}
                                                type="time-12-hours"
                                                placeholder="From"
                                                defaultValue={
                                                    workHours?.workHours?.[0]?.from !== null
                                                        ? workHours?.workHours?.[0]?.from.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                        <Flex>
                                            <CustomInput
                                                errors={errors?.to1}
                                                label=""
                                                register={{ ...register('to1') }}
                                                type="time-12-hours"
                                                placeholder="To"
                                                defaultValue={
                                                    workHours?.workHours?.[0]?.to !== null
                                                        ? workHours?.workHours?.[0]?.to.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex width="100%" justify="space-between" align="center" mb="15px">
                                    <Text fontSize="18px" fontWeight="300" color="brand">
                                        Tuesday
                                    </Text>
                                    <Flex>
                                        <Flex mr="15px">
                                            <CustomInput
                                                errors={errors?.from2}
                                                label=""
                                                register={{ ...register('from2') }}
                                                type="time-12-hours"
                                                placeholder="From"
                                                defaultValue={
                                                    workHours?.workHours?.[1]?.from !== null
                                                        ? workHours?.workHours?.[1]?.from.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                        <Flex>
                                            <CustomInput
                                                errors={errors?.to2}
                                                label=""
                                                register={{ ...register('to2') }}
                                                type="time-12-hours"
                                                placeholder="To"
                                                defaultValue={
                                                    workHours?.workHours?.[1]?.to !== null
                                                        ? workHours?.workHours?.[1]?.to.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex width="100%" justify="space-between" align="center" mb="15px">
                                    <Text fontSize="18px" fontWeight="300" color="brand">
                                        Wednesday
                                    </Text>
                                    <Flex>
                                        <Flex mr="15px">
                                            <CustomInput
                                                errors={errors?.from3}
                                                label=""
                                                register={{ ...register('from3') }}
                                                type="time-12-hours"
                                                placeholder="From"
                                                defaultValue={
                                                    workHours?.workHours?.[2]?.from !== null
                                                        ? workHours?.workHours?.[2]?.from.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                        <Flex>
                                            <CustomInput
                                                errors={errors?.to3}
                                                label=""
                                                register={{ ...register('to3') }}
                                                type="time-12-hours"
                                                placeholder="To"
                                                defaultValue={
                                                    workHours?.workHours?.[2]?.to !== null
                                                        ? workHours?.workHours?.[2]?.to.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex width="100%" justify="space-between" align="center" mb="15px">
                                    <Text fontSize="18px" fontWeight="300" color="brand">
                                        Thursday
                                    </Text>
                                    <Flex>
                                        <Flex mr="15px">
                                            <CustomInput
                                                errors={errors?.from4}
                                                label=""
                                                register={{ ...register('from4') }}
                                                type="time-12-hours"
                                                placeholder="From"
                                                defaultValue={
                                                    workHours?.workHours?.[3]?.from !== null
                                                        ? workHours?.workHours?.[3]?.from.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                        <Flex>
                                            <CustomInput
                                                errors={errors?.to4}
                                                label=""
                                                register={{ ...register('to4') }}
                                                type="time-12-hours"
                                                placeholder="To"
                                                defaultValue={
                                                    workHours?.workHours?.[3]?.to !== null
                                                        ? workHours?.workHours?.[3]?.to.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex width="100%" justify="space-between" align="center" mb="15px">
                                    <Text fontSize="18px" fontWeight="300" color="brand">
                                        Friday
                                    </Text>
                                    <Flex>
                                        <Flex mr="15px">
                                            <CustomInput
                                                errors={errors?.from5}
                                                label=""
                                                register={{ ...register('from5') }}
                                                type="time-12-hours"
                                                placeholder="From"
                                                defaultValue={
                                                    workHours?.workHours?.[4]?.from !== null
                                                        ? workHours?.workHours?.[4]?.from.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                        <Flex>
                                            <CustomInput
                                                errors={errors?.to5}
                                                label=""
                                                register={{ ...register('to5') }}
                                                type="time-12-hours"
                                                placeholder="To"
                                                defaultValue={
                                                    workHours?.workHours?.[4]?.to !== null
                                                        ? workHours?.workHours?.[4]?.to.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex width="100%" justify="space-between" align="center" mb="15px">
                                    <Text fontSize="18px" fontWeight="300" color="brand">
                                        Saturday
                                    </Text>
                                    <Flex>
                                        <Flex mr="15px">
                                            <CustomInput
                                                errors={errors?.from6}
                                                label=""
                                                register={{ ...register('from6') }}
                                                type="time-12-hours"
                                                placeholder="From"
                                                defaultValue={
                                                    workHours?.workHours?.[5]?.from !== null
                                                        ? workHours?.workHours?.[5]?.from.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                        <Flex>
                                            <CustomInput
                                                errors={errors?.to6}
                                                label=""
                                                register={{ ...register('to6') }}
                                                type="time-12-hours"
                                                placeholder="To"
                                                defaultValue={
                                                    workHours?.workHours?.[5]?.to !== null
                                                        ? workHours?.workHours?.[5]?.to.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex width="100%" justify="space-between" align="center" mb="15px">
                                    <Text fontSize="18px" fontWeight="300" color="brand">
                                        Sunday
                                    </Text>
                                    <Flex>
                                        <Flex mr="15px">
                                            <CustomInput
                                                errors={errors?.from7}
                                                label=""
                                                register={{ ...register('from7') }}
                                                type="time-12-hours"
                                                placeholder="From"
                                                defaultValue={
                                                    workHours?.workHours?.[6]?.from !== null
                                                        ? workHours?.workHours?.[6]?.from.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                        <Flex>
                                            <CustomInput
                                                errors={errors?.to7}
                                                label=""
                                                register={{ ...register('to7') }}
                                                type="time-12-hours"
                                                placeholder="To"
                                                defaultValue={
                                                    workHours?.workHours?.[6]?.to !== null
                                                        ? workHours?.workHours?.[6]?.to.slice(
                                                              11,
                                                              16,
                                                          )
                                                        : ''
                                                }
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Button h="60px" w="100%" variant="main" mb="35px" type="submit">
                                    Save
                                </Button>
                            </chakra.form>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
