import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import 'dayjs/locale/en';
import range from 'lodash.range';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { SelectValue } from '../../../types';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(weekday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(duration);

export const getTimeOffset = (timeZone?: string) => {
    if (!timeZone) {
        return dayjs().utcOffset() / 60 < 16 && dayjs().utcOffset() / 60 > -16
            ? dayjs().utcOffset() / 60
            : dayjs().utcOffset();
    }

    return dayjs().tz(timeZone).utcOffset() / 60 < 16 && dayjs().tz(timeZone).utcOffset() / 60 > -16
        ? dayjs().tz(timeZone).utcOffset() / 60
        : dayjs().tz(timeZone).utcOffset();
};

export const getDuration = (startTime: string, endTime: string) => {
    const x = dayjs(startTime);
    const y = dayjs(endTime);

    return dayjs.duration(x.diff(y));
};

export const isLeapYear = (year: number): boolean =>
    (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

export const dateIsBetween = (date: number, start: number, end: number): boolean =>
    date > start && date < end;

export const dateIsOut = (date: number, start: number, end: number): boolean =>
    date < start || date > end;

export const formartTime = (value: string): string => `0${value}`.slice(-2);

export const yearsList = (): SelectValue[] => {
    const year = new Date().getFullYear();
    return Array.from(new Array(10), (val, index) => index + year).map(year => ({
        value: year.toString(),
        label: year.toString(),
    }));
};

export const getDaysNewWay = (selectedMonth: number, selectedYear: number): number[] => {
    const daysInMonth = dayjs.utc(`${selectedYear}-${selectedMonth + 1}-1`).daysInMonth();

    const dayObjOf1 = dayjs
        .utc(`${selectedYear}-${selectedMonth + 1}-1`)
        .hour(0)
        .minute(0)
        .millisecond(0);
    const weekDayOf1 = dayObjOf1.utc().day();

    const dayObjOfLast = dayjs
        .utc(`${selectedYear}-${selectedMonth + 1}-${daysInMonth}`)
        .hour(0)
        .minute(0)
        .millisecond(0);
    const weekDayOfLast = dayObjOfLast.utc().day();
    return [
        ...range(weekDayOf1 >= 1 ? weekDayOf1 : 0).map((i: number) =>
            dayObjOf1
                .utc()
                .subtract(weekDayOf1 - i, 'day')
                .valueOf(),
        ),
        ...range(daysInMonth).map((i: number) =>
            dayjs.utc(`${selectedYear}-${selectedMonth + 1}-${i + 1}`).valueOf(),
        ),
        ...range(6 - weekDayOfLast).map((i: number) =>
            dayObjOfLast
                .utc()
                .add(i + 1, 'day')
                .valueOf(),
        ),
    ];
};

export function sliceIntoChunks(arr: number[], chunkSize: number): number[][] {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }

    return res;
}

export const monthLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const dayLabels = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
