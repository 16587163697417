import React from 'react'
import {CreateDwollaAccountForm, PaymentsModalWrapper} from "../components";
import {CreateDwollaAccountPayload} from "../types";

interface IUpdateDwollaAccountModal{
    isOpen:boolean
    title: string
    onClose: () => void
    onUpdateDwollaAccount: (payload: CreateDwollaAccountPayload) => void
    updateDwollaAccountLoading: boolean
}

export const UpdateDwollaAccountModal:React.FC<IUpdateDwollaAccountModal> = ({
  isOpen,
  onClose,
  title,
  onUpdateDwollaAccount ,
  updateDwollaAccountLoading
}) => {
    return (
        <PaymentsModalWrapper isOpen={isOpen} title={title} onClose={onClose}>
            <CreateDwollaAccountForm
                onCreateDwollaAccount={onUpdateDwollaAccount}
                isRetry={true}
                loading={updateDwollaAccountLoading}
            />
        </PaymentsModalWrapper>

    )
}
