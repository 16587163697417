import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, IconButton, chakra } from '@chakra-ui/react';

import { fonts } from '../../../common/utils';
import { MyRequestsType, RequestInfoType } from '../types';
import { BorderIconBox } from './BorderIconBox';
import { colors } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils/formatDateTime';
import {
    CalendarIcon,
    CheckIcon,
    ClockIcon,
    DropdownArrowIcon,
    LocationIcon,
    CloseButton,
    Home,
    WavesIcon,
} from '../../../assets/icons';
import { deleteRequestAfterTimer } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import dayjs from 'dayjs';
import { secondsToMinutes } from '../../../common/utils';
import { ApplyingJobModal } from '../../../common/components/modals/ApplyingJob/ApplyingJobModal';

type AvailableBoxType = {
    request: MyRequestsType;
    handleOpenAvailableModal: (id: number) => void;
    handleAcceptRequest: (IsAccepted: boolean, RequestId: number) => void;
    requestInfo: RequestInfoType;
    onGetRequest: (id: number) => void;
};

export const AvailableBox: React.FC<AvailableBoxType> = ({
    request,
    handleOpenAvailableModal,
    handleAcceptRequest,
    requestInfo,
    onGetRequest,
}) => {
    const dispatch = useDispatch();

    const [timeLeft, setTimeLeft] = useState(() => {
        const date1 = dayjs(request.createdAt);
        const date2 = dayjs();
        const timeDiff = date2.diff(date1, 'seconds');
        return 3600 - timeDiff;
    });

    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
            if (timeLeft <= 0) setTimeLeft(0);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    useEffect(() => {
        if (timeLeft <= 0) {
            dispatch(deleteRequestAfterTimer(request.id));
        }
    }, [timeLeft]);

    useEffect(() => {
        onGetRequest(request.id);
    }, []);

    const { loading } = useSelector((state: RootStore) => state.requests.acceptRequest);

    const requestStates = request.states.join(', ');
    const requestAddresses = request.addresses.join(', ');

    const requestDate = formatDateTime(request.dateTime).date;
    const requestTime = formatDateTime(request.dateTime).time;

    const [agentBonusModalOpen, setAgentBonusModalOpen] = useState(false);

    return (
        <>
            <Box mt="20px">
                <Text
                    mb="10px"
                    color="orange"
                    fontWeight={500}
                    fontSize="15px"
                    lineHeight="18px"
                    fontFamily={fonts.montserrat}
                >
                    Are you available?
                </Text>
                <Flex
                    overflow="hidden"
                    alignItems="center"
                    borderRadius={14}
                    justifyContent="space-between"
                    mb="20px"
                    mt="10px"
                    bg="rgba(209, 210, 219, 0.2)"
                    px="25px"
                >
                    <Flex>
                        <Box py="15px" w="200px">
                            <Text
                                color="brand"
                                fontWeight={600}
                                fontSize="15px"
                                lineHeight="18px"
                                fontFamily={fonts.montserrat}
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                overflow="hidden"
                            >
                                {request.requestTypeName}
                            </Text>
                            <Flex alignItems="center" mt={1}>
                                <LocationIcon />
                                <Text
                                    ml={1}
                                    color="brand"
                                    fontWeight={500}
                                    fontSize="12px"
                                    lineHeight="16px"
                                    fontFamily={fonts.montserrat}
                                    whiteSpace="nowrap"
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                >
                                    {request.typeId === 1 ? requestStates : requestAddresses}
                                </Text>
                            </Flex>
                        </Box>
                        <Flex alignSelf="stretch">
                            <BorderIconBox text={requestDate} icon={<CalendarIcon />} isFirst />
                            <BorderIconBox text={requestTime} icon={<ClockIcon />} />
                            {request.typeId === 1 ? (
                                <BorderIconBox
                                    text={
                                        request.propertiesToShow <= 1
                                            ? `${request.propertiesToShow} house`
                                            : `${request.propertiesToShow} houses`
                                    }
                                    icon={<Home height="15" width="15" color={colors.brand} />}
                                />
                            ) : (
                                <BorderIconBox
                                    text={`${request.duration} hours`}
                                    icon={<WavesIcon />}
                                />
                            )}
                        </Flex>
                    </Flex>
                    <Flex alignItems="center" justifyContent="center" minW="8vw">
                        <ClockIcon color={colors.orange} />
                        <Text
                            ml={1}
                            color={colors.orange}
                            fontWeight={500}
                            fontSize="16px"
                            lineHeight="18px"
                            fontFamily={fonts.montserrat}
                        >
                            {secondsToMinutes(timeLeft)}
                        </Text>
                    </Flex>
                    <Flex alignSelf="stretch">
                        <Flex
                            cursor="pointer"
                            alignItems="center"
                            justifyContent="center"
                            w="8vw"
                            bg={colors.orangeOpacity}
                            onClick={() => handleAcceptRequest(false, request.id)}
                        >
                            <CloseButton color={colors.orange} />
                            <Text
                                ml={2}
                                color="orange"
                                fontWeight={500}
                                fontSize="16px"
                                lineHeight="18px"
                                fontFamily={fonts.montserrat}
                            >
                                No
                            </Text>
                        </Flex>
                        <Flex
                            cursor="pointer"
                            alignItems="center"
                            justifyContent="center"
                            w="8vw"
                            bg={colors.greenOpacity}
                            onClick={() => setAgentBonusModalOpen(true)}
                        >
                            <CheckIcon color={colors.green} />
                            <Text
                                ml={2}
                                color="green"
                                fontWeight={500}
                                fontSize="16px"
                                lineHeight="18px"
                                fontFamily={fonts.montserrat}
                            >
                                Yes
                            </Text>
                        </Flex>
                    </Flex>
                    <IconButton
                        onClick={() => handleOpenAvailableModal(request.id)}
                        bg="transparent"
                        alignSelf="center"
                        aria-label="More for available request"
                        icon={
                            <chakra.span transform="rotate(-90deg)">
                                <DropdownArrowIcon />
                            </chakra.span>
                        }
                    />
                </Flex>
            </Box>

            <ApplyingJobModal
                isOpen={agentBonusModalOpen}
                onClose={() => setAgentBonusModalOpen(false)}
                onConfirm={() => handleAcceptRequest(true, request.id)}
                amount={requestInfo.totalPrice}
                type={'$'}
            />
        </>
    );
};
