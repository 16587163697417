import { call, put, takeEvery } from 'redux-saga/effects';
import { GetAgentOpenHousesSuccessResponse } from '../types';
import {
    getMyAgencyOpenHouses,
    getMyAgencyOpenHousesError,
    getMyAgencyOpenHousesSuccess,
    getPartnerAgencyOpenHouses,
    getPartnerAgencyOpenHousesError,
    getPartnerAgencyOpenHousesSuccess,
} from './actions';
import API from '../../../common/api/api.base';
import { IResponseWithCustomValue } from '../../../common/types';

function* workerGetMyAgencyOpenHouses(action: ReturnType<typeof getMyAgencyOpenHouses>) {
    try {
        const result: IResponseWithCustomValue<GetAgentOpenHousesSuccessResponse> = yield call(
            API.post,
            '/api/listings/my-agency-openhouses',
            action.payload,
        );
        console.log('getMyAgencyOpenHouses action', action, result);
        if (result.success) {
            yield put(
                getMyAgencyOpenHousesSuccess({
                    result,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getMyAgencyOpenHousesError(result.errors));
        }
    } catch (error) {
        console.log('getMyAgencyOpenHousesError', error);
    }
}

function* workerGetPartnerAgencyOpenHouses(action: ReturnType<typeof getPartnerAgencyOpenHouses>) {
    try {
        const result: IResponseWithCustomValue<GetAgentOpenHousesSuccessResponse> = yield call(
            API.post,
            '/api/listings/partner-agency-openhouses',
            action.payload,
        );
        console.log('getPartnerAgencyOpenHouses action', action, result);
        if (result.success) {
            yield put(
                getPartnerAgencyOpenHousesSuccess({
                    result,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getPartnerAgencyOpenHousesError(result.errors));
        }
    } catch (error) {
        console.log('getPartnerAgencyOpenHousesError', error);
    }
}

export default function* watchGetMyAgencyOpenHousesSaga() {
    yield takeEvery(getMyAgencyOpenHouses.type, workerGetMyAgencyOpenHouses);
    yield takeEvery(getPartnerAgencyOpenHouses.type, workerGetPartnerAgencyOpenHouses);
}
