import React from 'react';
import { colors } from '../../common/constants';

export const BankIcon: React.FC = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path
            d="M12.2891 1.26172L12.5127 0.814505C12.3719 0.744123 12.2062 0.744123 12.0655 0.814505L12.2891 1.26172ZM0.476562 23.8117H24.1016V22.8117H0.476562V23.8117ZM12.0655 0.814505L2.61546 5.53951L3.06267 6.43394L12.5127 1.70893L12.0655 0.814505ZM0.476562 9.63673H24.1016V8.63673H0.476562V9.63673ZM21.9627 5.53951L12.5127 0.814505L12.0655 1.70893L21.5155 6.43394L21.9627 5.53951ZM8.63906 13.0742V19.3742H9.63906V13.0742H8.63906ZM14.9391 13.0742V19.3742H15.9391V13.0742H14.9391ZM2.33906 9.13673V23.3117H3.33906V9.13673H2.33906ZM21.2391 9.13673V23.3117H22.2391V9.13673H21.2391Z"
            fill={colors.brand}
        />
    </svg>
);
