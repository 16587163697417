import React, { Dispatch, SetStateAction } from 'react';
import { Box, Flex, Tabs, Text, HStack, TabPanel, TabPanels, Container } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { CustomTabs, CustomTag } from './components';
import { BackButton, CustomButton, DatePicker } from '../../common/components';
import { PostTab } from './tabs';

import { AddReportPayload, AdvertisingPostTypeResponse, PostTypeResponse } from './types';
import { IdNameTypes } from '../../common/types';
import { fonts } from '../../common/utils';
import { AdvertisingPosts } from './components/AdvertisingPosts/AdvertisingPosts';

interface IUpdates {
    onAddReport: (payload: AddReportPayload) => void;
    setTabIndex: Dispatch<SetStateAction<number>>;
    tabIndex: number;
    dateFilter: number;
    setDateFilter: Dispatch<SetStateAction<any>>;
    postTags: IdNameTypes[];
    reportTypes: IdNameTypes[];
    handleSelectTag: (id: number) => void;
    selectedTags: Array<number>;
    onDeletePost: (id: number) => void;
    posts: PostTypeResponse[];
    advertisingPosts: AdvertisingPostTypeResponse[];
    postsLoading: boolean;
    advertisingPostsLoading: boolean;
    onOpenCreateNewPost: () => void;
    setEditPostId: Dispatch<SetStateAction<number>>;
    setDetailsPostId: Dispatch<SetStateAction<number>>;
    setAdvertisingDetailsPostId: Dispatch<SetStateAction<number>>;
    setReportPostId: Dispatch<SetStateAction<number>>;
    setReportTypeId: Dispatch<SetStateAction<number>>;
}

const tabs = ['Blasts', 'My Blasts', 'Saved'];

export const Updates: React.FC<IUpdates> = ({
    setTabIndex,
    tabIndex,
    dateFilter,
    setDateFilter,
    postTags,
    handleSelectTag,
    selectedTags,
    reportTypes,
    onAddReport,
    onDeletePost,
    posts,
    advertisingPosts,
    advertisingPostsLoading,
    postsLoading,
    onOpenCreateNewPost,
    setEditPostId,
    setDetailsPostId,
    setReportTypeId,
    setReportPostId,
    setAdvertisingDetailsPostId
}) => {
return (
    <Flex backgroundColor={'brand'} flexDirection={'column'} alignItems={'center'}>
        {/*HEADER*/}
        <DashboardHeader />
        <Container maxW="container.lg" py={'35px'}>
            <BackButton color="#888DAB" />
            <Text color={'white'} fontSize={'24px'} lineHeight={'25px'} fontWeight={'bold'}>
                Blasts
            </Text>
            {/*    TABS*/}
            <Tabs isManual mt={'16px'} variant={'unstyled'} tabIndex={tabIndex}>
                <Flex align={'center'} justifyContent={'space-between'}>
                    <CustomTabs setTabIndex={setTabIndex} tabIndex={tabIndex} labels={tabs} />
                    {/*    DATE PICKER*/}
                    <Flex align={'center'}>
                        <DatePicker
                            isDark
                            removeBottomMargin
                            label={''}
                            setValue={value => setDateFilter(value)}
                            displayValue={
                                dateFilter ? dayjs.utc(dateFilter).format('MM/DD/YY') : ''
                            }
                            value={dateFilter || dayjs.utc().valueOf()}
                            placeholder="MM/DD/YY"
                            width="285px"
                            formControlProps={{ width: '285px' }}
                        />
                        <Box ml={'8px'}>
                            {tabIndex === 2 ? <CustomButton
                                onClick={onOpenCreateNewPost}
                                text="+ New Blast"
                                bgColor="#545B7C"
                                width="150px"
                                height="50px"
                                borderRadius="4px"
                                lineHeight="24px"
                                fontSize="16px"
                                fontWeight={700}
                                fontFamily={fonts.poppins}
                            /> : null}
                        </Box>
                    </Flex>
                </Flex>
                {/*    Tags*/}

                <HStack mt={'40px'} spacing={'12px'}>
                    {postTags.map(item => (
                        <CustomTag
                            key={item.name}
                            isSelected={selectedTags.includes(item.id)}
                            name={item.name}
                            setSelected={() => handleSelectTag(item.id)}
                        />
                    ))}
                </HStack>
                <TabPanels>
                    <TabPanel p={'0'} h={'100%'}>
                        <AdvertisingPosts 
                            advertisingPosts={advertisingPosts}
                            advertisingPostsLoading={advertisingPostsLoading}
                            setDetailsPostId={setAdvertisingDetailsPostId}
                        />
                        <PostTab
                            setReportPostId={setReportPostId}
                            setReportTypeId={setReportTypeId}
                            setDetailsPostId={setDetailsPostId}
                            postsLoading={postsLoading}
                            posts={posts}
                            reportTypes={reportTypes}
                            onDeletePost={onDeletePost}
                            onAddReport={onAddReport}
                            isMine={false}
                        />
                    </TabPanel>
                    <TabPanel>
                        <PostTab
                            setDetailsPostId={setDetailsPostId}
                            setEditPostId={setEditPostId}
                            postsLoading={postsLoading}
                            posts={posts}
                            reportTypes={reportTypes}
                            onDeletePost={onDeletePost}
                            onAddReport={onAddReport}
                            isMine={true}
                        />
                    </TabPanel>
                    <TabPanel>
                        <PostTab
                            setReportPostId={setReportPostId}
                            setReportTypeId={setReportTypeId}
                            setDetailsPostId={setDetailsPostId}
                            postsLoading={postsLoading}
                            posts={posts}
                            reportTypes={reportTypes}
                            onDeletePost={onDeletePost}
                            onAddReport={onAddReport}
                            isMine={false}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    </Flex>
);
}