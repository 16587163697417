import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    GridItem,
    HStack,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    useRadioGroup,
} from '@chakra-ui/react';
import { CustomDropdown, CustomInput, DatePicker, TypeAhead } from '../../../common/components';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
    agentPreferrences,
    getInspectionItems,
    TypeIds,
} from '../../../common/constants/dropdown.consatnts';
import dayjs from 'dayjs';
import { FindAssistancePayloadType, SearchBy } from '../types';
import  { requestOtherResolver, requestOtherSchema } from '../../../common/schemes';
import {
    agentLoadOptions,
    carryAddressLoadOptions,
    combineDateAndTime,
    convertTimeIn24HoursFormat,
    fonts,
    formatHoursToDateTime,
    getPlaceInfo,
} from '../../../common/utils';
import { SelectValue } from '../../../common/types';
import { ClockIcon } from '../../../assets/icons';
import { RadioCard } from '../../Settings/pages/Location/components';

type RequestOtherFormValues = {
    dateTime: string;
    fromTime: string;
    states: string;
    searchBy: SearchBy;
    isShowingScheduled: string;
    clientTypeId: number;
    preferences: string;
    yesOrNo: string;
    privateNote: string;
    generalNote: string;
    longitude: number;
    latitude: number;
    clientPhone: string;
    clientName: string;
    startingAddress: string;
    preferredAgentId: string;
    approximateDuration: number;
    involvesProperty: string;
};

interface IOtherFormProps {
    states: SelectValue[];
    searchBy: SearchBy;
    preferredAgentId?: string;
    filterStateCode: string;
    onFindAssistance: (data: FindAssistancePayloadType) => void;
    requestValue: TypeIds;
    assistanceLoading: boolean;
}

const options = [
    { id: 'yes', label: 'Yes' },
    { id: 'no', label: 'No' },
];

export const OtherForm: React.FC<IOtherFormProps> = ({
    filterStateCode,
    searchBy,
    states,
    preferredAgentId,
    onFindAssistance,
    requestValue,
    assistanceLoading,
}) => {
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        clearErrors,
        watch,
        reset,
        control,
    } = useForm<RequestOtherFormValues>({
        resolver: requestOtherResolver,
        defaultValues: requestOtherSchema.cast({}) as any,
    });
    const history = useHistory();

    const [involvesProperty, dateForm, preferrencesValue] = watch(['involvesProperty', 'dateTime', 'preferences']);

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'involvesProperty',
        onChange: (value: 'yes' | 'no') => setValue('involvesProperty', value),
        value: involvesProperty,
    });

    const group = getRootProps();

    const onSubmit = (data: RequestOtherFormValues) => {
        const fromTime = convertTimeIn24HoursFormat(data.fromTime);

        const payload = {
            ...data,
            states: [data.states],
            addresses: [{ name: data.startingAddress }],
            requestTypeId: requestValue,
            isSearchByLocation: searchBy === SearchBy.Location,
            preferredAgentId:
                preferredAgentId && data.preferences === '2'
                    ? preferredAgentId
                    : data.preferences === '3'
                    ? data.preferredAgentId
                    : null,
            isShowingScheduled: data.isShowingScheduled === 'Yes, I Have Scheduled The Other',
            doesInvolveByProperty: involvesProperty === 'yes',
            dateTime: data.dateTime
                ? combineDateAndTime(
                      dayjs(+data.dateTime).toISOString(),
                      formatHoursToDateTime(fromTime).toISOString(),
                  )
                : new Date().toISOString(),
        };
        onFindAssistance(payload);
        history.push('/app');
    };

    useEffect(() => {
        if (preferredAgentId) {
            reset({ preferences: '2' });
        }
    }, [preferredAgentId]);

    useEffect(() => {
        if (searchBy === SearchBy.Date && involvesProperty !== 'yes') {
            // setValue('latitude', 0);
            // setValue('longitude', 0);
            setValue('startingAddress', '');
        }
    }, [searchBy, involvesProperty]);

    useEffect(() => {
        setValue('preferredAgentId', '');
    }, [preferrencesValue]);

    useEffect(() => {
        setValue('searchBy', searchBy);
    }, [searchBy]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={2} columnGap="60px">
                <GridItem>
                    <CustomDropdown
                        options={states?.map((item: any, i: any) => ({
                            ...item,
                            id: i,
                        }))}
                        label="State: *"
                        register={{ ...register('states') }}
                        errors={errors.states}
                        width="100%"
                        placeholder="Select"
                        margin={errors.states ? '0' : '0 0 26px 0'}
                    />

                    <DatePicker
                        label="Date: *"
                        displayValue={
                            searchBy === SearchBy.Location
                                ? dayjs.utc().format('MM/DD/YY')
                                : dateForm
                                ? dayjs.utc(+dateForm).format('MM/DD/YY')
                                : ''
                        }
                        value={Number(dateForm) || dayjs.utc().valueOf()}
                        setValue={value => {
                            setValue('dateTime', value.toString());
                            clearErrors('dateTime');
                        }}
                        removeBottomMargin
                        placeholder="MM/DD/YY"
                        errMessage={errors.dateTime?.message}
                        borderRadius="50px"
                        margin={errors.dateTime ? '0 10px 0 0' : '0 10px 26px 0'}
                        isDisabled={searchBy === SearchBy.Location}
                    />
                </GridItem>
                <GridItem>
                    <Flex justifyContent={'space-between'}>
                        <CustomInput
                            label="Time: *"
                            register={{ ...register('fromTime') }}
                            type="time-12-hours"
                            width="47%"
                            defaultValue="00:00 AM"
                            errors={errors.fromTime}
                            margin={errors.fromTime ? '0 0 0 10px' : '0 0 26px 10px'}
                            rightIcon={<ClockIcon color="#888DAB" />}
                        />
                        <CustomInput
                            width={'47%'}
                            label="Est. Duration: *"
                            placeholder="Enter"
                            register={{ ...register('approximateDuration') }}
                            errors={errors.approximateDuration}
                            type="number"
                            margin={errors.approximateDuration ? '0' : '0 0 26px 0'}
                            rightIcon={
                                <Text fontSize="16px" color="gray">
                                    h
                                </Text>
                            }
                        />
                    </Flex>
                </GridItem>
            </SimpleGrid>
            <Box my={'26px'}>
                {searchBy === SearchBy.Date ? (
                    <HStack mb={'26px'} spacing={'39px'}>
                        <Text
                            color="brand"
                            fontWeight="400"
                            fontSize="14px"
                            fontFamily={fonts.poppins}
                        >
                            Does this activity involve a property? *
                        </Text>
                        <Controller
                            control={control}
                            name="involvesProperty"
                            render={({ field }) => (
                                <RadioGroup {...field}>
                                    <Stack {...group} direction="row" spacing="25px">
                                        {options.map(value => {
                                            const radio = getRadioProps({
                                                value: value.id,
                                            });
                                            return (
                                                <RadioCard key={value.id} {...radio}>
                                                    {value.label}
                                                </RadioCard>
                                            );
                                        })}
                                    </Stack>
                                </RadioGroup>
                            )}
                        />
                    </HStack>
                ) : null}

                {(searchBy === SearchBy.Date && involvesProperty === 'yes') ||
                searchBy === SearchBy.Location ? (
                    <TypeAhead
                        placeholder="Select location"
                        label={'Address: *'}
                        loadOptions={carryAddressLoadOptions(false, filterStateCode)}
                        onStateChange={async ev => {
                            const placeInfo = ev?.value ? await getPlaceInfo(ev.value) : null;
                            if (placeInfo) {
                                console.log(placeInfo, 'placeInfo');
                                setValue('latitude', placeInfo?.lat);
                                setValue('longitude', placeInfo?.lng);
                                clearErrors('latitude');
                                clearErrors('longitude');
                            } else {
                                setValue('latitude', 0);
                                setValue('longitude', 0);
                            }
                            setValue('startingAddress', ev?.label || '');
                            clearErrors('startingAddress');
                        }}
                        errors={errors.longitude?.message}
                    />
                ) : null}
            </Box>

            <SimpleGrid columns={2} columnGap={'60px'}>
                <GridItem>
                    <CustomInput
                        label="Description:"
                        width="100%"
                        placeholder="Enter"
                        register={{ ...register('generalNote') }}
                        errors={errors.generalNote}
                        margin={errors.generalNote ? '0' : '0 0 26px 0'}
                    />
                    <CustomDropdown
                        label="The Client is: *"
                        options={[
                            { id: 1, label: 'A Couple', value: '1' },
                            { id: 2, label: 'A Family', value: '2' },
                            { id: 3, label: 'An Individual', value: '3' },
                        ]}
                        placeholder="Select"
                        register={{ ...register('clientTypeId') }}
                        errors={errors.clientTypeId?.message}
                        width="100%"
                        margin={errors.clientTypeId ? '0' : '0 0 26px 0'}
                    />
                    <CustomInput
                        register={{ ...register('clientPhone') }}
                        label="Client Phone Number:"
                        placeholder="Enter"
                        errors={errors.clientPhone}
                        type="tel"
                        mask="999-999-9999"
                    />
                </GridItem>
                <GridItem>
                    <CustomDropdown
                        options={getInspectionItems('Other')}
                        label="Is access to the property scheduled? *"
                        register={{ ...register('isShowingScheduled') }}
                        errors={errors.isShowingScheduled}
                        placeholder="Select"
                        width="100%"
                        margin={errors.isShowingScheduled ? '0' : '0 0 26px 0'}
                    />
                    <CustomInput
                        label="Client Name"
                        width="100%"
                        placeholder="Enter"
                        register={{ ...register('clientName') }}
                        errors={errors.clientName}
                        margin={errors.clientName ? '0' : '0 0 26px 0'}
                    />

                    <CustomDropdown
                        label="I have a preference for: *"
                        options={
                            preferredAgentId
                                ? agentPreferrences
                                : agentPreferrences.filter(elem => elem.value !== '2')
                        }
                        placeholder="Select"
                        register={{ ...register('preferences') }}
                        errors={errors.preferences?.message}
                        width="100%"
                    />
                    {preferrencesValue === '3' ? (
                        <TypeAhead
                            label="Previous Agent:"
                            placeholder="Previous Agent"
                            loadOptions={agentLoadOptions}
                            onStateChange={ev => {
                                setValue('preferredAgentId', ev?.value || '');
                                clearErrors('preferredAgentId');
                            }}
                            errors={errors.preferredAgentId?.message}
                        />
                    ) : null}
                </GridItem>
                <GridItem colSpan={2} mt={'26px'}>
                    <CustomInput
                        label="Private Notes to the Chosen Agent"
                        width="100%"
                        register={{ ...register('privateNote') }}
                        placeholder="Enter"
                        errors={errors.privateNote}
                    />
                </GridItem>
                <GridItem gridColumn={'2/3'} mt={'26px'}>
                    <Button
                        variant={'primary'}
                        isFullWidth
                        text="Search"
                        type="submit"
                        isLoading={assistanceLoading}
                    >
                        Search
                    </Button>
                </GridItem>
            </SimpleGrid>
        </form>
    );
};
