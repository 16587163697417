import { put, call, takeEvery } from 'redux-saga/effects';
import { getMyRequests, getMyRequestsSuccess, getMyRequestsError } from '../actions';
import API from '../../../../common/api/api.base';
import { GetMyRequestsSuccess } from '../../types';
import { IResponseWithCustomValue } from '../../../../common/types';

function* workerGetMyRequests(action: ReturnType<typeof getMyRequests>) {
    try {
        const result: IResponseWithCustomValue<GetMyRequestsSuccess> = yield call(
            API.post,
            `/api/request/pending?PageNumber=${action.payload.PageNumber}&PageSize=${action.payload.PageSize}&includeOtherAgent=${action.payload.includeOtherAgent}`,
            action.payload,
        );
        console.log('workerGetMyRequests', result, action);
        if (result.success) {
            yield put(getMyRequestsSuccess({ result, PageNumber: action.payload.PageNumber }));
        } else {
            yield put(getMyRequestsError(result.errors));
        }
    } catch (error) {
        console.log('workerGetMyRequests error', error);
    }
}

export default function* watchGetMyRequestsSaga() {
    yield takeEvery(getMyRequests.type, workerGetMyRequests);
}
