import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const addNewAgencySchema = yup.object().shape({
    name: yup.string().required(ErrorMessages.REQUIRED),
    email: yup.string().email(ErrorMessages.EMAIL_INVALID).required(ErrorMessages.REQUIRED),
    address: yup.string().required(ErrorMessages.REQUIRED),
    licenseNumber: yup
        .number()
        .typeError('License must be a number')
        .positive(ErrorMessages.POSITIVE_VALUE)
        .required(ErrorMessages.REQUIRED),
    phoneNumber: yup
        .string()
        .required(ErrorMessages.REQUIRED)
        .test('phone-number', ErrorMessages.PHONE_NUMBER_INCOMPLETE, function (value?: string) {
            return value?.replace(/[-_]/g, '').length === 10;
        }),
});

export default yupResolver(addNewAgencySchema);
