import React, { useEffect } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import editDwollaAccountResolver, {
    EditDwollaAccountFormValues,
} from './validation/editDwollaAccount.schema';
import { VStack } from '@chakra-ui/react';
import {
    CustomButton,
    CustomDropdown,
    CustomInput,
    Loader,
} from '../../../../../common/components';
import { GetCustomerGeneralInfoResponse } from '../types';
import { useDispatch } from 'react-redux';
import { editDwollaAccountRequest } from '../store/actions';
import { StateDirectoryItem } from '../../../../../common/types';

interface IEditDwollaAccountFormProps {
    states: StateDirectoryItem[];
    generalInfoLoading: boolean;
    generalInfoData: GetCustomerGeneralInfoResponse;
    editDwollaAccountLoading: boolean;
}

export const EditDwollaAccountForm: React.FC<IEditDwollaAccountFormProps> = ({
    states,
    generalInfoData,
    generalInfoLoading,
    editDwollaAccountLoading,
}) => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        formState: { errors },
        register,
        reset,
    } = useForm({
        resolver: editDwollaAccountResolver as Resolver<EditDwollaAccountFormValues>,
    });

    const onSubmit = (data: EditDwollaAccountFormValues) => {
        dispatch(
            editDwollaAccountRequest({
                ...data,
            }),
        );
    };
    useEffect(() => {
        if (generalInfoData) {
            reset({
                ...generalInfoData,
            });
        }
    }, [generalInfoData]);

    return (
        <>
            {generalInfoLoading ? (
                <Loader spinnerSize={'md'} centerHeight={'500px'} />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack mx={'auto'} align={'stretch'} maxW={'342px'} spacing={'16px'}>
                        <CustomInput
                            errors={errors.email}
                            register={{ ...register('email') }}
                            label={'Email'}
                        />
                        <CustomInput
                            errors={errors.address1}
                            register={{ ...register('address1') }}
                            label={'Address 1'}
                        />
                        <CustomInput
                            errors={errors.address2}
                            register={{ ...register('address2') }}
                            label={'Address 2'}
                        />
                        <CustomInput
                            errors={errors.city}
                            register={{ ...register('city') }}
                            label={'City'}
                        />
                        <CustomDropdown
                            options={states.map(state => ({
                                ...state,
                                value: state.code,
                                label: state.name,
                            }))}
                            label={'State'}
                            register={{ ...register('state') }}
                            errors={errors.state}
                        />
                        <CustomInput
                            errors={errors.postalCode}
                            register={{ ...register('postalCode') }}
                            label={'Postal Code'}
                        />
                        <CustomButton
                            isLoading={editDwollaAccountLoading}
                            text="Save"
                            type="submit"
                            height="60px"
                            bgColor="brand"
                        />
                    </VStack>
                </form>
            )}
        </>
    );
};
