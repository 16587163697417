import { call, put, takeEvery, ForkEffect } from 'redux-saga/effects';
import {
    signUpFillDataRequest,
    signUpFillDataSuccess,
    signUpFillDataError,
    signUpAddDriverLicencePhoto,
    signUpAddDriverLicencePhotoSuccess,
    signUpAddDriverLicencePhotoError,
    signUpAddRealEsateLicencePhoto,
    signUpAddRealEsateLicencePhotoSuccess,
    signUpAddRealEsateLicencePhotoError,
    signUpSendEmailCode,
    signUpSendEmailCodeSuccess,
    signUpSendEmailCodeError,
    signUpConfirmEmailCode,
    signUpConfirmEmailCodeSuccess,
    signUpConfirmEmailCodeError,
    signUpSendPhoneCode,
    signUpSendPhoneCodeSuccess,
    signUpSendPhoneCodeError,
    signUpConfirmPhoneCode,
    signUpConfirmPhoneCodeSuccess,
    signUpConfirmPhoneCodeError,
    signUpGetAgencies,
    signUpGetAgenciesSuccess,
    signUpGetAgenciesError,
    addNewAgencyRequest,
    addNewAgencySuccess,
    addNewAgencyError,
    getLicensesDraft,
    getLicensesDraftSuccess,
    getLicensesDraftError,
} from './actions';
import API from '../../../../common/api/api.base';
import { IdNameTypes, IResponseWithCustomValue, SignUpResponse } from '../../../../common/types';
import { SignUpConfirmEmailSuccess, SignUpGetAgenciesSuccess } from '../types';
import { push } from 'connected-react-router';

function* workerSignUpFillData(action: ReturnType<typeof signUpFillDataRequest>) {
    try {
        const result: SignUpResponse = yield call(
            API.post,
            `/api/auth/fill-data`,
            action.payload.payload,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${action.payload.token}`,
            },
        );
        if (result.success) {
            yield put(signUpFillDataSuccess(result));
            yield put(push(`verificationpopup`));
        } else {
            yield put(signUpFillDataError(result.errors));
        }
    } catch (error) {
        yield put(signUpFillDataError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}
export function* watchSignUpFillData(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(signUpFillDataRequest.type, workerSignUpFillData);
}

function* workerSignUpAddDriverLicencePhoto(
    action: ReturnType<typeof signUpAddDriverLicencePhoto>,
) {
    console.log(action.payload.body);
    try {
        const result: SignUpResponse = yield call(
            API.file,
            `/api/auth/set-id-license-photo`,
            action.payload.body,
            {
                Accept: 'application/json',
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${action.payload.token}`,
            },
        );
        if (result.success) {
            yield put(signUpAddDriverLicencePhotoSuccess(result));
            yield put(push(`addrealestatelicense`));
        } else {
            yield put(signUpAddDriverLicencePhotoError(result.errors));
        }
    } catch (error) {
        yield put(
            signUpAddDriverLicencePhotoError([{ key: 'Error', errorMessage: 'Network error' }]),
        );
    }
}
export function* watchSignUpAddDriverLicencePhoto(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(signUpAddDriverLicencePhoto.type, workerSignUpAddDriverLicencePhoto);
}

function* workerSignUpAddRealEstateLicencePhoto(
    action: ReturnType<typeof signUpAddRealEsateLicencePhoto>,
) {
    try {
        const result: SignUpResponse = yield call(
            API.file,
            `/api/auth/set-real-estate-license-photo?licenseId=${action.payload.license}`,
            action.payload.body,
            {
                Accept: 'application/json',
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${action.payload.token}`,
            },
        );
        if (result.success) {
            yield put(signUpAddRealEsateLicencePhotoSuccess(result));
            yield put(push(`verificationpopup`));
        } else {
            yield put(signUpAddRealEsateLicencePhotoError(result.errors));
        }
    } catch (error) {
        yield put(
            signUpAddRealEsateLicencePhotoError([{ key: 'Error', errorMessage: 'Network error' }]),
        );
    }
}
export function* watchSignUpAddRealEstateLicencePhoto(): Generator<
    ForkEffect<never>,
    void,
    unknown
> {
    yield takeEvery(signUpAddRealEsateLicencePhoto.type, workerSignUpAddRealEstateLicencePhoto);
}

function* workerSignUpSendEmailCode(action: ReturnType<typeof signUpSendEmailCode>) {
    try {
        const result: SignUpResponse = yield call(API.post, `/api/auth/code`, action.payload, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });
        if (result.success) {
            console.log(result.value);
            yield put(signUpSendEmailCodeSuccess(result));
        } else {
            yield put(signUpSendEmailCodeError(result.errors));
        }
    } catch (error) {
        yield put(signUpSendEmailCodeError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}
export function* watchSignUpSendEmailCode(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(signUpSendEmailCode.type, workerSignUpSendEmailCode);
}

function* workerSignUpConfirmEmailCode(action: ReturnType<typeof signUpConfirmEmailCode>) {
    try {
        const result: IResponseWithCustomValue<SignUpConfirmEmailSuccess> = yield call(
            API.put,
            `/api/auth/code`,
            action.payload,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        );
        if (result.success) {
            yield put(signUpConfirmEmailCodeSuccess(result));
        } else {
            yield put(signUpConfirmEmailCodeError(result.errors));
        }
    } catch (error) {
        console.log(error);
        yield put(signUpConfirmEmailCodeError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}
export function* watchSignUpConfirmEmailCode(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(signUpConfirmEmailCode.type, workerSignUpConfirmEmailCode);
}

function* workerSignUpSendPhoneCode(action: ReturnType<typeof signUpSendPhoneCode>) {
    try {
        const result: SignUpResponse = yield call(
            API.put,
            `/api/profile/update-phone-number`,
            action.payload.payload,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${action.payload.token}`,
            },
        );
        if (result.success) {
            console.log(result.value);
            yield put(signUpSendPhoneCodeSuccess(result));
        } else {
            yield put(signUpSendPhoneCodeError(result.errors));
        }
    } catch (error) {
        yield put(signUpSendPhoneCodeError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}
export function* watchSignUpSendPhoneCode(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(signUpSendPhoneCode.type, workerSignUpSendPhoneCode);
}

function* workerSignUpConfirmPhoneCode(action: ReturnType<typeof signUpConfirmPhoneCode>) {
    try {
        const result: SignUpResponse = yield call(
            API.put,
            `/api/profile/verify-phone-number`,
            action.payload.payload,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${action.payload.token}`,
            },
        );
        if (result.success) {
            yield put(signUpConfirmPhoneCodeSuccess(result));
            yield put(push(`filldata`));
        } else {
            yield put(signUpConfirmPhoneCodeError(result.errors));
        }
    } catch (error) {
        yield put(signUpConfirmPhoneCodeError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}
export function* watchSignUpConfirmPhoneCode(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(signUpConfirmPhoneCode.type, workerSignUpConfirmPhoneCode);
}

function* workerSignUpGetAgencies(action: ReturnType<typeof signUpGetAgencies>) {
    try {
        const result: IResponseWithCustomValue<SignUpGetAgenciesSuccess> = yield call(
            API.get,
            `/api/profile/agencies?id=${action.payload.stateId}`,
            null,
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${action.payload.token}`,
            },
        );
        if (result.success) {
            yield put(signUpGetAgenciesSuccess(result));
        } else {
            yield put(signUpGetAgenciesError(result.errors));
        }
    } catch (error) {
        yield put(signUpConfirmPhoneCodeError([{ key: 'Error', errorMessage: 'Network error' }]));
    }
}
export function* watchSignUpSignUpGetAgencies(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(signUpGetAgencies.type, workerSignUpGetAgencies);
}

function* workerAddNewAgency(action: ReturnType<typeof addNewAgencyRequest>) {
    try {
        const response: IResponseWithCustomValue<IdNameTypes> = yield call(
            API.post,
            'api/auth/create-agency',
            {
                ...action.payload.data,
            },
            { Authorization: `Bearer ${action.payload.verifyToken}` },
        );
        console.log(response, action, 'workerAddNewAgency');
        if (response.success) {
            yield put(addNewAgencySuccess(response.value));
        } else {
            yield put(addNewAgencyError(response.errors));
        }
    } catch (e) {
        console.log('workerAddNewAgency error', e);
    }
}

export function* watchAddNewAgencySaga() {
    yield takeEvery(addNewAgencyRequest.type, workerAddNewAgency);
}

function* workerGetLicensesDraft(action: ReturnType<typeof getLicensesDraft>) {
    try {
        const result: IResponseWithCustomValue<any> = yield call(
            API.get,
            '/api/auth/get-draft-license',
            {},
            {
                Authorization: `Bearer ${action.payload}`,
            },
        );
        console.log('getLicensesDraft', result, action);
        if (result.success) {
            yield put(getLicensesDraftSuccess(result));
        } else {
            yield put(getLicensesDraftError(result.errors));
        }
    } catch (error) {
        console.log('getLicensesDraftError', error);
    }
}

export default function* watchGetLicensesDraftSaga() {
    yield takeEvery(getLicensesDraft.type, workerGetLicensesDraft);
}
