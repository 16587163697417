import React, { useEffect, useState } from 'react';
import { PaymentDropDown, PaymentInfoBox, PaymentsModalWrapper } from '../components';
import { Box, Radio, RadioGroup, Stack, Text, VStack } from '@chakra-ui/react';
import { GetFundingSourcesResponse } from '../types';
import { CustomButton, CustomInput, Loader } from '../../../../../common/components';
import { DollarInputIcon } from '../../../../../assets/icons';
import amountBalanceResolver, {
    AmountBalanceFormValue,
} from '../components/validation/amountBalance.schema';
import { Resolver, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createUserToSelfTransferRequest } from '../store/actions';

interface IGetPaidModalProps {
    isOpen: boolean;
    title: string;
    onClose: () => void;
    walletBalanceLoading: boolean;
    fundingSourcesLoading: boolean;
    walletBalance: number;
    fundingSources: GetFundingSourcesResponse[];
    rechargeBalanceLoading: boolean;
}

export const GetPaidModal: React.FC<IGetPaidModalProps> = ({
    isOpen,
    title,
    onClose,
    walletBalanceLoading,
    fundingSourcesLoading,
    walletBalance,
    fundingSources,
    rechargeBalanceLoading,
}) => {
    const [radioButtonValue, setRadioButtonValue] = useState(1);
    const [fundingSourceType, setFundingSourceType] = useState<GetFundingSourcesResponse>(
        {} as GetFundingSourcesResponse,
    );

    const handleTextInput = (name: keyof AmountBalanceFormValue, value: number | string) => {
        setValue(name, value);
        clearErrors(name);
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
        clearErrors,
        setValue,
        reset,
    } = useForm<AmountBalanceFormValue>({
        resolver: amountBalanceResolver as Resolver<AmountBalanceFormValue>,
    });

    const dispatch = useDispatch();

    const onSubmit = (data: AmountBalanceFormValue) => {
        dispatch(
            createUserToSelfTransferRequest({
                ...data,
                isPayout: true,
            }),
        );
    };

    useEffect(() => {
        if (fundingSourceType) {
            setValue('fundingSourceId', fundingSourceType.dwollaId);
            clearErrors('fundingSourceId');
        }
    }, [fundingSourceType]);

    useEffect(() => {
        if (!isOpen) {
            reset({});
            setFundingSourceType({} as GetFundingSourcesResponse);
            setRadioButtonValue(1);
        }
    }, [isOpen]);

    useEffect(() => {
        if (radioButtonValue && radioButtonValue === 2) {
            handleTextInput('amountOfMoney', '');
        } else {
            handleTextInput('amountOfMoney', walletBalance);
        }
    }, [radioButtonValue, walletBalance]);

    return (
        <PaymentsModalWrapper isOpen={isOpen} title={title} onClose={onClose}>
            <>
                {fundingSourcesLoading || walletBalanceLoading ? (
                    <Loader spinnerSize={'md'} centerHeight={'500px'} />
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Header Box */}
                        <PaymentInfoBox walletBalance={walletBalance} />
                        {/* Payment Drop Down */}
                        <Box mt={'22px'}>
                            <PaymentDropDown
                                error={errors.fundingSourceId?.message}
                                items={fundingSources}
                                setValue={setFundingSourceType}
                                value={fundingSourceType}
                                loading={fundingSourcesLoading}
                            />
                        </Box>
                        {/* Amount */}
                        <Text
                            my={'20px'}
                            textTransform={'capitalize'}
                            fontWeight={400}
                            fontSize={'16px'}
                            lineHeight={'22px'}
                            color="brand"
                        >
                            Amount
                        </Text>

                        <RadioGroup
                            onChange={value => setRadioButtonValue(+value)}
                            value={radioButtonValue}
                        >
                            <Stack direction={'column'} spacing={'30px'}>
                                <Radio
                                    color={'#BABFD2'}
                                    sx={{
                                        '.chakra-radio__label[data-checked]': {
                                            color: '#2C335C',
                                        },
                                    }}
                                    value={1}
                                >
                                    ${walletBalance}
                                </Radio>
                                <Radio
                                    color={'#BABFD2'}
                                    sx={{
                                        '.chakra-radio__label[data-checked]': {
                                            color: '#2C335C',
                                        },
                                    }}
                                    value={2}
                                >
                                    Other Amount
                                </Radio>
                            </Stack>
                        </RadioGroup>

                        {radioButtonValue === 2 && (
                            <Box mt={'30px'}>
                                <CustomInput
                                    errors={errors.amountOfMoney}
                                    register={{ ...register('amountOfMoney') }}
                                    width={'342px'}
                                    rightIcon={<DollarInputIcon />}
                                    label={'Enter amount'}
                                    placeholder={'Enter'}
                                />
                            </Box>
                        )}

                        {/* Button */}
                        <Box textAlign={'right'} mt={'166px'}>
                            <CustomButton
                                isLoading={rechargeBalanceLoading}
                                maxW={'342px'}
                                text="Get Paid"
                                type="submit"
                                height="60px"
                                bgColor="#2C335C"
                                placeholder={'Enter'}
                            />
                        </Box>
                    </form>
                )}
            </>
        </PaymentsModalWrapper>
    );
};
