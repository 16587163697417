import React from 'react';
import {
    Flex,
    IconButton,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
} from '@chakra-ui/react';
import { QuestionIcon } from '../../../../../assets/icons';

export const ConnectPaymentAccountHint: React.FC = () => (
    <Flex align={'center'}>
        <Popover>
            <PopoverTrigger>
                <IconButton
                    bg={'none'}
                    icon={<QuestionIcon />}
                    aria-label={'information about create dwolla account'}
                />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverBody>
                    <Text
                        color="rgba(44, 51, 92, 0.55)"
                        fontSize="14px"
                        fontWeight={400}
                        lineHeight="21px"
                    >
                        We will connect Dwolla payment system to your account so you can send money
                        to other users
                    </Text>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    </Flex>
);
