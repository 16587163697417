import React from 'react';
import { colors } from '../../common/constants';

export const PhoneIcon: React.FC<{ color?: string }> = ({ color = colors.brand }) => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.4"
            d="M13.9691 10.392C13.9301 10.361 11.0691 8.292 10.2791 8.444C9.90412 8.51 9.68912 8.766 9.25912 9.279C9.19012 9.362 9.02312 9.56 8.89412 9.701C8.62212 9.61233 8.35678 9.50439 8.10012 9.378C6.77496 8.73286 5.70427 7.66216 5.05912 6.337C4.93264 6.08038 4.8247 5.81504 4.73612 5.543C4.87712 5.413 5.07612 5.243 5.16012 5.175C5.67012 4.747 5.92612 4.532 5.99312 4.156C6.12912 3.377 4.06612 0.493 4.04412 0.467C3.95065 0.333199 3.82857 0.221876 3.68674 0.141103C3.54491 0.0603292 3.38689 0.01213 3.22412 0C2.38712 0 0.00012207 3.1 0.00012207 3.619C0.00012207 3.649 0.044122 6.73 3.84312 10.595C7.70412 14.395 10.7851 14.434 10.8151 14.434C11.3371 14.434 14.4341 12.047 14.4341 11.21C14.4221 11.0478 14.3742 10.8904 14.2939 10.7489C14.2137 10.6075 14.1031 10.4856 13.9701 10.392H13.9691Z"
            fill={color}
        />
    </svg>
);
