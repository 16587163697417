import React from 'react';
import { Box } from '@chakra-ui/react';
import { Notification } from '../../../../assets/icons';

interface ICustomButtonProps {
    buttonIcon: JSX.Element;
    notification?: boolean;
}

const ButtonWithNotification: React.FC<ICustomButtonProps> = ({ buttonIcon, notification }) => (
    <Box d="flex" flexDirection="row">
        {buttonIcon}
        {notification && (
            <Box position="absolute" ml="18px">
                <Notification width="12" height="12" />
            </Box>
        )}
    </Box>
);
export { ButtonWithNotification };
