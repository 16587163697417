import React, { useEffect } from 'react';
import { ActivityIssueModal } from './ActivityIssueModal';
import { dispatch, RootStore } from '../../../../store';
import { useSelector } from 'react-redux';
import { SendIssuePayloadType } from './types';
import { sendIssueRequest } from './store/actions';

interface IOpportunitiesModalProps {
    isOpen: boolean;
    onClose: () => void;
    activity: any;
}

export const ActivityIssueModalContainer: React.FC<IOpportunitiesModalProps> = ({
    isOpen,
    onClose,
    activity,
}) => {
    const [subjectValue, setSubjectValue] = React.useState<string>('');
    const [textValue, setTextValue] = React.useState<string>('');
    const [checkText, setCheckText] = React.useState<boolean>(false);

    useEffect(() => {
        if (subjectValue && textValue) {
            setCheckText(false);
        } else {
            setCheckText(true);
        }
    }, [subjectValue, textValue]);

    const onSendIssueRequest = (payload: SendIssuePayloadType) =>
        dispatch(sendIssueRequest(payload));
    const id = useSelector((state: RootStore) => state.auth.user.id);
    const token = useSelector((state: RootStore) => state.auth.user.token.accessToken);
    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) =>
        setSubjectValue(event.target.value);
    const handleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
        setTextValue(event.target.value);
    const handleResetTexts = () => {
        setTextValue('');
        setSubjectValue('');
    };
    return (
        <ActivityIssueModal
            isOpen={isOpen}
            onClose={onClose}
            titleValue={subjectValue}
            messageValue={textValue}
            handleChangeTitle={handleChangeTitle}
            handleChangeMessage={handleChangeMessage}
            handleResetTexts={handleResetTexts}
            onSendIssueRequest={onSendIssueRequest}
            id={activity.id}
            token={token}
            checkText={checkText}
        />
    );
};
