import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { RootStore } from '../../../../../store';
import { SubmitHandler } from 'react-hook-form';
import { IAuthContainerProps } from '../../../../../common/types';
import { SignInEnterCode } from './SignInEnterCode';
import { SendPinCode } from '../../types';
import { sendPinCode } from '../../store/actions';

export const SignInEnterCodeContainer: React.FC<IAuthContainerProps> = ({ nestedPath }) => {
    const loading = useSelector((state: RootStore) => state.auth.loading);
    const userEmail = useSelector((state: RootStore) => state.changePassword.email);

    const dispatch = useDispatch();
    const onSendPinCode: SubmitHandler<SendPinCode> = payload => dispatch(sendPinCode(payload));

    return <SignInEnterCode loading={loading} email={userEmail} onSendPinCode={onSendPinCode} />;
};
