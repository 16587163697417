import { call, put, takeEvery } from '@redux-saga/core/effects';
import API from '../../../common/api/api.base';
import { NotificationsResponse } from '../types';
import {
    getNotificationsError,
    getNotificationsRequest,
    getNotificationsSuccess,
    getUnreadNotifications,
    getUnreadNotificationsSuccess,
} from './actions';
import { IResponseWithCustomValue } from './../../../common/types';

function* workerGetNotifications(action: ReturnType<typeof getNotificationsRequest>) {
    try {
        const result: IResponseWithCustomValue<NotificationsResponse> = yield call(
            API.get,
            `/api/notifications?PageNumber=${action.payload.pageNumber}&PageSize=${action.payload.pageSize}`,
            {},
        );
        if (result.success) {
            yield put(getNotificationsSuccess(result.value));
        } else {
            yield put(getNotificationsError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetNotifications');
    }
}

function* workerGetUnreadNotificationsInfo() {
    try {
        const result: IResponseWithCustomValue<number> = yield call(
            API.get,
            '/api/notifications/unread-exist',
            {},
        );
        if (result.success) {
            yield put(getUnreadNotificationsSuccess(result.value));
        }
    } catch (error) {
        console.log(error, 'workerGetUnreadNotificationsInfo');
    }
}

export default function* watchNotificationsSaga() {
    yield takeEvery(getNotificationsRequest.type, workerGetNotifications);
    yield takeEvery(getUnreadNotifications.type, workerGetUnreadNotificationsInfo);
}
