import React from 'react';
import { colors } from '../../common/constants';

export const EmailIcon: React.FC<{ color?: string; opacity?: number }> = ({
    color = colors.brand,
    opacity = 0.4,
}) => (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity={opacity}>
            <path d="M11.8019 5.89302L17.4959 9.49303V2.14502L11.8019 5.89302Z" fill={color} />
            <path d="M0.605957 2.14502V9.49603L6.29996 5.89603L0.605957 2.14502Z" fill={color} />
            <path
                d="M16.4409 0H1.66194C1.41167 0.00219711 1.17053 0.0943186 0.982544 0.259544C0.794553 0.42477 0.672244 0.652084 0.637939 0.900002L9.05094 6.443L17.4649 0.900002C17.4306 0.652084 17.3083 0.42477 17.1203 0.259544C16.9323 0.0943186 16.6912 0.00219711 16.4409 0Z"
                fill={color}
            />
            <path
                d="M10.835 6.53003L9.34098 7.51404C9.25465 7.57061 9.15369 7.60074 9.05048 7.60074C8.94727 7.60074 8.8463 7.57061 8.75998 7.51404L7.26598 6.52905L0.638977 10.721C0.675485 10.9668 0.798669 11.1913 0.986279 11.3542C1.17389 11.517 1.41355 11.6074 1.66198 11.609H16.441C16.6897 11.6071 16.9295 11.5162 17.1169 11.3528C17.3044 11.1893 17.4271 10.9642 17.463 10.718L10.835 6.53003Z"
                fill={color}
            />
        </g>
    </svg>
);
