import {colors} from "../../common/constants";

export const Checkbox = {
    baseStyle: {
        control: {
            bg: colors.white,
            _checked: {
                bg: colors.brand,
            },
        },
    },
}
