import { createSlice } from '@reduxjs/toolkit';
import {
    signUpFillDataRequest,
    signUpFillDataSuccess,
    signUpFillDataError,
    signUpAddDriverLicencePhoto,
    signUpAddDriverLicencePhotoSuccess,
    signUpAddDriverLicencePhotoError,
    signUpAddRealEsateLicencePhoto,
    signUpAddRealEsateLicencePhotoSuccess,
    signUpAddRealEsateLicencePhotoError,
    signUpSendEmailCode,
    signUpSendEmailCodeSuccess,
    signUpSendEmailCodeError,
    signUpConfirmEmailCode,
    signUpConfirmEmailCodeSuccess,
    signUpConfirmEmailCodeError,
    signUpSendPhoneCodeSuccess,
    signUpConfirmPhoneCodeSuccess,
    signUpSendPhoneCode,
    signUpSendPhoneCodeError,
    signUpConfirmPhoneCode,
    signUpConfirmPhoneCodeError,
    signUpGetAgencies,
    signUpGetAgenciesSuccess,
    signUpGetAgenciesError,
    signUpSetStep,
    addNewAgencyRequest,
    addNewAgencySuccess,
    addNewAgencyError,
    handleAddNewAgencyModal,
    removeDraftAgency,
    getLicensesDraft,
    getLicensesDraftSuccess,
    getLicensesDraftError,
} from './actions';
import { IdNameTypes, PayloadError } from '../../../../common/types';

export const initialState: {
    errors: PayloadError[];
    fillDataSuccess: boolean;
    addDriverLicencePhotoSuccess: boolean;
    addRealEstateLicencePhoto: boolean;
    loading: boolean;
    loaded: boolean;
    step: number;
    accessToken: string | null;
    agencies: any[];
    license: any;
    addNewAgency: {
        loading: boolean;
        value: IdNameTypes;
        errors: PayloadError[];
    };
    isAddedDraftAgency: boolean;
    addNewAgencyModalVisible: boolean;
} = {
    step: 1,
    errors: [],
    fillDataSuccess: false,
    addDriverLicencePhotoSuccess: false,
    addRealEstateLicencePhoto: false,
    loading: false,
    loaded: false,
    accessToken: null,
    agencies: [],
    license: [],
    addNewAgency: {
        loading: false,
        value: {
            id: 0,
            name: '',
        },
        errors: [],
    },
    isAddedDraftAgency: false,
    addNewAgencyModalVisible: false,
};

const signUpSlice = createSlice({
    name: 'signUp',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(signUpSendEmailCode, state => {
                state.loaded = false;
                state.loading = true;
                state.errors = [];
            })
            .addCase(signUpSendEmailCodeSuccess, (state, action) => {
                state.step = 2;
                state.loading = false;
            })
            .addCase(signUpSendEmailCodeError, (state, action) => {
                state.loading = false;
            })
            .addCase(signUpConfirmEmailCode, (state, action) => {
                state.loaded = false;
                state.loading = true;
                state.errors = [];
            })
            .addCase(signUpConfirmEmailCodeSuccess, (state, action) => {
                // console.log(action.payload.value.accessToken)
                state.step = 3;
                state.loading = false;
                state.accessToken = action.payload.value.accessToken;
            })
            .addCase(signUpConfirmEmailCodeError, (state, action) => {
                state.loading = false;
            })
            .addCase(signUpSendPhoneCode, (state, action) => {
                state.loaded = false;
                state.loading = true;
                state.errors = [];
            })
            .addCase(signUpSendPhoneCodeSuccess, (state, action) => {
                state.step = 4;
                state.loading = false;
            })
            .addCase(signUpSendPhoneCodeError, (state, action) => {
                state.loading = false;
            })
            .addCase(signUpConfirmPhoneCode, (state, action) => {
                state.loaded = false;
                state.loading = true;
                state.errors = [];
            })
            .addCase(signUpConfirmPhoneCodeSuccess, (state, action) => {
                state.loading = false;
                state.step = 1;
            })
            .addCase(signUpConfirmPhoneCodeError, (state, action) => {
                state.loading = false;
            })
            .addCase(signUpGetAgencies, state => {
                state.loading = true;
                state.loaded = false;
                state.errors = [];
            })
            .addCase(signUpGetAgenciesSuccess, (state, action) => {
                state.loading = false;
                state.agencies = action.payload.value.agencies;
            })
            .addCase(signUpGetAgenciesError, state => {
                state.loading = false;
            })
            .addCase(signUpFillDataRequest, (state, action) => {
                state.loading = true;
                state.loaded = false;
                state.errors = [];
            })
            .addCase(signUpFillDataSuccess, (state, action) => {
                state.fillDataSuccess = true;
                state.loading = false;
                state.loaded = true;
                state.errors = [];
            })
            .addCase(signUpFillDataError, (state, action) => {
                state.fillDataSuccess = false;
                state.loading = false;
            })
            .addCase(signUpAddDriverLicencePhoto, state => {
                state.loading = true;
                state.loaded = false;
                state.errors = [];
            })
            .addCase(signUpAddDriverLicencePhotoSuccess, (state, action) => {
                state.addDriverLicencePhotoSuccess = true;
                state.loading = false;
                state.loaded = true;
                state.errors = [];
            })
            .addCase(signUpAddDriverLicencePhotoError, (state, action) => {
                state.addDriverLicencePhotoSuccess = false;
                state.loading = false;
            })
            .addCase(signUpAddRealEsateLicencePhoto, state => {
                state.loading = true;
                state.loaded = false;
                state.errors = [];
            })
            .addCase(signUpAddRealEsateLicencePhotoSuccess, (state, action) => {
                state.addRealEstateLicencePhoto = true;
                state.loading = false;
                state.loaded = true;
                state.errors = [];
            })
            .addCase(signUpAddRealEsateLicencePhotoError, (state, action) => {
                state.addRealEstateLicencePhoto = false;
                state.loading = false;
            })
            .addCase(signUpSetStep, (state, action) => {
                state.step = action.payload;
            })
            .addCase(addNewAgencyRequest, state => {
                state.addNewAgency.loading = true;
                state.addNewAgency.errors = [];
            })
            .addCase(addNewAgencySuccess, (state, action) => {
                state.addNewAgency.loading = false;
                state.addNewAgency.value = action.payload;
                state.isAddedDraftAgency = true;
                state.addNewAgencyModalVisible = false;
            })
            .addCase(addNewAgencyError, (state, action) => {
                state.addNewAgency.loading = false;
                state.addNewAgency.errors = action.payload;
            })
            .addCase(handleAddNewAgencyModal, (state, action) => {
                state.addNewAgencyModalVisible = action.payload;
            })
            .addCase(removeDraftAgency, state => {
                state.addNewAgency.value = {
                    id: 0,
                    name: '',
                };
                state.isAddedDraftAgency = false;
            })
            .addCase(getLicensesDraft, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getLicensesDraftSuccess, (state, action) => {
                state.license = action.payload.value.licenseIds;
                state.loading = false;
                state.errors = [];
            })
            .addCase(getLicensesDraftError, (state, action) => {
                state.license = null;
                state.loading = false;
                state.errors = action.payload;
            });
    },
});

export default signUpSlice.reducer;
