import React, { ReactElement } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { BackArrow } from '../../../../assets/icons';
import { useHistory } from 'react-router-dom';

interface IBackButtonProps extends ButtonProps {
    color?: string;
    text?: string;
}

export const BackButton: React.FC<IBackButtonProps> = ({ color, text = 'Back', ...rest }) => {
    const history = useHistory();
    return (
        <Button
            leftIcon={<BackArrow color={color} />}
            variant="unstyled"
            fontSize="14px"
            color="secondaryFontDarker"
            fontWeight="normal"
            d="flex"
            align="center"
            mb="15px"
            onClick={() => history.goBack()}
            {...rest}
        >
            {text}
        </Button>
    );
};
