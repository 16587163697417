import { CoordinatesType } from '../../common/types';

export function getPolygonCenter(points: CoordinatesType[]) {
    var l = points.length;

    return points.reduce(
        function (center, p, i) {
            center.lat += p.lat;
            center.lng += p.lng;

            if (i === l - 1) {
                center.lat /= l;
                center.lng /= l;
            }

            return center;
        },
        { lat: 0, lng: 0 },
    );
}
