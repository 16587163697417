import { colors } from './layout.constants';
export const months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export enum CalendarCategoriesIds {
    assistanceRequests = 1,
    opportunities,
    openHouses,
    myActivities,
    agentActivities,
}

export const calendarCategoriesColors = {
    1: {
        type: 'assistanceRequests',
        color: colors.calendarLical,
        borderColor: colors.calendarLical,
    },
    2: {
        type: 'opportunities',
        color: colors.calendarYellow,
        borderColor: colors.calendarYellow,
    },
    3: {
        type: 'openHouses',
        color: colors.calendarBlue,
        borderColor: colors.calendarBlue,
    },
    4: {
        type: 'myActivities',
        color: colors.calendarGreen,
        borderColor: colors.calendarGreen,
    },
    5: {
        type: 'agentActivities',
        color: colors.calendarRed,
        borderColor: colors.calendarRed,
    },
};
