import React from 'react';
import { Box, Container, Flex } from '@chakra-ui/react';
import { DashboardHeader } from './DashboardHeader';

interface IDashboard {
    sidebar: React.ReactNode;
    content?: React.ReactNode;
}

export const DashboardWrapper: React.FC<IDashboard> = ({
    sidebar,
    content,
}) => {

    return (
        <Flex backgroundColor="brand" flexDirection="column" alignItems="center" minH="100vh">
            <DashboardHeader />
            <Container maxW="container.xl">
                <Flex mt="10px" borderRadius="8px" backgroundColor="#EBF2F8FF">
                    <Box>{sidebar}</Box>
                    <Box
                        paddingLeft="30px"
                        paddingRight="30px"
                        bgColor="#FFFFFF"
                        borderRadius="10px"
                        position="relative"
                        flex="1"
                    >
                        {content}
                    </Box>
                </Flex>
            </Container>
        </Flex>
    );
};
