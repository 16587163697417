import { createAction } from '@reduxjs/toolkit';
import { LoginResponseSuccessType } from '../../common/types';

export const checkPolicyViewedRequest = createAction<LoginResponseSuccessType | undefined>(
    'CHECK_POLICY_VIEWED_REQUEST',
);
export const checkPolicyViewedResult = createAction('CHECK_POLICY_VIEWED_RESULT');

export const checkTermsViewedRequest = createAction<LoginResponseSuccessType | undefined>(
    'CHECK_TERMS_VIEWED_REQUEST',
);
export const checkTermsViewedResult = createAction('CHECK_TERMS_VIEWED_RESULT');
