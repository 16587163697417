import {
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    IconButton,
    chakra,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BackArrow, CloseButton } from '../../../assets/icons';
import { CustomButton, CustomDropdown, CustomInput, MultiSelect } from '../../../common/components';
import { colors } from '../../../common/constants';
import { useCitiesByState } from '../../../common/helpers';
import {
    AddLicensePayload,
    LicenseDraftForm,
    SaveLicenseDraftPayload,
    LicenseType,
    LicenseDraftType,
} from '../types';
import { addLicenseResolver } from '../../../common/schemes';
import { GetCitiesResponse } from '../../../common/types';

interface IEditProfileModal {
    isOpen: boolean;
    onClose: () => void;
    modalType: string;
    license: LicenseType;
    saveLicenseDraft: (v: SaveLicenseDraftPayload) => void;
    draft: LicenseDraftType;
    agencies: any;
    states: any;
    setState: (v: string) => void;
    state: string;
    addLicense: (v: AddLicensePayload) => void;
    addLicenseLoading: boolean;
    saveDraftLoading: boolean;
}

export const LicenseEditorModal: React.FC<IEditProfileModal> = ({
    isOpen,
    onClose,
    modalType,
    license,
    saveLicenseDraft,
    draft,
    states,
    setState,
    state,
    agencies,
    addLicense,
    addLicenseLoading,
    saveDraftLoading,
}) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
        reset,
        setValue,
    } = useForm<LicenseDraftForm>({ resolver: addLicenseResolver });

    const values = watch();

    const cities = useCitiesByState(values.state ? values.state : state);
    const [typeIdsSelected, setTypeIdsSelected] = useState<GetCitiesResponse[]>([]);

    const onSubmit = (data: LicenseDraftForm) => {
        if (modalType === 'new') {
            const addPayload = {
                state: data.state,
                licenseNumber: data.licenseNumber,
                mlsId: data.mlsId,
                preferredAreasOfServices: typeIdsSelected,
                agencyId: +data.agency,
            };
            addLicense(addPayload);
        } else {
            const editPayload: SaveLicenseDraftPayload = {
                state: data.state,
                licenseNumber: data.licenseNumber,
                mlsId: data.mlsId,
                preferredAreasOfServices: typeIdsSelected,
                agencyId: +data.agency,
                changeLicensePreferredAreasOfServiceModel: [
                    {
                        licenseId: license.id,
                        preferredAreasOfServiceModels: typeIdsSelected,
                    },
                ],
            };
            saveLicenseDraft(editPayload);
        }
        console.log(data, 'LicenseDraftForm data');
    };

    useEffect(() => {
        if (!isOpen) {
            reset(undefined);
        } else if (modalType === 'existing') {
            reset({
                mlsId: license.mlsId,
                licenseNumber: license.license,
                agency: license.agencyId,
                state: license.state,
            });
        } else if (modalType === 'draft') {
            reset({
                mlsId: draft.mlsId,
                licenseNumber: draft.licenseNumber,
                agency: draft.agencyId,
                state: draft.state,
            });
        }
    }, [isOpen]);

    useEffect(() => {
        if (agencies.length && modalType !== 'new') {
            setValue('agency', modalType === 'existing' ? license.agencyId : draft.agencyId);
        }
    }, [agencies]);

    console.log(cities, 'cities');

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'90vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <IconButton
                        aria-label="close modal"
                        variant="unstyled"
                        onClick={onClose}
                        d="flex"
                        alignItems="center"
                    >
                        <BackArrow />
                    </IconButton>
                    <ModalHeader flex={1} align="center">
                        <Text textAlign="center" color="brand">
                            {modalType === 'new' ? 'Add new License' : 'Edit'}
                        </Text>
                    </ModalHeader>
                    <IconButton
                        aria-label="close modal"
                        variant="unstyled"
                        onClick={onClose}
                        d="flex"
                        alignItems="center"
                    >
                        <CloseButton />
                    </IconButton>
                </Flex>
                <ModalBody d="flex" w="100%" flexDirection="column" overflowY="auto">
                    <Flex direction="column" align="center" px="10%">
                        <chakra.form w="100%" onSubmit={handleSubmit(onSubmit)}>
                            {modalType === 'existing' && (
                                <Flex mb="20px">
                                    <Text fontSize="18px" fontWeight="500">
                                        {license.agencyName}
                                    </Text>
                                </Flex>
                            )}
                            <Flex w="100%" mb="15px">
                                {/* <Controller
                                    render={({ field }) => ( */}
                                <CustomDropdown
                                    options={states?.map((item: any) => ({
                                        value: item.name,
                                        label: item.name,
                                        id: item.id,
                                    }))}
                                    label="State"
                                    register={{
                                        ...register('state'),
                                        onChange: (value: any) => {
                                            console.log(value, 'onchange value');
                                            register('state').onChange(value);
                                            setState(value.target.value);
                                        },
                                        isDisabled: modalType === 'existing',
                                    }}
                                    placeholder="State"
                                    errors={errors.state}
                                    width="100%"
                                    height="50px"
                                    fontSize="16px"
                                    labelSize="16px"

                                    // onChange={(v: string) => setState(v)}
                                />
                            </Flex>
                            <Flex w="100%" mb="15px">
                                <CustomDropdown
                                    options={agencies?.map((item: any, i: any) => ({
                                        value: item.id,
                                        label: item.name,
                                        id: item.id,
                                    }))}
                                    label="Agency"
                                    register={{
                                        ...register('agency'),
                                        isDisabled: modalType === 'existing',
                                    }}
                                    placeholder="Agency"
                                    errors={errors.agency}
                                    width="100%"
                                    height="50px"
                                    fontSize="16px"
                                    labelSize="16px"
                                />
                            </Flex>
                            <Flex w="100%" mb="15px">
                                <CustomInput
                                    label="RE License"
                                    placeholder="RE License"
                                    register={{ ...register('licenseNumber') }}
                                    width="100%"
                                    // defaultValue={
                                    //     modalType === 'existing'
                                    //         ? license.license
                                    //         : modalType === 'draft'
                                    //         ? draft.licenseNumber
                                    //         : ''
                                    // }
                                    errors={errors.licenseNumber}
                                    disabled={modalType === 'existing'}
                                    height="50px"
                                    fontSize="16px"
                                    textSize="16px"
                                />
                            </Flex>
                            <Flex w="100%" mb="15px">
                                <CustomInput
                                    label="MLS ID"
                                    placeholder="MLS ID"
                                    register={{ ...register('mlsId') }}
                                    width="100%"
                                    // defaultValue={
                                    //     modalType === 'existing'
                                    //         ? license.mlsId
                                    //         : modalType === 'draft'
                                    //         ? draft.mlsId
                                    //         : ''
                                    // }
                                    errors={errors.mlsId}
                                    disabled={modalType === 'existing'}
                                    height="50px"
                                    fontSize="16px"
                                    textSize="16px"
                                />
                            </Flex>
                            {/* <Flex w="100%">
                                <Controller
                                    render={({ field }) => (
                                        <MultiSelect
                                            label="Areas of Service"
                                            options={cities?.map((item: any) => ({
                                                value: item.label,
                                                label: item.label,
                                                id: item.id,
                                            }))}
                                            value={field.value}
                                            onChange={field.onChange}
                                            defaultValue={
                                                license.areasOfServiceModels?.map(elem => ({
                                                    label: elem.cityName,
                                                    value: elem.cityId.toString(),
                                                })) ||
                                                draft?.prefferedAreasOfServices?.map(elem => ({
                                                    label: elem.name,
                                                    value: elem.name,
                                                }))
                                            }
                                            height="50px"
                                            fontSize="16px"
                                            // errors={errors.states && (errors.states as any).message}
                                        />
                                    )}
                                    name="preferredAreasOfServices"
                                    control={control}
                                />
                            </Flex> */}
                            {modalType !== 'new' ? (
                                <CustomButton
                                    text="Save"
                                    type="submit"
                                    width="100%"
                                    height="60px"
                                    bgColor="brand"
                                    margin="0 0 20px 0"
                                    isLoading={saveDraftLoading}
                                />
                            ) : (
                                <Flex w="100%" justify="center" direction="column">
                                    <CustomButton
                                        text="Save as draft"
                                        isLoading={addLicenseLoading}
                                        type="submit"
                                        width="100%"
                                        height="60px"
                                        bgColor="brand"
                                        margin="0 0 20px 0"
                                    />
                                </Flex>
                            )}
                        </chakra.form>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
