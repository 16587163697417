import { findAssistanceError, findAssistanceRequest, findAssistanceSuccess } from './actions';
import API from '../../../common/api/api.base';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { Alerter } from '../../../common/utils';
import { IResponseWithCustomValue } from '../../../common/types';

export function* workerFindAssistance(action: ReturnType<typeof findAssistanceRequest>) {
    try {
        const result: IResponseWithCustomValue<{ states: string[] }> = yield call(
            API.post,
            '/api/get-available-users',
            action.payload,
        );
        if (result.success) {
            yield put(findAssistanceSuccess());
            Alerter.info('Request successfully created', 2000);
        } else {
            yield put(findAssistanceError());
        }
    } catch (err) {
        console.log(err, 'workerFindAssistance');
    }
}

export default function* watchFindAssistanceSaga() {
    yield takeEvery(findAssistanceRequest.type, workerFindAssistance);
}
