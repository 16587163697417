import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, SimpleGrid } from '@chakra-ui/react';
import {
    DatePicker,
    CustomInput,
    CustomDropdown,
    CustomButton,
    CustomTextarea,
    TypeAhead,
    MultiSelect,
} from '../../../../../common/components';
import { Resolver, useForm, useFieldArray, Controller } from 'react-hook-form';
import createPostShowingSchema, {
    CreatePostShowingFormValues,
} from '../validation/createPostShowing.schema';
import dayjs from 'dayjs';
import { CreateJobPostPayload } from '../../../types';
import { ClockIcon } from '../../../../../assets/icons';
import { colors } from '../../../../../common/constants';
import {
    combineDateAndTime,
    carryAddressLoadOptions,
    getStateCodes,
    convertTimeIn24HoursFormat,
} from '../../../../../common/utils';
import { BonusConfirmModal } from '../../../../../common/components/modals/BonusConfirm/BonusConfirmModal';
import { StateDirectoryItem } from '../../../../../common/types';

interface IShowingFormProps {
    currentStates: string[];
    statesType: StateDirectoryItem[];
    requestValue: string;
    onCreatePost: (value: CreateJobPostPayload) => void;
    createPostLoading: boolean;
    setRequestValue: (value: string) => void;
}

export const ShowingForm: React.FC<IShowingFormProps> = ({
    currentStates,
    statesType,
    requestValue,
    onCreatePost,
    createPostLoading,
    setRequestValue,
}) => {
    const [finalPrice, setFinalPrice] = useState(0);
    const [feePrice, setFeePrice] = useState(0);
    const [filterStateCode, setFilterStateCode] = useState<string[]>([]);

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        clearErrors,
        getValues,
        control,
        watch,
        trigger,
    } = useForm<CreatePostShowingFormValues>({
        resolver: createPostShowingSchema as Resolver<CreatePostShowingFormValues>,
    });
    const { fields, append, remove } = useFieldArray({ control, name: 'addresses' });
    const [date, pricePerDoor] = getValues(['date', 'pricePerDoor']);
    const [watchPropertiesToShow, watchWouldLikeToOfferBonus, amount, watchPricePerDoor] = watch([
        'propertiesToShow',
        'wouldLikeToOfferBonus',
        'amount',
        'pricePerDoor',
    ]);

    const [bonusType, setBonusType] = useState('');
    const [offer, setOffer] = useState(false);

    useEffect(() => {
        setValue('price', pricePerDoor);
    }, [pricePerDoor]);

    const [agentBonusModalOpen, setAgentBonusModalOpen] = useState(false);
    const [bonusAmount, setBonusAmount] = useState('');

    const onSubmit = (data: CreatePostShowingFormValues) => {
        const fromTime = convertTimeIn24HoursFormat(data.fromTime);

        const payload = {
            ...data,
            typeId: Number(requestValue),
            date: combineDateAndTime(
                data.date ? dayjs(+data.date).toISOString() : dayjs().toISOString(),
                fromTime
                    ? dayjs(new Date())
                          .set('hour', +fromTime.split(':')[0])
                          .set('minute', +fromTime.split(':')[1])
                          .set('seconds', 0)
                          .set('milliseconds', 0)
                          .toISOString()
                    : dayjs().toISOString(),
            ),
            propertiesToShow: Number(data.propertiesToShow),
            price: Number(data.pricePerDoor),
            amount: Number(data.amount) || 0,
            wouldLikeToOfferBonus: data.wouldLikeToOfferBonus === '1',
            isShowingScheduled: data.isShowingScheduled === '1',
            clientTypeId: Number(data.clientTypeId),
            bonusTypeId: Number(bonusType),
            pricePerDoor: 0,
        };
        onCreatePost(payload);
    };

    useEffect(() => {
        if (watchPricePerDoor) {
            const totalPrice = (
                Number(watchPricePerDoor) +
                Number(watchPricePerDoor) * 0.015
            ).toFixed(2);
            const feePrice = (Number(watchPricePerDoor) * 0.015).toFixed(2);
            setFinalPrice(Number(totalPrice));
            setFeePrice(Number(feePrice));
        } else {
            setFinalPrice(0);
            setFeePrice(0);
        }
    }, [watchPricePerDoor]);

    useEffect(() => {
        remove();
        for (let i = 0; i < Number(watchPropertiesToShow); i++) {
            append({ name: '' });
        }
    }, [watchPropertiesToShow]);

    useEffect(() => {
        if (watchWouldLikeToOfferBonus === '1') {
            if (bonusType === '1') {
                setBonusAmount(`${amount}% of the commission`);
            } else {
                setBonusAmount(`$${amount}.00`);
            }
        }
    }, [amount, bonusType, watchWouldLikeToOfferBonus]);

    // useEffect(() => {
    //     if (currentStates.length !== 0 && statesType.length !== 0) {
    //         setFilterStateCode(getStateCodes(statesType, currentStates));
    //     }
    // }, [currentStates, statesType]);

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid columns={2} gap="50px" rowGap="0px">
                    <CustomDropdown
                        options={[
                            { label: 'Showing', value: '1', id: 1 },
                            { label: 'Home Inspection', value: '2', id: 2 },
                            { label: 'Final Walk-Through', value: '3', id: 3 },
                            { label: 'Other', value: '4', id: 4 },
                            { label: 'Appraisal', value: '5', id: 5 },
                            { label: 'Referral', value: '7', id: 7 },
                        ]}
                        label="Request type:*"
                        register={{
                            value: requestValue,
                            onChange: (ev: any) => {
                                setRequestValue(ev.target.value);
                            },
                        }}
                        errors={''}
                        width="350px"
                    />
                    <Controller
                        render={({ field }) => (
                            <MultiSelect
                                label="State:*"
                                options={currentStates.map((state, i) => ({
                                    value: state,
                                    label: state,
                                }))}
                                value={field.value}
                                onChange={field.onChange}
                                errors={errors.states && (errors.states as any).message}
                                width="350px"
                                // defaultValue={[
                                //     { value: currentStates[0], label: currentStates[0] },
                                // ]}
                            />
                        )}
                        control={control}
                        name="states"
                    />
                    <DatePicker
                        label="Date:*"
                        displayValue={date ? dayjs.utc(+date).format('MM/DD/YY') : ''}
                        value={Number(date) || dayjs.utc().valueOf()}
                        setValue={value => {
                            setValue('date', value.toString());
                            clearErrors('date');
                        }}
                        removeBottomMargin
                        placeholder="MM/DD/YY"
                        errMessage={errors.date?.message}
                        borderRadius="50px"
                        formControlProps={{
                            margin: errors.date ? '0 10px 0 0' : '0 10px 26px 0',
                        }}
                    />
                    <CustomInput
                        register={{ ...register('fromTime') }}
                        label="Start Time*"
                        placeholder="From Time"
                        type="time-12-hours"
                        defaultValue="00:00 AM"
                        errors={errors.fromTime}
                        rightIcon={<ClockIcon color={colors.secondaryFontDarker} />}
                        margin={errors.fromTime ? '0' : '0 0 26px 0'}
                        width="350px"
                    />
                    <CustomDropdown
                        register={{ ...register('propertiesToShow') }}
                        label="# of Properties (max 15):*"
                        options={Array.from(new Array(15)).map((value, i) => ({
                            value: (i + 1).toString(),
                            label: (i + 1).toString(),
                            id: i,
                        }))}
                        width="350px"
                        errors={errors.propertiesToShow}
                        margin={errors.propertiesToShow ? '0' : '0 0 26px 0'}
                    />
                    <CustomInput
                        register={{ ...register('pricePerDoor') }}
                        type="number"
                        label="Offer Price:*"
                        errors={errors.pricePerDoor}
                        rightIcon={<Text color="secondaryFontDarker">$</Text>}
                        placeholder="100"
                        width="350px"
                        margin={errors.pricePerDoor ? '0' : '0 0 26px 0 !important'}
                    />
                </SimpleGrid>
                <Flex flexDirection="column">
                    <SimpleGrid
                        columns={2}
                        gap="40px"
                        rowGap="0px"
                        pt="20px"
                        borderTop="1px solid #D1D2DB"
                        borderBottom="1px solid #D1D2DB"
                    >
                        {fields.map((item, index) => (
                            <Controller
                                key={index}
                                render={field => (
                                    <TypeAhead
                                        placeholder="Enter"
                                        label={'Address ' + (index + 1)}
                                        onStateChange={ev => {
                                            setValue(`addresses.${index}.name`, ev?.label || '');
                                            clearErrors(`addresses.${index}.name`);
                                        }}
                                        errors={errors.addresses?.[index]?.name?.message}
                                        loadOptions={carryAddressLoadOptions(
                                            false,
                                            filterStateCode,
                                        )}
                                        width="350px"
                                    />
                                )}
                                name={`addresses.${index}`}
                                control={control}
                                defaultValue={{ name: '' }}
                            />
                        ))}
                    </SimpleGrid>
                    <Flex pt="20px">
                        <CustomTextarea
                            register={{ ...register('description') }}
                            label="Description"
                            placeholder="Enter"
                            errors={errors.description}
                            height="75px"
                        />
                    </Flex>
                    <SimpleGrid columns={2} gap="50px" rowGap="0px" pt="20px">
                        <Box pt="20px">
                            <CustomDropdown
                                options={[
                                    {
                                        label: 'Yes, I Have Scheduled The Showing',
                                        value: '1',
                                        id: 1,
                                    },
                                    {
                                        label: 'No, the Agent Will Schedule The Showing',
                                        value: '2',
                                        id: 2,
                                    },
                                ]}
                                label="Is The Showing Scheduled?*"
                                placeholder="Select"
                                register={{
                                    onChange: (ev: any) => {
                                        setValue('isShowingScheduled', ev.target.value);
                                    },
                                }}
                                errors={errors.isShowingScheduled}
                                width="350px"
                            />
                        </Box>
                        <CustomDropdown
                            options={[
                                { label: 'Yes', value: '1', id: 1 },
                                { label: 'No', value: '2', id: 2 },
                            ]}
                            label="I would like to offer a Bonus if a contract is ratified from this showing list within 48-hours Of Showing:*"
                            placeholder="Select"
                            register={{
                                onChange: (ev: any) => {
                                    if (ev.target.value === '1') {
                                        setOffer(true);
                                    } else {
                                        setOffer(false);
                                    }
                                    setValue('wouldLikeToOfferBonus', ev.target.value);
                                },
                            }}
                            errors={errors.wouldLikeToOfferBonus}
                            width="350px"
                        />
                        {offer && (
                            <SimpleGrid columns={2} gap="50px" rowGap="0px" pt="20px">
                                <CustomDropdown
                                    options={[
                                        {
                                            label: '$',
                                            value: 2,
                                            id: 2,
                                        },
                                        {
                                            label: '%',
                                            value: 1,
                                            id: 1,
                                        },
                                    ]}
                                    label="Select bonus type:*"
                                    register={{
                                        value: bonusType,
                                        onChange: (ev: any) => {
                                            setBonusType(ev.target.value);
                                        },
                                    }}
                                    errors={errors.bonusTypeId}
                                    width="150px"
                                />
                                <CustomInput
                                    register={{ ...register('amount') }}
                                    type="number"
                                    label="Enter bonus:*"
                                    errors={errors.amount}
                                    rightIcon={
                                        bonusType === '1' ? (
                                            <Text color="secondaryFontDarker">%</Text>
                                        ) : (
                                            <Text color="secondaryFontDarker">$</Text>
                                        )
                                    }
                                    placeholder="100"
                                    width="150px"
                                    margin={errors.amount ? '0' : '0 0 26px 0 !important'}
                                />
                            </SimpleGrid>
                        )}
                        <Box pt="20px">
                            <CustomDropdown
                                label="The client is:*"
                                options={[
                                    { id: 1, label: 'A Couple', value: '1' },
                                    { id: 2, label: 'A Family', value: '2' },
                                    { id: 3, label: 'An Individual', value: '3' },
                                ]}
                                placeholder="Select"
                                // register={{ ...register('clientTypeId') }}
                                register={{
                                    onChange: (ev: any) => {
                                        setValue('clientTypeId', ev.target.value);
                                    },
                                }}
                                errors={errors.clientTypeId}
                                width="350px"
                                margin={errors.clientTypeId ? '0' : '0 0 26px 0'}
                            />
                        </Box>
                        <Box pt={offer ? '0px' : '20px'}>
                            <CustomInput
                                register={{ ...register('clientName') }}
                                type="text"
                                label="Client name:"
                                errors={errors.clientName}
                                placeholder="Enter"
                                width="350px"
                                margin={errors.clientName ? '0' : '0 0 26px 0 !important'}
                            />
                        </Box>
                        <CustomInput
                            register={{ ...register('clientPhone') }}
                            type="tel"
                            mask="999-999-9999"
                            label="Client phone number:"
                            errors={errors.clientPhone}
                            placeholder="Enter"
                            width="350px"
                            margin={errors.clientPhone ? '0' : '0 0 26px 0 !important'}
                        />
                    </SimpleGrid>
                    <Flex pt="20px">
                        <CustomTextarea
                            register={{ ...register('privateNote') }}
                            label="Private Notes To The Chosen Agent:"
                            placeholder="Enter"
                            errors={errors.privateNote}
                            height="50px"
                        />
                    </Flex>
                </Flex>
                <Flex pt="20px" flexDir="column" alignItems="flex-end">
                    <Flex justifyContent="space-between" width="350px">
                        <Text fontSize="13px" color="secondaryFontDarker">
                            Fee
                        </Text>
                        <Text fontSize="16px" fontWeight={500} color="secondaryFontDarker">
                            ${feePrice}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between" width="350px" mt="10px">
                        <Text fontSize="13px" color="secondaryFontDarker">
                            Offer Price
                        </Text>
                        <Text fontSize="16px" fontWeight={500} color="secondaryFontDarker">
                            ${pricePerDoor ? pricePerDoor : 0}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between" width="350px" mt="10px" mb="40px">
                        <Text fontSize="13px" color="brand" fontWeight={600}>
                            Eastimated Cost
                        </Text>
                        <Text fontSize="19px" fontWeight={500} color="brand">
                            ${finalPrice}
                        </Text>
                    </Flex>
                    <CustomButton
                        width="350px"
                        height="60px"
                        isLoading={createPostLoading}
                        loadingText="Sending..."
                        text="Post"
                        bgColor="brand"
                        mb="50px"
                        onClick={
                            watchWouldLikeToOfferBonus === '1' && amount && bonusType
                                ? async () => {
                                      const result = await trigger();
                                      if (result) {
                                          setAgentBonusModalOpen(true);
                                      }
                                  }
                                : handleSubmit(onSubmit)
                        }
                    />
                </Flex>
            </form>
            <BonusConfirmModal
                isOpen={agentBonusModalOpen}
                onClose={() => setAgentBonusModalOpen(false)}
                onConfirm={handleSubmit(onSubmit)}
                amount={bonusAmount}
                type={'Showing'}
            />
        </Box>
    );
};
