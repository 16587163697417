import { getTimeByTimeZone } from './getTimeByTimeZone';

import { time12HoursRegExp } from '../constants';

export const convertTimeIn12Hours = (time: string, timeZone?: string) => {
    if (time12HoursRegExp.test(time)) {
        return time;
    }

    const isTimeGreenwich = /(Z|\+00:00)$/.test(time);

    const timeZoneForConverting = isTimeGreenwich ? timeZone : '';

    return getTimeByTimeZone(time, timeZoneForConverting).format('hh:mm A');
};
