import React, {useCallback, useEffect, useState} from 'react'
import { PaymentsModalWrapper} from "../components";
import {UploadLicensePhoto} from "../components";
import {ErrorMessages} from "../../../../../common/schemes/messages";
import {useDispatch} from "react-redux";
import {uploadsNewDocumentRequest} from "../store/actions";


interface IUploadLicensePhotoModal{
    isOpen:boolean
    title: string
    onClose: () => void
    uploadNewDocumentLoading: boolean
}

export const UploadLicensePhotoModal:React.FC<IUploadLicensePhotoModal> = ({
    isOpen,
    onClose,
    title,
    uploadNewDocumentLoading

}) => {
    const [uploadedFile, setUploadedFile] = useState<
        string | ArrayBuffer | null
        >(null);
    const [photoError, setPhotoError] = useState('')

    const dispatch = useDispatch()

    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles, 'acceptedFiles')
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
        if(allowedTypes.includes(acceptedFiles[0].type)){
            setUploadedFile(acceptedFiles[0])
            setPhotoError('')
        }else {
            setUploadedFile(null)
            setPhotoError('Incorrect file format. Please select .jpg, .jpeg or .png')
        }

    },[])

    const onUploadLicenseDocument = () => {
        if(uploadedFile && !photoError){
            const formData = new FormData()
            formData.append('document', uploadedFile as any)
            dispatch(uploadsNewDocumentRequest({
                documentType: 'license',
                document: formData
            }))
        }else {
            setPhotoError(ErrorMessages.REQUIRED)
        }
    }
    useEffect(() => {
        if(!isOpen){
            setPhotoError('')
            setUploadedFile(null)
        }

    },[isOpen])

    return (
        <PaymentsModalWrapper isOpen={isOpen} title={title} onClose={onClose}>

            <UploadLicensePhoto
                uploadNewDocumentLoading={uploadNewDocumentLoading}
                onUploadLicenseDocument={onUploadLicenseDocument}
                error={photoError}
                onDrop={onDrop}
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
            />

        </PaymentsModalWrapper>

    )
}
