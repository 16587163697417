import React from 'react';

export const GetHelpNow: React.FC = () => (
    <svg width="35" height="35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35Z"
            fill="#E70000"
        />
        <path
            d="M17.583 10.333a7.085 7.085 0 0 0-5.009 12.093 7.085 7.085 0 1 0 5.009-12.093Zm0-.833a7.917 7.917 0 1 1 0 15.834 7.917 7.917 0 0 1 0-15.834Zm-.417 11.667h.833v1.666h-.833v-1.666ZM17.583 12a2.917 2.917 0 0 1 2.916 2.917c0 .747-.582 1.266-1.199 1.813l-.779.753c-.49.561-.543 1.629-.523 1.993v.024h-.832c-.003-.043-.085-1.637.728-2.566l.852-.826c.493-.439.92-.818.92-1.191a2.083 2.083 0 1 0-4.167 0h-.833A2.917 2.917 0 0 1 17.583 12Z"
            fill="#fff"
        />
    </svg>
);
