import { createAction } from '@reduxjs/toolkit';
import {
    UserAvatarPayload,
    UserAvatarSuccessResponse,
    UserInfoSuccessPayload,
    IdNameTypes,
    PayloadError,
    IResponseWithCustomValue, SearchAgentByAreaPayload, SearchAgentByAreaResponse,
} from '../../common/types';

export const getUserInfoRequest = createAction('GET_USER_INFO_REQUEST');
export const getUserInfoSuccess = createAction<UserInfoSuccessPayload>('GET_USER_INFO_SUCCESS');
export const getUserInfoError = createAction('GET_USER_INFO_ERROR');

export const getUserAvatarRequest = createAction<UserAvatarPayload>('GET_USER_AVATAR_REQUEST');
export const getUserAvatarSuccess =
    createAction<IResponseWithCustomValue<UserAvatarSuccessResponse>>('GET_USER_AVATAR_SUCCESS');
export const getUserAvatarError = createAction('GET_USER_AVATAR_ERROR');

export const getProfileAgencies = createAction<{ id: number }>('GET_PROFILE_AGENCIES');
export const getProfileAgenciesSuccess = createAction<IdNameTypes[]>(
    'GET_PROFILE_AGENCIES_SUCCESS',
);
export const getProfileAgenciesError = createAction<PayloadError[]>('GET_PROFILE_AGENCIES_ERROR');

export const searchAgentsByArea = createAction<SearchAgentByAreaPayload>('SEARCH_AGENTS_BY_AREA');
export const searchAgentsByAreaSuccess = createAction<Array<SearchAgentByAreaResponse>>('SEARCH_AGENTS_BY_AREA_SUCCESS');
export const searchAgentsByAreaError = createAction<PayloadError[]>('SEARCH_AGENTS_BY_AREA_ERROR');
export const clearAvailableAgents = createAction('CLEAR_AVAILABLE_AGENTS')

