import { call, put, takeEvery } from 'redux-saga/effects';

import {
    getAgenciesTypesRequest,
    getAgenciesTypesSuccess,
    getAgenciesTypesError,
} from '../../actions/directories.actions';
import Api from '../../../common/api/api.base';
import { IResponseWithCustomValue, IdNameTypes } from '../../../common/types';

function* workerGetAgencies() {
    try {
        const result: IResponseWithCustomValue<{
            agencies: IdNameTypes[];
        }> = yield call(Api.get, '/api/profile/agencies', {});

        if (result.success) {
            console.log(result, 'watchGetAgenciesTypeSaga');
            yield put(getAgenciesTypesSuccess(result.value.agencies));
        } else {
            yield put(getAgenciesTypesError(result.errors));
            // AlerterService.error(result?.errors[0].errorMessage);
            console.log('ERROR', result);
        }
    } catch (error) {
        console.log('Error');
        // AlerterService.error('Something went wrong, try again later');
    }
}

export function* watchGetAgenciesTypeDirectorySaga() {
    yield takeEvery(getAgenciesTypesRequest.type, workerGetAgencies);
}
