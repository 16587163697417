import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FindAssistance } from './FindAssistance';

import { RootStore } from '../../store';
import { getCurrentStates } from '../../store/actions/order.actions';
import { getStates } from '../../store/actions/directories.actions';
import { findAssistanceRequest } from './store/actions';

import { FindAssistancePayloadType } from './types';

import { setConnectPaymentModalOpen } from '../Notifications/store/actions';

export const FindAssistanceContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { states, assistanceLoading } = useSelector((state: RootStore) => state.order);
    const statesTypes = useSelector((state: RootStore) => state.directories.states);
    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed = roles?.some((role: string) => role === 'user');

    const onFindAssistance = (data: FindAssistancePayloadType) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            return;
        }
        dispatch(findAssistanceRequest(data));
    };

    useEffect(() => {
        dispatch(getCurrentStates());
        dispatch(getStates());
    }, []);

    return (
        <>
            <FindAssistance
                states={states}
                statesTypes={statesTypes}
                onFindAssistance={onFindAssistance}
                assistanceLoading={assistanceLoading}
            />
        </>
    );
};
