import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    CloseButton,
    Button,
} from '@chakra-ui/react';
import React from 'react';
import { BackArrow, EditIcon, PhoneIcon } from '../../../assets/icons';
import { CustomInput } from '../../../common/components';
import { colors } from '../../../common/constants';
import { fonts } from '../../../common/utils';

interface IEditProfileModal {
    isOpen: boolean;
    onClose: () => void;
    showEditNameModal: () => void;
    showEditPhoneNumberModal: () => void;
    showEditEmailModal: () => void;
    showEditPasswordModal: () => void;
    showEditLicenseModal: () => void;
    currentUser: any;
    avatar: any;
}

export const EditProfileModal: React.FC<IEditProfileModal> = ({
    isOpen,
    onClose,
    showEditNameModal,
    showEditEmailModal,
    showEditPhoneNumberModal,
    showEditPasswordModal,
    showEditLicenseModal,
    currentUser,
    avatar,
}) => {
    const { email, firstName, lastName, phoneNumber } = currentUser;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <Box as="button" onClick={onClose}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text
                            fontSize="22px"
                            color="brand"
                            textAlign="center"
                            lineHeight="18px"
                            fontWeight={700}
                            fontFamily={fonts.montserrat}
                        >
                            Edit
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody d="flex" w="68vw" flexDirection="column" overflowY="auto">
                    <Flex direction="column" align="center" mt="20px">
                        <Flex align="center" direction="column" width="40%">
                            {/* <Flex w="100%" mb="15px">
                                <CustomInput
                                    label="Full Name"
                                    placeholder="Full Name"
                                    register={''}
                                    width="100%"
                                    isReadOnly
                                    props={{
                                        onClick: showEditNameModal,
                                        value: `${firstName} ${lastName}`,
                                    }}
                                />
                            </Flex> */}
                            <CustomInput
                                label="Phone Number"
                                width="100%"
                                leftIcon={<PhoneIcon />}
                                placeholder="Phone Number"
                                register=""
                                isReadOnly
                                props={{
                                    value: phoneNumber,
                                }}
                                margin="0 0 15px 0"
                            />
                            <CustomInput
                                label="Email"
                                placeholder="Email"
                                register={''}
                                width="100%"
                                isReadOnly
                                props={{
                                    value: email,
                                }}
                                margin="0 0 15px 0"
                            />
                            <Button
                                leftIcon={<EditIcon />}
                                variant="unstyled"
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight={400}
                                color="brand"
                                iconSpacing="15px"
                                onClick={showEditPasswordModal}
                            >
                                Edit Password
                            </Button>
                            <Button
                                leftIcon={<EditIcon />}
                                variant="unstyled"
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight={400}
                                color="brand"
                                iconSpacing="15px"
                                onClick={showEditPhoneNumberModal}
                            >
                                Edit Phone
                            </Button>
                            <Button
                                leftIcon={<EditIcon />}
                                variant="unstyled"
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight={400}
                                color="brand"
                                iconSpacing="15px"
                                onClick={showEditEmailModal}
                            >
                                Edit Email
                            </Button>
                            {/* <Flex w="100%" justify="flex-end" mb="40px">
                                <EditIcon />
                                <Text ml="5px" fontSize="13px" fontWeight="300" color="#888DAB">
                                    Edit profile
                                </Text>
                            </Flex> */}
                            {/* <Flex>
                                <Text
                                    as="u"
                                    ml="5px"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="#28B0AF"
                                    onClick={showEditLicenseModal}
                                    _hover={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    Photo Of Driver License
                                </Text>
                            </Flex> */}
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
