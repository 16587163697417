import React from 'react';
import { LandingFooter, LandingHeader, TrialBanner } from '../../../common/components/landing';
import WhyZpBG from '../../../assets/landing/why-zoot-pro-bg.png';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { fonts } from '../../../common/utils';
import { whyBlocks } from '../../../common/constants';
import { WhyBlock } from './components/WhyBlock';

export const WhyZootPro = () => {
    return (
        <>
            <LandingHeader />
            <Box
                h={{ base: '300px', lg: '450px' }}
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={WhyZpBG}
                position="relative"
                backgroundSize="cover"
                overflowY="hidden"
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />
                <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                    <Flex direction="column" height="100%" justify="center" pb="70px">
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={{ base: '28px', lg: '54px' }}
                            lineHeight={{ base: '32px', lg: '62px' }}
                            fontWeight="bold"
                            w={{ base: '100%', lg: '60%' }}
                        >
                            Why ZootPro?
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize="16px"
                            lineHeight="28px"
                            my="23px"
                        >
                            Agents committed to building successful businesses use ZootPro.
                        </Text>
                    </Flex>
                </Container>
            </Box>
            <Box
                bg="white"
                borderTopRadius={{ base: '30px', md: '70px' }}
                mt="-70px"
                zIndex={10}
                position="relative"
            >
                <Container
                    maxW="container.xl"
                    pt="40px"
                    pb={{ base: '50px', lg: '130px' }}
                    px="25px"
                >
                    {whyBlocks.map((block, index, arr) => {
                        const addDots = () => {
                            if (index === 0) {
                                return 'bottom';
                            }

                            if (index === arr.length - 1) {
                                return 'top';
                            }
                        };

                        return <WhyBlock key={block.title} hasDots={addDots()} {...block} />;
                    })}
                </Container>
            </Box>
            <TrialBanner
                title="Grow your real estate business the stress-free way."
                buttonTitle="Start"
                textUnderButton="Cancel anytime"
            />
            <LandingFooter />
        </>
    );
};
