import dayjs from 'dayjs';

export const shouldShowCancellationMessage = (date: string, OpenHouseHostTypeId: number) => {
    if (
        (dayjs.utc().isSameOrAfter(dayjs.utc(date), 'hour') ||
            dayjs.utc(date).diff(dayjs.utc(), 'hour') === 0) &&
        OpenHouseHostTypeId !== 2
    ) {
        return true;
    }
    return false;
};
