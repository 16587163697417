import React from 'react';
import { colors } from '../../common/constants';

export const LicenseIcon: React.FC = () => (
    <svg width="20" height="15" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.5 10.5V11H3.5V10.5H2.5ZM7.5 10.5V11H8.5V10.5H7.5ZM3.5 10.5V9.99988H2.5V10.5H3.5ZM7.5 9.99966V10.5H8.5V9.99966H7.5ZM5.5 8C6.60466 8 7.5 8.89518 7.5 9.99966H8.5C8.5 8.34272 7.15677 7 5.5 7V8ZM3.5 9.99988C3.5 8.89534 4.3954 8 5.5 8V7C3.84318 7 2.5 8.343 2.5 9.99988H3.5ZM5.5 2C4.39543 2 3.5 2.89543 3.5 4H4.5C4.5 3.44772 4.94772 3 5.5 3V2ZM7.5 4C7.5 2.89543 6.60457 2 5.5 2V3C6.05228 3 6.5 3.44772 6.5 4H7.5ZM5.5 6C6.60457 6 7.5 5.10457 7.5 4H6.5C6.5 4.55228 6.05228 5 5.5 5V6ZM5.5 5C4.94772 5 4.5 4.55228 4.5 4H3.5C3.5 5.10457 4.39543 6 5.5 6V5ZM2 1H14V0H2V1ZM14.5 1.5V9.5H15.5V1.5H14.5ZM14 10H2V11H14V10ZM1.5 9.5V1.5H0.5V9.5H1.5ZM2 10C1.72386 10 1.5 9.77614 1.5 9.5H0.5C0.5 10.3284 1.17157 11 2 11V10ZM14.5 9.5C14.5 9.77614 14.2761 10 14 10V11C14.8284 11 15.5 10.3284 15.5 9.5H14.5ZM14 1C14.2761 1 14.5 1.22386 14.5 1.5H15.5C15.5 0.671573 14.8284 0 14 0V1ZM2 0C1.17157 0 0.5 0.671573 0.5 1.5H1.5C1.5 1.22386 1.72386 1 2 1V0ZM9.5 4H12.5V3H9.5V4ZM9.5 7H12.5V6H9.5V7Z"
            fill={colors.brand}
        />
    </svg>
);
