import { States } from '../types';

export const states: States[] = [
    {
        "id": 1,
        "label": "Alabama",
        "code": "AL",
        "value": "1,Alabama"
    },
    {
        "id": 2,
        "label": "Alaska",
        "code": "AK",
        "value": "2,Alaska"
    },
    {
        "id": 3,
        "label": "Arizona",
        "code": "AZ",
        "value": "3,Arizona"
    },
    {
        "id": 4,
        "label": "Arkansas",
        "code": "AR",
        "value": "4,Arkansas"
    },
    {
        "id": 5,
        "label": "California",
        "code": "CA",
        "value": "5,California"
    },
    {
        "id": 6,
        "label": "Colorado",
        "code": "CO",
        "value": "6,Colorado"
    },
    {
        "id": 7,
        "label": "Connecticut",
        "code": "CT",
        "value": "7,Connecticut"
    },
    {
        "id": 8,
        "label": "Delaware",
        "code": "DE",
        "value": "8,Delaware"
    },
    {
        "id": 9,
        "label": "District of Columbia",
        "code": "DC",
        "value": "9,District of Columbia"
    },
    {
        "id": 10,
        "label": "Florida",
        "code": "FL",
        "value": "10,Florida"
    },
    {
        "id": 11,
        "label": "Georgia",
        "code": "GA",
        "value": "11,Georgia"
    },
    {
        "id": 12,
        "label": "Hawaii",
        "code": "HI",
        "value": "12,Hawaii"
    },
    {
        "id": 13,
        "label": "Idaho",
        "code": "ID",
        "value": "13,Idaho"
    },
    {
        "id": 14,
        "label": "Illinois",
        "code": "IL",
        "value": "14,Illinois"
    },
    {
        "id": 15,
        "label": "Indiana",
        "code": "IN",
        "value": "15,Indiana"
    },
    {
        "id": 16,
        "label": "Iowa",
        "code": "IA",
        "value": "16,Iowa"
    },
    {
        "id": 17,
        "label": "Kansas",
        "code": "KS",
        "value": "17,Kansas"
    },
    {
        "id": 18,
        "label": "Kentucky",
        "code": "KY",
        "value": "18,Kentucky"
    },
    {
        "id": 19,
        "label": "Louisiana",
        "code": "LA",
        "value": "19,Louisiana"
    },
    {
        "id": 20,
        "label": "Maine",
        "code": "ME",
        "value": "20,Maine"
    },
    {
        "id": 21,
        "label": "Maryland",
        "code": "MD",
        "value": "21,Maryland"
    },
    {
        "id": 22,
        "label": "Massachusetts",
        "code": "MA",
        "value": "22,Massachusetts"
    },
    {
        "id": 23,
        "label": "Michigan",
        "code": "MI",
        "value": "23,Michigan"
    },
    {
        "id": 24,
        "label": "Minnesota",
        "code": "MN",
        "value": "24,Minnesota"
    },
    {
        "id": 25,
        "label": "Mississippi",
        "code": "MS",
        "value": "25,Mississippi"
    },
    {
        "id": 26,
        "label": "Missouri",
        "code": "MO",
        "value": "26, Missouri"
    },
    {
        "id": 27,
        "label": "Montana",
        "code": "MT",
        "value": "27,Montana"
    },
    {
        "id": 28,
        "label": "Nebraska",
        "code": "NE",
        "value": "28,Nebraska"
    },
    {
        "id": 29,
        "label": "Nevada",
        "code": "NV",
        "value": "29, Nevada"
    },
    {
        "id": 30,
        "label": "New Hampshire",
        "code": "NH",
        "value": "30,New Hampshire"
    },
    {
        "id": 31,
        "label": "New Jersey",
        "code": "NJ",
        "value": "31,New Jersey"
    },
    {
        "id": 32,
        "label": "New Mexico",
        "code": "NM",
        "value": "32,New Mexico"
    },
    {
        "id": 33,
        "label": "New York",
        "code": "NY",
        "value": "33,New York"
    },
    {
        "id": 34,
        "label": "North Carolina",
        "code": "NC",
        "value": "34,North Carolina"
    },
    {
        "id": 35,
        "label": "North Dakota",
        "code": "ND",
        "value": "35,North Dakota"
    },
    {
        "id": 36,
        "label": "Ohio",
        "code": "OH",
        "value": "36,Ohio"
    },
    {
        "id": 37,
        "label": "Oklahoma",
        "code": "OK",
        "value": "37,Oklahoma"
    },
    {
        "id": 38,
        "label": "Oregon",
        "code": "OR",
        "value": "38,Oregon"
    },
    {
        "id": 39,
        "label": "Pennsylvania",
        "code": "PA",
        "value": "39,Oregon"
    },
    {
        "id": 40,
        "label": "Puerto Rico",
        "code": "PR",
        "value": "40,Puerto Rico"
    },
    {
        "id": 41,
        "label": "Rhode Island",
        "code": "RI",
        "value": "41,Rhode Island"
    },
    {
        "id": 42,
        "label": "South Carolina",
        "code": "SC",
        "value": "42,South Carolina"
    },
    {
        "id": 43,
        "label": "South Dakota",
        "code": "SD",
        "value": "43,South Dakota"
    },
    {
        "id": 44,
        "label": "Tennessee",
        "code": "TN",
        "value": "44,Tennessee"
    },
    {
        "id": 45,
        "label": "Texas",
        "code": "TX",
        "value": "45,Texas"
    },
    {
        "id": 46,
        "label": "Utah",
        "code": "UT",
        "value": "46,Utah"
    },
    {
        "id": 47,
        "label": "Vermont",
        "code": "VT",
        "value": "47,Vermont"
    },
    {
        "id": 48,
        "label": "Virginia",
        "code": "VA",
        "value": "48,Virginia"
    },
    {
        "id": 49,
        "label": "Washington",
        "code": "WA",
        "value": "49,Washington"
    },
    {
        "id": 50,
        "label": "West Virginia",
        "code": "WV",
        "value": "50,West Virginia"
    },
    {
        "id": 51,
        "label": "Wisconsin",
        "code": "WI",
        "value": "51,Wisconsin"
    },
    {
        "id": 52,
        "label": "Wyoming",
        "code": "WY",
        "value": "52,Wyoming"
    }
]