import React, { useCallback, useEffect, useState } from 'react';
import { Payments } from './Payments';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserPaymentAccountRequest, createDwollaAccountRequest } from './store/actions';
import { RootStore } from '../../../../store';
import { CreateDwollaAccountModal } from './modals';
import { CreateDwollaAccountPayload } from './types';
import { useDelta } from 'react-delta';

export const PaymentsContainer: React.FC = () => {
    const [showCreateDwollaAccount, setShowCreateDwollaAccount] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const dispatch = useDispatch();

    const { value: isUserHasPaymentAccount, loading: checkUserPaymentAccountLoading } = useSelector(
        (state: RootStore) => state.payments.checkUserPaymentAccount,
    );

    const { loading: createDwollaAccountLoading, errors } = useSelector(
        (state: RootStore) => state.payments.createDwollaAccount,
    );

    const creteDwollaAccountLoadingDelta = useDelta(createDwollaAccountLoading);

    const onCreateDwollaAccount = useCallback((payload: CreateDwollaAccountPayload) => {
        dispatch(createDwollaAccountRequest(payload));
    }, []);

    useEffect(() => {
        dispatch(checkUserPaymentAccountRequest());
    }, []);

    useEffect(() => {
        if (
            !creteDwollaAccountLoadingDelta?.prev &&
            creteDwollaAccountLoadingDelta?.curr &&
            !errors.length
        ) {
            setShowCreateDwollaAccount(false);
        }
    }, [creteDwollaAccountLoadingDelta]);

    return (
        <>
            <Payments
                setTabIndex={setTabIndex}
                tabIndex={tabIndex}
                isUserHasPaymentAccount={isUserHasPaymentAccount}
                checkUserPaymentAccountLoading={checkUserPaymentAccountLoading}
                setShowCreateDwollaAccount={setShowCreateDwollaAccount}
            />
            {/*Create Dwolla Account Modal*/}
            <CreateDwollaAccountModal
                isOpen={showCreateDwollaAccount}
                createDwollaAccountLoading={createDwollaAccountLoading}
                onCreateDwollaAccount={onCreateDwollaAccount}
                title={'Create account'}
                onClose={() => setShowCreateDwollaAccount(false)}
            />
        </>
    );
};
