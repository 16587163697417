import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PaymentDropDown } from '../../components';
import { GetFundingSourcesResponse } from '../../types';

import { Box, Text } from '@chakra-ui/react';
import { useDelta } from 'react-delta';
import { CustomButton } from '../../../../../../common/components';

interface IPaymentMethodTabProps {
    fundingSourcesLoading: boolean;
    fundingSources: GetFundingSourcesResponse[];
    onSetSendingFundingSource: (id: string) => void;
    onSetReceivingFundingSource: (id: string) => void;
    setShowFundingSourceModal: Dispatch<SetStateAction<boolean>>;
}

export const PaymentMethodTab: React.FC<IPaymentMethodTabProps> = ({
    fundingSources,
    fundingSourcesLoading,
    onSetReceivingFundingSource,
    onSetSendingFundingSource,
    setShowFundingSourceModal,
}) => {
    const [fundingSourceReceiving, setFundingSourceReceiving] = useState<GetFundingSourcesResponse>(
        {} as GetFundingSourcesResponse,
    );
    const [fundingSourceSending, setFundingSourceSending] = useState<GetFundingSourcesResponse>(
        {} as GetFundingSourcesResponse,
    );

    const receivingDeltaId = useDelta(fundingSourceReceiving?.dwollaId);
    const sendingDeltaId = useDelta(fundingSourceSending?.dwollaId);

    useEffect(() => {
        if (fundingSources.length !== 0) {
            setFundingSourceReceiving(fundingSources.filter(item => item.isDefaultDestination)[0]);
            setFundingSourceSending(fundingSources.filter(item => item.isDefaultSource)[0]);
        }
    }, [fundingSources]);

    useEffect(() => {
        if (
            receivingDeltaId &&
            receivingDeltaId.prev &&
            receivingDeltaId.curr &&
            receivingDeltaId?.prev !== receivingDeltaId?.curr
        ) {
            onSetReceivingFundingSource(receivingDeltaId.curr);
        }
    }, [receivingDeltaId]);

    useEffect(() => {
        if (
            sendingDeltaId &&
            sendingDeltaId.prev &&
            sendingDeltaId.curr &&
            sendingDeltaId?.prev !== sendingDeltaId?.curr
        ) {
            onSetSendingFundingSource(sendingDeltaId.curr);
        }
    }, [sendingDeltaId]);

    return (
        <>
            <Text
                mt={'43px'}
                mb={'16px'}
                _before={{
                    content: `"receiving "`,
                    fontWeight: 600,
                }}
                letterSpacing={'2px'}
                fontSize={'14px'}
                lineHeight={'21px'}
                textTransform={'uppercase'}
            >
                funds method
            </Text>
            <PaymentDropDown
                loading={fundingSourcesLoading}
                items={fundingSources}
                setValue={setFundingSourceReceiving}
                value={fundingSourceReceiving}
            />

            <Text
                mb={'16px'}
                mt={'43px'}
                _before={{
                    content: `"sending "`,
                    fontWeight: 600,
                }}
                letterSpacing={'2px'}
                fontSize={'14px'}
                lineHeight={'21px'}
                textTransform={'uppercase'}
            >
                funds method
            </Text>

            <PaymentDropDown
                loading={fundingSourcesLoading}
                items={fundingSources}
                setValue={setFundingSourceSending}
                value={fundingSourceSending}
            />
            <Box textAlign={'right'}>
                <CustomButton
                    onClick={() => setShowFundingSourceModal(true)}
                    mt={'128px'}
                    maxW={'342px'}
                    text="Funding Sources"
                    height="60px"
                    bgColor="brand"
                />
            </Box>
        </>
    );
};
