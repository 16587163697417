import React from 'react';
import { colors } from '../../common/constants';

export const QuestionIcon: React.FC = () => (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
            <path
                d="M16.5016 19.8V16.5H17.6016C19.4241 16.5 20.9016 15.0225 20.9016 13.2V12.98C20.9016 11.2789 19.5226 9.89998 17.8216 9.89998H16.5016C14.679 9.89998 13.2016 11.3774 13.2016 13.2M15.4016 23.1H17.6016M16.5016 31.9C7.99638 31.9 1.10156 25.0052 1.10156 16.5C1.10156 7.99479 7.99638 1.09998 16.5016 1.09998C25.0067 1.09998 31.9016 7.99479 31.9016 16.5C31.9016 25.0052 25.0067 31.9 16.5016 31.9Z"
                stroke={colors.secondaryFontDarker}
            />
        </g>
    </svg>
);
