import React from 'react';
import { Box, Text, Flex, Checkbox, Input } from '@chakra-ui/react';
import { Controller, FieldValues, FieldArrayWithId, Control } from 'react-hook-form';

interface IAddressItemViewProps {
    index: number;
    address: string;
    isCompleted: boolean;
}

export const AddressItemView: React.FC<IAddressItemViewProps> = ({
    index,
    address,
    isCompleted,
}) => (
    <Box>
        <Box borderBottom="1px solid #D1D2DB" py="15px" mb="10px">
            <Text color="brand" fontSize="13px" fontWeight="700" lineHeight="20px">
                Address {index + 1}
            </Text>

            <Text
                color="rgba(44, 51, 92, 0.55)"
                fontSize="16px"
                lineHeight="24px"
                fontWeight={400}
                textDecorationLine={isCompleted ? 'line-through' : 'none'}
            >
                {address}
            </Text>
        </Box>
    </Box>
);
