import React from 'react';
import { Box, Flex, HStack, Text, IconButton, chakra } from '@chakra-ui/react';
import {
    Calendar,
    Location,
    Clock,
    Home,
    DollarSignIcon,
    ConsultMethodIcon,
    DropdownArrowIcon,
} from '../../../assets/icons';
import { FindJobPostsResponseType } from '../types';
import dayjs from 'dayjs';
import { IconDescription } from '../../../common/components/blocks';
import { colors } from '../../../common/constants';
import pluralize from 'pluralize';
import { fonts } from '../../../common/utils';

interface IPostItemProps extends FindJobPostsResponseType {
    onOpenModal: () => void;
}

export const PostItem: React.FC<IPostItemProps> = ({
    requestTypeName,
    states,
    addresses,
    dateTime,
    propertiesToShow,
    duration,
    onOpenModal,
    typeId,
    state,
    finalPrice,
}) => (
    <Flex
        h="70px"
        bg="inputBackground"
        borderRadius="14px"
        justifyContent="space-between"
        px="25px"
        mb="10px"
    >
        <HStack align="flex-start" alignSelf="center" width="40%">
            <Box>
                <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={600}>
                    {requestTypeName}
                </Text>
                <HStack mt="5px">
                    <Location />
                    <Text
                        color={
                            requestTypeName === 'Showing' || requestTypeName === 'Referral'
                                ? 'secondaryFontDarker'
                                : 'brand'
                        }
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight={500}
                    >
                        {requestTypeName === 'Showing' || requestTypeName === 'Referral'
                            ? states.join(',')
                            : `${states.join('')}, ${addresses?.join('')}`}
                    </Text>
                </HStack>
            </Box>
        </HStack>
        <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
            {typeId === 7 ? (
                <IconDescription
                    icon={
                        <Text
                            color="brand"
                            fontSize="12px"
                            fontWeight="500"
                            fontFamily={fonts.montserrat}
                        >
                            State:
                        </Text>
                    }
                    text={state || ''}
                />
            ) : (
                <>
                    <IconDescription
                        icon={<Calendar />}
                        text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                    />
                    <IconDescription icon={<Clock />} text={dayjs(dateTime).format('hh:mmA')} />
                    <IconDescription
                        icon={
                            requestTypeName === 'Showing' ? (
                                <Home color={colors.brand} width="16" height="16" />
                            ) : (
                                <ConsultMethodIcon />
                            )
                        }
                        text={
                            requestTypeName === 'Showing'
                                ? `${propertiesToShow} ${pluralize('house', propertiesToShow)}`
                                : `${duration} ${pluralize('hour', duration)}`
                        }
                    />
                    <IconDescription icon={<DollarSignIcon />} text={finalPrice} />
                </>
            )}
        </Flex>
        <IconButton
            onClick={onOpenModal}
            bg="transparent"
            alignSelf="center"
            aria-label="More for job"
            ml="auto"
            icon={
                <chakra.span transform="rotate(-90deg)">
                    <DropdownArrowIcon />
                </chakra.span>
            }
        />
    </Flex>
);
