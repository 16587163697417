import { call, put, takeEvery } from 'redux-saga/effects';

// import AlerterService from '../../../services/AlerterService';
import Api from '../../../common/api/api.base';
import { IGetBonusTypesSuccessResponse, IResponseWithCustomValue } from '../../../common/types';
import {
  getBonusTypes,
  getBonusTypesError,
  getBonusTypesSuccess,
} from '../../actions/directories.actions';

function* workerGetBonusTypes() {
  try {
    const result: IResponseWithCustomValue<IGetBonusTypesSuccessResponse> = yield call(
      Api.get,
      '/api/directories/get-bonus-types',
      {},
    );
    if (result.success) {
      yield put(getBonusTypesSuccess(result));
    } else {
      yield put(getBonusTypesError(result.errors));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetBonusTypesDirectorySaga() {
  yield takeEvery(getBonusTypes.type, workerGetBonusTypes);
}
