import React from 'react';

export const NewBlast: React.FC = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z" fill="#1177BE"/>
    <path d="M15.9436 12.6387H12.8325C12.4029 12.6387 12.0547 12.9869 12.0547 13.4164V16.5276C12.0547 16.9571 12.4029 17.3053 12.8325 17.3053H15.9436C16.3731 17.3053 16.7214 16.9571 16.7214 16.5276V13.4164C16.7214 12.9869 16.3731 12.6387 15.9436 12.6387Z" stroke="white"/>
    <path d="M22.1658 12.6387H19.0547C18.6251 12.6387 18.2769 12.9869 18.2769 13.4164V16.5276C18.2769 16.9571 18.6251 17.3053 19.0547 17.3053H22.1658C22.5954 17.3053 22.9436 16.9571 22.9436 16.5276V13.4164C22.9436 12.9869 22.5954 12.6387 22.1658 12.6387Z" stroke="white"/>
    <path d="M22.1658 18.8609H19.0547C18.6251 18.8609 18.2769 19.2091 18.2769 19.6387V22.7498C18.2769 23.1793 18.6251 23.5276 19.0547 23.5276H22.1658C22.5954 23.5276 22.9436 23.1793 22.9436 22.7498V19.6387C22.9436 19.2091 22.5954 18.8609 22.1658 18.8609Z" stroke="white"/>
    <path d="M15.9436 18.8609H12.8325C12.4029 18.8609 12.0547 19.2091 12.0547 19.6387V22.7498C12.0547 23.1793 12.4029 23.5276 12.8325 23.5276H15.9436C16.3731 23.5276 16.7214 23.1793 16.7214 22.7498V19.6387C16.7214 19.2091 16.3731 18.8609 15.9436 18.8609Z" stroke="white"/>
    </svg>
)