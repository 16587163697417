import { call, put, takeEvery } from "redux-saga/effects";
import API from '../../common/api/api.base';
import { IResponseWithCustomValue } from "../../common/types";
import { DashboardType } from "../../common/types/dashboard.types";
import { getDashboardRequest, getDashboardRequestError, getDashboardRequestSuccess } from "../actions/dashboard.actions";

function* workerGetDashboard(action: ReturnType<typeof getDashboardRequest>) {
    try {
        const result: IResponseWithCustomValue<{
            requests: Array<DashboardType>;
            totalCount: number;
        }> = yield call(API.post, '/api/requests', action.payload);
        if (result.success) {
            yield put(
                getDashboardRequestSuccess({
                    ...result.value,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getDashboardRequestError(result.errors));
        }
    } catch (error) {
        console.log('workerGetDashboard error', error);
    }
}

export default function* watchDashboardSaga() {
    yield takeEvery(getDashboardRequest.type, workerGetDashboard);
}