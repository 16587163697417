import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ListItem } from './ListItem';
import { AvailableForApplicationResponse } from '../../../../pages/Calendar/types';
import dayjs from 'dayjs';

interface IListBlockProps {
    startTime: string;
    models: AvailableForApplicationResponse[];
    setPostId: (value: number) => void;
    borderLeftColor: string;
    bgColor: string;
}

export const ListBlock: React.FC<IListBlockProps> = ({
    models,
    startTime,
    setPostId,
    bgColor,
    borderLeftColor,
}) => (
    <Box>
        <Box borderLeftColor={borderLeftColor} borderLeftWidth="8px" bgColor={bgColor} mb="10px">
            <Text fontWeight={400} fontSize="20px" ml={5}>
                Starting{' '}
                {dayjs(startTime.replace('0001', new Date().getFullYear().toString())).format(
                    'hh:mm A',
                )}
            </Text>
        </Box>
        {models.map(elem => (
            <ListItem key={elem.id} {...elem} onPressDetails={() => setPostId(elem.id)} />
        ))}
    </Box>
);
