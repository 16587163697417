import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

export type ReferralFormValues = {
    states: Array<string>;
    fromTime: string;
    referralFee: number;
    description: string;
    clientName: string;
    clientPhone: string;
    agencyIds: Array<string>;
    privateNote: string;
    clientTypeId: number;
};

const createPostReferralSchema = yup.object().shape({
    states: yup.array().required(ErrorMessages.REQUIRED),
    referralFee: yup
        .number()
        .required(ErrorMessages.REQUIRED)
        .positive()
        .integer('Should be integer')
        .typeError('Must be a number'),
    description: yup.string(),
    clientName: yup.string(),
    clientPhone: yup.string(),
    agencyIds: yup.array().required(ErrorMessages.REQUIRED),
    privateNote: yup.string(),
    clientTypeId: yup.number().required(ErrorMessages.REQUIRED),
});

export default yupResolver(createPostReferralSchema);
