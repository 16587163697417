import { isAnyOf } from './../../../common/utils/isAnyOf';
import { createSlice } from '@reduxjs/toolkit';

import {
    getOpenHouseDetails,
    getOpenHouseDetailsError,
    getOpenHouseDetailsSuccess,
    getMyRequests,
    getMyRequestsError,
    getMyRequestsSuccess,
    getRequest,
    getRequestSuccess,
    getRequestError,
    setIsMyRequestInfoModalOpen,
    acceptRequest,
    acceptRequestSuccess,
    acceptRequestError,
    deleteRequest,
    deleteRequestSuccess,
    deleteRequestError,
    getAgencyRequests,
    getAgencyRequestsSuccess,
    getAgencyRequestsError,
    cancelAcceptedWork,
    cancelAcceptedWorkSuccess,
    cancelAcceptedWorkError,
    editRequest,
    editRequestSuccess,
    editRequestError,
    getApplicantsToRequest,
    getApplicantsToRequestSuccess,
    getApplicantsToRequestError,
    confirmApplicant,
    confirmApplicantSuccess,
    confirmApplicantError,
    getPartnerApplicantsRequest,
    getPartnerApplicantsSuccess,
    getPartnerApplicantsFailed,
    deleteRequestAfterTimer,
    getUserFeedbacks,
    getUserFeedbacksSuccess,
    getUserFeedbacksError,
    getFeedbackUserInfo,
    getFeedbackUserInfoSuccess,
    getFeedbackUserInfoError,
} from './actions';
import {
    MyRequestsType,
    RequestInfoType,
    SentRequestType,
    ApplicantType,
    UserFeedbackType,
    FeedbackUserInfoType,
} from '../types';
import { PayloadError } from '../../../common/types';

type InitialState = {
    myRequests: {
        loading: boolean;
        errors: PayloadError[];
        items: MyRequestsType[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
    openHouse: {
        loading: boolean;
        errors: PayloadError[];
        details: any;
    };
    acceptRequest: {
        loading: boolean;
        errors: PayloadError[];
        success: boolean;
    };
    isMyRequestInfoModalOpen: boolean;
    requestInfo: { loading: boolean; errors: PayloadError[]; value: RequestInfoType };
    deleteRequest: {
        loading: boolean;
        errors: PayloadError[];
    };
    editRequest: {
        loading: boolean;
        errors: PayloadError[];
    };
    agencyRequests: {
        loading: boolean;
        totalCount: number;
        items: Array<SentRequestType | MyRequestsType>;
        loadMoreLoading: boolean;
    };
    cancelAcceptedWork: {
        loading: boolean;
        errors: PayloadError[];
    };
    applicants: {
        loading: boolean;
        errors: PayloadError[];
        items: ApplicantType[];
        partnerApplicants: ApplicantType[];
    };
    confirmApplicant: {
        applicantId: number;
        requestId: number;
        loading: boolean;
    };
    userFeedback: {
        items: UserFeedbackType[];
        loading: boolean;
        errors: PayloadError[];
        totalCount: number;
    };
    feedbackUserInfo: {
        value: FeedbackUserInfoType;
        loading: boolean;
        errors: PayloadError[];
    };
};
const initialState: InitialState = {
    myRequests: {
        loading: false,
        loadMoreLoading: false,
        errors: [],
        items: [],
        totalCount: 0,
    },
    openHouse: {
        loading: false,
        errors: [],
        details: {},
    },
    acceptRequest: {
        loading: false,
        errors: [],
        success: false,
    },
    isMyRequestInfoModalOpen: false,
    requestInfo: {
        loading: false,
        errors: [],
        value: {
            iWillPayAgentForHosting: false,
            fromTime: '',
            currentLocation: '',
            actualDuration: 0,
            addresses: [''],
            amount: 0,
            bonusTypeId: 0,
            isWorkFinished: false,
            ownerId: 0,
            assistantId: 0,
            clientTypeId: 0,
            dateTime: '',
            duration: 0,
            generalNote: '',
            id: 0,
            isAccepted: false,
            isDateFirmOrFlexible: false,
            isShowingScheduled: false,
            privateNote: '',
            propertiesToShow: 0,
            states: [''],
            totalPrice: null,
            typeId: 0,
            wouldLikeToOfferBonus: false,
            expiryDate: '',
            timeSlot: {
                date: '',
                endTime: '',
                id: 0,
                listingId: 0,
                price: 0,
                requestId: 0,
                startTime: '',
                openHouseAudienceId: 0,
                openHouseHostTypeId: 0,
                openHouseTypeId: 0,
                clientTypeId: 0,
            },
            avatar: {
                id: 0,
                itemHash: '',
                itemExtension: '',
            },
            assistantAvatar: {
                id: 0,
                itemHash: '',
                itemExtension: '',
            },
            agencyName: '',
            ownerFullName: '',
            assistantFullName: '',
            createdAt: '',
            referralClient: {
                name: '',
                phone: '',
            },
            requestCategoryId: 0,
            requestTypeName: '',
            ownerEmail: '',
            assistantEmail: '',
            ownerPhoneNumber: '',
            assistantPhoneNumber: '',
            agencies: [],
        },
    },
    deleteRequest: {
        errors: [],
        loading: false,
    },
    editRequest: {
        errors: [],
        loading: false,
    },
    agencyRequests: {
        loading: false,
        totalCount: 0,
        items: [],
        loadMoreLoading: false,
    },
    cancelAcceptedWork: {
        errors: [],
        loading: false,
    },
    applicants: {
        errors: [],
        items: [],
        partnerApplicants: [],
        loading: false,
    },
    confirmApplicant: {
        applicantId: 0,
        loading: false,
        requestId: 0,
    },
    userFeedback: {
        errors: [],
        items: [],
        loading: false,
        totalCount: 0,
    },
    feedbackUserInfo: {
        errors: [],
        loading: false,
        value: {
            avatar: {
                id: 0,
                itemExtension: '',
                itemHash: '',
            },
            dateTime: '',
            fullName: '',
            requestType: '',
        },
    },
};

const requestsSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getMyRequests, (state, action) => {
                if (action.payload.PageNumber === 1) {
                    state.myRequests.loading = true;
                } else {
                    state.myRequests.loadMoreLoading = true;
                }
                state.myRequests.errors = [];
            })
            .addCase(getMyRequestsSuccess, (state, action) => {
                state.myRequests.loading = false;
                state.myRequests.loadMoreLoading = false;
                state.myRequests.items =
                    action.payload.PageNumber === 1
                        ? action.payload.result.value.requests
                        : [...state.myRequests.items, ...action.payload.result.value.requests];
                state.myRequests.totalCount = action.payload.result.value.totalCount;
            })
            .addCase(getMyRequestsError, (state, action) => {
                state.myRequests.loading = false;
                state.myRequests.loadMoreLoading = false;
                state.myRequests.errors = action.payload;
            })

            .addCase(getOpenHouseDetails, state => {
                state.openHouse.loading = true;
                state.openHouse.errors = [];
            })
            .addCase(getOpenHouseDetailsSuccess, (state, action) => {
                state.myRequests.loading = false;
                state.openHouse.details = action.payload;
            })
            .addCase(getOpenHouseDetailsError, (state, action) => {
                state.myRequests.loading = false;
                state.myRequests.errors = action.payload;
            })

            .addCase(getRequest, state => {
                state.requestInfo.loading = true;
                state.requestInfo.errors = [];
            })
            .addCase(getRequestSuccess, (state, action) => {
                state.requestInfo.loading = false;
                state.requestInfo.errors = [];
                state.requestInfo.value = action.payload.value.requestModel;
            })
            .addCase(getRequestError, (state, action) => {
                state.requestInfo.loading = false;
                state.requestInfo.errors = action.payload;
            })

            .addCase(setIsMyRequestInfoModalOpen, (state, action) => {
                state.isMyRequestInfoModalOpen = action.payload;
            })

            .addCase(acceptRequest, state => {
                state.acceptRequest.loading = true;
                state.acceptRequest.errors = [];
            })
            .addCase(acceptRequestSuccess, (state, action) => {
                state.acceptRequest.loading = false;
                state.acceptRequest.errors = [];
                state.acceptRequest.success = true;
            })
            .addCase(acceptRequestError, (state, action) => {
                state.acceptRequest.loading = false;
                state.acceptRequest.errors = action.payload;
                state.acceptRequest.success = false;
            })

            .addCase(deleteRequest, state => {
                state.deleteRequest.errors = [];
                state.deleteRequest.loading = true;
            })
            .addCase(deleteRequestSuccess, (state, action) => {
                state.deleteRequest.loading = false;
                // IDK if this is working
                state.myRequests.items = state.myRequests.items.filter(
                    req => req.id !== action.payload,
                );
                // ! PLEASE ADD HERE WHEN AGENT ACTIVITIES IS READY
                // state.agentActivities = state.agentActivities.filter(
                //     req => req.id !== action.payload,
                // );
            })
            .addCase(deleteRequestError, (state, action) => {
                state.deleteRequest.errors = action.payload;
                state.deleteRequest.loading = false;
            })
            .addCase(getAgencyRequests, (state, action) => {
                if (action.payload.pageNumber === 1) {
                    state.agencyRequests.loading = true;
                } else {
                    state.agencyRequests.loadMoreLoading = true;
                }
            })
            .addCase(getAgencyRequestsSuccess, (state, action) => {
                state.agencyRequests.loading = false;
                state.agencyRequests.loadMoreLoading = false;
                state.agencyRequests.totalCount = action.payload.totalCount;
                state.agencyRequests.items =
                    action.payload.pageNumber === 1
                        ? [
                              ...action.payload.receivedAssistRequests,
                              ...action.payload.assistRequests,
                          ]
                        : [
                              ...state.agencyRequests.items,
                              ...action.payload.receivedAssistRequests,
                              ...action.payload.assistRequests,
                          ];
            })
            .addCase(getAgencyRequestsError, (state, action) => {
                state.agencyRequests.loading = false;
                state.agencyRequests.loadMoreLoading = false;
            })
            .addCase(cancelAcceptedWork, state => {
                state.cancelAcceptedWork.errors = [];
                state.cancelAcceptedWork.loading = true;
            })
            .addCase(cancelAcceptedWorkSuccess, state => {
                state.cancelAcceptedWork.loading = false;
            })
            .addCase(cancelAcceptedWorkError, (state, action) => {
                state.cancelAcceptedWork.errors = action.payload;
                state.cancelAcceptedWork.loading = false;
            })
            .addCase(editRequest, state => {
                state.editRequest.errors = [];
                state.editRequest.loading = true;
            })
            .addCase(editRequestSuccess, state => {
                state.editRequest.loading = false;
            })
            .addCase(editRequestError, (state, action) => {
                state.editRequest.loading = false;
                state.editRequest.errors = action.payload;
            })
            .addCase(getApplicantsToRequestSuccess, (state, action) => {
                state.applicants.loading = false;
                state.applicants.items = action.payload;
            })
            .addCase(getPartnerApplicantsSuccess, (state, action) => {
                state.applicants.loading = false;
                state.applicants.partnerApplicants = action.payload;
            })
            .addCase(confirmApplicant, (state, action) => {
                state.confirmApplicant.applicantId = action.payload.applicantId;
                state.confirmApplicant.requestId = action.payload.requestId;
                state.confirmApplicant.loading = true;
            })
            .addCase(deleteRequestAfterTimer, (state, action) => {
                state.myRequests.items = state.myRequests.items.filter(
                    req => req.id !== action.payload,
                );
            })
            .addCase(getUserFeedbacks, state => {
                state.userFeedback.loading = true;
                state.userFeedback.errors = [];
            })
            .addCase(getUserFeedbacksSuccess, (state, action) => {
                state.userFeedback.loading = false;
                state.userFeedback.items = action.payload.feedback;
                state.userFeedback.totalCount = action.payload.totalCount;
            })
            .addCase(getUserFeedbacksError, (state, action) => {
                state.userFeedback.loading = false;
                state.userFeedback.errors = action.payload;
            })
            .addCase(getFeedbackUserInfo, state => {
                state.feedbackUserInfo.loading = true;
                state.feedbackUserInfo.errors = [];
            })
            .addCase(getFeedbackUserInfoSuccess, (state, action) => {
                state.feedbackUserInfo.loading = false;
                state.feedbackUserInfo.value = action.payload;
            })
            .addCase(getFeedbackUserInfoError, (state, action) => {
                state.feedbackUserInfo.loading = false;
                state.feedbackUserInfo.errors = action.payload;
            })
            .addCase(confirmApplicantSuccess, (state, action) => {
                state.confirmApplicant.applicantId = 0;
                state.confirmApplicant.requestId = 0;
                state.confirmApplicant.loading = false;
                state.applicants.items = state.applicants.items.map(elem => {
                    if (elem.id === action.payload) {
                        return { ...elem, isConfirmed: true };
                    } else {
                        return elem;
                    }
                });
                state.isMyRequestInfoModalOpen = false;
            })
            .addMatcher(isAnyOf(getPartnerApplicantsRequest, getApplicantsToRequest), state => {
                state.applicants.errors = [];
                state.applicants.loading = true;
            })
            .addMatcher(
                isAnyOf(getPartnerApplicantsFailed, getApplicantsToRequestError),
                (state, action) => {
                    state.applicants.loading = false;
                    state.applicants.errors = action.payload;
                },
            )
            .addMatcher(isAnyOf(confirmApplicantError), state => {
                state.confirmApplicant.applicantId = 0;
                state.confirmApplicant.requestId = 0;
                state.confirmApplicant.loading = false;
            });
    },
});

export default requestsSlice.reducer;
