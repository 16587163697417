import React, { useEffect } from 'react';
import { Flex, Text, Avatar, Box, SimpleGrid } from '@chakra-ui/react';
import { LogoutIcon } from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAvatarRequest } from '../../../store/actions/user.actions';
import { RootStore } from '../../../store';
import { ToggleUserProfileModalPayload, UserAvatarPayload } from '../../../common/types';
import { BackButton } from '../../../common/components';
import { fonts, getAvatarPicture } from '../../../common/utils';
import { logout } from '../../../store/actions/auth/auth.actions';
import { SidebarDropdown } from '../../../common/components/blocks/SidebarDropdown/SidebarDropdown';
import { SetCurrentAgencyPayloadType } from '../../Sidebar/types';
import { setCurrentAgencyRequest } from '../../Sidebar/store/actions';
import { SettingsItem } from '../components/SettingsItem';
import { Link } from 'react-router-dom';
import { toggleUserProfileModal } from '../../../store/actions/profile.actions';

export const Content: React.FC = () => {
    const dispatch = useDispatch();

    const userAvatar = useSelector((state: RootStore) => state.user.userPhoto);
    const user = useSelector((state: RootStore) => state.user.user);
    const agencies = useSelector((state: RootStore) => state.user.user.agencies);
    const token = useSelector((state: RootStore) => state.auth.user.token.accessToken);
    const currentAgency = useSelector((state: RootStore) => state.user.user.currentAgency);

    const onSetCurrentAgency = (payload: SetCurrentAgencyPayloadType) =>
        dispatch(setCurrentAgencyRequest(payload));

    const getUserPhoto = (payload: UserAvatarPayload) => {
        dispatch(getUserAvatarRequest(payload));
    };

    const onToggleUserProfileModal = (payload: ToggleUserProfileModalPayload) =>
        dispatch(toggleUserProfileModal(payload));

    const onLogout = () => dispatch(logout());

    // useEffect(() => {
    //     dispatch(getUserInfoRequest());
    // }, []);

    useEffect(() => {
        if (user.id !== 0) {
            getUserPhoto(user.id);
        }
    }, [user]);

    return (
        <Flex direction="column" px="50px" mb="30px">
            <Flex justify="space-between" align="center">
                <Flex align="center">
                    <Box>
                        <BackButton text="" />
                    </Box>
                    <Text
                        fontSize="24px"
                        lineHeight="25px"
                        color="brand"
                        fontWeight="bold"
                        mb="15px"
                        ml="20px"
                    >
                        Settings
                    </Text>
                </Flex>
                <Flex mb="15px">
                    <Flex
                        justify="space-between"
                        align="center"
                        borderBottom="1px solid #E1E2E8"
                        px="16px"
                        width="250px"
                        height="70px"
                        border="1px solid #DADADA"
                        borderRadius={'10px'}
                        mr="20px"
                    >
                        <Flex>
                            <Avatar
                                h="40px"
                                w="40px"
                                src={getAvatarPicture(userAvatar?.avatar)}
                            ></Avatar>
                            <Flex direction="column" justify="space-around" ml="15px">
                                <Text fontWeight={600} fontFamily={fonts.poppins} fontSize={'16px'}>
                                    {user.firstName} {user.lastName}
                                </Text>
                                <Text
                                    as="button"
                                    fontWeight={600}
                                    fontFamily={fonts.poppins}
                                    fontSize={'14px'}
                                    textDecoration="underline"
                                    onClick={() =>
                                        onToggleUserProfileModal({
                                            value: true,
                                            userId: user.id,
                                        })
                                    }
                                >
                                    View Profile
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex mr="20px">
                        <SidebarDropdown
                            maxW={'100%'}
                            width="250px"
                            height="70px"
                            options={agencies}
                            token={token}
                            onSetCurrentAgency={onSetCurrentAgency}
                            currentAgency={currentAgency}
                            border="1px solid #DADADA"
                            borderRadius={'10px'}
                        />
                    </Flex>

                    <Flex
                        align="center"
                        as="button"
                        onClick={onLogout}
                        height="70px"
                        border="1px solid #EB5757"
                        borderRadius={'10px'}
                        px="16px"
                    >
                        <Text color="#EB5757" fontSize="16px" mr="15px">
                            Log Out
                        </Text>
                        <LogoutIcon />
                    </Flex>
                </Flex>
            </Flex>
            <SimpleGrid columns={2} columnGap="15px" rowGap="50px" mt="40px">
                <SettingsItem destination="/settings/edit-profile" text="Account" />
                <SettingsItem destination="/settings/location" text="Location" />
                <SettingsItem destination="/settings/licenses" text="Licenses" />
                <SettingsItem destination="/settings/task-availability" text="Task Availability" />
                <SettingsItem destination="/settings/rates" text="Rates" />
                <SettingsItem destination="/settings/subscription" text="Subscription" />
                <SettingsItem destination="/settings/payments" text="Payments" />
                <SettingsItem destination="/settings/notifications" text="Notifications" />
                <SettingsItem
                    destination="/settings/calendar-and-availability"
                    text="Calendar Preferences"
                />
                <SettingsItem destination="/settings/support" text="Support" />
                <SettingsItem destination="/settings/history" text="History" />
            </SimpleGrid>
        </Flex>
    );
};
