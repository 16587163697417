import React from 'react';
import {Box, Button, VStack} from '@chakra-ui/react';
import {  CustomDropdown, CustomInput } from '../../../../../common/components';
import { bankAccountTypes } from '../consts';
import { Resolver, useForm } from 'react-hook-form';
import addPaymentMethodResolver, {
    AddPaymentMethodFormValues,
} from './validation/addPaymentMethod.schema';
import { useDispatch } from 'react-redux';
import { addNewBankAccountRequest } from '../store/actions';
import {Typography} from "../../../../../common/components/blocks/Typography/Typography";

interface IAddPaymentMethodFormProps {
    addNewBankAccountLoading: boolean;
}

export const AddPaymentMethodForm: React.FC<IAddPaymentMethodFormProps> = ({
    addNewBankAccountLoading,
}) => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm<AddPaymentMethodFormValues>({
        resolver: addPaymentMethodResolver as Resolver<AddPaymentMethodFormValues>,
    });
    const onSubmit = (data: AddPaymentMethodFormValues) => {
        dispatch(addNewBankAccountRequest(data));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack mx={'auto'} align={'stretch'} maxW={'342px'} spacing={'16px'} h={'70vh'}>
                <CustomInput
                    label={'Nickname'}
                    register={{ ...register('name') }}
                    placeholder={'Enter'}
                    errors={errors.name}
                />
                <CustomInput
                    label={'Routing number'}
                    register={{ ...register('routingNumber') }}
                    placeholder={'Enter'}
                    errors={errors.routingNumber}
                />
                <CustomInput
                    label={'Account number'}
                    register={{ ...register('accountNumber') }}
                    placeholder={'Enter'}
                    errors={errors.accountNumber}
                />
                <CustomDropdown
                    options={bankAccountTypes}
                    label={'Account Type'}
                    register={{ ...register('bankAccountType') }}
                    errors={errors.bankAccountType}
                />
                <Typography size={14}>
                    We will send 2 microdeposits to your account. Look out for it in the next few days. Once received, please return to complete setting up your payment account.
                </Typography>
                <Box>
                    <Button
                        isFullWidth
                        variant={'primary'}
                        type="submit"
                        isLoading={addNewBankAccountLoading}
                    >
                        Add
                    </Button>

                </Box>

            </VStack>
        </form>
    );
};
