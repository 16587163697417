import React from 'react';
import { Box, Text, Center, Image, HStack, Flex } from '@chakra-ui/react';
import { fonts } from '../../../../common/utils';
import { points } from './AvailableOptionsOverview';
import { ListCheckmark } from '../../../../assets/icons';
import SubscriptionPentagon from '../../../../assets/img/subscribe_pentagon.png';
import { SubscriptionHistoryType } from './types';
import { Loader } from '../../../../common/components';
import { HistoryItem } from './components';
import dayjs from 'dayjs';

interface ISubscriptionStatusProps {
    loading: boolean;
    type: string;
    items: SubscriptionHistoryType[];
    subscribedTo: string;
}

export const SubscriptionStatus: React.FC<ISubscriptionStatusProps> = ({
    items,
    loading,
    type,
    subscribedTo,
}) => {
    return loading ? (
        <Loader centerHeight="500px" />
    ) : (
        <Box>
            <Box
                bg="#4FD1C5"
                borderRadius="26px"
                p="30px"
                position="relative"
                overflow="hidden"
                mb="30px"
            >
                <Flex align="center" justify="space-between">
                    <Text
                        fontSize="17px"
                        lineHeight="23px"
                        fontWeight={500}
                        color="white"
                        fontFamily={fonts.avenirRoman}
                    >
                        Your Subscription
                    </Text>
                    <Text
                        fontSize="17px"
                        lineHeight="23px"
                        fontWeight={500}
                        color="white"
                        fontFamily={fonts.avenirRoman}
                    >
                        End {dayjs.utc(subscribedTo).format('MM/DD/YYYY')}
                    </Text>
                </Flex>
                <HStack align="baseline" my="18px">
                    <Text
                        fontSize="36px"
                        color="white"
                        fontWeight={500}
                        fontFamily={fonts.avenirBlack}
                        lineHeight="46px"
                    >
                        ${type === 'Monthly' ? '7.99' : '59.99'}
                    </Text>
                    <Text
                        fontSize="17px"
                        color="white"
                        lineHeight="23px"
                        fontFamily={fonts.avenirRoman}
                    >
                        {type === 'Monthly' ? '/monthly' : '/yearly'}
                    </Text>
                </HStack>
                {points.map(elem => (
                    <HStack key={elem} mb="10px">
                        <Center w="20px" h="20px" borderRadius="10px" bg="rgba(255,255,255, .1)">
                            <ListCheckmark />
                        </Center>
                        <Text
                            color="white"
                            fontSize="15px"
                            lineHeight="20px"
                            fontWeight={500}
                            fontFamily={fonts.avenirRoman}
                        >
                            {elem}
                        </Text>
                    </HStack>
                ))}
                <Image src={SubscriptionPentagon} position="absolute" top="0" left="200px" />
            </Box>
            <Box borderBottom="1px solid #D1D2DB" pb="20px">
                <Text color="brand" fontSize="18px" fontWeight={600} lineHeight="27px">
                    Last Payments
                </Text>
            </Box>
            {items.map(elem => (
                <HistoryItem
                    key={elem.transactionId}
                    fromDateTime={elem.fromDateTime}
                    type={elem.subscriptionTypeId.name}
                />
            ))}
            {/* <HistoryItem />
            <HistoryItem /> */}
        </Box>
    );
};
