import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const editHireAssistanceSchema = yup.object().shape({
    clientTypeId: yup.string().required(ErrorMessages.REQUIRED),
    isShowingScheduled: yup.string().required(ErrorMessages.REQUIRED),
    privateNotes: yup.string().required(ErrorMessages.REQUIRED),
});

export const editHireAssistanceResolver = yupResolver(editHireAssistanceSchema);
