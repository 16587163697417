import React, { useEffect } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import {
    CustomButton,
    CustomDropdown,
    CustomInput,
    DatePicker,
} from '../../../../../common/components';
import { VStack, Text } from '@chakra-ui/react';
import createDwollaAccountResolver, {
    CreateDwollaAccountFormValues,
} from './validation/createDwollaAccount.schema';
import { useDispatch, useSelector } from 'react-redux';
import { getStates } from '../../../../../store/actions/directories.actions';
import { RootStore } from '../../../../../store';
import { CreateDwollaAccountPayload } from '../types';
import { StateDirectoryItem } from '../../../../../common/types';
import dayjs from 'dayjs';

interface ICreateDwollaAccountForm {
    isRetry: boolean;
    onCreateDwollaAccount: (payload: CreateDwollaAccountPayload) => void;
    loading: boolean;
}

const customYearList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(100), (val, index) => index + 1 + (year - 100)).map((year, i) => ({
        value: year.toString(),
        label: year.toString(),
        id: i,
    }));
};

export const CreateDwollaAccountForm: React.FC<ICreateDwollaAccountForm> = ({
    isRetry,
    onCreateDwollaAccount,
    loading,
}) => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        reset,
        formState: { errors },
        register,
        setValue,
        clearErrors,
        watch,
    } = useForm<CreateDwollaAccountFormValues>({
        resolver: createDwollaAccountResolver as Resolver<CreateDwollaAccountFormValues>,
    });
    const states: StateDirectoryItem[] = useSelector(
        (state: RootStore) => state.directories.states,
    );

    const onSubmit = (data: CreateDwollaAccountFormValues) => {
        onCreateDwollaAccount({
            ...data,
            ssn: +data.ssn,
            dateOfBirth: dayjs(+data.dateOfBirth).toISOString(),
        });
    };

    useEffect(() => {
        dispatch(getStates());
    }, []);

    const [dateOfBirth] = watch(['dateOfBirth']);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack mx={'auto'} align={'stretch'} maxW={'342px'} spacing={'16px'} mt={'16px'}>
                <CustomInput
                    labelProps={{ mb: '7px' }}
                    label={'Address 1 *'}
                    register={{ ...register('address1') }}
                    errors={errors.address1}
                    placeholder={'Enter'}
                />
                <CustomInput
                    labelProps={{ mb: '7px' }}
                    label={'Address 2'}
                    register={{ ...register('address2') }}
                    errors={errors.address2}
                    placeholder={'Enter'}
                />
                <CustomInput
                    labelProps={{ mb: '7px' }}
                    label={'City *'}
                    register={{ ...register('city') }}
                    errors={errors.city}
                    placeholder={'Enter'}
                />
                <CustomDropdown
                    options={states.map(state => ({
                        ...state,
                        value: state.code,
                        label: state.name,
                    }))}
                    label={'State *'}
                    register={{ ...register('state') }}
                    errors={errors.state}
                />
                <CustomInput
                    labelProps={{ mb: '7px' }}
                    label={'Postal Code *'}
                    register={{ ...register('postalCode') }}
                    errors={errors.postalCode}
                    placeholder={'Enter'}
                />
                <DatePicker
                    label="Date of Birth *"
                    displayValue={dateOfBirth ? dayjs.utc(+dateOfBirth).format('MM/DD/YY') : ''}
                    value={Number(dateOfBirth) || dayjs.utc().valueOf()}
                    setValue={value => {
                        setValue('dateOfBirth', value.toString());
                        clearErrors('dateOfBirth');
                    }}
                    removeBottomMargin
                    placeholder="MM/DD/YY"
                    errMessage={errors.dateOfBirth?.message}
                    borderRadius="50px"
                    margin={errors.dateOfBirth ? '0 10px 0 0' : '0 10px 26px 0'}
                    customYearList={customYearList}
                />
                <CustomInput
                    labelProps={{ mb: '7px' }}
                    register={{ ...register('ssn') }}
                    errors={errors.ssn}
                    label={'Last 4 Digits of #SSN *'}
                    placeholder={'Enter'}
                />
                {!isRetry && (
                    <Text
                        textTransform={'capitalize'}
                        fontWeight={300}
                        fontSize={'13px'}
                        color={'#F2994A'}
                    >
                        * We will not show this number to anyone, we will just send it to payment
                        processor For checking
                    </Text>
                )}
                <CustomButton
                    isLoading={loading}
                    text="Submit"
                    type="submit"
                    height="60px"
                    bgColor="brand"
                />
            </VStack>
        </form>
    );
};
