import { put, call, takeEvery } from 'redux-saga/effects';
import { getUserFeedbacks, getUserFeedbacksSuccess, getUserFeedbacksError } from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithCustomValue } from './../../../../common/types';
import { UserFeedbackType } from '../../types';

function* workerGetUserFeedbacks(action: ReturnType<typeof getUserFeedbacks>) {
    try {
        const result: IResponseWithCustomValue<{
            totalCount: number;
            feedback: UserFeedbackType[];
        }> = yield call(
            API.get,
            `/api/request/get-feedback-on-user?UserId=${action.payload.UserId}&IsShowAll${action.payload.IsShowAll}`,
            {},
        );
        console.log('getUserFeedbacks action', result, action);
        if (result.success) {
            yield put(getUserFeedbacksSuccess(result.value));
            console.log('getUserFeedbacksSuccess', result);
        } else {
            yield put(getUserFeedbacksError(result.errors));
            console.log('getUserFeedbacksError', result);
        }
    } catch (error) {
        console.log('getUserFeedbacksError', error);
    }
}
export default function* watchGetUserFeedbacksSaga() {
    yield takeEvery(getUserFeedbacks.type, workerGetUserFeedbacks);
}
