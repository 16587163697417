import React, { useEffect } from 'react';
import { AgencyRelationship } from './AgencyRelationship';
import { getStates } from '../../store/actions/directories.actions';
import { sendCooperationDetailRequest } from '../../store/actions/landing.actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';

export const AgencyRelationshipContainer: React.FC = () => {
    const dispatch = useDispatch();

    const { states } = useSelector((state: RootStore) => state.directories);
    const sendingDetailsLoading = useSelector(
        (state: RootStore) => state.landing.sendingDetailsLoading,
    );

    const onSendCooperationDetail = (data: FormData) =>
        dispatch(sendCooperationDetailRequest(data));

    useEffect(() => {
        dispatch(getStates());
    }, []);

    return (
        <AgencyRelationship
            states={states}
            onSendCooperationDetail={onSendCooperationDetail}
            sendingDetailsLoading={sendingDetailsLoading}
        />
    );
};
