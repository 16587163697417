import React, { useEffect, useState } from 'react';
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import {
    CustomInput,
    CustomDropdown,
    CustomButton,
    CustomTextarea,
    MultiSelect,
} from '../../../../../common/components';
import { Controller, Resolver, useForm } from 'react-hook-form';
import referralPostSchema, { ReferralFormValues } from '../validation/createPostReferral.schema';
import { CreateJobPostPayload } from '../../../types';
import { IdNameTypes, StateDirectoryItem } from '../../../../../common/types';

interface IReferralFormProps {
    agenciesTypes: {
        id: number;
        name: string;
    }[];
    statesType: StateDirectoryItem[];
    requestValue: string;
    onCreatePost: (value: CreateJobPostPayload) => void;
    createPostLoading: boolean;
    setRequestValue: (value: string) => void;
    onGetReferralOpportunityAgenciesRequest: (id: number) => void;
    agenciesForState: IdNameTypes[];
}

export const ReferralForm: React.FC<IReferralFormProps> = ({
    agenciesTypes,
    statesType,
    requestValue,
    onCreatePost,
    createPostLoading,
    setRequestValue,
    onGetReferralOpportunityAgenciesRequest,
    agenciesForState,
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        control,
        watch,
        formState: { errors },
    } = useForm<ReferralFormValues>({
        resolver: referralPostSchema as Resolver<ReferralFormValues>,
    });

    const [stateValue] = watch(['states']);

    const [agenciesValue, setAgenciesValue] = useState<string[]>([]);

    useEffect(() => {
        if (stateValue && stateValue[0]) {
            const stateId = statesType.find(elem => elem.name === stateValue[0])?.id;
            if (stateId) {
                onGetReferralOpportunityAgenciesRequest(stateId);
            }
        }
    }, [stateValue]);

    const onSubmit = (data: ReferralFormValues) => {
        const payload = {
            typeId: +requestValue,
            states: data.states,
            referralFee: Number(data.referralFee),
            agencies: data.agencyIds,
            clientName: data.clientName,
            clientPhone: data.clientPhone,
            description: data.description,
            privateNote: data.privateNote,
            clientTypeId: data.clientTypeId,
        };
        onCreatePost(payload);
    };

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid columns={2} gap="50px" rowGap="0px">
                    <CustomDropdown
                        options={[
                            { label: 'Showing', value: '1', id: 1 },
                            { label: 'Home Inspection', value: '2', id: 2 },
                            { label: 'Final Walk-Through', value: '3', id: 3 },
                            { label: 'Other', value: '4', id: 4 },
                            { label: 'Appraisal', value: '5', id: 5 },
                            { label: 'Referral', value: '7', id: 7 },
                        ]}
                        label="Request type:*"
                        register={{
                            value: requestValue,
                            onChange: (ev: any) => {
                                setRequestValue(ev.target.value);
                            },
                        }}
                        errors={''}
                        width="350px"
                    />
                    <Controller
                        render={({ field }) => (
                            <MultiSelect
                                label="State:*"
                                options={statesType.map((state, i) => ({
                                    value: state.name,
                                    label: state.name,
                                }))}
                                value={field.value}
                                onChange={field.onChange}
                                errors={errors.states && (errors.states as any).message}
                                width="350px"
                            />
                        )}
                        control={control}
                        name="states"
                    />
                    <Controller
                        render={({ field }) => (
                            <MultiSelect
                                label="Agency (Max. Of 5):*"
                                options={agenciesForState.map((state, i) => ({
                                    value: state.id.toString(),
                                    label: state.name,
                                }))}
                                onChange={(value: string[]) => {
                                    clearErrors();
                                    setAgenciesValue(value);
                                    setValue('agencyIds', value);
                                }}
                                value={agenciesValue}
                                errors={errors.agencyIds && (errors.agencyIds as any).message}
                                width="350px"
                                maxLength={5}
                            />
                        )}
                        control={control}
                        name="agencyIds"
                    />
                    <CustomInput
                        register={{ ...register('referralFee') }}
                        type="number"
                        label="Refferal Fee:*"
                        errors={errors.referralFee}
                        rightIcon={<Text color="secondaryFontDarker">$</Text>}
                        placeholder="100"
                        width="350px"
                        margin={errors.referralFee ? '0' : '0 0 26px 0 !important'}
                    />
                </SimpleGrid>
                <Flex flexDirection="column">
                    <CustomTextarea
                        register={{ ...register('description') }}
                        label="Description"
                        placeholder="Enter"
                        errors={errors.description}
                        height="75px"
                    />
                    <SimpleGrid columns={2} gap="50px" rowGap="0px">
                        <Box pt="20px">
                            <CustomDropdown
                                label="The client is:*"
                                options={[
                                    { id: 1, label: 'A Couple', value: '1' },
                                    { id: 2, label: 'A Family', value: '2' },
                                    { id: 3, label: 'An Individual', value: '3' },
                                ]}
                                placeholder="Select"
                                register={{
                                    onChange: (ev: any) => {
                                        setValue('clientTypeId', ev.target.value);
                                    },
                                }}
                                errors={errors.clientTypeId}
                                width="350px"
                                margin={errors.clientTypeId ? '0' : '0 0 26px 0'}
                            />
                        </Box>
                        <Box pt="20px">
                            <CustomInput
                                register={{ ...register('clientName') }}
                                type="text"
                                label="Client name:"
                                errors={errors.clientName}
                                placeholder="Enter"
                                width="350px"
                                margin={errors.clientName ? '0' : '0 0 26px 0 !important'}
                            />
                        </Box>
                    </SimpleGrid>
                    <CustomInput
                        register={{ ...register('clientPhone') }}
                        type="tel"
                        mask="999-999-9999"
                        label="Client phone number:"
                        errors={errors.clientPhone}
                        placeholder="Enter"
                        margin={errors.clientPhone ? '0' : '0 0 26px 0 !important'}
                    />
                    <CustomTextarea
                        register={{ ...register('privateNote') }}
                        label="Private Notes To The Chosen Agent:"
                        placeholder="Enter"
                        errors={errors.privateNote}
                        height="50px"
                    />
                </Flex>
                <Flex pt="20px" flexDir="column" alignItems="flex-end">
                    {/*<Flex justifyContent="space-between" width="350px">*/}
                    {/*    <Text fontSize="13px" color="secondaryFontDarker">*/}
                    {/*        Fee Price*/}
                    {/*    </Text>*/}
                    {/*    <Text fontSize="16px" fontWeight={500} color="secondaryFontDarker">*/}
                    {/*        ${feePrice}*/}
                    {/*    </Text>*/}
                    {/*</Flex>*/}
                    {/*<Flex justifyContent="space-between" width="350px" mt="10px">*/}
                    {/*    <Text fontSize="13px" color="secondaryFontDarker">*/}
                    {/*        Offer Price*/}
                    {/*    </Text>*/}
                    {/*    <Text fontSize="16px" fontWeight={500} color="secondaryFontDarker">*/}
                    {/*        ${price ? price : 0}*/}
                    {/*    </Text>*/}
                    {/*</Flex>*/}
                    {/*<Flex justifyContent="space-between" width="350px" mt="10px" mb="40px">*/}
                    {/*    <Text fontSize="13px" color="brand" fontWeight={600}>*/}
                    {/*        Eastimated Cost*/}
                    {/*    </Text>*/}
                    {/*    <Text fontSize="19px" fontWeight={500} color="brand">*/}
                    {/*        ${finalPrice}*/}
                    {/*    </Text>*/}
                    {/*</Flex>*/}
                    <CustomButton
                        width="350px"
                        height="60px"
                        type="submit"
                        isLoading={createPostLoading}
                        loadingText="Sending..."
                        text="Post"
                        bgColor="brand"
                        mb="50px"
                    />
                </Flex>
            </form>
        </Box>
    );
};
