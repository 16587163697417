import React from 'react';
import {
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Box,
    Text,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomButton } from '../../../../../common/components';
import { EmailIcon, BackArrow } from '../../../../../assets/icons';
import { ChangePassword } from '../../types';
import { theme } from '../../../../../theme';
import { emailResolver } from '../../../../../common/schemes';
import { Link } from 'react-router-dom';

interface ISignInForgotPassword {
    loading?: boolean;
    getChangePassword: SubmitHandler<ChangePassword>;
}

export const SignInEnterEmail: React.FC<ISignInForgotPassword> = ({
    loading,
    getChangePassword,
}) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<ChangePassword>({ resolver: emailResolver });

    return (
        <form onSubmit={handleSubmit(getChangePassword)}>
            <Box
                borderRadius={20}
                backgroundColor="#FFFFFF"
                display="flex"
                alignItems="center"
                flexDirection="column"
                p={{ base: '30px', lg: '50px' }}
            >
                <Box alignSelf={'start'} mb="26px">
                    <Link to="login">
                        <BackArrow />
                    </Link>
                </Box>
                <Stack>
                    <Heading
                        color="brand"
                        fontFamily={theme.fonts.body}
                        fontWeight="700"
                        fontSize={'22px'}
                        alignSelf={'start'}
                    >
                        Change password
                    </Heading>
                    <FormControl isInvalid={!!errors.email}>
                        <Text mt="30px" color="brand" fontWeight="300" fontSize="12px">
                            Email
                        </Text>
                        <InputGroup>
                            <InputLeftElement h="50px" w="50px" children={<EmailIcon />} />
                            <Input
                                type="text"
                                {...register('email', { required: true })}
                                label="Email"
                                width="100%"
                                placeholder="Enter Email"
                                h="50px"
                                bg="#F6F7FB"
                                borderRadius="80px"
                                color="brand"
                                fontSize="13px"
                            />
                        </InputGroup>
                        {errors.email && (
                            <FormErrorMessage fontSize="12px">Email is required</FormErrorMessage>
                        )}
                    </FormControl>
                    <Box mt="24px !important">
                        <CustomButton
                            type="submit"
                            isLoading={loading}
                            loadingText="Sending..."
                            text="Send Code"
                            height="50px"
                            bgColor="brand"
                            boxShadow="0px 3px 3px grey"
                        />
                    </Box>
                </Stack>
            </Box>
        </form>
    );
};
