import React from 'react';
import { colors } from '../../common/constants';

export const Location: React.FC = () => (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.85482 4.68779C6.85482 3.9396 6.24856 3.33334 5.50093 3.33334C4.75274 3.33334 4.14648 3.9396 4.14648 4.68779C4.14648 5.43542 4.75274 6.04168 5.50093 6.04168C6.24856 6.04168 6.85482 5.43542 6.85482 4.68779Z"
            stroke={colors.brand}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.49974 10.375C4.85056 10.375 1.4375 7.61162 1.4375 4.72178C1.4375 2.45943 3.25593 0.625 5.49974 0.625C7.74354 0.625 9.5625 2.45943 9.5625 4.72178C9.5625 7.61162 6.14891 10.375 5.49974 10.375Z"
            stroke={colors.brand}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
