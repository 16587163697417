import React from 'react';

export const LogoIcon: React.FC<{ fill?: string }> = ({ fill = 'white' }) => (
    <svg
        width="163px"
        height="28px"
        viewBox="0 0 163 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M46.8882 24.8877L49.4045 20.2624C49.6973 20.4462 49.9757 20.652 50.2373 20.8779C52.6461 23.2708 57.2465 23.6386 60.2188 22.0882C62.7401 20.7738 63.933 18.535 64.3702 15.8799C64.6634 14.1388 64.5349 12.3527 63.9955 10.6715C63.5552 9.07478 62.6131 7.66198 61.3081 6.64186C60.0032 5.62174 58.4048 5.0484 56.7489 5.00657C55.127 4.86022 53.4965 5.17284 52.0438 5.90875C49.0964 7.48339 47.8577 10.1275 47.7175 13.3059C47.5988 16.0187 47.2532 18.6495 45.9735 21.0785C45.064 22.9085 43.7099 24.4811 42.0351 25.6522C40.3604 26.8233 38.4186 27.5555 36.3875 27.7816C34.1918 28.1018 31.955 27.997 29.7989 27.4728C28.0444 27.1192 26.3973 26.3591 24.9898 25.2535C23.5823 24.1479 22.4537 22.7277 21.6946 21.1069C19.122 15.6814 19.3899 10.3329 22.8306 5.34177C25.1902 1.92044 28.6601 0.326373 32.7497 0.0675183C35.9073 -0.245933 39.0765 0.538338 41.7236 2.28825C42.1684 2.59361 42.5702 2.96349 43.0109 3.31812L40.532 7.88521C40.0962 7.51393 39.7207 7.19887 39.3474 6.87894C34.7976 2.97391 27.5046 5.08708 25.8911 10.8423C24.9785 14.0984 25.2464 17.2671 27.3297 20.0847C27.9073 20.8369 28.6276 21.4679 29.4492 21.9416C30.2708 22.4153 31.1778 22.7224 32.1182 22.8453C33.5751 23.1207 35.0759 23.0568 36.5041 22.6587C37.8835 22.3084 39.1306 21.5629 40.0922 20.5137C41.0538 19.4646 41.6881 18.1575 41.9172 16.7529C42.269 15.0929 42.2593 13.3586 42.5008 11.6716C43.4072 5.32511 48.2047 0.1841 55.5477 0.0425275C58.0502 -0.0810112 60.5425 0.440156 62.7859 1.5561C66.5209 3.51382 68.6514 6.70197 69.5404 10.7326C70.3582 14.2861 69.8526 18.0159 68.1184 21.2235C67.216 23.0061 65.885 24.5365 64.2449 25.6776C62.6048 26.8186 60.707 27.5344 58.7219 27.7608C55.3307 28.3647 51.8349 27.8175 48.7904 26.2063C48.1321 25.8028 47.497 25.3626 46.8882 24.8877Z"
            fill={fill}
        />
        <path
            d="M7.58447 22.2716C11.1814 22.2716 14.5076 22.3056 17.8325 22.2507C18.2615 22.2027 18.6949 22.2881 19.0735 22.4956C19.452 22.703 19.7574 23.0222 19.9477 23.4097C20.5885 24.3658 21.2958 25.2755 22.0644 26.1322C22.3135 26.4424 22.5738 26.7415 22.7875 26.9969C22.7555 27.0831 22.7162 27.1664 22.6702 27.246C22.6426 27.2825 22.6035 27.3087 22.5592 27.3203C22.3363 27.3718 22.1095 27.4046 21.8812 27.4181C14.81 27.4149 7.7383 27.4068 0.666171 27.3938C0.495267 27.3606 0.32909 27.3066 0.171364 27.2328C-0.0437698 25.8123 -0.229063 24.443 0.750144 23.0884C4.48515 17.9252 8.157 12.7161 11.8469 7.521C12.239 6.96998 12.6019 6.39605 13.1391 5.59242H0.400379V0.83171C1.5399 0.539545 19.3461 0.48473 20.8666 0.765792C21.0595 1.32113 21.1161 1.91472 21.0315 2.49651C20.947 3.0783 20.7238 3.63123 20.3808 4.10869C16.3488 9.73826 12.3618 15.4032 8.36312 21.0571C8.13202 21.3874 7.92799 21.7344 7.58447 22.2716Z"
            fill={fill}
        />
        <path
            d="M77.2845 5.51763C75.9202 5.45251 74.5533 5.46155 73.19 5.5447C71.3357 5.75983 69.9727 5.28724 69.1531 3.50232C68.8432 2.98682 68.4598 2.51925 68.015 2.11436C67.6534 1.71254 67.2613 1.33848 66.9268 0.997736C66.9484 0.90921 66.9791 0.823163 67.0184 0.740967C67.0455 0.704134 67.0858 0.679247 67.1309 0.671564C74.4334 0.642417 81.7359 0.615585 89.0384 0.591064C89.6332 0.591064 90.2279 0.591064 90.9184 0.591064C91.2751 2.19361 91.3091 3.85116 91.0184 5.46697H82.8592C82.8079 6.03604 82.7385 6.43299 82.7406 6.82787C82.7662 13.1584 82.81 19.4882 82.8273 25.8187C82.8273 27.2892 82.6885 27.3954 81.2443 27.4148C80.3186 27.4294 79.3928 27.4391 78.4684 27.4148C77.5045 27.3905 77.3692 27.2524 77.2984 26.2815C77.2692 25.8651 77.2852 25.4488 77.2852 25.0324C77.2852 19.2085 77.2852 13.3851 77.2852 7.5621L77.2845 5.51763Z"
            fill={fill}
        />
        <path
            d="M148.638 27.7496C143.572 27.7753 139.501 25.8502 137.067 21.3116C135.741 18.9898 135.063 16.3549 135.103 13.6817C135.142 11.0085 135.898 8.39489 137.292 6.11337C139.048 3.01822 141.864 1.2555 145.325 0.492125C148.908 -0.383226 152.689 0.133667 155.904 1.93838C159.469 3.93149 161.274 7.16753 162.031 11.0524C162.535 13.7431 162.324 16.5191 161.419 19.1026C160.608 21.6285 159.011 23.8294 156.862 25.3849C154.713 26.9403 152.123 27.769 149.47 27.7503C149.192 27.751 148.916 27.7496 148.638 27.7496ZM137.612 13.8436C137.6 16.3432 138.307 18.7934 139.649 20.9021C142.592 25.3505 148.307 26.5379 152.953 24.7974C155.11 24.0345 156.924 22.5274 158.07 20.5468C159.07 18.8762 159.679 17.001 159.852 15.0615C160.025 13.1221 159.758 11.1686 159.069 9.34733C158.536 7.76028 157.604 6.33691 156.362 5.21397C153.569 2.7087 150.249 2.12646 146.662 2.6171C145.068 2.79837 143.543 3.36714 142.22 4.27371C140.897 5.18029 139.816 6.39709 139.072 7.81779C138.093 9.67439 137.592 11.7451 137.612 13.8436Z"
            fill={fill}
        />
        <path
            d="M93.953 2.75668V0.683761C94.4096 0.653226 94.8128 0.603252 95.216 0.601864C98.7755 0.595618 102.333 0.579656 105.894 0.601864C109.96 0.626153 112.997 2.85037 114.114 6.7443C115.444 11.394 113.294 16.34 108.749 17.7494C107.69 18.069 106.593 18.2441 105.488 18.2699C102.948 18.3456 100.404 18.2894 97.8622 18.3018C96.5436 18.3081 96.4478 18.3969 96.4353 19.7259C96.4145 21.8502 96.4485 23.9765 96.4083 26.1008C96.3875 27.2528 96.1737 27.4194 95.1244 27.4194C94.0425 27.4194 93.8662 27.2702 93.86 26.1175C93.8438 23.1606 93.8438 20.2036 93.86 17.2463C93.8662 16.052 93.9835 15.9368 95.2611 15.9277C98.731 15.9055 102.195 15.9548 105.66 15.884C106.671 15.8932 107.673 15.6975 108.606 15.3087C109.558 14.871 110.37 14.1796 110.955 13.3104C111.539 12.4412 111.873 11.4278 111.919 10.3814C112.113 8.39181 111.838 6.5118 110.447 4.93161C109.994 4.37495 109.433 3.91693 108.796 3.58582C108.16 3.25472 107.463 3.05756 106.747 3.00652C106.101 2.96626 105.455 2.92324 104.809 2.91977C101.85 2.91144 98.892 2.92601 95.935 2.91005C95.3028 2.90588 94.6699 2.81428 93.953 2.75668Z"
            fill={fill}
        />
        <path
            d="M124.335 17.2442C126.009 19.0603 127.48 20.6447 128.939 22.2409C130.39 23.8238 131.83 25.4165 133.323 27.0592C131.519 27.758 130.565 27.5512 129.477 26.3652C126.448 23.0609 123.419 19.7578 120.391 16.4558C120.09 16.1283 119.84 15.757 119.369 15.1525C120.124 15.1088 120.6 15.0755 121.075 15.0561C122.458 15.065 123.841 14.9994 125.217 14.8597C129.466 14.2087 130.399 10.4994 129.844 7.64228C129.249 4.5728 126.888 2.84062 123.619 2.8774C121.491 2.90146 119.365 2.91441 117.241 2.91626C115.178 2.91626 115.051 2.83299 113.885 0.749656C114.187 0.684634 114.493 0.640117 114.8 0.616411C117.945 0.603225 121.09 0.534521 124.232 0.616411C131.857 0.810726 134.309 8.41398 131.578 13.301C130.266 15.6487 128.108 16.7917 125.501 17.1755C125.231 17.2116 124.953 17.2074 124.335 17.2442Z"
            fill={fill}
        />
    </svg>
);
