import { createAction } from '@reduxjs/toolkit';
import { PayloadError } from '../../../common/types';
import {
    GetCurrentActivityPayload,
    GetAgentActivityPayload,
    ConfirmWorkPayload,
    FinishWorkPayload,
    GetRequestAddressesPayload,
    AddFeedbackPayload,
    GetRequestByIdPayload,
    ActivitiesResponseType,
    RequestAddresses,
    RequestDurations,
} from '../types';
import { RequestInfoType } from '../../Requests/types';

export const getCurrentActivityRequest = createAction<GetCurrentActivityPayload>(
    'GET_CURRENT_ACTIVITY_REQUEST',
);
export const getCurrentActivitySuccess = createAction<{
    activity: ActivitiesResponseType[];
    totalCount: number;
    pageNumber: number;
}>('GET_CURRENT_ACTIVITY_SUCCESS');
export const getCurrentActivityError = createAction('GET_CURRENT_ACTIVITY_ERROR');

export const getAgentActivityRequest = createAction<GetAgentActivityPayload>(
    'GET_AGENT_ACTIVITY_REQUEST',
);
export const getAgentActivitySuccess = createAction<{
    activity: ActivitiesResponseType[];
    totalCount: number;
    pageNumber: number;
}>('GET_AGENT_ACTIVITY_SUCCESS');
export const getAgentActivityError = createAction('GET_AGENT_ACTIVITY_ERROR');

export const confirmWorkRequest = createAction<ConfirmWorkPayload>('CONFIRM_WORK_REQUEST');
export const confirmWorkSuccess = createAction('CONFIRM_WORK_SUCCESS');
export const confirmWorkError = createAction<PayloadError[]>('CONFIRM_WORK_ERROR');

export const finishWorkRequest = createAction<FinishWorkPayload>('FINISH_WORK_REQUEST');
export const finishWorkSuccess = createAction('FINISH_WORK_SUCCESS');
export const finishWorkError = createAction<PayloadError[]>('FINISH_WORK_ERROR');

export const getRequestAddressesRequest = createAction<GetRequestAddressesPayload>(
    'GET_REQUEST_ADDRESSES_REQUEST',
);
export const getRequestAddressesSuccess = createAction<{
    requestAddresses: RequestAddresses[];
    userName: string;
    pricePerDoor: null | number;
}>('GET_REQUEST_ADDRESSES_SUCCESS');
export const getRequestAddressesError = createAction('GET_REQUEST_ADDRESSES_ERROR');

export const addFeedbackRequest = createAction<AddFeedbackPayload>('ADD_FEEDBACK_REQUEST');
export const addFeedbackSuccess = createAction('ADD_FEEDBACK_SUCCESS');
export const addFeedbackError = createAction<PayloadError[]>('ADD_FEEDBACK_ERROR');

export const getRequestByIdRequest = createAction<GetRequestByIdPayload>(
    'GET_REQUEST_BY_ID_REQUEST',
);
export const getRequestByIdSuccess = createAction<{ requestModel: RequestInfoType }>(
    'GET_REQUEST_BY_ID_SUCCESS',
);
export const getRequestByIdError = createAction('GET_REQUEST_BY_ID_ERROR');
export const setDefaultRequest = createAction('SET_DEFAULT_REQUEST');

export const getRequestDurationsRequest = createAction<number>('GET_REQUEST_DURATIONS_REQUEST');
export const getRequestDurationsSuccess = createAction<RequestDurations>(
    'GET_REQUEST_DURATIONS_SUCCESS',
);

export const closePaymentStatusModal = createAction('CLOSE_PAYMENT_STATUS_MODAL');
