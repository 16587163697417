import React from 'react';
import { colors } from '../../common/constants';

export const AppleIcon: React.FC = () => (
    <svg
        width="1vw"
        height="22px"
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.9062 11.6094C14.9062 9.875 15.7031 8.60938 17.25 7.625C16.3594 6.35938 15.0469 5.70312 13.3125 5.5625C11.625 5.42188 9.79688 6.5 9.14062 6.5C8.4375 6.5 6.84375 5.60938 5.57812 5.60938C2.95312 5.65625 0.1875 7.67188 0.1875 11.8438C0.1875 13.0625 0.375 14.3281 0.84375 15.6406C1.45312 17.375 3.60938 21.5938 5.85938 21.5C7.03125 21.5 7.875 20.6562 9.42188 20.6562C10.9219 20.6562 11.6719 21.5 12.9844 21.5C15.2812 21.5 17.25 17.6562 17.8125 15.9219C14.7656 14.4688 14.9062 11.7031 14.9062 11.6094ZM12.2812 3.92188C13.5469 2.42188 13.4062 1.01562 13.4062 0.5C12.2812 0.59375 10.9688 1.29688 10.2188 2.14062C9.375 3.07812 8.90625 4.25 9 5.51562C10.2188 5.60938 11.3438 5 12.2812 3.92188Z"
            fill={colors.brand}
        />
    </svg>
);
