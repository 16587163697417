import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    Avatar,
    SimpleGrid,
    CloseButton,
    VStack,
} from '@chakra-ui/react';
import { LocationIcon } from '../../../../assets/icons';
import { fonts, getAvatarPicture } from '../../../../common/utils';
import { colors } from '../../../../common/constants';
import { CustomInput, CustomButton, Loader, RequestQuickInfo } from '../../../../common/components';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestTypes } from '../../../../store/actions/directories.actions';
import { submitJobProposalRequest } from '../../store/actions';
import { getRequest } from '../../../Requests/store/actions';
import { RootStore } from '../../../../store';
import { useDelta } from 'react-delta';
import { ApplyingJobModal } from '../../../../common/components/modals/ApplyingJob/ApplyingJobModal';
import { setConnectPaymentModalOpen } from '../../../Notifications/store/actions';

interface IJobDetailsModal {
    isOpen: boolean;
    onClose: () => void;
    jobId: number;
}

export const JobDetailsModal: React.FC<IJobDetailsModal> = ({ isOpen, onClose, jobId }) => {
    const dispatch = useDispatch();

    const { loading, value: data } = useSelector((state: RootStore) => state.requests.requestInfo);
    const { loading: submitLoading, errors: submitErrors } = useSelector(
        (state: RootStore) => state.opportunities.submitJobProposal,
    );
    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed = roles?.some((role: string) => role === 'user');

    const onSubmitJobProposal = (id: number, isListings: boolean) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            return;
        }
        dispatch(submitJobProposalRequest({ id, isListings }));
    };

    const submitLoadingDelta = useDelta(submitLoading);

    const [agentBonusModalOpen, setAgentBonusModalOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {
            dispatch(getRequest(jobId));
            dispatch(getRequestTypes());
        }
    }, [isOpen]);

    useEffect(() => {
        if (submitLoadingDelta && submitLoadingDelta.prev) {
            if (submitLoadingDelta.prev && !submitLoadingDelta.curr && !submitErrors.length) {
                onClose();
            }
        }
    }, [submitLoadingDelta]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="5xl">
                <ModalOverlay />
                <ModalContent
                    borderRadius="18px"
                    bgColor={colors.modalBackground}
                    alignItems="center"
                >
                    <ModalHeader
                        display="flex"
                        w="800px"
                        justifyContent="space-between"
                        flexDirection="row"
                        mt="20px"
                    >
                        <Text color="brand">{data.requestTypeName}</Text>
                        <CloseButton onClick={onClose} />
                    </ModalHeader>
                    <ModalBody overflowY="auto">
                        {loading ? (
                            <Loader spinnerSize="md" centerHeight="500px" />
                        ) : (
                            <>
                                <SimpleGrid columns={2} gap="50px" rowGap="0px">
                                    <Flex justifyContent="space-between" flexDirection="column">
                                        {data.typeId !== 7 ? (
                                            <>
                                                <Flex>
                                                    <Avatar
                                                        border="none"
                                                        size="xl"
                                                        name={data.ownerFullName}
                                                        src={getAvatarPicture(data.avatar)}
                                                    />
                                                    <Flex
                                                        justifyContent="space-between"
                                                        ml="20px"
                                                        flexDirection="column"
                                                    >
                                                        <Text
                                                            color="brand"
                                                            fontWeight={700}
                                                            fontSize="24px"
                                                            lineHeight="36px"
                                                            fontFamily={fonts.poppins}
                                                            textDecorationLine="underline"
                                                        >
                                                            {data.ownerFullName}
                                                        </Text>
                                                        <Flex>
                                                            <LocationIcon height="24" width="24" />
                                                            <Text
                                                                color="brand"
                                                                fontWeight={400}
                                                                fontSize="16px"
                                                                lineHeight="24px"
                                                                fontFamily={fonts.poppins}
                                                            >
                                                                {data.states[0]}
                                                            </Text>
                                                        </Flex>
                                                        <Text
                                                            color="brand"
                                                            fontWeight={300}
                                                            fontSize="14px"
                                                            lineHeight="21px"
                                                            fontFamily={fonts.poppins}
                                                        >
                                                            {data.agencyName}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                <RequestQuickInfo
                                                    date={data.dateTime}
                                                    fromTime={data.fromTime}
                                                    duration={data.duration}
                                                    propertiesToShow={data.propertiesToShow}
                                                    requestTypeName={data.requestTypeName}
                                                    totalPrice={data.totalPrice}
                                                    isShowTotalPrice={false}
                                                    margin={6}
                                                />
                                                <Box mt={6} w="350px">
                                                    {data.states &&
                                                        data.states.map((state: string) => (
                                                            <CustomInput
                                                                key={state}
                                                                placeholder="State"
                                                                label="State"
                                                                register={{ value: state }}
                                                                isBorder={false}
                                                                isReadOnly
                                                            />
                                                        ))}
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <VStack
                                                    align={'stretch'}
                                                    bg="white"
                                                    borderRadius="24px"
                                                    padding="20px"
                                                >
                                                    <Text
                                                        fontSize="14px"
                                                        color="secondaryFontDarker"
                                                    >
                                                        State
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        fontFamily={fonts.montserrat}
                                                        fontWeight={600}
                                                        color="brand"
                                                    >
                                                        {data.states.join('')}
                                                    </Text>
                                                    <Text
                                                        fontSize="14px"
                                                        color="secondaryFontDarker"
                                                    >
                                                        Agencies
                                                    </Text>
                                                    {data.agencies.map((agency: string) => (
                                                        <Text
                                                            key={agency}
                                                            fontSize="14px"
                                                            fontFamily={fonts.montserrat}
                                                            fontWeight={600}
                                                            color="brand"
                                                        >
                                                            {agency}
                                                        </Text>
                                                    ))}
                                                </VStack>
                                            </>
                                        )}

                                        <Box mt={6} w="350px">
                                            {data.typeId !== 7 && (
                                                <Text
                                                    color="brand"
                                                    fontWeight={700}
                                                    fontSize="16px"
                                                    lineHeight="24px"
                                                    fontFamily={fonts.poppins}
                                                >
                                                    Addresses
                                                </Text>
                                            )}

                                            <Box>
                                                {data.addresses &&
                                                    data.addresses.map((address: string) => (
                                                        <CustomInput
                                                            key={address}
                                                            placeholder="Address"
                                                            register={{ value: address }}
                                                            props={{ mt: 2 }}
                                                            isReadOnly
                                                        />
                                                    ))}
                                            </Box>
                                        </Box>
                                        {data.generalNote && (
                                            <Box mt={6} w="350px">
                                                <Text
                                                    color="brand"
                                                    fontWeight={700}
                                                    fontSize="18px"
                                                    lineHeight="18px"
                                                    fontFamily={fonts.montserrat}
                                                >
                                                    Description:
                                                </Text>
                                                <Text
                                                    color="secondaryFontDarker"
                                                    fontWeight={400}
                                                    fontSize="16px"
                                                    lineHeight="26px"
                                                    fontFamily={fonts.poppins}
                                                    mt={2}
                                                >
                                                    {data.generalNote}
                                                </Text>
                                            </Box>
                                        )}
                                    </Flex>
                                    {data.typeId === 1 && (
                                        <Flex flexDirection="column">
                                            <Text
                                                color="brand"
                                                fontWeight={700}
                                                fontSize="18px"
                                                lineHeight="36px"
                                                fontFamily={fonts.poppins}
                                            >
                                                More details:
                                            </Text>
                                            <Flex mt={4} justifyContent="space-between">
                                                <CustomInput
                                                    label="Is The Showing Scheduled?"
                                                    isReadOnly
                                                    width="350px"
                                                    textSize="16px"
                                                    value={data.isShowingScheduled ? 'Yes' : 'No'}
                                                />
                                            </Flex>
                                            <Flex mt={4} justifyContent="space-between">
                                                <CustomInput
                                                    label="I would like to offer a Bonus if a contract is ratified from this showing list within 48-hours Of Showing:"
                                                    isReadOnly
                                                    width="350px"
                                                    textSize="16px"
                                                    value={
                                                        data.wouldLikeToOfferBonus ? 'Yes' : 'No'
                                                    }
                                                />
                                            </Flex>
                                            {data.wouldLikeToOfferBonus && (
                                                <Flex mt={4} justifyContent="space-between">
                                                    <CustomInput
                                                        label="bonus Type:"
                                                        isReadOnly
                                                        width="150"
                                                        value={data.bonusTypeId === 1 ? '%' : '$'}
                                                    />
                                                    <CustomInput
                                                        label="Bonus"
                                                        isReadOnly
                                                        width="150"
                                                        value={data.amount}
                                                    />
                                                </Flex>
                                            )}
                                        </Flex>
                                    )}
                                </SimpleGrid>
                                <SimpleGrid
                                    columns={2}
                                    gap="50px"
                                    rowGap="0px"
                                    mt="150px"
                                    mb="80px"
                                >
                                    <Flex alignItems="flex-end">
                                        {data.typeId !== 7 && (
                                            <Flex justify="space-between" w="350px">
                                                <Text
                                                    fontSize="13px"
                                                    lineHeight="19px"
                                                    color="secondaryFontDarker"
                                                >
                                                    Estimated Earnings:
                                                </Text>
                                                <Text
                                                    fontSize="19px"
                                                    lineHeight="28px"
                                                    fontWeight={500}
                                                    color="brand"
                                                >
                                                    ${data.totalPrice}
                                                </Text>
                                            </Flex>
                                        )}
                                    </Flex>
                                    <CustomButton
                                        width="350px"
                                        height="60px"
                                        isLoading={submitLoading}
                                        loadingText="Submitting..."
                                        text="Submit"
                                        bgColor="brand"
                                        onClick={() => setAgentBonusModalOpen(true)}
                                    />
                                </SimpleGrid>
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <ApplyingJobModal
                isOpen={agentBonusModalOpen}
                onClose={() => setAgentBonusModalOpen(false)}
                onConfirm={() => onSubmitJobProposal(jobId, false)}
                amount={data.totalPrice}
                type={'$'}
                typeId={data.typeId}
            />
        </>
    );
};
