import React, { Dispatch } from 'react';
import {
    CloseButton,
    IconButton,
    Modal,
    ModalContent,
    ModalOverlay,
} from '@chakra-ui/react';

import { MultipleFilter } from '../MultipleFilter/MultipleFilter';
import { ModalOptionsProps } from '../DashboardContentHeader/DashboardContentHeader';

import { colors } from '../../../../../constants';
import { FilterState } from '../../../../../types/dashboard.types';

interface ITypesFilterProps {
    setOpenModal: (type: ModalOptionsProps) => void;
    isOpen: boolean;
    onClose: () => void;
    setFilterState: Dispatch<React.SetStateAction<FilterState>>
    filterState: FilterState;
}

const openHouseTypeIds = [
    { label: 'O/H Coverage Needed', value: 1, fontWeight: 700 },
    { label: 'O/H with Bonus', value: 2, fontWeight: 700 },
    { label: 'O/H with Payout', value: 3, fontWeight: 700 },
];

const dateOfActivity = [
    {
        label: 'Next week (7 days or less)',
        value: 7,
        fontWeight: 400,
        color: '#888DAB',
    },
    {
        label: 'Next two weeks (14 days or less)',
        value: 14,
        fontWeight: 400,
        color: '#888DAB',
    },
];

const itemsFilter = [
    { label: 'Assistance', value: 1, fontWeight: 600 },
    { label: 'Opportunity', value: 2, fontWeight: 600 },
];

const referrals = [{ label: 'Referral', value: 7, fontWeight: 600 }];

export const ModalFilterDashboard: React.FC<ITypesFilterProps> = ({
    isOpen,
    onClose,
    setFilterState,
    filterState,
}) => {

    const onApply = (value: any) => {
        let categoryIds = [...value.categoryIds].filter(id => id !== 3);
        if (value['Filter By'].length) {
            categoryIds = [...categoryIds, 3];
        }
        setFilterState((prev: any) => ({
            ...prev,
            categoryIds,
            forDays: value['Date of Activity'],
            openHouseTypeIds: Object.values(value['Filter By']),
            typeIds: value['Refferals'],
        }));
        onClose()
    };

    const onReset = () => {
        setFilterState({
            typeIds: [],
            categoryIds: [],
            openHouseTypeIds: [],
            forDays: [],
            sortingType: 0,
        });
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay />
            <ModalContent
                overflow={'hidden'}
                height="580px"
                borderRadius="18px"
                bgColor={colors.white}
                alignItems="center"
                pt="20px"
            >
                <IconButton
                    onClick={onClose}
                    aria-label={'close modal'}
                    icon={<CloseButton />}
                    variant="ghost"
                    position="absolute"
                    top="30px"
                    right="55px"
                />
                <MultipleFilter
                    onApply={onApply}
                    onReset={onReset}
                    items={[
                        {
                            categoryTitle: 'Filter By',
                            categories: openHouseTypeIds,
                            marginBottom: '25px',
                            defaultValue: filterState.openHouseTypeIds,
                        },
                        {
                            fieldsKey: 'categoryIds',
                            categories: itemsFilter,
                            marginBottom: '-5px',
                            defaultValue: filterState.categoryIds,
                        },
                        {
                            fieldsKey: 'Refferals',
                            categories: referrals,
                            defaultValue: filterState.typeIds,
                        },
                        {
                            categories: dateOfActivity,
                            categoryTitle: 'Date of Activity',
                            fontSize: '14px',
                            defaultValue: filterState.forDays,
                        },
                    ]}
                />
            </ModalContent>
        </Modal>
    );
};
