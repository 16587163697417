import React from 'react';
import {
    Box,
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Checkbox,
    VStack,
    Flex,
    Button,
} from '@chakra-ui/react';
import { colors } from '../../../common/constants';

interface ICooperationSelectProps {
    typeIdsSelected: number[];
    setTypeIdsSelected: (value: number[]) => void;
    menuValues: { label: string; value: number }[];
    label: string;
    fontFamily?: string;
    errors?: string;
}

export const CooperationSelect: React.FC<ICooperationSelectProps> = ({
    menuValues,
    setTypeIdsSelected,
    typeIdsSelected,
    label,
    fontFamily,
    errors,
}) => {
    const onChangeFilter = (checked: boolean, value: number) => {
        if (checked) {
            setTypeIdsSelected([...typeIdsSelected, value]);
        } else {
            setTypeIdsSelected(typeIdsSelected.filter(elem => elem !== value));
        }
    };

    return (
        <Popover placement="auto-start">
            <PopoverTrigger>
                <Box>
                    <Text
                        textTransform={'capitalize'}
                        color={'brand'}
                        fontSize={'14px'}
                        fontFamily={fontFamily}
                    >
                        {label}
                    </Text>
                    <Flex
                        bg={colors.inputBackground}
                        borderRadius="80px"
                        height="50px"
                        width="100%"
                        align="center"
                        px="20px"
                        justifyContent="flex-start"
                        fontWeight="normal"
                        as={Button}
                        border={errors ? '1px solid #e53e3e' : 'none'}
                        mt="0 !important"
                        _hover={{ background: colors.inputBackground }}
                    >
                        <Text
                            fontSize="16px"
                            lineHeight="21px"
                            fontWeight="300"
                            color={typeIdsSelected.length ? colors.brand : '#A0AEC0'}
                        >
                            {typeIdsSelected.length
                                ? menuValues
                                      .filter(elem => typeIdsSelected.includes(elem.value))
                                      .map(elem => elem.label)
                                      .join(', ')
                                : 'Enter'}
                        </Text>
                    </Flex>
                    <Text color="red.500" fontSize="12px" mt={2}>
                        {errors}
                    </Text>
                </Box>
            </PopoverTrigger>
            <PopoverContent borderRadius="20px">
                <VStack align="flex-start" p="15px">
                    {menuValues.map(elem => (
                        <Checkbox
                            key={elem.value}
                            defaultIsChecked={false}
                            checked={typeIdsSelected.includes(elem.value)}
                            onChange={ev => onChangeFilter(ev.target.checked, elem.value)}
                            colorScheme="checkboxScheme"
                        >
                            <Text fontSize="14px" lineHeight="21px" color="secondaryFontDarker">
                                {elem.label}
                            </Text>
                        </Checkbox>
                    ))}
                </VStack>
            </PopoverContent>
        </Popover>
    );
};
