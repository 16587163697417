import React, { useState, useEffect } from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
//components
import { IMessageBubble } from '../../../types';

export const MessageBubble: React.FC<IMessageBubble> = ({ body, time, isMy, item }) => {
    const [path, setPath] = useState<string | null>(null);

    useEffect(() => {
        if (item.state.media) {
            item.state.media.getContentTemporaryUrl().then((res: string) => {
                setPath(res);
            });
        }
    }, [item]);

    return (
        <Box m="20px 0" d="flex" flexDirection={isMy ? 'row-reverse' : 'row'}>
            <Box d="inline-block" maxW="50%">
                {path ? (
                    <Image src={path} w="25vw" borderRadius="15px" />
                ) : (
                    <Box
                        d="inline-block"
                        p="10px"
                        bg={isMy ? 'brand' : 'white'}
                        borderRadius="20px"
                    >
                        <Text fontSize="14px" color={isMy ? 'white' : 'brand'}>
                            {body}
                        </Text>
                    </Box>
                )}
                <Text mt="5px" fontSize="12px" color="#97ADB6" textAlign={isMy ? 'right' : 'left'}>
                    {time}
                </Text>
            </Box>
        </Box>
    );
};
