import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

export const PaymentInfoBox: React.FC<{ walletBalance: number }> = ({ walletBalance }) => (
    <Box bg={'#E6EDF4'} py={'14px'} px={'24px'} h={'110px'} mt="30px" borderRadius="8px">
        <Flex justifyContent={'space-between'} flexDir={'column'} h={'100%'}>
            <Text
                textTransform={'capitalize'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'21px'}
                color="brand"
            >
                Your balance is :
            </Text>
            <Text
                color={'#6FCF97'}
                textTransform={'uppercase'}
                fontWeight={'500'}
                fontSize={'29px'}
                lineHeight={'39px'}
            >
                ${walletBalance}
            </Text>
        </Flex>
    </Box>
);
