import React from 'react';
import { Box, Text, useMediaQuery, Image, Flex } from '@chakra-ui/react';
import { colors } from '../../constants';
import { fonts } from '../../utils';
import AgencyProfileAccent from '../../../assets/landing/agency-profile-accent.png';
import OpenHousesAccent from '../../../assets/landing/open-houses-accent.png';

interface IHowItWorks {
    title: string;
    firstParagraphDesc: string;
    secondParagraphDesc: string;
    isReverse: boolean;
    image: string;
    index: number;
}

export const HowItWorksBlock: React.FC<IHowItWorks> = ({
    title,
    firstParagraphDesc,
    secondParagraphDesc,
    isReverse,
    image,
    index,
}) => {
    // const [isLargerThan900] = useMediaQuery('(min-width: 900px');
    return (
        <Flex
            bg="#F8F9FA"
            flexDirection={{ base: 'column', lg: isReverse ? 'row-reverse' : 'row' }}
            justifyContent={
                /* isLargerThan900 ? 'space-between' : 'center' */ {
                    base: 'center',
                    lg: 'space-between',
                }
            }
            alignItems="center"
        >
            <Flex
                // w={isLargerThan900 ? '50%' : '90%'}
                py="30px"
                px={{ base: '20px', lg: '50px' }}
                w={{ base: '100%', lg: '45%' }}
                h="100%"
                flexDirection="column"
                justifyContent="center"
            >
                <Text
                    color={colors.brand}
                    fontFamily={fonts.montserrat}
                    fontSize={{ base: '18px', md: '28px' }}
                    lineHeight={{ base: '28px', md: '56px' }}
                    fontWeight="600"
                    mb="10px"
                >
                    {title}
                </Text>
                <Text
                    color={colors.brand}
                    fontFamily={fonts.montserrat}
                    fontSize="16px"
                    lineHeight="23px"
                    mb="10px"
                >
                    {firstParagraphDesc}
                </Text>
                <Text
                    color={colors.brand}
                    fontFamily={fonts.montserrat}
                    fontSize="16px"
                    lineHeight="23px"
                >
                    {secondParagraphDesc}
                </Text>
            </Flex>
            <Box w={{ base: '100%', lg: '45%' }} position="relative">
                <Image src={image} w="100%" />
                {index === 0 && (
                    <Image
                        src={AgencyProfileAccent}
                        left={{ base: 'auto', lg: '-100px' }}
                        top={{ base: 'auto', lg: '100px' }}
                        right={{ base: '-20px', lg: 'auto' }}
                        bottom={{ base: '-20px', lg: 'auto' }}
                        w={{ base: '130px', md: '240px' }}
                        position="absolute"
                    />
                )}
                {index === 3 && (
                    <Image
                        src={OpenHousesAccent}
                        width={{ base: '110px', md: 'auto' }}
                        position="absolute"
                        right="-20px"
                        bottom={{ base: '-20px', lg: '50px' }}
                        boxShadow={'0px 6px 16px rgba(59, 41, 2, 0.16)'}
                        borderRadius="10px"
                    />
                )}
            </Box>
        </Flex>
    );
};
