import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { RootStore } from '../../../store';
import { MainChat } from './Main.chat';

interface IMainChatContainer {
    match: any;
}

export const MainChatContainer: React.FC<IMainChatContainer> = ({ match }) => {

    const accessToken = useSelector((state: RootStore) => state.auth.user.token.accessToken);
    const userId = useSelector((state: RootStore) => state.auth.user.id);
    const participantId = match.params.id
    
    return (
        <MainChat
            accessToken={accessToken}
            userId={userId}
            participantId={participantId}
        />
    );
}
