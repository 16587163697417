import React, { Dispatch, SetStateAction } from 'react';
import { Box, Flex, TabPanel, TabPanels, Tabs, Text, HStack } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { BackArrow, CloseButton } from '../../../../assets/icons';
import { ConnectPaymentAccountHint, PaymentsTabsSwitcher } from './components';
import { Loader, CustomButton } from '../../../../common/components';
import { tabsLabels } from './consts';
import {
    BalanceTabContainer,
    PaymentMethodTabContainer,
    TransactionHistoryContainer,
} from './tabs';

interface IPaymentsProps {
    checkUserPaymentAccountLoading: boolean;
    isUserHasPaymentAccount: boolean;
    setShowCreateDwollaAccount: Dispatch<SetStateAction<boolean>>;
    tabIndex: number;
    setTabIndex: Dispatch<SetStateAction<number>>;
}

export const Payments: React.FC<IPaymentsProps> = ({
    checkUserPaymentAccountLoading,
    isUserHasPaymentAccount,
    setShowCreateDwollaAccount,
    tabIndex,
    setTabIndex,
}) => {
    const history = useHistory();

    return (
        <>
            <Box h={'85vh'}>
                <Flex justify="space-between" align="center" mb="40px" width="100%">
                    <HStack spacing="50px">
                        <Box onClick={history.goBack} as="button">
                            <BackArrow />
                        </Box>
                        <Text fontSize="22px" fontWeight="700" color="brand">
                            Payments
                        </Text>
                    </HStack>
                    {isUserHasPaymentAccount ? (
                        <Box onClick={history.goBack} as="button">
                            <CloseButton />
                        </Box>
                    ) : (
                        <ConnectPaymentAccountHint />
                    )}
                </Flex>
                {checkUserPaymentAccountLoading ? (
                    <Loader spinnerSize={'md'} centerHeight={'100%'} />
                ) : isUserHasPaymentAccount ? (
                    <Tabs isManual mt={'16px'} variant={'unstyled'} tabIndex={tabIndex}>
                        <PaymentsTabsSwitcher
                            setTabIndex={setTabIndex}
                            tabIndex={tabIndex}
                            labels={tabsLabels}
                        />
                        <TabPanels p="0">
                            <TabPanel p="0">
                                <BalanceTabContainer tabIndex={tabIndex} />
                            </TabPanel>
                            <TabPanel>
                                <PaymentMethodTabContainer tabIndex={tabIndex} />
                            </TabPanel>
                            <TabPanel>
                                <TransactionHistoryContainer />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                ) : (
                    <Flex w="100%" h="90%" justifyContent="flex-end" alignItems="flex-end">
                        <CustomButton
                            onClick={() => setShowCreateDwollaAccount(true)}
                            bgColor="brand"
                            height="60px"
                            width="342px"
                            text={'Connect Payment Account'}
                        />
                    </Flex>
                )}
            </Box>
        </>
    );
};
