import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import {
    getReportTypes,
    getReportTypesError,
    getReportTypesSuccess,
} from '../../actions/directories.actions';
import { IGetReportTypesSuccessResponse, IResponseWithCustomValue } from '../../../common/types';

function* workerGetReportTypes() {
    try {
        const result: IResponseWithCustomValue<IGetReportTypesSuccessResponse> = yield call(
            Api.get,
            '/api/directories/get-report-types',
            {},
        );
        console.log('getReportTypes', result);
        if (result.success) {
            yield put(getReportTypesSuccess(result));
        } else {
            yield put(getReportTypesError(result.errors));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* watchGetReportTypesDirectorySaga() {
    yield takeEvery(getReportTypes.type, workerGetReportTypes);
}
