import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import { IGetRequestTypesSuccessResponse, IResponseWithCustomValue } from '../../../common/types';
import {
    getRequestTypes,
    getRequestTypesError,
    getRequestTypesSuccess,
} from '../../actions/directories.actions';

function* workerGetRequestTypes() {
    try {
        const result: IResponseWithCustomValue<IGetRequestTypesSuccessResponse> = yield call(
            Api.get,
            '/api/directories/get-request-types',
            {},
        );
        console.log('getRequestTypes', result);
        if (result.success) {
            yield put(getRequestTypesSuccess(result));
        } else {
            yield put(getRequestTypesError(result.errors));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* watchGetRequestTypesDirectorySaga() {
    yield takeEvery(getRequestTypes.type, workerGetRequestTypes);
}
