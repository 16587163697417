import React from 'react';
import {
    LandingFooter,
    LandingHeader,
    TrialBanner,
    DropDownInfoBlock,
} from '../../../common/components/landing';
import HelpFAQBG from '../../../assets/landing/help-faq-bg.png';
import {
    Box,
    Container,
    Divider,
    Flex,
    Grid,
    Heading,
    Text,
    useMediaQuery,
} from '@chakra-ui/react';
import { fonts } from '../../../common/utils';
import {
    marginSections,
    FAQDropDownContent,
    FAQPricingContent,
    colors,
} from '../../../common/constants';

export const FAQ = () => {
    const [isMobile] = useMediaQuery('(max-width: 900px)');

    const marginSection = isMobile ? marginSections['80'] : marginSections['160'];

    return (
        <>
            <LandingHeader />

            <Box
                h={{ base: '300px', lg: '450px' }}
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={HelpFAQBG}
                position="relative"
                backgroundSize="cover"
                overflowY="hidden"
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />

                <Container maxW="container.xl" zIndex={2} position="inherit" h="100%">
                    <Flex direction="column" height="100%" justify="center" pb="70px">
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={{ base: '28px', lg: '52px' }}
                            lineHeight={{ base: '32px', lg: '62px' }}
                            fontWeight="bold"
                            w={{ base: '100%', lg: '60%' }}
                        >
                            Help & FAQs
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize="16px"
                            lineHeight="28px"
                            my="23px"
                        >
                            You have questions, and we have the answers.
                        </Text>
                    </Flex>
                </Container>
            </Box>

            <Box
                mt="-70px"
                padding="0 25px"
                bg="white"
                borderTopRadius={{ base: '30px', md: '70px' }}
                zIndex={10}
                position="relative"
                align="center"
                color={colors.brand}
                fontFamily={fonts.montserrat}
            >
                <Container maxW="1260px" p="0" pt={marginSection} color={colors.brand}>
                    <DropDownInfoBlock dataArr={FAQDropDownContent} />
                    <Grid
                        m={{ base: '50px 0', lg: '100px 0' }}
                        templateColumns={{ base: '1fr', sm: '1fr auto 1fr' }}
                        temlateRoes={{ base: '1fr 1fr', sm: '1fr' }}
                        gap="30px"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Divider border="1px solid #D1D2DB" />
                        <Heading
                            fontSize={{ base: '20px', lg: '28px' }}
                            lineHeight="48px"
                            fontFamily={fonts.montserrat}
                            fontWeight="600"
                            textAlign="left"
                        >
                            Common Pricing Questions
                        </Heading>
                        <Divider
                            border="1px solid #D1D2DB"
                            display={{ base: 'none', sm: 'block' }}
                        />
                    </Grid>
                    <Grid
                        mb={marginSection}
                        templateColumns={{
                            base: 'repeat(auto-fit, minmax(200px, 1fr))',
                            sm: 'repeat(auto-fit, minmax(400px, 1fr))',
                        }}
                        columnGap="80px"
                        rowGap="45px"
                    >
                        {FAQPricingContent.map(({ header, text }, index) => (
                            <Box key={header + index} align="left">
                                <Heading
                                    fontSize={{ base: '16px', lg: '20px' }}
                                    fontFamily={fonts.montserrat}
                                    mb="10px"
                                >
                                    {header}
                                </Heading>
                                <Text fontSize="16px">{text}</Text>
                            </Box>
                        ))}
                    </Grid>
                </Container>
            </Box>

            <TrialBanner
                title="Grow your real estate business in every state where you are licensed."
                buttonTitle="Register"
                textUnderButton="No subscriptions required."
            />
            <LandingFooter />
        </>
    );
};
