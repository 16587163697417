import { createAction } from '@reduxjs/toolkit';

import { PayloadError, StatesType, IResponseWithCustomValue } from '../../common/types';

export const getCurrentStates = createAction('GET_CURRENT_STATES');
export const getCurrentStatesSuccess = createAction<IResponseWithCustomValue<StatesType>>(
    'GET_CURRENT_STATES_SUCCESS',
);
export const getCurrentStatesError = createAction<PayloadError[]>('GET_CURRENT_STATES_ERROR');

// export const findAssistance = createAction<FindAssistancePayload>('FIND_ASSISTANCE');
// export const findAssistanceSuccess = createAction<FindAssistanceSuccessResponse>('FIND_ASSISTANCE_SUCCESS');
// export const findAssistanceError = createAction<PayloadError[]>('FIND_ASSISTANCE_ERROR');
