import React, { useEffect } from 'react';
import { Flex, Text, HStack, Image, chakra, Button } from '@chakra-ui/react';
import { Location, EyeIcon, DropdownArrowIcon } from '../../../assets/icons';
import { IconDescription, CustomButton } from '../../../common/components';
import { colors } from '../../../common/constants';
import { ReactComponent as House } from '../../../assets/icons/house.svg';
import { ReactComponent as Price } from '../../../assets/icons/price.svg';
import { ReactComponent as LocationLime } from '../../../assets/icons/location.svg';
import { ListingsType, OpenHouseDetailsModalOpen } from '../../../common/types';
import { convertToObject } from 'typescript';

interface IHostOpenHouseListingsProps extends ListingsType {
    onOpenModal: (value: number) => void;
    onOpenManage?: (value: number) => void;
    isMyListings?: boolean;
    onScheduleOpenHouse?: (value: number) => void;
}

export const HostOpenHouseListingItem: React.FC<IHostOpenHouseListingsProps> = ({
    address,
    city,
    id,
    isOpenHouse,
    onOpenModal,
    onOpenManage,
    photo,
    postalCode,
    price,
    stateCode,
    type,
    timeSlot,
    isMyListings,
    listingStatusId,
    onScheduleOpenHouse,
}) => {

    return (
        <Flex bg="inputBackground" borderRadius="14px" mt="10px" p="5px">
            <Flex w="32%">
                <Image w="58px" h="58px" borderRadius="16px" src={photo} />
                <Flex ml="10px" alignSelf="center" flexDirection="column">
                    <Text color="brand" fontWeight="800" lineHeight="18px" w="200px">
                        {address}, {city}, {stateCode} {postalCode}
                    </Text>
                    <HStack>
                        <Location />
                        <Text color="brand" fontSize="12px" lineHeight="16px" fontWeight={500}>
                            {stateCode}
                        </Text>
                    </HStack>
                </Flex>
            </Flex>
            <Flex w="32%" justify="center">
                <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
                    <IconDescription
                        icon={<Price />}
                        text={`$${price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                    />
                    <IconDescription icon={<House />} text={type.split('/').join(', ')} />
                    <IconDescription icon={<LocationLime />} text={city} />
                </Flex>
            </Flex>
            <Flex w="32%" justify="center">
                <Button
                    onClick={() => onOpenModal(id)}
                    bg="transparent"
                    alignSelf="center"
                    leftIcon={<EyeIcon />}
                    fontSize="14px"
                    fontWeight={400}
                    color="brand"
                    textDecorationLine="underline"
                >
                    See Details
                </Button>
                {!isOpenHouse && isMyListings && listingStatusId === 1 ? (
                    <CustomButton
                        text="Schedule Open House"
                        height="50px"
                        bgColor="brand"
                        w="220px"
                        alignSelf="center"
                        onClick={() => onScheduleOpenHouse?.(id)}
                    />
                ) : isOpenHouse && isMyListings && listingStatusId === 1 ? (
                    <CustomButton
                        text="Manage Open House"
                        height="50px"
                        bgColor="brand"
                        alignSelf="center"
                        w="220px"
                        onClick={() => onOpenManage?.(id)}
                    />
                ) : null}
            </Flex>
            <Flex align="center" as="button" onClick={() => onOpenModal(id)}>
                <chakra.span transform="rotate(-90deg)">
                    <DropdownArrowIcon />
                </chakra.span>
            </Flex>
        </Flex>
    );
};
