import React from 'react';

interface IHouseIcon {
    color?: string;
}

export const HouseIcon: React.FC<IHouseIcon> = ({
    color = "#4FD1C5"
}) => (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z" fill={color} />
        <path d="M15.1194 9.74255L15.4448 9.36293C15.2576 9.20243 14.9813 9.20243 14.794 9.36293L15.1194 9.74255ZM9.74219 14.3516L9.41679 13.972L9.24219 14.1216V14.3516H9.74219ZM13.5831 20.497V20.997C13.8592 20.997 14.0831 20.7732 14.0831 20.497H13.5831ZM16.6558 20.497H16.1558C16.1558 20.7732 16.3796 20.997 16.6558 20.997V20.497ZM20.4967 14.3516H20.9967V14.1216L20.822 13.972L20.4967 14.3516ZM10.5104 20.997H13.5831V19.997H10.5104V20.997ZM20.822 13.972L15.4448 9.36293L14.794 10.1222L20.1713 14.7312L20.822 13.972ZM14.794 9.36293L9.41679 13.972L10.0676 14.7312L15.4448 10.1222L14.794 9.36293ZM14.0831 20.497V18.1925H13.0831V20.497H14.0831ZM16.1558 18.1925V20.497H17.1558V18.1925H16.1558ZM16.6558 20.997H19.7285V19.997H16.6558V20.997ZM20.9967 19.7288V14.3516H19.9967V19.7288H20.9967ZM9.24219 14.3516V19.7288H10.2422V14.3516H9.24219ZM15.1194 17.1561C15.6918 17.1561 16.1558 17.6201 16.1558 18.1925H17.1558C17.1558 17.0678 16.2441 16.1561 15.1194 16.1561V17.1561ZM15.1194 16.1561C13.9948 16.1561 13.0831 17.0678 13.0831 18.1925H14.0831C14.0831 17.6201 14.5471 17.1561 15.1194 17.1561V16.1561ZM19.7285 20.997C20.4289 20.997 20.9967 20.4292 20.9967 19.7288H19.9967C19.9967 19.877 19.8766 19.997 19.7285 19.997V20.997ZM10.5104 19.997C10.3623 19.997 10.2422 19.877 10.2422 19.7288H9.24219C9.24219 20.4292 9.80997 20.997 10.5104 20.997V19.997Z" fill="white" />
    </svg>

)