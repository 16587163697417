import React, { useEffect, useState } from 'react';
import Opportunities from './Opportunities';
import { useDispatch, useSelector } from 'react-redux';
import { findJobPostsRequest, clearJobPostsList } from './store/actions';
import { CreateNewPostModal, JobDetailsModal, PostDetailsModal } from './modals';
import { RootStore } from '../../store';

const OpportunitiesContainer: React.FC = () => {
    const dispatch = useDispatch();

    const [tabIndex, setTabIndex] = useState(0);
    const [showCreateNewPostModal, setCreateNewPostModal] = useState(false);
    const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
    const [showPostDetailsModal, setShowPostDetailsModal] = useState(false);
    const [jobId, setJobId] = useState(0);
    const [postId, setPostId] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);

    const { loading, items, totalCount, loadMoreLoading } = useSelector(
        (state: RootStore) => state.opportunities.jobPosts,
    );

    const getItems = (page?: number) => {
        dispatch(
            findJobPostsRequest({
                pageNumber: page || currentPage,
                pageSize: 10,
                isMine: tabIndex === 0 ? false : true,
                typeIds: [],
                date: '',
            }),
        );
    };

    useEffect(() => {
        getItems();
    }, [tabIndex, currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        setOnEndReachedCalled(false);
    }, [tabIndex]);

    useEffect(() => {
        if (jobId) {
            setShowJobDetailsModal(true);
        }
    }, [jobId]);

    useEffect(() => {
        if (postId) {
            setShowPostDetailsModal(true);
        }
    }, [postId]);

    const loadMoreItems = () => {
        if (items.length >= totalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPage((prev: number) => prev + 1);
        }
    };

    return (
        <>
            <Opportunities
                tabIndex={tabIndex}
                setTabIndex={(value: number) => {
                    dispatch(clearJobPostsList());
                    setTabIndex(value);
                }}
                openCreateNewPost={() => setCreateNewPostModal(true)}
                loading={loading}
                posts={items}
                setJobId={setJobId}
                setPostId={setPostId}
                loadMoreItems={loadMoreItems}
                loadMoreLoading={loadMoreLoading}
            />
            <CreateNewPostModal
                isOpen={showCreateNewPostModal}
                onClose={() => setCreateNewPostModal(false)}
                getItems={getItems}
            />
            <JobDetailsModal
                isOpen={showJobDetailsModal}
                onClose={() => {
                    setShowJobDetailsModal(false);
                    setJobId(0);
                }}
                jobId={jobId}
            />
            <PostDetailsModal
                isOpen={showPostDetailsModal}
                onClose={() => {
                    setShowPostDetailsModal(false);
                    setPostId(0);
                }}
                postId={postId}
                getItems={getItems}
            />
        </>
    );
};

export default OpportunitiesContainer;
