import React from 'react';
import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    Center,
    ModalBody,
    Text,
    HStack,
    CloseButton as ChakraCloseButton,
    Flex,
} from '@chakra-ui/react';
import { CheckIcon, CloseButton } from '../../../../assets/icons';
import { colors } from '../../../../common/constants';
import { CustomButton } from '../../../../common/components';

interface IConfirmAlertProps {
    message: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    confirmLoading?: boolean;
    subMessage?: string;
}

export const ConfirmAlert: React.FC<IConfirmAlertProps> = ({
    isOpen,
    message,
    onClose,
    onConfirm,
    confirmLoading,
    subMessage,
}) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent borderRadius={'18px'} alignItems="center">
            <ModalBody d="flex" flexDirection="column" p="35px 50px">
                <Flex justifyContent="space-between">
                    <Text color="brand" fontSize="18px" fontWeight="bold" lineHeight="22px">
                        {message}
                    </Text>
                    <ChakraCloseButton onClick={onClose} />
                </Flex>
                <Center height="100%">
                    <Box w="100%">
                        {!!subMessage && (
                            <Text
                                color="brand"
                                textAlign="center"
                                fontSize="14px"
                                lineHeight="20px"
                                mb="15px"
                            >
                                {subMessage}
                            </Text>
                        )}
                        <HStack justifyContent="space-around" mt="30px">
                            <CustomButton
                                text="Cancel"
                                color="brand"
                                bgColor="white"
                                borderColor="brand"
                                height="60px"
                                width="100%"
                                borderRadius="8px"
                                onClick={onClose}
                            />
                            <CustomButton
                                text="Confirm"
                                bgColor="brand"
                                height="60px"
                                width="100%"
                                borderRadius="8px"
                                onClick={onConfirm}
                                isLoading={confirmLoading}
                            />
                        </HStack>
                    </Box>
                </Center>
            </ModalBody>
        </ModalContent>
    </Modal>
);
