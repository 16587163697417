import React, { useState, useEffect } from 'react';
import ModalWrapper from '../../blocks/ModalWrapper/ModalWrapper';
import { Box, Text } from '@chakra-ui/react';
import { CalendarModalHeader, CalendarModalBody } from '../../blocks';
import { RootStore } from '../../../../store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getCalendarOtherAgentActivitiesRequest } from '../../../../pages/Calendar/store/actions';
import { MyActivitiesModalSearch } from '../MyActivitiesModal/components';
import { SelectedActivity } from '../../../../pages/Activity/types';
import { CalendarModalItem } from '../../../../pages/Calendar/types';

interface IAgencyActivitiesModalProps {
    isOpen: boolean;
    onClose: () => void;
    startEndConfig: { start: string; end: string };
    setSelectedActivityId: (value: SelectedActivity) => void;
}

const AgencyActivitiesModal: React.FC<IAgencyActivitiesModalProps> = ({
    isOpen,
    onClose,
    startEndConfig,
    setSelectedActivityId,
}) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
    const [typeIdsSelected, setTypeIdsSelected] = useState<number[]>([]);
    const [categoryIdSelected, setCategoryIdSelected] = useState(1);

    const { loading, items, loadMoreLoading, totalCount } = useSelector(
        (state: RootStore) => state.calendar.otherAgentActivitiesModal,
    );

    const loadMoreItems = () => {
        const itemsFullLength = items.reduce(
            (prevValue: number, currValue: CalendarModalItem) =>
                prevValue + currValue.models.length,
            0,
        );
        if (itemsFullLength >= totalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPage((prev: number) => prev + 1);
        }
    };

    const getItems = (page?: number) => {
        dispatch(
            getCalendarOtherAgentActivitiesRequest({
                categoryId: categoryIdSelected,
                fromTime: startEndConfig.start,
                pageNumber: page || currentPage,
                pageSize: 10,
                toTime: startEndConfig.end,
                date: startEndConfig.start,
                typeIds: typeIdsSelected,
            }),
        );
    };

    useEffect(() => {
        if (isOpen) {
            getItems();
        }
    }, [isOpen, currentPage]);

    const ModalBody: React.FC = () => (
        <>
            <Text color="brand" fontWeight={600} fontSize="17px" mt="5px" lineHeight="25px">
                Other Agent Activities
            </Text>
            <CalendarModalBody
                items={items}
                loadMoreItems={loadMoreItems}
                loadMoreLoading={loadMoreLoading}
                loading={loading}
                placeholder="There is no available activities"
                setPostId={id => setSelectedActivityId({ id, isMyActivity: true })}
                bgColor="calendarRedOpacity"
                borderLeftColor="calendarRed"
            />
        </>
    );

    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            modalHeader={
                <CalendarModalHeader
                    bgColor="calendarRedOpacity"
                    borderLeftColor="calendarRed"
                    startDate={startEndConfig.start}
                    title="Agent Activities"
                />
            }
            modalSearch={
                <MyActivitiesModalSearch
                    categoryIdSelected={categoryIdSelected}
                    getItems={getItems}
                    setCategoryIdSelected={setCategoryIdSelected}
                    setTypeIdsSelected={setTypeIdsSelected}
                    typeIdsSelected={typeIdsSelected}
                />
            }
            modalBody={<ModalBody />}
        />
    );
};

export { AgencyActivitiesModal };
