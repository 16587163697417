import { put, call, takeEvery } from 'redux-saga/effects';
import { getRequest, getRequestSuccess, getRequestError } from '../actions';
import API from '../../../../common/api/api.base';
import { GetRequestSuccessResponse } from '../../types';
import { IResponseWithCustomValue } from '../../../../common/types';

function* workerGetRequest(action: ReturnType<typeof getRequest>) {
    try {
        const result: IResponseWithCustomValue<GetRequestSuccessResponse> = yield call(
            API.get,
            `/api/request/${action.payload}`,
            {},
        );
        console.log('getRequest', result, action);
        if (result.success) {
            yield put(getRequestSuccess(result));
        } else {
            yield put(getRequestError(result.errors));
        }
    } catch (error) {
        console.log('workerGetRequest error', error);
    }
}

export default function* watchGetRequestSaga() {
    yield takeEvery(getRequest.type, workerGetRequest);
}
