import React, { useEffect } from 'react';
import { useForm, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CustomModalWrapper, PostEditor } from '../../components';
import {
    Avatar,
    Box,
    Flex,
    ModalBody,
    Text,
    Image,
    Wrap,
    WrapItem,
    Tag,
    Divider,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addCommentToPostRequest,
    getDetailsPostRequest,
    saveTogglePostRequest,
} from '../../store/actions';
import { RootStore } from '../../../../store';
import { baseUrl } from '../../../../common/api/api.base';
import dayjs from 'dayjs';
import { BookMark, CommentsIcon } from '../../../../assets/icons';
import { Loader } from '../../../../common/components';
import { colors } from '../../../../common/constants';
import { Comments } from './components/Comments';
import { getAvatarPicture } from '../../../../common/utils';
import { IdNameTypes } from '../../../../common/types';

const commentSchema = yupResolver(
    yup.object().shape({
        comment: yup
            .string()
            .required('This field is required')
            .test('comment', 'This field is required', function (value) {
                return !(value && value.replace(/<(.|\n)*?>/g, '').trim().length === 0);
            }),
    }),
);
type CommentForm = {
    comment: string;
};

interface IDetailsPostModalProps {
    size: string;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    detailsPostId: number;
}

export const DetailsPostModal: React.FC<IDetailsPostModalProps> = React.memo(
    ({ size, isOpen, onClose, title, detailsPostId }) => {
        const {
            handleSubmit,
            formState: { errors },
            setValue,
            register,
            reset,
            watch,
            clearErrors,
        } = useForm({
            resolver: commentSchema as Resolver<CommentForm>,
        });

        const dispatch = useDispatch();
        const { post, loading } = useSelector((state: RootStore) => state.posts.detailsPost);
        const { loading: addCommentLoading } = useSelector(
            (state: RootStore) => state.posts.addComment,
        );

        const commentValue = watch('comment');

        const onSaveTogglePost = () => {
            dispatch(
                saveTogglePostRequest({
                    PostId: post.id,
                    isSavedPost: !post.isSaved,
                }),
            );
        };
        const onSubmit = (data: CommentForm) => {
            dispatch(
                addCommentToPostRequest({
                    PostId: post.id,
                    Text: data.comment,
                }),
            );
            reset({});
        };

        useEffect(() => {
            register('comment');
        }, [register]);

        useEffect(() => {
            if (isOpen && detailsPostId) {
                dispatch(getDetailsPostRequest(detailsPostId));
            }
        }, [isOpen, detailsPostId]);

        useEffect(() => {
            if (!isOpen) {
                reset({});
                clearErrors('comment');
            }
        }, [isOpen]);
        // console.log(post.comment.length)
        return (
            <CustomModalWrapper isOpen={isOpen} title={title} size={size} onClose={onClose}>
                <ModalBody h={loading ? '85vh' : 'auto'}>
                    {loading ? (
                        <Loader spinnerSize={'md'} centerHeight={'100%'} />
                    ) : (
                        <>
                            {/*    Header Post*/}
                            {post.isSuspended && (
                                <Box
                                    textAlign={'center'}
                                    py={'8px'}
                                    top={0}
                                    left={0}
                                    right={0}
                                    position={'absolute'}
                                    bgColor={'#FFEFBE'}
                                >
                                    <Text
                                        _before={{
                                            content: `"!"`,
                                            marginRight: '10px',
                                        }}
                                        color={colors.orange}
                                        letterSpacing={'1px'}
                                        textTransform={'uppercase'}
                                        fontWeight={'600'}
                                        fontSize={'12px'}
                                        lineHeight={'16,8px'}
                                    >
                                        Suspended
                                    </Text>
                                </Box>
                            )}

                            <Flex justifyContent={'space-between'} align={'center'} mt={'27px'}>
                                <Flex align={'center'}>
                                    <Avatar
                                        mr={'8px'}
                                        boxSize={'6'}
                                        name={post.createdBy.fullName}
                                        src={getAvatarPicture(post.createdBy.avatar)}
                                    />
                                    <Text
                                        lineHeight={'24px'}
                                        color={'brand'}
                                        weight={500}
                                        fontSize={'16px'}
                                    >
                                        {post.createdBy.fullName}
                                    </Text>
                                    <Text
                                        ml={'21px'}
                                        color={'#888DAB'}
                                        lineHeight={'21px'}
                                        fontSize={'14px'}
                                        fontWeight={400}
                                    >
                                        {dayjs(post.createdAt).format('MM/DD/YY')}
                                    </Text>
                                </Flex>
                                <Flex>
                                    <Box as={'button'} onClick={onSaveTogglePost} mr="15px">
                                        <BookMark colorFill={post.isSaved ? '#2C335C' : 'none'} />
                                    </Box>
                                    <Flex>
                                        <CommentsIcon />
                                        <Text>{post.comments.length}</Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                            {/* Image Box*/}
                            {post.photo.itemExtension && (
                                <Image
                                    mt={'13px'}
                                    h={'324px'}
                                    w={'100%'}
                                    objectFit={'cover'}
                                    alt={'post image'}
                                    src={`${baseUrl}/api/storage/static-images/${post.photo.itemHash}${post.photo.itemExtension}`}
                                />
                            )}
                            {/*Tags*/}
                            {!!post.tags.length && (
                                <Wrap spacing={'22px'} my={'18px'}>
                                    {post.tags.map((tag: IdNameTypes) => (
                                        <WrapItem key={tag.id}>
                                            <Tag
                                                color={colors.secondaryFontDarker}
                                                fontSize={'12px'}
                                                p={'12px 8px'}
                                                variant={'outline'}
                                                h={'32px'}
                                                borderRadius={'40px'}
                                            >
                                                {tag.name}
                                            </Tag>
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            )}
                            {/* Title*/}
                            <Text
                                lineHeight={'35px'}
                                fontSize={'24px'}
                                letterSpacing={'2px'}
                                fontWeight={'600'}
                            >
                                {post.title}
                            </Text>
                            {/*    Post Text*/}
                            <Text
                                color={colors.secondaryFontDarker}
                                lineHeight={'24px'}
                                fontSize={'16px'}
                                letterSpacing={'1px'}
                                fontWeight={'400'}
                            >
                                {post.text}
                            </Text>
                            {!!post.comments.length && (
                                <Divider border={'1px solid #D1D2DB'} my={'32px'} />
                            )}
                            {/*Comments */}
                            <Comments comments={post.comments} postId={post.id} />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/*Post Editor*/}
                                <PostEditor
                                    addCommentLoading={addCommentLoading}
                                    value={commentValue}
                                    errorMsg={errors.comment?.message}
                                    placeholder={'Write your comment here ...'}
                                    onChange={value => {
                                        // console.log(value, 'VALUE')
                                        setValue('comment', value);
                                    }}
                                />
                            </form>
                        </>
                    )}
                </ModalBody>
            </CustomModalWrapper>
        );
    },
);
