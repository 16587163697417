import { createAction } from '@reduxjs/toolkit';
import {
    GetDetailsReportPayload,
    GetDetailsReportSuccessResponse,
    GetServiceHistoryPayload,
    GetServiceHistorySuccessResponse,
} from '../types';
import { PayloadError, IResponseWithCustomValue } from '../../../common/types';

export const getServiceHistoryRequest = createAction<GetServiceHistoryPayload>(
    'GET_SERVICE_HISTORY_REQUEST',
);
export const getServiceHistorySuccess = createAction<{
    result: GetServiceHistorySuccessResponse;
    pageNumber: number;
}>('GET_SERVICE_HISTORY_SUCCESS');
export const getServiceHistoryError = createAction<PayloadError[]>('GET_SERVICE_HISTORY_ERROR');

export const getDetailsReportRequest = createAction<GetDetailsReportPayload>(
    'GET_DETAILS_REPORT_REQUEST',
);
export const getDetailsReportSuccess = createAction<
    IResponseWithCustomValue<GetDetailsReportSuccessResponse>
>('GET_DETAILS_REPORT_SUCCESS');
export const getDetailsReportError = createAction<PayloadError[]>('GET_DETAILS_REPORT_ERROR');
