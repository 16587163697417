import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomModalWrapper } from '../../components';
import { Box, ModalBody } from '@chakra-ui/react';
import { AddReportPayload } from '../../types';
import { CustomButton, CustomInput } from '../../../../common/components';
import { Resolver, useForm } from 'react-hook-form';

interface IReportPostModalProps {
    size: string;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    reportPostId: number;
    reportTypeId: number;
    onAddReport: (payload: AddReportPayload) => void;
    addReportLoading: boolean;
}

const reportSchema = yupResolver(
    yup.object().shape({
        text: yup.string().required('This field is required'),
    }),
);

export const ReportPostModal: React.FC<IReportPostModalProps> = ({
    size,
    isOpen,
    onClose,
    title,
    reportTypeId,
    reportPostId,
    onAddReport,
    addReportLoading,
}) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: reportSchema as Resolver<{ text: string }>,
    });

    const onSubmit = (data: { text: string }) => {
        onAddReport({
            Text: data.text,
            TypeId: reportTypeId,
            PostId: reportPostId,
        });
    };

    useEffect(() => {
        if (!isOpen) {
            reset({});
        }
    }, [isOpen]);

    return (
        <CustomModalWrapper isCentered isOpen={isOpen} title={title} size={size} onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <CustomInput
                        register={register('text')}
                        label={'Reason'}
                        placeholder={'Enter'}
                        errors={errors.text}
                    />

                    <Box mt={'30px'}>
                        <CustomButton
                            isLoading={addReportLoading}
                            bgColor="brand"
                            height={'50px'}
                            type={'submit'}
                            text={'Send Report'}
                            borderRadius="4px"
                            fontWeight={300}
                        />
                    </Box>
                </ModalBody>
            </form>
        </CustomModalWrapper>
    );
};
