import React from 'react';
import { Text, Flex, CloseButton } from '@chakra-ui/react';
import { fonts } from '../../../common/utils';

interface IUploadedFileItemProps {
    fileName: string;
    onRemove: () => void;
}

export const UploadedFileItem: React.FC<IUploadedFileItemProps> = ({ fileName, onRemove }) => {
    return (
        <Flex justify="space-between" align="center" w="100%">
            <Text fontFamily={fonts.montserrat} fontSize="16px">
                {fileName}
            </Text>
            <CloseButton color="brand" onClick={onRemove} />
        </Flex>
    );
};
