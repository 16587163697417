import React from 'react';

export const ThumbUpIcon: React.FC = () => (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.5969 18.8197L11.7532 18.2828L11.5969 18.5285V18.8197H12.5969ZM22.3083 3.55896L23.1519 4.09583L22.3083 3.55896ZM31.9093 8.99494L31.0148 8.54773V8.54773L31.9093 8.99494ZM26.9969 18.8197L26.1025 18.3725C25.9475 18.6825 25.964 19.0506 26.1462 19.3454C26.3284 19.6402 26.6503 19.8197 26.9969 19.8197V18.8197ZM52.1969 36.8197L52.9969 37.4197L53.1969 37.153V36.8197H52.1969ZM43.5569 48.3397L44.3569 48.9397L43.5569 48.3397ZM29.8106 1.7879L30.3251 0.930408V0.930408L29.8106 1.7879ZM0.796875 17.0197V53.0197H2.79688V17.0197H0.796875ZM13.4405 19.3566L23.1519 4.09583L21.4646 3.02208L11.7532 18.2828L13.4405 19.3566ZM31.0148 8.54773L26.1025 18.3725L27.8913 19.2669L32.8037 9.44216L31.0148 8.54773ZM26.9969 19.8197H44.9969V17.8197H26.9969V19.8197ZM51.1969 26.0197V36.8197H53.1969V26.0197H51.1969ZM51.3969 36.2197L42.7569 47.7397L44.3569 48.9397L52.9969 37.4197L51.3969 36.2197ZM37.7969 50.2197H19.7969V52.2197H37.7969V50.2197ZM13.5969 44.0197V18.8197H11.5969V44.0197H13.5969ZM44.9969 19.8197C48.421 19.8197 51.1969 22.5955 51.1969 26.0197H53.1969C53.1969 21.491 49.5256 17.8197 44.9969 17.8197V19.8197ZM19.7969 50.2197C16.3727 50.2197 13.5969 47.4439 13.5969 44.0197H11.5969C11.5969 48.5484 15.2681 52.2197 19.7969 52.2197V50.2197ZM29.2961 2.64539C31.3231 3.86161 32.072 6.4334 31.0148 8.54773L32.8037 9.44216C34.3282 6.39309 33.2482 2.68431 30.3251 0.930408L29.2961 2.64539ZM42.7569 47.7397C41.586 49.3009 39.7484 50.2197 37.7969 50.2197V52.2197C40.3779 52.2197 42.8083 51.0045 44.3569 48.9397L42.7569 47.7397ZM23.1519 4.09583C24.4684 2.02708 27.1934 1.38379 29.2961 2.64539L30.3251 0.930408C27.2928 -0.888948 23.3631 0.0387344 21.4646 3.02208L23.1519 4.09583Z"
            fill="#4FD1C5"
        />
    </svg>
);
