import React from 'react';
import { Box, Text, Flex, IconButton } from '@chakra-ui/react';
import { ISignUpProps } from './types';
import { SignUpForm } from './components/SignUpForm/SignUpForm';
import { BackArrow } from '../../../assets/icons';

export const SignUp: React.FC<ISignUpProps> = ({
    loading,
    stateErrors,
    nestedPath,
    onSendEmailCode,
    step,
    onConfirmEmail,
    onSendPhoneCode,
    onConfirmPhone,
    accessToken,
    onSetStep,
    goBack,
}) => (
    <Box borderRadius={20} mt="30px" backgroundColor="#FFFFFF" w="100%" position="relative">
        <Flex
            h="100%"
            flexDirection="column"
            justifyContent="space-around"
            px={{ base: '20px', md: '20%' }}
        >
            <IconButton
                aria-label="Back"
                variant="unstyled"
                position="absolute"
                top={{ base: '27px', lg: '30px' }}
                left={{ base: '15px', lg: '30px' }}
                d="flex"
                alignItems="center"
                onClick={() => goBack?.()}
            >
                <BackArrow />
            </IconButton>
            <Text
                fontWeight={900}
                fontSize={{ base: '18px', lg: '32px' }}
                color="brand"
                mt={{ base: '35px', lg: '50px' }}
                ml={{ base: '50px', md: 0 }}
            >
                Let&apos;s Create Your Account
            </Text>
            <SignUpForm
                loading={loading}
                stateErrors={stateErrors}
                onSendEmailCode={onSendEmailCode}
                step={step}
                nestedPath={nestedPath}
                onConfirmEmail={onConfirmEmail}
                onSendPhoneCode={onSendPhoneCode}
                onConfirmPhone={onConfirmPhone}
                accessToken={accessToken}
                onSetStep={onSetStep}
            />
        </Flex>
    </Box>
);
