export enum colors {
    white = '#FFF',
    black = '#000',
    primaryFont = '#FFF',
    secondaryFont = '#D1D2DB',
    secondaryFontDarker = '#888DAB',
    brand = '#2C335C',
    inputBackground = '#F6F7FB',
    green = '#6FCF97',
    orange = '#FE805C',
    calendarBlue = 'rgb(47, 128, 237)',
    calendarBlueOpacity = 'rgba(47, 128, 237, 0.2)',
    calendarGreen = 'rgb(39, 174, 96)',
    calendarGreenOpacity = 'rgba(39, 175, 96, 0.2)',
    calendarRed = 'rgb(235, 87, 87)',
    calendarRedOpacity = 'rgba(235, 87, 87, 0.2)',
    calendarYellow = 'rgb(242, 201, 76)',
    calendarYellowOpacity = 'rgba(242, 201, 76, 0.2)',
    calendarLical = 'rgba(187, 107, 217)',
    calendarLicalOpacity = 'rgba(187, 107, 217, 0.2)',
    calendarBackGround = 'rgba(44, 51, 92, 0.03)',
    calendarDateBackGround = 'rgba(44, 51, 92, 0.5)',
    calendarCurrentDateBackground = 'rgba(79, 209, 197, 0.06)',
    calendarCurrentDateTextBackground = 'rgba(44, 51, 92, 0.2)',
    calendarPastDatesBackground = 'rgba(12, 15, 30, 0.05)',
    indicatorGreen = '#2CC197',
    indicatorGreenShadow = 'rgba(44, 193, 151, 0.603714)',
    modalBackground = '#EBF2F8',
    chakraErrorRed = '#e53e3e',
    chakraBorder = 'rgb(226, 232, 240)',
    chakraBorderHover = '#CBD5E0',
    pink = '#F85C7F',
    cherry = '#95227A',
    lime = '#28B0AF',
    azure = '#2FA8D1',
    borderGray = '#C4C4C4',
    orangeOpacity = 'rgba(254, 128, 92, 0.15)',
    greenOpacity = 'rgba(111, 207, 151, 0.15)',
    switchGreen = '#4FD1C5',
}
