import React from 'react';
import { Text, Flex, IconButton } from '@chakra-ui/react';
//components
import { PagginationRightArrow, PagginationLeftArrow } from '../../../../../assets/icons';
import { usePagination } from '../../../../../common/helpers';
import { IPagination } from '../../../types';

export const ConversationPagination: React.FC<IPagination> = ({
    currentPage,
    totalCount,
    pageSize,
    onPageChange,
    siblingCount = 1,
}) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            ml="auto"
            mr="auto"
            // position="absolute"
            bottom="15px"
            left="0"
            right="0"
        >
            <IconButton
                aria-label="Previous page"
                mr="14px"
                d="flex"
                alignItems="center"
                variant="unstyled"
                onClick={() => {
                    if (currentPage > 1) onPageChange(currentPage - 1);
                }}
            >
                <PagginationLeftArrow />
            </IconButton>
            {paginationRange.map((item: string | number, index: number) => (
                <Flex
                    key={index}
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50%"
                    w="25px"
                    h="25px"
                    as="button"
                    bg={currentPage === item ? 'brand' : 'transparent'}
                    onClick={() => {
                        if (item !== '...') onPageChange(item);
                    }}
                >
                    <Text
                        m="0 10px"
                        color={currentPage === item ? 'white' : 'secondaryFont'}
                        fontSize="15px"
                    >
                        {item}
                    </Text>
                </Flex>
            ))}
            <IconButton
                aria-label="Next page"
                ml="14px"
                d="flex"
                alignItems="center"
                variant="unstyled"
                onClick={() => {
                    if (currentPage < paginationRange[paginationRange.length - 1])
                        onPageChange(currentPage + 1);
                }}
            >
                <PagginationRightArrow />
            </IconButton>
        </Flex>
    );
};
