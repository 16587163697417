import { useEffect, useState } from 'react';
import axios from 'axios';


export const useCitiesByState = (state: string) => {
  const [cities, setCities] = useState<any[]>([]);
  const citiesResponse = () => {
    axios({
      method: 'post',
      url: 'https://countriesnow.space/api/v0.1/countries/state/cities',
      data: {
        country: 'United States',
        state,
      },
    })
      .then((res) => {
        const response = res.data.data;
        const mappedCities = response.map((city: any, index: number) => ({
          value: city,
          label: city,
          id: index
        }));
        setCities(mappedCities);
      })
      .catch((err) => {
        console.log('error in request', err);
      });
  };

  useEffect(() => {
    state && state[0] !== "" && citiesResponse();
  }, [state]);

  return cities;
};

