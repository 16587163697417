import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../../../../../store/actions/auth/auth.actions';
import { AgentsType, PayloadError } from '../../../../types';
import { getAgentsBySearch, getAgentsBySearchError, getAgentsBySearchSuccess, resetSearchedAgents } from './actions';

interface InitialState {
    loading: boolean;
    loadMoreLoading: boolean;
    errors: PayloadError[];
    agents: Array<AgentsType>;
    totalCount: number;
}

export const initialState: InitialState = {
    errors: [],
    loading: false,
    loadMoreLoading: false,
    agents: [],
    totalCount: 0,
};

const searchUsersSlice = createSlice({
    name: 'searchUsers',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAgentsBySearch, (state, action) => {
                if (action.payload.PageNumber === 1) {
                    state.loading = true;
                } else {
                    state.loadMoreLoading = true;
                }
                state.errors = [];
            })
            .addCase(getAgentsBySearchSuccess, (state, action) => {
                state.loading = false;
                state.loadMoreLoading = false;
                state.errors = [];
                state.agents = [...state.agents, ...action.payload.value.agents];
                state.totalCount = action.payload.value.totalCount;
            })
            .addCase(getAgentsBySearchError, (state, action) => {
                state.loading = false;
                state.loadMoreLoading = false;
                state.errors = action.payload;
            })
            .addCase(resetSearchedAgents, (state) => {
                state.loading = false;
                state.loadMoreLoading = false;
                state.agents = [];
            })
            .addCase(logout, state => {
                state.loading = false;
                state.loadMoreLoading = false;
                state.errors = [];
                state.agents = [];
            });
    },
});

export default searchUsersSlice.reducer;
