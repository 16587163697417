import React from 'react';

export const EditIcon2: React.FC<{ height?: string; color: string; width?: string }> = ({
    color,
    height,
    width,
}) => {
    return (
        <svg width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none">
            <path
                d="M4 20.0001H8L18.5 9.50006C19.0304 8.96962 19.3284 8.2502 19.3284 7.50006C19.3284 6.74991 19.0304 6.03049 18.5 5.50006C17.9696 4.96963 17.2501 4.67163 16.5 4.67163C15.7499 4.67163 15.0304 4.96963 14.5 5.50006L4 16.0001V20.0001Z"
                stroke={color}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5 6.5L17.5 10.5"
                stroke={color}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
