import React from 'react';
import {
    ChangeClientName,
    ChangeClientPhone,
    ChangeClientType,
    ChangePrivateNote,
    RequestInfoType,
} from '../../../types';
import { OpenHouseInfo } from '../request-types';
import { Loader, HireAssistanceInfoSection } from '../../../../../common/components';
import { Box } from '@chakra-ui/react';
import { OpportunitiesInfo } from '../components';
import { IdNameTypes, OpenHouseInfoType } from '../../../../../common/types';

interface IInfoProps {
    requestInfo: RequestInfoType;
    requestInfoLoading: boolean;
    deleteLoading: boolean;
    bonusTypes: IdNameTypes[];
    clientTypes: IdNameTypes[];
    openHouseInfo: OpenHouseInfoType;
    openHouseInfoLoading: boolean;
    onDeleteRequest: (value: number) => void;
    onChangeClientName: (payload: ChangeClientName) => void;
    onChangeClientPhone: (payload: ChangeClientPhone) => void;
    onChangeClientType: (payload: ChangeClientType) => void;
    onChangePrivateNote: (payload: ChangePrivateNote) => void;
}

export const Info: React.FC<IInfoProps> = ({
    requestInfo,
    deleteLoading,
    requestInfoLoading,
    bonusTypes,
    clientTypes,
    openHouseInfo,
    openHouseInfoLoading,
    onDeleteRequest,
    onChangeClientName,
    onChangeClientPhone,
    onChangeClientType,
    onChangePrivateNote,
}) => {
    if (requestInfoLoading) {
        return <Loader spinnerSize="md" centerHeight="65vh" />;
    }

    return (
        <Box height="100%">
            {requestInfo.requestCategoryId === 1 ? (
                <HireAssistanceInfoSection
                    requestInfo={requestInfo}
                    bonusTypes={bonusTypes}
                    clientTypes={clientTypes}
                    loading={requestInfoLoading}
                    deleteLoading={deleteLoading}
                    isActivities={false}
                />
            ) : null}
            {requestInfo.requestCategoryId === 3 ? (
                <OpenHouseInfo
                    loading={openHouseInfoLoading}
                    openHouseInfo={openHouseInfo}
                    requestInfo={requestInfo}
                    requestLoading={requestInfoLoading}
                />
            ) : null}
            {requestInfo.requestCategoryId === 2 ? (
                <OpportunitiesInfo
                    requestInfoLoading={requestInfoLoading}
                    requestInfo={requestInfo}
                    onDeleteRequest={onDeleteRequest}
                    onChangeClientName={onChangeClientName}
                    onChangeClientPhone={onChangeClientPhone}
                    onChangeClientType={onChangeClientType}
                    onChangePrivateNote={onChangePrivateNote}
                    bonusTypes={bonusTypes}
                    clientTypes={clientTypes}
                />
            ) : null}
        </Box>
    );
};
