import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Text, Container, HStack, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import { useDelta } from 'react-delta';

import { ListingDetailsModal, OpenhouseDetailsModal } from '../Listings/modals';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { BackButton } from '../../common/components';
import { TabsSwitcher } from '../../common/components/blocks/TabsSwitcher';
import { FiltersBlock } from '../Listings/components/FiltersBlock';
import { CalendarTab } from './CalendarTab/CalendarTab';
import { AgencyListingsTab } from '../Listings/tabs';

import { RootStore } from '../../store';
import {
    getListingTypesRequest,
    getMyListings,
    getOpenHouseInfo,
    getAgencyListingsRequest,
    getAgencyRoasterRequest,
    getAgencyCities,
} from '../../store/actions/listings.actions';
import {
    getOpenHouseAudiences,
    getOpenHouseHostTypes,
    getOpenHouseTypes,
} from '../../store/actions/directories.actions';

import { OpenHouseDetailsOpenConfig, ListingsType, IdNameTypes } from '../../common/types';
import { sortByTypes } from '../../common/constants';
import PeopleCardsContainer from '../Listings/tabs/PeopleCardsContainer';

export const defaultOpenHouseDetailsOpenConfig = {
    isOpen: false,
    isMyListings: true,
    isManageOpenHouse: false,
    listingStatusId: 0,
};

interface IListingsProps {
    agencyListings: ListingsType[];
    agencyListingsLoading: boolean;
    agencyListingsMoreLoading: boolean;
    myListingsLoading: boolean;
    listingTypes: IdNameTypes[];
    setToShowListingId: (value: number) => void;
    setShowOpenhouseInfoModal: (value: OpenHouseDetailsOpenConfig) => void;
    setShowAddTimeSlotModal: (value: boolean) => void;
    setShowListingInfoModal: (value: boolean) => void;
    tabIndex: number;
    setTabIndex: (value: number) => void;
    activeListings: ListingsType[];
    pendingListings: ListingsType[];
    closedListings: ListingsType[];
    agencyCities: string[];
    sortValue: number;
    setSortValue: (value: number) => void;
    setIsSorted: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenHouse: (value: boolean) => void;
    setPropertyTypes: (value: number[]) => void;
    setCities: (value: string[]) => void;
    loadMoreItems: () => void;
}

const tabsOptions = [
    {
        index: 0,
        label: 'Agency Listings',
    },
    {
        index: 1,
        label: 'Agency Roaster',
    },
    {
        index: 2,
        label: 'Agency Calendar',
    },
];

export const MyAgency: React.FC<IListingsProps> = ({}) => {
    const onOpenModal = (
        value: number,
        listing: ListingsType,
        isMyListings: boolean,
        isManageOpenHouse: boolean,
    ) => {
        setToShowListingId(value);
        if (listing.isOpenHouse) {
            setShowOpenhouseInfoModal({
                isOpen: true,
                listingStatusId: listing.listingStatusId || 0,
                isMyListings,
                isManageOpenHouse,
            });
        } else {
            setShowListingInfoModal(true);
        }
    };

    const [showOpenhouseInfoModal, setShowOpenhouseInfoModal] =
        useState<OpenHouseDetailsOpenConfig>(defaultOpenHouseDetailsOpenConfig);
    const [showAddTimeSlotModal, setShowAddTimeSlotModal] = useState(false);
    const [toShowListingId, setToShowListingId] = useState(0);
    const [showListingInfoModal, setShowListingInfoModal] = useState(false);
    const [tabIndex, setTabIndex] = useState(tabsOptions[0].index);
    const [currentPageListings, setCurrentPageListings] = useState(1);
    const [currentPageRoaster, setCurrentPageRoaster] = useState(1);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
    const [onEndReachedRoasterCalled, setOnEndReachedRoasterCalled] = useState(false);
    const [sortValue, setSortValue] = useState(sortByTypes[0].value);
    const [isSorted, setIsSorted] = useState(false);
    const [isOpenHouse, setIsOpenHouse] = useState(false);
    const [propertyTypes, setPropertyTypes] = useState<number[]>([]);
    const [cities, setCities] = useState<string[]>([]);

    const {
        loading: agencyListingsLoading,
        items: agencyListings,
        loadMoreLoading: agencyListingsMoreLoading,
        totalCount: agencyListingsTotalCount,
    } = useSelector((state: RootStore) => state.listings.agencyListings);

    const {
        loading: agencyRoasterLoading,
        items: agencyRoaster,
        loadMoreLoading: agencyRoasterMoreLoading,
        totalCount: agencyRoasterTotalCount,
    } = useSelector((state: RootStore) => state.listings.agencyRoaster);

    const { loading: addSlotLoading, errors: addSlotErrors } = useSelector(
        (state: RootStore) => state.listings.addTimeSlot,
    );

    const { items: listingTypes } = useSelector((state: RootStore) => state.listings.listingTypes);
    const { items: agencyCities } = useSelector((state: RootStore) => state.listings.agencyCities);

    const addSlotLoadingDelta = useDelta(addSlotLoading);

    const dispatch = useDispatch();
    const onGetOpenHouseTypes = () => dispatch(getOpenHouseTypes());
    const onGetOpenHouseHostTypes = () => dispatch(getOpenHouseHostTypes());
    const onGetOpenHouseAudiences = () => dispatch(getOpenHouseAudiences());
    const onGetListingTypes = () => dispatch(getListingTypesRequest());
    const onGetAgencyCities = () => dispatch(getAgencyCities());

    useEffect(() => {
        if (tabIndex === 0) {
            dispatch(
                getAgencyListingsRequest({
                    cities,
                    isOpenHouse,
                    pageNumber: currentPageListings,
                    pageSize: 20,
                    propertyTypes,
                    sortBy: sortValue === 1 ? 0 : 1,
                    sortOrder: isSorted,
                }),
            );
        } else if (tabIndex === 1) {
            dispatch(
                getAgencyRoasterRequest({
                    pageNumber: currentPageRoaster,
                    pageSize: 20,
                }),
            );
        }
    }, [
        tabIndex,
        sortValue,
        isSorted,
        cities,
        isOpenHouse,
        propertyTypes,
        currentPageListings,
        currentPageRoaster,
    ]);

    useEffect(() => {
        onGetListingTypes();
        onGetOpenHouseTypes();
        onGetOpenHouseHostTypes();
        onGetOpenHouseAudiences();
        onGetAgencyCities();
    }, []);

    useEffect(() => {
        if (addSlotLoadingDelta && addSlotLoadingDelta.prev) {
            if (addSlotLoadingDelta.prev && !addSlotLoadingDelta.curr && !addSlotErrors.length) {
                setShowAddTimeSlotModal(false);
                dispatch(getMyListings());
                dispatch(getOpenHouseInfo(toShowListingId));
            }
        }
    }, [addSlotLoadingDelta]);

    const loadMoreListings = () => {
        if (agencyListings.length >= agencyListingsTotalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPageListings((prev: number) => prev + 1);
        }
    };

    const loadMoreRoaster = () => {
        if (agencyRoaster.length >= agencyRoasterTotalCount) {
            setOnEndReachedRoasterCalled(true);
        } else if (!onEndReachedRoasterCalled) {
            setCurrentPageRoaster((prev: number) => prev + 1);
        }
    };

    return (
        <>
            <Flex backgroundColor="brand" flexDirection="column" alignItems="center" h="100vh">
                <DashboardHeader />
                <Container
                    maxW="container.xl"
                    bgColor="#FFFFFF"
                    h="90vh"
                    mt="15px"
                    borderRadius="10px"
                    p="25px 33px"
                >
                    <HStack mb="16px">
                        <BackButton mb="0" text="" />
                        <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                            My Agency
                        </Text>
                    </HStack>
                    <Tabs variant="unstyled" index={tabIndex}>
                        <Flex justifyContent="space-between">
                            <Flex>
                                <TabsSwitcher
                                    tabIndex={tabIndex}
                                    setTabIndex={setTabIndex}
                                    tabsOptions={tabsOptions}
                                />
                            </Flex>
                            {tabIndex === 0 && (
                                <FiltersBlock
                                    listingTypes={listingTypes}
                                    agencyCities={agencyCities}
                                    setSortValue={setSortValue}
                                    sortValue={sortValue}
                                    setIsSorted={setIsSorted}
                                    setCities={setCities}
                                    setIsOpenHouse={setIsOpenHouse}
                                    setPropertyTypes={setPropertyTypes}
                                />
                            )}
                        </Flex>
                        <TabPanels>
                            <TabPanel p="0" h="100%" pt="10px">
                                <AgencyListingsTab
                                    items={agencyListings}
                                    loading={agencyListingsLoading}
                                    loadMoreLoading={agencyListingsMoreLoading}
                                    onOpenModal={onOpenModal}
                                    loadMoreItems={loadMoreListings}
                                />
                            </TabPanel>
                            <TabPanel p="0" h="100%" pt="10px">
                                <PeopleCardsContainer
                                    items={agencyRoaster}
                                    loading={agencyRoasterLoading}
                                    loadMoreLoading={agencyRoasterMoreLoading}
                                    loadMoreItems={loadMoreRoaster}
                                />
                            </TabPanel>
                            <TabPanel p="0" h="100%" pt="10px">
                                <CalendarTab />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </Flex>
            <OpenhouseDetailsModal
                showOpenhouseInfoModal={showOpenhouseInfoModal}
                onClose={() => {
                    setShowOpenhouseInfoModal(defaultOpenHouseDetailsOpenConfig);
                    setToShowListingId(0);
                }}
                listingId={toShowListingId}
                setShowAddTimeSlotModal={setShowAddTimeSlotModal}
                getItems={() => dispatch(getMyListings())}
            />
            <ListingDetailsModal
                isOpen={showListingInfoModal}
                onClose={() => {
                    setShowListingInfoModal(false);
                    setToShowListingId(0);
                }}
                listingId={toShowListingId}
            />
        </>
    );
};
