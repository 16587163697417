import React from 'react';

import { Box, Heading, Text, Image } from '@chakra-ui/react';

import { fonts } from '../../../../common/utils';

import accentDots from '../../../../assets/landing/accent_dots.svg';

interface IWhyBlockProps {
    header: string;
    text: string;
    hasDots?: boolean;
}

export const WhyItWorksCard: React.FC<IWhyBlockProps> = ({ text, header, hasDots }) => (
    <Box
        bgColor="#F8F9FA"
        fontFamily={fonts.montserrat}
        padding={{ base: '25px', md: '40px' }}
        align="left"
        borderRadius="15px"
        position="relative"
    >
        <Heading fontSize="20px" weight="700" lineHeight="40px" pb="15px">
            {header}
        </Heading>

        <Text fontSize="16px" lineHeight="26px">
            {text}
        </Text>

        {hasDots && (
            <Box
                position="absolute"
                bottom={{ base: '-50px', lg: '50px' }}
                right={{ base: '-10px', md: '-25px', '2xl': '-50px' }}
                zIndex={-1}
            >
                <Image src={accentDots} />
            </Box>
        )}
    </Box>
);
