import React from 'react';
import { colors } from '../../common/constants';

export const DragAndDropIcon: React.FC = () => (
    <svg width="66" height="48" viewBox="0 0 66 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.83333 46.6663V47.1663H10.8333V46.6663H9.83333ZM32.5 46.6663V47.1663H33.5V46.6663H32.5ZM10.8333 46.6663V44.3991H9.83333V46.6663H10.8333ZM32.5 44.3981V46.6663H33.5V44.3981H32.5ZM21.6667 33.5663C27.6498 33.5663 32.5 38.4151 32.5 44.3981H33.5C33.5 37.8627 28.2019 32.5663 21.6667 32.5663V33.5663ZM10.8333 44.3991C10.8333 38.4161 15.6835 33.5663 21.6667 33.5663V32.5663C15.1313 32.5663 9.83333 37.8637 9.83333 44.3991H10.8333ZM21.6667 9.89967C17.635 9.89967 14.3667 13.168 14.3667 17.1997H15.3667C15.3667 13.7203 18.1873 10.8997 21.6667 10.8997V9.89967ZM28.9667 17.1997C28.9667 13.168 25.6983 9.89967 21.6667 9.89967V10.8997C25.1461 10.8997 27.9667 13.7203 27.9667 17.1997H28.9667ZM21.6667 24.4997C25.6983 24.4997 28.9667 21.2314 28.9667 17.1997H27.9667C27.9667 20.6791 25.1461 23.4997 21.6667 23.4997V24.4997ZM21.6667 23.4997C18.1873 23.4997 15.3667 20.6791 15.3667 17.1997H14.3667C14.3667 21.2314 17.635 24.4997 21.6667 24.4997V23.4997ZM5.8 1.83301H60.2V0.833008H5.8V1.83301ZM64.2333 5.86634V42.133H65.2333V5.86634H64.2333ZM60.2 46.1663H5.8V47.1663H60.2V46.1663ZM1.76666 42.133V5.86634H0.766663V42.133H1.76666ZM5.8 46.1663C3.57245 46.1663 1.76666 44.3606 1.76666 42.133H0.766663C0.766663 44.9128 3.02016 47.1663 5.8 47.1663V46.1663ZM64.2333 42.133C64.2333 44.3605 62.4275 46.1663 60.2 46.1663V47.1663C62.9798 47.1663 65.2333 44.9128 65.2333 42.133H64.2333ZM60.2 1.83301C62.4275 1.83301 64.2333 3.63879 64.2333 5.86634H65.2333C65.2333 3.08651 62.9798 0.833008 60.2 0.833008V1.83301ZM5.8 0.833008C3.02016 0.833008 0.766663 3.08651 0.766663 5.86634H1.76666C1.76666 3.63879 3.57245 1.83301 5.8 1.83301V0.833008ZM39.8 15.433H53.4V14.433H39.8V15.433ZM39.8 29.033H53.4V28.033H39.8V29.033Z"
            fill={colors.secondaryFontDarker}
        />
    </svg>
);
