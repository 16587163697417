import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ListItem } from './ListItem';
import { OpenHousesResponse } from '../../../../../pages/Calendar/types';
import dayjs from 'dayjs';

interface IListBlockProps {
    startTime: string;
    models: OpenHousesResponse[];
    setPostId: (value: number) => void;
}

export const ListBlock: React.FC<IListBlockProps> = ({ models, startTime, setPostId }) => (
    <Box>
        <Box
            borderLeftColor="calendarBlue"
            borderLeftWidth="9px"
            bgColor="calendarBlueOpacity"
            mb="10px"
        >
            <Text fontWeight={400} fontSize="22px" ml="10px">
                Starting{' '}
                {dayjs(startTime.replace('0001', new Date().getFullYear() + '')).format('hh:mm A')}
            </Text>
        </Box>
        {models.map(elem => (
            <ListItem key={elem.id} {...elem} onPressDetails={() => setPostId(elem.id)} />
        ))}
    </Box>
);
