import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import {
    getOpenHouseHostTypes,
    getOpenHouseHostTypesSuccess,
    getOpenHouseHostTypesError,
} from '../../actions/directories.actions';
import { IResponseWithCustomValue, IdNameTypes } from '../../../common/types';

function* workerGetOpenHouseHostTypes() {
    try {
        const result: IResponseWithCustomValue<{
            types: Array<IdNameTypes>;
        }> = yield call(Api.get, '/api/directories/open-house-host-types', {});

        if (result.success) {
            yield put(getOpenHouseHostTypesSuccess(result.value));
        } else {
            yield put(getOpenHouseHostTypesError(result.errors));
            console.log('ERROR', result);
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* watchGetOpenHouseHostTypesSaga() {
    yield takeEvery(getOpenHouseHostTypes.type, workerGetOpenHouseHostTypes);
}
