import { Avatar, chakra, Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import { DropdownArrowIcon, EmailIcon, PhoneWithWavesIcon } from '../../../../../assets/icons';
import { AgentsType, ToggleUserProfileModalPayload } from '../../../../types';
import { getAvatarPicture } from '../../../../utils';
import { Typography } from '../../Typography/Typography';


interface IUserCardItem {
    agent: AgentsType;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
}

export const SearchUsersCardItem: React.FC<IUserCardItem> = ({ agent, onOpenProfileImage }) => {
    return (
            <Flex
                key={agent.id}
                borderRadius={'26px'}
                bg={'inputBackground'}
                px={'10px'}
                py={'10px'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Flex alignItems={'center'} justifyContent='center'>
                    <Avatar
                        boxSize="70px"
                        src={getAvatarPicture(agent.avatar)}
                        mr="35px"
                        ml="10px"
                        name={agent.firstName}
                    />
                    <Flex flexDir='column'>
                        <Typography size={13} weight={700}>
                            {agent.firstName + " " + agent.lastName}
                        </Typography>
                        <Typography size={13} weight={500}>
                            {agent.agencyName}
                        </Typography>
                        <Flex alignItems={'center'}>
                            <PhoneWithWavesIcon fill={'#868AA4'} />
                            <Typography ml={'5px'} size={12}>
                                {agent.phoneNumber}
                            </Typography>
                        </Flex>
                        <Flex alignItems={'center'}>
                            <EmailIcon />
                            <Typography ml={'5px'} size={12}>
                                {agent.email}
                            </Typography>
                        </Flex>
                    </Flex>
                </Flex>
                <IconButton
                    onClick={() => {
                        onOpenProfileImage({
                            userId: agent.id,
                            value: true,
                        });
                    }}
                    bg="transparent"
                    alignSelf="center"
                    aria-label="Navigate to profile image"
                    icon={
                        <chakra.span transform="rotate(-90deg)">
                            <DropdownArrowIcon />
                        </chakra.span>
                    }
                />
            </Flex>
    );
};
