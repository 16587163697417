import { Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BackButton, CustomButton } from '../../../../common/components';
import {
    AddLicensePayload,
    LicenseDraftType,
    LicenseType,
    SaveLicenseDraftPayload,
} from '../../types';
import { AddLicenseItem } from './components/AddLicenseItem';
import { LicenseItem } from './components/LicenseItem';

interface ILicenses {
    licenses: LicenseType[];
    states: any;
    agencies: any;
    draft: LicenseDraftType;
    addLicenseLoading: boolean;
    saveDraftLoading: boolean;
    showLicenseEditor: boolean;
    accessToken: string;
    stateId: string;
    handleAddLicense: () => void;
    setState: (v: string) => void;
    saveLicenseDraft: (v: SaveLicenseDraftPayload) => void;
    addLicense: (v: AddLicensePayload) => void;
}

export const Licenses: React.FC<ILicenses> = ({
    licenses,
    states,
    agencies,
    draft,
    addLicenseLoading,
    saveDraftLoading,
    showLicenseEditor,
    accessToken,
    stateId,
    handleAddLicense,
    setState,
    saveLicenseDraft,
    addLicense,
}) => {
    const [choosenItem, setChoosenItem] = useState<number | null>(null);

    return (
        <Flex bgColor="#FFFFFF" flexDirection="column" borderRadius="10px" p="10px 50px">
            <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                    <BackButton text="" />
                    <Text
                        fontSize="24px"
                        lineHeight="25px"
                        color="brand"
                        fontWeight="bold"
                        ml="22px"
                        mb="15px"
                    >
                        Licenses
                    </Text>
                </Flex>
                <CustomButton
                    bgColor="transparent"
                    color="brand"
                    borderColor="brand"
                    height="50px"
                    width="200px"
                    onClick={() => {
                        setChoosenItem(licenses.length + 1);
                        handleAddLicense();
                    }}
                    mb="15px"
                    text={!showLicenseEditor ? '+ Add License' : 'Undo Add'}
                />
            </Flex>
            {licenses.length > 0 &&
                licenses.map((license, index) => (
                    <LicenseItem
                        key={license.id}
                        license={license}
                        agencies={agencies}
                        states={states}
                        draft={draft}
                        addLicenseLoading={addLicenseLoading}
                        saveDraftLoading={saveDraftLoading}
                        index={index}
                        showLicenseEditor={showLicenseEditor}
                        accessToken={accessToken}
                        stateId={stateId}
                        choosenItem={choosenItem}
                        saveLicenseDraft={saveLicenseDraft}
                        addLicense={addLicense}
                        setState={setState}
                        handleAddLicense={handleAddLicense}
                        setChoosenItem={setChoosenItem}
                    />
                ))}
            {showLicenseEditor && (
                <AddLicenseItem
                    licenses={licenses}
                    agencies={agencies}
                    states={states}
                    draft={draft}
                    addLicenseLoading={addLicenseLoading}
                    saveDraftLoading={saveDraftLoading}
                    index={licenses.length + 1}
                    showLicenseEditor={showLicenseEditor}
                    accessToken={accessToken}
                    stateId={stateId}
                    choosenItem={choosenItem}
                    saveLicenseDraft={saveLicenseDraft}
                    addLicense={addLicense}
                    setState={setState}
                    handleAddLicense={handleAddLicense}
                    setChoosenItem={setChoosenItem}
                />
            )}
        </Flex>
    );
};
