import {createAction} from "@reduxjs/toolkit";
import {
    AddPostCommentPayload, AddReportPayload, AdvertisingPostTypeResponse, CommentType,
    CreatePostPayload,
    GetPostsPayload,
    PostDetailResponse,
    PostTypeResponse,
    UpdatePostPayload
} from "../types";
import {PayloadError} from "../../../common/types";

//CREATE POST
export const createPostRequest = createAction<CreatePostPayload>('CREATE_POST_REQUEST')
export const createPostSuccess = createAction('CREATE_POST_SUCCESS')
export const createPostError = createAction<PayloadError[]>('CREATE_POST_ERROR')
//GET POSTS
export const getPostsRequest = createAction<GetPostsPayload>('GET_POSTS_REQUEST')
export const getPostsSuccess = createAction<{
    posts: PostTypeResponse[]
}>('GET_POSTS_SUCCESS')
export const getPostsError = createAction<PayloadError[]>('GET_POSTS_ERROR')

//GET ADVERTISING POSTS
export const getAdvertisingPostsRequest = createAction('GET_ADVERTISING_POSTS_REQUEST')
export const getAdvertisingPostsSuccess = createAction<{
    posts: AdvertisingPostTypeResponse[]
}>('GET_ADVERTISING_POSTS_SUCCESS')
export const getAdvertisingPostsError = createAction<PayloadError[]>('GET_ADVERTISING_POSTS_ERROR')

//UPDATE POSTS
export const updatePostRequest = createAction<UpdatePostPayload>('UPDATE_POST_REQUEST')
export const updatePostSuccess = createAction('UPDATE_POST_SUCCESS')
export const updatePostError = createAction<PayloadError[]>('UPDATE_POST_ERROR')

//DELETE POST
export const deletePostRequest = createAction<number>('DELETE_POST_REQUEST')
export const deletePostSuccess = createAction('DELETE_POST_SUCCESS')
export const deletePostError = createAction<PayloadError[]>('DELETE_POST_ERROR')

//GET DETAIL POST
export const getDetailsPostRequest = createAction<number>('GET_DETAILS_POST_REQUEST')
export const getDetailsPostSuccess = createAction<{
    post: PostDetailResponse
}>('GET_DETAILS_POST_SUCCESS')
export const getDetailsPostError = createAction<PayloadError[]>('GET_DETAILS_POST_ERROR')

//GET ADVERTISING DETAIL POST
export const getAdvertisingDetailsPostRequest = createAction<number>('GE_ADVERTISINGT_DETAILS_POST_REQUEST')
export const getAdvertisingDetailsPostSuccess = createAction<{
    post: AdvertisingPostTypeResponse
}>('GET_ADVERTISING_DETAILS_POST_SUCCESS')
export const getAdvertisingDetailsPostError = createAction<PayloadError[]>('GET_ADVERTISING_DETAILS_POST_ERROR')

//ADD COMMENT TO POST
export const addCommentToPostRequest = createAction<AddPostCommentPayload>('ADD_COMMENT_TO_POST_REQUEST')
export const addCommentToPostSuccess = createAction<CommentType>('ADD_COMMENT_TO_POST_SUCCESS')
export const addCommentToPostError = createAction<PayloadError[]>('ADD_COMMENT_TO_POST_ERROR')

//SAVE TOGGLE POST
export const saveTogglePostRequest = createAction<{
    PostId: number,
    isSavedPost: boolean
}>('SAVE_TOGGLE_POST_REQUEST')
export const saveTogglePostSuccess = createAction<{
    PostId: number,
    isSavedPost: boolean
}>('SAVE_TOGGLE_POST_SUCCESS')
export const saveTogglePostError = createAction<PayloadError[]>('SAVE_TOGGLE_POST_ERROR')

//ADD REPORT
export const addReportRequest = createAction<AddReportPayload>('ADD_REPORT_REQUEST')
export const addReportSuccess = createAction('ADD_REPORT_SUCCESS')
export const addReportError = createAction<PayloadError[]>('ADD_REPORT_ERROR')
