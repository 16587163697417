import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { RootStore } from '../../../../../store';
import { SignUpUploadPhoto } from './SignUpUploadPhoto';
import { SubmitHandler } from 'react-hook-form';
import { SignUpUploadLicensePayload } from '../../types';
import { signUpAddDriverLicencePhoto } from '../../store/actions';
import { IAuthContainerProps } from '../../../../../common/types';

export const SignUpUploadDriverLicense: React.FC<IAuthContainerProps> = ({ nestedPath }) => {
    const loading = useSelector((state: RootStore) => state.signUp.loading);
    const errors = useSelector((state: RootStore) => state.signUp.errors);
    const accessToken = useSelector((state: RootStore) => state.signUp.accessToken);
    const isAddedDraftAgency = useSelector((state: RootStore) => state.signUp.isAddedDraftAgency);
    const licenseDraft = useSelector((state: RootStore) => state.signUp.license);

    const dispatch = useDispatch();
    const onAddDriverLicencePhoto: SubmitHandler<SignUpUploadLicensePayload> = payload =>
        dispatch(signUpAddDriverLicencePhoto(payload));

    return (
        <SignUpUploadPhoto
            subLabel={
                'Please upload a form of identification to help us to verify your identity. This information is for OUR use only!'
            }
            label="Add Identification"
            loading={loading}
            stateErrors={errors}
            onSubmit={onAddDriverLicencePhoto}
            token={accessToken}
            infoText={
                "Acceptable identification include a Driver's License, state-issued ID Card, or  a Passport"
            }
            isAddedDraftAgency={isAddedDraftAgency}
            licenseDraft={licenseDraft}
        />
    );
};
