import React from 'react';
import {
    Box,
    Text,
    Flex,
    Tabs,
    TabList,
    Tab,
} from '@chakra-ui/react';
import {
    Loader,
    InfiniteScroll,
} from '../../../../../../common/components';
import { TransactionHistoryItemComponent } from './components/TransactionHistoryTabItem';
import { TransactionHistoryItem } from '../../types';
import { colors } from '../../../../../../common/constants';
import { ToggleUserProfileModalPayload } from '../../../../../../common/types';

interface ITransactionHistoryProps {
    loadNewTransactions: () => void;
    items: TransactionHistoryItem[];
    setIsIncome: (payload: boolean) => void;
    isIncome: boolean;
    tabIndex: number;
    setTabIndex: (index: number) => void;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
}

const tabs = [{ index: 1, name: 'Incoming' }, { index: 2, name: 'Outgoing' }]

export const TransactionHistory: React.FC<ITransactionHistoryProps> = ({
    items,
    loadNewTransactions,
    setIsIncome,
    isIncome,
    tabIndex,
    setTabIndex,
    onOpenProfileImage,
}) => (
    <Box>
        <Tabs variant="unstyled" index={tabIndex} h={'full'}>
            <TabList py="15px">
                {tabs.map(elem => (
                    <Tab
                        key={elem.index}
                        border={
                            tabIndex === elem.index
                                ? `1px solid ${colors.brand}`
                                : `1px solid ${colors.secondaryFontDarker}`
                        }
                        borderRadius="20px"
                        minW="150px"
                        h="35px"
                        onClick={() => setTabIndex(elem.index)}
                        bg={
                            tabIndex === elem.index
                                ? colors.brand
                                : 'transparent'
                        }
                        mr="10px"
                    >
                        <Text
                            fontSize="14px"
                            color={
                                tabIndex === elem.index
                                    ? 'white'
                                    : 'secondaryFontDarker'
                            }
                            fontWeight={400}
                        >
                            {elem.name}
                        </Text>
                    </Tab>
                ))}
            </TabList>

        </Tabs>

        <Flex
            borderBottom="1px #D1D2DB solid"
            alignItems="center"
            pb="10px"
        >
            <Box w="26%" color="secondaryFontDarker">
                Name
            </Box>
            <Box w="30%" color="secondaryFontDarker">
                Description
            </Box>
            <Box w="22%" color="secondaryFontDarker">
                Date
            </Box>
            <Box w="11%" color="secondaryFontDarker">
                Total
            </Box>
            <Box w="11%" textAlign="right" color="secondaryFontDarker">
                Details
            </Box>
        </Flex>
        <InfiniteScroll onReachBottom={loadNewTransactions}>
            {items?.map((item: TransactionHistoryItem, index: number) => (
                <TransactionHistoryItemComponent
                    key={index}
                    item={item}
                    onOpenProfileImage={onOpenProfileImage}
                />
            ))}
        </InfiniteScroll>
    </Box>
);

