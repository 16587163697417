import React from 'react';
import { colors } from '../../common/constants';

export const TextDocumentIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 9.49373H5.5V10.4937H6V9.49373ZM14 10.4937H14.5V9.49373H14V10.4937ZM6.00054 13.4911L5.50054 13.4906L5.49946 14.4906L5.99946 14.4911L6.00054 13.4911ZM13.9995 14.4998L14.4995 14.5004L14.5005 13.5004L14.0005 13.4998L13.9995 14.4998ZM6.00033 5.49634L5.50033 5.49601L5.49967 6.49601L5.99967 6.49634L6.00033 5.49634ZM11.333 6.49984L11.833 6.50016L11.8337 5.50016L11.3337 5.49984L11.333 6.49984ZM14 0.666504L14.3536 0.31295L14.2071 0.166504H14V0.666504ZM18 4.6665H18.5V4.4594L18.3536 4.31295L18 4.6665ZM6 10.4937H14V9.49373H6V10.4937ZM5.99946 14.4911L13.9995 14.4998L14.0005 13.4998L6.00054 13.4911L5.99946 14.4911ZM5.99967 6.49634L11.333 6.49984L11.3337 5.49984L6.00033 5.49634L5.99967 6.49634ZM16.6667 18.8332H3.33333V19.8332H16.6667V18.8332ZM2.5 17.9998V1.99984H1.5V17.9998H2.5ZM3.33333 1.1665H14V0.166504H3.33333V1.1665ZM17.5 4.6665V17.9998H18.5V4.6665H17.5ZM13.6464 1.02006L17.6464 5.02006L18.3536 4.31295L14.3536 0.31295L13.6464 1.02006ZM3.33333 18.8332C2.8731 18.8332 2.5 18.4601 2.5 17.9998H1.5C1.5 19.0124 2.32081 19.8332 3.33333 19.8332V18.8332ZM16.6667 19.8332C17.6792 19.8332 18.5 19.0124 18.5 17.9998H17.5C17.5 18.4601 17.1269 18.8332 16.6667 18.8332V19.8332ZM2.5 1.99984C2.5 1.5396 2.8731 1.1665 3.33333 1.1665V0.166504C2.32081 0.166504 1.5 0.987316 1.5 1.99984H2.5Z"
            fill={colors.secondaryFontDarker}
        />
    </svg>
);
