import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { colors } from '../../constants';
import { fonts } from '../../utils';

interface IFeatureBlockProps {
    icon: JSX.Element;
    title: string;
    description: string;
}

export const FeatureBlock: React.FC<IFeatureBlockProps> = ({ description, icon, title }) => (
    <Box>
        {icon}
        <Text
            color={colors.brand}
            fontFamily={fonts.montserrat}
            fontSize="20px"
            lineHeight={{ base: "32px", lg: "28px" }}
            fontWeight="600"
            mb="10px"
            mt="20px"
        >
            {title}
        </Text>
        <Text
            opacity={0.7}
            color={colors.brand}
            fontFamily={fonts.montserrat}
            fontSize="16px"
            fontWeigth={400}
            lineHeight="24px"
        >
            {description}
        </Text>
    </Box>
);
