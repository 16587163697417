import React, { Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { InputGroup } from '@chakra-ui/react';

interface IFileUploadProps {
    mt?: string | number;
    accept?: string;
    children?: ReactNode;
    onChange: (data: File | null) => void;
    disabled?: boolean;
}
export const FileUpload: React.FC<IFileUploadProps> = ({
    mt,
    onChange,
    accept,
    children,
    disabled,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleClick = () => inputRef.current?.click();

    return (
        <InputGroup onClick={handleClick} cursor="pointer" mt={mt}>
            <input
                type="file"
                hidden
                accept={accept}
                ref={inputRef}
                onChange={e => onChange(e!.target!.files![0])}
                disabled={disabled}
            />
            <>{children}</>
        </InputGroup>
    );
};
