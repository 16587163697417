import { createAction } from '@reduxjs/toolkit';
import { CurrentSubscription, SubscriptionHistoryType } from '../types';
import { PayloadError } from '../../../../../common/types';

export const getCurrentSubscriptionRequest = createAction('GET_CURRENT_SUBSCRIPTION_REQUEST');
export const getCurrentSubscriptionSuccess = createAction<CurrentSubscription>(
    'GET_CURRENT_SUBSCRIPTION_SUCCESS',
);
export const getCurrentSubscriptionError = createAction<PayloadError[]>(
    'GET_CURRENT_SUBSCRIPTION_ERROR',
);

export const getSubscriptionsHistoryRequest = createAction('GET_SUBSCRIPTION_HISTORY_REQUEST');
export const getSubscriptionsHistorySuccess = createAction<SubscriptionHistoryType[]>(
    'GET_SUBSCRIPTION_HISTORY_SUCCESS',
);
export const getSubscriptionsHistoryError = createAction<PayloadError[]>(
    'GET_SUBSCRIPTION_HISTORY_ERROR',
);

export const toggleSubscriptionsModal = createAction<boolean>('TOGGLE_SUBSCRIPTIONS_MODAL')
