import React, { useState } from 'react';
import {
    FormControl,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Box,
    Text,
    HStack,
    PinInput,
    PinInputField,
} from '@chakra-ui/react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { CustomButton } from '../../../../../common/components';
import { EmailIcon, BackArrow } from '../../../../../assets/icons';
import { theme } from '../../../../../theme';
import { SendPinCode } from '../../types';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';

interface ISignInEnterCode {
    loading?: boolean;
    email: string;
    onSendPinCode: SubmitHandler<SendPinCode>;
}

interface ITimer {
    minutes: number;
    seconds: number;
    completed: boolean;
}

export const SignInEnterCode: React.FC<ISignInEnterCode> = ({ loading, email, onSendPinCode }) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
    } = useForm<SendPinCode>({});

    const [timerOut, setTimerOut] = useState(false);

    const renderer: React.FC<ITimer> = ({ minutes, seconds, completed }) => {
        if (completed) {
            setTimerOut(true);
            return <></>;
        } else {
            return (
                <span>
                    0{minutes}:{seconds >= 10 ? '' : 0}
                    {seconds}
                </span>
            );
        }
    };

    return (
        <Box
            borderRadius="20px"
            backgroundColor="#FFFFFF"
            p={{ base: '30px', lg: '50px' }}
            display="flex"
            alignItems="center"
            flexDirection="column"
        >
            <form
                onSubmit={handleSubmit(onSendPinCode)}
                onKeyPress={e => {
                    timerOut && e.key === 'Enter' && e.preventDefault();
                }}
            >
                <Box alignSelf={'start'} mt="20px">
                    <Link to="email">
                        <BackArrow />
                    </Link>
                </Box>

                <Stack mt="26px">
                    <Heading
                        fontSize="24px"
                        alignSelf={'start'}
                        color="brand"
                        fontFamily={theme.fonts.body}
                        fontWeight="700"
                        lineHeight="35px"
                    >
                        Change password
                    </Heading>
                    <Text mt="30px" color="brand" fontWeight="300" fontSize="12px">
                        Email
                    </Text>
                    <FormControl isInvalid={!!errors.email}>
                        <InputGroup>
                            <InputLeftElement h="50px" w="50px" children={<EmailIcon />} />
                            <Input
                                type="text"
                                {...register('email', { required: true, value: email })}
                                label="Email"
                                width="100%"
                                placeholder="Enter email"
                                value={email}
                                h="50px"
                                bg="#F6F7FB"
                                borderRadius="80px"
                                color="brand"
                                fontSize="13px"
                                isDisabled
                            />
                        </InputGroup>
                        <Box
                            pt="20px"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <Text
                                fontFamily={theme.fonts.body}
                                color={theme.colors.brand}
                                fontWeight={300}
                                fontSize="12px"
                                lineHeight="18px"
                            >
                                Enter Code We Sent To Your Email
                            </Text>
                            <Text
                                fontFamily={theme.fonts.body}
                                color={theme.colors.green}
                                fontWeight={300}
                                fontSize="12px"
                                lineHeight="18px"
                            >
                                {timerOut ? (
                                    ''
                                ) : (
                                    <Countdown
                                        date={Date.now() + 2 * 60 * 1000}
                                        renderer={renderer}
                                    />
                                )}
                            </Text>
                        </Box>
                        <Box mt="16px">
                            <Controller
                                control={control}
                                name="token"
                                render={({ field: { ref, ...restField } }) => (
                                    <HStack
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                    >
                                        <PinInput placeholder="-" autoFocus={true} {...restField}>
                                            <PinInputField
                                                w="65px"
                                                h="65px"
                                                borderRadius="15px"
                                                ref={ref}
                                            />
                                            <PinInputField w="65px" h="65px" borderRadius="15px" />
                                            <PinInputField w="65px" h="65px" borderRadius="15px" />
                                            <PinInputField w="65px" h="65px" borderRadius="15px" />
                                        </PinInput>
                                    </HStack>
                                )}
                            />
                        </Box>
                    </FormControl>
                    <Box my="24px !important">
                        <CustomButton
                            type="submit"
                            isLoading={loading}
                            loadingText="Loading..."
                            text={timerOut ? 'Time is out' : 'Next'}
                            width="100%"
                            height="50px"
                            bgColor="brand"
                            boxShadow="0px 3px 3px grey"
                            disabled={timerOut}
                        />
                    </Box>
                </Stack>
            </form>
        </Box>
    );
};
