import React from 'react';
import { colors } from '../../common/constants';

export const EditPostIcon: React.FC = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.3335 17.1667H6.66683L15.4168 8.41674C15.8589 7.97471 16.1072 7.3752 16.1072 6.75007C16.1072 6.12495 15.8589 5.52543 15.4168 5.08341C14.9748 4.64138 14.3753 4.39305 13.7502 4.39305C13.125 4.39305 12.5255 4.64138 12.0835 5.08341L3.3335 13.8334V17.1667Z"
            stroke={colors.brand}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.25 5.91673L14.5833 9.25007"
            stroke={colors.brand}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
