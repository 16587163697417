import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {ErrorMessages} from "../../../../../../common/schemes/messages";

export type AmountBalanceFormValue = {
    amountOfMoney: number,
    fundingSourceId: string
};

const AmountBalanceResolver = yup.object().shape({
    amountOfMoney: yup.number()
        .required(ErrorMessages.REQUIRED)
        .positive(ErrorMessages.POSITIVE_NUMBER)
        .typeError(ErrorMessages.NUMBER_INVALID),
    fundingSourceId: yup.string().required(ErrorMessages.REQUIRED),
});
export default yupResolver(AmountBalanceResolver);
