export const tabsLabels = [
    'Balance',
    'Payment Method',
    'Transaction History'
]
export const bankAccountTypes = [
    {id:1, label: 'Checking', value: 'checking' },
    {id:2, label: 'Savings', value: 'savings' },
];

export const fundingSourceDefault = {
    bankName: '',
    dwollaId: '',
    isDefaultDestination: false,
    isDefaultSource: false,
    nickname: '',
    status: '',
    type: '',
};
