import React from 'react';

export const RatesIcon = () => (
    <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.145 18.025C20.1084 17.9127 20.0412 17.8128 19.9509 17.7367C19.8606 17.6605 19.7509 17.6111 19.634 17.594L17.17 17.236L16.07 15.003C16.0177 14.8971 15.9369 14.808 15.8366 14.7457C15.7363 14.6834 15.6206 14.6504 15.5025 14.6504C15.3845 14.6504 15.2687 14.6834 15.1685 14.7457C15.0682 14.808 14.9873 14.8971 14.935 15.003L13.835 17.236L11.371 17.594C11.2541 17.6109 11.1442 17.6602 11.0538 17.7364C10.9634 17.8125 10.8961 17.9124 10.8596 18.0248C10.8231 18.1372 10.8187 18.2576 10.8471 18.3723C10.8754 18.487 10.9354 18.5915 11.02 18.674L12.803 20.412L12.381 22.867C12.361 22.9834 12.3741 23.1032 12.4186 23.2126C12.4632 23.3221 12.5375 23.4169 12.6331 23.4863C12.7287 23.5557 12.8419 23.5969 12.9597 23.6054C13.0776 23.6138 13.1955 23.5891 13.3 23.534L15.5 22.375L17.7 23.534C17.8046 23.5891 17.9224 23.6138 18.0403 23.6054C18.1582 23.5969 18.2713 23.5557 18.3669 23.4863C18.4626 23.4169 18.5369 23.3221 18.5814 23.2126C18.626 23.1032 18.639 22.9834 18.619 22.867L18.198 20.412L19.981 18.672C20.0659 18.5901 20.1262 18.4862 20.1552 18.3719C20.1842 18.2575 20.1807 18.1374 20.145 18.025Z"
            fill="white"
        />
        <path
            d="M9.90697 18.025C9.8704 17.9127 9.80318 17.8128 9.7129 17.7367C9.62261 17.6605 9.51284 17.6111 9.39598 17.594L6.93198 17.236L5.83197 15.003C5.77967 14.8972 5.69881 14.8081 5.59853 14.7458C5.49825 14.6835 5.38254 14.6504 5.26448 14.6504C5.14641 14.6504 5.0307 14.6835 4.93042 14.7458C4.83014 14.8081 4.74928 14.8972 4.69698 15.003L3.59698 17.236L1.13298 17.594C1.01601 17.6109 0.906122 17.6602 0.815741 17.7364C0.72536 17.8125 0.658099 17.9124 0.621572 18.0248C0.585044 18.1372 0.580709 18.2576 0.609058 18.3723C0.637406 18.4871 0.697306 18.5916 0.781976 18.674L2.56498 20.412L2.14398 22.867C2.124 22.9835 2.13703 23.1032 2.18158 23.2126C2.22612 23.3221 2.30042 23.4169 2.39605 23.4863C2.49168 23.5557 2.60484 23.597 2.7227 23.6054C2.84057 23.6138 2.95844 23.5891 3.06298 23.534L5.26298 22.375L7.46298 23.534C7.56751 23.5891 7.68539 23.6138 7.80325 23.6054C7.92111 23.597 8.03427 23.5557 8.1299 23.4863C8.22553 23.4169 8.29983 23.3221 8.34438 23.2126C8.38892 23.1032 8.40195 22.9835 8.38198 22.867L7.96098 20.412L9.75097 18.671C9.8343 18.5884 9.89302 18.4842 9.92057 18.3701C9.94812 18.256 9.94341 18.1365 9.90697 18.025Z"
            fill="white"
        />
        <path
            d="M30.3838 18.025C30.3473 17.9127 30.28 17.8128 30.1897 17.7367C30.0995 17.6605 29.9897 17.6111 29.8729 17.594L27.4088 17.236L26.3088 15.003C26.2566 14.8969 26.1758 14.8075 26.0754 14.745C25.975 14.6825 25.8591 14.6494 25.7408 14.6494C25.6226 14.6494 25.5067 14.6825 25.4063 14.745C25.3059 14.8075 25.225 14.8969 25.1728 15.003L24.0728 17.236L21.6078 17.594C21.4909 17.6109 21.381 17.6602 21.2906 17.7364C21.2002 17.8125 21.133 17.9124 21.0964 18.0248C21.0599 18.1372 21.0556 18.2576 21.0839 18.3723C21.1123 18.4871 21.1722 18.5916 21.2569 18.674L23.0398 20.412L22.6108 22.871C22.5909 22.9875 22.6039 23.1072 22.6484 23.2166C22.693 23.3261 22.7673 23.4209 22.8629 23.4903C22.9585 23.5597 23.0717 23.601 23.1896 23.6094C23.3074 23.6178 23.4253 23.5931 23.5298 23.538L25.7298 22.379L27.9298 23.538C28.0344 23.5931 28.1523 23.6178 28.2701 23.6094C28.388 23.601 28.5011 23.5597 28.5968 23.4903C28.6924 23.4209 28.7667 23.3261 28.8112 23.2166C28.8558 23.1072 28.8688 22.9875 28.8488 22.871L28.4278 20.417L30.2108 18.679C30.2986 18.5975 30.3614 18.4928 30.392 18.377C30.4227 18.2613 30.4198 18.1392 30.3838 18.025Z"
            fill="white"
        />
        <path
            d="M26.588 0.244995H4.41196C3.25921 0.246318 2.15405 0.704833 1.33893 1.51995C0.523805 2.33508 0.0652882 3.44024 0.0639648 4.59299L0.0639648 7.527C0.0651441 8.59833 0.461269 9.63162 1.17656 10.4292C1.89184 11.2268 2.87608 11.7326 3.94096 11.85L4.88096 13.103C5.0322 13.3058 5.22816 13.4711 5.45362 13.5859C5.67908 13.7007 5.92797 13.762 6.18096 13.765H6.20796C6.45646 13.7656 6.70187 13.71 6.92581 13.6023C7.14974 13.4946 7.34637 13.3375 7.50096 13.143L8.51596 11.875H26.588C27.7407 11.8737 28.8459 11.4152 29.661 10.6C30.4761 9.78491 30.9346 8.67975 30.936 7.527V4.59299C30.9346 3.44024 30.4761 2.33508 29.661 1.51995C28.8459 0.704833 27.7407 0.246318 26.588 0.244995ZM13.564 8.746H5.32796C5.08808 8.746 4.85801 8.6507 4.68839 8.48108C4.51876 8.31145 4.42346 8.08138 4.42346 7.8415C4.42346 7.60161 4.51876 7.37154 4.68839 7.20192C4.85801 7.03229 5.08808 6.93699 5.32796 6.93699H13.564C13.8039 6.93699 14.0339 7.03229 14.2035 7.20192C14.3732 7.37154 14.4685 7.60161 14.4685 7.8415C14.4685 8.08138 14.3732 8.31145 14.2035 8.48108C14.0339 8.6507 13.8039 8.746 13.564 8.746ZM25.676 5.109H5.32796C5.08808 5.109 4.85801 5.0137 4.68839 4.84407C4.51876 4.67445 4.42346 4.44438 4.42346 4.20449C4.42346 3.96461 4.51876 3.73454 4.68839 3.56491C4.85801 3.39529 5.08808 3.29999 5.32796 3.29999H25.672C25.9119 3.29999 26.1419 3.39529 26.3115 3.56491C26.4812 3.73454 26.5765 3.96461 26.5765 4.20449C26.5765 4.44438 26.4812 4.67445 26.3115 4.84407C26.1419 5.0137 25.9119 5.109 25.672 5.109H25.676Z"
            fill="white"
        />
    </svg>
);
