import React from 'react';
import { colors } from '../../common/constants';

type LocationIconType = {
    height?: string;
    width?: string;
    color?: string;
};

export const LocationIcon: React.FC<LocationIconType> = ({
    height,
    width,
    color = colors.brand,
}) => (
    <svg
        width={width || '13'}
        height={height || '13'}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ flexShrink: 0 }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.85482 5.68782C7.85482 4.93963 7.24856 4.33337 6.50093 4.33337C5.75274 4.33337 5.14648 4.93963 5.14648 5.68782C5.14648 6.43545 5.75274 7.04171 6.50093 7.04171C7.24856 7.04171 7.85482 6.43545 7.85482 5.68782Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.49974 11.375C5.85056 11.375 2.4375 8.61162 2.4375 5.72178C2.4375 3.45943 4.25593 1.625 6.49974 1.625C8.74354 1.625 10.5625 3.45943 10.5625 5.72178C10.5625 8.61162 7.14891 11.375 6.49974 11.375Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
