import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, Button, IconButton } from '@chakra-ui/react';
import { BackArrow, EditIcon } from '../../../assets/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {
    addLicenseRequest,
    getLicenseDraftRequest,
    getLicensesRequest,
    saveLicenseDraftRequest,
} from '../store/actions';
import { LicenseEditorModal } from '../components';
import { getCurrentStates } from '../../../store/actions/order.actions';
import {
    AddLicensePayload,
    SaveLicenseDraftPayload,
    LicenseType,
    LicenseDraftType,
} from '../types';
import { getStates } from '../../../store/actions/directories.actions';
import { signUpGetAgencies } from '../../AuthPages/SignUp/store/actions';
import { useDelta } from 'react-delta';

export const Account: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const states = useSelector((state: RootStore) => state.directories.states);
    const licenses = useSelector((state: RootStore) => state.settings.licenses);
    const draftLicense = useSelector((state: RootStore) => state.settings.draftLicense);
    const agencies = useSelector((state: RootStore) => state.signUp.agencies);
    const accessToken = useSelector((state: RootStore) => state.auth.user.token);
    const { loading: addLicenseLoading, errors: addLicenseErrors } = useSelector(
        (state: RootStore) => state.settings.addLicense,
    );
    const { loading: saveDraftLoading, errors: saveDraftErrors } = useSelector(
        (state: RootStore) => state.settings.saveDraft,
    );

    const addLicenseLoadingDelta = useDelta(addLicenseLoading);
    const saveDraftLoadingDelta = useDelta(saveDraftLoading);

    const saveLicenseDraft = (payload: SaveLicenseDraftPayload) =>
        dispatch(saveLicenseDraftRequest(payload));
    const addLicense = (payload: AddLicensePayload) => dispatch(addLicenseRequest(payload));

    const [showLicenseEditorModal, setShowLicenseEditorModal] = useState(false);
    const [licenseItem, setLicenseItem] = useState<any>({});
    const [modalType, setModalType] = useState('new');
    const [state, setState] = useState('');

    useEffect(() => {
        if (addLicenseLoadingDelta && addLicenseLoadingDelta.prev) {
            if (
                addLicenseLoadingDelta.prev &&
                !addLicenseLoadingDelta.curr &&
                !addLicenseErrors.length
            ) {
                setShowLicenseEditorModal(false);
                setLicenseItem({});
                dispatch(getLicensesRequest());
                dispatch(getLicenseDraftRequest());
            }
        }
    }, [addLicenseLoadingDelta]);

    useEffect(() => {
        if (saveDraftLoadingDelta && saveDraftLoadingDelta.prev) {
            if (
                saveDraftLoadingDelta.prev &&
                !saveDraftLoadingDelta.curr &&
                !saveDraftErrors.length
            ) {
                setShowLicenseEditorModal(false);
                setLicenseItem({});
            }
        }
    }, [saveDraftLoadingDelta]);

    const handleChangeLicense = (item: LicenseType) => {
        setShowLicenseEditorModal(true);
        setLicenseItem(item);
        setState(item.state);
        setModalType('existing');
    };

    const handleAddLicense = () => {
        setShowLicenseEditorModal(true);
        setModalType('new');
    };

    const handleChangeDraft = (item: LicenseDraftType) => {
        setShowLicenseEditorModal(true);
        setLicenseItem(item);
        setState(item.state);
        setModalType('draft');
    };

    useEffect(() => {
        dispatch(getLicensesRequest());
        dispatch(getLicenseDraftRequest());
    }, []);

    useEffect(() => {
        // dispatch(getCurrentStates());
        dispatch(getStates());
    }, []);

    useEffect(() => {
        console.log(state, 'state');
        if (state.length !== 0) {
            const stateId = states.find((st: any) => st.name === state)?.id || 0;
            console.log(stateId, state, 'statesIds');
            dispatch(signUpGetAgencies({ token: accessToken.accessToken, stateId: stateId }));
        }
    }, [state]);

    return (
        <Flex direction="column" pt="20px" px="20px">
            <Flex justify="space-between" align="center" mb="40px" width="100%">
                <IconButton onClick={history.goBack} aria-label="back" variant="unstyled">
                    <BackArrow />
                </IconButton>
                <Text color="brand" fontSize="22px" fontWeight="700">
                    Account
                </Text>
                <Box w="40px" />
            </Flex>
            <Flex direction="column">
                {draftLicense && (
                    <Flex
                        justify="space-between"
                        align="center"
                        py="30px"
                        borderBottom="1px solid #D6D8E0"
                        width="100%"
                    >
                        <Text color="brand" fontSize="18px" fontWeight="500">
                            {`${draftLicense?.agencyName} (Draft)` || 'Draft'}
                        </Text>
                        <Button
                            variant="outline"
                            mr="15px"
                            width="150px"
                            onClick={() => handleChangeDraft(draftLicense)}
                        >
                            <EditIcon color="#888DAB" />
                            <Text
                                ml="10px"
                                fontSize="16px"
                                fontWeight="300"
                                color="secondaryFontDarker"
                            >
                                Change
                            </Text>
                        </Button>
                    </Flex>
                )}
                {licenses?.map((item: LicenseType, index: number) => (
                    <Flex
                        justify="space-between"
                        align="center"
                        py="30px"
                        borderBottom="1px solid #D6D8E0"
                        width="100%"
                        key={index}
                    >
                        <Text color="brand" fontSize="18px" fontWeight="500">
                            {item?.agencyName}
                        </Text>
                        {!item?.isVerified ? (
                            <Button
                                variant="outline"
                                mr="15px"
                                width="150px"
                                onClick={() => handleChangeLicense(item)}
                            >
                                <EditIcon color="#888DAB" />
                                <Text
                                    ml="10px"
                                    fontSize="16px"
                                    fontWeight="300"
                                    color="secondaryFontDarker"
                                >
                                    Change
                                </Text>
                            </Button>
                        ) : (
                            <Text fontSize="15px" fontWeight="400" color="orange">
                                Verification processing
                            </Text>
                        )}
                    </Flex>
                ))}
                <Button
                    mt="50px"
                    variant="outline"
                    h="50px"
                    mr="15px"
                    width="150px"
                    onClick={handleAddLicense}
                >
                    <Text ml="10px" fontSize="16px" fontWeight="300" color="secondaryFontDarker">
                        +{''} Add License
                    </Text>
                </Button>
            </Flex>
            <LicenseEditorModal
                isOpen={showLicenseEditorModal}
                onClose={() => {
                    setShowLicenseEditorModal(false);
                    setLicenseItem({});
                }}
                modalType={modalType}
                license={licenseItem}
                saveLicenseDraft={saveLicenseDraft}
                draft={draftLicense}
                agencies={agencies}
                states={states}
                setState={setState}
                state={state}
                addLicense={addLicense}
                addLicenseLoading={addLicenseLoading}
                saveDraftLoading={saveDraftLoading}
            />
        </Flex>
    );
};
