import React from 'react';
import { colors } from '../../common/constants';

export const Disconnect: React.FC = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 6.5L1.32843 9.67157C0.797994 10.202 0.5 10.9214 0.5 11.6716C0.5 13.2337 1.76633 14.5 3.32843 14.5C4.07857 14.5 4.79799 14.202 5.32843 13.6716L8.5 10.5M10.5 8.5L13.6716 5.32843C14.202 4.79799 14.5 4.07857 14.5 3.32843C14.5 1.76633 13.2337 0.5 11.6716 0.5C10.9214 0.5 10.202 0.797993 9.67157 1.32843L6.5 4.5M4.5 10.5L10.5 4.5M3 4.5H0M4.5 0V3M10.5 12V15M12 10.5H15"
            stroke={colors.secondaryFontDarker}
        />
    </svg>
);
