import React from 'react';
import { colors } from '../../common/constants';

export const CalendarIcon: React.FC = () => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.02148 15.2439H12.6585C13.1945 15.2435 13.7085 15.0304 14.0875 14.6514C14.4665 14.2724 14.6796 13.7585 14.68 13.2224V3.15008C14.6796 2.61407 14.4665 2.10013 14.0875 1.72111C13.7085 1.3421 13.1945 1.12899 12.6585 1.1286H11.8551V0.564673C11.8551 0.414912 11.7957 0.271285 11.6898 0.165389C11.5839 0.0594921 11.4402 0 11.2905 0C11.1407 0 10.9971 0.0594921 10.8912 0.165389C10.7853 0.271285 10.7258 0.414912 10.7258 0.564673V1.12935H3.95047V0.564673C3.95047 0.414912 3.89098 0.271285 3.78508 0.165389C3.67919 0.0594921 3.53556 0 3.3858 0C3.23604 0 3.09241 0.0594921 2.98652 0.165389C2.88062 0.271285 2.82113 0.414912 2.82113 0.564673V1.12935H2.02148C1.48547 1.12974 0.971532 1.34284 0.592515 1.72186C0.213499 2.10088 0.000395142 2.61482 0 3.15083V13.221C0.000395142 13.757 0.213499 14.2709 0.592515 14.6499C0.971532 15.0289 1.48547 15.242 2.02148 15.2424V15.2439ZM1.12636 3.15008C1.12636 2.91268 1.22067 2.685 1.38854 2.51714C1.55641 2.34927 1.78408 2.25496 2.02148 2.25496H2.82113V2.81963C2.82113 2.96939 2.88062 3.11302 2.98652 3.21892C3.09241 3.32481 3.23604 3.38431 3.3858 3.38431C3.53556 3.38431 3.67919 3.32481 3.78508 3.21892C3.89098 3.11302 3.95047 2.96939 3.95047 2.81963V2.25869H10.7258V2.82336C10.7258 2.97312 10.7853 3.11675 10.8912 3.22265C10.9971 3.32854 11.1407 3.38804 11.2905 3.38804C11.4402 3.38804 11.5839 3.32854 11.6898 3.22265C11.7957 3.11675 11.8551 2.97312 11.8551 2.82336V2.25869H12.657C12.8944 2.25869 13.1221 2.353 13.29 2.52087C13.4578 2.68873 13.5521 2.91641 13.5521 3.15381V13.2239C13.5521 13.4613 13.4578 13.689 13.29 13.8569C13.1221 14.0247 12.8944 14.1191 12.657 14.1191H2.02148C1.78408 14.1191 1.55641 14.0247 1.38854 13.8569C1.22067 13.689 1.12636 13.4613 1.12636 13.2239V3.15008Z"
            fill={colors.brand}
        />
        <path
            d="M4.09237 6.49261C4.4821 6.49261 4.79803 6.17668 4.79803 5.78695C4.79803 5.39723 4.4821 5.0813 4.09237 5.0813C3.70265 5.0813 3.38672 5.39723 3.38672 5.78695C3.38672 6.17668 3.70265 6.49261 4.09237 6.49261Z"
            fill={colors.brand}
        />
        <path
            d="M7.19784 6.49261C7.58756 6.49261 7.9035 6.17668 7.9035 5.78695C7.9035 5.39723 7.58756 5.0813 7.19784 5.0813C6.80812 5.0813 6.49219 5.39723 6.49219 5.78695C6.49219 6.17668 6.80812 6.49261 7.19784 6.49261Z"
            fill={colors.brand}
        />
        <path
            d="M10.3014 6.49261C10.6911 6.49261 11.007 6.17668 11.007 5.78695C11.007 5.39723 10.6911 5.0813 10.3014 5.0813C9.91164 5.0813 9.5957 5.39723 9.5957 5.78695C9.5957 6.17668 9.91164 6.49261 10.3014 6.49261Z"
            fill={colors.brand}
        />
        <path
            d="M4.09237 9.31536C4.4821 9.31536 4.79803 8.99943 4.79803 8.60971C4.79803 8.21998 4.4821 7.90405 4.09237 7.90405C3.70265 7.90405 3.38672 8.21998 3.38672 8.60971C3.38672 8.99943 3.70265 9.31536 4.09237 9.31536Z"
            fill={colors.brand}
        />
        <path
            d="M7.19784 9.31536C7.58756 9.31536 7.9035 8.99943 7.9035 8.60971C7.9035 8.21998 7.58756 7.90405 7.19784 7.90405C6.80812 7.90405 6.49219 8.21998 6.49219 8.60971C6.49219 8.99943 6.80812 9.31536 7.19784 9.31536Z"
            fill={colors.brand}
        />
        <path
            d="M10.3014 9.31536C10.6911 9.31536 11.007 8.99943 11.007 8.60971C11.007 8.21998 10.6911 7.90405 10.3014 7.90405C9.91164 7.90405 9.5957 8.21998 9.5957 8.60971C9.5957 8.99943 9.91164 9.31536 10.3014 9.31536Z"
            fill={colors.brand}
        />
        <path
            d="M4.09237 12.1386C4.4821 12.1386 4.79803 11.8227 4.79803 11.4329C4.79803 11.0432 4.4821 10.7273 4.09237 10.7273C3.70265 10.7273 3.38672 11.0432 3.38672 11.4329C3.38672 11.8227 3.70265 12.1386 4.09237 12.1386Z"
            fill={colors.brand}
        />
        <path
            d="M7.19784 12.1386C7.58756 12.1386 7.9035 11.8227 7.9035 11.4329C7.9035 11.0432 7.58756 10.7273 7.19784 10.7273C6.80812 10.7273 6.49219 11.0432 6.49219 11.4329C6.49219 11.8227 6.80812 12.1386 7.19784 12.1386Z"
            fill={colors.brand}
        />
        <path
            d="M10.3014 12.1386C10.6911 12.1386 11.007 11.8227 11.007 11.4329C11.007 11.0432 10.6911 10.7273 10.3014 10.7273C9.91164 10.7273 9.5957 11.0432 9.5957 11.4329C9.5957 11.8227 9.91164 12.1386 10.3014 12.1386Z"
            fill={colors.brand}
        />
    </svg>
);
