import React from 'react';
import { Box, Text, Flex, Checkbox, Input } from '@chakra-ui/react';
import { Controller, FieldValues, FieldArrayWithId, Control } from 'react-hook-form';
import { IFinishForm } from '../PropertiesSelectionConfirm';

interface IAddressItemProps {
    index: number;
    address: FieldArrayWithId<IFinishForm, 'requestAddresses', 'address.id'>;
    control: Control<IFinishForm, object>;
    requestId: number;
}

export const AddressItem: React.FC<IAddressItemProps> = ({
    index,
    address,
    control,
    requestId,
}) => {
    return (
        <Box>
            <Box borderBottom="1px solid #D1D2DB" py="15px" mb="10px">
                <Text color="brand" fontSize="13px" fontWeight="700" lineHeight="20px">
                    Address {index + 1}
                </Text>
                <Controller
                    render={({ field }) => (
                        <Flex justify="space-between">
                            <Text
                                color="rgba(44, 51, 92, 0.55)"
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight={400}
                                textDecorationLine={field.value ? 'line-through' : 'none'}
                            >
                                {address.name}
                            </Text>
                            <Checkbox
                                isChecked={field.value}
                                onChange={ev => field.onChange(ev.target.checked)}
                            />
                        </Flex>
                    )}
                    name={`requestAddresses.${index}.isCompleted`}
                    defaultValue={address.isCompleted}
                    control={control}
                />
            </Box>
            <Controller
                render={({ field }) => (
                    <Input
                        display="none"
                        value={field.value}
                        onChangeText={(text: string) => field.onChange(text)}
                    />
                )}
                name={`requestAddresses.${index}.id`}
                defaultValue={address.id}
                control={control}
            />
            <Controller
                render={({ field }) => (
                    <Input
                        display="none"
                        value={field.value}
                        onChangeText={(text: string) => field.onChange(text)}
                    />
                )}
                name={`requestAddresses.${index}.name`}
                defaultValue={address.name}
                control={control}
            />
            <Controller
                render={({ field }) => (
                    <Input
                        display="none"
                        value={field.value}
                        onChangeText={(text: string) => field.onChange(text)}
                    />
                )}
                name={`requestAddresses.${index}.requestId`}
                defaultValue={requestId}
                control={control}
            />
        </Box>
    );
};
