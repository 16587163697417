import React from 'react';
import { Container, Box, Center } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LogoIcon } from '../../../../assets/icons';
import BottomCity from '../../../../assets/img/Bottom.png';

interface IAuthorization {
    child: React.ReactNode;
    isSignIn?: boolean | undefined;
}

export const AuthWrapper: React.FC<IAuthorization> = ({ child, isSignIn }) => (
    <Box
        backgroundColor="brand"
        backgroundImage={`url(${BottomCity})`}
        bgPosition="bottom"
        bgRepeat="no-repeat"
        h="100vh"
        // minH="100vh"
        // h="auto"
        overflowY="auto"
        py={{ base: '50px', lg: '85px' }}
    >
        <Container
            maxW="container.md"
            d="flex"
            // h="100%"
            // py={{ base: '50px', lg: '85px' }}
            flexDirection="column"
            alignItems={{ base: 'center', lg: 'baseline' }}
        >
            <Link to={isSignIn ? '/ ' : '/auth'} replace>
                <LogoIcon />
            </Link>
            <Center w="100%" h="100%">
                {child}
            </Center>
        </Container>
    </Box>
);
