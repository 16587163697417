import React from 'react';
import { colors } from '../../common/constants';

export const PicImageIcon: React.FC = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M53.75 12H16.25C12.805 12 10 14.805 10 18.25V45.75C10 49.195 12.805 52 16.25 52H53.75C57.195 52 60 49.195 60 45.75V18.25C60 14.805 57.195 12 53.75 12ZM16.25 17H53.75C54.44 17 55 17.56 55 18.25V35.9975L47.1025 26.7825C46.265 25.8 45.0525 25.275 43.75 25.245C42.455 25.2525 41.24 25.8275 40.41 26.8225L31.125 37.9675L28.1 34.95C26.39 33.24 23.6075 33.24 21.9 34.95L15 41.8475V18.25C15 17.56 15.56 17 16.25 17Z"
            fill="#F1F2F9"
        />
        <path
            d="M16.25 17H53.75C54.44 17 55 17.56 55 18.25V35.9975L47.1025 26.7825C46.265 25.8 45.0525 25.275 43.75 25.245C42.455 25.2525 41.24 25.8275 40.41 26.8225L31.125 37.9675L28.1 34.95C26.39 33.24 23.6075 33.24 21.9 34.95L15 41.8475V18.25C15 17.56 15.56 17 16.25 17Z"
            fill="#F1F2F9"
        />
        <path
            d="M50 48H10C7.2425 48 5 45.7575 5 43V13C5 10.2425 7.2425 8 10 8H50C52.7575 8 55 10.2425 55 13V43C55 45.7575 52.7575 48 50 48ZM10 10.5C8.6225 10.5 7.5 11.6225 7.5 13V43C7.5 44.3775 8.6225 45.5 10 45.5H50C51.3775 45.5 52.5 44.3775 52.5 43V13C52.5 11.6225 51.3775 10.5 50 10.5H10Z"
            fill={colors.brand}
        />
        <path
            d="M17.5 25.5C14.7425 25.5 12.5 23.2575 12.5 20.5C12.5 17.7425 14.7425 15.5 17.5 15.5C20.2575 15.5 22.5 17.7425 22.5 20.5C22.5 23.2575 20.2575 25.5 17.5 25.5ZM17.5 18C16.1225 18 15 19.1225 15 20.5C15 21.8775 16.1225 23 17.5 23C18.8775 23 20 21.8775 20 20.5C20 19.1225 18.8775 18 17.5 18Z"
            fill={colors.brand}
        />
        <path
            d="M6.42494 45.325C6.10494 45.325 5.78494 45.2025 5.53994 44.96C5.05244 44.4725 5.05244 43.68 5.53994 43.1925L17.3474 31.385C18.7624 29.97 21.235 29.97 22.65 31.385L26.165 34.9L35.895 23.225C36.6025 22.3775 37.6425 21.885 38.75 21.875H38.7775C39.8725 21.875 40.91 22.35 41.625 23.1825L54.7 38.4375C55.15 38.96 55.09 39.75 54.565 40.2C54.0425 40.65 53.255 40.5925 52.8025 40.065L39.7275 24.81C39.485 24.53 39.15 24.375 38.7775 24.375C38.5175 24.3525 38.06 24.5325 37.8175 24.825L27.21 37.5525C26.985 37.8225 26.6575 37.985 26.305 38C25.95 38.025 25.6125 37.885 25.365 37.635L20.8824 33.1525C20.4099 32.6825 19.5874 32.6825 19.1149 33.1525L7.30744 44.96C7.06494 45.2025 6.74494 45.325 6.42494 45.325Z"
            fill={colors.brand}
        />
    </svg>
);
