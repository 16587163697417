import React, { useMemo, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Stack,
    Text,
} from '@chakra-ui/react';
import { DropdownArrowIcon, SearchIcon } from '../../../../assets/icons';

type Item = {
    value: string | number;
    label: string;
    county?: string;
};

interface IProps {
    items: Array<Item>;
    onChange?: (value: string | number) => void;
    loading?: boolean;
    error?: string;
    label?: string;
    disabled?: boolean;
    placeHolder?: string;
    height?: string;
    isAgencyChoise?: boolean;
    onOpenAgencyModal?: (payload: boolean) => void;
    width?: string;
    type?: string;
    setTerm?: (e: string) => void;
    boxWidth?: string;
    minW?: string;
}

export const CustomSearchableSelect: React.FC<IProps> = ({
    items,
    disabled,
    error,
    label,
    loading,
    onChange,
    placeHolder,
    height = '50px',
    isAgencyChoise,
    onOpenAgencyModal,
    width,
    type,
    setTerm,
    boxWidth,
    minW,
}) => {
    const [selectedValue, setSelectedValue] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    // const initialFocusRef = React.useRef(null)
    // initialFocusRef?.current?.offsetWidth
    const _items = useMemo(() => {
        return items.filter(it => it.label.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [searchTerm, items]);

    return (
        <Box width={boxWidth}>
            {label ? (
                <Text color="brand" fontWeight="300" fontSize="13px">
                    {label}
                </Text>
            ) : null}

            <Popover isLazy>
                {({ isOpen, onClose }) => {
                    return (
                        <>
                            <PopoverTrigger>
                                <Button
                                    disabled={disabled}
                                    isLoading={loading}
                                    justifyContent={loading ? 'center' : 'space-between'}
                                    border={error ? '2px solid #E53E3F' : 'none'}
                                    isFullWidth
                                    fontWeight={400}
                                    color="brand"
                                    fontSize="14px"
                                    backgroundColor={'#F6F7FB'}
                                    borderRadius={'80px'}
                                    h={height}
                                    rightIcon={<DropdownArrowIcon />}
                                    w={width}
                                    minW={minW}
                                >
                                    {selectedValue ? selectedValue : 'Select'}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent
                                rootProps={{
                                    zIndex: 1000,
                                }}
                                p={{ base: '10px', lg: '25px' }}
                                backgroundColor={'white'}
                                maxW={'352px'}
                                maxHeight={'316px'}
                                overflow={'auto'}
                            >
                                <PopoverHeader border={'none'} p="0">
                                    {/*INPUT*/}
                                    <InputGroup>
                                        <InputLeftElement children={<SearchIcon />} />
                                        <Input
                                            onChange={e => {
                                                setSearchTerm(e.target.value);
                                                if (type && type === 'open house' && setTerm) {
                                                    setTerm(e.target.value);
                                                }
                                            }}
                                            value={searchTerm}
                                            placeholder={placeHolder || 'Search...'}
                                        />
                                    </InputGroup>
                                </PopoverHeader>

                                <PopoverBody zIndex={100} pt={'20px'} px={'0px'}>
                                    <Stack spacing={'20px'}>
                                        {_items && _items.length !== 0 ? (
                                            _items.map((it, index) => (
                                                <Flex
                                                    cursor={'pointer'}
                                                    onClick={(e) => {
                                                        if (it.label === selectedValue) {
                                                            setSelectedValue("");
                                                            onChange?.(0);
                                                        } else {
                                                            setSelectedValue(it.label);
                                                            onChange?.(it.value);
                                                        }
                                                        onClose();
                                                    }}
                                                    key={index.toString()}
                                                    alignItems={'center'}
                                                >
                                                    <Flex
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                        mr={'10px'}
                                                        w={'24px'}
                                                        h={'24px'}
                                                        borderRadius={'12px'}
                                                        flexShrink={0}
                                                        border={'1px solid #2C335C'}
                                                    >
                                                        {selectedValue === it.label ? (
                                                            <Box
                                                                borderRadius={'100%'}
                                                                w={'12px'}
                                                                h={'12px'}
                                                                backgroundColor="brand"
                                                            />
                                                        ) : null}
                                                    </Flex>
                                                    <Text
                                                        fontSize={'14px'}
                                                        fontWeight={400}
                                                        isTruncated
                                                        color="brand"
                                                    >
                                                        {it.county
                                                            ? `${it.label} (${it.county})`
                                                            : it.label}
                                                    </Text>
                                                </Flex>
                                            ))
                                        ) : (
                                            <Text align={'center'} color="secondaryFontDarker">
                                                No Options
                                            </Text>
                                        )}
                                    </Stack>
                                </PopoverBody>
                                <PopoverFooter>
                                    {isAgencyChoise && (
                                        <Text
                                            onClick={() => {
                                                console.log('OPEN MODAL ADD AGENCY');
                                                if (onOpenAgencyModal) {
                                                    onOpenAgencyModal(true);
                                                }
                                                onClose();
                                            }}
                                            cursor={'pointer'}
                                            color="brand"
                                            textTransform={'capitalize'}
                                            textDecoration={'underline'}
                                        >
                                            Can not find my agency
                                        </Text>
                                    )}
                                </PopoverFooter>
                            </PopoverContent>
                            {error && (
                                <Text mt={'5px'} fontSize="12px" color="chakraErrorRed">
                                    {error}
                                </Text>
                            )}
                        </>
                    );
                }}
            </Popover>
        </Box>
    );
};
