import React, { useEffect, useState } from 'react';
import {
    ButtonGroup,
    Box,
    Divider,
    Avatar,
    MenuButton,
    Button,
    MenuList,
    MenuItem,
    Menu,
    Text,
    Container,
    Flex,
} from '@chakra-ui/react';
import { NavLink, useHistory } from 'react-router-dom';
import { Home, Messages, LogoutIcon } from '../../../../assets/icons';
import { ButtonWithNotification } from '../ButtonWithNotification/ButtonWithNotification';
import { logout } from '../../../../store/actions/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { SidebarDropdown } from '../SidebarDropdown/SidebarDropdown';
import { toggleUserProfileModal } from '../../../../store/actions/profile.actions';
import { SetCurrentAgencyPayloadType } from '../../../../pages/Sidebar/types';
import { setCurrentAgencyRequest } from '../../../../pages/Sidebar/store/actions';
import { getUserInfoRequest } from '../../../../store/actions/user.actions';
import { getAvatarPicture } from '../../../../common/utils';
import { LogoIconDashboard } from '../../../../assets/icons/LogoDashboard';
import { NotificationsPortal } from './components';
import { getTwillioToken } from '../../../../pages/Messages/utils';
import { Message } from '@twilio/conversations/lib/message';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { SearchUsersContainer } from '../SearchUsers/SearchUsersContainer';

const Conversations = require('@twilio/conversations');

export const DashboardHeader: React.FC = () => {
    const dispatch = useDispatch();
    const onLogout = () => dispatch(logout());
    const onSetCurrentAgency = (payload: SetCurrentAgencyPayloadType) =>
        dispatch(setCurrentAgencyRequest(payload));

    const currentUser = useSelector((state: RootStore) => state.auth.user);
    const agencies = useSelector((state: RootStore) => state.user.user.agencies);
    const token = useSelector((state: RootStore) => state.auth.user.token.accessToken);
    const currentAgency = useSelector((state: RootStore) => state.user.user.currentAgency);

    const { value: accountStatus } = useSelector(
        (state: RootStore) => state.payments.getCustomerAccountStatus,
    );
    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed =
        roles?.some((role: string) => role === 'user') && accountStatus === 'verified';

    const history = useHistory();
    const handleSettingsClick = () => history.push('/settings');
    const handleSettingsHelp = () => history.push('/help');

    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [messageNotification, setMessageNotification] = useState<boolean>(false);

    const initConversations = async (token: string) => {
        try {
            const conversationsClient = await Conversations.Client.create(token);
            conversationsClient.on('conversationJoined', (conversation: Conversation) => {
                setConversations((prevState: Conversation[]) => {
                    return [...prevState, conversation];
                });
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        dispatch(getUserInfoRequest());
    }, []);

    useEffect(() => {
        getTwillioToken(token, initConversations).catch(console.log);
    }, []);

    useEffect(() => {
        conversations.forEach((item: Conversation) => {
            item.getMessages()
                .then((messagePaginator: any) => {
                    const message = messagePaginator.items[messagePaginator.items.length - 1];
                    item.getUnreadMessagesCount().then((res: number | null) => {
                        if (Number(message?.author) !== currentUser.id && res) {
                            setMessageNotification(true);
                        }
                    });
                })
                .catch((err: any) => {
                    console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                });
            item.on('messageAdded', (m: Message) => {
                setMessageNotification(true);
            });
        });
    }, [conversations]);

    return (
        <>
            <Container
                d="flex"
                maxW="container.xl"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                h="80px"
            >
                <NavLink to="/app" replace>
                    <LogoIconDashboard />
                </NavLink>
                <ButtonGroup spacing="25px" d="flex" flexDirection="row" alignItems="center">
                    <SearchUsersContainer />
                    <NavLink to="/app">
                        <ButtonWithNotification buttonIcon={<Home width="20" height="20" />} />
                    </NavLink>
                    <NavLink to="/messages/none">
                        <ButtonWithNotification
                            buttonIcon={<Messages width="20" height="20" />}
                            notification={messageNotification}
                        />
                    </NavLink>
                    <NotificationsPortal isAccessAllowed={isAccessAllowed} />
                    <Menu id="headerMenu">
                        <MenuButton as={Button} rounded={'full'} variant={'link'} minW={0}>
                            <Avatar
                                h="50px"
                                w="50px"
                                src={getAvatarPicture(currentUser.avatar)}
                                name={currentUser.firstName + ' ' + currentUser.lastName}
                            />
                        </MenuButton>
                        <MenuList w="300px" zIndex={10}>
                            <MenuItem
                                justifyContent="flex-start"
                                px="20px"
                                onClick={() =>
                                    dispatch(
                                        toggleUserProfileModal({
                                            value: true,
                                            userId: currentUser.id,
                                        }),
                                    )
                                }
                            >
                                <Avatar
                                    h="50px"
                                    w="50px"
                                    src={getAvatarPicture(currentUser.avatar)}
                                    name={currentUser.firstName + ' ' + currentUser.lastName}
                                />
                                <Text
                                    ml="10px"
                                    fontSize="16px"
                                    color="brand"
                                    textShadow="0px 3px 10px"
                                >
                                    {currentUser.firstName + ' ' + currentUser.lastName}
                                </Text>
                            </MenuItem>
                            <Flex justifyContent="center" alignItems="center" mt="10px" px="20px">
                                <SidebarDropdown
                                    width="100%"
                                    maxW="100%"
                                    height="50px"
                                    border="1px solid #D1D2DB"
                                    borderRadius={'15px'}
                                    options={agencies}
                                    token={token}
                                    onSetCurrentAgency={onSetCurrentAgency}
                                    currentAgency={currentAgency}
                                />
                            </Flex>
                            <MenuItem
                                onClick={handleSettingsClick}
                                mt="20px"
                                _hover={{
                                    color: '#EB5757',
                                }}
                                fontSize="15px"
                                color="brand"
                                px="20px"
                            >
                                Profile Settings
                            </MenuItem>
                            <MenuItem
                                onClick={handleSettingsHelp}
                                _hover={{
                                    color: '#EB5757',
                                }}
                                fontSize="15px"
                                color="brand"
                                px="20px"
                            >
                                Help
                            </MenuItem>
                            <MenuItem onClick={onLogout} fontSize="15px" color="#EB5757" px="20px">
                                Log Out
                                <Box ml={2}>
                                    <LogoutIcon />
                                </Box>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </ButtonGroup>
            </Container>
            <Divider />
        </>
    );
};
