import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const editPasswordSchema = yup.object().shape({
    newPassword: yup
        .string()
        .min(8, ErrorMessages.SHORT_PASSWORD)
        .required(ErrorMessages.REQUIRED)
        .matches(/\d/, ErrorMessages.PASSWORD_DIGIT)
        .matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE)
        .matches(/\W/g, ErrorMessages.NON_ALPHANUMERIC_MISSING)
        .test('unique-chars', ErrorMessages.MINIMUM_4_CHARS, function (value?: string) {
            return new Set(value?.split('')).size >= 4;
        }),
    confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const editPasswordResolver = yupResolver(editPasswordSchema);
