import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Divider, Center, HStack, SimpleGrid } from '@chakra-ui/react';
import { ApplicantItem } from '../components';
import { InfiniteScroll, Loader } from '../../../../../common/components';
import { ApplicantType } from '../../../types';
import { useDispatch } from 'react-redux';
import { confirmApplicant, rejectApplicantRequest } from '../../../store/actions';
import { ConfirmApplicantModal } from '../../../../../common/components/modals';
import { ApplicantToSubmitType } from '../../../types';
import { roundAplicantPrices } from '../../../../../common/utils';

interface IApplicantsProps {
    items: ApplicantType[];
    partnerApplicants: ApplicantType[];
    loading: boolean;
    requestId: number;
}

export const Applicants: React.FC<IApplicantsProps> = ({
    items,
    loading,
    partnerApplicants,
    requestId,
}) => {
    const dispatch = useDispatch();

    const [confirmApplicationConfig, setConfirmApplicationConfig] =
        useState<null | ApplicantToSubmitType>(null);
    const [confirmApplicationModalOpen, setConfirmApplicationModalOpen] = useState(false);
    const onConfirmApplicant = (applicantId: number) =>
        dispatch(confirmApplicant({ applicantId, requestId }));

    const onRejectApplicant = (applicantId: number) =>
        dispatch(rejectApplicantRequest({ applicantId, requestId }));

    useEffect(() => {
        if (confirmApplicationConfig !== null) {
            setConfirmApplicationModalOpen(true);
        }
    }, [confirmApplicationConfig]);

    return loading ? (
        <Loader spinnerSize="md" centerHeight="65vh" />
    ) : (
        <Flex>
            <Box flex={1} pr="20px" borderRight="1px solid #D1D2DB">
                <Text fontSize="18px" lineHeight="27px" color="brand" fontWeight="600">
                    My agency
                </Text>
                {items.length ? (
                    <InfiniteScroll>
                        {items.map(elem => (
                            <ApplicantItem
                                key={elem.id}
                                {...elem}
                                onAccept={() =>
                                    setConfirmApplicationConfig({
                                        applicantId: elem.id,
                                        fee: +roundAplicantPrices(elem.fee),
                                        price: +roundAplicantPrices(elem.price),
                                        requestId,
                                    })
                                }
                                onReject={() => onRejectApplicant(elem.id)}
                            />
                        ))}
                    </InfiniteScroll>
                ) : (
                    <Center h="50vh">
                        <Text color="brand">There is no applicants from my agency</Text>
                    </Center>
                )}
            </Box>
            <Divider orientation="vertical" />
            <Box flex={1} ml="20px">
                <Text fontSize="18px" lineHeight="27px" color="brand" fontWeight="600">
                    Partner Agencies
                </Text>
                {partnerApplicants.length ? (
                    <InfiniteScroll>
                        {partnerApplicants.map(elem => (
                            <ApplicantItem
                                key={elem.id}
                                {...elem}
                                onAccept={() =>
                                    setConfirmApplicationConfig({
                                        applicantId: elem.id,
                                        fee: +roundAplicantPrices(elem.fee),
                                        price: +roundAplicantPrices(elem.price),
                                        requestId,
                                    })
                                }
                                onReject={() => onRejectApplicant(elem.id)}
                            />
                        ))}
                    </InfiniteScroll>
                ) : (
                    <Center h="50vh">
                        <Text color="brand">There is no applicants from partner agencies</Text>
                    </Center>
                )}
            </Box>
            <ConfirmApplicantModal
                isOpen={confirmApplicationModalOpen}
                onClose={() => {
                    setConfirmApplicationModalOpen(false);
                    setConfirmApplicationConfig(null);
                }}
                onConfirm={onConfirmApplicant}
                confirmApplicationConfig={confirmApplicationConfig}
            />
        </Flex>
    );
};
