import React from 'react';
import { VStack } from '@chakra-ui/react';
import { CustomButton, CustomDropdown, CustomInput } from '../../../../../common/components';
import { bankAccountTypes } from '../consts';
import { Resolver, useForm } from 'react-hook-form';
import addPaymentMethodResolver, {
    AddPaymentMethodFormValues,
} from './validation/addPaymentMethod.schema';
import { useDispatch } from 'react-redux';
import { updatePaymentMethodRequest } from '../store/actions';

interface IEditPaymentMethodForm {
    editPaymentMethodLoading: boolean;
    fundingSourceId: string;
}

export const EditPaymentMethodForm: React.FC<IEditPaymentMethodForm> = ({
    editPaymentMethodLoading,
    fundingSourceId,
}) => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm<AddPaymentMethodFormValues>({
        resolver: addPaymentMethodResolver as Resolver<AddPaymentMethodFormValues>,
    });
    const onSubmit = (data: AddPaymentMethodFormValues) => {
        dispatch(
            updatePaymentMethodRequest({
                ...data,
                fundingSourceId,
            }),
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack mx={'auto'} align={'stretch'} maxW={'342px'} spacing={'16px'}>
                <CustomInput
                    label={'Nickname'}
                    register={{ ...register('name') }}
                    placeholder={'Enter'}
                    errors={errors.name}
                />
                <CustomInput
                    label={'Routing number'}
                    register={{ ...register('routingNumber') }}
                    placeholder={'Enter'}
                    errors={errors.routingNumber}
                />
                <CustomInput
                    label={'Account number'}
                    register={{ ...register('accountNumber') }}
                    placeholder={'Enter'}
                    errors={errors.accountNumber}
                />
                <CustomDropdown
                    options={bankAccountTypes}
                    label={'Account Type'}
                    register={{ ...register('bankAccountType') }}
                    errors={errors.bankAccountType}
                />

                <CustomButton
                    isLoading={editPaymentMethodLoading}
                    text="Edit"
                    type="submit"
                    height="60px"
                    bgColor="brand"
                />
            </VStack>
        </form>
    );
};
