import React from 'react';
import { Link } from 'react-router-dom';

import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    CloseButton,
    ModalHeader,
    Text,
    HStack,
    ModalFooter,
} from '@chakra-ui/react';

import { CustomButton } from '../../blocks';

interface IConnectPaymentModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const ConnectPaymentModal: React.FC<IConnectPaymentModalProps> = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay />
            <ModalContent borderRadius={'18px'} bgColor="white" alignItems="center">
                <ModalHeader
                    d="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    width="100%"
                    padding="25px"
                >
                    <Text
                        color="brand"
                        fontSize="16px"
                        lineHeight="23px"
                        fontWeight="600"
                        mt="5px !important"
                        w="80%"
                    >
                        Action required
                    </Text>
                    <CloseButton onClick={onClose} />
                </ModalHeader>
                <ModalBody 
                width="100%" 
                paddingBottom="25px"
                d="flex" flexDirection="column">
                    <Text
                        color="brand"
                        fontSize="16px"
                        lineHeight="23px"
                    >
                        To continue please complete your payment account set-up first at the
                        Settings page.
                    </Text>
                </ModalBody>
                <ModalFooter padding="25px" w="100%">
                    <HStack w="100%" gap="25px">
                        <CustomButton
                            text="Cancel"
                            variant="outline"
                            color="brand"
                            flex="1"
                            height="60px"
                            borderColor="brand"
                            onClick={onClose}
                        />
                        <CustomButton
                            as={Link}
                            to={"/settings/payments"}
                            flex="1"
                            text="Connect payment"
                            bgColor="brand"
                            height="60px"
                            fontWeight="700"
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
