import React, { useEffect, useState } from 'react';
import { RequestInfoType, RequestAddressesType } from '../../../../Requests/types';
import { OpenHouseActivityInfo, OpportunitiesActivityInfo } from '../forms';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import {
    getOpenHouseInfo,
    deleteListingTimeSlot,
} from '../../../../../store/actions/listings.actions';
import { DeleteListingTimeSlotPayload } from '../../../../../common/types';
import { HireAssistanceInfoSection, ConfirmAlert } from '../../../../../common/components/blocks';
import { getBonusTypes, getClientTypes } from '../../../../../store/actions/directories.actions';
import { cancelAcceptedWork } from '../../../../Requests/store/actions';
import { SelectedActivity } from '../../../types';
import { useDelta } from 'react-delta';
import { FinishWorkModal } from '../../../../../common/components/modals';
import { finishWorkRequest } from '../../../store/actions';
import { shouldShowCancellationMessage } from '../../../../../common/utils';
import { ConfirmApplicantWorkModal } from '../../../../../common/components/modals/ConfirmApplicantWorkModal/ConfirmApplicantWorkModal';

interface IInfoTabProps {
    activity: RequestInfoType;
    selectedActivityId: SelectedActivity;
    requestAddresses: RequestAddressesType[];
    onClose: () => void;
    fetchItems: (value?: number) => void;
    setShowAddFeedback: (value: boolean) => void;
    onOpenIssue: () => void;
}

export const InfoTab: React.FC<IInfoTabProps> = ({
    activity,
    selectedActivityId,
    requestAddresses,
    fetchItems,
    onClose,
    setShowAddFeedback,
    onOpenIssue,
}) => {
    const dispatch = useDispatch();

    const [cancelWorkAlertOpen, setCancelWorkAlertOpen] = useState(false);
    const [toCancelWorkId, setToCancelWorkId] = useState(0);

    const [finishWorkModalOpen, setFinishWorkModalOpen] = useState(false);
    const [toFinishWorkId, setToFinishWorkId] = useState(0);

    const [isConfirmApplicantModalOpen, setConfirmApplicantModalOpen] = useState(false);

    useEffect(() => {
        if (activity.timeSlot.id !== 0) {
            dispatch(getOpenHouseInfo(activity.timeSlot.listingId));
        }
    }, [activity]);

    useEffect(() => {
        dispatch(getClientTypes());
        dispatch(getBonusTypes());
    }, []);

    const { value: openHouseInfo, loading: openHouseInfoLoading } = useSelector(
        (state: RootStore) => state.listings.openHouseInfo,
    );

    const { loading: deleteLoading } = useSelector(
        (state: RootStore) => state.requests.deleteRequest,
    );
    const bonusTypes = useSelector((state: RootStore) => state.directories.bonusTypes);
    const clientTypes = useSelector((state: RootStore) => state.directories.clientTypes);
    const { errors: cancelAcceptedWorkErrors, loading: cancelAcceptedWorkLoading } = useSelector(
        (state: RootStore) => state.requests.cancelAcceptedWork,
    );
    const { loading: finishWorkLoading, errors: finishWorkErrors } = useSelector(
        (state: RootStore) => state.activity.finishWork,
    );

    const { paymentStatus } = useSelector((state: RootStore) => state.activity);

    // const { loading: confirmWorkLoading, errors: confirmWorkErrors } = useSelector(
    //     (state: RootStore) => state.activity.confirmWork,
    // );

    const onDeleteTimeSlot = (value: DeleteListingTimeSlotPayload) => {
        dispatch(deleteListingTimeSlot(value));
    };

    useEffect(() => {
        if (toCancelWorkId) {
            setCancelWorkAlertOpen(true);
        }
    }, [toCancelWorkId]);

    useEffect(() => {
        if (toFinishWorkId) {
            setFinishWorkModalOpen(true);
        }
    }, [toFinishWorkId]);

    const cancelAcceptedWorkLoadingDelta = useDelta(cancelAcceptedWorkLoading);
    const finishWorkLoadingDelta = useDelta(finishWorkLoading);
    const paymentStatusDelta = useDelta(paymentStatus);

    useEffect(() => {
        if (cancelAcceptedWorkLoadingDelta && cancelAcceptedWorkLoadingDelta.prev) {
            if (
                cancelAcceptedWorkLoadingDelta.prev &&
                !cancelAcceptedWorkLoadingDelta.curr &&
                !cancelAcceptedWorkErrors.length
            ) {
                setCancelWorkAlertOpen(false);
                setToCancelWorkId(0);
                onClose();
                fetchItems(1);
            }
        }
    }, [cancelAcceptedWorkLoadingDelta]);

    useEffect(() => {
        if (finishWorkLoadingDelta && finishWorkLoadingDelta.prev) {
            if (
                finishWorkLoadingDelta.prev &&
                !finishWorkLoadingDelta.curr &&
                !finishWorkErrors.length
            ) {
                setFinishWorkModalOpen(false);
                setToFinishWorkId(0);
                // onClose();
                fetchItems(1);
                setShowAddFeedback(true);
            }
        }
    }, [finishWorkLoadingDelta]);

    useEffect(() => {
        if (paymentStatusDelta && paymentStatusDelta.prev) {
            if (
                paymentStatusDelta.prev.modalOpen &&
                !paymentStatusDelta.curr.modalOpen &&
                paymentStatusDelta.prev.status === 'success'
            ) {
                setConfirmApplicantModalOpen(false);
                setShowAddFeedback(true);
            }
        }
    }, [paymentStatusDelta]);

    return (
        <Box height="100%">
            {activity.requestCategoryId === 1 && (
                <HireAssistanceInfoSection
                    bonusTypes={bonusTypes}
                    clientTypes={clientTypes}
                    deleteLoading={deleteLoading}
                    isActivities={true}
                    // loading
                    requestInfo={activity}
                    setToCancelWorkId={setToCancelWorkId}
                    setToFinishWorkId={
                        selectedActivityId.isMyActivity ? setToFinishWorkId : undefined
                    }
                    setConfirmApplicantModalOpen={
                        activity.isWorkFinished && !selectedActivityId.isMyActivity
                            ? setConfirmApplicantModalOpen
                            : undefined
                    }
                />
            )}
            {activity.requestCategoryId === 2 && (
                <OpportunitiesActivityInfo
                    isMyActivity={selectedActivityId.isMyActivity}
                    onOpenIssue={onOpenIssue}
                    bonusTypes={bonusTypes}
                    clientTypes={clientTypes}
                    requestInfo={activity}
                    setToCancelWorkId={setToCancelWorkId}
                    setToFinishWorkId={setToFinishWorkId}
                    setConfirmApplicantModalOpen={setConfirmApplicantModalOpen}
                />
            )}
            {activity.requestCategoryId === 3 && (
                <OpenHouseActivityInfo
                    onOpenIssue={onOpenIssue}
                    openHouseInfo={openHouseInfo}
                    loading={openHouseInfoLoading}
                    activity={activity}
                    onDeleteTimeSlot={onDeleteTimeSlot}
                    setToCancelWorkId={setToCancelWorkId}
                    setToFinishWorkId={
                        selectedActivityId.isMyActivity ? setToFinishWorkId : undefined
                    }
                    setConfirmApplicantModalOpen={setConfirmApplicantModalOpen}
                />
            )}
            <ConfirmAlert
                isOpen={cancelWorkAlertOpen}
                onClose={() => {
                    setCancelWorkAlertOpen(false);
                    setToCancelWorkId(0);
                }}
                onConfirm={() => dispatch(cancelAcceptedWork(toCancelWorkId))}
                message={`Are you sure you want cancel this work? ${shouldShowCancellationMessage(
                    activity.dateTime,
                    activity.timeSlot.openHouseHostTypeId,
                )
                        ? 'You will be charged a $30 Cancellation Fee as this cancellation is within 1 hour of the start time'
                        : ''
                    }`}
                confirmLoading={cancelAcceptedWorkLoading}
            />
            <FinishWorkModal
                isOpen={finishWorkModalOpen}
                onClose={() => {
                    setFinishWorkModalOpen(false);
                    setToFinishWorkId(0);
                }}
                requestInfo={activity}
                requestAddresses={requestAddresses}
                onFinishWork={data => dispatch(finishWorkRequest(data))}
                finishWorkLoading={finishWorkLoading}
            />
            {isConfirmApplicantModalOpen && (
                <ConfirmApplicantWorkModal
                    requestInfo={activity}
                    isOpen={isConfirmApplicantModalOpen}
                    onClose={() => {
                        setConfirmApplicantModalOpen(false);
                    }}
                    onOpenIssue={onOpenIssue}
                />
            )}
        </Box>
    );
};
