import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ListItem } from './ListItem';
import { AvailableForApplicationResponse } from '../../../../../pages/Calendar/types';
import dayjs from 'dayjs';
import { AcceptRequestsPayload } from '../../../../../pages/Requests/types';

interface IListBlockProps {
    startTime: string;
    models: AvailableForApplicationResponse[];
    setPostId: (value: number) => void;
    onDeleteRequestAfterTimer: (id: number) => void;
    onAcceptRequest: (data: AcceptRequestsPayload) => void;
    setCreatedAt: (arg0: string) => void;
    handleOpenAvailableModal: (id: number) => void;
}

export const ListBlock: React.FC<IListBlockProps> = ({
    models,
    startTime,
    setPostId,
    onAcceptRequest,
    onDeleteRequestAfterTimer,
    setCreatedAt,
    handleOpenAvailableModal,
}) => (
    <Box>
        <Box
            borderLeftColor="calendarLical"
            borderLeftWidth="8px"
            bgColor="calendarLicalOpacity"
            mb="10px"
        >
            <Text fontWeight={400} fontSize="20px" ml="10px">
                Starting {dayjs(startTime).format('hh:mm A')}
            </Text>
        </Box>
        {models.map(elem => (
            <ListItem
                key={elem.id}
                {...elem}
                onPressDetails={() => setPostId(elem.id)}
                onAcceptRequest={onAcceptRequest}
                onDeleteRequestAfterTimer={onDeleteRequestAfterTimer}
                setCreatedAt={setCreatedAt}
                handleOpenAvailableModal={handleOpenAvailableModal}
            />
        ))}
    </Box>
);
