import React from 'react';
import { Header } from './Header';
import { ModalBody, HStack } from '@chakra-ui/react';
import { AddressItemView } from './AddressItemView';
import { CustomButton, ReportIssueButton } from '../../../blocks';
import { RequestAddressesType } from '../../../../../pages/Requests/types';

interface IPropertiesListToConfirmProps {
    onClose: () => void;
    requestAddresses: RequestAddressesType[];
    setShowingStep: (value: number) => void;
    onOpenIssue: () => void;
}

export const PropertiesListToConfirm: React.FC<IPropertiesListToConfirmProps> = ({
    onClose,
    requestAddresses,
    setShowingStep,
    onOpenIssue,
}) => {
    return (
        <>
            <Header
                onClose={onClose}
                title="Please confirm which properties were toured during this showing:"
            />
            <ModalBody w="100%">
                {requestAddresses.map((elem, index) => (
                    <AddressItemView
                        key={elem.id}
                        address={elem.name}
                        index={index}
                        isCompleted={elem.isCompleted}
                    />
                ))}
                <HStack mt="20px">
                    <ReportIssueButton onClick={onOpenIssue} w="100%" />
                    <CustomButton
                        text="Confirm"
                        onClick={() => setShowingStep(1)}
                        height="60px"
                        bgColor="brand"
                    />
                </HStack>
            </ModalBody>
        </>
    );
};
