import React from 'react';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import { colors } from '../../constants';
import { fonts } from '../../utils';

interface IPricingCommonQuestonsBlock {
    title: string;
    description: string;
}

export const PricingCommonQuestonsBlock: React.FC<IPricingCommonQuestonsBlock> = ({
    description,
    title,
}) => {

    const [isLargerThan900] = useMediaQuery('(min-width: 900px');

    return (
        <Box p={isLargerThan900 ? "0" : "0 20px"}>
            <Text
                color={colors.brand}
                fontFamily={fonts.montserrat}
                fontSize="18px"
                lineHeight="18px"
                fontWeight="600"
                mb="10px"
                mt="20px"
            >
                {title}
            </Text>
            <Text
                opacity={0.7}
                color={colors.brand}
                fontFamily={fonts.montserrat}
                fontSize="13px"
                lineHeight="23px"
            >
                {description}
            </Text>
        </Box>
    );
}
