import { createAction } from '@reduxjs/toolkit';
import {
    IResponseWithoutValue,
    PayloadError,
    SetLocationPayload,
    IResponseWithCustomValue,
} from '../../../../../common/types';

export const getLocation = createAction('GET_LOCATION');
export const getLocationSuccess =
    createAction<IResponseWithCustomValue<SetLocationPayload>>('GET_LOCATION_SUCCESS');
export const getLocationError = createAction<PayloadError[]>('GET_LOCATION_ERROR');

export const setLocation = createAction<SetLocationPayload>('SET_LOCATION');
export const setLocationSuccess = createAction<SetLocationPayload>('SET_LOCATION_SUCCESS');
export const setLocationError = createAction<PayloadError[]>('SET_LOCATION_ERROR');

export const setNeverUseLocation = createAction('SET_NEVER_USE_LOCATION');
export const setNeverUseLocationSuccess = createAction<IResponseWithoutValue>(
    'SET_NEVER_USE_LOCATION_SUCCESS',
);
export const setNeverUseLocationError = createAction<PayloadError[]>(
    'SET_NEVER_USE_LOCATION_ERROR',
);
