import React, { useEffect, useState } from 'react';
import { Listings } from './Listings';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import {
  getListingTypesRequest,
  getMyListings,
  getOpenHouseInfo,
  getAgencyListingsRequest,
  getAgencyCities,
} from '../../store/actions/listings.actions';
import { OpenhouseDetailsModal, AddTimeSlotModal, ListingDetailsModal } from './modals';
import { useDelta } from 'react-delta';
import {
  getOpenHouseAudiences,
  getOpenHouseHostTypes,
  getOpenHouseTypes,
} from '../../store/actions/directories.actions';
import { sortByTypes } from '../../common/constants';
import { OpenHouseDetailsOpenConfig, TimeSlotType } from '../../common/types';

export const defaultOpenHouseDetailsOpenConfig = {
  isOpen: false,
  isMyListings: true,
  isManageOpenHouse: false,
  listingStatusId: 0,
};

export const ListingsContainer: React.FC = () => {
  const [showOpenhouseInfoModal, setShowOpenhouseInfoModal] = useState<OpenHouseDetailsOpenConfig>(
    defaultOpenHouseDetailsOpenConfig,
  );
  const [showAddTimeSlotModal, setShowAddTimeSlotModal] = useState(false);
  const [timeSlotModalCompletedFieldsData, setTimeSlotModalCompletedFieldsData] = useState<
    TimeSlotType | undefined
  >(undefined);
  const [toShowListingId, setToShowListingId] = useState(0);
  const [showListingInfoModal, setShowListingInfoModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
  const [sortValue, setSortValue] = useState(sortByTypes[0].value);
  const [isSorted, setIsSorted] = useState(false);
  const [isOpenHouse, setIsOpenHouse] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState<number[]>([]);
  const [cities, setCities] = useState<string[]>([]);

  const {
    loading: myListingsLoading,
    active: activeListings,
    pending: pendingListings,
    closed: closedListings,
  } = useSelector((state: RootStore) => state.listings.myListings);
  const {
    loading: agencyListingsLoading,
    items: agencyListings,
    loadMoreLoading: agencyListingsMoreLoading,
    totalCount: agencyListingsTotalCount,
  } = useSelector((state: RootStore) => state.listings.agencyListings);

  const { loading: addSlotLoading, errors: addSlotErrors } = useSelector(
    (state: RootStore) => state.listings.addTimeSlot,
  );
  const { loading: updateSlotLoading, errors: updateSlotErrors } = useSelector(
    (state: RootStore) => state.listings.updateTimeSlot,
  );

  const { openHouseTypes, openHouseAudiences, openHouseHostTypes, openHouseTypesLoading } =
    useSelector((state: RootStore) => state.directories);
  const { items: listingTypes } = useSelector((state: RootStore) => state.listings.listingTypes);
  const { items: agencyCities } = useSelector((state: RootStore) => state.listings.agencyCities);

  const addSlotLoadingDelta = useDelta(addSlotLoading);
  const updateSlotLoadingDelta = useDelta(updateSlotLoading);

  const dispatch = useDispatch();
  const onGetOpenHouseTypes = () => dispatch(getOpenHouseTypes());
  const onGetOpenHouseHostTypes = () => dispatch(getOpenHouseHostTypes());
  const onGetOpenHouseAudiences = () => dispatch(getOpenHouseAudiences());
  const onGetListingTypes = () => dispatch(getListingTypesRequest());
  const onGetAgencyCities = () => dispatch(getAgencyCities());

  useEffect(() => {
    if (tabIndex === 0) {
      dispatch(getMyListings());
    } else {
      dispatch(
        getAgencyListingsRequest({
          cities,
          isOpenHouse,
          pageNumber: currentPage,
          pageSize: 20,
          propertyTypes,
          sortBy: sortValue === 1 ? 0 : 1,
          sortOrder: isSorted,
        }),
      );
    }
  }, [tabIndex, sortValue, isSorted, cities, isOpenHouse, propertyTypes, currentPage]);

  useEffect(() => {
    onGetListingTypes();
    onGetOpenHouseTypes();
    onGetOpenHouseHostTypes();
    onGetOpenHouseAudiences();
    onGetAgencyCities();
  }, []);

  useEffect(() => {
    if (addSlotLoadingDelta && addSlotLoadingDelta.prev) {
      if (addSlotLoadingDelta.prev && !addSlotLoadingDelta.curr && !addSlotErrors.length) {
        setShowAddTimeSlotModal(false);
        dispatch(getMyListings());
        dispatch(getOpenHouseInfo(toShowListingId));
      }
    }

    if (updateSlotLoadingDelta && updateSlotLoadingDelta.prev) {
      if (updateSlotLoadingDelta.prev && !updateSlotLoadingDelta.curr && !updateSlotErrors.length) {
        setShowAddTimeSlotModal(false);
        dispatch(getMyListings());
        dispatch(getOpenHouseInfo(toShowListingId));
      }
    }
  }, [addSlotLoadingDelta, updateSlotLoadingDelta]);

  const loadMoreItems = () => {
    if (agencyListings.length >= agencyListingsTotalCount) {
      setOnEndReachedCalled(true);
    } else if (!onEndReachedCalled) {
      setCurrentPage((prev: number) => prev + 1);
    }
  };

  return (
    <>
      <Listings
        agencyListings={agencyListings}
        activeListings={activeListings}
        pendingListings={pendingListings}
        closedListings={closedListings}
        agencyListingsLoading={agencyListingsLoading}
        agencyListingsMoreLoading={agencyListingsMoreLoading}
        myListingsLoading={myListingsLoading}
        setToShowListingId={setToShowListingId}
        setShowOpenhouseInfoModal={setShowOpenhouseInfoModal}
        setShowAddTimeSlotModal={setShowAddTimeSlotModal}
        setShowListingInfoModal={setShowListingInfoModal}
        listingTypes={listingTypes}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        agencyCities={agencyCities}
        sortValue={sortValue}
        setSortValue={setSortValue}
        setIsSorted={setIsSorted}
        setIsOpenHouse={setIsOpenHouse}
        setPropertyTypes={setPropertyTypes}
        setCities={setCities}
        loadMoreItems={loadMoreItems}
      />
      <OpenhouseDetailsModal
        showOpenhouseInfoModal={showOpenhouseInfoModal}
        onClose={() => {
          setShowOpenhouseInfoModal(defaultOpenHouseDetailsOpenConfig);
          setToShowListingId(0);
        }}
        listingId={toShowListingId}
        setShowAddTimeSlotModal={setShowAddTimeSlotModal}
        getItems={() => dispatch(getMyListings())}
        createTimeSlotModalCompletedFieldsData={(data: TimeSlotType) => {
          setTimeSlotModalCompletedFieldsData(data);
        }}
      />
      <AddTimeSlotModal
        isOpen={showAddTimeSlotModal}
        onClose={() => {
          setShowAddTimeSlotModal(false);
          setTimeSlotModalCompletedFieldsData(undefined);
        }}
        completedFieldsData={timeSlotModalCompletedFieldsData}
        listingId={toShowListingId}
        loading={addSlotLoading}
        openHouseTypesLoading={openHouseTypesLoading}
        openHouseHostTypes={openHouseHostTypes}
        openHouseAudiences={openHouseAudiences}
        openHouseTypes={openHouseTypes}
      />
      <ListingDetailsModal
        isOpen={showListingInfoModal}
        onClose={() => {
          setShowListingInfoModal(false);
          setToShowListingId(0);
        }}
        listingId={toShowListingId}
      />
    </>
  );
};
