import { call, put, takeEvery } from 'redux-saga/effects';
import { GetAgentsBySearchSuccessResponse } from '../../../../types';
import { getAgentsBySearch, getAgentsBySearchError, getAgentsBySearchSuccess } from './actions';
import API from '../../../../api/api.base';

function* workerGetAgentsBySearch(action: ReturnType<typeof getAgentsBySearch>) {
    try {
        const result: GetAgentsBySearchSuccessResponse = yield call(
            API.get,
            `/api/dashboard/search-agents?SearchQuery=${action.payload.SearchQuery}&PageNumber=${action.payload.PageNumber}&PageSize=${action.payload.PageSize}`,
            {},
        );
        if (result.success) {
            yield put(getAgentsBySearchSuccess(result));
        } else {
            yield put(getAgentsBySearchError(result.errors));
        }
    } catch (error) {
        console.log('Get Agents By Search Error', error);
    }
}

export default function* watchGetAgentsBySearchSaga() {
    yield takeEvery(getAgentsBySearch.type, workerGetAgentsBySearch);
}
