import React from 'react';
import {
    Box,
    Container,
    HStack,
    Link,
    Flex,
    Button,
    IconButton,
    useMediaQuery,
} from '@chakra-ui/react';
import { LogoIcon } from '../../../assets/icons';
import { colors } from '../../constants';
import { Link as ReactLink } from 'react-router-dom';
import { fonts } from '../../utils';
import { MobileMenu } from './MobileMenu';

export const LandingHeader: React.FC<{ isFinalScreen?: boolean }> = ({ isFinalScreen }) => {
    const [isLargerThan1070] = useMediaQuery('(min-width: 1070px)');
    return (
        <Box
            py={isFinalScreen ? '15px' : undefined}
            my={isFinalScreen ? '0px' : '15px'}
            boxShadow={isFinalScreen ? 'base' : undefined}
        >
            <Container maxW="container.xl">
                <Flex
                    justify="space-between"
                    align="center"
                    // py={{ base: '10px', lg: '0' }}
                    py={isLargerThan1070 ? '0' : '10px'}
                >
                    <Link href="/">
                        <LogoIcon fill={colors.brand} />
                    </Link>

                    <HStack display={isLargerThan1070 ? 'flex' : 'none'} spacing={'20px'}>
                        <Button
                            border={'1px solid rgb(47, 128, 237)'}
                            height="59px"
                            width="159px"
                            background={'white'}
                            borderRadius="30px"
                            fontFamily={fonts.montserrat}
                            fontWeight="bold"
                            fontSize="14px"
                            _hover={{ bg: colors.secondaryFont, textDecoration: 'none' }}
                            as={Link}
                            color={colors.calendarBlue}
                            href="/auth/signin/login"
                        >
                            Log in
                        </Button>

                        <Button
                            height="59px"
                            width="159px"
                            background={colors.calendarBlue}
                            borderRadius="30px"
                            fontFamily={fonts.montserrat}
                            fontWeight="bold"
                            fontSize="14px"
                            _hover={{ bg: colors.brand, textDecoration: 'none' }}
                            as={Link}
                            color="white"
                            href="/auth/signup/createaccount"
                        >
                            Join
                        </Button>
                    </HStack>
                    <Box
                        // display={{ base: 'block', lg: 'none' }}
                        display={isLargerThan1070 ? 'none' : 'block'}
                        zIndex={10001}
                    >
                        <MobileMenu />
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
};
