import React from 'react';
import { Box, Flex, Text, Avatar, VStack, ModalBody, Button } from '@chakra-ui/react';
import {
    CustomButton,
    CustomDropdown,
    CustomInput,
    Loader,
    RequestQuickInfo,
} from '../../../../../common/components';
import { fonts, getAvatarPicture } from '../../../../../common/utils';
import { RequestInfoType } from '../../../types';
import { LocationIcon } from '../../../../../assets/icons';
import { Typography } from '../../../../../common/components/blocks/Typography/Typography';
import { yesOrNo } from '../../../../../common/constants';
import { RequestModalHeader } from '../AgencyRequestInfoModal';
import { IdNameTypes } from '../../../../../common/types';
interface OpportunitiesRepliesSummary {
    loading: boolean;
    requestInfo: RequestInfoType;
    setToCancelWorkId: (value: number) => void;
    bonusTypes: IdNameTypes[];
    onClose: () => void;
}

export const OpportunitiesRepliesSummary: React.FC<OpportunitiesRepliesSummary> = ({
    loading,
    requestInfo,
    setToCancelWorkId,
    bonusTypes,
    onClose,
}) => {
    return (
        <>
            <RequestModalHeader title={requestInfo.requestTypeName} onClose={onClose} />
            <ModalBody d="flex" flexDirection="column" w="100%" px="70px" h={'full'}>
                {loading ? (
                    <Loader spinnerSize="md" centerHeight="100%" />
                ) : (
                    <>
                        <Flex justifyContent="space-between">
                            <Box w={'48%'}>
                                {requestInfo.typeId !== 7 ? (
                                    <Flex alignItems="center">
                                        <Avatar
                                            mr={4}
                                            border="none"
                                            size="xl"
                                            name={requestInfo.ownerFullName}
                                            src={getAvatarPicture(requestInfo.avatar)}
                                        />
                                        <Box>
                                            <Text
                                                color="brand"
                                                fontWeight={700}
                                                fontSize="24px"
                                                lineHeight="36px"
                                                fontFamily={fonts.poppins}
                                                textDecorationLine="underline"
                                            >
                                                {requestInfo.ownerFullName}
                                            </Text>
                                            <Flex mt={2} alignItems="center">
                                                <LocationIcon height="24" width="24" />
                                                <Text
                                                    color="brand"
                                                    fontWeight={400}
                                                    ml={2}
                                                    fontSize="16px"
                                                    lineHeight="24px"
                                                    fontFamily={fonts.poppins}
                                                >
                                                    {requestInfo.currentLocation}
                                                </Text>
                                            </Flex>
                                            <Text
                                                color="brand"
                                                fontWeight={300}
                                                fontSize="14px"
                                                mt={2}
                                                lineHeight="21px"
                                                fontFamily={fonts.poppins}
                                            >
                                                {requestInfo.agencyName}
                                            </Text>
                                        </Box>
                                    </Flex>
                                ) : null}
                                {requestInfo.typeId !== 7 ? (
                                    <RequestQuickInfo
                                        date={requestInfo.dateTime}
                                        fromTime={requestInfo.fromTime}
                                        duration={requestInfo.duration}
                                        propertiesToShow={requestInfo.propertiesToShow}
                                        requestTypeName={requestInfo.requestTypeName}
                                        totalPrice={requestInfo.totalPrice || 0}
                                        isShowTotalPrice={false}
                                    />
                                ) : (
                                    <VStack
                                        align={'stretch'}
                                        bg="white"
                                        borderRadius="24px"
                                        padding="20px"
                                    >
                                        <Typography size={14} color={'#888DAB'}>
                                            State
                                        </Typography>
                                        <Typography
                                            size={14}
                                            fontFamily={fonts.montserrat}
                                            weight={600}
                                        >
                                            {`${requestInfo.states.join('')}`}
                                        </Typography>
                                        <Typography size={14} color={'#888DAB'}>
                                            Agencies
                                        </Typography>
                                        {requestInfo.agencies.map(agency => (
                                            <Typography
                                                key={agency}
                                                size={14}
                                                fontFamily={fonts.montserrat}
                                                weight={600}
                                            >
                                                {agency}
                                            </Typography>
                                        ))}
                                    </VStack>
                                )}
                                <Box mt={6}>
                                    {requestInfo.states &&
                                        requestInfo.typeId !== 7 &&
                                        requestInfo.states.map((state: string) => (
                                            <CustomInput
                                                key={state}
                                                placeholder="State"
                                                label="State"
                                                register={{ value: state }}
                                                isBorder={false}
                                                isReadOnly
                                                margin="0 0 26px 0"
                                            />
                                        ))}
                                    {requestInfo.addresses && requestInfo.addresses.length !== 0 ? (
                                        <>
                                            <Typography
                                                fontFamily={fonts.montserrat}
                                                size={18}
                                                weight={700}
                                            >
                                                {requestInfo.typeId === 1 ? 'Addresses' : 'Address'}
                                            </Typography>
                                            {requestInfo.addresses.map(item => (
                                                <CustomInput
                                                    key={item}
                                                    register={{ value: item }}
                                                    isBorder={false}
                                                    isReadOnly
                                                    margin="0 0 11px 0"
                                                />
                                            ))}
                                        </>
                                    ) : null}
                                    {requestInfo.generalNote ? (
                                        <>
                                            <Typography
                                                fontFamily={fonts.montserrat}
                                                size={18}
                                                weight={700}
                                            >
                                                Description:
                                            </Typography>
                                            <Text
                                                color="secondaryFontDarker"
                                                fontWeight={400}
                                                fontSize="16px"
                                                mt={2}
                                                lineHeight="26px"
                                                fontFamily={fonts.poppins}
                                            >
                                                {requestInfo.generalNote}
                                            </Text>
                                        </>
                                    ) : null}
                                </Box>
                            </Box>

                            <Box w={'48%'}>
                                {requestInfo.typeId === 1 ? (
                                    <>
                                        <Typography
                                            fontFamily={fonts.montserrat}
                                            size={18}
                                            weight={700}
                                        >
                                            More details:
                                        </Typography>

                                        <CustomInput
                                            label={`Is The ${requestInfo.requestTypeName} Scheduled?`}
                                            register={{
                                                value: requestInfo.isShowingScheduled
                                                    ? 'Yes'
                                                    : 'No',
                                            }}
                                            isBorder={false}
                                            isReadOnly
                                            margin="0 0 11px 0"
                                        />
                                        {requestInfo.wouldLikeToOfferBonus ? (
                                            <>
                                                <CustomDropdown
                                                    label="I would like to offer a Bonus if a contract is ratified from the showing list within 48-hours after the showing:"
                                                    options={yesOrNo}
                                                    register={{
                                                        value: requestInfo.wouldLikeToOfferBonus
                                                            ? 'Yes'
                                                            : 'No',
                                                        isReadOnly: true,
                                                        pointerEvents: 'none',
                                                    }}
                                                    errors=""
                                                    margin="0 0 10px 0"
                                                    bgColor="white"
                                                />
                                                <Flex justifyContent={'space-between'}>
                                                    <CustomDropdown
                                                        register={{
                                                            value: requestInfo.bonusTypeId,
                                                            isReadOnly: true,
                                                            pointerEvents: 'none',
                                                        }}
                                                        options={bonusTypes.map((elem, index) => ({
                                                            label: elem.name,
                                                            value: elem.id,
                                                            id: index,
                                                        }))}
                                                        label="Bonus Type"
                                                        errors=""
                                                        margin="0 0 10px 0"
                                                        bgColor="white"
                                                        width={'47%'}
                                                    />
                                                    <CustomInput
                                                        width={'47%'}
                                                        label="Bonus"
                                                        register={{
                                                            value: requestInfo.amount?.toString(),
                                                            isReadOnly: true,
                                                        }}
                                                        margin="0 0 10px 0"
                                                        bgColor="white"
                                                    />
                                                </Flex>
                                            </>
                                        ) : null}
                                    </>
                                ) : null}
                            </Box>
                        </Flex>
                        <Flex
                            alignItems={'flex-end'}
                            justify={'space-between'}
                            mb={'30px'}
                            mt={'auto'}
                        >
                            <Box w={'48%'}>
                                {requestInfo.typeId !== 7 ? (
                                    <Flex justify="space-between" align="center">
                                        <Typography size={13} weight={600}>
                                            Estimated Earnings:
                                        </Typography>
                                        <Typography size={19} weight={500}>
                                            ${requestInfo.totalPrice}
                                        </Typography>
                                    </Flex>
                                ) : null}
                            </Box>
                            <Button
                                w={'48%'}
                                onClick={() => setToCancelWorkId(requestInfo.id)}
                                variant={'outlinePrimary'}
                            >
                                Cancel
                            </Button>
                        </Flex>
                    </>
                )}
            </ModalBody>
        </>
    );
};
