import React, {useEffect} from 'react'
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup";
import {PaymentsModalWrapper} from "../components";
import {ErrorMessages} from "../../../../../common/schemes/messages";
import {Resolver, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {addBankAccountWithPlaid} from "../store/actions";
import {Box} from "@chakra-ui/react";
import {CustomButton, CustomInput} from "../../../../../common/components";
import {RootStore} from "../../../../../store";


const plaidResolver = yupResolver(
    yup.object().shape({
        name: yup.string().required(ErrorMessages.REQUIRED),
    }),
);

interface IProps {
    isOpen:boolean
    onClose: () => void,
    plaidToken:string
}

export const AddBankAccountViaPlaidModal:React.FC<IProps> = ({
    isOpen,
    onClose,
    plaidToken

}) => {
    const dispatch = useDispatch()

    const {
        handleSubmit, formState:{errors} , reset, register,
    } = useForm<{ name: string }>({
        resolver: plaidResolver as Resolver<{ name: string }>,
    });

    const {loading} = useSelector((state: RootStore) => state.payments.addBankAccountWithPlaid)

    const onSubmit = (data:{name: string}) => {
        dispatch(addBankAccountWithPlaid({
            ...data,
            plaidToken
        }))
        onClose()
    }

    useEffect(() => {
        if(!isOpen){
            reset({})
        }

    },[isOpen])

    return (
        <PaymentsModalWrapper
            isCentered
            isOpen={isOpen}
            title={'Add Method Via Plaid'}
            onClose={onClose}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mx={"auto"} maxW={'342px'} mt={'30px'}>

                    <CustomInput
                        labelProps={{
                            marginBottom: '7px'
                        }}
                        errors={errors.name}
                        register={{...register('name')}}
                        label={'NickName'}
                        placeholder={"Enter"}
                    />
                    <CustomButton
                        isLoading={loading}
                        mt={'30px'}
                        text="Send"
                        type="submit"
                        height="60px"
                        bgColor="#2C335C"
                    />

                </Box>

            </form>

        </PaymentsModalWrapper>
    )
}
