import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {ErrorMessages} from "../../../../../../common/schemes/messages";

export type AddPaymentMethodFormValues = {
    routingNumber: string
    accountNumber: string
    bankAccountType: string
    name: string
};
// ADD VALIDATION routing number
// first two digits of the routing number must fall within the range “01” through “12”, or “21”
// through “32”,

const AddPaymentMethodResolver = yup.object().shape({
    name: yup.string().max(50).required(ErrorMessages.REQUIRED),
    accountNumber: yup.string().min(4).required(ErrorMessages.REQUIRED),
    bankAccountType: yup.string().required(ErrorMessages.REQUIRED),
    routingNumber: yup.string().required(ErrorMessages.REQUIRED).matches(/^[0-9]{9}$/, 'Must be exactly 9 digits'),
});
export default yupResolver(AddPaymentMethodResolver);
