import React from 'react';
import { Flex, Text, TabList, Tabs, Tab } from '@chakra-ui/react';
import { colors } from '../../../common/constants';
interface ICategoriesSection {
    activeCategoty: number;
    onClick: (v: number) => void;
}

const shadow = `0px 0px 3px 0px ${colors.indicatorGreen}`;

export const CategoriesSection: React.FC<ICategoriesSection> = ({ activeCategoty, onClick }) => (
    <Tabs
        variant="unstyled"
        isFitted
        width="100%"
        bgColor="#F5F6F8"
        borderRadius="10px"
        onChange={index => onClick(index + 1)}
        size="lg"
    >
        <TabList>
            <Tab position="relative">
                <Text
                    fontSize="14px"
                    color={activeCategoty === 1 ? 'brand' : colors.secondaryFontDarker}
                >
                    All
                </Text>
                {activeCategoty === 1 && (
                    <Flex
                        h="3px"
                        w="100%"
                        bgColor={colors.indicatorGreen}
                        position="absolute"
                        bottom="0px"
                        right="0px"
                        style={{ boxShadow: shadow }}
                    />
                )}
            </Tab>
            <Tab _seleted={{ color: 'brand' }} position="relative">
                <Text
                    fontSize="14px"
                    color={activeCategoty === 2 ? 'brand' : colors.secondaryFontDarker}
                >
                    Incoming
                </Text>
                {activeCategoty === 2 && (
                    <Flex
                        h="3px"
                        w="100%"
                        bgColor="green"
                        position="absolute"
                        bottom="0px"
                        right="0px"
                        style={{ boxShadow: shadow }}
                    />
                )}
            </Tab>
            <Tab _seleted={{ color: 'brand' }} position="relative">
                <Text
                    fontSize="14px"
                    color={activeCategoty === 3 ? 'brand' : colors.secondaryFontDarker}
                >
                    Outgoing
                </Text>
                {activeCategoty === 3 && (
                    <Flex
                        h="3px"
                        w="100%"
                        bgColor="green"
                        position="absolute"
                        bottom="0px"
                        right="0px"
                        style={{ boxShadow: shadow }}
                    />
                )}
            </Tab>
        </TabList>
    </Tabs>
);
