import React from 'react';
import {
    Box,
    Text,
    FormControl,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar,
    Flex,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { fonts } from '../../../common/utils';
import { DropdownType } from './types';
import { SetCurrentAgencyPayloadType } from '../types';
import { baseUrl } from '../../../common/api/api.base';

import { RadioSelected } from '../../../assets/icons/RadioSelected';
import { RadioUnselected } from '../../../assets/icons/RadioUnselected';

interface ISidebarDropdownProps {
    options: DropdownType[];
    width?: string;
    margin?: string;
    errors?: any;
    body?: string;
    type?: 'submit' | 'reset' | 'button';
    height?: string;
    bgColor?: string;
    onClick?: () => void;
    border?: string;
    token: string;
    onSetCurrentAgency: (payload: SetCurrentAgencyPayloadType) => void;
    currentAgency: number;
    borderRadius?: string;
    maxW?: string;
    isLarge?: boolean;
}

const SidebarDropdown: React.FC<ISidebarDropdownProps> = ({
    options,
    width = '250px',
    margin,
    errors,
    height = '60px',
    type,
    bgColor = '#FFFFFF',
    onClick,
    border,
    onSetCurrentAgency,
    token,
    currentAgency,
    borderRadius = '20px',
    maxW = '250px',
    isLarge = false,
}) => {
    return (
        <Box
            borderRadius={borderRadius}
            w={width}
            justifyContent="flex-start"
            textColor="brand"
            zIndex={5}
        >
            <FormControl isInvalid={!!errors}>
                <Menu>
                    <MenuButton
                        _hover={{
                            backgroundColor: isLarge ? 'none' : 'gray.300',
                        }}
                        _active={{
                            backgroundColor: isLarge ? 'none' : 'gray.300',
                        }}
                        _focus={{
                            boxShadow: isLarge ? 'none' : 'outline',
                        }}
                        px={isLarge ? 0 : '16px'}
                        as={Button}
                        h={height}
                        type={type}
                        borderRadius={borderRadius}
                        bg={bgColor}
                        maxW={maxW}
                        w={width}
                        onClick={onClick}
                        justifyContent="flex-start"
                        textAlign="start"
                        textColor="brand"
                        rightIcon={<ChevronDownIcon w="20px" h="20px" />}
                        border={border ? border : ''}
                        zIndex={5}
                    >
                        {options.map((agency: any) => {
                            if (agency.id === currentAgency) {
                                const logo = agency?.logo?.itemHash
                                    ? `${baseUrl}/api/storage/static-images/${agency.logo.itemHash}${agency.logo.itemExtension}`
                                    : undefined;
                                return (
                                    <Box
                                        d="flex"
                                        alignItems="center"
                                        flexDirection="row"
                                        key={agency.id}
                                    >
                                        <Avatar
                                            h={isLarge ? '60px' : '40px'}
                                            w={isLarge ? '60px' : '40px'}
                                            src={logo}
                                            name={agency.state}
                                        />
                                        <Text
                                            fontWeight={600}
                                            fontFamily={fonts.poppins}
                                            fontSize={isLarge ? '20px' : '15px'}
                                            justifyContent="center"
                                            pl="10px"
                                            isTruncated
                                        >
                                            {agency.state}
                                        </Text>
                                    </Box>
                                );
                            }
                        })}
                    </MenuButton>

                    <MenuList
                        justifyContent="flex-start"
                        flexDirection="column"
                        textColor="brand"
                        w="250px"
                        zIndex={5}
                    >
                        {options.map((agency: any) => {
                            const logo = agency?.logo?.itemHash
                                ? `${baseUrl}/api/storage/static-images/${agency.logo.itemHash}${agency.logo.itemExtension}`
                                : undefined;

                            return (
                                <MenuItem
                                    key={agency.id}
                                    d="flex"
                                    alignItems="center"
                                    flexDirection="row"
                                    onClick={() => {
                                        if (agency.id !== currentAgency) {
                                            onSetCurrentAgency({ id: agency.id, token: token });
                                        }
                                    }}
                                >
                                    <Flex
                                        justifyContent="space-between"
                                        alignItems="center"
                                        w="250px"
                                        px="5px"
                                    >
                                        <Flex alignItems="center" justifyContent="space-between">
                                            {agency.id === currentAgency ? (
                                                <RadioSelected />
                                            ) : (
                                                <RadioUnselected />
                                            )}
                                            <Text pl="5px" fontSize="15px" isTruncated maxW="100px">
                                                {agency.state}
                                            </Text>
                                        </Flex>
                                        <Avatar src={logo} h="40px" w="40px" name={agency.state} />
                                    </Flex>
                                </MenuItem>
                            );
                        })}
                    </MenuList>
                </Menu>
            </FormControl>
        </Box>
    );
};

export { SidebarDropdown };
