import {call, put, takeEvery} from "redux-saga/effects";
import {IResponseWithoutValue} from "../../../common/types";
import API from '../../../common/api/api.base'
import {unsubscribeEmailRequest, unsubscribeEmailSuccess, unsubscribeEmailError} from "./actions";

function* workerUnsubscribeEmail(action: ReturnType<typeof unsubscribeEmailRequest>) {
    console.log(action, 'ACTION')
    try {
        const result: IResponseWithoutValue = yield call(API.post, '/api/subscriptions/unsubscribe-user-email',
            action.payload);
        if (result.success) {
			yield put(unsubscribeEmailSuccess())

        } else {
			yield put(unsubscribeEmailError(result.errors))
        }
    } catch (error) {
		console.log(error, 'workerUnsubscribeEmailError')
    }
}

export default function* watchUnsubscribeEmailSaga() {
    yield takeEvery(unsubscribeEmailRequest.type, workerUnsubscribeEmail);
}
