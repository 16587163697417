import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Flex,
    HStack,
    PinInput,
    PinInputField,
    Button,
    CloseButton,
} from '@chakra-ui/react';
import { BackArrow } from '../../../assets/icons';
import { colors } from '../../../common/constants';
import Countdown from 'react-countdown';
import { Controller, useForm } from 'react-hook-form';
import { VerifyEmailCodePayload } from '../types';
import { fonts, countdownRenderer } from '../../../common/utils';

interface IEnterCodeForEditModal {
    isOpen: boolean;
    onClose: () => void;
    showEditProfileModal: () => void;
    sendCodeToEmail: () => void;
    verifyEmailCode: (v: VerifyEmailCodePayload) => void;
    isCodeVerified: boolean;
    clear: () => void;
}

export const EnterCodeForEditModal: React.FC<IEnterCodeForEditModal> = ({
    isOpen,
    onClose,
    showEditProfileModal,
    sendCodeToEmail,
    verifyEmailCode,
    isCodeVerified,
    clear,
}) => {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
    } = useForm({});
    const values = watch();
    const [resetCounterKey, setResetCounterKey] = useState(new Date().getTime().toString());

    const handleClose = () => {
        onClose();
        clear();
    };

    useEffect(() => {
        if (values?.emailCode?.length === 4) {
            setValue('emailCode', '');
            verifyEmailCode({ code: values?.emailCode });
        }
    }, [values]);

    useEffect(() => {
        if (isCodeVerified && isOpen) {
            handleClose();
            showEditProfileModal();
        }
    }, [isCodeVerified]);

    useEffect(() => {
        if (isOpen) {
            sendCodeToEmail();
            setResetCounterKey(new Date().getTime().toString());
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                w={'71.5vw'}
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <Box as="button" onClick={onClose}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text
                            fontSize="22px"
                            color="brand"
                            textAlign="center"
                            lineHeight="18px"
                            fontWeight={700}
                            fontFamily={fonts.montserrat}
                        >
                            For Edits Enter Code
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody d="flex" flexDirection="column" overflowY="auto">
                    <Flex direction="column" align="center" mt="50px">
                        <Text
                            fontWeight={600}
                            fontSize="16px"
                            color="brand"
                            mb="20px"
                            alignSelf="baseline"
                        >
                            We Sent You A 4-Digit Security Code To Your Mail
                        </Text>
                        <Controller
                            control={control}
                            name="emailCode"
                            render={({ field: { ref, ...restField } }) => (
                                <HStack
                                    spacing="15px"
                                    // w="100%"
                                >
                                    <PinInput placeholder="-" autoFocus={true} {...restField}>
                                        <PinInputField
                                            w="69px"
                                            h="63px"
                                            borderRadius="15px"
                                            ref={ref}
                                            bgColor="white"
                                        />
                                        <PinInputField
                                            w="69px"
                                            h="63px"
                                            borderRadius="15px"
                                            bgColor="white"
                                        />
                                        <PinInputField
                                            w="69px"
                                            h="63px"
                                            borderRadius="15px"
                                            bgColor="white"
                                        />
                                        <PinInputField
                                            w="69px"
                                            h="63px"
                                            borderRadius="15px"
                                            bgColor="white"
                                        />
                                    </PinInput>
                                </HStack>
                            )}
                        />
                        <Text
                            color="green"
                            fontWeight={600}
                            fontSize="18px"
                            lineHeight="18px"
                            mt="30px"
                        >
                            <Countdown
                                renderer={countdownRenderer}
                                date={Date.now() + 2 * 60 * 1000}
                                key={resetCounterKey}
                            />
                        </Text>
                        <Button
                            width="342px"
                            height="59px"
                            variant="main"
                            mt="30px"
                            onClick={sendCodeToEmail}
                        >
                            Resend
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
