import React from 'react';
import {
  FormControlProps,
  FormControl,
  FormLabel,
  InputProps,
  FormErrorMessage,
  useDisclosure,
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import { Calendar } from '../../../../assets/icons';
import { CalendarForDatePicker } from './DatePickerCalendar';
import { colors } from '../../../constants';
import { ItemType } from '../../../types';

interface IDatePicker extends InputProps {
  formControlProps?: FormControlProps;
  errMessage?: string;
  label: string;
  setValue: (value: number) => void;
  displayValue: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  value: number;
  removeBottomMargin?: boolean;
  borderRadius?: string;
  isDark?: boolean;
  maximumDate?: Date;
  minimumDate?: Date;
  width?: string;
  mbCustom?: string;
  customYearList?: () => ItemType[];
}

export const DatePicker = React.forwardRef<null, IDatePicker>(
  (
    {
      formControlProps,
      errMessage,
      label,
      setValue,
      displayValue,
      isDisabled = false,
      isReadOnly = false,
      value,
      removeBottomMargin,
      borderRadius = '14px',
      isDark,
      maximumDate,
      minimumDate,
      width = '100%',
      mbCustom = '26px',
      customYearList,
      ...rest
    },
    ref,
  ) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    return (
      <Popover
        placement={'bottom-start'}
        onClose={onClose}
        isOpen={isDisabled || isReadOnly ? false : isOpen}
      >
        <PopoverTrigger>
          <FormControl isInvalid={!!errMessage} {...formControlProps} width={width}>
            <FormLabel
              color="brand"
              fontWeight="400"
              fontSize="13px"
              marginBottom={0}
              opacity={isDisabled ? 0.4 : 1}
            >
              {label}
            </FormLabel>

            <Button
              onClick={onOpen}
              variant="unstyled"
              borderRadius={borderRadius}
              paddingLeft="12px"
              paddingRight="12px"
              bg={isDark ? '#545B7C' : colors.inputBackground}
              boxShadow={errMessage ? `0 0 0 1px ${colors.chakraErrorRed}` : ''}
              border={`1px solid ${
                errMessage ? colors.chakraErrorRed : isDark ? colors.brand : 'rgb(226, 232, 240)'
              }`}
              isDisabled={isDisabled}
              w={width}
              d="flex"
              justifyContent="space-between"
              height="50px"
              _focus={{
                boxShadow: 'none',
                border: '1px solid #CBD5E0',
              }}
              mb={errMessage || removeBottomMargin ? 0 : mbCustom}
              isActive={!isReadOnly}
            >
              <Text
                color={
                  displayValue && !isDark
                    ? 'brand'
                    : (isDark && displayValue) || (!displayValue && isDark)
                    ? 'white'
                    : 'secondaryFontDarker'
                }
                fontSize="13px"
                fontWeight={400}
              >
                {displayValue ? displayValue : rest.placeholder}
              </Text>
              <Calendar color={colors.secondaryFontDarker} />
            </Button>
            <FormErrorMessage fontSize="13px">{errMessage}</FormErrorMessage>
          </FormControl>
        </PopoverTrigger>

        <PopoverContent boxShadow={'none'} border={'none'}>
          <CalendarForDatePicker
            setValue={setValue}
            onClose={onClose}
            value={value}
            maximumDate={maximumDate}
            minimumDate={minimumDate}
            customYearList={customYearList}
          />
        </PopoverContent>
      </Popover>
    );
  },
);
DatePicker.displayName = 'DatePicker';
