import React from 'react';
import { colors } from '../../common/constants';

type CheckIconType = {
    color?: string;
};

export const CheckIcon: React.FC<CheckIconType> = ({ color = colors.calendarGreen }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.33331 9.33333L7.33331 15.3333L18.6666 4" stroke={color} />
    </svg>
);
