import { createAction } from '@reduxjs/toolkit';
import { WriteToSupportPayloadType } from '../types';
import { PayloadError } from '../../../common/types';

export const writeToSupportRequest = createAction<WriteToSupportPayloadType>(
    'WRITE_TO_SUPPORT_REQUEST',
);

export const writeToSupportSuccess = createAction('WRITE_TO_SUPPORT_SUCCESS');
export const writeToSupportError = createAction<PayloadError[]>('WRITE_TO_SUPPORT_ERROR');
