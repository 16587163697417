import React from 'react';
import { Flex } from '@chakra-ui/react';
import { CustomButton } from '../../../common/components';
import { MultipleFilter } from '../../Listings/components/MultipleFilter';

interface IFiltersBlockProps {
    openCreateNewPost: () => void;
    setTypeIdsSelected: (value: number[]) => void;
    menuValues: { label: string; value: number }[];
    buttonLabel?: string;
    showButton?: boolean;
}

export const FiltersBlock: React.FC<IFiltersBlockProps> = ({
    openCreateNewPost,
    setTypeIdsSelected,
    menuValues,
    buttonLabel = 'Create new',
    showButton = true,
}) => (
    <Flex align="center">
        <MultipleFilter
            label=""
            onApply={value => {
                setTypeIdsSelected(value['Type Of Activity'] as number[]);
            }}
            items={[
                {
                    categoryTitle: 'Type Of Activity',
                    categories: menuValues,
                },
            ]}
        />
        {showButton && (
            <CustomButton
                text={buttonLabel}
                bgColor="brand"
                width="auto"
                minWidth="150px"
                height="50px"
                borderRadius="4px"
                fontWeight={300}
                onClick={openCreateNewPost}
            />
        )}
    </Flex>
);
