import React, { useEffect, useState } from 'react';
import { OpenHousesResponse } from '../../../../pages/Calendar/types';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CustomButton, CustomInput, InfiniteScroll, Loader } from '../../blocks';
import dayjs from 'dayjs';
import ModalWrapper from '../../blocks/ModalWrapper/ModalWrapper';
import { getCalendarOpenHousesRequest } from '../../../../pages/Calendar/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { ListBlock } from './components/ListBlock';
import { SearchMenu } from './components/SearchMenu';
import { OpenHouseDetailsOpenConfig } from '../../../types';
import { OpenhouseDetailsModal } from '../../../../pages/Listings/modals';
import { defaultOpenHouseDetailsOpenConfig } from '../../../../pages/Listings/Listings.container';

interface IOpenHousesModalProps {
    isOpen: boolean;
    onClose: () => void;
    startEndConfig: { start: string; end: string };
}

const OpenHousesModal: React.FC<IOpenHousesModalProps> = ({ isOpen, onClose, startEndConfig }) => {
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
    const [showPostDetailsModal, setShowPostDetailsModal] = useState(false);
    const [postId, setPostId] = useState(0);
    const [priceFrom, setPriceFrom] = useState(0);
    const [priceTo, setPriceTo] = useState(0);
    const [city, setCity] = useState('');
    const [typeIdsSelected, setTypeIdsSelected] = useState<number[]>([]);
    const [showOpenhouseInfoModal, setShowOpenhouseInfoModal] =
        useState<OpenHouseDetailsOpenConfig>(defaultOpenHouseDetailsOpenConfig);

    const { loading, items, loadMoreLoading, totalCount } = useSelector(
        (state: RootStore) => state.calendar.openHousesModal,
    );

    const resetState = () => {
        setPostId(0);
        setPriceFrom(0);
        setPriceTo(0);
        setCity('');
    };

    const getItems = (page?: number) => {
        dispatch(
            getCalendarOpenHousesRequest({
                pageNumber: page || currentPage,
                pageSize: 10,
                categoryId: 3,
                date: startEndConfig.start,
                fromTime: startEndConfig.start,
                toTime: startEndConfig.end,
                cityOrNeighborhood: city,
                listingTypes: typeIdsSelected,
                fromPrice: priceFrom,
                toPrice: priceTo,
            }),
        );
    };

    useEffect(() => {
        if (!priceFrom) {
            setPriceFrom(0);
        }

        if (!priceTo) {
            setPriceTo(0);
        }
    }, [priceFrom, priceTo]);

    useEffect(() => {
        if (!isOpen) {
            resetState();
        }
    }, [onClose]);

    useEffect(() => {
        if (isOpen) {
            getItems();
        }
    }, [isOpen, currentPage]);

    useEffect(() => {
        if (postId) {
            setShowOpenhouseInfoModal({
                isOpen: true,
                isMyListings: false,
                listingStatusId: 0,
                isManageOpenHouse: false,
            });
        }
    }, [postId]);

    const loadMoreItems = () => {
        if (items.length >= totalCount) {
            setOnEndReachedCalled(true);
        } else if (!onEndReachedCalled) {
            setCurrentPage((prev: number) => prev + 1);
        }
    };

    const modalHeader: JSX.Element = (
        <Box>
            <Box
                borderLeftColor="calendarBlue"
                borderLeftWidth="8px"
                bgColor="calendarBlueOpacity"
                w="99%"
            >
                <Text fontWeight={400} fontSize="20px" ml="10px">
                    Open Houses {dayjs(startEndConfig.start).format('ddd DD MMM')}
                </Text>
            </Box>
        </Box>
    );

    const modalSearch: JSX.Element = (
        <Flex flexDirection="row" alignItems="end" justifyContent="space-between" pt="10px">
            <CustomInput
                label="City/Neighborhood"
                width="20%"
                placeholder="e.g. ‘Downtown Los Angeles’"
                onChange={el => setCity(el.target.value)}
                margin="0 10px 0 0"
            />
            <SearchMenu
                width="200px"
                typeIdsSelected={typeIdsSelected}
                setTypeIdsSelected={setTypeIdsSelected}
                menuValues={[
                    { value: 1, label: 'Mfd/Mobile Home' },
                    { value: 2, label: 'Land' },
                    { value: 3, label: 'Multi-Family Home' },
                    { value: 4, label: 'Condo/Townhome/Row Home/Co-Op' },
                    { value: 5, label: 'Single Family Home' },
                ]}
            />
            <CustomInput
                label="Min. Price (1000 $)"
                width="20%"
                placeholder="0"
                onChange={el => setPriceFrom(el.target.value)}
                margin="0 10px"
            />
            <CustomInput
                label="Max. Price (1000 $)"
                width="20%"
                placeholder="0"
                onChange={el => setPriceTo(el.target.value)}
                margin="0 10px 0 0"
            />
            <CustomButton
                text="Search"
                type="submit"
                width="250px"
                height="50px"
                bgColor="brand"
                onClick={getItems}
            />
        </Flex>
    );

    const ModalBody: React.FC = () => (
        <>
            <Text color="brand" fontWeight={600} fontSize="17px" lineHeight="25px">
                Open Houses
            </Text>
            <Box height="100%">
                {loading ? (
                    <Loader spinnerSize="md" centerHeight="100%" />
                ) : (
                    <InfiniteScroll onReachBottom={loadMoreItems}>
                        {items.map((elem: { startTime: string; models: OpenHousesResponse[] }) => (
                            <ListBlock
                                key={elem.startTime}
                                startTime={elem.startTime}
                                models={elem.models}
                                setPostId={setPostId}
                            />
                        ))}
                        {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
                    </InfiniteScroll>
                )}
            </Box>
        </>
    );

    return (
        <>
            <ModalWrapper
                isOpen={isOpen}
                onClose={onClose}
                modalHeader={modalHeader}
                modalBody={<ModalBody />}
                modalSearch={modalSearch}
            />
            <OpenhouseDetailsModal
                showOpenhouseInfoModal={showOpenhouseInfoModal}
                onClose={() => {
                    setShowOpenhouseInfoModal(defaultOpenHouseDetailsOpenConfig);
                    setPostId(0);
                }}
                listingId={postId}
                setShowAddTimeSlotModal={() => {}}
                getItems={getItems}
            />
        </>
    );
};

export { OpenHousesModal };
