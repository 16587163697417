import React from 'react';

type MessageIcon = {
    height?: string;
    width?: string;
};

export const Messages: React.FC<MessageIcon> = ({ height, width }) => (
    <svg
        width={width || '24'}
        height={height || '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.800049 7.1999L12 13.5999L23.2 7.1999M2.40005 2.3999H21.6C22.4837 2.3999 23.2 3.11625 23.2 3.9999V19.9999C23.2 20.8836 22.4837 21.5999 21.6 21.5999H2.40005C1.51639 21.5999 0.800049 20.8836 0.800049 19.9999V3.9999C0.800049 3.11625 1.51639 2.3999 2.40005 2.3999Z"
            stroke="white"
        />
    </svg>
);
