import { call, put, takeEvery } from '@redux-saga/core/effects';
import API from '../../../common/api/api.base';
import { GetDetailsReportSuccessResponse, GetServiceHistorySuccessResponse } from '../types';
import {
    getDetailsReportError,
    getDetailsReportRequest,
    getDetailsReportSuccess,
    getServiceHistoryError,
    getServiceHistoryRequest,
    getServiceHistorySuccess,
} from './actions';
import { IResponseWithCustomValue } from '../../../common/types';

function* workerGetSerciceHistory(action: ReturnType<typeof getServiceHistoryRequest>) {
    try {
        const filter =
            action.payload.filter !== undefined ? `&Filter=${action.payload.filter}` : '';
        const result: IResponseWithCustomValue<GetServiceHistorySuccessResponse> = yield call(
            API.get,
            `/api/request/get-service-history?PageNumber=${action.payload.pageNumber}&PageSize=${action.payload.pageSize}${filter}&Year=${action.payload.year}`,
            {},
        );
        if (result.success) {
            yield put(
                getServiceHistorySuccess({
                    result: result.value,
                    pageNumber: action.payload.pageNumber,
                }),
            );
        } else {
            yield put(getServiceHistoryError(result.errors));
        }
    } catch (err) {
        console.log(err);
    }
}

function* workerGetDetailsReport(action: ReturnType<typeof getDetailsReportRequest>) {
    try {
        const result: IResponseWithCustomValue<GetDetailsReportSuccessResponse> = yield call(
            API.get,
            `/api/request/get-details-report/${action.payload.id}`,
            {},
        );
        if (result.success) {
            yield put(getDetailsReportSuccess(result));
        } else {
            yield put(getDetailsReportError(result.errors));
        }
    } catch (err) {
        console.log(err);
    }
}

export default function* watchServiceHistorySaga() {
    yield takeEvery(getServiceHistoryRequest.type, workerGetSerciceHistory);
    yield takeEvery(getDetailsReportRequest.type, workerGetDetailsReport);
}
