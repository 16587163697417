import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { time12HoursRegExp, time24HoursRegExp } from '../constants';

dayjs.extend(customParseFormat);

export const convertTimeIn24HoursFormat = (time: string) => {
    let timeObj: any;

    if (time24HoursRegExp.test(time)) {
        return time;
    }

    if (time12HoursRegExp.test(time)) {
        time = time.toUpperCase();
        timeObj = dayjs(time, 'hh:mm A');
    } else {
        timeObj = dayjs(time);
    }

    if (!timeObj.isValid()) {
        throw new Error(`Invalid type. It is: ${time}. Can not convert it on Date object`);
    } else {
        return timeObj.format('HH:mm');
    }
};
