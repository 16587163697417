import React from 'react';
import { Box, Flex, Text, Tabs, TabPanels, TabPanel, Container, HStack } from '@chakra-ui/react';
import { TabsSwitcher, FiltersBlock } from '../Opportunities/components';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { BackButton } from '../../common/components';
import { ActivitiesResponseType, AddFeedbackPayload, SelectedActivity } from './types';
import { IdNameTypes, ToggleUserProfileModalPayload } from '../../common/types';
import { AgentActivityTab, MyActivityTab } from './tabs';
import { ActivityIssueModalContainer } from '../../common/components/modals/ActivityIssueModal/ActivityIssueModalContainer';
import { InProgressActivityModal, FeedbackModal } from './modals';
import { RequestAddressesType, RequestInfoType } from '../Requests/types';

interface IActivityProps {
    loadMoreItems: () => void;
    activity: ActivitiesResponseType[];
    loading: boolean;
    loadMoreLoading: boolean;
    tabIndex: number;
    setTabIndex: (value: number) => void;
    setTypeFilter: (v: number[]) => void;
    selectedActivityId: SelectedActivity;
    setSelectedActivityId: (v: SelectedActivity) => void;
    showInProgressActivityModal: boolean;
    setShowInProgressActivityModal: (v: boolean) => void;
    requestAddresses: RequestAddressesType[];
    requestCategories: IdNameTypes[];
    actualDuration: number;
    isOpenIssue: boolean;
    onCloseIssue: () => void;
    onOpenIssue: () => void;
    showFeedbackModal: boolean;
    setShowAddFeedback: (v: boolean) => void;
    addFeedback: (v: AddFeedbackPayload) => void;
    requestInfo: RequestInfoType;
    fetchItems: (page?: number) => void;
    requestByIdLoading: boolean;
    onSetDefaultRequest: () => void;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
    onCreateChat: (userId: number) => void;
}

export const Activity: React.FC<IActivityProps> = ({
    activity,
    loading,
    loadMoreLoading,
    loadMoreItems,
    tabIndex,
    setTabIndex,
    setTypeFilter,
    selectedActivityId,
    setSelectedActivityId,
    showInProgressActivityModal,
    setShowInProgressActivityModal,
    requestAddresses,
    requestCategories,
    actualDuration,
    isOpenIssue,
    onCloseIssue,
    onOpenIssue,
    showFeedbackModal,
    setShowAddFeedback,
    addFeedback,
    requestInfo,
    fetchItems,
    requestByIdLoading,
    onSetDefaultRequest,
    onOpenProfileImage,
    onCreateChat,
}) => (
    <Flex backgroundColor="brand" flexDirection="column" alignItems="center" height="100vh">
        <DashboardHeader />
        <Container
            maxW="container.lg"
            bgColor="white"
            h="90vh"
            mt="15px"
            borderRadius="10px"
            p="25px 33px"
        >
            <HStack mb="16px">
                <BackButton mb="0" text="" />
                <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                    Activity
                </Text>
            </HStack>
            <Tabs variant="unstyled" index={tabIndex}>
                <Flex justifyContent="space-between">
                    <TabsSwitcher
                        setTabIndex={setTabIndex}
                        tabIndex={tabIndex}
                        firstTabLabel="My Tasks"
                        secondTabLabel="My Hires"
                    />
                    <Box w="20px" />
                    <FiltersBlock
                        openCreateNewPost={() => {}}
                        showButton={false}
                        setTypeIdsSelected={setTypeFilter}
                        menuValues={requestCategories.map(elem => ({
                            label: elem.name,
                            value: elem.id,
                        }))}
                    />
                </Flex>
                <TabPanels>
                    <TabPanel p="0" h="100%" pt="10px">
                        <MyActivityTab
                            loading={loading}
                            activity={activity}
                            setActivityId={setSelectedActivityId}
                            loadMoreItems={loadMoreItems}
                            loadMoreLoading={loadMoreLoading}
                            requestCategories={requestCategories}
                        />
                    </TabPanel>
                    <TabPanel p="0" h="100%" pt="10px">
                        <AgentActivityTab
                            loading={loading}
                            activity={activity}
                            setActivityId={setSelectedActivityId}
                            loadMoreItems={loadMoreItems}
                            loadMoreLoading={loadMoreLoading}
                            requestCategories={requestCategories}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
        <InProgressActivityModal
            isOpen={showInProgressActivityModal}
            onClose={() => {
                setShowInProgressActivityModal(false);
                setSelectedActivityId({ id: 0, isMyActivity: undefined });
                onSetDefaultRequest();
            }}
            activity={requestInfo}
            requestAddresses={requestAddresses}
            actualDuration={actualDuration}
            isOpenIssue={isOpenIssue}
            onCloseIssue={onCloseIssue}
            onOpenIssue={onOpenIssue}
            setShowAddFeedback={setShowAddFeedback}
            fetchItems={fetchItems}
            selectedActivityId={selectedActivityId}
            requestByIdLoading={requestByIdLoading}
            onOpenProfileImage={onOpenProfileImage}
            onCreateChat={onCreateChat}
        />
        <ActivityIssueModalContainer
            isOpen={isOpenIssue}
            onClose={onCloseIssue}
            activity={requestInfo}
        />
        <FeedbackModal
            isOpen={showFeedbackModal}
            onClose={() => setShowAddFeedback(false)}
            assistRequestId={requestInfo.id}
            addFeedback={addFeedback}
        />
    </Flex>
);
