import React from 'react';
import { colors } from '../../common/constants';

export const NameInputIcon: React.FC = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4">
            <path
                d="M8.61713 0C7.00086 0 5.42089 0.479279 4.07701 1.37723C2.73313 2.27518 1.68571 3.55147 1.06719 5.04471C0.44867 6.53795 0.286837 8.18107 0.602156 9.76628C0.917474 11.3515 1.69578 12.8076 2.83866 13.9505C3.98153 15.0934 5.43764 15.8717 7.02286 16.187C8.60807 16.5023 10.2512 16.3405 11.7444 15.7219C13.2377 15.1034 14.514 14.056 15.4119 12.7121C16.3099 11.3682 16.7891 9.78827 16.7891 8.172C16.7818 6.00692 15.9184 3.93263 14.3875 2.40168C12.8565 0.870726 10.7822 0.00738041 8.61713 0V0ZM8.61713 2.452C8.94043 2.44605 9.26162 2.50527 9.56154 2.62613C9.86146 2.747 10.134 2.92703 10.3628 3.15549C10.5916 3.38395 10.7721 3.65616 10.8935 3.95588C11.0148 4.2556 11.0746 4.57669 11.0691 4.9C11.0755 5.22389 11.0165 5.54574 10.8955 5.84628C10.7746 6.14682 10.5943 6.41987 10.3654 6.64908C10.1364 6.87829 9.86359 7.05896 9.5632 7.18025C9.26281 7.30155 8.94103 7.36098 8.61713 7.355C8.29332 7.36085 7.97165 7.30131 7.67137 7.17995C7.3711 7.0586 7.09839 6.87791 6.86957 6.64872C6.64074 6.41952 6.4605 6.14652 6.33964 5.84605C6.21877 5.54557 6.15976 5.22381 6.16613 4.9C6.16071 4.57678 6.22043 4.25577 6.34172 3.95612C6.46301 3.65646 6.6434 3.3843 6.87212 3.15585C7.10085 2.92741 7.37323 2.74735 7.67303 2.62643C7.97284 2.5055 8.29392 2.44618 8.61713 2.452ZM8.61713 14.052C7.64949 14.0465 6.69772 13.8055 5.84403 13.3499C4.99033 12.8943 4.26035 12.2378 3.71713 11.437C3.71713 9.803 6.98613 8.904 8.61713 8.904C10.2481 8.904 13.5171 9.804 13.5171 11.437C12.9744 12.2385 12.2446 12.8959 11.3909 13.3522C10.5371 13.8085 9.58514 14.05 8.61713 14.056V14.052Z"
                fill={colors.brand}
            />
        </g>
    </svg>
);
