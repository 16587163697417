import { TypeIds } from '../../common/constants/dropdown.consatnts';

export enum SearchBy {
    Date = 1,
    Location,
}

export type FindAssistanceFillDataForm = {
    helpWith: TypeIds;
    searchBy: SearchBy;
    state: string;
    propertiesToShow: number;
    dateTime: string;
    fromTime: string;
    generalNotes: string;
    bonus: string;
    ifYes: string;
    amount: string;
    agentPreference: string;
    clientIs: string;
    showingScheduled: string;
    privateNotes: string;
    apprDuration: string;
    addresses: Array<{ name: string }>;
    startingAddress: any;
};

export type FindAssistancePayloadType = {
    dateTime: string;
    fromTime: string;
    states: string[];
    addresses?: Array<{
        name: string;
    }>;
    propertiesToShow?: number;
    requestTypeId: number;
    privateNote: string;
    generalNote: string;
    wouldLikeToOfferBonus?: boolean;
    bonusTypeId?: string;
    clientTypeId: number;
    amount?: number;
    isShowingScheduled: boolean;
    isSearchByLocation: boolean;
    preferredAgentId: string | null;
    clientPhone?: string;
    clientName?: string;
    approximateDuration?: number;
    longitude?: number;
    latitude?: number;
    isDateFlexible?: boolean;
    doesInvolveByProperty?: boolean;
    [key: string]: any;
};
