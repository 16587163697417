import React from 'react';
import { NavLink } from 'react-router-dom';
import { Text, Button, Flex } from '@chakra-ui/react';

import { fonts } from '../../../utils';
import { colors } from '../../../constants';

interface ICustomButtonProps {
    text: string;
    href?: string;
}

const DashboardButton: React.FC<ICustomButtonProps> = ({ text, href }) => (
    <Button
        d="flex"
        w="180px"
        borderRadius="100px"
        textAlign="center"
        border="1px solid #888DAB"
        _hover={{
            textDecoration: 'none',
            background: '#E2E6F0',
        }}
        to={href || '#'}
        color={colors.brand}
        fontFamily={fonts.poppins}
        lineHeight="21px"
        fontSize="14px"
        fontWeight="600"
        background={colors.white}
        mr="15px"
        as={NavLink}
    >
        <Flex justifyContent="space-between">
            <Text mr="12px">{text}</Text>
            <Text>&#10095;</Text>
        </Flex>
    </Button>
);

export { DashboardButton };
