import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Avatar, chakra, Flex, IconButton, VStack } from '@chakra-ui/react';

import { Typography } from './Typography/Typography';

import { getAvatarPicture } from '../../utils';

import { toggleUserProfileModal } from '../../../store/actions/profile.actions';

import { DropdownArrowIcon, EmailIcon, PhoneWithWavesIcon } from '../../../assets/icons';

import { AvatarImageType, ToggleUserProfileModalPayload } from '../../types';

interface IProps {
    avatar: AvatarImageType;
    fullName: string;
    agencyName: string | null;
    phoneNumber: string | null;
    email: string | null;
    userId: number;
}

const AgentInfoCard: FC<IProps> = ({
    avatar,
    fullName,
    agencyName,
    phoneNumber,
    email,
    userId,
    ...props
}) => {
    const dispatch = useDispatch();

    const onOpenProfileImage = (payload: ToggleUserProfileModalPayload) =>
        dispatch(toggleUserProfileModal(payload));

    return (
        <Flex
            borderRadius="26px"
            bg="inputBackground"
            px="16px"
            py="20px"
            alignItems="center"
            justifyContent="space-between"
            {...props}
        >
            <Flex alignItems="center">
                <Avatar boxSize="72px" src={getAvatarPicture(avatar)} mr="35px" name={fullName} />
                <VStack alignItems={'stretch'} spacing={'3px'}>
                    <Typography size={13} weight={700}>
                        {fullName}
                    </Typography>
                    {agencyName && (
                        <Typography size={13} weight={500}>
                            {agencyName}
                        </Typography>
                    )}
                    {phoneNumber && (
                        <Flex alignItems={'center'}>
                            <PhoneWithWavesIcon fill={'#868AA4'} />
                            <Typography ml={'5px'} size={12}>
                                {phoneNumber}
                            </Typography>
                        </Flex>
                    )}
                    {email && (
                        <Flex alignItems={'center'}>
                            <EmailIcon />
                            <Typography ml={'5px'} size={12}>
                                {email}
                            </Typography>
                        </Flex>
                    )}
                </VStack>
            </Flex>
            {userId && (
                <IconButton
                    onClick={() => {
                        onOpenProfileImage({
                            userId,
                            value: true,
                        });
                    }}
                    bg="transparent"
                    alignSelf="center"
                    aria-label="Navigate to profile image"
                    icon={
                        <chakra.span transform="rotate(-90deg)">
                            <DropdownArrowIcon />
                        </chakra.span>
                    }
                />
            )}
        </Flex>
    );
};

export default AgentInfoCard;
