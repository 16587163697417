import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const addLicenseSchema = yup.object().shape({
    state: yup.string().required(ErrorMessages.REQUIRED),
    licenseNumber: yup.string().required(ErrorMessages.REQUIRED),
    agency: yup.number().positive(ErrorMessages.REQUIRED).typeError(ErrorMessages.REQUIRED),
    mlsId: yup.string(),
});

export const addLicenseResolver = yupResolver(addLicenseSchema);
