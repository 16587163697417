import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { FieldArrayMethodProps } from 'react-hook-form';
import { RequestInfoType, RequestAddressesType } from '../../../../../pages/Requests/types';
import { TypeAhead } from '../../../../components';
import { carryAddressLoadOptions } from '../../../../utils';

interface IAdditionalAddressProps {
    requestId: number;
    addingAddressValue: string;
    setAddingAddressValue: (value: string) => void;
    addingMode: boolean;
    setAddingMode: (value: boolean) => void;
    append: (
        value: Partial<RequestAddressesType> | Partial<RequestAddressesType>[],
        options?: FieldArrayMethodProps | undefined,
    ) => void;
}

export const AdditionalAddress: React.FC<IAdditionalAddressProps> = ({
    addingAddressValue,
    addingMode,
    append,
    requestId,
    setAddingAddressValue,
    setAddingMode,
}) => {
    return (
        <Box>
            {!addingMode ? (
                <Button
                    variant="unstyled"
                    color="brand"
                    fontSize="13px"
                    fontWeight={700}
                    textDecorationLine="underline"
                    textTransform="capitalize"
                    onClick={() => setAddingMode(true)}
                >
                    +Add Any Additional Properties Showed
                </Button>
            ) : (
                <>
                    <TypeAhead
                        placeholder="Enter"
                        label="Address"
                        onStateChange={ev => {
                            setAddingAddressValue(ev?.label || '');
                        }}
                        loadOptions={carryAddressLoadOptions(false, '')}
                        errors={''}
                    />
                    <Button
                        variant="unstyled"
                        color="brand"
                        fontSize="13px"
                        fontWeight={700}
                        textDecorationLine="underline"
                        textTransform="capitalize"
                        onClick={() => {
                            if (addingAddressValue) {
                                append({
                                    name: addingAddressValue,
                                    isCompleted: true,
                                    id: 0,
                                    requestId,
                                });
                                setAddingAddressValue('');
                            }
                            setAddingMode(false);
                        }}
                    >
                        +Add Address
                    </Button>
                </>
            )}
        </Box>
    );
};
