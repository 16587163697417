import { createAction } from '@reduxjs/toolkit';
import {
    ChangePassword,
    ChangePasswordSuccess,
    PasswordConfirm,
    SendPinCode,
    SendPinCodeSuccess,
} from '../types';
import { PayloadError, IResponseWithCustomValue } from '../../../../common/types';

export const changePassword = createAction<ChangePassword>('CHANGE_PASSWORD');
export const changePasswordSuccess = createAction<ChangePasswordSuccess>('CHANGE_PASSWORD_SUCCESS');
export const changePasswordError = createAction<PayloadError[]>('CHANGE_PASSWORD_ERROR');

export const sendPinCode = createAction<SendPinCode>('SEND_PIN_CODE');
export const sendPinCodeSuccess =
    createAction<IResponseWithCustomValue<SendPinCodeSuccess>>('SEND_PIN_CODE_SUCCESS');
export const sendPinCodeError = createAction<PayloadError[]>('SEND_PIN_CODE_ERROR');

export const setNewPassword = createAction<PasswordConfirm>('SET_NEW_PASSWORD');
export const setNewPasswordSuccess = createAction<IResponseWithCustomValue<SendPinCodeSuccess>>(
    'SET_NEW_PASSWORD_SUCCESS',
);
export const setNewPasswordError = createAction<PayloadError[]>('SET_NEW_PASSWORD_ERROR');
