import React from 'react';
import { Box, TabList, Tab, Text } from '@chakra-ui/react';
import { colors } from '../../../common/constants';

const Indicator: React.FC = () => (
    <Box
        bg="indicatorGreen"
        position="absolute"
        w="90%"
        h="3px"
        boxShadow={`0px -1px 3px ${colors.indicatorGreenShadow}`}
        bottom={0}
        left="5%"
    />
);

interface ITabsSwitcherProps {
    setTabIndex: (value: number) => void;
    tabIndex: number;
    firstTabLabel: string;
    secondTabLabel: string;
}

export const TabsSwitcher: React.FC<ITabsSwitcherProps> = ({
    setTabIndex,
    tabIndex,
    firstTabLabel,
    secondTabLabel,
}) => (
    <TabList bg="inputBackground" borderRadius="20px" w="415px" h="55px">
        <Tab w="100%" p="0" onClick={() => setTabIndex(0)} position="relative">
            <Text
                fontSize="14px"
                color={tabIndex === 0 ? 'brand' : 'secondaryFontDarker'}
                fontWeight={600}
            >
                {firstTabLabel}
            </Text>
            {tabIndex === 0 && <Indicator />}
        </Tab>
        <Tab onClick={() => setTabIndex(1)} w="100%" p="0" position="relative">
            <Text
                fontSize="14px"
                color={tabIndex === 1 ? 'brand' : 'secondaryFontDarker'}
                fontWeight={600}
            >
                {secondTabLabel}
            </Text>
            {tabIndex === 1 && <Indicator />}
        </Tab>
    </TabList>
);
