import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import {
    Box,
    Checkbox,
    CloseButton,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

import { OneOptionInfoElem } from './components/OneOptionInfoElem';
import { CustomButton } from '../../../components';

import { convertTimeIn12Hours } from '../../../utils';

import { IWillPayAgentForHostingIcon } from '../../../../assets/icons';

import { RootStore } from '../../../../store';

import { colors, sellerTypes } from '../../../constants';

import { TimeSlotType } from '../../../../common/types';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    displayData: TimeSlotType;
    cancelButtonText?: string;
    cancelButtonAction?: () => void;
    confirmButtonText: string;
    confirmButtonAction: () => void;
    timeZone?: string;
}

export const OpenHouseTImeSlotInfoModal: FC<IProps> = ({
    isOpen,
    onClose,
    displayData,
    cancelButtonText = 'Back',
    cancelButtonAction = () => onClose(),
    confirmButtonText,
    confirmButtonAction,
    timeZone,
}) => {
    if (!isOpen) return null;

    const {
        date,
        startTime,
        endTime,
        clientTypeId,
        bonusAmount,
        privateNote,
        openHouseAudienceId,
        openHouseTypeId,
        virtualOpenHouseLink,
        iWillPayAgentForHosting,
        wouldLikeToOfferBonus,
        bonusTypeId,
    } = displayData;

    const { openHouseAudiences, openHouseTypes } = useSelector(
        (state: RootStore) => state.directories,
    );

    const getLabelFromStateObject = (
        data: { id: number; name: string }[],
        fieldValue: string | number,
    ) => {
        const targetObject = data.find(({ id }) => id === Number(fieldValue));

        return targetObject?.name || '';
    };

    const wouldLikeBonus = String(wouldLikeToOfferBonus) === 'true' ? 'Yes' : 'No';
    const bonusType = String(bonusTypeId) === '1' ? '%' : '$';
    const seller = getLabelFromStateObject(sellerTypes, clientTypeId);
    const openHouseAudienceLabel = getLabelFromStateObject(openHouseAudiences, openHouseAudienceId);
    const typeOfOpenHouseLabel = getLabelFromStateObject(openHouseTypes, openHouseTypeId);
    const iWillPay = String(iWillPayAgentForHosting) === 'true';

    const convertDate = () => {
        const dayjsDate = dayjs(date);

        if (dayjsDate.isValid()) {
            return dayjsDate.format('MM/DD/YY');
        } else {
            console.error(
                `Invalid date format. It should be like corrent string for new Date creating or like milliseconds of timestamp. Your current date is ${date}`,
            );

            return String(date);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl" scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent
                maxHeight="90vh"
                borderRadius="18px"
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <ModalHeader
                    d="flex"
                    w="800px"
                    justifyContent="space-between"
                    flexDirection="row"
                    mt="20px"
                >
                    <Text color="brand">Open-House Coverage Request</Text>
                    <Box onClick={onClose}>
                        <CloseButton />
                    </Box>
                </ModalHeader>

                <ModalBody overflowY="auto">
                    <form>
                        <Box>
                            <SimpleGrid columns={2} gap="50px" rowGap="0px">
                                <OneOptionInfoElem
                                    displayData={{
                                        label: 'Date:*',
                                        value: convertDate(),
                                    }}
                                />
                                <HStack align="baseline">
                                    <OneOptionInfoElem
                                        displayData={{
                                            label: 'Start Time:',
                                            value: convertTimeIn12Hours(startTime, timeZone),
                                        }}
                                    />
                                    <OneOptionInfoElem
                                        displayData={{
                                            label: 'End Time',
                                            value: convertTimeIn12Hours(endTime, timeZone),
                                        }}
                                    />
                                </HStack>

                                <OneOptionInfoElem
                                    displayData={{
                                        label: 'Host Of Open House',
                                        value: 'Agent Hosted',
                                    }}
                                />

                                <OneOptionInfoElem
                                    displayData={{
                                        label: 'Seller is:',
                                        value: seller,
                                    }}
                                />

                                <OneOptionInfoElem
                                    displayData={{
                                        label: 'Bonus Offer On Ratification Of Contract Within 48-Hours Of Open House:',
                                        value: wouldLikeBonus,
                                    }}
                                    w="350px"
                                />

                                {wouldLikeBonus === 'Yes' && (
                                    <HStack align="baseline">
                                        <OneOptionInfoElem
                                            displayData={{
                                                label: 'Bonus Type:',
                                                value: bonusType,
                                            }}
                                        />
                                        <OneOptionInfoElem
                                            displayData={{
                                                label: 'Bonus Amount',
                                                value: String(bonusAmount),
                                            }}
                                        />
                                    </HStack>
                                )}

                                <OneOptionInfoElem
                                    displayData={{
                                        label: 'Private Note:',
                                        value: privateNote,
                                    }}
                                />

                                <OneOptionInfoElem
                                    displayData={{
                                        label: 'Open House Audience:',
                                        value: openHouseAudienceLabel,
                                    }}
                                />

                                <OneOptionInfoElem
                                    displayData={{
                                        label: 'Type of Open House:',
                                        value: typeOfOpenHouseLabel,
                                    }}
                                />

                                <OneOptionInfoElem
                                    displayData={{
                                        label: 'Link To Virtual Open House:',
                                        value: virtualOpenHouseLink,
                                    }}
                                />
                            </SimpleGrid>

                            {iWillPay && (
                                <Flex align="center" mb="85px">
                                    <IWillPayAgentForHostingIcon />
                                    <Text color="brand" fontWeight="300" fontSize="14px" ml="8px">
                                        I Will Pay Agent For Hosting This O/H
                                    </Text>
                                </Flex>
                            )}
                        </Box>

                        <Flex justifyContent="space-evenly" row-gap="15px" my="50px">
                            <CustomButton
                                text={cancelButtonText}
                                color={colors.brand}
                                variant="outline"
                                borderColor={colors.brand}
                                minH="50px"
                                maxW="347px"
                                onClick={cancelButtonAction || onClose}
                            />

                            <CustomButton
                                text={confirmButtonText}
                                bgColor={colors.brand}
                                minH="50px"
                                maxW="347px"
                                onClick={confirmButtonAction}
                            />
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
