import { put, call, takeEvery } from 'redux-saga/effects';
import {
    getFeedbackUserInfo,
    getFeedbackUserInfoSuccess,
    getFeedbackUserInfoError,
} from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithCustomValue } from '../../../../common/types';
import { FeedbackUserInfoType } from '../../types';

function* workerGetFeedbackUserinfo(action: ReturnType<typeof getFeedbackUserInfo>) {
    console.log('getFeedbackUserInfo action', action);
    try {
        const result: IResponseWithCustomValue<{ userInfo: FeedbackUserInfoType }> = yield call(
            API.get,
            `/api/request/get-feedback-user-info/${action.payload}`,
            {},
        );
        if (result.success) {
            yield put(getFeedbackUserInfoSuccess(result.value.userInfo));
            console.log('workerGetFeedbackUserinfo', result);
        } else {
            yield put(getFeedbackUserInfoError(result.errors));
            console.log('workerGetFeedbackUserinfo error', result);
        }
    } catch (error) {
        console.log('workerGetFeedbackUserinfo error', error);
    }
}

export default function* watchGetFeedbackUserInfoSaga() {
    yield takeEvery(getFeedbackUserInfo.type, workerGetFeedbackUserinfo);
}
