import React from 'react';

export const LogoutIcon: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 10L14 14.3333M18 10L14 6M18 10L5.33333 10M10.6667 18L2 18L2 2L10.6667 2"
            stroke="#EB5757"
        />
    </svg>
);
