import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, Text, Container, HStack, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';

import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { TabsSwitcher } from '../../common/components/blocks/TabsSwitcher';
import { BackButton } from '../../common/components';
import { MyListingsTab } from '../Listings/tabs';
import { AddTimeSlotModal, ListingDetailsModal, OpenhouseDetailsModal } from '../Listings/modals';

import {
    OpenHouseDetailsOpenConfig,
    ListingsType,
    TimeSlotType,
    myClientFromServerType,
} from '../../common/types';

import { RootStore } from '../../store';
import {
    getListingTypesRequest,
    getMyListings,
    getMyClients,
    getOpenHouseInfo,
} from '../../store/actions/listings.actions';
import {
    getOpenHouseAudiences,
    getOpenHouseHostTypes,
    getOpenHouseTypes,
} from '../../store/actions/directories.actions';
import { useDelta } from 'react-delta';
import { setConnectPaymentModalOpen } from '../Notifications/store/actions';
import PeopleCardsContainer from '../Listings/tabs/PeopleCardsContainer';

export const defaultOpenHouseDetailsOpenConfig = {
    isOpen: false,
    isMyListings: true,
    isManageOpenHouse: false,
    listingStatusId: 0,
};

interface IListingsProps {}

const tabsOptions = [
    {
        index: 0,
        label: 'My Listings',
    },
    {
        index: 1,
        label: 'My Clients',
    },
];

export const MyStuff: React.FC<IListingsProps> = ({}) => {
    const [toShowListingId, setToShowListingId] = useState(0);
    const [showAddTimeSlotModal, setShowAddTimeSlotModal] = useState(false);
    const [timeSlotModalCompletedFieldsData, setTimeSlotModalCompletedFieldsData] = useState<
        TimeSlotType | undefined
    >(undefined);
    const [showOpenhouseInfoModal, setShowOpenhouseInfoModal] =
        useState<OpenHouseDetailsOpenConfig>(defaultOpenHouseDetailsOpenConfig);

    const [showListingInfoModal, setShowListingInfoModal] = useState(false);
    const [tabIndex, setTabIndex] = useState(tabsOptions[0].index);

    const [myClientsPageNumber, setMyClientsPageNumber] = useState(1);
    const [onEndReachedMyClientsCalled, setOnEndReachedMyClientsCalled] = useState(false);

    const { loading: addSlotLoading, errors: addSlotErrors } = useSelector(
        (state: RootStore) => state.listings.addTimeSlot,
    );

    const { loading: updateSlotLoading, errors: updateSlotErrors } = useSelector(
        (state: RootStore) => state.listings.updateTimeSlot,
    );

    const { openHouseTypes, openHouseAudiences, openHouseHostTypes, openHouseTypesLoading } =
        useSelector((state: RootStore) => state.directories);

    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed = roles?.some((role: string) => role === 'user');

    const addSlotLoadingDelta = useDelta(addSlotLoading);
    const updateSlotLoadingDelta = useDelta(updateSlotLoading);

    const dispatch = useDispatch();
    const onGetOpenHouseTypes = () => dispatch(getOpenHouseTypes());
    const onGetOpenHouseHostTypes = () => dispatch(getOpenHouseHostTypes());
    const onGetOpenHouseAudiences = () => dispatch(getOpenHouseAudiences());
    const onGetListingTypes = () => dispatch(getListingTypesRequest());

    useEffect(() => {
        if (tabIndex === 0) {
            dispatch(getMyListings());
        } else {
            dispatch(
                getMyClients({
                    pageNumber: myClientsPageNumber,
                    pageSize: 20,
                }),
            );
        }
    }, [tabIndex, myClientsPageNumber]);

    useEffect(() => {
        onGetListingTypes();
        onGetOpenHouseTypes();
        onGetOpenHouseHostTypes();
        onGetOpenHouseAudiences();
    }, []);

    useEffect(() => {
        if (addSlotLoadingDelta && addSlotLoadingDelta.prev) {
            if (addSlotLoadingDelta.prev && !addSlotLoadingDelta.curr && !addSlotErrors.length) {
                setShowAddTimeSlotModal(false);
                dispatch(getMyListings());
                dispatch(getOpenHouseInfo(toShowListingId));
            }
        }

        if (updateSlotLoadingDelta && updateSlotLoadingDelta.prev) {
            if (
                updateSlotLoadingDelta.prev &&
                !updateSlotLoadingDelta.curr &&
                !updateSlotErrors.length
            ) {
                setShowAddTimeSlotModal(false);
                dispatch(getMyListings());
                dispatch(getOpenHouseInfo(toShowListingId));
            }
        }
    }, [addSlotLoadingDelta, updateSlotLoadingDelta]);

    const onOpenModal = (
        value: number,
        listing: ListingsType,
        isMyListings: boolean,
        isManageOpenHouse: boolean,
    ) => {
        setToShowListingId(value);
        if (listing.isOpenHouse) {
            setShowOpenhouseInfoModal({
                isOpen: true,
                listingStatusId: listing.listingStatusId || 0,
                isMyListings,
                isManageOpenHouse,
            });
        } else {
            setShowListingInfoModal(true);
        }
    };

    const onScheduleOpenHouse = (value: number) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            return;
        }
        setToShowListingId(value);
        setShowAddTimeSlotModal(true);
    };

    const {
        loading: myListingsLoading,
        active: activeListings,
        pending: pendingListings,
        closed: closedListings,
    } = useSelector((state: RootStore) => state.listings.myListings);

    const {
        loading: myClientsLoading,
        items: myClients,
        loadMoreLoading: myClientsMoreLoading,
        totalCount: myClientsTotalCount,
    } = useSelector((state: RootStore) => state.listings.myClients);

    const myClientsTransformed = myClients.map(
        ({ clientName, typeName, clientPhone }: myClientFromServerType) => ({
            firstName: clientName,
            agencyName: typeName,
            phoneNumber: clientPhone,
        }),
    );

    const loadMoreMyClients = () => {
        if (myClients.length >= myClientsTotalCount) {
            setOnEndReachedMyClientsCalled(true);
        } else if (!onEndReachedMyClientsCalled) {
            setMyClientsPageNumber((prev: number) => prev + 1);
        }
    };

    return (
        <>
            <Flex backgroundColor="brand" flexDirection="column" alignItems="center" h="100vh">
                <DashboardHeader />
                <Container
                    maxW="container.xl"
                    bgColor="#FFFFFF"
                    h="90vh"
                    mt="15px"
                    borderRadius="10px"
                    p="25px 33px"
                >
                    <HStack mb="16px">
                        <BackButton mb="0" text="" />
                        <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold">
                            My Stuff
                        </Text>
                    </HStack>
                    <Tabs variant="unstyled" index={tabIndex}>
                        <Flex justifyContent="space-between">
                            <TabsSwitcher
                                tabIndex={tabIndex}
                                setTabIndex={setTabIndex}
                                tabsOptions={tabsOptions}
                            />
                        </Flex>
                        <TabPanels>
                            <TabPanel p="0" h="100%" pt="10px">
                                <MyListingsTab
                                    loading={myListingsLoading}
                                    activeListings={activeListings}
                                    closedListings={closedListings}
                                    pendingListings={pendingListings}
                                    onOpenModal={onOpenModal}
                                    onScheduleOpenHouse={onScheduleOpenHouse}
                                />
                            </TabPanel>
                            <TabPanel p="0" h="100%" pt="10px">
                                <PeopleCardsContainer
                                    items={myClientsTransformed}
                                    loading={myClientsLoading}
                                    loadMoreLoading={myClientsMoreLoading}
                                    loadMoreItems={loadMoreMyClients}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </Flex>
            <OpenhouseDetailsModal
                showOpenhouseInfoModal={showOpenhouseInfoModal}
                onClose={() => {
                    setShowOpenhouseInfoModal(defaultOpenHouseDetailsOpenConfig);
                    setToShowListingId(0);
                }}
                listingId={toShowListingId}
                setShowAddTimeSlotModal={setShowAddTimeSlotModal}
                getItems={() => dispatch(getMyListings())}
                createTimeSlotModalCompletedFieldsData={(data: TimeSlotType) => {
                    setTimeSlotModalCompletedFieldsData(data);
                }}
            />
            <AddTimeSlotModal
                isOpen={showAddTimeSlotModal}
                onClose={() => {
                    setShowAddTimeSlotModal(false);
                    setTimeSlotModalCompletedFieldsData(undefined);
                }}
                listingId={toShowListingId}
                loading={addSlotLoading}
                openHouseTypesLoading={openHouseTypesLoading}
                openHouseHostTypes={openHouseHostTypes}
                openHouseAudiences={openHouseAudiences}
                openHouseTypes={openHouseTypes}
                completedFieldsData={timeSlotModalCompletedFieldsData}
            />
            <ListingDetailsModal
                isOpen={showListingInfoModal}
                onClose={() => {
                    setShowListingInfoModal(false);
                    setToShowListingId(0);
                }}
                listingId={toShowListingId}
            />
        </>
    );
};
