import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { Loader } from '../Loader/Loader';
import { InfiniteScroll } from '../InfiniteScroll/InfiniteScroll';
import { ListBlock } from './ListBlock';
import { AvailableForApplicationResponse } from '../../../../pages/Calendar/types';

interface ICalendarModalBodyProps {
    loading: boolean;
    loadMoreItems: () => void;
    loadMoreLoading: boolean;
    items: { startTime: string; models: AvailableForApplicationResponse[] }[];
    setPostId: (value: number) => void;
    placeholder: string;
    bgColor: string;
    borderLeftColor: string;
}

export const CalendarModalBody: React.FC<ICalendarModalBodyProps> = ({
    items,
    loadMoreItems,
    loadMoreLoading,
    loading,
    placeholder,
    setPostId,
    bgColor,
    borderLeftColor,
}) => (
    <Box height="calc(85vh - 260px)">
        {loading ? (
            <Loader spinnerSize="md" centerHeight="100%" />
        ) : (
            <InfiniteScroll onReachBottom={loadMoreItems}>
                {items.length ? (
                    items.map(
                        (elem: {
                            startTime: string;
                            models: AvailableForApplicationResponse[];
                        }) => (
                            <ListBlock
                                key={elem.startTime}
                                startTime={elem.startTime}
                                models={elem.models}
                                setPostId={setPostId}
                                bgColor={bgColor}
                                borderLeftColor={borderLeftColor}
                            />
                        ),
                    )
                ) : (
                    <Center height="100%">
                        <Text color="brand">{placeholder}</Text>
                    </Center>
                )}
                {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
            </InfiniteScroll>
        )}
    </Box>
);
