import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { MoneyContainer, CategoriesSection, HistoryTable, YearFilter } from './components';
import { BackButton } from '../../common/components/blocks';
import { ServiceHistoryItem} from './types';


interface IServiceHistory {
    activeCategory: number;
    setActiveCategory: (v: number) => void;
    currentPage: number;
    setCurrentPage: (v: number) => void;
    showDetailsModal: boolean;
    setShowDetailsModal: (v: number) => void;
    serviceHistoryItems: Array<ServiceHistoryItem>;
    totalCount: number
    income: number,
    outcome: number
    closeModal: () => void;
    requestType: string;
    setRequestType: (v: string) => void;
    selectedYear: number;
    setSelectedYear: (value: number) => void;
    loading: boolean;

}

export const ServiceHistory: React.FC<IServiceHistory> = ({
    activeCategory,
    setActiveCategory,
    currentPage,
    setCurrentPage,
    setShowDetailsModal,
    serviceHistoryItems,
    totalCount,
    income,
    outcome,
    setRequestType,
    selectedYear,
    setSelectedYear,
    loading,
}) => {
    return (
        <>
            <Flex justify="space-between" align="center" mb="40px" width="100%">
                <BackButton mb={'0'} text={''} />
                <Text fontSize="22px" fontWeight="700" color="brand">
                    Service History
                </Text>
                <Box w="40px" />
            </Flex>
            <Flex align="center" direction="column" width="100%">
                <Flex flex="1" justify="space-between" width="100%" mb="20px">
                    <MoneyContainer amount={income} type="Incoming" />
                    <MoneyContainer amount={outcome} type="Outgoing" />
                </Flex>
                <CategoriesSection activeCategoty={activeCategory} onClick={setActiveCategory} />
                <Flex w="100%" justifyContent="flex-end" my="10px">
                    <YearFilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
                </Flex>
                <HistoryTable
                    totalCount={totalCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    data={serviceHistoryItems}
                    showDetailsModal={setShowDetailsModal}
                    setRequestType={setRequestType}
                    loading={loading}
                />
            </Flex>
        </>
    );
};
