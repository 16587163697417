import React from 'react';
import { Flex, Text } from '@chakra-ui/layout';

interface ITabSwitcher {
    activeTab: number;
    onClick: (v: number) => void;
}

export const TabSwitcher: React.FC<ITabSwitcher> = ({ activeTab, onClick }) => {
    return (
        <Flex width="100%" flex="1" bgColor="#F5F6F8" borderRadius="10px">
            <Flex
                flex="0.5"
                justify="center"
                _hover={{ cursor: 'pointer' }}
                borderBottom="3px solid"
                borderColor={activeTab === 1 ? '#2CC197' : 'transparent'}
                onClick={() => onClick(1)}
                py="10px"
                borderBottomLeftRadius="10px"
            >
                <Text color={activeTab === 1 ? '#2C335C'  : '#888DAB'}>Google Calendar</Text>
            </Flex>
            <Flex
                flex="0.5"
                justify="center"
                _hover={{ cursor: 'pointer' }}
                borderBottom="3px solid"
                borderColor={activeTab === 2 ? '#2CC197' : 'transparent'}
                onClick={() => onClick(2)}
                py="10px"
                borderBottomRightRadius="10px"
            >
                <Text color={activeTab === 2 ? '#2C335C'  : '#888DAB'}>Manualy</Text>
            </Flex>
        </Flex>
    );
};
