import { put, call, takeEvery } from 'redux-saga/effects';
import {
    acceptRequest,
    acceptRequestSuccess,
    acceptRequestError,
    getAgencyRequests,
} from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithoutValue } from './../../../../common/types';
import { getDashboardRequest } from '../../../../store/actions/dashboard.actions';

function* workerAcceptRequest(action: ReturnType<typeof acceptRequest>) {
    try {
        const result: IResponseWithoutValue = yield call(API.put, `/api/request/accept`, {
            requestId: action.payload.RequestId,
            isAccepted: action.payload.IsAccepted,
        });
        console.log('acceptRequest action', action, result);
        if (result.success) {
            yield put(acceptRequestSuccess(result));
            if (action.payload.dashboardRequestPayload) {
                yield put(getDashboardRequest(action.payload.dashboardRequestPayload));
            } else {
               yield put(
                getAgencyRequests({
                    pageNumber: 1,
                    pageSize: action.payload.PageSize,
                    categoryIds: action.payload.categoryIds,
                    date: action.payload.date,
                }),
            );
            }
        } else {
            yield put(acceptRequestError(result.errors));
        }
    } catch (error) {
        console.log('error', error);
    }
}

export default function* watchAcceptRequestSaga() {
    yield takeEvery(acceptRequest.type, workerAcceptRequest);
}
