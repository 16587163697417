import React from 'react';
import { colors } from '../../common/constants';

export const WavesIcon: React.FC = () => (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.13646 4.964C8.54713 4.964 8.03579 4.84267 7.60246 4.6C7.16913 4.34 6.65779 3.96733 6.06846 3.482C5.60046 3.08333 5.21046 2.79733 4.89846 2.624C4.60379 2.43333 4.27446 2.338 3.91046 2.338C3.30379 2.338 2.81846 2.56333 2.45446 3.014C2.10779 3.44733 1.91713 4.04533 1.88246 4.808H0.634461C0.669128 3.54267 0.972461 2.572 1.54446 1.896C2.13379 1.22 2.90513 0.881999 3.85846 0.881999C4.44779 0.881999 4.95913 1.012 5.39246 1.272C5.82579 1.51467 6.33713 1.87867 6.92646 2.364C7.37713 2.76267 7.75846 3.05733 8.07046 3.248C8.38246 3.43867 8.72046 3.534 9.08446 3.534C9.69113 3.534 10.1678 3.30867 10.5145 2.858C10.8785 2.40733 11.0778 1.792 11.1125 1.012H12.3605C12.3258 2.29467 12.0138 3.274 11.4245 3.95C10.8525 4.626 10.0898 4.964 9.13646 4.964ZM9.13646 10.918C8.54713 10.918 8.03579 10.7967 7.60246 10.554C7.16913 10.294 6.65779 9.92133 6.06846 9.436C5.60046 9.03733 5.21046 8.75133 4.89846 8.578C4.60379 8.38733 4.27446 8.292 3.91046 8.292C3.30379 8.292 2.81846 8.51733 2.45446 8.968C2.10779 9.41867 1.91713 10.0253 1.88246 10.788H0.634461C0.669128 9.50533 0.972461 8.526 1.54446 7.85C2.13379 7.174 2.90513 6.836 3.85846 6.836C4.44779 6.836 4.95913 6.966 5.39246 7.226C5.82579 7.46867 6.33713 7.83267 6.92646 8.318C7.37713 8.71667 7.75846 9.01133 8.07046 9.202C8.38246 9.39267 8.72046 9.488 9.08446 9.488C9.69113 9.488 10.1678 9.26267 10.5145 8.812C10.8785 8.36133 11.0778 7.75467 11.1125 6.992H12.3605C12.3258 8.25733 12.0138 9.228 11.4245 9.904C10.8525 10.58 10.0898 10.918 9.13646 10.918Z"
            fill={colors.brand}
        />
    </svg>
);
