import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
    Flex,
    ModalBody,
    Text,
    Image,
    Wrap,
    WrapItem,
    Tag,
} from '@chakra-ui/react';

import { CustomModalWrapper } from '../../components';
import { Loader } from '../../../../common/components';

import {
    getAdvertisingDetailsPostRequest,
} from '../../store/actions';
import { RootStore } from '../../../../store';

import { baseUrl } from '../../../../common/api/api.base';

import { colors } from '../../../../common/constants';

import { IdNameTypes } from '../../../../common/types';
import { AdvertisingPostTypeResponse } from '../../types';

interface IAdvertisingDetailsPostModalProps {
    size: string;
    isOpen: boolean;
    onClose: () => void;
    title: string;
    detailsPostId: number;
}

export const AdvertisingDetailsPostModal: React.FC<IAdvertisingDetailsPostModalProps> = ({
    size,
    isOpen,
    onClose,
    title,
    detailsPostId,
}) => {
    const dispatch = useDispatch();
    const { post, loading }: {post: AdvertisingPostTypeResponse, loading: boolean} = useSelector((state: RootStore) => state.posts.advertisingDetailsPost);

    useEffect(() => {
        if (isOpen && detailsPostId) {
            dispatch(getAdvertisingDetailsPostRequest(detailsPostId));
        }
    }, [isOpen, detailsPostId]);

    return (
        <CustomModalWrapper isOpen={isOpen} title={title} size={size} onClose={onClose}>
            <ModalBody h={loading ? '85vh' : 'auto'}>
                {loading ? (
                    <Loader spinnerSize={'md'} centerHeight={'100%'} />
                ) : (
                    <>
                        <Flex justifyContent={'space-between'} align={'center'} mt={'27px'}>
                            <Flex align={'center'}>
                                <Text
                                    ml={'21px'}
                                    color={'#888DAB'}
                                    lineHeight={'21px'}
                                    fontSize={'14px'}
                                    fontWeight={400}
                                >
                                    {dayjs(post.createdAt).format('MM/DD/YY')}
                                </Text>
                            </Flex>
                        </Flex>
                        {/* Image Box*/}
                        {post.photo.itemExtension && (
                            <Image
                                mt={'13px'}
                                h={'324px'}
                                w={'100%'}
                                objectFit={'cover'}
                                alt={'post image'}
                                src={`${baseUrl}/api/storage/static-images/${post.photo.itemHash}${post.photo.itemExtension}`}
                            />
                        )}
                        {/*Tags*/}
                        {!!post.tags.length && (
                            <Wrap spacing={'22px'} my={'18px'}>
                                {post.tags.map((tag: IdNameTypes) => (
                                    <WrapItem key={tag.id}>
                                        <Tag
                                            color={colors.secondaryFontDarker}
                                            fontSize={'12px'}
                                            p={'12px 8px'}
                                            variant={'outline'}
                                            h={'32px'}
                                            borderRadius={'40px'}
                                        >
                                            {tag.name}
                                        </Tag>
                                    </WrapItem>
                                ))}
                            </Wrap>
                        )}
                        {/* Title*/}
                        <Text
                            lineHeight={'35px'}
                            fontSize={'24px'}
                            letterSpacing={'2px'}
                            fontWeight={'600'}
                        >
                            {post.title}
                        </Text>
                        {/*    Post Text*/}
                        <Text
                            color={colors.secondaryFontDarker}
                            lineHeight={'24px'}
                            fontSize={'16px'}
                            letterSpacing={'1px'}
                            fontWeight={'400'}
                        >
                            {post.body}
                        </Text>
                    </>
                )}
            </ModalBody>
        </CustomModalWrapper>
    );
};
