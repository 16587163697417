import React, { useState, useEffect } from 'react';
import {
    Link,
    Box,
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    IconButton,
} from '@chakra-ui/react';
import { NotificationsList } from './NotificationsList';
import { Bell, Notification } from '../../../../../assets/icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../../../store';
import { getNotificationsRequest } from '../../../../../pages/Notifications/store/actions';
import { Loader } from '../../Loader/Loader';
import { handlePressNotification } from '../../../../../common/utils';

interface INotificationsPortalProps {
    isAccessAllowed: boolean;
}

export const NotificationsPortal: React.FC<INotificationsPortalProps> = ({ isAccessAllowed }) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            dispatch(getNotificationsRequest({ pageSize: 9, pageNumber: 1 }));
        }
    }, [isOpen]);

    const handleClose = () => setIsOpen(false);

    const {
        items: notifications,
        userNotificationsLastReadTime,
        loading: notificationsLoading,
        isUnreadExists,
    } = useSelector((state: RootStore) => state.notifications);

    return (
        <Popover isOpen={isOpen} onClose={handleClose}>
            <PopoverTrigger>
                <IconButton
                    aria-label="Notifications"
                    variant="unstyled"
                    d="flex"
                    flexDirection="row"
                    disabled={!isAccessAllowed}
                    onClick={() => setIsOpen(true)}
                    width="20px"
                    height="20px"
                    minW="20px"
                >
                    <>
                        <Bell width="20" height="20" />
                        {!!isUnreadExists && (
                            <Box position="absolute" ml="18px" top="8px">
                                <Notification width="12" height="12" />
                            </Box>
                        )}
                    </>
                </IconButton>
            </PopoverTrigger>
            <PopoverContent w="500px" zIndex={2} p="20px">
                {notificationsLoading ? (
                    <Loader centerHeight="300px" spinnerSize="md" />
                ) : (
                    <NotificationsList
                        notifications={notifications.slice(0, 5)}
                        userNotificationsLastReadTime={userNotificationsLastReadTime}
                        handlePressNotification={item => handlePressNotification(item, dispatch)}
                    />
                )}
                <Link href="/notifications">
                    <Text fontSize="14px" color="brand" textDecoration="underline" ml="10px">
                        See All
                    </Text>
                </Link>
            </PopoverContent>
        </Popover>
    );
};
