import React from 'react';
import {
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Box,
    Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CustomButton } from '../../../../../common/components';
import { LockIcon, BackArrow } from '../../../../../assets/icons';
import { theme } from '../../../../../theme';
import { PasswordConfirm, SetNewPassword } from '../../types';
import { passwordConfirmResolver } from '../../../../../common/schemes';
import { Link } from 'react-router-dom';

export const SignInEnterNewPassword: React.FC<SetNewPassword> = ({
    loading,
    token,
    userId,
    onSetNewPassword,
}) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<PasswordConfirm>({
        resolver: passwordConfirmResolver,
        defaultValues: { token: token, userId: userId },
    });

    return (
        <form onSubmit={handleSubmit(onSetNewPassword)}>
            <Box
                borderRadius={'20px'}
                backgroundColor="#FFFFFF"
                display="flex"
                alignItems="center"
                flexDirection="column"
                p={{ base: '30px', lg: '50px' }}
            >
                <Box alignSelf={'start'} mb="26px">
                    <Link to="code">
                        <BackArrow />
                    </Link>
                </Box>
                <Stack>
                    <Heading
                        fontSize="22px"
                        alignSelf={'start'}
                        fontFamily={theme.fonts.body}
                        color={theme.colors.brand}
                        fontWeight={700}
                        lineHeight="35px"
                    >
                        Check Your Email
                    </Heading>
                    <FormControl isInvalid={!!errors.password}>
                        <Text mt="30px" color="brand" fontWeight="300" fontSize="12px">
                            Password
                        </Text>
                        <InputGroup>
                            <InputLeftElement h="50px" w="50px" children={<LockIcon />} />
                            <Input
                                type="password"
                                {...register('password', { required: true })}
                                label="Email"
                                width="100%"
                                placeholder="Password"
                                h="50px"
                                bg="#F6F7FB"
                                borderRadius="80px"
                                color="brand"
                                fontSize="13px"
                                autoComplete="new-password"
                                isPassword
                            />
                        </InputGroup>
                    </FormControl>
                    <FormControl isInvalid={!!errors.confirmPassword}>
                        <Text mt="15px" color="brand" fontWeight="300" fontSize="12px">
                            Repeat Password
                        </Text>
                        <InputGroup>
                            <InputLeftElement h="50px" w="50px" children={<LockIcon />} />
                            <Input
                                type="password"
                                {...register('confirmPassword', { required: true })}
                                label="Password"
                                width="100%"
                                placeholder="Password"
                                h="50px"
                                bg="#F6F7FB"
                                borderRadius="80px"
                                color="brand"
                                fontSize="13px"
                                autoComplete="new-password"
                                isPassword
                            />
                        </InputGroup>
                        {errors.confirmPassword && (
                            <FormErrorMessage fontSize="12px">
                                Passwords must match
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    <Box mt="24px !important">
                        <CustomButton
                            type="submit"
                            isLoading={loading}
                            loadingText="Confirming..."
                            text="Confirm"
                            width="100%"
                            height="50px"
                            bgColor="brand"
                            boxShadow="0px 3px 3px grey"
                        />
                    </Box>
                </Stack>
            </Box>
        </form>
    );
};
