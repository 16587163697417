import { createSlice } from '@reduxjs/toolkit';
import {
    checkUserPaymentAccountSuccess,
    checkUserPaymentAccountRequest,
    checkUserPaymentAccountError,
    createDwollaAccountRequest,
    createDwollaAccountSuccess,
    createDwollaAccountError,
    getDwollaWalletBalanceRequest,
    getDwollaWalletBalanceSuccess,
    getDwollaWalletBalanceError,
    getCustomerAccountStatusRequest,
    getCustomerAccountStatusSuccess,
    getCustomerAccountStatusError,
    getCustomerGeneralInfoRequest,
    getCustomerGeneralInfoSuccess,
    getCustomerGeneralInfoError,
    editDwollaAccountRequest,
    editDwollaAccountSuccess,
    editDwollaAccountError,
    updateCustomerRequest,
    updateCustomerSuccess,
    updateCustomerError,
    uploadsNewDocumentRequest,
    uploadsNewDocumentSuccess,
    uploadsNewDocumentError,
    getFundingSourcesRequest,
    getFundingSourcesSuccess,
    getFundingSourcesError,
    setSendingFundingSourceRequest,
    setSendingFundingSourceSuccess,
    setSendingFundingSourceError,
    setReceivingFundingSourceRequest,
    setReceivingFundingSourceSuccess,
    setReceivingFundingSourceError,
    checkUserAddFundingSourceRequest,
    checkUserAddFundingSourceSuccess,
    checkUserAddFundingSourceError,
    addNewBankAccountRequest,
    addNewBankAccountSuccess,
    addNewBankAccountError,
    removeFundingSourceRequest,
    removeFundingSourceSuccess,
    removeFundingSourceError,
    updatePaymentMethodRequest,
    updatePaymentMethodSuccess,
    updatePaymentMethodError,
    initVerificationFundingSourceRequest,
    initVerificationFundingSourceSuccess,
    initVerificationFundingSourceError,
    verifyMicroDepositsRequest,
    verifyMicroDepositsSuccess,
    verifyMicroDepositsError,
    createUserToSelfTransferRequest,
    createUserToSelfTransferSuccess,
    createUserToSelfTransferError,
    getPlaidLinkToken,
    getPlaidLinkTokenSuccess,
    getPlaidLinkTokenError,
    getProcessorToken,
    getProcessorTokenSuccess,
    getProcessorTokenError,
    addBankAccountWithPlaid,
    addBankAccountWithPlaidSuccess,
    addBankAccountWithPlaidError,
    setIsUserCanAddBankAccount,
    toggleReloginAlertModal,
    getTransactionHistory,
    getTransactionHistorySuccess,
} from './actions';
import { logout } from '../../../../../store/actions/auth/auth.actions';
import { PayloadError } from '../../../../../common/types';
import { GetFundingSourcesResponse, PlaidLinkTokenResponse } from '../types';
import { number } from 'yup';

type InitialState = {
    checkUserPaymentAccount: {
        loading: boolean;
        value: boolean;
        errors: PayloadError[];
    };
    createDwollaAccount: {
        loading: boolean;
        errors: PayloadError[];
    };
    dwollaWalletBalance: {
        loading: boolean;
        value: number;
        errors: PayloadError[];
    };
    getCustomerAccountStatus: {
        loading: boolean;
        value: string;
        errors: PayloadError[];
    };
    customerGeneralInfo: {
        loading: boolean;
        data: {
            email: string;
            city: string;
            state: string;
            postalCode: string;
            address1: string;
            address2: string;
        };
        errors: PayloadError[];
    };
    editDwollaAccount: {
        loading: boolean;
        errors: PayloadError[];
    };
    updateDwollaAccount: {
        loading: boolean;
        errors: PayloadError[];
    };
    uploadNewDocument: {
        loading: boolean;
        errors: PayloadError[];
    };
    fundingSources: {
        loading: boolean;
        errors: PayloadError[];
        items: GetFundingSourcesResponse[];
    };
    setSendingFundingSource: {
        loading: boolean;
        errors: PayloadError[];
    };
    setReceivingFundingSource: {
        loading: boolean;
        errors: PayloadError[];
    };
    isUserAddNewFundingSource: {
        value: boolean;
        loading: boolean;
        errors: PayloadError[];
    };
    addNewBankAccount: {
        loading: boolean;
        errors: PayloadError[];
    };
    removeFundingSource: {
        loading: boolean;
        errors: PayloadError[];
    };
    updatePaymentMethod: {
        loading: boolean;
        errors: PayloadError[];
    };
    initVerificationFundingSource: {
        loading: boolean;
        errors: PayloadError[];
    };
    verifyMicroDeposits: {
        loading: boolean;
        errors: PayloadError[];
    };
    createUserToSelfTransfer: {
        loading: boolean;
        errors: PayloadError[];
    };
    plaidLinkToken: {
        loading: boolean;
        value: PlaidLinkTokenResponse;
        errors: PayloadError[];
    };
    getProcessorToken: {
        loading: boolean;
        processorToken: string;
        errors: PayloadError[];
    };
    addBankAccountWithPlaid: {
        loading: boolean;
        errors: PayloadError[];
    };
    transactionHistory: {
        loading: boolean;
        errors: PayloadError[];
        items: any[];
        totalCount: number;
        itemDetails: any | null,
    };
    reloginAlertModalOpen: boolean;
};
const initialState: InitialState = {
    checkUserPaymentAccount: {
        loading: false,
        value: false,
        errors: [],
    },
    createDwollaAccount: {
        loading: false,
        errors: [],
    },
    dwollaWalletBalance: {
        loading: false,
        value: 0,
        errors: [],
    },
    getCustomerAccountStatus: {
        loading: false,
        value: '',
        errors: [],
    },
    customerGeneralInfo: {
        loading: false,
        data: {
            email: '',
            address1: '',
            postalCode: '',
            address2: '',
            city: '',
            state: '',
        },
        errors: [],
    },
    editDwollaAccount: {
        loading: false,
        errors: [],
    },
    updateDwollaAccount: {
        loading: false,
        errors: [],
    },
    uploadNewDocument: {
        loading: false,
        errors: [],
    },
    fundingSources: {
        loading: false,
        errors: [],
        items: [],
    },
    setReceivingFundingSource: {
        loading: false,
        errors: [],
    },
    setSendingFundingSource: {
        loading: false,
        errors: [],
    },
    isUserAddNewFundingSource: {
        value: false,
        loading: false,
        errors: [],
    },
    addNewBankAccount: {
        loading: false,
        errors: [],
    },
    removeFundingSource: {
        loading: false,
        errors: [],
    },
    updatePaymentMethod: {
        loading: false,
        errors: [],
    },
    initVerificationFundingSource: {
        loading: false,
        errors: [],
    },
    verifyMicroDeposits: {
        loading: false,
        errors: [],
    },
    createUserToSelfTransfer: {
        loading: false,
        errors: [],
    },
    plaidLinkToken: {
        loading: false,
        value: {
            linkToken: '',
            expiration: '',
        },
        errors: [],
    },
    getProcessorToken: {
        loading: false,
        processorToken: '',
        errors: [],
    },
    addBankAccountWithPlaid: {
        loading: false,
        errors: [],
    },
    reloginAlertModalOpen: false,
    transactionHistory: {
        loading: false,
        errors: [],
        items: [],
        totalCount: 0,
        itemDetails: null,
    }
};
const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(checkUserPaymentAccountRequest, state => {
                state.checkUserPaymentAccount.errors = [];
                state.checkUserPaymentAccount.loading = true;
            })
            .addCase(checkUserPaymentAccountSuccess, (state, action) => {
                state.checkUserPaymentAccount.loading = false;
                state.checkUserPaymentAccount.value = action.payload;
            })
            .addCase(checkUserPaymentAccountError, (state, action) => {
                state.checkUserPaymentAccount.loading = false;
                state.checkUserPaymentAccount.errors = action.payload;
            })
            //  CREATE DWOLLA ACCOUNT
            .addCase(createDwollaAccountRequest, state => {
                state.createDwollaAccount.loading = true;
                state.createDwollaAccount.errors = [];
            })
            .addCase(createDwollaAccountSuccess, state => {
                state.createDwollaAccount.loading = false;
                state.createDwollaAccount.errors = [];
            })
            .addCase(createDwollaAccountError, (state, action) => {
                state.createDwollaAccount.loading = false;
                state.createDwollaAccount.errors = action.payload;
            })
            //  GET DWOLLA WALLET BALANCE
            .addCase(getDwollaWalletBalanceRequest, state => {
                state.dwollaWalletBalance.loading = true;
                state.dwollaWalletBalance.errors = [];
            })
            .addCase(getDwollaWalletBalanceSuccess, (state, action) => {
                state.dwollaWalletBalance.loading = false;
                state.dwollaWalletBalance.value = action.payload;
            })
            .addCase(getDwollaWalletBalanceError, (state, action) => {
                state.dwollaWalletBalance.loading = false;
                state.dwollaWalletBalance.errors = action.payload;
            })
            //  GET CUSTOMER ACCOUNT STATUS
            .addCase(getCustomerAccountStatusRequest, state => {
                state.getCustomerAccountStatus.loading = true;
                state.getCustomerAccountStatus.errors = [];
            })
            .addCase(getCustomerAccountStatusSuccess, (state, action) => {
                state.getCustomerAccountStatus.loading = false;
                state.getCustomerAccountStatus.value = action.payload;
            })
            .addCase(getCustomerAccountStatusError, (state, action) => {
                state.getCustomerAccountStatus.loading = false;
                state.getCustomerAccountStatus.errors = action.payload;
            })
            //  GET CUSTOMER GENERAL INFO
            .addCase(getCustomerGeneralInfoRequest, state => {
                state.customerGeneralInfo.loading = true;
                state.customerGeneralInfo.errors = [];
            })
            .addCase(getCustomerGeneralInfoSuccess, (state, action) => {
                state.customerGeneralInfo.loading = false;
                state.customerGeneralInfo.data = {
                    ...action.payload,
                };
                state.customerGeneralInfo.errors = [];
            })
            .addCase(getCustomerGeneralInfoError, (state, action) => {
                state.customerGeneralInfo.loading = false;
                state.customerGeneralInfo.errors = action.payload;
            })
            //  EDIT DWOLLA ACCOUNT
            .addCase(editDwollaAccountRequest, state => {
                state.editDwollaAccount.loading = true;
                state.editDwollaAccount.errors = [];
            })
            .addCase(editDwollaAccountSuccess, state => {
                state.editDwollaAccount.loading = false;
                state.editDwollaAccount.errors = [];
            })
            .addCase(editDwollaAccountError, (state, action) => {
                state.editDwollaAccount.loading = false;
                state.editDwollaAccount.errors = action.payload;
            })
            //  UPDATE ACCOUNT WHEN RETRY STATUS
            .addCase(updateCustomerRequest, state => {
                state.updateDwollaAccount.loading = true;
                state.updateDwollaAccount.errors = [];
            })
            .addCase(updateCustomerSuccess, (state, action) => {
                state.updateDwollaAccount.loading = false;
                state.updateDwollaAccount.errors = [];
            })
            .addCase(updateCustomerError, (state, action) => {
                state.updateDwollaAccount.loading = false;
                state.updateDwollaAccount.errors = action.payload;
            })
            //  UPLOAD NEW DOCUMENT
            .addCase(uploadsNewDocumentRequest, state => {
                state.uploadNewDocument.loading = true;
                state.uploadNewDocument.errors = [];
            })
            .addCase(uploadsNewDocumentSuccess, state => {
                state.uploadNewDocument.loading = false;
                state.uploadNewDocument.errors = [];
            })
            .addCase(uploadsNewDocumentError, (state, action) => {
                state.uploadNewDocument.loading = false;
                state.uploadNewDocument.errors = action.payload;
            })
            //  GET FUNDING SOURCES
            .addCase(getFundingSourcesRequest, state => {
                state.fundingSources.loading = true;
                state.fundingSources.errors = [];
            })
            .addCase(getFundingSourcesSuccess, (state, action) => {
                state.fundingSources.loading = false;
                state.fundingSources.items = action.payload;
            })
            .addCase(getFundingSourcesError, (state, action) => {
                state.fundingSources.loading = false;
                state.fundingSources.errors = action.payload;
            })
            //  SET SENDING FUNDING SOURCE
            .addCase(setSendingFundingSourceRequest, state => {
                state.setSendingFundingSource.loading = true;
                state.setSendingFundingSource.errors = [];
            })
            .addCase(setSendingFundingSourceSuccess, state => {
                state.setSendingFundingSource.loading = false;
                state.setSendingFundingSource.errors = [];
            })
            .addCase(setSendingFundingSourceError, (state, action) => {
                state.setSendingFundingSource.loading = false;
                state.setSendingFundingSource.errors = action.payload;
            })
            //  SET RECEIVING FUNDING SOURCE
            .addCase(setReceivingFundingSourceRequest, state => {
                state.setReceivingFundingSource.loading = true;
                state.setReceivingFundingSource.errors = [];
            })
            .addCase(setReceivingFundingSourceSuccess, state => {
                state.setReceivingFundingSource.loading = false;
                state.setReceivingFundingSource.errors = [];
            })
            .addCase(setReceivingFundingSourceError, (state, action) => {
                state.setReceivingFundingSource.loading = false;
                state.setReceivingFundingSource.errors = action.payload;
            })
            //  CHECK USER CAN ADD NEW FUNDING SOURCE
            .addCase(checkUserAddFundingSourceRequest, state => {
                state.isUserAddNewFundingSource.loading = true;
                state.isUserAddNewFundingSource.errors = [];
                state.isUserAddNewFundingSource.value = false;
            })
            .addCase(checkUserAddFundingSourceSuccess, (state, action) => {
                state.isUserAddNewFundingSource.loading = false;
                state.isUserAddNewFundingSource.value = action.payload;
            })
            .addCase(checkUserAddFundingSourceError, (state, action) => {
                state.isUserAddNewFundingSource.loading = false;
                state.isUserAddNewFundingSource.errors = action.payload;
                state.isUserAddNewFundingSource.value = false;
            })
            //  ADD NEW BANK ACCOUNT
            .addCase(addNewBankAccountRequest, state => {
                state.addNewBankAccount.loading = true;
                state.addNewBankAccount.errors = [];
            })
            .addCase(addNewBankAccountSuccess, state => {
                state.addNewBankAccount.loading = false;
            })
            .addCase(addNewBankAccountError, (state, action) => {
                state.addNewBankAccount.loading = false;
                state.addNewBankAccount.errors = action.payload;
            })
            //  REMOVE FUNDING SOURCE
            .addCase(removeFundingSourceRequest, state => {
                state.removeFundingSource.loading = true;
                state.removeFundingSource.errors = [];
            })
            .addCase(removeFundingSourceSuccess, state => {
                state.removeFundingSource.loading = false;
            })
            .addCase(removeFundingSourceError, (state, action) => {
                state.removeFundingSource.loading = false;
                state.removeFundingSource.errors = action.payload;
            })
            //  UPDATE BANK NICKNAME
            .addCase(updatePaymentMethodRequest, state => {
                state.updatePaymentMethod.loading = true;
                state.updatePaymentMethod.errors = [];
            })
            .addCase(updatePaymentMethodSuccess, state => {
                state.updatePaymentMethod.loading = false;
            })
            .addCase(updatePaymentMethodError, (state, action) => {
                state.updatePaymentMethod.loading = false;
                state.updatePaymentMethod.errors = action.payload;
            })
            //  INIT VERIFICATION FUNDING SOURCE
            .addCase(initVerificationFundingSourceRequest, state => {
                state.initVerificationFundingSource.loading = true;
                state.initVerificationFundingSource.errors = [];
            })
            .addCase(initVerificationFundingSourceSuccess, state => {
                state.initVerificationFundingSource.loading = false;
            })
            .addCase(initVerificationFundingSourceError, (state, action) => {
                state.initVerificationFundingSource.loading = false;
                state.initVerificationFundingSource.errors = action.payload;
            })
            //    VERIFY MICRO DEPOSITS
            .addCase(verifyMicroDepositsRequest, state => {
                state.verifyMicroDeposits.loading = true;
                state.verifyMicroDeposits.errors = [];
            })
            .addCase(verifyMicroDepositsSuccess, state => {
                state.verifyMicroDeposits.loading = false;
            })
            .addCase(verifyMicroDepositsError, (state, action) => {
                state.verifyMicroDeposits.loading = false;
                state.verifyMicroDeposits.errors = action.payload;
            })
            //    CREATE USER TO SELF TRANSFER
            .addCase(createUserToSelfTransferRequest, state => {
                state.createUserToSelfTransfer.loading = true;
                state.createUserToSelfTransfer.errors = [];
            })
            .addCase(createUserToSelfTransferSuccess, state => {
                state.createUserToSelfTransfer.loading = false;
            })
            .addCase(createUserToSelfTransferError, (state, action) => {
                state.createUserToSelfTransfer.loading = false;
                state.createUserToSelfTransfer.errors = action.payload;
            })
            //    GET PLAID LINK TOKEN
            .addCase(getPlaidLinkToken, state => {
                state.plaidLinkToken.loading = true;
                state.plaidLinkToken.errors = [];
            })
            .addCase(getPlaidLinkTokenSuccess, (state, action) => {
                state.plaidLinkToken.loading = false;
                state.plaidLinkToken.value = action.payload;
            })
            .addCase(getPlaidLinkTokenError, (state, action) => {
                state.plaidLinkToken.loading = false;
                state.plaidLinkToken.errors = action.payload;
            })
            //    GET PROCESSOR TOKEN
            .addCase(getProcessorToken, state => {
                state.getProcessorToken.loading = true;
                state.getProcessorToken.errors = [];
            })
            .addCase(getProcessorTokenSuccess, (state, action) => {
                state.getProcessorToken.loading = false;
                state.getProcessorToken.processorToken = action.payload;
            })
            .addCase(getProcessorTokenError, (state, action) => {
                state.getProcessorToken.loading = false;
                state.getProcessorToken.errors = action.payload;
            })
            //    ADD BANK ACCOUNT WITH PLAID
            .addCase(addBankAccountWithPlaid, state => {
                state.addBankAccountWithPlaid.loading = true;
                state.addBankAccountWithPlaid.errors = [];
            })
            .addCase(addBankAccountWithPlaidSuccess, state => {
                state.addBankAccountWithPlaid.loading = false;
            })
            .addCase(addBankAccountWithPlaidError, (state, action) => {
                state.addBankAccountWithPlaid.loading = false;
                state.addBankAccountWithPlaid.errors = action.payload;
            })
            .addCase(setIsUserCanAddBankAccount, state => {
                state.isUserAddNewFundingSource.value = false;
            })
            .addCase(toggleReloginAlertModal, (state, action) => {
                state.reloginAlertModalOpen = action.payload;
            })
            .addCase(logout, state => {
                state.checkUserPaymentAccount = initialState.checkUserPaymentAccount;
                state.createDwollaAccount = initialState.createDwollaAccount;
                state.dwollaWalletBalance = initialState.dwollaWalletBalance;
                state.getCustomerAccountStatus = initialState.getCustomerAccountStatus;
                state.customerGeneralInfo = initialState.customerGeneralInfo;
                state.fundingSources = initialState.fundingSources;
                state.isUserAddNewFundingSource = initialState.isUserAddNewFundingSource;
                state.getProcessorToken = initialState.getProcessorToken;
                state.plaidLinkToken = initialState.plaidLinkToken;
            })
            //PAYMENY HISTORY 
            .addCase(getTransactionHistory, (state, action) => {
                state.transactionHistory.loading = true;
            })
            .addCase(getTransactionHistorySuccess, (state, action) => {
                state.transactionHistory.loading = false;
                if (action.payload.pageNumber === 1) {
                    state.transactionHistory.items = action.payload.value.models;
                } else {
                    state.transactionHistory.items = [...state.transactionHistory.items, action.payload.value.models];
                }
            });
    },
});

export default paymentsSlice.reducer;
