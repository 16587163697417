import { createSlice } from '@reduxjs/toolkit';
import {
    getCalendarDataRequest,
    getCalendarDataSuccess,
    getCalendarDateError,
    getCalendarOpportunitiesRequest,
    getCalendarOpportunitiesSuccess,
    getCalendarOpportunitiesError,
    getCalendarOpenHousesRequest,
    getCalendarOpenHousesSuccess,
    getCalendarOpenHousesError,
    getCalendarAssistanceRequestsRequest,
    getCalendarAssistanceRequestsSuccess,
    getCalendarAssistanceRequestsError,
    getCalendarMyActivitiesRequest,
    getCalendarMyActivitiesSuccess,
    getCalendarMyActivitiesError,
    getCalendarOtherAgentActivitiesRequest,
    getCalendarOtherAgentsSuccess,
    getCalendarOtherAgentsActivitiesError,
} from './actions';
import { PayloadError } from '../../../common/types';
import {
    CalendarItemsResponse,
    AvailableForApplicationResponse,
    OpenHousesResponse,
    CalendarModalItem,
} from '../types';

interface InitialState extends CalendarItemsResponse {
    loading: boolean;
    errors: PayloadError[];
    opportunitiesModal: {
        loading: boolean;
        errors: PayloadError[];
        items: CalendarModalItem[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
    openHousesModal: {
        loading: boolean;
        errors: PayloadError[];
        items: CalendarModalItem[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
    assistanceRequestsModal: {
        loading: boolean;
        errors: PayloadError[];
        items: CalendarModalItem[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
    myActivitiesModal: {
        loading: boolean;
        errors: PayloadError[];
        items: CalendarModalItem[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
    otherAgentActivitiesModal: {
        loading: boolean;
        errors: PayloadError[];
        items: CalendarModalItem[];
        totalCount: number;
        loadMoreLoading: boolean;
    };
}

export const initialState: InitialState = {
    errors: [],
    loading: false,
    availableForApplication: [],
    myActivities: [],
    otherAgentActivities: [],
    opportunitiesModal: {
        loading: false,
        errors: [],
        items: [],
        loadMoreLoading: false,
        totalCount: 0,
    },
    openHousesModal: {
        loading: false,
        errors: [],
        items: [],
        totalCount: 0,
        loadMoreLoading: false,
    },
    assistanceRequestsModal: {
        loading: false,
        errors: [],
        items: [],
        totalCount: 0,
        loadMoreLoading: false,
    },
    myActivitiesModal: {
        errors: [],
        loading: false,
        items: [],
        loadMoreLoading: false,
        totalCount: 0,
    },
    otherAgentActivitiesModal: {
        errors: [],
        loading: false,
        items: [],
        loadMoreLoading: false,
        totalCount: 0,
    },
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCalendarDataRequest, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getCalendarDataSuccess, (state, action) => {
                state.loading = false;
                state.availableForApplication = action.payload.availableForApplication;
                state.myActivities = action.payload.myActivities;
                state.otherAgentActivities = action.payload.otherAgentActivities;
            })
            .addCase(getCalendarDateError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            .addCase(getCalendarOpportunitiesRequest, (state, action) => {
                state.opportunitiesModal.errors = [];
                if (action.payload.pageNumber === 1) {
                    state.opportunitiesModal.loading = true;
                } else {
                    state.opportunitiesModal.loadMoreLoading = true;
                }
            })
            .addCase(getCalendarOpportunitiesSuccess, (state, action) => {
                state.opportunitiesModal.loading = false;
                state.opportunitiesModal.loadMoreLoading = false;
                state.opportunitiesModal.items =
                    action.payload.pageNumber === 1
                        ? action.payload.models
                        : [...state.opportunitiesModal.items, ...action.payload.models];
                state.opportunitiesModal.totalCount = action.payload.totalCount;
            })
            .addCase(getCalendarOpportunitiesError, (state, action) => {
                state.opportunitiesModal.errors = action.payload;
                state.opportunitiesModal.loading = false;
                state.opportunitiesModal.loadMoreLoading = false;
            })
            .addCase(getCalendarOpenHousesRequest, (state, action) => {
                state.openHousesModal.errors = [];
                if (action.payload.pageNumber === 1) {
                    state.openHousesModal.loading = true;
                } else {
                    state.openHousesModal.loadMoreLoading = true;
                }
            })
            .addCase(getCalendarOpenHousesSuccess, (state, action) => {
                state.openHousesModal.loading = false;
                state.openHousesModal.loadMoreLoading = false;
                state.openHousesModal.items =
                    action.payload.pageNumber === 1
                        ? action.payload.models
                        : [...state.openHousesModal.items, ...action.payload.models];
                state.openHousesModal.totalCount = action.payload.totalCount;
            })
            .addCase(getCalendarOpenHousesError, (state, action) => {
                state.openHousesModal.errors = action.payload;
                state.openHousesModal.loading = false;
                state.openHousesModal.loadMoreLoading = false;
            })
            .addCase(getCalendarAssistanceRequestsRequest, (state, action) => {
                state.assistanceRequestsModal.errors = [];
                if (action.payload.pageNumber === 1) {
                    state.assistanceRequestsModal.loading = true;
                } else {
                    state.assistanceRequestsModal.loadMoreLoading = true;
                }
            })
            .addCase(getCalendarAssistanceRequestsSuccess, (state, action) => {
                state.assistanceRequestsModal.loading = false;
                state.assistanceRequestsModal.loadMoreLoading = false;
                state.assistanceRequestsModal.items =
                    action.payload.pageNumber === 1
                        ? action.payload.models
                        : [...state.assistanceRequestsModal.items, ...action.payload.models];
                state.assistanceRequestsModal.totalCount = action.payload.totalCount;
            })
            .addCase(getCalendarAssistanceRequestsError, (state, action) => {
                state.assistanceRequestsModal.errors = action.payload;
                state.assistanceRequestsModal.loading = false;
                state.assistanceRequestsModal.loadMoreLoading = false;
            })
            .addCase(getCalendarMyActivitiesRequest, (state, action) => {
                state.myActivitiesModal.errors = [];
                if (action.payload.pageNumber === 1) {
                    state.myActivitiesModal.loading = true;
                } else {
                    state.myActivitiesModal.loadMoreLoading = true;
                }
            })
            .addCase(getCalendarMyActivitiesSuccess, (state, action) => {
                state.myActivitiesModal.loading = false;
                state.myActivitiesModal.loadMoreLoading = false;
                state.myActivitiesModal.items =
                    action.payload.pageNumber === 1
                        ? action.payload.models
                        : [...state.myActivitiesModal.items, ...action.payload.models];
                state.myActivitiesModal.totalCount = action.payload.totalCount;
            })
            .addCase(getCalendarMyActivitiesError, (state, action) => {
                state.myActivitiesModal.errors = action.payload;
                state.myActivitiesModal.loadMoreLoading = false;
                state.myActivitiesModal.loading = false;
            })
            .addCase(getCalendarOtherAgentActivitiesRequest, (state, action) => {
                state.otherAgentActivitiesModal.errors = [];
                if (action.payload.pageNumber === 1) {
                    state.otherAgentActivitiesModal.loading = true;
                } else {
                    state.otherAgentActivitiesModal.loadMoreLoading = true;
                }
            })
            .addCase(getCalendarOtherAgentsSuccess, (state, action) => {
                state.otherAgentActivitiesModal.loadMoreLoading = false;
                state.otherAgentActivitiesModal.loading = false;
                state.otherAgentActivitiesModal.items =
                    action.payload.pageNumber === 1
                        ? action.payload.models
                        : [...state.otherAgentActivitiesModal.items, ...action.payload.models];
                state.otherAgentActivitiesModal.totalCount = action.payload.totalCount;
            })
            .addCase(getCalendarOtherAgentsActivitiesError, (state, action) => {
                state.otherAgentActivitiesModal.loadMoreLoading = false;
                state.otherAgentActivitiesModal.loading = false;
                state.otherAgentActivitiesModal.errors = action.payload;
            });
    },
});

export default calendarSlice.reducer;
