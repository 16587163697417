import { createStandaloneToast, extendTheme } from '@chakra-ui/react';
import { PayloadError } from '../types';

const theme = extendTheme({
    fonts: {
        body: 'Poppins, sans-serif',
    },
});

const toast = createStandaloneToast({ theme });

export class Alerter {
    static success(message: string, duration: number = 3000) {
        toast({
            title: message,
            status: 'success',
            position: 'top-right',
            duration: duration,
        });
    }
    static error(message: { errors: PayloadError[] } | string, duration: number = 3000) {
        if (typeof message === 'string') {
            toast({
                title: message,
                status: 'error',
                position: 'top-right',
                duration: duration,
            });
        } else {
            toast({
                title: message.errors[0].errorMessage,
                status: 'error',
                position: 'top-right',
                duration: duration,
            });
        }
    }
    static info(message: string, duration: number = 3000) {
        toast({
            title: message,
            status: 'info',
            position: 'top-right',
            duration: duration,
        });
    }
    static warning(message: string, duration: number = 3000) {
        toast({
            title: message,
            status: 'warning',
            position: 'top-right',
            duration: duration,
        });
    }
}
