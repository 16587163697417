import { put, takeEvery, call } from 'redux-saga/effects';
import API from '../../../../../common/api/api.base';
import {
    checkUserPaymentAccountSuccess,
    checkUserPaymentAccountRequest,
    checkUserPaymentAccountError,
    createDwollaAccountRequest,
    createDwollaAccountSuccess,
    createDwollaAccountError,
    getDwollaWalletBalanceRequest,
    getDwollaWalletBalanceSuccess,
    getDwollaWalletBalanceError,
    getCustomerAccountStatusSuccess,
    getCustomerAccountStatusError,
    getCustomerAccountStatusRequest,
    getCustomerGeneralInfoSuccess,
    getCustomerGeneralInfoRequest,
    editDwollaAccountRequest,
    editDwollaAccountSuccess,
    editDwollaAccountError,
    updateCustomerRequest,
    updateCustomerSuccess,
    updateCustomerError,
    uploadsNewDocumentRequest,
    uploadsNewDocumentSuccess,
    uploadsNewDocumentError,
    getFundingSourcesRequest,
    getFundingSourcesSuccess,
    getFundingSourcesError,
    setReceivingFundingSourceRequest,
    setReceivingFundingSourceSuccess,
    setReceivingFundingSourceError,
    setSendingFundingSourceRequest,
    setSendingFundingSourceSuccess,
    setSendingFundingSourceError,
    checkUserAddFundingSourceSuccess,
    checkUserAddFundingSourceError,
    checkUserAddFundingSourceRequest,
    addNewBankAccountRequest,
    addNewBankAccountSuccess,
    addNewBankAccountError,
    removeFundingSourceRequest,
    removeFundingSourceSuccess,
    removeFundingSourceError,
    updatePaymentMethodRequest,
    updatePaymentMethodSuccess,
    initVerificationFundingSourceRequest,
    initVerificationFundingSourceSuccess,
    initVerificationFundingSourceError,
    verifyMicroDepositsRequest,
    verifyMicroDepositsSuccess,
    verifyMicroDepositsError,
    createUserToSelfTransferRequest,
    createUserToSelfTransferSuccess,
    createUserToSelfTransferError,
    getPlaidLinkTokenSuccess,
    getPlaidLinkTokenError,
    getProcessorToken,
    getProcessorTokenSuccess,
    getProcessorTokenError,
    getPlaidLinkToken,
    addBankAccountWithPlaid,
    addBankAccountWithPlaidSuccess,
    addBankAccountWithPlaidError,
    getTransactionHistory,
    getTransactionHistorySuccess,
    getTransactionHistoryError,
} from './actions';
import { IResponseWithCustomValue, IResponseWithoutValue } from '../../../../../common/types';
import {
    GetCustomerGeneralInfoResponse,
    GetFundingSourcesResponse,
    PlaidLinkTokenResponse,
    TransactionHistoryValue
} from '../types';

function* workerCheckUserPaymentAccount() {
    try {
        const result: IResponseWithCustomValue<boolean> = yield call(
            API.get,
            '/api/dwolla/customers',
            {},
        );
        console.log(result, 'RESULT');
        if (result.success) {
            yield put(checkUserPaymentAccountSuccess(result.value));
        } else {
            yield put(checkUserPaymentAccountError(result.errors));
        }
    } catch (err) {
        console.log(err, 'workerCheckUserPaymentAccount');
    }
}

function* workerCreateDwollaAccount(action: ReturnType<typeof createDwollaAccountRequest>) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            '/api/dwolla/customers',
            action.payload,
        );
        console.log(response, 'workerCreateDwollaAccount');
        if (response.success) {
            yield put(createDwollaAccountSuccess());
            yield workerCheckUserPaymentAccount();
        } else {
            yield put(createDwollaAccountError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerCreateDwollaAccount');
    }
}

function* workerGetDwollaWalletBalance() {
    try {
        const response: IResponseWithCustomValue<number> = yield call(
            API.get,
            '/api/dwolla/customers/funding-sources/wallet/balance',
            {},
        );
        console.log(response, 'workerGetDwollaWalletBalance');
        if (response.success) {
            yield put(getDwollaWalletBalanceSuccess(response.value));
        } else {
            yield put(getDwollaWalletBalanceError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetDwollaWalletBalanceError');
    }
}
function* workerGetCustomerAccountStatus() {
    try {
        const response: IResponseWithCustomValue<string> = yield call(
            API.get,
            '/api/dwolla/customers/status',
            {},
        );
        console.log(response);
        if (response.success) {
            yield put(getCustomerAccountStatusSuccess(response.value));
        } else {
            yield put(getCustomerAccountStatusError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetCustomerAccountStatusError');
    }
}
function* workerGetCustomerGeneralInfo() {
    try {
        const response: IResponseWithCustomValue<GetCustomerGeneralInfoResponse> = yield call(
            API.get,
            '/api/dwolla/customers/general-info',
            {},
        );
        if (response.success) {
            yield put(getCustomerGeneralInfoSuccess(response.value));
        } else {
            yield put(getCustomerAccountStatusError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetCustomerGeneralInfoError');
    }
}
function* workerEditDwollaAccount(action: ReturnType<typeof editDwollaAccountRequest>) {
    try {
        const response: IResponseWithoutValue = yield call(API.patch, '/api/dwolla/customers', {
            ...action.payload,
        });
        console.log(response, 'workerEditDwollaAccount');
        if (response.success) {
            yield put(editDwollaAccountSuccess());
        } else {
            yield put(editDwollaAccountError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerEditDwollaAccountError');
    }
}
function* workerUpdateDwollaAccount(action: ReturnType<typeof updateCustomerRequest>) {
    try {
        const response: IResponseWithoutValue = yield call(API.put, '/api/dwolla/customers', {
            ...action.payload,
        });
        console.log(response, 'workerUpdateDwollaAccount');
        if (response.success) {
            yield put(updateCustomerSuccess());
            yield workerGetCustomerAccountStatus();
        } else {
            yield put(updateCustomerError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerUpdateDwollaAccount');
    }
}
function* workerUploadsNewDocument(action: ReturnType<typeof uploadsNewDocumentRequest>) {
    console.log(action, 'Upload Document Action');
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            `/api/dwolla/customers/new-documents?documentType=${action.payload.documentType}`,
            action.payload.document,
        );

        if (response.success) {
            yield put(uploadsNewDocumentSuccess());
            yield workerGetCustomerAccountStatus();
        } else {
            yield put(uploadsNewDocumentError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerUploadsNewDocumentError');
    }
}
function* workerGetFundingSources(action: ReturnType<typeof getFundingSourcesRequest>) {
    try {
        const response: IResponseWithCustomValue<GetFundingSourcesResponse[]> = yield call(
            API.get,
            `/api/dwolla/customers/funding-sources?includeBalance=${action.payload}`,
            {},
        );
        console.log(response);
        if (response.success) {
            yield put(getFundingSourcesSuccess(response.value));
        } else {
            yield put(getFundingSourcesError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetFundingSourcesError');
    }
}
function* workerSetReceivingFundingSource(
    action: ReturnType<typeof setReceivingFundingSourceRequest>,
) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            `/api/dwolla/customers/funding-sources/${action.payload}/default-destination`,
            {},
        );
        console.log(response, 'workerSetReceivingFundingSource');
        if (response.success) {
            yield put(setReceivingFundingSourceSuccess());
        } else {
            yield put(setReceivingFundingSourceError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerSetReceivingFundingSourceError');
    }
}
function* workerSetSendingFundingSource(action: ReturnType<typeof setSendingFundingSourceRequest>) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            `/api/dwolla/customers/funding-sources/${action.payload}/default-source`,
            {},
        );
        console.log(response, 'workerSetSendingFundingSource');
        if (response.success) {
            yield put(setSendingFundingSourceSuccess());
        } else {
            yield put(setSendingFundingSourceError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerSetSendingFundingSourceError');
    }
}
function* workerCheckUserAddFundingSource() {
    try {
        const response: IResponseWithCustomValue<boolean> = yield call(
            API.get,
            '/api/dwolla/customers/funding-sources/rights',
            {},
        );
        if (response.success) {
            yield put(checkUserAddFundingSourceSuccess(response.value));
        } else {
            yield put(checkUserAddFundingSourceError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerCheckUserAddFundingSourceError');
    }
}
function* workerAddNewBankAccount(action: ReturnType<typeof addNewBankAccountRequest>) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            '/api/dwolla/customers/funding-sources',
            {
                ...action.payload,
            },
        );
        console.log(response, 'workerAddNewBankAccount');
        if (response.success) {
            yield put(addNewBankAccountSuccess());

            yield put(getFundingSourcesRequest(false));
        } else {
            yield put(addNewBankAccountError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerAddNewBankAccount');
    }
}
function* workerRemoveFundingSource(action: ReturnType<typeof removeFundingSourceRequest>) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            `/api/dwolla/customers/funding-sources/${action.payload}`,
            {},
        );
        console.log(response, 'workerRemoveFundingSource');
        if (response.success) {
            yield put(removeFundingSourceSuccess());

            yield put(getFundingSourcesRequest(false));
        } else {
            yield put(removeFundingSourceError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerRemoveFundingSourceError');
    }
}
function* workerUpdatePaymentMethod(action: ReturnType<typeof updatePaymentMethodRequest>) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.patch,
            `/api/dwolla/customers/funding-sources/${action.payload.fundingSourceId}`,
            {
                name: action.payload.name,
                routingNumber: action.payload.routingNumber,
                accountNumber: action.payload.accountNumber,
                bankAccountType: action.payload.bankAccountType,
            },
        );
        console.log(response, 'watchUpdateBankNicknameSaga');
        if (response.success) {
            yield put(updatePaymentMethodSuccess());

            yield put(getFundingSourcesRequest(false));
        } else {
            yield put(updateCustomerError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerUpdatePaymentMethodError');
    }
}
function* workerInitVerificationFundingSource(
    action: ReturnType<typeof initVerificationFundingSourceRequest>,
) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            `/api/dwolla/customers/funding-sources/${action.payload}/initiate-micro-deposits`,
            {},
        );
        console.log(response, 'InitVerificationFundingSource');
        if (response.success) {
            yield put(initVerificationFundingSourceSuccess());
            yield put(getFundingSourcesRequest(false));
        } else {
            yield put(initVerificationFundingSourceError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerInitVerificationFundingSourceError');
    }
}

function* workerVerifyMicroDeposits(action: ReturnType<typeof verifyMicroDepositsRequest>) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            '/api/dwolla/customers/funding-sources/verify-micro-deposits',
            {
                ...action.payload,
            },
        );
        console.log(response, 'workerVerifyMicroDeposits');
        if (response.success) {
            yield put(verifyMicroDepositsSuccess());

            yield put(getFundingSourcesRequest(false));
        } else {
            yield put(verifyMicroDepositsError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerVerifyMicroDepositsError');
    }
}
function* workerCreateUserToSelfTransfer(
    action: ReturnType<typeof createUserToSelfTransferRequest>,
) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            '/api/dwolla/customers/funding-sources/transfers/user-to-self',
            {
                ...action.payload,
            },
        );
        console.log(response, 'CreateUserToSelfTransfer');
        if (response.success) {
            yield put(createUserToSelfTransferSuccess());
            yield workerGetDwollaWalletBalance();
        } else {
            yield put(createUserToSelfTransferError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerCreateUserToSelfTransferError');
    }
}
function* workerGetLinkToken(action: ReturnType<typeof getPlaidLinkToken>) {
    try {
        const response: IResponseWithCustomValue<PlaidLinkTokenResponse> = yield call(
            API.get,
            `/api/plaid/link-token?isWeb=${action.payload}`,
            {},
        );
        if (response.success) {
            yield put(getPlaidLinkTokenSuccess(response.value));
            localStorage.setItem('linkTokenData', JSON.stringify(response.value.linkToken));
        } else {
            yield put(getPlaidLinkTokenError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetLinkToken');
    }
}
function* workerGetProcessorToken(action: ReturnType<typeof getProcessorToken>) {
    try {
        const response: IResponseWithCustomValue<{
            processorToken: string;
        }> = yield call(API.post, '/api/plaid/processor-token', {
            ...action.payload,
        });
        if (response.success) {
            yield put(getProcessorTokenSuccess(response.value.processorToken));
        } else {
            yield put(getProcessorTokenError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerGetLinkToken');
    }
}
function* workerAddBankAccountWithPlaid(action: ReturnType<typeof addBankAccountWithPlaid>) {
    try {
        const response: IResponseWithoutValue = yield call(
            API.post,
            'api/dwolla/customers/plaid-funding-sources',
            {
                ...action.payload,
            },
        );
        console.log(response, 'workerAddBankAccountWithPlaid');
        if (response.success) {
            yield put(addBankAccountWithPlaidSuccess());

            yield put(getFundingSourcesRequest(false));
        } else {
            yield put(addBankAccountWithPlaidError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerAddBankAccountWithPlaid');
    }
}

function* workerGetTransactionHistory(action: ReturnType<typeof getTransactionHistory>) {
    try {
        const response: IResponseWithCustomValue<TransactionHistoryValue> = yield call(
            API.get,
            `api/dwolla/customers/transfer-history?PageNumber=${action.payload.pageNumber}&PageSize=${action.payload.pageSize}&isIncome=${action.payload.isIncome}`,
            {}
        );
        console.log('Transaction History', response);
        if (response.success) {
            yield put(getTransactionHistorySuccess({
                ...response,
                pageNumber: action.payload.pageNumber
            }));
        } else {
            yield put(getTransactionHistoryError(response.errors));
        }
    } catch (err) {
        console.log(err, 'workerAddBankAccountWithPlaid');
    }
}

export default function* watchPaymentsSaga() {
    yield takeEvery(checkUserPaymentAccountRequest.type, workerCheckUserPaymentAccount);
    yield takeEvery(createDwollaAccountRequest.type, workerCreateDwollaAccount);
    yield takeEvery(getDwollaWalletBalanceRequest.type, workerGetDwollaWalletBalance);
    yield takeEvery(getCustomerAccountStatusRequest.type, workerGetCustomerAccountStatus);
    yield takeEvery(getCustomerGeneralInfoRequest.type, workerGetCustomerGeneralInfo);
    yield takeEvery(editDwollaAccountRequest.type, workerEditDwollaAccount);
    yield takeEvery(updateCustomerRequest.type, workerUpdateDwollaAccount);
    yield takeEvery(uploadsNewDocumentRequest.type, workerUploadsNewDocument);
    yield takeEvery(getFundingSourcesRequest.type, workerGetFundingSources);
    yield takeEvery(setReceivingFundingSourceRequest.type, workerSetReceivingFundingSource);
    yield takeEvery(setSendingFundingSourceRequest.type, workerSetSendingFundingSource);
    yield takeEvery(checkUserAddFundingSourceRequest.type, workerCheckUserAddFundingSource);
    yield takeEvery(addNewBankAccountRequest.type, workerAddNewBankAccount);
    yield takeEvery(removeFundingSourceRequest.type, workerRemoveFundingSource);
    yield takeEvery(updatePaymentMethodRequest.type, workerUpdatePaymentMethod);
    yield takeEvery(initVerificationFundingSourceRequest.type, workerInitVerificationFundingSource);
    yield takeEvery(verifyMicroDepositsRequest.type, workerVerifyMicroDeposits);
    yield takeEvery(createUserToSelfTransferRequest.type, workerCreateUserToSelfTransfer);
    yield takeEvery(getProcessorToken.type, workerGetProcessorToken);
    yield takeEvery(getPlaidLinkToken.type, workerGetLinkToken);
    yield takeEvery(addBankAccountWithPlaid.type, workerAddBankAccountWithPlaid);
    yield takeEvery(getTransactionHistory.type, workerGetTransactionHistory);
}
