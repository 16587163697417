import { createAction } from '@reduxjs/toolkit';

import {
    AcceptRequestsPayload,
    GetMyRequestsPayload,
    GetMyRequestsSuccess,
    GetRequestSuccessResponse,
    GetAgencyRequestsPayload,
    SentRequestType,
    EditRequestPayload,
    ApplicantType,
    ConfirmApplicantPayload,
    GetUserFeedbacksPayload,
    UserFeedbackType,
    FeedbackUserInfoType,
    ChangeClientName,
    ChangeClientPhone,
    ChangeClientType,
    ChangePrivateNote,
    MyRequestsType,
} from '../types';
import {
    IResponseWithoutValue,
    IResponseWithCustomValue,
    PayloadError,
} from '../../../common/types';

// GET PENDING REQUESTS
export const getMyRequests = createAction<GetMyRequestsPayload>('GET_MY_REQUESTS');
export const getMyRequestsSuccess = createAction<{
    result: IResponseWithCustomValue<GetMyRequestsSuccess>;
    PageNumber: number;
}>('GET_MY_REQUESTS_SUCCESS');
export const getMyRequestsError = createAction<PayloadError[]>('GET_MY_REQUESTS_ERROR');

// GET OPEN HOUSE DETAILS
export const getOpenHouseDetails = createAction<number>('GET_OPEN_HOUSE_DETAILS');
export const getOpenHouseDetailsSuccess = createAction<any>('GET_OPEN_HOUSE_DETAILS_SUCCESS');
export const getOpenHouseDetailsError = createAction<PayloadError[]>(
    'GET_OPEN_HOUSE_DETAILS_ERROR',
);

// GET REQUEST
export const getRequest = createAction<number>('GET_REQUEST');
export const getRequestSuccess =
    createAction<IResponseWithCustomValue<GetRequestSuccessResponse>>('GET_REQUEST_SUCCESS');
export const getRequestError = createAction<PayloadError[]>('GET_REQUEST_ERROR');

// ACCEPT REQUEST
export const acceptRequest = createAction<AcceptRequestsPayload>('ACCEPT_REQUEST');
export const acceptRequestSuccess = createAction<IResponseWithoutValue>('ACCEPT_REQUEST_SUCCESS');
export const acceptRequestError = createAction<PayloadError[]>('ACCEPT_REQUEST_ERROR');

// DELETE REQUEST
export const deleteRequest = createAction<number>('DELETE_REQUEST');
export const deleteRequestSuccess = createAction<number>('DELETE_REQUEST_SUCCESS');
export const deleteRequestError = createAction<PayloadError[]>('DELETE_REQUEST_ERROR');

// GET SENT REQUESTS
export const getAgencyRequests = createAction<GetAgencyRequestsPayload>('GET_AGENCY_REQUESTS');
export const getAgencyRequestsSuccess = createAction<{
    assistRequests: SentRequestType[];
    receivedAssistRequests: MyRequestsType[];
    totalCount: number;
    pageNumber: number;
}>('GET_AGENCY_REQUESTS_SUCCESS');
export const getAgencyRequestsError = createAction<PayloadError[]>('GET_AGENCY_REQUESTS_ERROR');

// Cancel Accepted work
export const cancelAcceptedWork = createAction<number>('CANCEL_ACCEPTED_WORK');
export const cancelAcceptedWorkSuccess = createAction<number>('CANCEL_ACCEPTED_WORK_SUCCESS');
export const cancelAcceptedWorkError = createAction<PayloadError[]>('CANCEL_ACCEPTED_WORK_ERROR');

// EDIT REQUEST
export const editRequest = createAction<EditRequestPayload>('EDIT_REQUEST');
export const editRequestSuccess = createAction('EDIT_REQUEST_SUCCESS');
export const editRequestError = createAction<PayloadError[]>('EDIT_REQUEST_ERROR');

// CHANGE isMyRequestInfoModalOpen
export const setIsMyRequestInfoModalOpen = createAction<boolean>(
    'SET_IS_MY_REQUEST_INFO_MODAL_OPEN',
);

// GET APPLICANTS
export const getApplicantsToRequest = createAction<number>('GET_APPLICANTS_TO_REQUEST');
export const getApplicantsToRequestSuccess = createAction<ApplicantType[]>(
    'GET_APPLICANTS_TO_REQUEST_SUCCESS',
);
export const getApplicantsToRequestError = createAction<PayloadError[]>(
    'GET_APPLICANTS_TO_REQUEST_ERROR',
);

// ACCEPT APPLICANT
export const confirmApplicant = createAction<ConfirmApplicantPayload>('CONFIRM_APPLICANT');
export const confirmApplicantSuccess = createAction<number>('CONFIRM_APPLICANT_SUCCESS');
export const confirmApplicantError = createAction<PayloadError[]>('CONFIRM_APPLICANT_ERROR');

// REJECT APPLICANT
export const rejectApplicantRequest = createAction<ConfirmApplicantPayload>(
    'REJECT_APPLICANT_REQUEST',
);
export const rejectApplicantSuccess = createAction<ConfirmApplicantPayload>(
    'REJECT_APPLICANT_SUCCESS',
);
export const rejectApplicantError = createAction<PayloadError[]>('REJECT_APPLICANT_ERROR');

// GET PARTNER APPLICANTS
export const getPartnerApplicantsRequest = createAction<number>('GET_PARTNER_APPLICANTS_REQUEST');
export const getPartnerApplicantsSuccess = createAction<ApplicantType[]>(
    'GET_PARTNER_APPLICANTS_SUCCESS',
);
export const getPartnerApplicantsFailed = createAction<PayloadError[]>(
    'GET_PARTNER_APPLICANTS_Failed',
);

export const getUserFeedbacks = createAction<GetUserFeedbacksPayload>('GET_USER_FEEDBACKS');
export const getUserFeedbacksSuccess = createAction<{
    feedback: UserFeedbackType[];
    totalCount: number;
}>('GET_USER_FEEDBACKS_SUCCESS');
export const getUserFeedbacksError = createAction<PayloadError[]>('GET_USER_FEEDBACKS_ERROR');

export const deleteRequestAfterTimer = createAction<number>('DELETE_REQUEST_AFTER_TIMER');

export const getFeedbackUserInfo = createAction<number>('GET_FEEDBACK_USER_INFO');
export const getFeedbackUserInfoSuccess = createAction<FeedbackUserInfoType>(
    'GET_FEEDBACK_USER_INFO_SUCCESS',
);
export const getFeedbackUserInfoError = createAction<PayloadError[]>(
    'GET_FEEDBACK_USER_INFO_ERROR',
);

export const changeClientName = createAction<ChangeClientName>('CHANGE_CLIENT_NAME');
export const changeClientNameSuccess = createAction<IResponseWithoutValue>(
    'CHANGE_CLIENT_NAME_SUCCESS',
);
export const changeClientNameError = createAction<PayloadError[]>('CHANGE_CLIENT_NAME_ERROR');

export const changeClientPhone = createAction<ChangeClientPhone>('CHANGE_CLIENT_PHONE');
export const changeClientPhoneSuccess = createAction<IResponseWithoutValue>(
    'CHANGE_CLIENT_PHONE_SUCCESS',
);
export const changeClientPhoneError = createAction<PayloadError[]>('CHANGE_CLIENT_PHONE_ERROR');

export const changeClientType = createAction<ChangeClientType>('CHANGE_CLIENT_TYPE');
export const changeClientTypeSuccess = createAction<IResponseWithoutValue>(
    'CHANGE_CLIENT_TYPE_SUCCESS',
);

export const changePrivateNote = createAction<ChangePrivateNote>('CHANGE_PRIVATE_NOTE');
export const changePrivateNoteSuccess = createAction<IResponseWithoutValue>(
    'CHANGE_PRIVATE_NOTE_SUCCESS',
);
export const changePrivateNoteError = createAction<PayloadError[]>('CHANGE_PRIVATE_NOTE_ERROR');

export const changeClientTypeError = createAction<PayloadError[]>('CHANGE_CLIENT_TYPE_ERROR');
