import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  CloseButton,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { colors } from '../../../common/constants';

import { TimeSlotType } from '../../../common/types';

import { AddTimeSlotCore, IdNameTypes } from '../components';
import { getListingInfo } from '../../../store/actions/listings.actions';
import { RootStore } from '../../../store';

interface IAddTimeSlotModalProps {
  isOpen: boolean;
  onClose: () => void;
  listingId: number;
  loading: boolean;
  openHouseHostTypes: Array<IdNameTypes>;
  openHouseAudiences: Array<IdNameTypes>;
  openHouseTypes: Array<IdNameTypes>;
  openHouseTypesLoading: boolean;
  completedFieldsData?: TimeSlotType;
}

export const AddTimeSlotModal: React.FC<IAddTimeSlotModalProps> = ({
  isOpen,
  onClose,
  listingId,
  loading,
  openHouseHostTypes,
  openHouseAudiences,
  openHouseTypes,
  openHouseTypesLoading,
  completedFieldsData,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(getListingInfo(listingId));
    }
  }, [isOpen]);

  const {
    loading: listingInfoLoading,
    value: { timeZone },
  } = useSelector((state: RootStore) => state.listings.listingInfo);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        maxHeight="90vh"
        borderRadius="18px"
        bgColor={colors.modalBackground}
        alignItems="center"
      >
        <ModalHeader
          d="flex"
          w="800px"
          justifyContent="space-between"
          flexDirection="row"
          mt="20px"
        >
          <Text color="brand">Select time slot</Text>
          <Box onClick={onClose}>
            <CloseButton />
          </Box>
        </ModalHeader>
        <ModalBody overflowY="auto">
          <AddTimeSlotCore
            listingId={listingId}
            loading={loading || listingInfoLoading}
            timeZone={timeZone}
            openHouseTypesLoading={openHouseTypesLoading}
            openHouseHostTypes={openHouseHostTypes}
            openHouseAudiences={openHouseAudiences}
            openHouseTypes={openHouseTypes}
            completedFieldsData={completedFieldsData}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
