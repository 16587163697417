import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface IPricingSummaryItemProps {
    label: string;
    amount: number;
    color: string;
    subTitle?: string;
}

export const PricingSummaryItem: React.FC<IPricingSummaryItemProps> = ({
    amount,
    color,
    label,
    subTitle,
}) => (
    <Flex
        bg={color}
        w="48%"
        minH="200px"
        borderRadius="20px"
        flexDirection="column"
        justifyContent="space-between"
    >
        <Text
            fontSize="16px"
            lineHeight="24px"
            fontWeight="bold"
            color="white"
            textAlign="center"
            pt="10px"
        >
            {label}
        </Text>
        {subTitle && <Text
            fontSize="14px"
            px="20px"
            textAlign="center"
            lineHeight="24px"
            fontWeight="medium"
            color="white"
            mb="10px"
        >
            {subTitle}
        </Text>}
        <Text
            color="white"
            fontSize="24px"
            lineHeight="36px"
            textAlign="center"
            mb="10px"
            fontWeight="bold">
            $ {amount}
        </Text>
    </Flex>
);
