import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import {
    getPostTags,
    getPostTagsError,
    getPostTagsSuccess,
} from '../../actions/directories.actions';
import { IGetPostTagsSuccessResponse, IResponseWithCustomValue } from '../../../common/types';

function* workerGetPostTags() {
    try {
        const result: IResponseWithCustomValue<IGetPostTagsSuccessResponse> = yield call(
            Api.get,
            '/api/directories/get-post-tags',
            {},
        );
        console.log('getPostTags', result);
        if (result.success) {
            yield put(getPostTagsSuccess(result));
        } else {
            yield put(getPostTagsError(result.errors));
        }
    } catch (error) {
        console.log('workerGetPostTags error', error);
    }
}

export function* watchGetPostTagsDirectorySaga() {
    yield takeEvery(getPostTags.type, workerGetPostTags);
}
