import React from 'react';
import { LandingFooter, LandingHeader, TrialBanner } from '../../../common/components/landing';
import {
    Box,
    Container,
    Flex,
    Heading,
    Text,
    Image,
    Grid,
    useMediaQuery,
    Button,
    Link,
} from '@chakra-ui/react';

import { WhyItWorksCard } from './components/WhyItWorksCard';
import { ByTheDatesCard } from './components/ByTheDatesCard';

import AboutUsPeopleSvg from '../../../assets/landing/about-us-people.svg';
import AboutUsFamilySvg from '../../../assets/landing/about-us-family.svg';
import OurFounderFirst from '../../../assets/landing/people-1.png';
import OurFounderSecond from '../../../assets/landing/people-2.png';
import OurFounderThird from '../../../assets/landing/people-3.png';
import OurFounderFourth from '../../../assets/landing/people-4.png';
import OurFounderFive from '../../../assets/landing/people-5.png';
import RemaxSvg from '../../../assets/landing/agent-remax.svg';
import KwSvg from '../../../assets/landing/agent-kw.svg';
import SamsonSvg from '../../../assets/landing/agent-samson.svg';

import { fonts } from '../../../common/utils';
import { colors, marginSections } from '../../../common/constants';
import {
    firstSectionText,
    familySectionTextArr,
    joinTeamAdditionalText,
    whyItWorksCardsContent,
    byTheDatesCardsContent,
    ourFoundersTextFirst,
    ourFoundersTextSecond,
    ourFoundersTextThird,
    ourFoundersTextFourth,
    ourFoundersTextFive,
} from './text-data';

export const AboutUs = () => {
    const [isFamilySectionVertical] = useMediaQuery('(max-width: 850px)');
    const [isAgentsVertical] = useMediaQuery('(max-width: 530px)');

    const marginSection = isFamilySectionVertical ? marginSections['40'] : marginSections['160'];

    return (
        <>
            <LandingHeader />

            <Box bgColor={colors.calendarBlueOpacity}>
                <Container
                    maxW="container.xl"
                    minH="440px"
                    p="0 25px"
                    display="flex"
                    flexDir={isFamilySectionVertical ? 'column' : 'row'}
                    alignItems="center"
                >
                    <Flex
                        m="0"
                        p="25px 0"
                        h="100%"
                        flex="1 1"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <Heading
                            fontFamily={fonts.montserrat}
                            fontSize="40px"
                            lineHeight="70px"
                            fontWeight="600"
                        >
                            About Us
                        </Heading>
                        <Text
                            fontFamily={fonts.mulish}
                            fontSize="16px"
                            lineHeight="25px"
                            fontWeight="700"
                        >
                            {firstSectionText}
                        </Text>
                    </Flex>
                    <Box flex={isFamilySectionVertical ? '1 1' : '1 3'}>
                        <Image src={AboutUsPeopleSvg} h="fit-content"></Image>
                    </Box>
                </Container>
            </Box>

            <Box
                display={isFamilySectionVertical ? 'block' : 'none'}
                h="40px"
                bg="white"
                borderTopRadius="30px"
                mt="0"
                position="relative"
                top="-30"
            />

            <Container
                d="flex"
                m={`${marginSection} auto`}
                padding="0 25px"
                maxW="container.xl"
                flexDir={isFamilySectionVertical ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
                color={colors.brand}
            >
                <Box
                    flex="1 3"
                    order={isFamilySectionVertical ? 1 : 0}
                    mt={isFamilySectionVertical ? '40px' : '0'}
                >
                    <Image src={AboutUsFamilySvg} h="fit-content"></Image>
                </Box>

                <Box flex="1 1">
                    {familySectionTextArr.map((paragraph, index) => (
                        <Text
                            key={index}
                            mb={index === familySectionTextArr.length - 1 ? '0' : '15px'}
                            fontFamily={fonts.mulish}
                            fontSize={isAgentsVertical ? '16px' : '20px'}
                            lineHeight="25px"
                            fontWeight="400"
                        >
                            {paragraph}
                        </Text>
                    ))}
                </Box>
            </Container>

            <Flex
                bg={colors.calendarBlue}
                direction="column"
                justifyContent="space-evenly"
                align={{ base: 'left', md: 'center' }}
                p={{ base: '80px 15px', md: '0 15px' }}
                fontFamily={fonts.montserrat}
                minH="345px"
            >
                <Heading
                    m="8px"
                    fontFamily={fonts.montserrat}
                    fontWeight="bold"
                    fontSize="28px"
                    lineHeight={{ base: '32px', lg: '52px' }}
                    color="white"
                    textAlign={{ base: 'left', md: 'center' }}
                    maxWidth={1200}
                >
                    Join Our Team
                </Heading>

                <Text m="8px" maxW="725px" fontSize="16px" lineHeight="28px" color="white">
                    We are always looking for fast-paced people with bright ideas to come aboard.
                    Whether you’re a fast learner, technologist, real estate enthusiast, marketer,
                    researcher or designer, we are seeking ambitious, self-starters who are ready to
                    grow with our company.
                </Text>

                <Button
                    fontWeight="700"
                    fontSize={{ base: '14px', lg: '16px' }}
                    width={{ base: '100%', md: '306px' }}
                    height={{ base: '50px', lg: '60px' }}
                    backgroundColor="#FFBF00"
                    color={colors.brand}
                    borderRadius="25px"
                    my="20px"
                    _hover={{
                        textDecoration: 'none',
                    }}
                    as={Link}
                    href="/contact"
                >
                    Apply Now
                </Button>
            </Flex>

            <Container p="0 25px" maxW="container.xl">
                <Box p="0" mt={marginSection} w="100%" align="center" color={colors.brand}>
                    <Heading
                        my={{ base: marginSection, md: '95px' }}
                        fontSize={{ base: '20px', md: '28px' }}
                    >
                        Why It Works
                    </Heading>
                    <Grid
                        maxW="1245px"
                        templateColumns={{
                            base: '1fr',
                            md: '1fr 1fr',
                        }}
                        gap="25px"
                        position="relative"
                    >
                        {whyItWorksCardsContent.map(({ text, header }, index, arr) => (
                            <WhyItWorksCard
                                key={header + index}
                                header={header}
                                text={text}
                                hasDots={index === arr.length - 1}
                            />
                        ))}
                    </Grid>
                </Box>
                <Box p="0" mt={marginSection} w="100%" align="center" color={colors.brand}>
                    <Heading m="95px" display={{ base: 'none', md: 'block' }}>
                        By the Dates
                    </Heading>
                    <Heading
                        mt="65px"
                        mb="55px"
                        display={{ base: 'block', md: 'none' }}
                        fontSize="20px"
                        lineHeight="32px"
                    >
                        By the Dates
                    </Heading>
                    <Flex
                        mt={{ base: '90px', md: '0' }}
                        maxW="1225px"
                        flexWrap="wrap"
                        justifyContent="center"
                        position="relative"
                    >
                        {byTheDatesCardsContent.map(({ img, text, header }, index, arr) => {
                            const addDots = () => {
                                if (index === 0) {
                                    return 'top';
                                }
                                if (index === arr.length - 1) {
                                    return 'bottom';
                                }
                            };
                            return (
                                <ByTheDatesCard
                                    key={index}
                                    img={img}
                                    header={header}
                                    text={text}
                                    hasDots={addDots()}
                                />
                            );
                        })}
                    </Flex>
                </Box>
                <Box
                    padding="0 15px 60px"
                    mt={marginSection}
                    w="100%"
                    align="center"
                    color={colors.brand}
                >
                    <Heading my="95px" display={{ base: 'none', md: 'block' }}>
                        Trusted By Agents at...
                    </Heading>
                    <Heading
                        m="70px 0 30px"
                        display={{ base: 'block', md: 'none' }}
                        fontSize="20px"
                        lineHeight="32px"
                    >
                        Trusted By Agents at...
                    </Heading>
                    <Flex
                        maxW="770px"
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={{ base: 'column', md: 'row' }}
                    >
                        <Image src={RemaxSvg} alt="agent" w="178px" p="15px 0"></Image>
                        <Image src={KwSvg} alt="agent" w="86px" p="15px 0"></Image>
                        <Image src={SamsonSvg} alt="agent" w="188px" p="15px 0"></Image>
                    </Flex>
                </Box>
            </Container>

            <Box
                bgColor={colors.calendarBlueOpacity}
                pb={isFamilySectionVertical ? '50px' : '200px'}
                pt={isAgentsVertical ? '65px' : '120px'}
            >
                <Container maxW="container.xl">
                    <Heading
                        color={colors.brand}
                        lineHeight={isAgentsVertical ? '32px' : '48px'}
                        fontSize={isAgentsVertical ? '20px' : '32px'}
                        fontWeight="700"
                        fontFamily={fonts.montserrat}
                        mb={isAgentsVertical ? '55px' : '95px'}
                        display={{ md: 'block' }}
                        textAlign="center"
                    >
                        Our Founders
                    </Heading>
                    <Flex
                        minH="440px"
                        mb={isFamilySectionVertical ? '50px' : '170px'}
                        flexDir={isFamilySectionVertical ? 'column' : 'row'}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box
                            flex={isFamilySectionVertical ? '1 1' : '1 3'}
                            maxWidth={isFamilySectionVertical ? '100%' : '480px'}
                            pb={isAgentsVertical ? '25px' : '0px'}
                        >
                            <Image src={OurFounderFirst} h="fit-content" />
                        </Box>
                        <Flex
                            m="0"
                            p="25px 10px"
                            h="100%"
                            flex="1 1"
                            flexDirection="column"
                            justifyContent="center"
                            maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                        >
                            <Text
                                fontFamily={fonts.mulish}
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight="400"
                                color={colors.brand}
                            >
                                {ourFoundersTextFirst}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex
                        minH="440px"
                        mb={isFamilySectionVertical ? '50px' : '170px'}
                        flexDir={isFamilySectionVertical ? 'column-reverse' : 'row'}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Flex
                            m="0"
                            p="25px 10px"
                            h="100%"
                            flex="1 1"
                            flexDirection="column"
                            justifyContent="center"
                            maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                        >
                            {ourFoundersTextSecond.map((paragraph, index) => (
                                <Text
                                    maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                                    key={index}
                                    mb={index === ourFoundersTextSecond.length - 1 ? '0' : '30px'}
                                    fontFamily={fonts.mulish}
                                    fontSize="16px"
                                    lineHeight="24px"
                                    fontWeight="400"
                                    color={colors.brand}
                                >
                                    {paragraph}
                                </Text>
                            ))}
                        </Flex>
                        <Box
                            flex={isFamilySectionVertical ? '1 1' : '1 3'}
                            maxWidth={isFamilySectionVertical ? '100%' : '480px'}
                            pb={isAgentsVertical ? '25px' : '0px'}
                        >
                            <Image src={OurFounderSecond} h="fit-content" />
                        </Box>
                    </Flex>
                    <Flex
                        minH="440px"
                        mb={isFamilySectionVertical ? '50px' : '170px'}
                        flexDir={isFamilySectionVertical ? 'column' : 'row'}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box
                            flex={isFamilySectionVertical ? '1 1' : '1 3'}
                            maxWidth={isFamilySectionVertical ? '100%' : '480px'}
                            pb={isAgentsVertical ? '25px' : '0px'}
                        >
                            <Image src={OurFounderThird} h="fit-content" />
                        </Box>
                        <Flex
                            m="0"
                            p="25px 10px"
                            h="100%"
                            flex="1 1"
                            flexDirection="column"
                            justifyContent="center"
                            maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                        >
                            <Text
                                fontFamily={fonts.mulish}
                                maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight="400"
                                color={colors.brand}
                            >
                                {ourFoundersTextThird}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex
                        minH="440px"
                        mb={isFamilySectionVertical ? '50px' : '170px'}
                        flexDir={isFamilySectionVertical ? 'column-reverse' : 'row'}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Flex
                            m="0"
                            p="25px 10px"
                            h="100%"
                            flex="1 1"
                            flexDirection="column"
                            justifyContent="center"
                            maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                        >
                            {ourFoundersTextFourth.map((paragraph, index) => (
                                <Text
                                    key={index}
                                    mb={index === ourFoundersTextFourth.length - 1 ? '0' : '30px'}
                                    fontFamily={fonts.mulish}
                                    fontSize="16px"
                                    maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                                    lineHeight="24px"
                                    fontWeight="400"
                                    color={colors.brand}
                                >
                                    {paragraph}
                                </Text>
                            ))}
                        </Flex>
                        <Box
                            flex={isFamilySectionVertical ? '1 1' : '1 3'}
                            maxWidth={isFamilySectionVertical ? '100%' : '480px'}
                            pb={isAgentsVertical ? '25px' : '0px'}
                        >
                            <Image src={OurFounderFourth} h="fit-content" />
                        </Box>
                    </Flex>
                    <Flex
                        minH="440px"
                        flexDir={isFamilySectionVertical ? 'column' : 'row'}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box
                            flex={isFamilySectionVertical ? '1 1' : '1 3'}
                            maxWidth={isFamilySectionVertical ? '100%' : '480px'}
                            pb={isAgentsVertical ? '25px' : '0px'}
                        >
                            <Image src={OurFounderFive} h="fit-content" />
                        </Box>
                        <Flex
                            m="0"
                            p="25px 10px"
                            h="100%"
                            flex="1 1"
                            flexDirection="column"
                            justifyContent="center"
                            maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                        >
                            {ourFoundersTextFive.map((paragraph, index) => (
                                <Text
                                    key={index}
                                    mb={index === ourFoundersTextFive.length - 1 ? '0' : '30px'}
                                    fontFamily={fonts.mulish}
                                    fontSize="16px"
                                    lineHeight="24px"
                                    maxWidth={isFamilySectionVertical ? '100%' : '550px'}
                                    fontWeight="400"
                                    color={colors.brand}
                                >
                                    {paragraph}
                                </Text>
                            ))}
                        </Flex>
                    </Flex>
                </Container>
            </Box>
            <TrialBanner
                title="Grow your real estate business through by leveraging affilliated agents"
                buttonTitle="Start"
                textUnderButton="Cancel anytime"
            />
            <LandingFooter />
        </>
    );
};
