import React from 'react'
import {Tag, TagLabel} from "@chakra-ui/react";

interface ICustomTagProps {
    isSelected: boolean
    name:string
    setSelected: () => void
}

export const CustomTag:React.FC<ICustomTagProps> = ({
    isSelected,
    name,
    setSelected
 }) => (
    <>
        <Tag
            backgroundColor={isSelected ? '#FFFFFF' : '#2C335C'}
            onClick={setSelected}
            boxShadow={'none'}
            border={'1px solid #D1D2DB'}
            px={'12px'}
            py={'8px'}
            height={'32px'}
            borderRadius={'40px'}
            as={'button'}
            variant={isSelected ? 'subtle' : 'outline'}
        >
            <TagLabel
                color={isSelected ? '#2C335C' : '#888DAB'}
                fontWeight={500}
                fontSize={12}
            >
                {name}
            </TagLabel>
        </Tag>
    </>
)
