import { createSlice } from '@reduxjs/toolkit';
import {
    toggleLandingVideoModal,
    sendCooperationDetailRequest,
    sendCooperationDetailSuccess,
    sendCooperationDetailError,
    sendContactForm,
    sendContactFormSuccess,
    sendContactFormError,
} from '../actions/landing.actions';

type InitialState = {
    videoModalOpen: boolean;
    sendingDetailsLoading: boolean;
    sendingContactLoading: boolean;
};

const initialState: InitialState = {
    videoModalOpen: false,
    sendingDetailsLoading: false,
    sendingContactLoading: false,
};

const landingSlice = createSlice({
    name: 'landing',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(toggleLandingVideoModal, (state, action) => {
                state.videoModalOpen = action.payload;
            })
            .addCase(sendCooperationDetailRequest, state => {
                state.sendingDetailsLoading = true;
            })
            .addCase(sendCooperationDetailSuccess, state => {
                state.sendingDetailsLoading = false;
            })
            .addCase(sendCooperationDetailError, state => {
                state.sendingDetailsLoading = false;
            })
            .addCase(sendContactForm, state => {
                state.sendingContactLoading = true;
            })
            .addCase(sendContactFormSuccess, state => {
                state.sendingContactLoading = false;
            })
            .addCase(sendContactFormError, state => {
                state.sendingContactLoading = false;
            });
    },
});

export default landingSlice.reducer;
