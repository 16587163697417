import { createAction } from '@reduxjs/toolkit';
import { PayloadError } from '../../../../../common/types';
import {
    AddBankAccountWithPlaidPayload,
    AddNewBankAccountPayload,
    CreateDwollaAccountPayload,
    EditDwollaAccountPayload,
    GetCustomerGeneralInfoResponse,
    GetFundingSourcesResponse,
    GetProcessorTokenPayload,
    PlaidLinkTokenResponse,
    UpdatePaymentMethodPayload,
    UploadsNewDocumentPayload,
    UserToSelfTransferPayload,
    VerifyInitDepositsPayload,
    GetTransactionHistoryPayload,
    GetTransactionHistorySuccessPayload,
} from '../types';

export const checkUserPaymentAccountRequest = createAction('CHECK_USER_PAYMENT_ACCOUNT_REQUEST');
export const checkUserPaymentAccountSuccess = createAction<boolean>(
    'CHECK_USER_PAYMENT_ACCOUNT_SUCCESS',
);
export const checkUserPaymentAccountError = createAction<PayloadError[]>(
    'CHECK_USER_PAYMENT_ACCOUNT_ERROR',
);
// CREATE USER DWOLLA ACCOUNT
export const createDwollaAccountRequest = createAction<CreateDwollaAccountPayload>(
    'CREATE_DWOLLA_ACCOUNT_REQUEST',
);
export const createDwollaAccountSuccess = createAction('CREATE_DWOLLA_ACCOUNT_SUCCESS');
export const createDwollaAccountError = createAction<PayloadError[]>('CREATE_DWOLLA_ACCOUNT_ERROR');
// GET DWOLLA WALLET BALANCE
export const getDwollaWalletBalanceRequest = createAction('GET_DWOLLA_WALLET_BALANCE_REQUEST');
export const getDwollaWalletBalanceSuccess = createAction<number>(
    'GET_DWOLLA_WALLET_BALANCE_SUCCESS',
);
export const getDwollaWalletBalanceError = createAction<PayloadError[]>(
    'GET_DWOLLA_WALLET_BALANCE_ERROR',
);
// GET CUSTOMER ACCOUNT STATUS
export const getCustomerAccountStatusRequest = createAction('GET_CUSTOMER_ACCOUNT_STATUS_REQUEST');
export const getCustomerAccountStatusSuccess = createAction<string>(
    'GET_CUSTOMER_ACCOUNT_STATUS_SUCCESS',
);
export const getCustomerAccountStatusError = createAction<PayloadError[]>(
    'GET_CUSTOMER_ACCOUNT_STATUS_ERROR',
);
// GET CUSTOMER GENERAL INFO
export const getCustomerGeneralInfoRequest = createAction('GET_CUSTOMER_GENERAL_INFO');
export const getCustomerGeneralInfoSuccess = createAction<GetCustomerGeneralInfoResponse>(
    'GET_CUSTOMER_GENERAL_SUCCESS',
);
export const getCustomerGeneralInfoError = createAction<PayloadError[]>(
    'GET_CUSTOMER_GENERAL_ERROR',
);
// EDIT USER DWOLLA ACCOUNT
export const editDwollaAccountRequest = createAction<EditDwollaAccountPayload>(
    'EDIT_DWOLLA_ACCOUNT_REQUEST',
);
export const editDwollaAccountSuccess = createAction('EDIT_DWOLLA_ACCOUNT_SUCCESS');
export const editDwollaAccountError = createAction<PayloadError[]>('EDIT_DWOLLA_ACCOUNT_ERROR');
// UPDATE CUSTOMER WHEN RETRY STATUS
export const updateCustomerRequest =
    createAction<CreateDwollaAccountPayload>('UPDATE_CUSTOMER_REQUEST');
export const updateCustomerSuccess = createAction('UPDATE_CUSTOMER_SUCCESS');
export const updateCustomerError = createAction<PayloadError[]>('UPDATE_CUSTOMER_ERROR');
// UPLOADS NEW DOCUMENT
export const uploadsNewDocumentRequest = createAction<UploadsNewDocumentPayload>(
    'UPLOADS_NEW_DOCUMENT_REQUEST',
);
export const uploadsNewDocumentSuccess = createAction('UPLOADS_NEW_DOCUMENT_SUCCESS');
export const uploadsNewDocumentError = createAction<PayloadError[]>('UPLOADS_NEW_DOCUMENT_ERROR');
// GET FUNDING SOURCES
export const getFundingSourcesRequest = createAction<boolean>('GET_FUNDING_SOURCE_REQUEST');
export const getFundingSourcesSuccess = createAction<GetFundingSourcesResponse[]>(
    'GET_FUNDING_SOURCE_SUCCESS',
);
export const getFundingSourcesError = createAction<PayloadError[]>('GET_FUNDING_SOURCE_ERROR');
// SET SENDING FUNDING SOURCE
export const setSendingFundingSourceRequest = createAction<string>(
    'SET_SENDING_FUNDING_SOURCE_REQUEST',
);
export const setSendingFundingSourceSuccess = createAction('SET_SENDING_FUNDING_SOURCE_SUCCESS');
export const setSendingFundingSourceError = createAction<PayloadError[]>(
    'SET_SENDING_FUNDING_SOURCE_ERROR',
);
// SET RECEIVING FUNDING SOURCE
export const setReceivingFundingSourceRequest = createAction<string>(
    'SET_RECEIVING_FUNDING_SOURCE_REQUEST',
);
export const setReceivingFundingSourceSuccess = createAction(
    'SET_RECEIVING_FUNDING_SOURCE_SUCCESS',
);
export const setReceivingFundingSourceError = createAction<PayloadError[]>(
    'SET_RECEIVING_FUNDING_SOURCE_ERROR',
);
// CHECK IF USER CAN ADD NEW FUNDING SOURCE
export const checkUserAddFundingSourceRequest = createAction(
    'CHECK_USER_ADD_FUNDING_SOURCE_REQUEST',
);
export const checkUserAddFundingSourceSuccess = createAction<boolean>(
    'CHECK_USER_ADD_FUNDING_SOURCE_SUCCESS',
);
export const checkUserAddFundingSourceError = createAction<PayloadError[]>(
    'CHECK_USER_ADD_FUNDING_SOURCE_ERROR',
);
// ADD NEW BANK ACCOUNT
export const addNewBankAccountRequest = createAction<AddNewBankAccountPayload>(
    'ADD_NEW_BANK_ACCOUNT_REQUEST',
);
export const addNewBankAccountSuccess = createAction('ADD_NEW_BANK_ACCOUNT_SUCCESS');
export const addNewBankAccountError = createAction<PayloadError[]>('ADD_NEW_BANK_ACCOUNT_ERROR');
// REMOVE FUNDING SOURCE
export const removeFundingSourceRequest = createAction<string>('REMOVE_FUNDING_SOURCE_REQUEST');
export const removeFundingSourceSuccess = createAction('REMOVE_FUNDING_SOURCE_SUCCESS');
export const removeFundingSourceError = createAction<PayloadError[]>('REMOVE_FUNDING_SOURCE_ERROR');
// UPDATE NICKNAME BANK
export const updatePaymentMethodRequest = createAction<UpdatePaymentMethodPayload>(
    'UPDATE_PAYMENT_METHOD_REQUEST',
);
export const updatePaymentMethodSuccess = createAction('UPDATE_PAYMENT_METHOD_SUCCESS');
export const updatePaymentMethodError = createAction<PayloadError[]>('UPDATE_PAYMENT_METHOD_ERROR');
// INIT MICRO DEPOSIT VERIFICATION
export const initVerificationFundingSourceRequest = createAction<string>(
    'INIT_VERIFICATION_FUNDING_SOURCE_REQUEST',
);
export const initVerificationFundingSourceSuccess = createAction(
    'INIT_VERIFICATION_FUNDING_SOURCE_SUCCESS',
);
export const initVerificationFundingSourceError = createAction<PayloadError[]>(
    'INIT_VERIFICATION_FUNDING_SOURCE_ERROR',
);
// VERIFY MICRO DEPOSITS
export const verifyMicroDepositsRequest = createAction<VerifyInitDepositsPayload>(
    'VERIFY_MICRO_DEPOSITS_REQUEST',
);
export const verifyMicroDepositsSuccess = createAction('VERIFY_MICRO_DEPOSITS_SUCCESS');
export const verifyMicroDepositsError = createAction<PayloadError[]>('VERIFY_MICRO_DEPOSITS_ERROR');
// CREATE USER TO SELF TRANSFER
export const createUserToSelfTransferRequest = createAction<UserToSelfTransferPayload>(
    'CREATE_USER_TO_SELF_TRANSFER_REQUEST',
);
export const createUserToSelfTransferSuccess = createAction('CREATE_USER_TO_SELF_TRANSFER_SUCCESS');
export const createUserToSelfTransferError = createAction<PayloadError[]>(
    'CREATE_USER_TO_SELF_TRANSFER_ERROR',
);
// GET PLAID LINK TOKEN
export const getPlaidLinkToken = createAction<boolean>('GET_PLAID_LINK_TOKEN');
export const getPlaidLinkTokenSuccess = createAction<PlaidLinkTokenResponse>(
    'GET_PLAID_LINK_TOKEN_SUCCESS',
);
export const getPlaidLinkTokenError = createAction<PayloadError[]>('GET_PLAID_LINK_TOKEN_ERROR');
// GET PROCESSOR TOKEN
export const getProcessorToken = createAction<GetProcessorTokenPayload>('GET_PROCESSOR_TOKEN');
export const getProcessorTokenSuccess = createAction<string>('GET_PROCESSOR_TOKEN_SUCCESS');
export const getProcessorTokenError = createAction<PayloadError[]>('GET_PROCESSOR_TOKEN_ERROR');

// ADD BANK ACCOUNT WITH PLAID
export const addBankAccountWithPlaid = createAction<AddBankAccountWithPlaidPayload>(
    'ADD_BANK_ACCOUNT_WITH_PLAID',
);
export const addBankAccountWithPlaidSuccess = createAction('ADD_BANK_ACCOUNT_WITH_PLAID_SUCCESS');
export const addBankAccountWithPlaidError = createAction<PayloadError[]>(
    'ADD_BANK_ACCOUNT_WITH_PLAID_ERROR',
);

// SET USER CAN ADD BANK ACCOUNT
export const setIsUserCanAddBankAccount = createAction('SET_IS_USER_CAN_ADD_BANK_ACCOUNT');

export const toggleReloginAlertModal = createAction<boolean>('TOGGLE_RELOGIN_ALERT_MODAL');

export const getTransactionHistory = createAction<GetTransactionHistoryPayload>('GET_TRANSACTION_HISTORY');
export const getTransactionHistorySuccess = createAction<GetTransactionHistorySuccessPayload>('GET_TRANSACTION_HISTORY_SUCCESS');
export const getTransactionHistoryError = createAction<PayloadError[]>('GET_TRANSACTION_HISTORY_ERROR');