import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const editPhoneSchema = yup.object().shape({
    phoneNumber: yup
        .string()
        .required(ErrorMessages.REQUIRED)
        .test('phone-number', ErrorMessages.PHONE_NUMBER_INCOMPLETE, function (value?: string) {
            return value?.replace(/[-_]/g, '').length === 10;
        }),
});

export const editPhoneResolver = yupResolver(editPhoneSchema);
