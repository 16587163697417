import { createAction } from '@reduxjs/toolkit';
import {
    PayloadError,
    ProfileImageType,
    ToggleUserProfileModalPayload,
    AdditionalInfoPayload
} from '../../common/types';

export const toggleUserProfileModal = createAction<ToggleUserProfileModalPayload>(
    '@profile/TOGGLE_USER_PROFILE_MODAL',
);

export const getUserProfileImage = createAction<number>('GET_USER_PROFILE_IMAGE');
export const getUserProfileImageSuccess = createAction<ProfileImageType>(
    'GET_USER_PROFILE_IMAGE_SUCCESS',
);
export const getUserProfileImageError = createAction<{ errors: PayloadError[]; userId: number }>(
    'GET_USER_PROFILE_IMAGE_ERROR',
);

export const deleteProfileLeftovers = createAction<number>('DELETE_PROFILE_LEFTOVERS');

export const changeAdditionalProfileInfo = createAction<AdditionalInfoPayload>(
    'CHANGE_ADDITIONAL_PROFILE_INFO',
);
