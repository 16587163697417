import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Button,
    HStack,
    PinInput,
    PinInputField,
    CloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Controller, useForm } from 'react-hook-form';
import { BackArrow } from '../../../assets/icons';
import { CustomInput } from '../../../common/components';
import { colors } from '../../../common/constants';
import { countdownRenderer } from '../../../common/utils';
import { UpdatePhoneNumberPayload, VerifyPhoneNumberPayload } from '../types';
import { editPhoneResolver } from '../../../common/schemes';
import { PayloadError } from '../../../common/types';
import { useDelta } from 'react-delta';

interface IEditPhoneNumberModal {
    isOpen: boolean;
    onClose: () => void;
    editPhoneNumber: (v: UpdatePhoneNumberPayload) => void;
    verifyPhoneNumber: (v: VerifyPhoneNumberPayload) => void;
    isCodeVerified: boolean;
    clear: () => void;
    loading: boolean;
    stateErrors: PayloadError[];
}

export const EditPhoneNumberModal: React.FC<IEditPhoneNumberModal> = ({
    isOpen,
    onClose,
    editPhoneNumber,
    verifyPhoneNumber,
    isCodeVerified,
    clear,
    loading,
    stateErrors,
}) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
        setValue,
        clearErrors,
    } = useForm({ resolver: editPhoneResolver });

    const [codeSent, setCodeSent] = useState(false);

    const values = watch();

    const loadingDelta = useDelta(loading);

    const handleClose = () => {
        onClose();
        clear();
        setCodeSent(false);
    };

    const onSubmitPhoneNumber = (data: { phoneNumber: string }) => {
        editPhoneNumber({ phoneNumber: data.phoneNumber });
        setCodeSent(true);
    };

    useEffect(() => {
        if (values?.phoneNumberCode?.length === 4) {
            setValue('phoneNumberCode', '');
            verifyPhoneNumber({ phoneNumber: values?.phoneNumber, code: values?.phoneNumberCode });
        }
    }, [values]);

    useEffect(() => {
        if (!isOpen) {
            setCodeSent(false);
            clearErrors(undefined);
        }
    }, [isOpen]);

    useEffect(() => {
        if (loadingDelta && loadingDelta.prev) {
            if (loadingDelta.prev && !loadingDelta.curr && !stateErrors.length) {
                handleClose();
            }
        }
    }, [loadingDelta]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <Box as="button" onClick={onClose}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text color="brand" textAlign="center">
                            Edit Phone Number
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody d="flex" flexDirection="column" overflowY="auto">
                    <form onSubmit={handleSubmit(onSubmitPhoneNumber)}>
                        <Flex direction="column" align="center" mt="30px">
                            <Flex mb="15px">
                                <Flex w="100%" mb="15px">
                                    <CustomInput
                                        label="Enter New Phone Number"
                                        width="100%"
                                        type="tel"
                                        mask="999-999-9999"
                                        placeholder="Enter New Phone Number"
                                        register={{ ...register('phoneNumber') }}
                                        errors={errors.phoneNumber}
                                    />
                                </Flex>
                            </Flex>
                            {!codeSent ? (
                                <Button variant="main" w="100%" type="submit">
                                    Send Code
                                </Button>
                            ) : (
                                <>
                                    <Text
                                        color="brand"
                                        alignSelf="baseline"
                                        fontWeight={600}
                                        fontSize="16px"
                                        mb="20px"
                                    >
                                        We Sent You A 4-Digit Security Code
                                    </Text>
                                    <Controller
                                        control={control}
                                        name="phoneNumberCode"
                                        render={({ field: { ref, ...restField } }) => (
                                            <HStack spacing="15px">
                                                <PinInput
                                                    placeholder="-"
                                                    autoFocus={true}
                                                    {...restField}
                                                >
                                                    <PinInputField
                                                        w="69px"
                                                        h="63px"
                                                        borderRadius="15px"
                                                        ref={ref}
                                                        bgColor="white"
                                                    />
                                                    <PinInputField
                                                        w="69px"
                                                        h="63px"
                                                        borderRadius="15px"
                                                        bgColor="white"
                                                    />
                                                    <PinInputField
                                                        w="69px"
                                                        h="63px"
                                                        borderRadius="15px"
                                                        bgColor="white"
                                                    />
                                                    <PinInputField
                                                        w="69px"
                                                        h="63px"
                                                        borderRadius="15px"
                                                        bgColor="white"
                                                    />
                                                </PinInput>
                                            </HStack>
                                        )}
                                    />
                                    <Text
                                        color="green"
                                        fontWeight={600}
                                        fontSize="18px"
                                        lineHeight="18px"
                                        mt="30px"
                                    >
                                        <Countdown
                                            date={Date.now() + 2 * 60 * 1000}
                                            renderer={countdownRenderer}
                                        />
                                    </Text>
                                    <Button
                                        width="100%"
                                        height="59px"
                                        variant="main"
                                        mt="30px"
                                        onClick={() =>
                                            editPhoneNumber({ phoneNumber: values?.phoneNumber })
                                        }
                                    >
                                        Resend
                                    </Button>
                                </>
                            )}
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
