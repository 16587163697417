import { createAction } from '@reduxjs/toolkit';
import {
    ChangeEmailPayload,
    UpdatePhoneNumberPayload,
    VerifyEmailCodePayload,
    VerifyPhoneNumberPayload,
    VerifyEmailPayload,
    ChangePasswordPayload,
    ChangeFullNamePayload,
    ChangeLicencePhotoPayload,
    ChangeUserAvatarPayload,
    EditRatePayload,
    AddCalendarIdPayload,
    AddCalendarPayload,
    SaveLicenseDraftPayload,
    AddLicensePayload,
    LicenseType,
    LicenseDraftType,
    SetAvailableRequestsPayload,
    RatesType,
    SetNotificationSettingsRequestPayload,
} from '../types';
import { PayloadError, IResponseWithCustomValue } from '../../../common/types';

export const sendCodeToEmailRequest = createAction('SEND_CODE_TO_EMAIL_REQUEST');
export const sendCodeToEmailSuccess = createAction('SEND_CODE_TO_EMAIL_SUCCESS');
export const sendCodeToEmailError = createAction('SEND_CODE_TO_EMAIL_ERROR');

export const verifyEmailCodeRequest = createAction<VerifyEmailCodePayload>(
    'VERIFY_EMAIL_CODE_REQUEST',
);
export const verifyEmailCodeSuccess = createAction('VERIFY_EMAIL_CODE_SUCCESS');
export const verifyEmailCodeError = createAction('VERIFY_EMAIL_CODE_ERROR');

export const updatePhoneNumberRequest = createAction<UpdatePhoneNumberPayload>(
    'UPDATE_PHONE_NUMBER_REQUEST',
);
export const updatePhoneNumberSuccess = createAction('UPDATE_PHONE_NUMBER_SUCCESS');
export const updatePhoneNumberError = createAction('UPDATE_PHONE_NUMBER_ERROR');

export const verifyPhoneNumberRequest = createAction<VerifyPhoneNumberPayload>(
    'VERIFY_PHONE_NUMBER_REQUEST',
);
export const verifyPhoneNumberSuccess = createAction('VERIFY_PHONE_NUMBER_SUCCESS');
export const verifyPhoneNumberError = createAction<PayloadError[]>('VERIFY_PHONE_NUMBER_ERROR');

export const changeEmailRequest = createAction<ChangeEmailPayload>('CHANGE_EMAIL_REQUEST');
export const changeEmailSuccess = createAction('CHANGE_EMAIL_SUCCESS');
export const changeEmailError = createAction('CHANGE_EMAIL_ERROR');

export const verifyEmailRequest = createAction<VerifyEmailPayload>('VERIFY_EMAIL_REQUEST');
export const verifyEmailSuccess = createAction('VERIFY_EMAIL_SUCCESS');
export const verifyEmailError = createAction<PayloadError[]>('VERIFY_EMAIL_ERROR');

export const changePasswordRequest = createAction<ChangePasswordPayload>('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordError = createAction('CHANGE_PASSWORD_ERROR');

export const changeFullNameRequest = createAction<ChangeFullNamePayload>('CHANGE_FULLNAME_REQUEST');
export const changeFullNameSuccess = createAction('CHANGE_FULLNAME_SUCCESS');
export const changeFullNameError = createAction('CHANGE_FULLNAME_ERROR');

export const changeLicensePhotoRequest = createAction<ChangeLicencePhotoPayload>(
    'CHANGE_LICENSE_PHOTO_REQUEST',
);
export const changeLicensePhotoSuccess = createAction('CHANGE_LICENSE_PHOTO_SUCCESS');
export const changeLicensePhotoError = createAction('CHANGE_LICENSE_PHOTO_ERROR');

export const changeUserAvatarRequest = createAction<ChangeUserAvatarPayload>(
    'CHANGE_USER_AVATAR_REQUEST',
);
export const changeUserAvatarSuccess = createAction('CHANGE_USER_AVATAR_SUCCESS');
export const changeUserAvatarError = createAction('CHANGE_USER_AVATAR_ERROR');

export const clearFields = createAction('CLEAR_FIELDS');

export const getRatesRequest = createAction('GET_RATES_REQUEST');
export const getRatesSuccess =
    createAction<IResponseWithCustomValue<{ rates: RatesType[] }>>('GET_RATES_SUCCESS');
export const getRatesError = createAction('GET_RATES_ERROR');

export const editRateRequest = createAction<EditRatePayload>('EDIT_RATE_REQUEST');
export const editRateSuccess = createAction('EDIT_RATE_SUCCESS');
export const editRateError = createAction('EDIT_RATE_ERROR');

export const getGoogleCalendarRequest = createAction('GET_GOOGLE_CALENDAR_REQUEST');
export const getGoogleCalendarSuccess = createAction<string>('GET_GOOGLE_CALENDAR_SUCCESS');
export const getGoogleCalendarError = createAction('GET_GOOGLE_CALENDAR_ERROR');

export const addCalendarIdRequest = createAction<AddCalendarIdPayload>('ADD_CELENDAR_ID_REQUEST');
export const addCalendarIdSuccess = createAction('ADD_CALENDAR_ID_SUCCESS');
export const addCalendarIdError = createAction('ADD_CALENDAR_ID_ERROR');

export const getCalendarRequest = createAction('GET_CALENDAR_REQUEST');
export const getCalendarSuccess =
    createAction<IResponseWithCustomValue<any>>('GET_CALENDAR_SUCCESS');
export const getCalendarError = createAction('GET_CALENDAR_ERROR');

export const addCalendarRequest = createAction<AddCalendarPayload>('ADD_CALENDAR_REQUEST');
export const addCalendarSuccess = createAction('ADD_CALENDAR_SUCCESS');
export const addCalendarError = createAction('ADD_CALENDAR_ERROR');

export const getLicensesRequest = createAction('GET_LICENSE_REQUEST');
export const getLicenseSuccess = createAction<LicenseType[]>('GET_LICENSE_SUCCESS');
export const getLicenseError = createAction('GET_LICENSE_ERROR');

export const saveLicenseDraftRequest = createAction<SaveLicenseDraftPayload>(
    'SAVE_LICENSE_DRAFT_REQUEST',
);
export const saveLicenseDraftSuccess = createAction<number>('SAVE_LICENSE_DRAFT_SUCCESS');
export const saveLicenseDraftError = createAction<PayloadError[]>('SAVE_LICENSE_DRAFT_ERROR');

export const getLicenseDraftRequest = createAction('GET_LICENSE_DRAFT_REQUEST');
export const getLicenesDraftSuccess = createAction<LicenseDraftType | null>(
    'GET_LICENSE_DRAFT_SUCCESS',
);
export const getLicenseDraftError = createAction('GET_LICENSE_DRAFT_ERROR');

export const addLicenseRequest = createAction<AddLicensePayload>('ADD_LICENSE_REQUEST');
export const addLicenseSuccess = createAction<number>('ADD_LICENSE_SUCCESS');
export const addLicenseError = createAction<PayloadError[]>('ADD_LICENSE_ERROR');

export const getAvailableRequests = createAction('GET_AVAILABLE_REQUESTS');
export const getAvailableRequestsSuccess = createAction<SetAvailableRequestsPayload>(
    'GET_AVAILABLE_REQUESTS_SUCCESS',
);
export const getAvailableRequestsError = createAction<PayloadError[]>(
    'GET_AVAILABLE_REQUESTS_ERROR',
);

export const setAvailableRequests =
    createAction<SetAvailableRequestsPayload>('SET_AVAILABLE_REQUESTS');
export const setAvailableRequestsSuccess = createAction('SET_AVAILABLE_REQUESTS_SUCCESS');
export const setAvailableRequestsError = createAction<PayloadError[]>(
    'SET_AVAILABLE_REQUESTS_ERROR',
);

export const getNotificationSettingsRequest =
    createAction('GET_NOTIFICATION_SETTINGS_REQUEST');

export const getNotificationSettingsRequestSuccess = createAction<SetNotificationSettingsRequestPayload>(
    'GET_NOTIFICATION_SETTINGS_REQUEST_SUCCESS',
);

export const getNotificationSettingsRequestError = createAction<PayloadError[]>(
    'GET_NOTIFICATION_SETTINGS_REQUEST_ERROR',
);

export const setNotificationSettingsRequest = createAction<SetNotificationSettingsRequestPayload>(
    'SET_NOTIFICATION_SETTINGS_REQUEST',
);
