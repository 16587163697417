import React, { useEffect, useState } from 'react';
import { UserProfileModal } from './UserProfileModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import {
    toggleUserProfileModal,
    getUserProfileImage,
    deleteProfileLeftovers,
} from '../../../../store/actions/profile.actions';
import { getUserFeedbacks } from '../../../../pages/Requests/store/actions';
import { getAgentOpenHouses } from '../../../../store/actions/listings.actions';
import { createChat } from './../../../../store/actions/messages.actions';
import { AgentListingInfoModal, SearchAgentByAreaModalContainer } from './modals';
import { ToggleUserProfileModalPayload, SearchAgentByAreaPayload } from '../../../../common/types';
import { getStates } from '../../../../store/actions/directories.actions';
import {searchAgentsByArea} from "../../../../store/actions/user.actions";

const defaultProfileImage = {
    id: 0,
    avatar: null,
    email: '',
    fullName: '',
    latitude: 0,
    licenses: '',
    longitude: 0,
    phone: '',
    pricingPerDoor: 0,
    pricingPerHour: 0,
    agency: '',
    assistGiven: 0,
    assistHired: 0,
    openHousesConducted: 0,
    openHousesMade: 0,
    rating: 0,
    registrationDate: '',
    notToUseLocation: true,
    preferredAreasOfService: [],
};

interface IUserProfileModalContainerProps {
    userId: number;
}

export const UserProfileModalContainer: React.FC<IUserProfileModalContainerProps> = ({
    userId,
}) => {
    const dispatch = useDispatch();

    const {
        isOpen,
        userProfileImage = defaultProfileImage,
        loading: userProfileImageLoading = false,
        toFeeback,
    } = useSelector((state: RootStore) => state.profile[userId]);
    const profile = useSelector((state: RootStore) => state.profile);
    const [tabIndex, setTabIndex] = useState(0);
    const [openAgentListingModalInfo, setOpenAgentListingModalInfo] = useState(false);
    const [toShowAgentListing, setToShowAgentListing] = useState(0);
    const [searchAgentModalVisible, setSearchAgentModalVisible] = useState(false);
    const { items: feedbacks, loading: feedbacksLoading } = useSelector(
        (state: RootStore) => state.requests.userFeedback,
    );
    const { items: agentOpenHouses, loading: agentHousesLoading } = useSelector(
        (state: RootStore) => state.listings.agentOpenHouses,
    );

    const onSearchAgentsByArea = (payload: SearchAgentByAreaPayload) => dispatch(searchAgentsByArea(payload));
    const onOpenProfileImage = (payload: ToggleUserProfileModalPayload) => {
        dispatch(toggleUserProfileModal(payload));
    };

    const onCloseSearchAgentModal = () => setSearchAgentModalVisible(false)

    const states = useSelector((state: RootStore) => state.directories.states);

    const onCloseModal = () => {
        dispatch(toggleUserProfileModal({ value: false, userId }));
        setTimeout(() => {
            dispatch(deleteProfileLeftovers(userId));
        }, 500);
    };

    const onOpenReviewer = (id: number) => {
        dispatch(toggleUserProfileModal({ value: true, userId: id }));
    };

    const getInfoInTabs = () => {
        if (tabIndex === 1) {
            dispatch(getUserFeedbacks({ IsShowAll: true, UserId: userId }));
        }
        if (tabIndex === 2) {
            dispatch(getAgentOpenHouses({ showAll: false, id: userId }));
        }
    };

    const onCreateChat = () => {
        dispatch(createChat(userId));
    };

    useEffect(() => {
        if (isOpen && userId) {
            dispatch(getUserProfileImage(userId));
            dispatch(getStates());
        }
    }, [userId, isOpen]);

    useEffect(() => {
        getInfoInTabs();
    }, [tabIndex, Object.keys(profile).length]);

    useEffect(() => {
        if (toShowAgentListing) {
            setOpenAgentListingModalInfo(true);
        }
    }, [toShowAgentListing]);

    useEffect(() => {
        if (toFeeback) {
            setTabIndex(1);
        }
    }, [toFeeback]);

    return (
        <>
            <UserProfileModal
                isOpen={isOpen}
                onClose={onCloseModal}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                feedbacks={feedbacks}
                feedbacksLoading={feedbacksLoading}
                agentOpenHouses={agentOpenHouses}
                agentHousesLoading={agentHousesLoading}
                userProfileImage={userProfileImage}
                userProfileImageLoading={userProfileImageLoading}
                setToShowAgentListing={setToShowAgentListing}
                onOpenReviewer={onOpenReviewer}
                onCreateChat={onCreateChat}
                setSearchAgentModalVisible={setSearchAgentModalVisible}
                states={states}
            />
            <AgentListingInfoModal
                listingId={toShowAgentListing}
                isOpen={openAgentListingModalInfo}
                onClose={() => {
                    setOpenAgentListingModalInfo(false);
                    setToShowAgentListing(0);
                }}
            />
            <SearchAgentByAreaModalContainer
                modalVisible={searchAgentModalVisible}
                setModalVisible={onCloseSearchAgentModal}
                onSearchAgentsByArea={onSearchAgentsByArea}
                onOpenProfileImage={onOpenProfileImage}
                userId={userId}
            />
        </>
    );
};
