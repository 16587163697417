/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { ErrorMessages } from './messages';
import { SearchBy } from '../../pages/FindAssistance/types';

const homeInspectionSchema = yup.object().shape({
    dateTime: yup
        .string()
        .test('in_the_future', ErrorMessages.PAST_DATE, function (value?: string) {
            return dayjs.utc(value ? +value : undefined).isSameOrAfter(dayjs(), 'day');
        })
        .test('required', ErrorMessages.REQUIRED, function (value?: string) {
            return this.parent.searchBy === SearchBy.Location ? true : !!value;
        }),
    fromTime: yup.string().required(ErrorMessages.REQUIRED),
    states: yup.mixed().required(ErrorMessages.REQUIRED),
    privateNote: yup.string().default(''),
    generalNote: yup.string().default(''),
    clientTypeId: yup.string().required(ErrorMessages.REQUIRED),
    isShowingScheduled: yup.string().required(ErrorMessages.REQUIRED),
    startingAddress: yup.string().required(ErrorMessages.REQUIRED),
    // longitude: yup.number().required(ErrorMessages.REQUIRED),
    // latitude: yup.number().required(ErrorMessages.REQUIRED),
    clientName: yup.string().default(''),
    clientPhone: yup.string().default(''),
    preferences: yup.string().required(ErrorMessages.REQUIRED),
    preferredAgentId: yup
        .string()
        .test('preferred-agent', ErrorMessages.REQUIRED, function (value?: string) {
            return this.parent.preferrences === '3' ? !!value : true;
        }),
    approximateDuration: yup
        .number()
        .positive(ErrorMessages.POSITIVE_VALUE)
        .required(ErrorMessages.REQUIRED)
        .typeError(ErrorMessages.REQUIRED),
});

export const homeInspectionResolver = yupResolver(homeInspectionSchema);
