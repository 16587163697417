import React from 'react';
import { colors } from '../../common/constants';

export const RightArrowIcon: React.FC = () => (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.62286 4.35347H11.5577L8.2661 1.0619C8.20834 1.00414 8.16252 0.935562 8.13126 0.860092C8.1 0.784622 8.08391 0.703733 8.08391 0.622045C8.08391 0.540357 8.1 0.459469 8.13126 0.383999C8.16252 0.308529 8.20834 0.239955 8.2661 0.182193C8.32386 0.124431 8.39244 0.0786109 8.46791 0.0473502C8.54338 0.0160895 8.62427 -1.21725e-09 8.70595 0C8.78764 1.21725e-09 8.86853 0.0160895 8.944 0.0473502C9.01947 0.0786109 9.08805 0.124431 9.14581 0.182193L13.4975 4.53503C13.5552 4.59279 13.6011 4.66137 13.6323 4.73684C13.6636 4.81231 13.6797 4.8932 13.6797 4.97489C13.6797 5.05658 13.6636 5.13747 13.6323 5.21294C13.6011 5.28841 13.5552 5.35698 13.4975 5.41474L9.14464 9.76758C9.08687 9.82534 9.0183 9.87116 8.94283 9.90242C8.86736 9.93368 8.78647 9.94977 8.70478 9.94977C8.6231 9.94977 8.54221 9.93368 8.46674 9.90242C8.39127 9.87116 8.32269 9.82534 8.26493 9.76758C8.20717 9.70982 8.16135 9.64124 8.13009 9.56577C8.09883 9.4903 8.08274 9.40941 8.08274 9.32773C8.08274 9.24604 8.09883 9.16515 8.13009 9.08968C8.16135 9.01421 8.20717 8.94564 8.26493 8.88787L11.5565 5.5963H0.62169C0.540008 5.5963 0.459125 5.58021 0.383659 5.54895C0.308195 5.5177 0.239626 5.47188 0.181868 5.41412C0.124109 5.35636 0.0782928 5.28779 0.0470343 5.21233C0.0157757 5.13686 -0.000311852 5.05598 -0.000311852 4.9743C-0.000311852 4.89262 0.0157757 4.81174 0.0470343 4.73627C0.0782928 4.66081 0.124109 4.59224 0.181868 4.53448C0.239626 4.47672 0.308195 4.4309 0.383659 4.39965C0.459125 4.36839 0.540008 4.3523 0.62169 4.3523L0.62286 4.35347Z"
            fill={colors.secondaryFontDarker}
        />
    </svg>
);
