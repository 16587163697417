import { createAction } from '@reduxjs/toolkit';
import {
    GetAgentsBySearchPayload,
    GetAgentsBySearchSuccessResponse,
    PayloadError,
} from '../../../../types';

export const getAgentsBySearch = createAction<GetAgentsBySearchPayload>('GET_AGENTS_BY_SEARCH');
export const getAgentsBySearchSuccess = createAction<GetAgentsBySearchSuccessResponse>('GET_AGENTS_BY_SEARCH_SUCCESS');
export const getAgentsBySearchError = createAction<PayloadError[]>('GET_AGENTS_BY_SEARCH_ERROR');
export const resetSearchedAgents = createAction('RESET_AGENTS_BY_SEARCH');