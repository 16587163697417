import React from 'react';
import { colors } from '../../common/constants';

export const DollarSignIcon: React.FC = () => (
    <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.5 10.5C0.5 12.1569 1.84315 13.5 3.5 13.5H7.5C9.15685 13.5 10.5 12.1569 10.5 10.5C10.5 8.84315 9.15685 7.5 7.5 7.5H3.5C1.84315 7.5 0.5 6.15685 0.5 4.5C0.5 2.84315 1.84315 1.5 3.5 1.5H7.5C9.15685 1.5 10.5 2.84315 10.5 4.5M5.5 0V1.5M5.5 15V13.5"
            stroke={colors.brand}
        />
    </svg>
);
