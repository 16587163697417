import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    Text,
    CloseButton,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';

import { useDelta } from 'react-delta';

import { Loader } from '../../../../common/components';
import { InfoTab, OHDetailsTab } from './tabs';

import {
    getOpenHouseInfo,
    deleteListingTimeSlot,
    updateListingTimeSlot,
    selectMultipleTimeSlotsRequest,
    multipleTimeSlotsErrorClear,
} from '../../../../store/actions/listings.actions';

import { fonts } from '../../../../common/utils';
import { colors } from '../../../../common/constants';

import { RootStore } from '../../../../store';

import {
    UpdateListingTimeSlotPayload,
    DeleteListingTimeSlotPayload,
    OpenHouseDetailsOpenConfig,
    TimeSlotType,
} from '../../../../common/types';
import { setConnectPaymentModalOpen } from '../../../Notifications/store/actions';

interface IOpenhouseDetailsModalProps {
    showOpenhouseInfoModal: OpenHouseDetailsOpenConfig;
    onClose: () => void;
    listingId: number;
    setShowAddTimeSlotModal: (value: boolean) => void;
    getItems: (value?: number) => void;
    createTimeSlotModalCompletedFieldsData?: (data: TimeSlotType) => void;
}

const tabs = [
    { index: 0, name: 'Info' },
    { index: 1, name: 'Open House Details' },
];

export const OpenhouseDetailsModal: React.FC<IOpenhouseDetailsModalProps> = ({
    showOpenhouseInfoModal,
    onClose,
    listingId,
    setShowAddTimeSlotModal,
    getItems,
    createTimeSlotModalCompletedFieldsData = () => {},
}) => {
    const roles = useSelector((state: RootStore) => state.auth.user.roles);

    const isAccessAllowed = roles?.some((role: string) => role === 'user');

    const dispatch = useDispatch();

    const onCloseAndClear = () => {
        onClose();
        dispatch(multipleTimeSlotsErrorClear());
    };

    const [tabIndex, setTabIndex] = useState(0);

    const onDeleteTimeSlot = (data: DeleteListingTimeSlotPayload) => {
        dispatch(deleteListingTimeSlot(data));
    };

    const onUpdateTimeSlot = (data: UpdateListingTimeSlotPayload) => {
        dispatch(updateListingTimeSlot(data));
    };

    const shouldShowAddTimeSlotModal = (value: boolean) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            return;
        }
        setShowAddTimeSlotModal(value);
    };

    const onSelectMultipleTimeSlots = (value: number[]) => {
        if (!isAccessAllowed) {
            // dispatch(setConnectPaymentModalOpen({ isOpen: true }));
            return;
        }
        dispatch(selectMultipleTimeSlotsRequest(value));
    };

    const { loading, value: openHouseInfo } = useSelector(
        (state: RootStore) => state.listings.openHouseInfo,
    );
    const { loading: deleteLoading } = useSelector(
        (state: RootStore) => state.listings.deleteTimeSlot,
    );
    const { loading: updateLoading } = useSelector(
        (state: RootStore) => state.listings.updateTimeSlot,
    );
    const { loading: selectMultipleLoading, errors: selectMultipleErrors } = useSelector(
        (state: RootStore) => state.listings.multipleTimeSlotsSelect,
    );

    const deleteLoadingDelta = useDelta(deleteLoading);
    const updateLoadingDelta = useDelta(updateLoading);

    useEffect(() => {
        if (showOpenhouseInfoModal.isOpen) {
            dispatch(getOpenHouseInfo(listingId));
        }
    }, [showOpenhouseInfoModal.isOpen]);

    useEffect(() => {
        if (deleteLoadingDelta && deleteLoadingDelta.prev) {
            if (
                deleteLoadingDelta.prev &&
                !deleteLoadingDelta.curr &&
                showOpenhouseInfoModal.isOpen
            ) {
                getItems(1);
                // dispatch(getOpenHouseInfo(listingId));
            }
        }
    }, [deleteLoadingDelta]);

    useEffect(() => {
        if (updateLoadingDelta && updateLoadingDelta.prev) {
            if (updateLoadingDelta.prev && !updateLoadingDelta.curr) {
                dispatch(getOpenHouseInfo(listingId));
            }
        }
    }, [updateLoadingDelta]);

    useEffect(() => {
        if (showOpenhouseInfoModal.isManageOpenHouse) {
            setTabIndex(1);
        }
    }, [showOpenhouseInfoModal.isManageOpenHouse]);

    useEffect(() => {
        if (!showOpenhouseInfoModal.isOpen) {
            setTabIndex(0);
        }
    }, [showOpenhouseInfoModal.isOpen]);

    return (
        <Modal
            isOpen={showOpenhouseInfoModal.isOpen}
            onClose={onCloseAndClear}
            size="5xl"
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent
                h="85vh"
                borderRadius="18px"
                bgColor={colors.modalBackground}
                alignItems="center"
                px="70px"
                pb="15px"
            >
                <ModalHeader
                    display="flex"
                    flexDirection="row"
                    w="100%"
                    px="0"
                    justifyContent="space-between"
                >
                    <Text color="brand" fontSize="22px" fontFamily={fonts.montserrat}>
                        Property Details
                    </Text>
                    <CloseButton onClick={onCloseAndClear} />
                </ModalHeader>
                <ModalBody d="flex" flexDirection="column" w="100%" p="0">
                    <>
                        {loading ? (
                            <Loader centerHeight="100%" />
                        ) : (
                            <Tabs variant="unstyled" h="100%" index={tabIndex}>
                                <TabList>
                                    {tabs.map((elem, index) => (
                                        <Tab
                                            isDisabled={
                                                index === 1 &&
                                                showOpenhouseInfoModal.isMyListings &&
                                                showOpenhouseInfoModal.listingStatusId !== 1
                                            }
                                            key={elem.index}
                                            border={
                                                tabIndex === elem.index
                                                    ? `1px solid ${colors.brand}`
                                                    : `1px solid ${colors.secondaryFontDarker}`
                                            }
                                            borderRadius="20px"
                                            minW="150px"
                                            onClick={() => {
                                                setTabIndex(elem.index);
                                                dispatch(multipleTimeSlotsErrorClear());
                                            }}
                                            bg={
                                                tabIndex === elem.index
                                                    ? colors.brand
                                                    : 'transparent'
                                            }
                                            mr="10px"
                                        >
                                            <Text
                                                fontSize="14px"
                                                color={
                                                    tabIndex === elem.index
                                                        ? 'white'
                                                        : 'secondaryFontDarker'
                                                }
                                                fontWeight={400}
                                            >
                                                {elem.name}
                                            </Text>
                                        </Tab>
                                    ))}
                                </TabList>

                                <TabPanels height="calc(100% - 40px)">
                                    <TabPanel p="0" h="100%" pt="15px">
                                        <InfoTab openHouseInfo={openHouseInfo} />
                                    </TabPanel>
                                    <TabPanel p="0" h="100%" pt="15px">
                                        <OHDetailsTab
                                            openHouseInfo={openHouseInfo}
                                            setShowAddTimeSlotModal={shouldShowAddTimeSlotModal}
                                            listingId={listingId}
                                            onDeleteTimeSlot={onDeleteTimeSlot}
                                            isMyListings={showOpenhouseInfoModal.isMyListings}
                                            onSelectMultipleTimeSlots={onSelectMultipleTimeSlots}
                                            selectMultipleLoading={selectMultipleLoading}
                                            selectMultipleErrors={selectMultipleErrors}
                                            createTimeSlotModalCompletedFieldsData={
                                                createTimeSlotModalCompletedFieldsData
                                            }
                                        />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        )}
                    </>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
