import React from 'react';
import { Flex, Box, HStack, Text, IconButton, chakra, Avatar, Center } from '@chakra-ui/react';
import {
    Location,
    Calendar,
    Clock,
    Home,
    ConsultMethodIcon,
    DollarSignIcon,
    DropdownArrowIcon,
} from '../../../../assets/icons';
import { colors } from '../../../constants';
import { IconDescription } from '../../blocks';
import { AvailableForApplicationResponse } from '../../../../pages/Calendar/types';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { getAvatarPicture } from '../../../utils';

interface IListItemProps extends AvailableForApplicationResponse {
    onPressDetails: () => void;
}

export const ListItem: React.FC<IListItemProps> = ({
    addresses,
    canBeAcceptedTill,
    city,
    createdAt,
    dateTime,
    duration,
    fromTime,
    houseType,
    id,
    ownerId,
    photo,
    postalCode,
    price,
    propertiesToShow,
    relatedUserAvatar,
    requestCategoryId,
    requestTypeId,
    requestTypeName,
    states,
    timeSlot,
    onPressDetails,
}) => {
    return (
        <Flex
            h="70px"
            bg="inputBackground"
            borderRadius="14px"
            justifyContent="space-between"
            px="25px"
            mb="10px"
        >
            <HStack align="flex-start" alignSelf="center" width="40%">
                <Box>
                    <Text color="brand" fontSize="15px" lineHeight="18px" fontWeight={600}>
                        {requestTypeName}
                    </Text>
                    <HStack>
                        <Location />
                        <Text color="brand" fontSize="12px" lineHeight="16px" fontWeight={500}>
                            {requestTypeName === 'Showing'
                                ? states?.join(',')
                                : `${states?.join('')}, ${addresses?.join('')}`}
                        </Text>
                    </HStack>
                </Box>
            </HStack>
            <Flex borderLeft={`1px solid ${colors.secondaryFont}`}>
                <IconDescription
                    icon={<Calendar />}
                    text={dayjs.utc(dateTime).format('MM/DD/YYYY')}
                />
                <IconDescription
                    icon={<Clock />}
                    text={`Fr: ${dayjs(dateTime).format('hh:mmA')}`}
                />
                <IconDescription
                    icon={
                        requestTypeName === 'Showing' ? (
                            <Home color={colors.brand} width="16" height="16" />
                        ) : (
                            <ConsultMethodIcon />
                        )
                    }
                    text={
                        requestTypeName === 'Showing'
                            ? `${propertiesToShow} ${pluralize('house', propertiesToShow)}`
                            : `${duration} ${pluralize('hour', duration)}`
                    }
                />
            </Flex>
            {relatedUserAvatar ? (
                <Center>
                    <Avatar boxSize="35px" ml="30px" src={getAvatarPicture(relatedUserAvatar)} />
                </Center>
            ) : null}
            <IconButton
                onClick={onPressDetails}
                bg="transparent"
                alignSelf="center"
                aria-label="More for job"
                ml="auto"
                icon={
                    <chakra.span transform="rotate(-90deg)">
                        <DropdownArrowIcon />
                    </chakra.span>
                }
            />
        </Flex>
    );
};
