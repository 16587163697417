import React from 'react';
import { Box, AccordionItem, AccordionButton, AccordionPanel, Text } from '@chakra-ui/react';
import { DownArrowIcon } from '../../../../../assets/icons';

export const SupportItem: React.FC<any> = ({ title, content }) => (
    <AccordionItem>
        {({ isExpanded }) => (
            <>
                <h2>
                    <AccordionButton>
                        <Box flex="1" textAlign="left" p="5px 0">
                            <Text color="brand" fontSize="16px" fontWeight={400} lineHeight="24px">
                                {title}
                            </Text>
                        </Box>
                        {isExpanded ? (
                            <Box transform={'rotate(-180deg)'}>
                                <DownArrowIcon />
                            </Box>
                        ) : (
                            <Box>
                                <DownArrowIcon />
                            </Box>
                        )}
                    </AccordionButton>
                </h2>
                <AccordionPanel
                    mb="10px"
                    border="none"
                    mt="10px"
                    p="30px"
                    background="inputBackground"
                    borderRadius="24px"
                >
                    {content}
                </AccordionPanel>
            </>
        )}
    </AccordionItem>
);
