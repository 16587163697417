import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

interface IPieChartLegendItemProps {
    number: number;
    text: string;
    color: string;
}

export const PieChartLegendItem: React.FC<IPieChartLegendItemProps> = ({ color, number, text }) => (
    <HStack mb="12px">
        <Box bg={color} h="22px" w="22px" borderRadius="5px" />
        <Text color="brand" fontSize="16px" lineHeight="24px" fontWeight="light">
            {number} {text}
        </Text>
    </HStack>
);
