import { createSlice } from '@reduxjs/toolkit';
import { CurrentSubscription, SubscriptionHistoryType } from '../types';
import { PayloadError } from './../../../../../common/types/api.types';
import {
    getCurrentSubscriptionRequest,
    getCurrentSubscriptionSuccess,
    getCurrentSubscriptionError,
    getSubscriptionsHistoryRequest,
    getSubscriptionsHistorySuccess,
    getSubscriptionsHistoryError, toggleSubscriptionsModal,
} from './actions';

type InitialState = {
    isModalOpen: boolean,
    current: {
        value: CurrentSubscription;
        loading: boolean;
        errors: PayloadError[];
    };
    history: {
        items: SubscriptionHistoryType[];
        loading: boolean;
        errors: PayloadError[];
    };
};

const initialState: InitialState = {
    isModalOpen: false,
    current: {
        value: {
            isFreeSubscriptionAvailable: false,
            isSubscribed: false,
            subscriptionType: { id: 0, name: '' },
            transactionId: '',
            subscribedTo: '',
        },
        errors: [],
        loading: false,
    },
    history: {
        items: [],
        loading: false,
        errors: [],
    },
};

const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCurrentSubscriptionRequest, state => {
                state.current.errors = [];
                state.current.loading = true;
            })
            .addCase(getCurrentSubscriptionSuccess, (state, action) => {
                state.current.value = action.payload;
                state.current.loading = false;
            })
            .addCase(getCurrentSubscriptionError, (state, action) => {
                state.current.loading = false;
                state.current.errors = action.payload;
            })
            .addCase(getSubscriptionsHistoryRequest, state => {
                state.history.errors = [];
                state.history.loading = true;
            })
            .addCase(getSubscriptionsHistorySuccess, (state, action) => {
                state.history.items = action.payload;
                state.history.loading = false;
            })
            .addCase(getSubscriptionsHistoryError, (state, action) => {
                state.history.loading = false;
                state.history.errors = action.payload;
            }).addCase(toggleSubscriptionsModal, (state, action) => {
                state.isModalOpen = action.payload
        })
    },
});

export default subscriptionsSlice.reducer;
