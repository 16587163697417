import React from 'react';
import { Box, Flex, Text, Tabs, TabPanels, TabPanel, HStack, Container } from '@chakra-ui/react';
import { TabsSwitcher } from './components';
import { JobTab, PostTab } from './tabs';
import { FindJobPostsResponseType } from './types';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { BackButton, CustomButton } from '../../common/components';

interface IOpportunitiesProps {
    tabIndex: number;
    setTabIndex: (value: number) => void;
    openCreateNewPost: () => void;
    loading: boolean;
    posts: FindJobPostsResponseType[];
    setJobId: (value: number) => void;
    setPostId: (value: number) => void;
    loadMoreItems: () => void;
    loadMoreLoading: boolean;
}

const Opportunities: React.FC<IOpportunitiesProps> = ({
    tabIndex,
    setTabIndex,
    openCreateNewPost,
    loading,
    posts,
    setJobId,
    setPostId,
    loadMoreItems,
    loadMoreLoading,
}) => (
    <Flex backgroundColor="brand" flexDirection="column" h="100vh" alignItems="center">
        <DashboardHeader />
        <Container
            maxW="container.lg"
            bgColor="white"
            h="90vh"
            mt="1.5vh"
            borderRadius="10px"
            p="25px 33px"
        >
            <HStack mb="16px">
                <BackButton mb="0" text="" />
                <Text fontSize="24px" lineHeight="25px" color="brand" fontWeight="bold" mb="22px">
                    Opportunities
                </Text>
            </HStack>
            <Tabs variant="unstyled" index={tabIndex}>
                <Flex justifyContent="space-between">
                    <TabsSwitcher
                        setTabIndex={setTabIndex}
                        tabIndex={tabIndex}
                        firstTabLabel="Agency Board"
                        secondTabLabel="My Posts"
                    />
                    <CustomButton
                        text={tabIndex === 0 ? 'Create new' : '+ New'}
                        bgColor="brand"
                        width="auto"
                        minWidth="150px"
                        height="50px"
                        borderRadius="4px"
                        fontWeight={300}
                        onClick={openCreateNewPost}
                    />
                </Flex>
                <TabPanels>
                    <TabPanel p="0" h="100%" pt="10px">
                        <JobTab
                            loading={loading}
                            posts={posts}
                            setJobId={setJobId}
                            loadMoreItems={loadMoreItems}
                            loadMoreLoading={loadMoreLoading}
                        />
                    </TabPanel>
                    <TabPanel p="0" h="100%" pt="10px">
                        <PostTab
                            loading={loading}
                            posts={posts}
                            setPostId={setPostId}
                            loadMoreItems={loadMoreItems}
                            loadMoreLoading={loadMoreLoading}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    </Flex>
);
export default Opportunities;
