import React, { useState } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { CustomInput, CustomButton } from '../../../../../common/components';
import {
    SetLocationPayload,
    AddressLocationType,
    GeoPositionShortType,
} from '../../../../../common/types';
import { EditIcon2 } from '../../../../../assets/icons';
import { useDispatch } from 'react-redux';
import { SelectLocationModal } from '../modals';
import { setLocation } from '../store/actions';
import { Alerter } from '../../../../../common/utils';
interface IManuallyLocationProps {
    data: SetLocationPayload;
    onGetCoords: (obj: GeoPositionShortType) => void;
    regionPosition: GeoPositionShortType;
    address: AddressLocationType;
    setAddress: (value: AddressLocationType) => void;
    saveLoading: boolean;
}

export const ManuallyLocation: React.FC<IManuallyLocationProps> = ({
    data,
    regionPosition,
    onGetCoords,
    setAddress,
    address,
    saveLoading,
}) => {
    const dispatch = useDispatch();

    const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);

    const onSaveManuallyLocation = () => {
        if (address.state !== '' && regionPosition.lat) {
            dispatch(
                setLocation({
                    checkBox: 2,
                    longitude: regionPosition.lng,
                    latitude: regionPosition.lat,
                    ...address,
                }),
            );
        } else {
            Alerter.warning('You need to set the location', 2000);
        }
    };

    return (
        <Box>
            <CustomInput
                label="State"
                isReadOnly={true}
                register={{ value: data.state }}
                margin="20px 0 0 0"
            />
            <CustomInput
                label="Area"
                isReadOnly={true}
                register={{ value: data.area }}
                margin="20px 0"
            />
            <Center>
                <CustomButton
                    text="Change"
                    onClick={() => setShowSelectLocationModal(true)}
                    bgColor="brand"
                    height="50px"
                    width="300px"
                    icon={<EditIcon2 color="white" />}
                />
            </Center>
            <SelectLocationModal
                isOpen={showSelectLocationModal}
                onClose={() => setShowSelectLocationModal(false)}
                onGetCoords={onGetCoords}
                onGetAddress={(val: AddressLocationType) => setAddress(val)}
                regionPosition={regionPosition}
                onSaveManuallyLocation={onSaveManuallyLocation}
                saveLoading={saveLoading}
            />
        </Box>
    );
};
