export const getPlaceInfo = async (placeId: string) => {
    const geocoder = new google.maps.Geocoder();

    const coordinates = {
        lat: 0,
        lng: 0,
    };
    await geocoder
        .geocode({
            placeId: placeId,
        })
        .then(res => {
            coordinates.lat = res.results[0].geometry.location.lat();
            coordinates.lng = res.results[0].geometry.location.lng();
        });
    return coordinates;
};
