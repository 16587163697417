import React from 'react';
import { Box, Flex, Text, Link } from '@chakra-ui/react';
import { fonts } from '../../../../common/utils';
import { colors } from '../../../../common/constants';

interface IFeatureBlockProps {
    title: string;
    description: string;
    contact?: string;
    time?: string;
    href?: string;
}

export const ContactInfoBlock: React.FC<IFeatureBlockProps> = ({
    description,
    title,
    contact,
    time,
    href,
}) => (
    <Box>
        <Text
            color={colors.brand}
            fontFamily={fonts.montserrat}
            fontSize="18px"
            lineHeight="18px"
            fontWeight="600"
            mb="10px"
            mt="20px"
        >
            {title}
        </Text>
        <Text
            opacity={0.7}
            color={colors.brand}
            fontFamily={fonts.montserrat}
            fontSize="13px"
            lineHeight="23px"
        >
            {description}
        </Text>
        <Flex justifyContent="space-between" paddingTop="15px" paddingBottom="25px">
            <Link
                color={colors.calendarBlue}
                fontFamily={fonts.montserrat}
                fontSize={{ base: '13px', lg: '16px' }}
                lineHeight="23px"
                href={href}
            >
                {contact}
            </Link>
            <Text
                opacity={0.7}
                color={colors.brand}
                fontFamily={fonts.montserrat}
                fontSize={{ base: '13px', lg: '14px' }}
                lineHeight="23px"
            >
                {time}
            </Text>
        </Flex>
        {contact && <Box borderBottom={10} borderWidth="1px" />}
    </Box>
);
