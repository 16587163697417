import React from 'react';

export const RequestsIcon: React.FC = () => (
    <svg width="35" height="35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35Z"
            fill="#867AFA"
        />
        <path
            d="M12.883 11.326v2.43M15.313 13.757h-2.43M17.5 11.326a5.56 5.56 0 0 1 5.59 5.59 5.56 5.56 0 0 1-5.59 5.59 5.56 5.56 0 0 1-5.59-5.59"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M12.883 13.757c1.02-1.459 2.673-2.43 4.618-2.43M16.744 14.757v3.403M19.66 18.16h-2.916"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
    </svg>
);
