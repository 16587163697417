import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Flex,
    Text,
    CloseButton,
} from '@chakra-ui/react';
import { CustomButton } from '../../common/components';
import { ThumbUpIcon } from '../../assets/icons';

interface ISentSuccessModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const SentSuccessModal: React.FC<ISentSuccessModalProps> = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
            <ModalOverlay />
            <ModalContent borderRadius={'18px'} alignItems="center">
                <ModalBody d="flex" flexDirection="column" p="35px 50px" w="100%">
                    <Flex justifyContent="flex-end">
                        <CloseButton onClick={onClose} />
                    </Flex>
                    <Flex direction="column" align="center">
                        <ThumbUpIcon />
                        <Text
                            color="brand"
                            fontSize="25px"
                            fontWeight={400}
                            lineHeight="26px"
                            mt="30px"
                            mb="20px"
                        >
                            Thank you!
                        </Text>
                        <Text
                            color="brand"
                            fontSize="16px"
                            fontWeight={400}
                            lineHeight="26px"
                            mb="40px"
                        >
                            Our support will contact you via email{' '}
                        </Text>
                        <CustomButton
                            text="Close"
                            height="60px"
                            bgColor="brand"
                            width="250px"
                            mb="30px"
                            onClick={onClose}
                        />
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
