import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { RightArrowIcon } from '../../../assets/icons';
import { Link } from 'react-router-dom';

interface ISettingsItem {
    destination: string;
    text: string;
}

export const SettingsItem: React.FC<ISettingsItem> = ({ text, destination }) => (
    <Flex
        py="10px"
        px="30px"
        justify="space-between"
        align="center"
        cursor="pointer"
        // onClick={onClick}
        bgColor="#F6F7FB"
        w="100%"
        h="70px"
        borderRadius="10px"
        as={Link}
        to={destination}
    >
        <Text color="brand" fontSize="16px" fontWeight="600">
            {text}
        </Text>
        <RightArrowIcon />
    </Flex>
);
