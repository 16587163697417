import React from 'react';
import { Flex, Text, Center } from '@chakra-ui/layout';
import TablePaginator from '../../../common/components/blocks/TablePaginator/TablePaginator';
import './styles.css';
import parse from 'html-react-parser';
import { notificationsKeyWordParse } from '../../../common/utils';
import { Notification } from '../../../assets/icons';
import dayjs from 'dayjs';
import { Notification as NotificationType } from '../types';

interface INotificationsList {
    items: NotificationType[];
    currentPage: number;
    setCurrentPage: (v: number) => void;
    userNotificationsLastReadTime: string;
    totalCount: number;
    handlePressNotification: (value: NotificationType) => void;
}

export const NotificationsList: React.FC<INotificationsList> = ({
    items,
    currentPage,
    setCurrentPage,
    userNotificationsLastReadTime,
    totalCount,
    handlePressNotification,
}) => {
    return (
        <>
            <Flex direction="column" w="100%" justify="flex-start" id="message">
                {items.length ? (
                    items.map((item: any, index: number) => (
                        <Flex
                            key={index}
                            bgColor="#F6F7FB"
                            as="button"
                            onClick={() => handlePressNotification(item)}
                            p="15px"
                            w="100%"
                            borderRadius="16px"
                            justify="space-between"
                            align="center"
                            mb="10px"
                        >
                            <Text fontSize="16px" fontWeight="500" color="brand">
                                {parse(notificationsKeyWordParse(item.content))}
                            </Text>
                            {userNotificationsLastReadTime !== null &&
                            dayjs(userNotificationsLastReadTime).isBefore(item.eventDate) ? (
                                <Notification width="10" height="10" />
                            ) : null}
                        </Flex>
                    ))
                ) : (
                    <Center h="100%">
                        <Text color="brand">There is no notifications yet</Text>
                    </Center>
                )}
            </Flex>
            <TablePaginator
                pageSize={9}
                currentPage={currentPage}
                membersLoading={false}
                usersTotalCount={totalCount}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
};
