import React, {Dispatch, SetStateAction} from 'react'
import {GetFundingSourcesResponse} from "../types";
import {Box, Center, Flex, Text} from "@chakra-ui/react";
import {BankIcon, TrashIcon} from "../../../../../assets/icons";
import {CustomButton} from "../../../../../common/components";

interface IFundingSourceBoxProps {
    item: GetFundingSourcesResponse
    onRemoveFundingSource: (id: string) => void
    removeFundingSourceLoading: boolean
    setFundingSourceId:Dispatch<SetStateAction<string>>
    initVerificationLoading: boolean
    onInitVerificationFundingSource: (id: string) => void
    setVerifyFundingSourceId:Dispatch<SetStateAction<string>>
    setEditBankNameId:Dispatch<SetStateAction<string>>
}

export const FundingSourceBox:React.FC<IFundingSourceBoxProps> = ({
    item,
    onRemoveFundingSource,
    removeFundingSourceLoading,
    setFundingSourceId,
    initVerificationLoading,
    onInitVerificationFundingSource,
    setVerifyFundingSourceId,
    setEditBankNameId

}) => {
    return (
        <>

            <Flex
                justifyContent={'space-between'}
                px={'24px'}
                py={'16px'}
                h={'83px'}
                bg={'#FFFFFF'}
            >
                {/*Left*/}
                <Flex align={'center'}>
                   <Center
                       mr={'17px'}
                       bg={'#EAEBEF'}
                       h={'40px'}
                       w={'40px'}
                   >
                       <BankIcon/>
                   </Center>
                    <Box>
                        <Text
                            fontSize={'16px'}
                            fontWeight={'500'}
                            lineHeight={'24px'}
                            letterSpacing={'.2px'}
                        >
                            {item.nickname}

                        </Text>
                        <Text
                            fontSize={'16px'}
                            fontWeight={'500'}
                            lineHeight={'24px'}
                            letterSpacing={'.2px'}
                        >
                            {item.bankName}
                        </Text>
                    </Box>

                </Flex>
                {/* Right */}
                <Flex flexDir={'column'} justifyContent={'space-between'}>
                    <Text
                        color={
                            item.status === 'Unverified' ?
                             '#e53e3e' :  item.status === 'Verified' ?
                             '#7BDDD3' : '#F6D778'

                        }
                        fontSize={'16px'}
                        fontWeight={'400'}
                        lineHeight={'24px'}
                        letterSpacing={'.2px'}
                    >
                        {item.status}

                    </Text>
                    {
                        item.status === 'Unverified'
                            &&
                        <CustomButton
                            onClick={() => setFundingSourceId(item.dwollaId)}
                            alignSelf={'flex-end'}
                            _hover={{
                                background: 'none'
                            }}
                            variant={'custom'}
                            textDecoration={'underline'}
                            fontWeight={'400'}
                            color={'brand'}
                            fontSize={'16px'}
                            lineHeight={'24px'}
                            text={'Edit'}
                            width={'auto'}
                        />
                    }

                </Flex>
            </Flex>
            {/*Buttons */}
            <Flex my={'23px'} justifyContent={'flex-end'}>
                <CustomButton
                    isLoading={removeFundingSourceLoading}
                    onClick={() =>  onRemoveFundingSource(item.dwollaId)}
                    _hover={{
                        background: 'none'
                    }}
                    borderRadius={'10px'}
                    border={'1px solid #D1D2DB'}
                    height={'48px'}
                    w={'auto'}
                    lineHeight={'27px'}
                    fontSize={'18px'}
                    fontWeight={'300'}
                    color={'#888DAB'}
                    leftIcon={<TrashIcon color={'#888DAB'} width={'15'} height={'14'}/>}
                    variant={'custom'}
                    text={'Delete'}/>

                {
                    (item && item.status === 'Ready For Deposit Verification')
                        ? (
                            <CustomButton
                                onClick={() =>  setVerifyFundingSourceId(item.dwollaId)}
                                ml={'34px'}
                                _hover={{
                                    background: 'none'
                                }}
                                borderRadius={'10px'}
                                border={'1px solid #D1D2DB'}
                                height={'48px'}
                                w={'auto'}
                                lineHeight={'27px'}
                                fontSize={'18px'}
                                fontWeight={'300'}
                                color={'#888DAB'}
                                variant={'custom'}
                                text={'Verify'}/>
                        )
                        : item.status === 'Verified'
                            ? (
                                <CustomButton
                                    onClick={() => {
                                        setEditBankNameId(item.dwollaId)
                                    }}
                                    ml={'34px'}
                                    _hover={{
                                        background: 'none'
                                    }}
                                    borderRadius={'10px'}
                                    border={'1px solid #D1D2DB'}
                                    height={'48px'}
                                    w={'auto'}
                                    lineHeight={'27px'}
                                    fontSize={'18px'}
                                    fontWeight={'300'}
                                    color={'#888DAB'}
                                    variant={'custom'}
                                    text={'Edit'}/>

                            )
                            : item.status === 'In Verification'
                                ? null
                                : (
                                    <CustomButton
                                        onClick={() => onInitVerificationFundingSource(item.dwollaId)}
                                        isLoading={initVerificationLoading}
                                        ml={'34px'}
                                        _hover={{
                                            background: 'none'
                                        }}
                                        borderRadius={'10px'}
                                        border={'1px solid #D1D2DB'}
                                        height={'48px'}
                                        w={'auto'}
                                        lineHeight={'27px'}
                                        fontSize={'18px'}
                                        fontWeight={'300'}
                                        color={'#888DAB'}
                                        variant={'custom'}
                                        text={'Initiate Micro-Deposits'}/>

                                )

                }

            </Flex>
        </>
    )
}
