import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Button,
    CloseButton,
    chakra,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BackArrow } from '../../../assets/icons';
import { CustomInput } from '../../../common/components';
import { colors } from '../../../common/constants';
import { ChangePasswordPayload } from '../types';
import { editPasswordResolver } from '../../../common/schemes';

interface IEditPasswordModal {
    isOpen: boolean;
    onClose: () => void;
    changePassword: (v: ChangePasswordPayload) => void;
    isFieldChanged: boolean;
    clear: () => void;
    loading: boolean;
}

export const EditPasswordModal: React.FC<IEditPasswordModal> = ({
    isOpen,
    onClose,
    changePassword,
    isFieldChanged,
    clear,
    loading,
}) => {
    const handleClose = () => {
        onClose();
        clear();
    };
    const {
        formState: { errors },
        handleSubmit,
        register,
        clearErrors,
    } = useForm<ChangePasswordPayload>({
        resolver: editPasswordResolver,
    });

    const onSubmit = (data: ChangePasswordPayload) => {
        changePassword(data);
        handleClose();
    };

    useEffect(() => {
        if (isFieldChanged && isOpen) {
            handleClose();
        }
    }, [isFieldChanged]);

    useEffect(() => {
        if (!isOpen) {
            clearErrors(undefined);
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <Box as="button" onClick={onClose}>
                        <BackArrow />
                    </Box>
                    <ModalHeader flex={1} align="center">
                        <Text color="brand" textAlign="center">
                            Edit Password
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody d="flex" flexDirection="column" overflowY="auto">
                    <chakra.form h="90%" onSubmit={handleSubmit(onSubmit)}>
                        <Flex
                            direction="column"
                            align="center"
                            mt="30px"
                            justify="space-between"
                            h="100%"
                            pb="30px"
                        >
                            <Flex direction="column" w="100%">
                                <Flex w="100%" mb="15px">
                                    <CustomInput
                                        label="Old Password"
                                        register={{ ...register('oldPassword') }}
                                        width="100%"
                                        type="password"
                                        isPassword
                                        labelColor="secondaryFontDarker"
                                    />
                                </Flex>
                                <Flex w="100%" mb="15px">
                                    <CustomInput
                                        label="Enter Password"
                                        register={{ ...register('newPassword') }}
                                        width="100%"
                                        type="password"
                                        isPassword
                                        errors={errors.newPassword}
                                        labelColor="secondaryFontDarker"
                                    />
                                </Flex>
                                <Flex w="100%" mb="15px">
                                    <CustomInput
                                        label="Repeat New Password"
                                        register={{ ...register('confirmNewPassword') }}
                                        width="100%"
                                        type="password"
                                        errors={errors.confirmNewPassword}
                                        labelColor="secondaryFontDarker"
                                        isPassword
                                    />
                                </Flex>
                            </Flex>
                            <Button
                                variant="main"
                                w="100%"
                                type="submit"
                                height="60px"
                                isLoading={loading}
                            >
                                Save
                            </Button>
                        </Flex>
                    </chakra.form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
