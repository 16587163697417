import React, { useState, useEffect } from 'react';
import {
    Box,
    Image,
    InputGroup,
    Input,
    InputRightElement,
    Text,
    Center,
    IconButton,
} from '@chakra-ui/react';
//components
import { SendIcon, AttachFileIcon, CancelIcon } from '../../../../../assets/icons';
import { IChatFooter } from '../../../types';
import { Alerter } from '../../../../../common/utils';

export const ChatFooter: React.FC<IChatFooter> = ({
    sendMessage,
    onDrop,
    setIsFilesPicked,
    isFilesPicked,
    isLargeThan900
}) => {
    const [message, setMessage] = useState<string>('');
    const [files, setFiles] = useState<File[]>([]);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = (): void => {
        if (files.length >= 4) {
            Alerter.info('You can choose a maximum of 4 files to send at one time', 2000);
        } else {
            hiddenFileInput.current && hiddenFileInput.current.click();
        }
    };

    const handleChange = (event: any): void => {
        event.target.value &&
            setFiles((prevState: File[]) => [...prevState, event.target.files[0]]);
    };
    const removeFile = (file: File): void => {
        setFiles((prevState: File[]) => [...prevState.filter((item: File) => item !== file)]);
    };

    const onSendMessage = () => {
        if (message) {
            sendMessage(message);
            setMessage('');
        }
        if (files.length !== 0) {
            files.forEach((item: File) => {
                onDrop(item);
            });
            setFiles([]);
        }
    };

    useEffect(() => {
        files.length !== 0 ? setIsFilesPicked(true) : setIsFilesPicked(false);
    }, [files]);

    return (
        <Box
            h={isFilesPicked
                ? isLargeThan900 ? '25%' : '35%'
                : '15%'}
            borderTop="1px lightgrey solid"
            d="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="0 25px"
        >
            <Box d="flex" justifyContent="space-between" alignItems="center" w="100%">
                <InputGroup bg="white" borderRadius="14px" h="65px">
                    <Input
                        placeholder="Enter message"
                        h="100%"
                        borderRadius="14px"
                        fontSize="18px"
                        color="brand"
                        value={message}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setMessage(event.target.value)
                        }
                    />
                    <InputRightElement
                        onClick={handleClick}
                        h="100%"
                        mr="5px"
                        children={<AttachFileIcon />}
                    />
                </InputGroup>
                <IconButton
                    aria-label="Send message"
                    variant="unstyled"
                    w="65px"
                    h="65px"
                    d="flex"
                    borderRadius="14px"
                    justifyContent="center"
                    alignItems="center"
                    bg="white"
                    ml="15px"
                    cursor="pointer"
                    onClick={onSendMessage}
                >
                    <SendIcon />
                </IconButton>
            </Box>
            <Box w="100%" d="flex" justifyContent="flex-start" alignItems="center" mt="10px">
                {files.map((item: any, index: number) => (
                    <Box key={index}>
                        <Box
                            position="absolute"
                            p="5px"
                            bg="#91B3FA"
                            zIndex={100}
                            borderRadius="5px"
                            cursor="pointer"
                            onClick={() => removeFile(item)}
                        >
                            <CancelIcon />
                        </Box>
                        {item.type.includes('image') ? (
                            <Image
                                src={URL.createObjectURL(item)}
                                w="120px"
                                h="120px"
                                borderRadius="14px"
                                mr="10px"
                            />
                        ) : (
                            <Center w="120px" h="120px" p="5px">
                                <Text color="brand" noOfLines={1}>
                                    {item.name}
                                </Text>
                            </Center>
                        )}
                    </Box>
                ))}
            </Box>
            <Input type="file" ref={hiddenFileInput} onChange={handleChange} display="none" />
        </Box>
    );
};
