import React from 'react';
import { colors } from '../../common/constants';

export const DropdownArrowIcon: React.FC = () => (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.8 0.200076C10.7372 0.135527 10.6619 0.084497 10.5786 0.0501049C10.4954 0.0157128 10.406 -0.0013127 10.316 7.89209e-05H0.687988C0.597944 -0.0013127 0.508565 0.0157128 0.425336 0.0501049C0.342107 0.084497 0.266784 0.135527 0.203988 0.200076C0.139429 0.262967 0.0881188 0.338151 0.0530842 0.421191C0.0180497 0.504231 0 0.593453 0 0.683581C0 0.77371 0.0180497 0.862923 0.0530842 0.945964C0.0881188 1.029 0.139429 1.10419 0.203988 1.16708L5.01799 5.98108C5.08081 6.04576 5.15597 6.09718 5.23902 6.1323C5.32207 6.16741 5.41132 6.1855 5.50149 6.1855C5.59166 6.1855 5.68091 6.16741 5.76396 6.1323C5.84701 6.09718 5.92217 6.04576 5.98499 5.98108L10.8 1.16708C10.8645 1.10419 10.9159 1.029 10.9509 0.945964C10.9859 0.862923 11.004 0.77371 11.004 0.683581C11.004 0.593453 10.9859 0.504231 10.9509 0.421191C10.9159 0.338151 10.8645 0.262967 10.8 0.200076Z"
            fill={colors.brand}
        />
    </svg>
);
