import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { SearchAgentByAreaModal } from './SearchAgentByAreaModal';
import {
    ToggleUserProfileModalPayload,
    SearchAgentByAreaPayload,
    IResponseWithCustomValue, IdNameTypes, SelectValue
} from '../../../../../../common/types';
import { getCities } from '../../../../../../store/actions/directories.actions';
import { RootStore } from '../../../../../../store';
import API from "../../../../../api/api.base";
import {clearAvailableAgents} from "../../../../../../store/actions/user.actions";

interface ISearchAgentByAreaModalContainer {
    setModalVisible: () => void;
    modalVisible: boolean;
    onSearchAgentsByArea: (payload: SearchAgentByAreaPayload) => void;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
    userId: number;
}

export const SearchAgentByAreaModalContainer: React.FC<ISearchAgentByAreaModalContainer> = ({
    setModalVisible,
    modalVisible,
    onSearchAgentsByArea,
    onOpenProfileImage,
    userId,
}) => {

    const dispatch = useDispatch()
    const {loading, items} = useSelector((state:RootStore) => state.user.availableAgents)
    const getCities = async (searchValue: string ):Promise<SelectValue[]> =>{

        try {
            const result: IResponseWithCustomValue<{
                cities: IdNameTypes[];
            }> = await API.get(`api/profile/get-cities?UserId=${userId}&City=${searchValue}`, {});
            if (result.success) {
                console.log(result.value.cities)
                return result.value.cities.map(it => ({
                    value: it.id.toString(10),
                    label: it.name
                })).slice(0,4)
            } else {
                console.log(result.errors[0].errorMessage);
                return []
            }
        } catch (err) {
            console.log(err, 'getCities');
            return []
        }
    };
    const onClearAvailableAgents = () => dispatch(clearAvailableAgents())
    return (
        <SearchAgentByAreaModal
            loading={loading}
            items={items}
            getCities={getCities}
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
            onSearchAgentsByArea={onSearchAgentsByArea}
            onOpenProfileImage={onOpenProfileImage}
            userId={userId}
            onClearAvailableAgents={onClearAvailableAgents}
        />
    );
};
