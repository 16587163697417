import React from 'react';
import { Flex, Heading, Text, Link, Button } from '@chakra-ui/react';
import { colors } from '../../constants';
import { fonts } from '../../utils';

export const PricingBanner: React.FC = () => (
    <Flex
        bg={colors.calendarBlue}
        direction="column"
        align="center"
        py="60px"
        fontFamily={fonts.montserrat}
    >
        <Heading
            fontFamily={fonts.montserrat}
            fontWeight="bold"
            fontSize={{ base: '18px', lg: '32px' }}
            lineHeight={{ base: '32px', lg: '52px' }}
            color="white"
            textAlign="center"
            maxWidth={1180}
            margin="0 auto"
        >
            Get on the path to work and life, balanced.
        </Heading>

        <Button
            fontWeight="700"
            fontSize={'16px'}
            width={{ base: '178px', lg: '209px' }}
            height={'60px'}
            backgroundColor="#FFBF00"
            color={colors.brand}
            borderRadius="25px"
            mt="15px"
            _hover={{
                textDecoration: 'none',
            }}
            as={Link}
            href="/auth/signup/createaccount"
        >
            Join
        </Button>
    </Flex>
);
