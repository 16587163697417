import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

interface ICalendarModalHeaderProps {
    startDate: string;
    borderLeftColor: string;
    bgColor: string;
    title: string;
}

export const CalendarModalHeader: React.FC<ICalendarModalHeaderProps> = ({
    bgColor,
    borderLeftColor,
    startDate,
    title,
}) => (
    <Box borderLeftColor={borderLeftColor} borderLeftWidth="8px" bgColor={bgColor} w="99%">
        <Text fontWeight={400} fontSize="20px" ml={5}>
            {title} {dayjs(startDate).format('ddd DD MMM')}
        </Text>
    </Box>
);
