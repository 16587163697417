import React from 'react';
import { Box, Text, ListItem, Image, Flex } from '@chakra-ui/react';
//components
import { IConversationsListItemProps, IConversationsListItemState } from '../../../types';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { Message } from '@twilio/conversations/lib/message';
import { axiosBase } from '../../../../../common/api/api.base';
import { getTimeLastMessage } from '../../../utils';

export class ConversationsListItem extends React.Component<
    IConversationsListItemProps,
    IConversationsListItemState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            lastMessage: {},
            unreadMessageCount: null,
            currentConversation: this.props.currentConversation,
        };
    }

    loadMessagesFor = (conversation: Conversation, userId: number) => {
        conversation
            .getMessages()
            .then((messagePaginator: any) => {
                const message = messagePaginator.items[messagePaginator.items.length - 1];
                this.setState({ lastMessage: message });

                conversation.getUnreadMessagesCount().then((res: number | null) => {
                    if (Number(message?.author) === userId) {
                        this.setState({ unreadMessageCount: 0 });
                    } else {
                        this.setState({ unreadMessageCount: res });
                    }
                });
            })
            .catch((err: any) => {
                console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
            });
    };

    componentDidMount() {
        const { conversation, userId } = this.props;
        conversation.on('messageAdded', (m: any) => {
            this.setState({
                lastMessage: m,
                unreadMessageCount:
                    Number(m.state?.author) === userId
                        ? null
                        : this.state.unreadMessageCount
                        ? this.state.unreadMessageCount + 1
                        : 1,
            });
        });
        this.loadMessagesFor(conversation, userId);
    }

    render() {
        const { userId, currentConversation, setConversation, id, conversation, avatar, name } =
            this.props;
        const messageState = this.state.lastMessage?.state;
        const lastMessageMedia = messageState?.media;
        const lastMessageAuthor = messageState?.author;
        const lastMessageText =
            Number(lastMessageAuthor) === userId
                ? `You: ${messageState?.body}`
                : messageState?.body;
        const dateCreatedObject = messageState?.timestamp;
        const lastMessageTimeString = getTimeLastMessage(dateCreatedObject);
        const isMyLastMessage = Number(lastMessageAuthor) === userId;
        const unreadMessageCountVisible = currentConversation.sid === id;

        return (
            <ListItem
                h="100px"
                borderBottom="1px lightgrey solid"
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                px="15px"
                cursor="pointer"
                bg={currentConversation.sid === id ? '#EBF2F8' : 'transparent'}
                onClick={() => {
                    this.setState({ unreadMessageCount: null });
                    setConversation(conversation);
                }}
            >
                <Flex alignItems="center" h="50px">
                    {avatar ? (
                        <Image
                            src={`${axiosBase.defaults.baseURL}/api/storage/static-images/${avatar.ItemHash}${avatar.Extension}`}
                            w="50px"
                            h="50px"
                            borderRadius="50%"
                        />
                    ) : (
                        <Box
                            d="flex"
                            justifyContent="center"
                            alignItems="center"
                            bg="green"
                            w="50px"
                            h="50px"
                            color="white"
                            borderRadius="50%"
                            fontSize="20px"
                        >
                            {name[0]}
                        </Box>
                    )}
                    <Box ml="10px" maxW="75%">
                        <Text color="brand" fontSize="16px" fontWeight="600">
                            {name}
                        </Text>
                        <Text
                            color={isMyLastMessage ? 'secondaryFontDarker' : 'brand'}
                            fontSize="16px"
                            fontWeight="400"
                        >
                            {lastMessageMedia ? 'Media file' : lastMessageText || 'No messages yet'}
                        </Text>
                    </Box>
                </Flex>
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                    h="50px"
                    w="25%"
                >
                    <Text color="secondaryFontDarker" fontSize="14px" fontWeight="400">
                        {lastMessageTimeString}
                    </Text>
                    {unreadMessageCountVisible
                        ? null
                        : !!this.state.unreadMessageCount && (
                              <Box
                                  bg="green"
                                  borderRadius="50%"
                                  w="20px"
                                  h="20px"
                                  d="flex"
                                  justifyContent="center"
                                  alignItems="center"
                              >
                                  <Text
                                      color="white"
                                      lineHeight="14px"
                                      fontSize="14px"
                                      fontWeight="400"
                                  >
                                      {this.state.unreadMessageCount}
                                  </Text>
                              </Box>
                          )}
                </Flex>
            </ListItem>
        );
    }
}
