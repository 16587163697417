import React from 'react';
import { ReviewItem } from '../components';
import { InfiniteScroll, Loader } from '../../../index';
import { Box, Text } from '@chakra-ui/react';
import { UserFeedbackType } from '../../../../../pages/Requests/types';

interface IReviewsTabProps {
    items: UserFeedbackType[];
    loading: boolean;
    onOpenReviewer: (value: number) => void;
}

export const ReviewsTab: React.FC<IReviewsTabProps> = ({ items, loading, onOpenReviewer }) => {
    return (
        <Box h="100%">
            {loading ? (
                <Loader centerHeight="100%" />
            ) : (
                <InfiniteScroll>
                    {items.map(elem => (
                        <ReviewItem
                            key={elem.id}
                            onOpenReviewer={() => onOpenReviewer(elem.agentId)}
                            {...elem}
                        />
                    ))}
                </InfiniteScroll>
            )}
        </Box>
    );
};
