export const scheduledShowing = [
    {
        id: 1,
        value: '1',
        label: 'Yes, I Have Scheduled The Showing',
    },
    {
        id: 2,
        value: '2',
        label: 'No, The Agent Will Schedule The Showing',
    },
];

export const getInspectionItems = (name: string) => [
    {
        value: `Yes, I Have Scheduled The ${name}`,
        label: `Yes, I Have Scheduled The ${name}`,
        id: 1,
    },
    {
        value: `No, the Agent Will Schedule The ${name}`,
        label: `No, the Agent Will Schedule The ${name}`,
        id: 2,
    },
];

export const yesOrNo = [
    { id: 1, value: 'Yes', label: 'Yes' },
    { id: 2, value: 'No', label: 'No' },
];

export enum TypeIds {
    Showing = 1,
    'Home Inspection',
    'Final Walk-Through',
    Other,
    Appraisal,
    'Host an open house',
    Referral,
}

export const defaultRequestTypesToFilter = [
    {
        value: TypeIds.Showing,
        label: TypeIds[TypeIds.Showing],
    },
    {
        value: TypeIds['Home Inspection'],
        label: TypeIds[TypeIds['Home Inspection']],
    },
    {
        value: TypeIds['Final Walk-Through'],
        label: TypeIds[TypeIds['Final Walk-Through']],
    },
    {
        value: TypeIds.Other,
        label: TypeIds[TypeIds.Other],
    },
    {
        value: TypeIds.Appraisal,
        label: TypeIds[TypeIds.Appraisal],
    },
];

export const requestTypesWithReferral = [
    {
        value: TypeIds.Showing,
        label: TypeIds[TypeIds.Showing],
    },
    {
        value: TypeIds['Home Inspection'],
        label: TypeIds[TypeIds['Home Inspection']],
    },
    {
        value: TypeIds['Final Walk-Through'],
        label: TypeIds[TypeIds['Final Walk-Through']],
    },
    {
        value: TypeIds.Appraisal,
        label: TypeIds[TypeIds.Appraisal],
    },
    {
        value: TypeIds.Referral,
        label: TypeIds[TypeIds.Referral],
    },
];

export const agentPreferrences = [
    { id: 1, label: 'No preference', value: '1' },
    { id: 2, label: 'Specific Agent', value: '2' },
    { id: 3, label: 'Agent I`ve worked with before', value: '3' },
];

export const sortByTypes = [
    {
        label: 'Listing Date',
        value: 1,
        id: 1,
    },
    {
        label: 'Price',
        value: 2,
        id: 2,
    },
];

export const sortByTypesDashboard = [
    {
        label: 'By Date of Posting',
        value: 2,
        id: 2,
    },
    {
        label: 'By Date of Activity',
        value: 1,
        id: 1,
    },
    {
        label: 'By Agent Name',
        value: 3,
        id: 3,
    },
];
