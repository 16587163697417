import React from 'react';
import {Text, TextProps} from '@chakra-ui/react';
import { fonts } from '../../../utils';

interface ITypographyProps extends TextProps{
    size?: any;
    family?: string;
    weight?: number;
    isUnderline?: boolean;
    color?: string;
    noOfLines?: number;
    lineHeight?: string

}

const Typography: React.FC<ITypographyProps> = ({
    size,
    family,
    weight = 400,
    isUnderline,
    noOfLines,
    children,
    color,
    lineHeight='24px',
    ...rest
}) => (
    <Text
        lineHeight={lineHeight}
        fontFamily={family || fonts.poppins}
        fontSize={size}
        fontWeight={weight}
        color={color ? color : 'brand'}
        noOfLines={noOfLines}
        as={isUnderline ? 'u' : 'samp'}
        {...rest}
    >
        {children}
    </Text>
);

export { Typography };
