import React from 'react';

interface INotification {
    width: string;
    height: string;
}

export const Notification: React.FC<INotification> = ({ width, height }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="6" cy="6" r="6" fill="#4FD1C5" />
    </svg>
);
