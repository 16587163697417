import React from 'react';
import { Box, Flex, Text, Table, Th, Thead, Tr, Tbody, Td, Center, Button } from '@chakra-ui/react';
import { RenderTableBody, renderTableHeader } from '../../../common/helpers';
import TablePaginator from '../../../common/components/blocks/TablePaginator/TablePaginator';
import dayjs from 'dayjs';
import { baseUrl } from '../../../common/api/api.base';
import { Loader } from '../../../common/components/blocks';
import {ServiceHistoryItem} from "../types";

interface IHistoryTable {
    currentPage: number;
    setCurrentPage: (v: number) => void;
    showDetailsModal: (v: number) => void;
    data: Array<ServiceHistoryItem>;
    setRequestType: (v: string) => void;
    loading: boolean;
    totalCount: number
}

export const HistoryTable: React.FC<IHistoryTable> = ({
    currentPage,
    setCurrentPage,
    data,
    showDetailsModal,
    setRequestType,
    loading,
    totalCount
}) => {
    return (
        <Flex width="100%" direction="column">
            {loading ? (
                <Loader />
            ) : (
                <>
                    {data.length ? (
                        <Table
                            colorScheme="blackAlpha" sx={{
                            borderCollapse: 'separate',
                            borderSpacing: '0 12px',
                            borderRadius: '14px'
                        }}>
                            <Thead>
                                <Tr>
                                    {renderTableHeader('secondaryFontDarker', 'Name')}
                                    {renderTableHeader('secondaryFontDarker', 'Description')}
                                    {renderTableHeader('secondaryFontDarker', 'Date')}
                                    {renderTableHeader('secondaryFontDarker', 'Total')}
                                    <Th isNumeric textTransform="capitalize">
                                        <Flex align="center" justify="flex-end">
                                            <Text
                                                fontSize="14px"
                                                color="secondaryFontDarker"
                                                fontWeight="300"
                                            >
                                                Details
                                            </Text>
                                        </Flex>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody >
                                {data.length !== 0
                                    ? data.map((item: any) => (
                                          <Tr
                                              sx={{
                                                  'td:first-child':{
                                                      borderRadius: '14px 0 0 14px'
                                                  },
                                                  'td:last-child':{
                                                      borderRadius: '0 14px 14px 0'
                                                  },
                                              }}
                                              key={item.id}
                                              bg={'#F3F4F7'}
                                              mb={'10px'}
                                          >
                                              <RenderTableBody
                                                  color="brand"
                                                  size="16px"
                                                  weight="300"
                                                  text={item.fullName}
                                                  icon={
                                                      item.avatar
                                                          ? `${baseUrl}/api/storage/static-images/${item?.avatar?.itemHash}${item?.avatar?.itemExtension}`
                                                          : item.fullName
                                                  }
                                              />
                                              <RenderTableBody
                                                  color="brand"
                                                  size="16px"
                                                  weight="600"
                                                  text={item.requestType}
                                                  isIncome={item.isIncome}
                                                  description
                                              />
                                              <RenderTableBody
                                                  color="brand"
                                                  size="16px"
                                                  weight="300"
                                                  text={dayjs(item.dateTime).format('MMM D, YYYY')}
                                              />
                                              <Td>
                                                  <Flex align={'center'}>
                                                      <Text
                                                          mr={'5px'}
                                                          fontWeight={500}
                                                          color={item.isIncome ? 'green' : '#FE805C'}
                                                          fontSize={'16px'}
                                                      >
                                                          {'$' + item.finalPrice}
                                                      </Text>
                                                      <Text
                                                          transform={`rotate(${item.isIncome? '180deg' : '0deg'})`}
                                                          fontSize={'16px'}
                                                          fontWeight={500}
                                                          color={item.isIncome ? 'green' : '#FE805C'}
                                                      >
                                                          &uarr;
                                                      </Text>

                                                  </Flex>
                                              </Td>
                                              <Td isNumeric>
                                                  <Text
                                                      as={Button}
                                                      background="transparent"
                                                      type="button"
                                                      textDecoration="underline"
                                                      fontSize="16px"
                                                      fontWeight="300"
                                                      color="brand"
                                                      onClick={() => {
                                                          showDetailsModal(item.requestId);
                                                          setRequestType(item.requestType);
                                                      }}
                                                  >
                                                      Details
                                                  </Text>
                                              </Td>
                                          </Tr>
                                      ))
                                    : null}
                            </Tbody>
                        </Table>
                    ) : (
                        <Center h="100%">
                            <Text color="brand">No service history</Text>
                        </Center>
                    )}
                </>
            )}
            <TablePaginator
                pageSize={8}
                currentPage={currentPage}
                membersLoading={loading}
                usersTotalCount={totalCount}
                setCurrentPage={setCurrentPage}
            />
        </Flex>
    );
};
