import React from 'react'
import {Unsubscribe} from "./Unsubscribe";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../store";
import {unsubscribeEmailRequest} from "./store/actions";
import {useHistory, useParams} from "react-router-dom";

const UnsubscribeContainer = () => {
    const history = useHistory()
    const {email,hash} = useParams<{email:string, hash:string}>()
    const dispatch = useDispatch()
    const {
        loading :loadingUnsubscribeEmail,
        isUnsubscribed
    }
    = useSelector((state: RootStore) => state.unsubscribeEmail)

    const onUnsubscribeEmail = () => {
        dispatch(unsubscribeEmailRequest({
            email,
            hash
        }))
    }

    return <Unsubscribe
        onUnsubscribeEmail={onUnsubscribeEmail}
        isUnsubscribed={isUnsubscribed}
        loadingUnsubscribeEmail={loadingUnsubscribeEmail}
    />
}

export default UnsubscribeContainer
