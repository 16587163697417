import React from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react'
import { Chat } from './Chat';
import { IChatContainer } from '../types';
import { useMediaQuery } from '@chakra-ui/react'


export const ChatContainer: React.FC<IChatContainer> = ({
    currentConversation,
    userId,
    conversations,
    conversationReady
}) => {
    const [isLargerThan900] = useMediaQuery('(min-height: 900px)')
    if (currentConversation.state) {
        return (
            <Chat
                conversationProxy={currentConversation}
                userId={userId}
                isLargerThan900={isLargerThan900}
            />
        );
    } else {
        if (conversations.length) {
            return (
                <Box w="100%" h="100%" d="flex" justifyContent="center" alignItems="center">
                    <Text>Select chat to show messages...</Text>
                </Box>
            )
        } else {
            if (conversationReady) {
                return (
                    <Box w="100%" h="100%" d="flex" justifyContent="center" alignItems="center">
                        <Text>No chats yet</Text>
                    </Box>
                )
            } else {
                return (
                    <Box w="100%" h="100%" d="flex" justifyContent="center" alignItems="center">
                        <Spinner color='brand' size='xl' />
                    </Box>
                )
            }

        }

    }

}