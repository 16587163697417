import React from 'react';
import { Container, Box, Heading, Text, Image, HStack, Flex, Link, Button } from '@chakra-ui/react';
import {
    LandingHeader,
    MainPageInfoBlock,
    LandingFooter,
    TrialBanner,
    MainPagePoint,
    MainPageHelpPoint,
} from '../../common/components/landing';
import HeaderBG from '../../assets/landing/new-landing-header-bg.png';
import HeaderMobileBG from '../../assets/landing/new-landing-header-bg-mobile.png';
import { ReactComponent as PlayIcon } from '../../assets/landing/play-circle.svg';
import LandingHeroPic from '../../assets/landing/landing-main-hero.png';
import { fonts } from '../../common/utils';
import { mainInfoBlocks, mainInfoPoints, mainInfoHelpPoints, colors } from '../../common/constants';
import { useDispatch } from 'react-redux';
import { toggleLandingVideoModal } from '../../store/actions/landing.actions';
import { ReactComponent as Dots } from '../../assets/landing/dots.svg';
import DownloadAppstore from '../../assets/landing/download-appstore.png';
import DownloadGoogleplay from '../../assets/landing/download-googleplay.png';

export const NewLanding: React.FC = () => {
    const dispatch = useDispatch();
    return (
        <Box>
            <LandingHeader />
            <Box
                h={{ base: '780px', sm: '700px', lg: '600px' }}
                w="100%"
                backgroundRepeat="no-repeat"
                backgroundImage={{ base: HeaderMobileBG, md: HeaderBG }}
                position="relative"
                backgroundSize="cover"
                overflowY="hidden"
                backgroundPosition={{ base: 'top', lg: 'inherit' }}
            >
                <Box
                    position="absolute"
                    background="linear-gradient(180deg, #1E223D 0%, #2C335C 100%)"
                    h="100%"
                    w="100%"
                    top="0"
                    left="0"
                    opacity={0.7}
                    zIndex={1}
                />
                <Container
                    maxW="container.xl"
                    zIndex={2}
                    position="inherit"
                    h="100%"
                >
                    <Flex
                        direction="column"
                        height="100%"
                        justify={{ base: 'flex-start', lg: 'center' }}
                        pb="70px"
                        w="100%"
                    >
                        <Heading
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize={{ base: '28px', lg: '45px' }}
                            lineHeight={{ base: '38px', lg: '62px' }}
                            fontWeight="bold"
                            w={{ base: '100%', md: '60%' }}
                            mt={{ base: '100px', lg: '0' }}
                        >
                            Connecting affiliated real estate agents for daily coverage needs and off-market listings
                        </Heading>
                        <Text
                            color="white"
                            fontFamily={fonts.montserrat}
                            fontSize="16px"
                            lineHeight="28px"
                            my="23px"
                            w={{ base: '100%', md: '60%' }}
                        >
                            Agents assisting agents with showings and more, in a legally sound and compliant way.
                        </Text>
                        <HStack spacing="25px" mb="45px">
                            <Link
                                target={'_blank'}
                                href="https://apps.apple.com/app/zootpro/id1601250757"
                            >
                                <Image w={{ base: '125px', lg: '140px' }} src={DownloadAppstore} />
                            </Link>
                            <Link
                                w={{ base: '140px', lg: '160px' }}
                                target={'_blank'}
                                href="https://play.google.com/store/apps/details?id=com.zootpro"
                            >
                                <Image src={DownloadGoogleplay} />
                            </Link>
                        </HStack>
                        <HStack as="button" onClick={() => dispatch(toggleLandingVideoModal(true))}>
                            <PlayIcon />
                            <Text
                                fontSize="16px"
                                color="white"
                                fontFamily={fonts.montserrat}
                                fontWeight="500"
                            >
                                Watch video
                            </Text>
                        </HStack>
                    </Flex>

                    <Image
                        src={LandingHeroPic}
                        position="absolute"
                        right="0"
                        bottom={{ base: '0px', lg: '-80px' }}
                        width={{ base: '240px', sm: '270px', md: '400px', lg: '40%' }}
                    />
                </Container>
            </Box>
            <Box
                bg="white"
                borderTopRadius={{ base: '30px', md: '70px' }}
                mt="-70px"
                zIndex={10}
                position="relative"
            >
                <Container maxW="container.xl" py={{ base: '50px', lg: '100px' }}>
                    <Box
                        maxWidth={835}
                        margin="0 auto"
                        marginBottom={{ base: '28px', lg: '70px' }}
                        fontFamily={fonts.montserrat}
                    >
                        <Heading
                            fontFamily={fonts.montserrat}
                            fontWeight="700"
                            fontSize={{ base: '16px', lg: '32px' }}
                            textAlign="center"
                            lineHeight={{ base: '24px', lg: '40px' }}
                        >
                            90% of real estate agents leave the business in the first five years
                        </Heading>
                        <Text
                            textAlign="center"
                            fontWeight={500}
                            mt="9px"
                            mb="9px"
                            lineHeight={{ base: '24px', lg: '26px' }}
                            fontSize="16px"
                        >
                            *Inman News
                        </Text>
                        <Text
                            textAlign="center"
                            fontWeight={500}
                            lineHeight={{ base: '24px', lg: '31px' }}
                            fontSize={{ base: '16px', lg: '20px' }}
                        >
                            Get instant fiduciary leverage to help service more clients and grow
                            your business.
                        </Text>
                    </Box>
                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        spacing="25px"
                        position="relative"
                        mb={{ base: '25px', md: '50px' }}
                    >
                        {mainInfoHelpPoints.map((elem, index) => (
                            <MainPageHelpPoint key={elem.title} index={index} {...elem} />
                        ))}
                    </Flex>
                    <Flex justifyContent="center" marginBottom={{ base: '0', md: '165px' }}>
                        <Button
                            fontFamily={fonts.montserrat}
                            fontWeight="700"
                            fontSize={{ base: '14px', md: '16px' }}
                            bg="#FFBF00"
                            width={{ base: '264px', md: '330px' }}
                            color={colors.brand}
                            height={{ base: '42px', md: '72px' }}
                            borderRadius="25px"
                            _hover={{
                                textDecoration: 'none',
                            }}
                            as={Link}
                            href="/auth/signup/createaccount"
                        >
                            Activate your free account
                        </Button>
                    </Flex>
                    {mainInfoBlocks.map((elem, index) => (
                        <MainPageInfoBlock key={elem.heading} {...elem} index={index} />
                    ))}
                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        spacing="25px"
                        position="relative"
                        mt={{ base: '86px', md: '180px' }}
                        mb={{ base: '46px', lg: '141px' }}
                    >
                        <Box
                            position="absolute"
                            top={{ base: '-15px', lg: '-45px' }}
                            left={{ base: '-15px', lg: '-50px' }}
                            bottom={{ base: '0', lg: 'auto' }}
                            zIndex={0}
                        >
                            <Dots />
                        </Box>
                        <Box
                            position="absolute"
                            bottom={{ base: '-15px', lg: '-45px' }}
                            right={{ base: 'auto', md: '-15px', lg: '-15px', xl: '-50px' }}
                            left={{ base: '-15px', md: 'auto' }}
                            zIndex={0}
                        >
                            <Dots />
                        </Box>
                        <Box
                            display={{ base: 'block', md: 'none' }}
                            position="absolute"
                            top="25%"
                            right="-15px"
                            zIndex={0}
                        >
                            <Dots />
                        </Box>
                        {mainInfoPoints.map((elem, index) => (
                            <MainPagePoint key={elem.title} index={index} {...elem} />
                        ))}
                    </Flex>
                </Container>
            </Box>
            <TrialBanner />
            <LandingFooter />
        </Box>
    );
};
