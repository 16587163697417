import { createSlice } from '@reduxjs/toolkit';
import {
    toggleUserProfileModal,
    getUserProfileImage,
    getUserProfileImageSuccess,
    getUserProfileImageError,
    deleteProfileLeftovers
} from '../actions/profile.actions';
import { ProfileImageType } from '../../common/types';

// type InitialState = {
//     userId: number;
//     isOpen: boolean;
//     loading: boolean;
//     userProfileImage: ProfileImageType;
// };

type InitialState = {
    [key: number]: {
        userId: number;
        isOpen: boolean;
        toFeeback: boolean;
        loading: boolean;
        userProfileImage: ProfileImageType;
    };
};

const initialState: InitialState = {
    // userId: 0,
    // isOpen: false,
    // loading: false,
    // userProfileImage: {
    //     notToUseLocation: false,
    //     id: 0,
    //     registrationDate: '',
    //     avatar: null,
    //     email: '',
    //     fullName: '',
    //     latitude: 0,
    //     licenses: '',
    //     longitude: 0,
    //     phone: '',
    //     pricingPerDoor: null,
    //     pricingPerHour: null,
    //     assistGiven: 0,
    //     assistHired: 0,
    //     openHousesConducted: 0,
    //     openHousesMade: 0,
    //     rating: 0,
    //     agency: '',
    //     preferredAreasOfService: [],
    // },
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(toggleUserProfileModal, (state, action) => {
                return {
                    ...state,
                    [action.payload.userId]: {
                        ...state[action.payload.userId],
                        isOpen: action.payload.value,
                        userId: action.payload?.userId || 0,
                        toFeeback: action.payload?.toFeedback || false,
                    },
                };
            })
            .addCase(getUserProfileImage, (state, action) => {
                state[action.payload].loading = true;
            })
            .addCase(getUserProfileImageSuccess, (state, action) => {
                state[action.payload.id].loading = false;
                state[action.payload.id].userProfileImage = action.payload;
            })
            .addCase(getUserProfileImageError, (state, action) => {
                state[action.payload.userId].loading = false;
            })
            .addCase(deleteProfileLeftovers, (state, action) => {
                delete state[action.payload];
                return state;
            })
    },
});

export default profileSlice.reducer;
