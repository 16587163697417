import { createSlice } from '@reduxjs/toolkit';
import { PayloadError } from '../../../common/types';
import {
    getDetailsReportError,
    getDetailsReportRequest,
    getDetailsReportSuccess, getServiceHistoryError,
    getServiceHistoryRequest,
    getServiceHistorySuccess,
} from './actions';
import {DetailReport, ServiceHistoryItem} from "../types";

export const initialState: {
    errors: PayloadError[];
    serviceHistory: {
        loading: boolean,
        income: number,
        outcome: number
        items: Array<ServiceHistoryItem>
        totalCount: number
    }
    detailsReport: {
        loading: boolean,
        item: DetailReport | null
    }
} = {
    errors: [],
    serviceHistory: {
        loading: false,
        income: 0,
        outcome: 0,
        totalCount: 0,
        items: []
    },
    detailsReport: {
        item: null,
        loading: false
    },
};

const serviceHistorySlice = createSlice({
    name: 'historySlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getServiceHistoryRequest, (state, action) => {

                state.serviceHistory.loading = true
                state.errors = []
            })
            .addCase(getServiceHistorySuccess, (state, action) => {
                state.serviceHistory = {
                    ...action.payload.result,
                    loading: false,
                    items: action.payload.result.serviceHistoryListItems
                }
            })
            .addCase(getServiceHistoryError, (state, action) => {
                state.serviceHistory.loading = false
                state.errors =  action.payload
            })
            .addCase(getDetailsReportRequest, state => {
                state.detailsReport.loading =  true
                state.errors =  []

            })
            .addCase(getDetailsReportSuccess, (state, action) => {
                state.detailsReport.loading = false;
                state.detailsReport.item = action.payload.value.report;
            }).addCase(getDetailsReportError, (state, action) => {
            state.detailsReport.loading = false;
            state.errors= action.payload

        })
    },
});

export default serviceHistorySlice.reducer;
