import React, { useState, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { AgencyActivitiesModal, MyActivitiesModal } from '../../../common/components/modals';
import { InProgressActivityModal, FeedbackModal } from '../../Activity/modals';
import { ActivityIssueModalContainer } from './../../../common/components/modals/ActivityIssueModal/ActivityIssueModalContainer';
import { SelectedActivity, ConfirmWorkPayload, AddFeedbackPayload } from '../../Activity/types';
import {
    confirmWorkRequest,
    addFeedbackRequest,
    setDefaultRequest,
} from '../../Activity/store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../store';
import { ToggleUserProfileModalPayload } from '../../../common/types';
import { toggleUserProfileModal } from '../../../store/actions/profile.actions';
import { createChat } from '../../../store/actions/messages.actions';

interface IActivitiesModalsContainterProps {
    isMyActivitiesModalOpen: boolean;
    onCloseMyActivitiesModal: () => void;
    isAgencyActivitiesOpen: boolean;
    onCloseAgencyActivities: () => void;
    startEndConfig: { start: string; end: string };
}

export const ActivitiesModalsContainter: React.FC<IActivitiesModalsContainterProps> = ({
    isAgencyActivitiesOpen,
    isMyActivitiesModalOpen,
    onCloseAgencyActivities,
    onCloseMyActivitiesModal,
    startEndConfig,
}) => {
    const dispatch = useDispatch();
    const [showInProgressActivityModal, setShowInProgressActivityModal] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState<SelectedActivity>({
        id: 0,
        isMyActivity: undefined,
    });
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const { isOpen: isOpenIssue, onClose: onCloseIssue, onOpen: onOpenIssue } = useDisclosure();

    const { request: requestById, loading: requstByIdLoading } = useSelector(
        (state: RootStore) => state.activity.requestById,
    );
    const requestAddresses = useSelector(
        (state: RootStore) => state.activity.requestAddresses.addresses,
    );
    const actualDuration = useSelector(
        (state: RootStore) => state.requests.requestInfo.value.actualDuration,
    );

    const confirmWork = (payload: ConfirmWorkPayload) => dispatch(confirmWorkRequest(payload));
    const addFeedback = (payload: AddFeedbackPayload) => dispatch(addFeedbackRequest(payload));

    const onOpenProfileImage = (payload: ToggleUserProfileModalPayload) => {
        dispatch(toggleUserProfileModal(payload));
    };

    const onCreateChat = (id: number) => {
        dispatch(createChat(id));
    };

    const onSetDefaultRequest = () => dispatch(setDefaultRequest());

    useEffect(() => {
        if (selectedActivityId.id !== 0) {
            setShowInProgressActivityModal(true);
        }
    }, [selectedActivityId]);

    return (
        <>
            <MyActivitiesModal
                isOpen={isMyActivitiesModalOpen}
                onClose={onCloseMyActivitiesModal}
                startEndConfig={startEndConfig}
                setSelectedActivityId={setSelectedActivityId}
            />
            <AgencyActivitiesModal
                isOpen={isAgencyActivitiesOpen}
                onClose={onCloseAgencyActivities}
                startEndConfig={startEndConfig}
                setSelectedActivityId={setSelectedActivityId}
            />
            <InProgressActivityModal
                isOpen={showInProgressActivityModal}
                onClose={() => {
                    setShowInProgressActivityModal(false);
                    setSelectedActivityId({ id: 0, isMyActivity: undefined });
                    onSetDefaultRequest();
                }}
                activity={requestById}
                requestAddresses={requestAddresses}
                actualDuration={actualDuration}
                isOpenIssue={isOpenIssue}
                onCloseIssue={onCloseIssue}
                onOpenIssue={onOpenIssue}
                setShowAddFeedback={setShowFeedbackModal}
                // fetchItems={getItems}
                fetchItems={() => console.log('fetch items')}
                selectedActivityId={selectedActivityId}
                requestByIdLoading={requstByIdLoading}
                onOpenProfileImage={onOpenProfileImage}
                onCreateChat={onCreateChat}
            />
            <ActivityIssueModalContainer
                isOpen={isOpenIssue}
                onClose={onCloseIssue}
                activity={requestById}
            />
            <FeedbackModal
                isOpen={showFeedbackModal}
                onClose={() => setShowFeedbackModal(false)}
                assistRequestId={requestById}
                addFeedback={addFeedback}
            />
        </>
    );
};
