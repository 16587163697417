import React from 'react';
import { colors } from '../../common/constants';

export const BookMark: React.FC<{ colorFill?: string }> = ({ colorFill = 'none' }) => (
    <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill={colorFill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.7 1H10.3C10.8835 1 11.4431 1.21071 11.8556 1.58579C12.2682 1.96086 12.5 2.46957 12.5 3V17L7 14L1.5 17V3C1.5 2.46957 1.73178 1.96086 2.14436 1.58579C2.55695 1.21071 3.11652 1 3.7 1Z"
            stroke={colors.brand}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
