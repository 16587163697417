import React from 'react';
import { colors } from '../../common/constants';

export const Filter2Icon: React.FC = () => (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 13H1" stroke={colors.brand} strokeLinecap="round" />
        <path d="M19 7H8" stroke={colors.brand} strokeLinecap="round" />
        <path d="M19 13H1" stroke={colors.brand} strokeLinecap="round" />
        <path d="M19 1H10" stroke={colors.brand} strokeLinecap="round" />
    </svg>
);
