import { createSlice } from '@reduxjs/toolkit';
import { PayloadError } from '../../../common/types';
import { Notification } from '../types';
import {
    getNotificationsRequest,
    getNotificationsSuccess,
    getNotificationsError,
    getUnreadNotificationsSuccess,
    setNotificationsAvailableRequestModal,
    setConnectPaymentModalOpen,
    setNotificationsMyRequestsModal,
} from './actions';

type InitialState = {
    loading: boolean;
    errors: PayloadError[];
    items: Notification[];
    totalCount: number;
    isUnreadExists: number | boolean;
    userNotificationsLastReadTime: string;
    redirects: {
        showAvailableRequestModal: boolean;
        isConnectPaymentModalOpen: boolean;
        showMyRequestModal: boolean;
        myRequestToShowId: number;
        toApplicants: boolean;
    };
};

export const initialState: InitialState = {
    loading: false,
    errors: [],
    items: [],
    isUnreadExists: false,
    totalCount: 0,
    userNotificationsLastReadTime: '',
    redirects: {
        myRequestToShowId: 0,
        isConnectPaymentModalOpen: false,
        showMyRequestModal: false,
        showAvailableRequestModal: false,
        toApplicants: false,
    },
};

const notificationsSlice = createSlice({
    name: 'notificationsSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getNotificationsRequest, state => {
                state.loading = true;
                state.errors = [];
            })
            .addCase(getNotificationsSuccess, (state, action) => {
                state.loading = false;
                state.isUnreadExists = action.payload.isUnreadExists;
                state.items = action.payload.notifications;
                state.totalCount = action.payload.totalCount;
                state.userNotificationsLastReadTime = action.payload.userNotificationsLastReadTime;
            })
            .addCase(getNotificationsError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            .addCase(getUnreadNotificationsSuccess, (state, action) => {
                state.isUnreadExists = action.payload;
            })
            .addCase(setNotificationsAvailableRequestModal, (state, action) => {
                state.redirects.showAvailableRequestModal = action.payload.isOpen;
            })
            .addCase(setConnectPaymentModalOpen, (state, action) => {
                state.redirects.isConnectPaymentModalOpen = action.payload.isOpen;
            })
            .addCase(setNotificationsMyRequestsModal, (state, action) => {
                state.redirects.toApplicants = action.payload.toApplicants;
                state.redirects.showMyRequestModal = action.payload.isOpen;
                state.redirects.myRequestToShowId = action.payload.id;
            });
    },
});

export default notificationsSlice.reducer;
