import React from 'react';
import { Image, Box } from '@chakra-ui/react';
import MainInfoNewAgentsPic from '../../assets/landing/main_new_real_estate_agents.png';
import MainInfoNewHomeProvidersPic from '../../assets/landing/main_transaction_vendors.png';
import MainInfoBrokerageToolPic from '../../assets/landing/main_productivity_tool.png';
import MainInfoEstablishedAgentsPic from '../../assets/landing/main_established_real_estate_agents.png';
import HowItWorsAgentProfile from '../../assets/landing/how-it-works-agent-profile-image-block.png';
import HowItWorksListingAndOpenHouses from '../../assets/landing/how-it-works-listings-and-open-houses.png';
import HowItWorksHistory from '../../assets/landing/how-it-works-history.png';
import HowItWorksFindShowing from '../../assets/landing/how-it-works-find-showing-assistance.png';
import HowItWorksOurUniqueRole from '../../assets/landing/how-it-works-our-unique-role.png';

import edge1Pic from '../../assets/landing/edge-1.png';
import edge2Pic from '../../assets/landing/edge-2.png';
import edge3Pic from '../../assets/landing/edge-3.png';
import edge4Pic from '../../assets/landing/edge-4.png';
import edge5Pic from '../../assets/landing/edge-5.png';
import edge6Pic from '../../assets/landing/edge-6.png';

import why1Pic from '../../assets/landing/why-1.png';
import why2Pic from '../../assets/landing/why-2.png';

import ourStory1Pic from '../../assets/landing/our-story-1.svg';
import ourStory2Pic from '../../assets/landing/our-story-2.svg';
import ourStory3Pic from '../../assets/landing/our-story-3.svg';
import ourStory4Pic from '../../assets/landing/our-story-4.svg';
import ourStory5Pic from '../../assets/landing/our-story-5.svg';

import { ReactComponent as FeatureAppraisalsIcon } from '../../assets/landing/f_appraisals.svg';
import { ReactComponent as FeatureHomeInspectionsIcon } from '../../assets/landing/f_home-inspections.svg';
import { ReactComponent as FeatureLocalServicesIcon } from '../../assets/landing/f_local-services.svg';
import { ReactComponent as FeatureOpenHousesIcon } from '../../assets/landing/f_open-houses.svg';
import { ReactComponent as FeaturePaymentHistoryIcon } from '../../assets/landing/f_payment-history.svg';
import { ReactComponent as FeatureShowingsIcon } from '../../assets/landing/f_showings.svg';
import { ReactComponent as FeatureWalkthroughsIcon } from '../../assets/landing/f_walkthroughs.svg';
import { ReactComponent as FeatureListingsIcon } from '../../assets/landing/f_listings.svg';
import { ReactComponent as InstagramIcon } from '../../assets/landing/ic_instagram.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/landing/ic_youtube.svg';
import { ReactComponent as FacebookIcon } from '../../assets/landing/ic_facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/landing/ic_linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assets/landing/ic_twitter.svg';
import { ReactComponent as InstagramDarkIcon } from '../../assets/landing/ic_Instagram_dark.svg';
import { ReactComponent as YoutubeDarkIcon } from '../../assets/landing/ic_youtube_dark.svg';
import { ReactComponent as FacebookDarkIcon } from '../../assets/landing/ic_facebook_dark.svg';
import { ReactComponent as LinkedinDarkIcon } from '../../assets/landing/ic_linkedin_dark.svg';
import { ReactComponent as TwitterDarkIcon } from '../../assets/landing/ic_twitter_dark.svg';

import { ReactComponent as PaymentsIcon } from '../../assets/landing/payments.svg';
import { ReactComponent as ReduceWorkload } from '../../assets/landing/reduce-workload.svg';
import { ReactComponent as BuildYourBusiness } from '../../assets/landing/build-your-business.svg';
import { ReactComponent as ExpandYourNetwork } from '../../assets/landing/expand-your-network.svg';
import { ReactComponent as ContractsIcon } from '../../assets/landing/contracts.svg';
import { ReactComponent as SoftwareIcon } from '../../assets/landing/software.svg';

export const mainInfoBlocks: {
    image: string;
    heading: string;
    paragraph: string;
    position: 'left' | 'right';
}[] = [
    {
        image: MainInfoNewAgentsPic,
        heading: 'New Agents Get A Foot In The Door',
        paragraph:
            'Assisting with licensed real estate activities give new real estate agents a much-needed boost. New agents can get a sound footing in the real estate business by assisting established agents. Begin to align your expectations, build your market presence through the multitude of interactions and interfaces with the public, and earn extra income. The ability to manage your time and find a sense of community is highly improved through this networking and schedule-driven approach to outsourcing licensed activities.',
        position: 'left',
    },
    {
        image: MainInfoEstablishedAgentsPic,
        heading: 'Seasoned Agents Regain Time For ‘The 20%’',
        paragraph:
            'The work-life balance of established real estate agents can improve significantly. ZootPro makes it easy for established agents to get help for the 80% of activities in their business that do not generate any revenues, without the burdens of managing an employee. Our proprietary technology vets agents for affiliation and makes it difficult for an agent to violate the broker-agent agreement in the course of outsourcing a sales activity. Find other agents to attend to activities like showings and open houses so you can focus on the 20% of activities that generate revenues. It really is up to the imagination what you can do with all the time saved.',
        position: 'right',
    },
    {
        image: MainInfoBrokerageToolPic,
        heading: 'Productivity And Agent Retention Benefits To Brokers',
        paragraph:
            'A strong community of agents is essential to improve engagement, retention, and bottom line revenues.  Brokerages are promised a 50% increase in real estate sales activities which translates to increased business not only for the agents, but also bottom-line revenues for the broker. The ability for top producing agents to host multiple open houses at the same time by leveraging the time and skills of other licensed and affiliated agents makes perfect sense. Get your brokerage set up to harness the tools we provide.',
        position: 'left',
    },
    {
        image: MainInfoNewHomeProvidersPic,
        heading: 'Reliable Third-Party Vendors And Partners',
        paragraph:
            'Vetted third party vendors work wonders in the fast moving real estate business.  Endless searching for vendors are a thing of the past when they can be retained painlessly, and have worked with a real estate agent previously, have been referred by a real estate agent, or are part of a real estate broker’s vendor network. Your new favorite secret weapon to run a successful real estate sales business effectively and efficiently for both sellers and buyers is ZootPro.',
        position: 'right',
    },
];

export const mainInfoPoints: {
    icon: JSX.Element;
    title: string;
    description: string;
}[] = [
    {
        icon: <PaymentsIcon />,
        title: 'Agent-to-Agent payments.',
        description:
            'Get paid directly for the asistance you provide to other agents for real estate tasks, on the same platform. The benefits for targeted savings and agency collaboration is unmatched.',
    },
    {
        icon: <ContractsIcon />,
        title: 'Legally sound and compliant.',
        description:
            'Showings, home inspections, open houses, and more!  Search, find and hire affilliated agents who can assist, without worrying about violating the broker agreement or association and Realtor rules.',
    },
    {
        icon: <SoftwareIcon />,
        title: 'Powerful, innovative software.',
        description:
            'The first outsourcing software of its kind for real estate agents, this software service is comprehensive and packed with features that are easy to use on all your devices.',
    },
];

export const mainInfoHelpPoints: {
    icon: JSX.Element;
    title: string;
    description: string;
}[] = [
    {
        icon: <ReduceWorkload />,
        title: 'Reduce workload, gain experience, and boost your bottom line',
        description:
            'Leverage the time and skills of the agents in your brokerage for showings, stand-ins, and other licensed real estate activities, giving you and your clients a powerful leg up in the marketplace.',
    },
    {
        icon: <BuildYourBusiness />,
        title: 'Build your business across multiple states the smart way',
        description:
            'Expand your business in every state that you are licensed with little stress, using legally sound and compliant tools that make sure you never violate your broker or association rules.',
    },
    {
        icon: <ExpandYourNetwork />,
        title: 'Expand your network to benefit your clients and business',
        description:
            'Connect and network with agents in your brokerage and market for opportunities that otherwise might not present themselves, and for a stronger sense of community and belonging.',
    },
];

export const pricingCommonQuestionPoints: {
    title: string;
    description: string;
}[] = [
    {
        title: 'Who sets the rates for services?',
        description:
            'Agents set their own rates on the platform. We believe that in this manner, the market so-to-speak will adjust and hit the right balance. At this time there is no consensus for how these activities should be priced. We hbelieve that agents can set their preferred rates based on the nature of the work involved in real estate transactions.',
    },
    {
        title: 'How do I pay for a service?',
        description:
            'As part of the sign up process, users will also be required to create a payment account with a payment service provider sourced and selected at the sole discretion of ZootPro. Once agent payments and bank accounts are verified, agents can begin to use the platform fully. After a task is completed, it is the responsibility of the service provider to complete the task within the system for the payment process to begin. The hiring agent is responsible to make payment for the completed activity to the agent hired.',
    },
    {
        title: 'Can I pay an agent in commissions?',
        description:
            'Yes, you can compensate other agents with commission payments as an added incentive. This essential feature goes to the core of the nature of the real estate industry. Agents earn commission for properties they help homeowners buy or sell, so it only makes sense that agents should have the ability to choose to provide commission-based incentives in their businesses. With ZootPro, commission payments promised on the platform are communicated to the managing broker for ultimate disposition along with the underlying terms of the original activity.',
    },
    {
        title: 'Are there any hidden fees?',
        description:
            'There are no hidden fees. Choose one subscription tier and begin to grow your business the smart way, the ZootPro way.',
    },
];

export const featurePoints: {
    icon: JSX.Element;
    title: string;
    description: string;
}[] = [
    {
        icon: <FeatureShowingsIcon />,
        title: 'Showings',
        description:
            'Showings are an important aspect of any real estate business. Taking your client to view a home gives them an opportunity to picture themselves in the home. In situations where you are unable to make a showing or an emergency comes up, you can trust that with ZootPro, your client needs will not go unmet. Search and find an affiliated agent to fill in for you. Provide a few details for the showing and let the agent take your client around.',
    },
    {
        icon: <FeatureHomeInspectionsIcon />,
        title: 'Home Inspections ',
        description:
            'While home inspections can give clients peace of mind, it is not essential that you have to be in attendance. A home inspection is one of those real estate sales activities that do not generate any sales revenue for you or for your brokerage. For a small fee, you can hire an affiliated agent to stand in for you. Find an agent within the search radius or, plan ahead and post the home inspection opportunity to agents that would be interested. ',
    },
    {
        icon: <FeatureAppraisalsIcon />,
        title: 'Appraisals',
        description:
            'The appraisal is the process of developing an opinion of value of a property that is typically conducted by a state licensed property appraiser. This activity typically falls in the wheel of a listing agent to justify that the property’s list price is justified. It is very easy to prepare an affiliated agent to stand in for you at an appraisal using ZootPro. Once you’ve completed the search process and hired the agent, simply communicate your comparable sales and other helpful information to assist in the appraisal.',
    },
    {
        icon: <FeatureWalkthroughsIcon />,
        title: 'Walkthroughs',
        description:
            'A walkthrough, also called a final walkthrough is the last opportunity before closing that a new homeowner has to take a physical tour of a property, check to make sure that all promised repairs and changes from the inspection repair requests are made, and that there are no material changes to the property since the contract was ratified. It is usually conducted on the day of or shortly before the day of closing. This is one activity that can be outsourced to an affiliated agent using ZootPro.',
    },
    {
        icon: <FeatureOpenHousesIcon />,
        title: 'Open Houses',
        description:
            'An Open House is one of the most effective tools in a listing agent’s toolbox to sell a home. For sellers, open houses offer a powerful way to create demand and interest in a property. Typically scheduled at a set date and time, aspiring homeowners are invited to walk through the property on their own or be guided by an agent to see if a property is one that they could live in and would like to make an offer on. Agents who are spread thin can get help with hosting open houses for their listings using the “Create an Open House” function. This allows for increased activity on a listing, which in turn can drum up additional interest in a property that is listed for sale.',
    },
    {
        icon: <FeaturePaymentHistoryIcon />,
        title: 'Payment History & Reviews',
        description:
            'Agents on the ZootPro platform, both on the hiring side and the service provision side can track all activity conducted, including dates and times of service, properties serviced, clients assisted, payments made, and feedback given and received.  This information is important to ensuring agents continue to perform at a high level and that the integrity of the service delivered remains at a level most suitable to all the agents within an agency.  The real estate business can be easy using ZootPro.',
    },
    {
        icon: <FeatureListingsIcon />,
        title: 'Agent & Agency Listings',
        description:
            'Listings refer to the presence of a listing agreement between a homeowner and an agency to sell a property on the public marketplace. Through the broker-agent agreement, agents act as the sole agent of the broker in presenting a property to the market for sale. There are various kinds of listings, open, exclusive and more. Users of ZootPro can not only view their own personal listings, but the listings of other affiliated agents within an agency. This benefit allows for more improved intra-agency deal making and even marketing, knowledge sharing and productivity management. ',
    },
    {
        icon: <FeatureLocalServicesIcon />,
        title: 'Local Services',
        description:
            'Brokerage vendor programs are in great hands with ZootPro. Provide your agents easy access to approved and vetted vendors to assist with a real estate transaction. Finding transaction-vetted providers is no longer a pain as they are the fingertip of every ZootPro user. Agents can choose between broker vendors or other highly recommended vendors who have worked with and been recommended by other agents for the level of service and quality of work that they deliver. This feature is a no-brainer addition to any real estate sales business.',
    },
];

export const howItWorksPoints: {
    title: string;
    image: string;
    firstParagraphDesc: string;
    secondParagraphDesc: string;
    isReverse: boolean;
}[] = [
    {
        image: HowItWorsAgentProfile,
        title: 'Agent Profiles',
        isReverse: false,
        firstParagraphDesc:
            'Research on an agent prior to hiring can be important to a real estate agent. Or, simply, understanding an agent’s professional picture can help to build more community and a sense of belonging.',
        secondParagraphDesc:
            'Agent profiles allow you to view an agent, view their activities for the past 12 months, and find contact information and more. To communicate, simply try to chat in the application or call or email. On the profile you can also find details for an agent such as current listings and even team information.',
    },
    {
        image: HowItWorksHistory,
        title: 'History & Earnings',
        isReverse: true,
        firstParagraphDesc:
            'Creating a payment account is one of the most important steps to creating a ZootPro account which allows an agent to allocate an account for sending and for receiving payments.',
        secondParagraphDesc:
            'With transaction history, you have access to a snapshot of payments made and services rendered along with details for each entry and to review an agent. The review system allows for authentic sounding board and advice that can be useful for building a successful real estate business.',
    },
    {
        image: HowItWorksFindShowing,
        title: 'Assistance',
        isReverse: false,
        firstParagraphDesc:
            'To schedule a new showing using the ZootPro platform is very easy and straightforward.  Simply input specific details for the requests such as date and time and more. Do not worry if the complete details for a showing have yet to be finalized, in the fast moving world of real estate, this is to be expected.',
        secondParagraphDesc:
            'This is why the network of agents ready, able, and willing to help are prepared to assist with scheduling showings and more to serve your clients in the same way as you would. Depending on the size of your agency, hundreds or thousands of affiliated agents can pick up for you when other pressing matters call.',
    },
    {
        image: HowItWorksListingAndOpenHouses,
        title: 'Open Houses',
        isReverse: true,
        firstParagraphDesc:
            'With busy listing agents, juggling multiple sellers, terms, and offers can be difficult without the right technology tools to make things easy. This is why listing agents trust and use the ZootPro platform to outsource listings for open house hosting opportunities. On a Saturday when you could try to plan your entire day around open houses for three or more listings, simply make your open houses available to be hosted by affiliated agents.',
        secondParagraphDesc:
            'It is easy to communicate and share requirements with hosting agents with regards to ownership of leads, lead handling and hosting plans with the chat function to ensure that your standards are met.',
    },
    {
        image: HowItWorksOurUniqueRole,
        title: 'Our Unique Role',
        isReverse: false,
        firstParagraphDesc:
            'ZootPro is not a Brokerage.  We do not hire agents or have a license to act as professional real estate salespersons or brokers. Unfortunately, many online services that promise to provide showing assistants operate outside the bounds of the law. They act as brokers by hiring agents and receiving payments for the services that these agents provide.',
        secondParagraphDesc:
            'Brokers and agents are rest assured that ZootPro acts solely as a software provider. We never collect payments for agent activities in the market and we maintain strong relationships with brokers in order to help preserve the broker-agent relationships within the market as intended by the law.',
    },
];

export const socials = [
    {
        icon: <InstagramIcon />,
        link: 'http://instagram.com/zootproapp',
    },
    {
        icon: <TwitterIcon />,
        link: 'http://twitter.com/zootproapp',
    },
    {
        icon: <LinkedinIcon />,
        link: 'http://linkedin.com/company/zootpro',
    },
    {
        icon: <FacebookIcon />,
        link: 'http://facebook.com/zootpro',
    },
];

export const pricingFeaturnes: string[] = [
    'Showing Assistance',
    'Home Inspection Assistance',
    'Listings Management',
    'Open House',
    'Agency Networking',
];
export const socialsDark = [
    {
        icon: <InstagramDarkIcon />,
        link: 'http://instagram.com/zootproapp',
    },
    {
        icon: <TwitterDarkIcon />,
        link: 'http://twitter.com/zootproapp',
    },
    {
        icon: <LinkedinDarkIcon />,
        link: 'http://linkedin.com/company/zootpro',
    },
    {
        icon: <FacebookDarkIcon />,
        link: 'http://facebook.com/zootpro',
    },
];

export const contactPoints: {
    title: string;
    description: string;
    contact?: string;
    href?: string;
    time?: string;
}[] = [
    {
        title: 'Call Us',
        description:
            'Have questions? Speak to real person. A member of our team is ready to take your call.',
        contact: '703.278.3347',
        href: 'tel:17032783347',
        time: 'Mon - Fri 10:00am to 4:00pm EST',
    },
    {
        title: 'Email Us',
        description: `Send us an email and we'll be sure to respond right away. We check for messages and emails frequently so expect to hear back quickly.`,
        contact: 'info@zootpro.com',
        href: 'mailto:info@zootpro.com',
        time: '24/7. Every day of the year.',
    },
    {
        title: 'Frequently Asked Questions',
        description: `Visit our helpcenter which has answers to the most commonly asked questions regarding real estate outsourcing questions.`,
        contact: 'Visit Help Center',
        href: '/help',
        time: '24/7. Every day of the year.',
    },
    {
        title: 'Get Social With Us',
        description:
            'We love being social and love to connect with real estate agents and other industry professionals on our networks. Take a minute to look us up!',
    },
];

export const edgeBlocks: {
    position: string;
    image: string;
    title: string;
    text: string;
    description?: string;
}[] = [
    {
        position: 'right',
        image: edge1Pic,
        title: 'First Legally-Sound Technology Solution',
        text: `ZootPro is the first provider to develop a comprehensive task outsourcing solution for the real estate industry. The ZootPro platform allows agents from multiple brokerages to find and outsource licensed real estate activities without breaking their affiliations, Realtor rules or the Broker-Agent agreement. `,
        description: `We have developed a piece of technology that takes into consideration every facet of real estate sales activities and that vets agents for affiliations in order to maintain and preserve the unique organizational needs of the industry, yet also encouraging smaller brokers to get creative in ways in which to grow their businesses through inter-broker cooperation arrangements.`,
    },
    {
        position: 'left',
        image: edge2Pic,
        title: 'Our Expertise Is To Your Advantage',
        text: `Developed by legal and compliance professionals with years of experience in the industry, the expertise packed into the ZootPro solution is unparalleled. With first-hand experience on the ways in which agent businesses could be highly improved, a sound outsourcing model is one that needed just the right touch to become a reality. Today, agents no longer have to call a friend to assist with real estate tasks where such assistance would imply that both agents are conducting licensed real estate activities in violation of the agreements with their assigned broker. `,
        description: `Where an agent asks an agent at a different broker to show a property while he was out of town before dotting the “I”s and crossing the “T”s on the Buyer-Agency contract. If the client decides he wants to use the showing agent, this presents a tenuous situation for which broker-agent agreements seek to pre-empt as two separate and competing brokers have to find a resolution. Such scenarios are a thing of the past with ZootPro.`,
    },
    {
        position: 'right',
        image: edge3Pic,
        title: 'Best In Class Innovative Platform',
        text: `With ZootPro, there is a distinct attention to detail that is noticed by every new agent using the platform. We’ve spent countless hours and days honing and perfecting the platform to cover every contingency that an agent might face in the course of outsourcing a real estate task. If a property was unavailable to be showed, we have made provisions for that. Did a home inspection go too long? We’ve made provisions for that. `,
        description: `We’ve taken every care to cover every little detail so that agents can focus on the things that matter the most, building and running a successful real estate business. Our help center and feedback loop is always open and available, and we are always listening to learn what new needs agents might have so that we can continue to deliver on top notch features and tools.`,
    },
    {
        position: 'left',
        image: edge4Pic,
        title: 'We champion Real Estate Agents',
        text: `The real estate industry gets a bad rap. A simple Google search will return numerous articles with topics highlighting words like “shady,” “bad,” or other negative connotations about real estate agents or the real estate industry. At ZootPro, we believe that agents are the #1 ingredient to a real estate transaction and provide a very valuable and highly regarded service to our society.  To this end, we have undertaken to assist with unburdening hardworking agents of the negative connotations attributed to the profession by creating a scaleable platform on which Agents can outsource transactions to other agents in an honest, transparent, legally sound and compliant manner.  `,
        description: `We hope to send a message that the industry holds itself to very high standards regarding its ethics and professional duties and practices. Realtor Associations are also encouraged by this development as they champion the cause of agents dedicated to honoring their commitments not only to the broker but also their commitment to the profession through upholding the standards that the associations proffer.`,
    },
    {
        position: 'right',
        image: edge5Pic,
        title: 'Our Unique Role',
        text: `ZootPro is not a Brokerage.  We do not hire agents or have a license to act as professional real estate salespersons or brokers. Unfortunately, many online services that promise to provide showing assistants operate outside the bounds of the law. They act as brokers by hiring agents and receiving payments for the services that these agents provide.  `,
        description: `Brokers and agents are rest assured that ZootPro acts solely as a software provider. We never collect payments for agent activities in the market and we maintain strong relationships with brokers in order to help preserve the broker-agent relationships within the market as intended by the law.`,
    },
    {
        position: 'left',
        image: edge6Pic,
        title: 'Trustworthy Model Of Outsourcing',
        text: `If you’ve ever had a last minute client need that you cannot service, you may have come across services that advertise assistants. You pay these services and hope they send someone to fix this issue. Agents taking shortcuts like this give the industry a bad reputation because these actions circumvent the rules and go against the intentions of real estate regulatory framework to protect consumers.  If it isn’t mystery services,  it is posting incessantly to social media pages in the hopes that the right person sees it at the right time. Well, not anymore.`,
        description: `ZootPro follows a legally sound model of outsourcing which agents and brokers can trust.  Our compliance guarantee means that we have taken the guesswork out of the regulatory guidance for you so that you can focus on doing work you love, serving your clients and growing your business.  This is just what we do.  Plus, you get the help you need when you need on your terms.`,
    },
];

export const whyBlocks: {
    position: string;
    image: string;
    title?: string;
    paragraphs: string[];
    description?: string;
}[] = [
    {
        position: 'right',
        image: why1Pic,
        paragraphs: [
            'New Agent Advantages – Use ZootPro to gain knowledge and experience while helping other agents with real estate tasks and activities.',
            'Broker Growth Advantages – if you`re building a brokerage, and growth is your goal, find other brokerages that you can cooperate with and ZootPro makes the job of leverage those broker professionals for your brokerage easy.',
            'Seasoned Agent Advantages – Give your clients the gift of instant fiduciaryleverage and access to a larger pool of your brokerage’s resources of agents, time, skills, and listings by using a patent-pending software technology available to you on ZootPro in your day-to-day business.',
        ],
    },
    {
        position: 'left',
        image: why2Pic,
        paragraphs: [
            'Increased Exposure – Build market presence and earn extra income with the increased exposure you’ll get from the extra hands ZootPro provides to make that happen.',
            'Work Life Balance – Never worry about overbooking yourself again, find balance with the reliability of other licensed agents from ZootPro willing to help at any time of day or night.',
            'Increased Productivity – ZootPro gives you a community of agents that are able to help you improve engagement and loyalty which increases revenues.',
            'Reliable – Professionals on ZootPro are licensed members of your brokerage that have been fully vetted and are able to be retained without hassle to do exactly what you’re needing, when you’re needing it done.',
            'Trustworthy – The ZootPro platform allows for seamless agent-to-agent payments with no hidden fees so that you don’t run the risk of violating any rules or regulations that guide the profession.',
        ],
    },
];

export const ourStoryBlocks: {
    position: string;
    image: string;
    title: string;
    text: string;
    description?: string;
}[] = [
    {
        position: 'left',
        image: ourStory1Pic,
        title: 'How it was...',
        text: `ZootPro was founded in 2020, however Yvonne and Sharif, the husband-and-wife team that dreamed it up had been working separately, Sharif, as a real estate agent and Yvonne, as a technologist turned corporate and compliance lawyer.  They began working side by side since 2017.  The opportunity for synergy between a real estate agent and an attorney was clear. With a clear vision for helping people build wealth through real estate, they embarked on a joint real estate venture.`,
    },
    {
        position: 'right',
        image: ourStory2Pic,
        title: 'How it started...',
        text: `They decided to work together to build a real estate business that caters to new house buyers seeking sharp and expert guidance to navigate the real estate market while Yvonne’s role was to serve to help make sure deals were meeting their legal and regulatory requirements.`,
        description: `The business began to grow and the clients slowly came flowing in. However, not too long after client needs started to increase, they saw a definitive gap in the industry. Whenever Sharif was overextended and unable to get help for real estate tasks and resources, he would turn to his brokerage office to only find that there were no effective resources available to him without his hiring a full time real estate agent employee or joining a team.`,
    },
    {
        position: 'left',
        image: ourStory3Pic,
        title: 'Finding The Problem',
        text: `It happened multiple times where help was needed without a reasonable or viable solution; while on vacation and a client needed to gain access for a re-inspection, or juggling multiple clients meetings, or trying to fit multiple listings into the schedule on the same day as a final walkthrough, or getting called by agents who needed help with a showing or an open house and being unavailable to assist... It was very stressful to try to run a people business without also running yourself into the ground and being constantly exhausted for it.  Something needed to give.`,
    },
    {
        position: 'right',
        image: ourStory4Pic,
        title: 'The Solution',
        text: `What Sharif and Yvonne realized early on was that the benefit of being able to rely on someone else when licensed real estate help was needed was necessary and unnegotiable. The fact that filling this need had to be done in a way that complied with applicable real estate laws and regulations as well as rules of agency for different states was a priority for Yvonne. This person needed to also be a licensed agent, and most importantly, they needed to also be affiliated to the same brokerage`,
        description: `As an agent licensed in multiple states, where a few of these states followed common law rules while the other did not and were Commonwealth states, it became clear why the real estate industry had remained fragmented for such a long time that this pressing need of real estate agents in the industry was not being met.`,
    },
    {
        position: 'left',
        image: ourStory5Pic,
        title: 'Work-Life Balanced',
        text: `Whether it was an agent having more information and knowledge in certain areas yet being unable to effectively spread this knowledge for a lack of time, or a real estate assistant helping with scheduling open houses and overbookings yet finding that more could still be done to engage fully with the entire book of real estate clients and listings, it was obvious that networking and having assistance was what would make their real estate business successful.`,
        description: `Defining these needs helped them create the ZootPro system so that real estate professionals all over the country would have a trusted and dedicated community to turn to for the resources they need to be able to complete licensed real estate activities, in a legally sound and compliant manner, quickly and effectively.`,
    },
];

export const FAQDropDownContent = [
    {
        header: 'Is ZootPro good for Realtors?',
        text: 'Yes, ZootPro is good for new and established agents.  It is hard for newer agents to get a footing in the business, and ZootPro provides an avenue to generate business by assisting other agents with licensed activities for a small fee. Established agents can use ZootPro to gain more leverage in their business by outsourcing non-revenue generating tasks to other agents so that they can focus on the more important things such as generating more leads, converting current clients of spending time with family or on other passions.',
    },
    {
        header: 'Is ZootPro good for Brokers?',
        text: 'ZootPro is great for Brokers.  Real estate agents leave the business at a high rate for various reasons including lack of community, trouble with time management, and even problems with lead generation. New agents most times have no ground on which to begin to grow a business. ZootPro helps brokers reduce agents attrition and increase agent engagement and productivity by providing instant access to other agents for community and networking, access to lead generation opportunities for agents who might host open houses for other agents, and even access to time management opportunities as these agents can begin to understand the business by helping other agents who have a need.',
    },
    {
        header: 'Is ZootPro good for homebuyers?',
        text: 'Homebuyers whose real estate agents are part of ZootPro gain an instant fiduciary to other agents who are affiliated to his or her real estate agent. When your agent is out of town and you need a last minute showing, you can rest assured that your agent will get you a trusted professional to assist quickly and properly.',
    },
    {
        header: 'Is ZootPro good for showings?',
        text: 'Homebuyers whose real estate agents are part of ZootPro gain an instant fiduciary to other agents who are affiliated to his or her real estate agent. When your agent is out of town and you need a last minute showing, you can rest assured that your agent will get you a trusted professional to assist quickly and properly.',
    },
    {
        header: 'Is ZootPro good for Home inspections?',
        text: 'Activities like home inspections take a lot of time and do not generate revenues for the agent representing the buyer or seller. When this time could be spent attending to other pressing matters, ZootPro makes it easy to find help quickly.',
    },
    {
        header: 'Is ZootPro good for real estate agents to hire other agents?',
        text: 'Yes. Agents have always assisted other agents with tasks. The only problem has always been finding agents who are affiliated in order to preserve the terms of the broker-agent agreement that disallows agents from hiring agents of other brokerages.  Today, agents post to social media, use Excel files to share their needs or availability when there is a better way that this could be done. ZootPro solves this problem beautifully and innovatively for the industry.',
    },
    {
        header: 'Why is ZootPro legally sound and compliant?',
        text: 'Agents are retained to be part of a brokerage through a broker-agent agreement which makes the agent in most cases an exclusive representative of the selected broker.  When an agent uses an agent at a different brokerage for any licensed sales activity, they run the risk of breaking this agreement which was created to preclude any misunderstandings in the market about ownerships of leads and clients. ZootPro is the only solution that has solved this problem.',
    },
    {
        header: 'Is ZootPro a brokerage?',
        text: 'ZootPro is a software provider. We are not a brokerage. We do not supervise real estate agents or collect and disperse funds to real estate agents as is expected of brokers.',
    },
];

export const FAQPricingContent = [
    {
        header: 'Who sets the rates for services?',
        text: 'Agents set their own rates on the platform. We believe that in this manner, the market so-to-speak will adjust and hit the right balance. At this time there is no consensus for how these activities should be priced. We hbelieve that agents can set their preferred rates based on the nature of the work involved in real estate transactions.',
    },
    {
        header: 'How do I pay for a service?',
        text: 'As part of the sign up process, users will also be required to create a payment account with a payment service provider sourced and selected at the sole discretion of ZootPro. Once agent payments and bank accounts are verified, agents can begin to use the platform fully. After a task is completed, it is the responsibility of the service provider to complete the task within the system for the payment process to begin. The hiring agent is responsible to make payment for the completed activity to the agent hired.',
    },
    {
        header: 'Can I pay an agent in commissions?',
        text: 'Yes, you can compensate other agents with commission payments as an added incentive. This essential feature goes to the core of the nature of the real estate industry. Agents earn commission for properties they help homeowners buy or sell, so it only makes sense that agents should have the ability to choose to provide commission-based incentives in their businesses. With ZootPro, commission payments promised on the platform are communicated to the managing broker for ultimate disposition along with the underlying terms of the original activity. ',
    },
    {
        header: 'Are there any hidden fees?',
        text: 'There are no hidden fees. Choose one subscription tier and begin to grow your business the smart way, the ZootPro way.',
    },
];
