import React, {useEffect} from 'react'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {PaymentsModalWrapper} from "../components";
import {Box} from "@chakra-ui/react";
import {CustomButton, CustomInput} from "../../../../../common/components";
import {ErrorMessages} from "../../../../../common/schemes/messages";
import {Resolver, useForm} from "react-hook-form";
import {dispatch, RootStore} from "../../../../../store";
import {useDispatch, useSelector} from "react-redux";
import {updatePaymentMethodRequest} from "../store/actions";

interface IEditBankNameModal{
    isOpen:boolean
    title: string
    onClose: () => void
    editBankNameId: string
}

const editNicknameResolver = yupResolver(
    yup.object().shape({
        name: yup.string().required(ErrorMessages.REQUIRED),
    }),
);

export const EditBankNameModal:React.FC<IEditBankNameModal> = ({
isOpen,
onClose,
title,
editBankNameId

}) => {
    const dispatch = useDispatch()

    const {register, formState:{errors},handleSubmit, reset} = useForm<{ name:string }>({
        resolver: editNicknameResolver as Resolver<{ name:string }>
    })

    const {loading} = useSelector((state: RootStore) => state.payments.updatePaymentMethod)

    const onSubmit = (data:{ name:string } ) => {
        dispatch(updatePaymentMethodRequest({
            ...data,
            fundingSourceId: editBankNameId
        }))
    }

    useEffect(() => {
        if(!isOpen){
            reset({})
        }

    },[isOpen])

    return (
        <PaymentsModalWrapper isCentered isOpen={isOpen} title={title} onClose={onClose}>

            <form onSubmit={handleSubmit(onSubmit)}>
                    <Box mx={"auto"} maxW={'342px'} mt={'30px'}>

                        <CustomInput
                            placeholder={'Enter'}
                            errors={errors.name}
                            register={{...register('name')}}
                            label={'NickName'}
                        />
                        <CustomButton
                            isLoading={loading}
                            mt={'30px'}
                            text="Send"
                            type="submit"
                            height="60px"
                            bgColor="#2C335C"
                        />

                    </Box>

            </form>

        </PaymentsModalWrapper>

    )
}
