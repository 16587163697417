import React from 'react';

export const NewOpportunity: React.FC = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z"
            fill="#F2C94C"
        />
        <path
            d="M15.7895 13.0666V12.6388C15.7895 11.6938 16.5556 10.9277 17.5006 10.9277C18.4456 10.9277 19.2117 11.6938 19.2117 12.6388V13.0666M11.5117 20.3388H23.4895M12.3673 13.4944H22.6339C23.1064 13.4944 23.4895 13.8774 23.4895 14.35V22.05C23.4895 22.5225 23.1064 22.9055 22.6339 22.9055H12.3673C11.8948 22.9055 11.5117 22.5225 11.5117 22.05V14.35C11.5117 13.8774 11.8948 13.4944 12.3673 13.4944Z"
            stroke="white"
        />
    </svg>
);