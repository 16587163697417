import { IResponseWithCustomValue } from '../../../../common/types'

export type CreateDwollaAccountPayload = {
    city: string;
    state: string;
    postalCode: string;
    address1: string;
    address2: string;
    ssn: number;
    dateOfBirth: string;
};
export type GetCustomerGeneralInfoResponse = {
    email: string;
    city: string;
    state: string;
    postalCode: string;
    address1: string;
    address2: string;
};
export type EditDwollaAccountPayload = {
    email: string;
    city: string;
    postalCode: string;
    address1: string;
    address2: string;
    state: string;
};
export type UploadsNewDocumentPayload = {
    documentType: string;
    document: FormData;
};
export type GetFundingSourcesResponse = {
    dwollaId: string;
    type: string;
    nickname: string | null;
    bankName: string | null;
    status: string;
    isDefaultSource: boolean;
    isDefaultDestination: boolean;
};
export type AddNewBankAccountPayload = {
    routingNumber: string;
    accountNumber: string;
    bankAccountType: string;
    name: string;
};
export type UpdatePaymentMethodPayload = {
    name?: string;
    fundingSourceId: string;
    routingNumber?: string;
    accountNumber?: string;
    bankAccountType?: string;
};
export type VerifyInitDepositsPayload = {
    firstDepositValue: number;
    secondDepositValue: number;
    fundingSourceId: string;
};
export type UserToSelfTransferPayload = {
    fundingSourceId: string;
    amountOfMoney: number;
    isPayout: boolean;
};
export type PlaidLinkTokenResponse = {
    linkToken: string;
    expiration: string;
};
export type GetProcessorTokenPayload = {
    publicToken: string;
    accountId: string;
};
export type AddBankAccountWithPlaidPayload = {
    plaidToken: string;
    name: string;
};
export type GetTransactionHistoryPayload = {
    pageNumber: number;
    pageSize: number;
    isIncome: boolean;
};
export type PaymentStatus = 'success' | 'fail' | null;
export type TransactionHistoryItem = {
    amountOfMoney: number;
    assistRequestId: number;
    assistRequestTypeName: string;
    created: string;
    fee: number;
    fundingSource: string;
    id: string | number;
    isIncome: boolean;
    relatedUserAvatar: {
        id: number;
        itemHash: string;
        itemExtension: string;
    }
    relatedUserFullName: string;
    relatedUserId: number;
    status: string;
    requestTypeId: number | null
};
export type TransactionHistoryValue = {
    models: TransactionHistoryItem[];
    totalCount: number;
}
export interface GetTransactionHistorySuccessPayload extends IResponseWithCustomValue<TransactionHistoryValue> {
    pageNumber: number;
}

export enum CustomerStatus {
    Verified = 'verified',
    Retry = 'retry',
    Document = 'document',
    DocumentMany = 'document_many',
    Suspended = 'suspended',
}

