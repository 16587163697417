import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Flex,
    FormControl,
    GridItem,
    HStack,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    useRadioGroup,
} from '@chakra-ui/react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { StateDirectoryItem } from '../../../../../common/types';
import {
    CustomButton,
    CustomDropdown,
    CustomInput,
    DatePicker,
    MultiSelect,
    TypeAhead,
} from '../../../../../common/components';
import { ClockIcon } from '../../../../../assets/icons';
import {
    carryAddressLoadOptions,
    fonts,
    getPlaceInfo,
    combineDateAndTime,
    convertTimeIn24HoursFormat,
} from '../../../../../common/utils';
import { RadioCard } from '../../../../Settings/pages/Location/components';
import { colors, getInspectionItems } from '../../../../../common/constants';
import createPostOtherSchemaResolver, {
    CreateOtherFormValues,
    createPostOtherSchema,
} from '../validation/createPostOther.schema';
import { CreateJobPostPayload } from '../../../types';

interface IOtherFormProps {
    currentStates: string[];
    statesType: StateDirectoryItem[];
    searchByCities?: boolean;
    requestValue: string;
    onCreatePost: (value: CreateJobPostPayload) => void;
    createPostLoading: boolean;
    setRequestValue: (value: string) => void;
}

const options = [
    { id: 'yes', label: 'Yes' },
    { id: 'no', label: 'No' },
];

export const OtherForm: React.FC<IOtherFormProps> = ({
    requestValue,
    setRequestValue,
    currentStates,
    createPostLoading,
    onCreatePost,
}) => {
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        clearErrors,
        watch,
        control,
        getValues,
        reset,
    } = useForm<CreateOtherFormValues>({
        resolver: createPostOtherSchemaResolver as Resolver<CreateOtherFormValues>,
        defaultValues: createPostOtherSchema.cast({}),
    });
    const history = useHistory();

    const [date, involvesProperty] = watch(['date', 'involvesProperty']);

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'involvesProperty',
        onChange: (value: 'yes' | 'no') => setValue('involvesProperty', value),
        value: involvesProperty,
    });

    const group = getRootProps();

    useEffect(() => {
        if (involvesProperty !== 'yes') {
            // setValue('latitude', 0);
            // setValue('longitude', 0);
            setValue('startingAddress', '');
        }
    }, [involvesProperty]);

    const onSubmit = (data: CreateOtherFormValues) => {
        const fromTime = convertTimeIn24HoursFormat(data.fromTime);
        const payload = {
            clientTypeId: data.clientTypeId,
            price: Number(data.price),
            fromTime: fromTime,
            states: data.states,
            generalNote: data.generalNote,
            clientName: data.clientName,
            clientPhone: data.clientPhone,
            privateNote: data.privateNote,
            addresses: [{ name: data.startingAddress }],
            typeId: +requestValue,
            isShowingScheduled: data.isShowingScheduled === 'Yes, I Have Scheduled The Other',
            doesInvolveByProperty: involvesProperty === 'yes',
            date: combineDateAndTime(
                data.date ? dayjs(+data.date).toISOString() : dayjs().toISOString(),
                fromTime
                    ? dayjs(new Date())
                          .set('hour', +fromTime.split(':')[0])
                          .set('minute', +fromTime.split(':')[1])
                          .set('seconds', 0)
                          .set('milliseconds', 0)
                          .toISOString()
                    : dayjs().toISOString(),
            ),
        };
        onCreatePost(payload);
        history.push('/app');
    };

    return (
        <Container maxW="container.md">
            <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid columns={2} gap="50px" rowGap="0px">
                    <CustomDropdown
                        options={[
                            { label: 'Showing', value: '1', id: 1 },
                            { label: 'Home Inspection', value: '2', id: 2 },
                            { label: 'Final Walk-Through', value: '3', id: 3 },
                            { label: 'Other', value: '4', id: 4 },
                            { label: 'Appraisal', value: '5', id: 5 },
                            { label: 'Referral', value: '7', id: 7 },
                        ]}
                        label="Request type:*"
                        register={{
                            value: requestValue,
                            onChange: (ev: any) => {
                                setRequestValue(ev.target.value);
                            },
                        }}
                        errors={''}
                        width="350px"
                    />

                    <Controller
                        render={({ field }) => (
                            <MultiSelect
                                label="State:*"
                                options={currentStates.map((state, i) => ({
                                    value: state,
                                    label: state,
                                }))}
                                value={field.value}
                                onChange={field.onChange}
                                errors={errors.states && (errors.states as any).message}
                                width="350px"
                            />
                        )}
                        control={control}
                        name="states"
                    />
                    <DatePicker
                        label="Date:*"
                        displayValue={date ? dayjs.utc(+date).format('MM/DD/YY') : ''}
                        value={Number(date) || dayjs.utc().valueOf()}
                        setValue={value => {
                            setValue('date', value.toString());
                            clearErrors('date');
                        }}
                        removeBottomMargin
                        placeholder="MM/DD/YY"
                        errMessage={errors.date?.message}
                        borderRadius="50px"
                        formControlProps={{
                            margin: errors.date ? '0 10px 0 0' : '0 10px 26px 0',
                        }}
                    />
                    <CustomInput
                        register={{ ...register('fromTime') }}
                        label="Start Time*"
                        placeholder="From Time"
                        type="time-12-hours"
                        defaultValue="00:00 AM"
                        errors={errors.fromTime}
                        rightIcon={<ClockIcon color={colors.secondaryFontDarker} />}
                        margin={errors.fromTime ? '0' : '0 0 26px 0'}
                        width="350px"
                    />
                    <CustomInput
                        register={{ ...register('price') }}
                        type="number"
                        label="Offer Price:*"
                        errors={errors.price}
                        rightIcon={<Text color="secondaryFontDarker">$</Text>}
                        placeholder="100"
                        width="350px"
                        margin={errors.price ? '0' : '0 0 26px 0 !important'}
                    />
                </SimpleGrid>
                <Box my={'26px'}>
                    <HStack mb={'26px'} spacing={'39px'}>
                        <Text
                            color="brand"
                            fontWeight="400"
                            fontSize="14px"
                            fontFamily={fonts.poppins}
                        >
                            Does this activity involve a property? *
                        </Text>
                        <Controller
                            control={control}
                            name="involvesProperty"
                            render={({ field }) => (
                                <RadioGroup {...field}>
                                    <Stack {...group} direction="row" spacing="25px">
                                        {options.map(value => {
                                            const radio = getRadioProps({
                                                value: value.id,
                                            });
                                            return (
                                                <RadioCard key={value.id} {...radio}>
                                                    {value.label}
                                                </RadioCard>
                                            );
                                        })}
                                    </Stack>
                                </RadioGroup>
                            )}
                        />
                    </HStack>
                    {involvesProperty === 'yes' ? (
                        <>
                            <TypeAhead
                                placeholder="Select location"
                                label={'Address: *'}
                                loadOptions={carryAddressLoadOptions(false, [])}
                                onStateChange={async ev => {
                                    const placeInfo = ev?.value
                                        ? await getPlaceInfo(ev.value)
                                        : null;
                                    if (placeInfo) {
                                        console.log(placeInfo, 'placeInfo');
                                        setValue('latitude', placeInfo?.lat);
                                        setValue('longitude', placeInfo?.lng);
                                        clearErrors('latitude');
                                        clearErrors('longitude');
                                    } else {
                                        setValue('latitude', 0);
                                        setValue('longitude', 0);
                                    }
                                    setValue('startingAddress', ev?.label || '');
                                    clearErrors('startingAddress');
                                }}
                                errors={errors.longitude?.message}
                            />
                            <CustomDropdown
                                options={getInspectionItems('Other')}
                                label="Is access to the property scheduled? *"
                                register={{ ...register('isShowingScheduled') }}
                                errors={errors.isShowingScheduled}
                                placeholder="Select"
                                width="100%"
                                margin={errors.isShowingScheduled ? '0' : '0 0 26px 0'}
                            />
                        </>
                    ) : null}
                </Box>
                <Flex flexDirection="column">
                    <SimpleGrid columns={2} gap="40px" rowGap="0px" pt="20px">
                        <CustomInput
                            label="Description:"
                            width="100%"
                            placeholder="Enter"
                            register={{ ...register('generalNote') }}
                            errors={errors.generalNote}
                            margin={errors.generalNote ? '0' : '0 0 26px 0'}
                        />
                        <CustomInput
                            label="Client Name"
                            width="100%"
                            placeholder="Enter"
                            register={{ ...register('clientName') }}
                            errors={errors.clientName}
                            margin={errors.clientName ? '0' : '0 0 26px 0'}
                        />
                        <CustomDropdown
                            label="The Client is: *"
                            options={[
                                { id: 1, label: 'A Couple', value: '1' },
                                { id: 2, label: 'A Family', value: '2' },
                                { id: 3, label: 'An Individual', value: '3' },
                            ]}
                            placeholder="Select"
                            register={{ ...register('clientTypeId') }}
                            errors={errors.clientTypeId?.message}
                            width="100%"
                            margin={errors.clientTypeId ? '0' : '0 0 26px 0'}
                        />
                        <CustomInput
                            register={{ ...register('clientPhone') }}
                            label="Client Phone Number:"
                            placeholder="Enter"
                            errors={errors.clientPhone}
                            type="tel"
                            mask="999-999-9999"
                        />
                        <GridItem colSpan={2} mt={'26px'}>
                            <CustomInput
                                label="Private Notes to the Chosen Agent"
                                width="100%"
                                register={{ ...register('privateNote') }}
                                placeholder="Enter"
                                errors={errors.privateNote}
                            />
                        </GridItem>
                        <GridItem gridColumn={'2/3'} mt={'26px'}>
                            <CustomButton
                                width="350px"
                                height="60px"
                                type="submit"
                                isLoading={createPostLoading}
                                loadingText="Sending..."
                                text="Post"
                                bgColor="brand"
                                mb="50px"
                            />
                        </GridItem>
                    </SimpleGrid>
                </Flex>
            </form>
        </Container>
    );
};
