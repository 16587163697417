import React from 'react';
import { Link, Text, Center, VStack } from '@chakra-ui/react';

interface IMainSunnaryItemProps {
    background: string;
    label: string;
    icon: JSX.Element;
    url?: string | null;
}

export const MainSummaryItem: React.FC<IMainSunnaryItemProps> = ({ background, icon, label, url }) => (
    <VStack
        bg={background}
        borderRadius="30px"
        minHeight="100px"
        padding="20px"
        w="100%"
        justify="center"
    >
        <Center>{icon}</Center>

        {label === 'Agent website'
            ? <Link
                target="_blank"
                href={`https://${url}`}
                color="white"
                fontSize="13px"
                fontWeight="bold"
                lineHeight="18px"
                align="center">
                {label}
            </Link>
            : <Text color="white" fontSize="13px" fontWeight="bold" lineHeight="18px" align="center">
                {label}
            </Text>}
    </VStack>
);
