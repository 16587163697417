import React from 'react';
import parse from 'html-react-parser';
import { CommentType } from '../../../types';
import { Box, Flex, Text, Avatar } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { colors } from '../../../../../common/constants';
import { getAvatarPicture } from '../../../../../common/utils';

interface ICommentBoxProps {
    comment: CommentType;
}

export const CommentBox: React.FC<ICommentBoxProps> = ({ comment }) => {
    return (
        <Box>
            {/*    Comment Header*/}
            <Flex align={'center'}>
                <Avatar
                    mr={'8px'}
                    boxSize={'8'}
                    name={comment.createdBy.fullName}
                    src={getAvatarPicture(comment.createdBy.avatar)}
                />
                <Box>
                    <Text
                        color={colors.brand}
                        letterSpacing={'1px'}
                        lineHeight={'21px'}
                        fontSize={'14px'}
                        fontWeight={'500'}
                    >
                        {comment.createdBy.fullName}
                    </Text>
                    <Text
                        color={colors.secondaryFontDarker}
                        letterSpacing={'1px'}
                        lineHeight={'21px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                    >
                        {dayjs(comment.createdAt).fromNow()}
                    </Text>
                </Box>
            </Flex>
            {/*    Comment Text*/}
            <Text
                mt={'12px'}
                color={'#16192C'}
                letterSpacing={'1px'}
                lineHeight={'24px'}
                fontSize={'16px'}
                fontWeight={'400'}
            >
                {parse(comment.text)}
            </Text>
        </Box>
    );
};
