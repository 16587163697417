import React from 'react';
import {
    Box,
    Flex,
    Input,
    Text,
    Textarea,
    Modal,
    ModalContent,
    ModalHeader,
    CloseButton,
    ModalBody,
    ModalOverlay,
} from '@chakra-ui/react';
import { CustomButton } from '../../blocks';
import { SendIssuePayloadType } from './types';

interface IOpportunitiesModalProps {
    isOpen: boolean;
    onClose: () => void;
    titleValue: string;
    messageValue: string;
    handleChangeTitle: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeMessage: (arg0: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleResetTexts: () => void;
    onSendIssueRequest: (payload: SendIssuePayloadType) => void;
    id: number;
    token: string;
    checkText: boolean;
}

export const ActivityIssueModal: React.FC<IOpportunitiesModalProps> = ({
    isOpen,
    onClose,
    id,
    token,
    titleValue,
    messageValue,
    handleChangeMessage,
    handleChangeTitle,
    handleResetTexts,
    onSendIssueRequest,
    checkText,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} useInert={false} isCentered size="3xl">
            <ModalOverlay />
            <ModalContent flexDirection="column" alignItems="center" w="100%" px="5%">
                <ModalHeader
                    d="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    w="100%"
                    mt="20px"
                >
                    <Text
                        fontSize="22px"
                        fontWeight="700"
                        color="brand"
                        textTransform="capitalize"
                        align="center"
                    >
                        Issue
                    </Text>
                    <CloseButton color="brand" onClick={onClose} />
                </ModalHeader>
                <ModalBody w="100%">
                    <Flex mt="30px" align="center" w="100%">
                        <Box w="100%">
                            <Text color="brand" fontSize="14px">
                                Title
                            </Text>
                            <Input
                                w="100%"
                                color="##F6F7FB"
                                variant="filled"
                                placeholder="Enter"
                                value={titleValue}
                                onChange={handleChangeTitle}
                                borderRadius="80px"
                            />
                        </Box>
                    </Flex>
                    <Flex mt="30px" align="center">
                        <Textarea
                            h="300px"
                            color="##F6F7FB"
                            variant="filled"
                            placeholder="Describe issue"
                            value={messageValue}
                            onChange={handleChangeMessage}
                            borderRadius="24px"
                        />
                    </Flex>
                    <Flex mt="50px" mb="20px" align="center" justifyContent="flex-end">
                        <CustomButton
                            type="submit"
                            loadingText="Sending..."
                            text="Send"
                            width="300px"
                            height="60px"
                            bgColor="brand"
                            disabled={checkText}
                            onClick={() => {
                                onSendIssueRequest({
                                    subject: titleValue,
                                    text: messageValue,
                                    id: id,
                                    token: token,
                                });
                                handleResetTexts();
                            }}
                        />
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
