import React, { useState } from 'react';
import { Box, IconButton, Image, Flex, Text, HStack, Divider, BoxProps } from '@chakra-ui/react';
import { ResizeIcon, LocationIcon } from '../../../../assets/icons';
import { ListingDetailsSummary } from './ListingDetailsSummary';
import { OpenHouseInfoType } from '../../../types';
import { fonts } from '../../../utils';
import { colors } from '../../../constants';
import Viewer from 'images-viewer-react';

interface IOpenHouseInfoSectionProps {
    openHouseInfo: OpenHouseInfoType;
    optionalContainerProps?: BoxProps;
}

{
    /* <HStack spacing={2} mt="33px">
    <Avatar
        name={openHouseInfo?.agentName}
        src={getAvatarPicture(openHouseInfo?.avatar)}
        w="54px"
        h="54px"
    />
    <Box>
        <Text
            color={colors.brand}
            fontWeight={700}
            fontSize="16px"
            lineHeight="23px"
            fontFamily={fonts.poppins}
        >
            {openHouseInfo?.agentName}
        </Text>
        <Text
            color={colors.secondaryFontDarker}
            fontSize="12px"
            lineHeight="18px"
            fontFamily={fonts.poppins}
        >
            MLS Id: {openHouseInfo?.agentMlsId}
        </Text>
    </Box>
</HStack> */
}

export const OpenHouseInfoSection: React.FC<IOpenHouseInfoSectionProps> = ({
    openHouseInfo,
    optionalContainerProps,
}) => {
    const [imageViewerOpen, setImageViewerOpen] = useState(false);

    return (
        <>
            <Box borderRight="1px solid #D1D2DB" mr="20px" pr="20px" {...optionalContainerProps}>
                <Box pos="relative">
                    <IconButton
                        aria-label="Full screen"
                        icon={<ResizeIcon />}
                        bg="brand"
                        variant="unstyled"
                        pos="absolute"
                        _hover={{
                            opacity: 0.5,
                        }}
                        top="15px"
                        left="15px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => setImageViewerOpen(true)}
                    />
                    <Image
                        // height="280px"
                        w="100%"
                        borderRadius="20px"
                        src={
                            openHouseInfo.primaryPhoto !== ''
                                ? openHouseInfo.primaryPhoto
                                : undefined
                        }
                        alt="Primary photo"
                    />
                </Box>
                <Flex mt={4} alignItems="center" justifyContent="space-between">
                    <Text
                        color={colors.brand}
                        fontWeight={700}
                        fontSize="22px"
                        lineHeight="33px"
                        fontFamily={fonts.poppins}
                    >
                        ${openHouseInfo?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Text>
                    <Flex>
                        <HStack pr={3}>
                            <Text
                                color={colors.brand}
                                fontSize="13px"
                                lineHeight="20px"
                                fontFamily={fonts.poppins}
                            >
                                {openHouseInfo?.badRoomCount} bd
                            </Text>
                            <Divider
                                orientation="vertical"
                                borderColor={colors.secondaryFontDarker}
                            />
                        </HStack>
                        <HStack pr={3}>
                            <Text
                                color={colors.brand}
                                fontSize="13px"
                                lineHeight="20px"
                                fontFamily={fonts.poppins}
                            >
                                {openHouseInfo?.bathRoomCount} ba
                            </Text>
                            <Divider
                                orientation="vertical"
                                borderColor={colors.secondaryFontDarker}
                            />
                        </HStack>
                        <HStack>
                            <Text
                                color={colors.brand}
                                fontSize="13px"
                                lineHeight="20px"
                                fontFamily={fonts.poppins}
                            >
                                {openHouseInfo?.area} sqft
                            </Text>
                        </HStack>
                    </Flex>
                </Flex>
                <HStack spacing={2} mt={2}>
                    <LocationIcon color={colors.secondaryFontDarker} height="20px" width="20px" />
                    <Text
                        color={colors.secondaryFontDarker}
                        fontSize="13px"
                        lineHeight="20px"
                        fontFamily={fonts.poppins}
                    >
                        {openHouseInfo?.line}, {openHouseInfo?.city}, {openHouseInfo?.stateCode}{' '}
                        {openHouseInfo?.postalCode}
                    </Text>
                </HStack>

                <Text
                    mt="30px"
                    color={colors.brand}
                    fontWeight={700}
                    fontSize="16px"
                    lineHeight="24px"
                    fontFamily={fonts.poppins}
                >
                    About house
                </Text>
                <Text
                    mt={1}
                    color={colors.secondaryFontDarker}
                    fontSize="16px"
                    lineHeight="26px"
                    fontFamily={fonts.poppins}
                >
                    {openHouseInfo.description}
                </Text>
                <ListingDetailsSummary
                    title="Address:"
                    values={[
                        { name: 'State Code', value: openHouseInfo?.stateCode },
                        { name: 'Country', value: openHouseInfo?.country },
                        { name: 'Address', value: openHouseInfo?.line },
                        {
                            name: 'Postal Code',
                            value: openHouseInfo?.postalCode,
                        },
                        { name: 'City', value: openHouseInfo?.city },
                        { name: 'County', value: openHouseInfo?.county },
                    ]}
                />
                <ListingDetailsSummary
                    title="Facilities:"
                    values={[
                        { name: 'Type', value: openHouseInfo?.type },
                        { name: 'Baths full', value: openHouseInfo?.bathsFull },
                        { name: 'Bedrooms', value: openHouseInfo?.bedrooms },
                        { name: 'Garage', value: openHouseInfo?.garage },
                        { name: 'Stories', value: openHouseInfo?.stories },
                        {
                            name: 'Baths half',
                            value: openHouseInfo?.bathshHalf,
                        },
                        { name: 'Beds', value: openHouseInfo?.beds },
                    ]}
                />
                <ListingDetailsSummary
                    title="Building and Construction:"
                    stringData={openHouseInfo?.buildingAndConstructionsFeatureText}
                />
                <ListingDetailsSummary
                    title="Heating and Cooling:"
                    stringData={openHouseInfo?.heatingAndCooling}
                />
                <ListingDetailsSummary
                    title="Road Frontage Type:"
                    stringData={openHouseInfo?.roadFrontageType}
                />
                <ListingDetailsSummary title="Land Info:" stringData={openHouseInfo?.landInfo} />
                <ListingDetailsSummary
                    title="Homeowners Association"
                    stringData={openHouseInfo?.homeownersAssociation}
                />
                <ListingDetailsSummary
                    title="School District:"
                    stringData={openHouseInfo?.schoolDistrict}
                />
                <ListingDetailsSummary
                    title="Other Property Info:"
                    stringData={openHouseInfo?.otherPropertyInfo}
                />
                <ListingDetailsSummary title="Utilities:" stringData={openHouseInfo?.utilities} />
            </Box>
            <Viewer
                visible={imageViewerOpen}
                onClose={() => setImageViewerOpen(false)}
                images={[openHouseInfo.primaryPhoto, ...openHouseInfo.photos]
                    .filter(elem => elem)
                    .map((elem: string) => ({
                        src: elem,
                        fileType: '',
                        navSrc: elem,
                    }))}
                zIndex={9999999}
                className="image-viewer"
                drag={false}
                rotatable={false}
                scalable={false}
                attribute={false}
                onMaskClick={() => setImageViewerOpen(false)}
            />
        </>
    );
};
