import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import { IGetStatesSuccessResponse, IResponseWithCustomValue } from '../../../common/types';
import { getStates, getStatesError, getStatesSuccess } from '../../actions/directories.actions';

function* workerGetStates() {
    try {
        const result: IResponseWithCustomValue<IGetStatesSuccessResponse> = yield call(
            Api.get,
            '/api/directories/get-states',
            {},
        );
        console.log('getStates', result);
        if (result.success) {
            yield put(getStatesSuccess(result));
        } else {
            yield put(getStatesError(result.errors));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* watchGetStatesDirectorySaga() {
    yield takeEvery(getStates.type, workerGetStates);
}
