import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const editableTimeSlotSchema = yup.object().shape({
    date: yup.number().positive(ErrorMessages.REQUIRED).required(ErrorMessages.REQUIRED),
    endTime: yup.string().required(ErrorMessages.REQUIRED),
    startTime: yup.string().required(ErrorMessages.REQUIRED),
    price: yup
        .number()
        .positive(ErrorMessages.POSITIVE_NUMBER)
        .typeError(ErrorMessages.NUMBER_INVALID),
});

export const editableTimeSlotResolver = yupResolver(editableTimeSlotSchema);
