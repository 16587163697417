import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s',
        s: 'Just now',
        m: '1m ago',
        mm: '%dm ago',
        h: '1h ago',
        hh: '%d hours ago',
        d: '1d ago',
        dd: '%dd ago',
        M: 'a month ago',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
    },
});

export const getTimeLastMessage = (time: Date) => dayjs(time).fromNow();
