import React from 'react';
import { Flex } from '@chakra-ui/react';
import { SignUpVerificationPopUpProps } from '../../types';
import { LandingFooter, LandingHeader } from '../../../../../common/components/landing';
import { MoodSmile } from '../../../../../assets/icons';
import { Typography } from '../../../../../common/components/blocks/Typography/Typography';

export const SignUpVerificationPopUp: React.FC<SignUpVerificationPopUpProps> = ({ nestedPath }) => (
    <Flex flexDirection={'column'} minHeight={'100vh'}>
        <LandingHeader isFinalScreen />
        <Flex flex={'1 1 auto'} justifyContent={'center'} alignItems={'center'} py="30px">
            <Flex
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={20}
                backgroundColor="brand"
                w={{ base: '90%', lg: '32vw' }}
                h="40vh"
                minHeight={'300px'}
            >
                <Flex w={'278px'} flexDirection={'column'} alignItems={'center'}>
                    <MoodSmile />
                    <Typography mt={'10px'} weight={600} color={'white'} size={25}>
                        Congratulations!
                    </Typography>
                    <Typography mt={'10px'} textAlign={'center'} color={'white'} size={16}>
                        You have completed registration, and your account is currently being
                        verified.
                    </Typography>
                </Flex>
            </Flex>
        </Flex>
        <LandingFooter />
    </Flex>
);
