import React from 'react';
import { Text, HStack } from '@chakra-ui/react';
import { MultipleMenuSelect, CustomButton } from '../../../blocks';
import { defaultRequestTypesToFilter } from '../../../../constants';

interface IOpportunitiesModalSearchProps {
    getItems: (value?: number) => void;
    setTypeIdsSelected: (value: number[]) => void;
    typeIdsSelected: number[];
}

export const OpportunitiesModalSearch: React.FC<IOpportunitiesModalSearchProps> = ({
    getItems,
    setTypeIdsSelected,
    typeIdsSelected,
}) => (
    <>
        <Text color="brand" fontSize="13px" lineHeight="19px" fontWeight="light" my="10px">
            Settings
        </Text>
        <HStack>
            <MultipleMenuSelect
                menuValues={defaultRequestTypesToFilter}
                setTypeIdsSelected={setTypeIdsSelected}
                typeIdsSelected={typeIdsSelected}
            />
            <CustomButton
                text="Search"
                bgColor="brand"
                width="30%"
                height="50px"
                onClick={() => getItems(1)}
            />
        </HStack>
    </>
);
