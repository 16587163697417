import React from 'react';
import { Flex, Container } from '@chakra-ui/react';
import { Route, Switch } from 'react-router-dom';
import {
    EditProfile,
    Content,
    Rates,
    CalendarAndAvailability,
    Location,
    Account,
    PaymentsContainer,
    Support,
    TaskAvailability,
    Subscription,
    LicensesContainer,
} from './pages';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { NotificationsSettings } from './pages/NotificationsSettings/NotificationsSettings';
import { ServiceHistoryContainer } from '../ServiceHistory/ServiceHistory.container';

export const Settings: React.FC = () => {
    return (
        <Flex backgroundColor="brand" height="100vh" flexDirection="column" alignItems="center">
            <DashboardHeader />
            <Container
                maxW="container.lg"
                bgColor="#FFFFFF"
                maxH="90vh"
                overflowY="auto"
                mt="15px"
                mb="15px"
                borderRadius="10px"
                p="25px 33px"
            >
                <Switch>
                    <Route path="/settings/account" component={Account} />
                    <Route
                        path="/settings/calendar-and-availability"
                        component={CalendarAndAvailability}
                    />
                    <Route path="/settings/rates" component={Rates} />
                    <Route path="/settings/edit-profile" component={EditProfile} />
                    <Route path="/settings/location" component={Location} />
                    <Route path="/settings/licenses" component={LicensesContainer} />
                    <Route path="/settings/payments" component={PaymentsContainer} />
                    <Route path="/settings/support" component={Support} />
                    <Route path="/settings/task-availability" component={TaskAvailability} />
                    <Route path="/settings/subscription" component={Subscription} />
                    <Route path="/settings/notifications" component={NotificationsSettings} />
                    <Route path="/settings/history" component={ServiceHistoryContainer} />
                    <Route path="/" component={Content} />
                </Switch>
            </Container>
        </Flex>
    );
};
