import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import {
    IGetRequestCategoriesSuccessResponse,
    IResponseWithCustomValue,
} from '../../../common/types';
import {
    getRequestCategories,
    getRequestCategoriesError,
    getRequestCategoriesSuccess,
} from '../../actions/directories.actions';

function* workerGetRequestCategories() {
    try {
        const result: IResponseWithCustomValue<IGetRequestCategoriesSuccessResponse> = yield call(
            Api.get,
            '/api/directories/get-request-categories',
            {},
        );
        console.log('getRequestCategories', result);
        if (result.success) {
            yield put(getRequestCategoriesSuccess(result));
        } else {
            yield put(getRequestCategoriesError(result.errors));
        }
    } catch (error) {
        console.log('workerGetRequestCategories error', error);
    }
}

export function* watchGetRequestCategoriesDirectorySaga() {
    yield takeEvery(getRequestCategories.type, workerGetRequestCategories);
}
