import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    Box,
    ModalBody,
    CloseButton,
    Flex,
} from '@chakra-ui/react';
import { colors } from '../../../common/constants';
import ReactPlayer from 'react-player';

interface IVideoModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const VideoModal: React.FC<IVideoModalProps> = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="large">
            <ModalOverlay />
            <ModalContent
                w={'800px'}
                borderRadius={'18px'}
                h={'500px'}
                bgColor={colors.modalBackground}
                alignItems="center"
                py={4}
            >
                <Flex width="100%" justify="flex-end" px={4}>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody w="100%">
                    <ReactPlayer
                        url="https://zootpro.wistia.com/medias/w5brhh04je"
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
