import React, { useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import { secondsToMinutes } from '../../../utils';

interface IProps {
    size: string;
    timeLeft: number;
    setTimeLeft: any;
    color?: string;
}

export const CountDownTimer: React.FC<IProps> = ({ size, color, timeLeft, setTimeLeft }) => {
    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
            if (timeLeft <= 0) setTimeLeft(0);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return (
        <Text weight="light" color={color} textAlign="center">
            {timeLeft < 0 ? '00:00' : secondsToMinutes(timeLeft)}
        </Text>
    );
};
