import { put, call, takeEvery } from 'redux-saga/effects';
import { cancelAcceptedWork, cancelAcceptedWorkSuccess, cancelAcceptedWorkError } from '../actions';
import API from '../../../../common/api/api.base';
import { IResponseWithoutValue } from './../../../../common/types';

function* workerCancelAcceptedWork(action: ReturnType<typeof cancelAcceptedWork>) {
    console.log('cancelAcceptedWork action', action);
    try {
        const result: IResponseWithoutValue = yield call(
            API.put,
            `/api/request/cancel-accepted-work?RequestId=${action.payload}`,
            {},
        );
        console.log('cancelAcceptedWork', result, action);
        if (result.success) {
            yield put(cancelAcceptedWorkSuccess(action.payload));
        } else {
            yield put(cancelAcceptedWorkError(result.errors));
            console.log('cancelAcceptedWorkError', result);
        }
    } catch (error) {
        console.log('cancelAcceptedWorkError', error);
    }
}

export default function* watchCancelAcceptedWorkSaga() {
    yield takeEvery(cancelAcceptedWork.type, workerCancelAcceptedWork);
}
