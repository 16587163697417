import React, { LegacyRef, useState, useEffect } from 'react';
import {
    Box,
    Input,
    Text,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    FormErrorMessage,
    FormControl,
    InputLeftAddon,
    TextProps,
    Button,
    IconButton,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { fonts } from '../../../utils';
import { CheckIcon, EditIcon } from '../../../../assets/icons';
import { colors } from '../../../constants';
import { AnySchema } from 'yup';

interface ICustomInputEditProps {
    labelProps?: TextProps;
    props?: any;
    label: string;
    width?: string | { base?: string; sm?: string; md?: string; lg?: string };
    leftIcon?: any;
    rightIcon?: any;
    placeholder?: string;
    margin?: string;
    register?: any;
    errors?: any;
    bgColor?: string;
    isBorder?: boolean;
    disabled?: boolean;
    defaultValue?: string | number;
    isReadOnly?: boolean;
    color?: string;
    labelColor?: string;
    mask?: string;
    pattern?: string;
    cursor?: string;
    fontWeight?: number;
    fontSize?: string;
    fontFamily?: string;
    textSize?: string;
    placeholderColor?: string;
    height?: string;
    type?:
        | 'none'
        | 'text'
        | 'tel'
        | 'url'
        | 'email'
        | 'number'
        | 'decimal'
        | 'search'
        | 'password'
        | 'time'
        | undefined;
    editFunction?: (value: any) => void;
    schema?: AnySchema;
    errorMsg?: string;
}

export const CustomInputEdit: React.FC<ICustomInputEditProps> = React.forwardRef(
    (
        {
            type,
            label,
            width,
            leftIcon,
            rightIcon,
            props,
            placeholder,
            isBorder = true,
            margin,
            register,
            bgColor,
            errors,
            mask,
            pattern,
            disabled,
            defaultValue,
            isReadOnly = false,
            color,
            labelColor,
            cursor,
            labelProps,
            placeholderColor,
            fontWeight = '300',
            fontSize = '13px',
            fontFamily = fonts.poppins,
            textSize = '13px',
            height = '50px',
            editFunction,
            schema,
            errorMsg = '',
        },
        ref: LegacyRef<HTMLInputElement>,
    ) => {
        const [editable, setEditable] = useState(false);
        const [error, setError] = useState('');
        const [editedValue, setEditedValue] = useState(defaultValue);

        useEffect(() => {
            setError('');
        }, [editedValue]);

        useEffect(() => {
            if (defaultValue) {
                setEditedValue(defaultValue);
            }
        }, [defaultValue]);

        return (
            <Box w={width} textAlign="left" color="#fff" m={margin}>
                <FormControl isInvalid={!!error}>
                    <Text
                        textTransform={'capitalize'}
                        color={labelColor || 'brand'}
                        fontWeight={fontWeight}
                        fontSize={fontSize}
                        fontFamily={fontFamily}
                        {...labelProps}
                    >
                        {label}
                    </Text>
                    <InputGroup>
                        {leftIcon && <InputLeftElement h={height} w={height} children={leftIcon} />}
                        {type === 'tel' && (
                            <InputLeftAddon
                                h={height}
                                children="+1"
                                borderRadius="80px"
                                color="brand"
                                fontSize="14px"
                                borderWidth={isBorder ? '1px' : '0'}
                            />
                        )}
                        <Input
                            as={mask && InputMask}
                            mask={mask && mask}
                            // maskChar={null}
                            ref={ref}
                            type={type}
                            h={height}
                            cursor={cursor || 'text'}
                            bg={bgColor || '#F6F7FB'}
                            borderRadius="80px"
                            borderWidth={isBorder ? '1px' : '0'}
                            color={color || 'brand'}
                            placeholder={placeholder}
                            _placeholder={{
                                color: placeholderColor ? 'black' : null,
                            }}
                            readOnly={disabled || !editable}
                            // defaultValue={defaultValue}
                            value={editedValue}
                            onChange={e => setEditedValue(e.target.value)}
                            isReadOnly={isReadOnly}
                            fontWeight={fontWeight}
                            fontSize={textSize}
                            fontFamily={fontFamily}
                            {...register}
                            {...props}
                        />
                        {disabled ? null : (
                            <InputRightElement h="100%" width="auto" px="5px">
                                {editable ? (
                                    <Button
                                        color={colors.calendarGreen}
                                        fontWeight={300}
                                        rightIcon={<CheckIcon />}
                                        variant="link"
                                        fontSize="14px"
                                        onClick={() => {
                                            if (schema?.isValidSync(editedValue)) {
                                                setEditable(false);
                                                editFunction?.(editedValue || '');
                                            } else {
                                                setError(errorMsg);
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <IconButton
                                        variant="unstyled"
                                        aria-label="Edit field"
                                        d="flex"
                                        alignItems="center"
                                        onClick={() => setEditable(true)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </InputRightElement>
                        )}
                    </InputGroup>
                    {!!error && <FormErrorMessage fontSize={fontSize}>{error}</FormErrorMessage>}
                </FormControl>
            </Box>
        );
    },
);

CustomInputEdit.displayName = 'CustomInput';
