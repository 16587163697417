import React, { useState, useEffect } from 'react';
import { ModalBody, HStack } from '@chakra-ui/react';
import { FinishWorkPayload } from '../../../../pages/Activity/types';
import { useFieldArray, useForm } from 'react-hook-form';
import { AddressItem, AdditionalAddress, ShowingSummary, ConfirmHeader } from './components';
import { RequestInfoType, RequestAddressesType } from '../../../../pages/Requests/types';
import { CustomButton } from '../../index';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';

interface IPropertiesSelectionConfirmProps {
    onClose: () => void;
    requestAddresses: RequestAddressesType[];
    requestInfo: RequestInfoType;
    onFinishWork: (data: FinishWorkPayload) => void;
    finishWorkLoading: boolean;
}

export interface IFinishForm {
    duration?: number;
    requestAddresses: RequestAddressesType[];
    requestId: number;
}

export const PropertiesSelectionConfirm: React.FC<IPropertiesSelectionConfirmProps> = ({
    onClose,
    requestAddresses,
    requestInfo,
    onFinishWork,
    finishWorkLoading,
}) => {
    const [step, setStep] = useState(0);
    const [saveDraft, setSaveDraft] = useState<null | FinishWorkPayload>(null);
    const [addingAddressValue, setAddingAddressValue] = useState('');
    const [addingMode, setAddingMode] = useState(false);

    const { handleSubmit, control, reset } = useForm<IFinishForm>();

    const { pricePerDoor } = useSelector((state: RootStore) => state.activity.requestAddresses);

    const { fields, append } = useFieldArray({
        control,
        name: 'requestAddresses',
        keyName: 'address.id',
    });

    useEffect(() => {
        reset({ requestAddresses, requestId: requestInfo.id });
    }, [requestAddresses, reset]);

    const handleData = (data: FinishWorkPayload) => {
        setSaveDraft(data);
        setStep(1);
    };

    const onSubmit = () => {
        if (saveDraft !== null) {
            onFinishWork({ ...saveDraft, duration: 0 });
        }
    };

    const numOfProperties =
        saveDraft?.requestAddresses.filter(elem => elem.isCompleted).length || 0;

    return (
        <>
            {step === 1 ? (
                <>
                    <ConfirmHeader onClose={onClose} title="Payment Confirmation" />
                    <ShowingSummary
                        numOfProperties={numOfProperties}
                        onSubmit={onSubmit}
                        pricePerDoor={pricePerDoor}
                        finishWorkLoading={finishWorkLoading}
                    />
                </>
            ) : (
                <>
                    <ConfirmHeader
                        onClose={onClose}
                        title="Please confirm which properties were toured during this showing:"
                    />
                    <ModalBody w="100%">
                        {fields.map((address, index) => (
                            <AddressItem
                                key={address.id}
                                control={control}
                                address={address}
                                index={index}
                                requestId={requestInfo.id}
                            />
                        ))}
                        <AdditionalAddress
                            requestId={requestInfo.id}
                            addingAddressValue={addingAddressValue}
                            setAddingAddressValue={setAddingAddressValue}
                            addingMode={addingMode}
                            setAddingMode={setAddingMode}
                            append={append}
                        />
                        <HStack mt="20px">
                            <CustomButton
                                text="Cancel"
                                height="60px"
                                bgColor="white"
                                color="brand"
                                onClick={onClose}
                                borderColor="brand"
                            />
                            <CustomButton
                                text="Confirm"
                                onClick={handleSubmit(handleData)}
                                height="60px"
                                bgColor="brand"
                            />
                        </HStack>
                    </ModalBody>
                </>
            )}
        </>
    );
};
