import React, { FC } from 'react';

import { Box, Text } from '@chakra-ui/react';

import { fonts } from '../../../../utils';

import { colors } from '../../../../constants';

interface IProps {
    displayData: { label: string; value?: string };
    m?: string;
    w?: string;
}

export const OneOptionInfoElem: FC<IProps> = ({
    displayData: { label, value },
    m = '0 0 26px 0',
    w = '170px',
}) => {
    if (!value) return null;

    return (
        <Box w={w} textAlign="left" color="#fff" m={m}>
            <Text
                textTransform={'capitalize'}
                color={colors.secondaryFontDarker}
                fontSize="14px"
                fontFamily={fonts.poppins}
            >
                {label}
            </Text>

            <Text
                textTransform={'capitalize'}
                color={colors.brand}
                fontSize="16px"
                fontFamily={fonts.poppins}
            >
                {value}
            </Text>
        </Box>
    );
};
