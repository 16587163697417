import {
    Box,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Button,
    HStack,
    PinInput,
    PinInputField,
    IconButton,
    CloseButton,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { Controller, useForm } from 'react-hook-form';
import { BackArrow } from '../../../assets/icons';
import { CustomInput } from '../../../common/components';
import { colors } from '../../../common/constants';
import { ChangeEmailPayload, VerifyEmailPayload } from '../types';
import { countdownRenderer } from '../../../common/utils';
import { PayloadError } from '../../../common/types';
import { useDelta } from 'react-delta';

interface IEditEmailModal {
    isOpen: boolean;
    onClose: () => void;
    changeEmail: (v: ChangeEmailPayload) => void;
    verifyEmail: (v: VerifyEmailPayload) => void;
    isCodeVerified: boolean;
    clear: () => void;
    loading: boolean;
    stateErrors: PayloadError[];
}

export const EditEmailModal: React.FC<IEditEmailModal> = ({
    isOpen,
    onClose,
    changeEmail,
    verifyEmail,
    isCodeVerified,
    clear,
    stateErrors,
    loading,
}) => {
    const {
        formState: { errors },
        control,
        handleSubmit,
        register,
        watch,
        setValue,
        clearErrors,
    } = useForm({});
    const [codeSent, setCodeSent] = useState(false);

    const values = watch();

    const loadingDelta = useDelta(loading);

    const onSubmitEmail = (data: { email: string }) => {
        changeEmail({ newEmail: data.email });
        setCodeSent(true);
    };

    useEffect(() => {
        if (values?.emailCode?.length === 4) {
            setValue('emailCode', '');
            verifyEmail({ newEmail: values?.email, code: values?.emailCode });
        }
    }, [values]);

    useEffect(() => {
        if (!isOpen) {
            clearErrors(undefined);
            setCodeSent(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (loadingDelta && loadingDelta.prev) {
            if (loadingDelta.prev && !loadingDelta.curr && !stateErrors.length) {
                onClose();
                clear();
            }
        }
    }, [loadingDelta]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent
                borderRadius={'18px'}
                h={'85vh'}
                bgColor={colors.modalBackground}
                alignItems="center"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    px="30px"
                    pt="10px"
                >
                    <IconButton
                        onClick={onClose}
                        aria-label="Close modal"
                        variant="unstyled"
                        d="flex"
                        alignItems="center"
                    >
                        <BackArrow />
                    </IconButton>
                    <ModalHeader flex={1} align="center">
                        <Text color="brand" textAlign="center">
                            Edit Email
                        </Text>
                    </ModalHeader>
                    <CloseButton onClick={onClose} />
                </Flex>
                <ModalBody d="flex" flexDirection="column" overflowY="auto">
                    <form onSubmit={handleSubmit(onSubmitEmail)}>
                        <Flex direction="column" align="center" mt="30px">
                            <Flex mb="15px" width="100%">
                                <CustomInput
                                    label="Enter New Email"
                                    register={{ ...register('email') }}
                                    width="100%"
                                    placeholder="Enter New Email"
                                    type="email"
                                />
                            </Flex>
                            {!codeSent ? (
                                <Button variant="main" w="100%" type="submit">
                                    Send Code
                                </Button>
                            ) : (
                                <>
                                    <Text
                                        color="brand"
                                        fontWeight={600}
                                        fontSize="15px"
                                        mb="20px"
                                        alignSelf="baseline"
                                    >
                                        We Sent You A 4-Digit Security Code
                                    </Text>
                                    <Controller
                                        control={control}
                                        name="emailCode"
                                        render={({ field: { ref, ...restField } }) => (
                                            <HStack spacing="15px">
                                                <PinInput
                                                    placeholder="-"
                                                    autoFocus={true}
                                                    {...restField}
                                                >
                                                    <PinInputField
                                                        w="65px"
                                                        h="65px"
                                                        borderRadius="15px"
                                                        ref={ref}
                                                        bgColor="white"
                                                    />
                                                    <PinInputField
                                                        w="65px"
                                                        h="65px"
                                                        borderRadius="15px"
                                                        bgColor="white"
                                                    />
                                                    <PinInputField
                                                        w="65px"
                                                        h="65px"
                                                        borderRadius="15px"
                                                        bgColor="white"
                                                    />
                                                    <PinInputField
                                                        w="65px"
                                                        h="65px"
                                                        borderRadius="15px"
                                                        bgColor="white"
                                                    />
                                                </PinInput>
                                            </HStack>
                                        )}
                                    />
                                    <Text
                                        color="green"
                                        fontWeight={600}
                                        fontSize="18px"
                                        lineHeight="18px"
                                        mt="30px"
                                    >
                                        <Countdown
                                            renderer={countdownRenderer}
                                            date={Date.now() + 2 * 60 * 1000}
                                        />
                                    </Text>
                                    <Button
                                        width="100%"
                                        height="60px"
                                        variant="main"
                                        mt="30px"
                                        onClick={() => changeEmail({ newEmail: values?.email })}
                                    >
                                        Resend
                                    </Button>
                                </>
                            )}
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
