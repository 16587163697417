import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BalanceTab } from './BalanceTab';
import {
    getCustomerAccountStatusRequest,
    getDwollaWalletBalanceRequest,
    getFundingSourcesRequest,
    updateCustomerRequest,
} from '../../store/actions';
import { RootStore } from '../../../../../../store';
import {
    EditDwollaAccountModal,
    GetPaidModal,
    RechargeModal,
    UpdateDwollaAccountModal,
    UploadLicensePhotoModal,
} from '../../modals';
import { useDelta } from 'react-delta';
import {CreateDwollaAccountPayload, CustomerStatus} from '../../types';

interface IBalanceTabContainerProps {
    tabIndex: number;
}

export const BalanceTabContainer: React.FC<IBalanceTabContainerProps> = ({ tabIndex }) => {
    const [showEditAccountModal, setShowEditAccountModal] = useState(false);
    const [showUpdateAccountModal, setShowUpdateModal] = useState(false);
    const [showUploadLicenseModal, setShowUploadLicenseModal] = useState(false);
    const [showRechargeModal, setShowRechargeModal] = useState(false);
    const [showGetPaidModal, setShowGetPaidModalModal] = useState(false);

    const dispatch = useDispatch();

    const { loading: walletBalanceLoading, value: walletBalance } = useSelector(
        (state: RootStore) => state.payments.dwollaWalletBalance,
    );
    const { loading: accountStatusLoading, value: accountStatus } = useSelector(
        (state: RootStore) => state.payments.getCustomerAccountStatus,
    );
    const { loading: editDwollaAccountLoading, errors: editDwollaAccountErrors } = useSelector(
        (state: RootStore) => state.payments.editDwollaAccount,
    );
    const { loading: updateDwollaAccountLoading, errors: updateDwollaAccountErrors } = useSelector(
        (state: RootStore) => state.payments.updateDwollaAccount,
    );
    const { loading: uploadNewDocumentLoading, errors: uploadNewDocumentError } = useSelector(
        (state: RootStore) => state.payments.uploadNewDocument,
    );
    const { loading: fundingSourcesLoading, items: fundingSources } = useSelector(
        (state: RootStore) => state.payments.fundingSources,
    );
    const { loading: rechargeBalanceLoading, errors: rechargeBalanceError } = useSelector(
        (state: RootStore) => state.payments.createUserToSelfTransfer,
    );

    const editDwollaAccountDelta = useDelta(editDwollaAccountLoading);
    const updateDwollaAccountDelta = useDelta(updateDwollaAccountLoading);
    const uploadNewDocumentDelta = useDelta(uploadNewDocumentLoading);
    const rechargeBalanceDelta = useDelta(rechargeBalanceLoading);

    const onUpdateDwollaAccount = (payload: CreateDwollaAccountPayload) => {
        dispatch(updateCustomerRequest(payload));
    };

    useEffect(() => {
        if (tabIndex === 0) {
            dispatch(getCustomerAccountStatusRequest());
        }
    }, [tabIndex]);
    useEffect(() => {
        if (tabIndex === 0 && accountStatus === CustomerStatus.Verified) {
            dispatch(getDwollaWalletBalanceRequest());
        }
    }, [accountStatus, tabIndex]);

    useEffect(() => {
        if (showRechargeModal || showGetPaidModal) {
            dispatch(getDwollaWalletBalanceRequest());
            dispatch(getFundingSourcesRequest(false));
        }
    }, [showRechargeModal, showGetPaidModal]);

    useEffect(() => {
        if (
            !editDwollaAccountDelta?.prev &&
            editDwollaAccountDelta?.curr &&
            !editDwollaAccountErrors.length
        ) {
            setShowEditAccountModal(false);
        }
    }, [editDwollaAccountDelta]);
    useEffect(() => {
        if (
            !updateDwollaAccountDelta?.prev &&
            updateDwollaAccountDelta?.curr &&
            !updateDwollaAccountErrors.length
        ) {
            setShowUpdateModal(false);
        }
    }, [updateDwollaAccountDelta]);
    useEffect(() => {
        if (
            !uploadNewDocumentDelta?.prev &&
            uploadNewDocumentDelta?.curr &&
            !uploadNewDocumentError.length
        ) {
            setShowUploadLicenseModal(false);
        }
    }, [uploadNewDocumentDelta]);
    useEffect(() => {
        if (
            !rechargeBalanceDelta?.prev &&
            rechargeBalanceDelta?.curr &&
            !rechargeBalanceError.length
        ) {
            setShowRechargeModal(false);
            setShowGetPaidModalModal(false);
        }
    }, [rechargeBalanceDelta]);

    return (
        <>
            <BalanceTab
                setShowGetPaidModalModal={setShowGetPaidModalModal}
                setShowRechargeModal={setShowRechargeModal}
                setShowUploadLicenseModal={setShowUploadLicenseModal}
                setShowUpdateModal={setShowUpdateModal}
                setShowEditAccountModal={setShowEditAccountModal}
                accountStatus={accountStatus}
                accountStatusLoading={accountStatusLoading}
                walletBalance={walletBalance}
                walletBalanceLoading={walletBalanceLoading}
            />
            {/* Edit Account Modal*/}
            <EditDwollaAccountModal
                editDwollaAccountLoading={editDwollaAccountLoading}
                isOpen={showEditAccountModal}
                title={'Edit Account'}
                onClose={() => {
                    setShowEditAccountModal(false);
                }}
            />
            {/* Update Dwolla Account Modal If Retry*/}
            <UpdateDwollaAccountModal
                isOpen={showUpdateAccountModal}
                title={'Create Account'}
                onClose={() => {
                    setShowUpdateModal(false);
                }}
                onUpdateDwollaAccount={onUpdateDwollaAccount}
                updateDwollaAccountLoading={updateDwollaAccountLoading}
            />
            {/* Upload License Photo Modal*/}
            <UploadLicensePhotoModal
                uploadNewDocumentLoading={uploadNewDocumentLoading}
                isOpen={showUploadLicenseModal}
                title={'Upload License Photo'}
                onClose={() => {
                    setShowUploadLicenseModal(false);
                }}
            />
            {/*Recharge Modal*/}
            <RechargeModal
                rechargeBalanceLoading={rechargeBalanceLoading}
                walletBalanceLoading={walletBalanceLoading}
                walletBalance={walletBalance}
                fundingSourcesLoading={fundingSourcesLoading}
                fundingSources={fundingSources}
                isOpen={showRechargeModal}
                title={'Recharge'}
                onClose={() => {
                    setShowRechargeModal(false);
                }}
            />
            {/* Get Paid Modal */}
            <GetPaidModal
                isOpen={showGetPaidModal}
                title={'Get Paid'}
                onClose={() => setShowGetPaidModalModal(false)}
                walletBalanceLoading={walletBalanceLoading}
                fundingSourcesLoading={fundingSourcesLoading}
                walletBalance={walletBalance}
                fundingSources={fundingSources}
                rechargeBalanceLoading={rechargeBalanceLoading}
            />
        </>
    );
};
