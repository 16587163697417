import React from 'react';
import { colors } from '../../common/constants';

export const WalletIcon: React.FC = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5 6V2.5C13.5 1.94772 13.0523 1.5 12.5 1.5L1.5 1.5C0.947716 1.5 0.5 1.94772 0.5 2.5L0.5 12.5C0.5 13.0523 0.947715 13.5 1.5 13.5L12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5V9M14.4307 5.5H9.5C8.39543 5.5 7.5 6.39543 7.5 7.5C7.5 8.60457 8.39543 9.5 9.5 9.5H14.4307C14.469 9.5 14.5 9.46897 14.5 9.43069V5.56931C14.5 5.53103 14.469 5.5 14.4307 5.5Z"
            stroke={colors.brand}
        />
    </svg>
);
