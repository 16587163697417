import { transformAddress, Alerter } from '../../../../../common/utils';
import { AddressLocationType, GeoPositionShortType } from '../../../../../common/types';

export const getAddressFromCoords = (
    lat: number,
    lng: number,
    onGetAddress: (obj: AddressLocationType) => void,
    onGetCoords: (obj: GeoPositionShortType) => void,
    optionalSave?: () => void,
) => {
    var latlng = new google.maps.LatLng(lat, lng);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
            Alerter.error(`Google goeocode error. Status: ${status}`, 2000);
        }
        if (status == google.maps.GeocoderStatus.OK) {
            onGetAddress(transformAddress(results?.[0]?.address_components));
            onGetCoords({
                lat,
                lng,
            });
            optionalSave?.();
        }
    });
};
