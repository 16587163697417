import React, { FC } from 'react';

export const IWillPayAgentForHostingIcon: FC = () => (
    <svg width="15" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7.5" cy="8" r="7.5" fill="#2C335C" />
        <path
            d="M7.506 6.223a.712.712 0 0 1-.5-.186.648.648 0 0 1-.194-.477c0-.188.064-.344.194-.468a.695.695 0 0 1 .5-.194c.2 0 .364.065.493.194a.61.61 0 0 1 .202.468.635.635 0 0 1-.202.477.683.683 0 0 1-.493.186Zm.558.533v4.474H6.933V6.756h1.13Z"
            fill="#fff"
        />
    </svg>
);
