import { Button, Flex, Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BackButton } from '../../common/components';
import { DashboardHeader } from '../../common/components/blocks/DashboardWrapper/DashboardHeader';
import { HostOpenHouseSearch } from './components/HostOpenHouseSearch';
import { Text } from '@chakra-ui/react';
import { GetFilteredOpenHousesPayload, OpenHousesType } from './types';
import { HostOpenHouseListingSection } from './components/HostOpenHouseListingSection';
import { BackArrow } from '../../assets/icons';
import { IdNameTypes, ListingsType, OpenHouseDetailsOpenConfig } from '../../common/types';

interface IHostOpenHouseProps {
    loading: boolean;
    myAgenciesLoading: boolean;
    onStartLoadMyListings: () => void;
    openHouses: ListingsType[];
    onGetMyAgencyOpenHouses: (data: GetFilteredOpenHousesPayload) => void;
    onGetPartnerAgencyOpenHouses: (data: GetFilteredOpenHousesPayload) => void;
    onGetListingTypes: () => void;
    listingTypesLoading: boolean;
    listingTypes: IdNameTypes[];
    myAgencyOpenHousesTotalCount: number;
    partnerAgenciesLoading: boolean;
    partnerAgencyOpenHousesTotalCount: number;
    partnerAgencyOpenHouses: Array<ListingsType>;
    setToShowListingId: (value: number) => void;
    setShowOpenhouseInfoModal: (value: OpenHouseDetailsOpenConfig) => void;
    setShowListingInfoModal: (value: boolean) => void;
}

export const HostOpenHouse: React.FC<IHostOpenHouseProps> = ({
    loading,
    onStartLoadMyListings,
    onGetListingTypes,
    onGetMyAgencyOpenHouses,
    onGetPartnerAgencyOpenHouses,
    myAgenciesLoading,
    myAgencyOpenHousesTotalCount,
    openHouses,
    partnerAgenciesLoading,
    partnerAgencyOpenHousesTotalCount,
    partnerAgencyOpenHouses,
    setToShowListingId,
    setShowOpenhouseInfoModal,
    setShowListingInfoModal,
    listingTypes,
}) => {
    const [showAllListings, setShowAllListings] = useState(false);

    const onOpenModal = (
        value: number,
        listing: ListingsType,
        isMyListings: boolean,
        isManageOpenHouse: boolean,
    ) => {
        setToShowListingId(value);
        if (listing.isOpenHouse) {
            setShowOpenhouseInfoModal({
                isOpen: true,
                listingStatusId: listing.listingStatusId || 0,
                isMyListings,
                isManageOpenHouse,
            });
        } else {
            setShowListingInfoModal(true);
        }
    };

    useEffect(() => {
        onStartLoadMyListings();
        onGetMyAgencyOpenHouses({
            pageNumber: 1,
            pageSize: 10,
        });
        onGetListingTypes();
    }, []);

    useEffect(() => {
        if (myAgencyOpenHousesTotalCount !== 0) {
            onGetMyAgencyOpenHouses({
                pageNumber: 1,
                pageSize: myAgencyOpenHousesTotalCount,
            });
        }
    }, [myAgencyOpenHousesTotalCount]);

    useEffect(() => {
        if (myAgencyOpenHousesTotalCount !== 0) {
            onGetPartnerAgencyOpenHouses({
                pageNumber: 1,
                pageSize: partnerAgencyOpenHousesTotalCount,
            });
        }
    }, [partnerAgencyOpenHousesTotalCount]);

    return (
        <Flex backgroundColor="brand" flexDirection="column" alignItems="center" h="100vh">
            <DashboardHeader />
            <Flex
                bgColor="#FFFFFF"
                mt="15px"
                flexDirection="column"
                borderRadius="10px"
                p="25px 50px"
                w="1000px"
                h="90vh"
                overflowY="auto"
            >
                {showAllListings ? (
                    <>
                        <Flex flexDir="column" h="100%">
                            <Button
                                leftIcon={<BackArrow />}
                                variant="unstyled"
                                width="35px"
                                mt="4px"
                                onClick={() => setShowAllListings(false)}
                            />
                            <Text
                                fontSize="18px"
                                lineHeight="25px"
                                color="brand"
                                fontWeight={600}
                                mt="30px"
                                ml="5px"
                            >
                                My Agency
                            </Text>
                            <HostOpenHouseListingSection
                                loading={myAgenciesLoading}
                                loadMoreLoading={loading}
                                items={openHouses}
                                onOpenModal={onOpenModal}
                            />
                            <Text
                                fontSize="18px"
                                lineHeight="25px"
                                color="brand"
                                fontWeight={600}
                                mt="30px"
                                ml="5px"
                            >
                                Partner Agencies
                            </Text>
                            <HostOpenHouseListingSection
                                loading={partnerAgenciesLoading}
                                loadMoreLoading={loading}
                                items={partnerAgencyOpenHouses}
                                onOpenModal={onOpenModal}
                            />
                        </Flex>
                    </>
                ) : (
                    <>
                        <Flex alignItems="center">
                            <BackButton text="" />
                            <Text
                                fontSize="24px"
                                lineHeight="25px"
                                color="brand"
                                fontWeight="bold"
                                ml="22px"
                                mb="15px"
                            >
                                Host An Open House
                            </Text>
                        </Flex>
                        <HostOpenHouseSearch
                            onGetMyAgencyOpenHouses={onGetMyAgencyOpenHouses}
                            myAgenciesLoading={myAgenciesLoading}
                            listingTypes={listingTypes}
                        />
                        <Flex alignItems="flex-end" mb="5px">
                            <Text fontSize="20px" lineHeight="25px" color="brand" mt="30px">
                                Available Listings
                            </Text>
                            <Text
                                fontSize="14px"
                                lineHeight="25px"
                                color="brand"
                                mt="30px"
                                ml="25px"
                                textDecoration="underline"
                                as="button"
                                onClick={() => setShowAllListings(true)}
                            >
                                Show All
                            </Text>
                        </Flex>
                        <Flex overflowY="auto" w="100%" h="100%">
                            <HostOpenHouseListingSection
                                loading={loading}
                                loadMoreLoading={false}
                                items={openHouses}
                                onOpenModal={onOpenModal}
                            />
                        </Flex>
                    </>
                )}
            </Flex>
        </Flex>
    );
};
