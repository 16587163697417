import React, { useState } from 'react';
import { CustomButton } from '../../../../../common/components';
import { AddressLocationType, GeoPositionShortType } from '../../../../../common/types';
import { setLocation } from '../store/actions';
import { useDispatch } from 'react-redux';
import { Text, Center } from '@chakra-ui/react';
import { getAddressFromCoords } from '../helpers/getAddressFromCoords';
import { Alerter } from '../../../../../common/utils';

interface ISaveCurrentLocation {
    onGetCoords: (obj: GeoPositionShortType) => void;
    onGetAddress: (obj: AddressLocationType) => void;
    regionPosition: GeoPositionShortType;
    address: AddressLocationType;
    saveLoading: boolean;
}

export const SaveCurrentLocation: React.FC<ISaveCurrentLocation> = ({
    onGetAddress,
    onGetCoords,
    address,
    regionPosition,
    saveLoading,
}) => {
    const dispatch = useDispatch();

    const [userDeniedAccess, setUserDeniedAccess] = useState(false);

    const onSaveLocation = () => {
        dispatch(
            setLocation({
                checkBox: 1,
                longitude: regionPosition.lng,
                latitude: regionPosition.lat,
                ...address,
            }),
        );
    };

    return (
        <>
            <Center>
                <CustomButton
                    text="Save"
                    bgColor="brand"
                    height="50px"
                    width="300px"
                    isLoading={saveLoading}
                    onClick={() => {
                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(
                                position => {
                                    getAddressFromCoords(
                                        position.coords.latitude,
                                        position.coords.longitude,
                                        onGetAddress,
                                        onGetCoords,
                                        onSaveLocation,
                                    );
                                },
                                error => {
                                    if (error.code === 1) {
                                        setUserDeniedAccess(true);
                                    }
                                },
                            );
                        } else {
                            Alerter.info('Geolocation is not supported by this browser.', 2000);
                        }
                    }}
                />
            </Center>
            {userDeniedAccess && (
                <Text color="chakraErrorRed">
                    Please check permissions for location and try again
                </Text>
            )}
        </>
    );
};
