import React from 'react';
import { colors } from '../../common/constants';

export const PagginationRightArrow: React.FC = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.9766 9.99999L6.85159 14.125L8.02992 15.3033L13.3333 9.99999L8.02992 4.69666L6.85159 5.87499L10.9766 9.99999Z"
            fill={colors.brand}
        />
    </svg>
);
