import React from 'react';
import {
    Box,
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Checkbox,
    VStack,
    Flex,
    Button,
} from '@chakra-ui/react';
import { FilterIcon } from '../../../../../assets/icons';

interface ITestSearchMenuProps {
    typeIdsSelected: number[];
    setTypeIdsSelected: (value: number[]) => void;
    menuValues: { label: string; value: number }[];
}

export const TestSearchMenu: React.FC<ITestSearchMenuProps> = ({
    menuValues,
    setTypeIdsSelected,
    typeIdsSelected,
}) => {
    const onChangeFilter = (checked: boolean, value: number) => {
        if (checked) {
            setTypeIdsSelected([...typeIdsSelected, value]);
        } else {
            setTypeIdsSelected(typeIdsSelected.filter(elem => elem !== value));
        }
    };

    return (
        <Popover placement="auto-start">
            <PopoverTrigger>
                <Flex
                    bg="white"
                    borderRadius="20px"
                    height="50px"
                    width="100%"
                    align="center"
                    px="20px"
                    justifyContent="flex-start"
                    fontWeight="normal"
                    as={Button}
                >
                    <FilterIcon />
                    <Text fontSize="14px" lineHeight="21px" color="secondaryFontDarker" ml="20px">
                        {typeIdsSelected.length
                            ? menuValues
                                  .filter(elem => typeIdsSelected.includes(elem.value))
                                  .map(elem => elem.label)
                                  .join(', ')
                            : 'Filter'}
                    </Text>
                </Flex>
            </PopoverTrigger>
            <PopoverContent borderRadius="20px">
                <VStack align="flex-start" p="15px">
                    {menuValues.map(elem => (
                        <Checkbox
                            key={elem.value}
                            defaultIsChecked={elem.value === 0}
                            checked={typeIdsSelected.includes(elem.value)}
                            onChange={ev => onChangeFilter(ev.target.checked, elem.value)}
                            colorScheme="checkboxScheme"
                        >
                            <Text fontSize="14px" lineHeight="21px" color="secondaryFontDarker">
                                {elem.label}
                            </Text>
                        </Checkbox>
                    ))}
                </VStack>
            </PopoverContent>
        </Popover>
    );
};
