import React, { Dispatch, SetStateAction } from 'react';
import {
    Box,
    Flex,
    Image,
    Text,
    Link,
} from '@chakra-ui/react';

import { baseUrl } from '../../../../common/api/api.base';

import { AdvertisingPostTypeResponse } from '../../types';

import RedStar from '../../../../../src/assets/img/RedStar.png';

interface IPostBoxProps {
    item: AdvertisingPostTypeResponse;
    setDetailsPostId: Dispatch<SetStateAction<number>>;
}

export const AdvertisingPost: React.FC<IPostBoxProps> = ({ item, setDetailsPostId }) => {
    return (
        <Box borderRadius={'16px'} overflow={'hidden'} bgColor={'#FFFFFF'}>
            <Flex height={'35px'} alignItems="center" justifyContent="center" bgColor={'#FEDEE5'}>
                <Image src={RedStar} alt="Red Star" />
                <Text marginLeft={'10px'} textTransform={'uppercase'} color={'#E65B79'}>
                    Recommended
                </Text>
            </Flex>
            {item.link ? (
                <Link target={"_blank"} href={item.link || '#'} padding={'20px 16px 0 16px'} display={'block'}>
                    <Image
                        w={'100%'}
                        h={'200px'}
                        borderRadius={'16px'}
                        objectFit={'cover'}
                        alt={'advertising post image'}
                        src={`${baseUrl}/api/storage/static-images/${item.photo.itemHash}${item.photo.itemExtension}`}
                    />
                </Link>
            ) : (
                <Box padding={'20px 16px 0 16px'}>
                    <Image
                        w={'100%'}
                        h={'200px'}
                        borderRadius={'16px'}
                        objectFit={'cover'}
                        alt={'advertising post image'}
                        src={`${baseUrl}/api/storage/static-images/${item.photo.itemHash}${item.photo.itemExtension}`}
                    />
                </Box>
            )}
            <Box
                onClick={() => setDetailsPostId(item.id)}
                padding={'16px 16px 25px 16px'}
                cursor={'pointer'}
            >
                <Text fontWeight={500} letterSpacing={'0.02em'} color={'#16192C'}>
                    {item.title}
                </Text>
            </Box>
        </Box>
    );
};
