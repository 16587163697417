import React from 'react';
import {
    Modal,
    ModalOverlay,
    CloseButton,
    Box,
    Text,
    ModalContent,
    ModalBody,
    Flex,
} from '@chakra-ui/react';
import { CustomButton } from '../../blocks';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { toggleReloginAlertModal } from '../../../../pages/Settings/pages/Payments/store/actions';
import { logout } from '../../../../store/actions/auth/auth.actions';

export const ReloginAlertModal: React.FC = () => {
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(toggleReloginAlertModal(false));
        dispatch(logout());
    };

    const reloginAlertModalOpen = useSelector(
        (state: RootStore) => state.payments.reloginAlertModalOpen,
    );

    return (
        <Modal
            isOpen={reloginAlertModalOpen}
            onClose={onClose}
            isCentered
            size="2xl"
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent borderRadius={'18px'} bgColor="white" alignItems="center">
                <Box alignSelf="flex-end" margin="20px 20px 0 0">
                    <CloseButton onClick={onClose} />
                </Box>
                <ModalBody px="100px">
                    <Flex direction="column" align="center">
                        <Text color="brand" fontSize="18px" textAlign="center" my="50px">
                            Your payment account has been created and you will now be logged out.
                        </Text>
                        <CustomButton
                            text="OK"
                            onClick={onClose}
                            height="60px"
                            width="300px"
                            bgColor="brand"
                            mb="50px"
                        />
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
