import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { RootStore } from '../../../../../store';
import { SubmitHandler } from 'react-hook-form';
import { IAuthContainerProps } from '../../../../../common/types';
import { SignInEnterEmail } from './SignInEnterEmail';
import { ChangePassword } from '../../types';
import { changePassword } from '../../store/actions';

export const SignInEnterEmailContainer: React.FC<IAuthContainerProps> = ({ nestedPath }) => {
    const loading = useSelector((state: RootStore) => state.auth.loading);

    const dispatch = useDispatch();
    const getChangePassword: SubmitHandler<ChangePassword> = payload => dispatch(changePassword(payload));

    return <SignInEnterEmail loading={loading} getChangePassword={getChangePassword} />;
};
