import React, { useEffect } from 'react';
import { EditDwollaAccountForm, PaymentsModalWrapper } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { getStates } from '../../../../../store/actions/directories.actions';
import { getCustomerGeneralInfoRequest } from '../store/actions';
import { RootStore } from '../../../../../store';

interface IEditDwollaAccountModalProps {
    isOpen: boolean;
    title: string;
    onClose: () => void;
    editDwollaAccountLoading: boolean;
}

export const EditDwollaAccountModal: React.FC<IEditDwollaAccountModalProps> = ({
    isOpen,
    title,
    onClose,
    editDwollaAccountLoading,
}) => {
    const dispatch = useDispatch();
    const states = useSelector((state: RootStore) => state.directories.states);
    const { loading: generalInfoLoading, data: generalInfoData } = useSelector(
        (state: RootStore) => state.payments.customerGeneralInfo,
    );

    useEffect(() => {
        if (isOpen) {
            dispatch(getCustomerGeneralInfoRequest());
            dispatch(getStates());
        }
    }, [isOpen]);

    return (
        <PaymentsModalWrapper isOpen={isOpen} title={title} onClose={onClose}>
            <EditDwollaAccountForm
                editDwollaAccountLoading={editDwollaAccountLoading}
                generalInfoLoading={generalInfoLoading}
                generalInfoData={generalInfoData}
                states={states}
            />
        </PaymentsModalWrapper>
    );
};
