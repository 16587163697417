import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { dispatch, RootStore } from '../../../../../store';
import { SignUpUploadPhoto } from './SignUpUploadPhoto';
import { SubmitHandler } from 'react-hook-form';
import { SignUpUploadLicensePayload } from '../../types';
import { getLicensesDraft, signUpAddRealEsateLicencePhoto } from '../../store/actions';
import { IAuthContainerProps } from '../../../../../common/types';

export const SignUpUploadRealEstateLicense: React.FC<IAuthContainerProps> = () => {
    const loading = useSelector((state: RootStore) => state.signUp.loading);
    const errors = useSelector((state: RootStore) => state.signUp.errors);
    const accessToken = useSelector((state: RootStore) => state.signUp.accessToken);
    const licenseDraft = useSelector((state: RootStore) => state.signUp.license);
    const isAddedDraftAgency = useSelector((state: RootStore) => state.signUp.isAddedDraftAgency);

    const dispatch = useDispatch();
    const onAddRealEstateLicencePhoto: SubmitHandler<SignUpUploadLicensePayload> = payload =>
        dispatch(signUpAddRealEsateLicencePhoto(payload));
    const onGetLicensesDraft = () => dispatch(getLicensesDraft(accessToken));

    return (
        <SignUpUploadPhoto
            label="Add Real Estate License"
            loading={loading}
            stateErrors={errors}
            onSubmit={onAddRealEstateLicencePhoto}
            token={accessToken}
            infoText={'Please provide a copy of your state-issued real estate license'}
            isAddedDraftAgency={isAddedDraftAgency}
            onGetLicensesDraft={onGetLicensesDraft}
            licenseDraft={licenseDraft}
        />
    );
};
