import React from 'react';
import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import { fonts } from '../../../utils';
import { colors } from '../../../constants';

interface IListingDetailsSummaryProps {
    title: string;
    stringData?: string;
    values?: { name: string; value: string | number | null }[];
}

export const ListingDetailsSummary: React.FC<IListingDetailsSummaryProps> = ({
    stringData,
    title,
    values,
}) => {
    const splitString = stringData?.split('|');
    const mappedString = splitString?.map(item => item.split(':'));

    if (stringData || values?.length) {
        return (
            <>
                <Text
                    mt="30px"
                    color={colors.brand}
                    fontWeight={700}
                    fontSize="16px"
                    lineHeight="24px"
                    fontFamily={fonts.poppins}
                >
                    {title}
                </Text>
                <SimpleGrid columns={3} background="white" borderRadius="20px" padding="20px">
                    {stringData
                        ? mappedString?.map((elem, index) => (
                              <Box key={index} minWidth="100px" mb="10px" mr="20px">
                                  <Text color="secondaryFontDarker" fontSize="14px">
                                      {elem[0]}:
                                  </Text>
                                  <Text fontSize="16px" color="brand">
                                      {elem[1]}
                                  </Text>
                              </Box>
                          ))
                        : values?.map((elem, index) =>
                              elem.value ? (
                                  <Box key={index} minWidth="100px" mb="10px" mr="20px">
                                      <Text color="secondaryFontDarker" fontSize="14px">
                                          {elem.name}:
                                      </Text>
                                      <Text fontSize="16px" color="brand">
                                          {elem.value}
                                      </Text>
                                  </Box>
                              ) : null,
                          )}
                </SimpleGrid>
            </>
        );
    } else {
        return null;
    }
};
