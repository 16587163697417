import React from 'react';
import { Contact } from './Contact';
import { useDispatch, useSelector } from 'react-redux';
import { sendContactForm, toggleLandingVideoModal } from '../../../store/actions/landing.actions';
import { SendContactFormType } from '../../../common/types';
import { RootStore } from '../../../store';

export const ContactContainer: React.FC = () => {
    const dispatch = useDispatch();

    const openVideo = () => dispatch(toggleLandingVideoModal(true));
    const onSendContactForm = (data: SendContactFormType) => dispatch(sendContactForm(data));

    const sendingContactLoading = useSelector(
        (state: RootStore) => state.landing.sendingContactLoading,
    );

    return (
        <Contact
            openVideo={openVideo}
            onSendContactForm={onSendContactForm}
            sendingContactLoading={sendingContactLoading}
        />
    );
};
