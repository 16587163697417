import * as yup from 'yup';
import { ErrorMessages } from './messages';
import { yupResolver } from '@hookform/resolvers/yup';

const cooperationDetailsSchema = yup.object().shape({
    AgencyName: yup.string().required(ErrorMessages.REQUIRED),
    YourName: yup.string().required(ErrorMessages.REQUIRED),
    ContactEmail: yup.string().required(ErrorMessages.REQUIRED).email(ErrorMessages.EMAIL_INVALID),
    StateId: yup.string().required(ErrorMessages.REQUIRED),
    PartnerAgencyName: yup.string().required(ErrorMessages.REQUIRED),
    PartnerName: yup.string().required(ErrorMessages.REQUIRED),
    PartnerContactPhone: yup.string().required(ErrorMessages.REQUIRED),
    ContactPhone: yup.string().required(ErrorMessages.REQUIRED),
    PartnerContactEmail: yup
        .string()
        .required(ErrorMessages.REQUIRED)
        .email(ErrorMessages.EMAIL_INVALID),
    TypeOfCooperation: yup.array().min(1, ErrorMessages.AT_LEAST_1_VALUE),
});

export const cooperationDetailsResolver = yupResolver(cooperationDetailsSchema);
