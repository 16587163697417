import React from 'react';
import { Box, Flex, Text, ModalBody } from '@chakra-ui/react';
import {
    CustomButton,
    CustomInput,
    RequestQuickInfo,
    Loader,
    CustomDropdown,
} from '../../../../../common/components';
import { RequestInfoType } from '../../../types';
import { fonts } from '../../../../../common/utils';
import { yesOrNo, getInspectionItems } from '../../../../../common/constants/dropdown.consatnts';
import { RequestModalHeader } from '../AgencyRequestInfoModal';
import { IdNameTypes } from '../../../../../common/types';

interface IHireAssistanceSummary {
    loading: boolean;
    requestInfo: RequestInfoType;
    clientTypes: IdNameTypes[];
    bonusTypes: IdNameTypes[];
    setToCancelWorkId: (value: number) => void;
    onClose: () => void;
}

export const HireAssistanceSummary: React.FC<IHireAssistanceSummary> = ({
    loading,
    requestInfo,
    clientTypes,
    bonusTypes,
    setToCancelWorkId,
    onClose,
}) => (
    <>
        <RequestModalHeader title={requestInfo.requestTypeName} onClose={onClose} />
        <ModalBody d="flex" flexDirection="column" w="100%" px="70px">
            {loading ? (
                <Loader spinnerSize="md" centerHeight="100%" />
            ) : (
                <Flex flexDirection="column" flex={1}>
                    <Flex justifyContent="space-between">
                        <Box flex={1} pr="20px">
                            <RequestQuickInfo
                                date={requestInfo.dateTime}
                                fromTime={requestInfo.fromTime}
                                duration={requestInfo.duration}
                                propertiesToShow={requestInfo.propertiesToShow}
                                requestTypeName={requestInfo.requestTypeName}
                                totalPrice={null}
                                margin={0}
                            />
                            <Box mt={6}>
                                {requestInfo.states &&
                                    requestInfo.typeId !== 7 &&
                                    requestInfo.states.map((state: string) => (
                                        <CustomInput
                                            key={state}
                                            placeholder="State"
                                            label="State"
                                            register={{ value: state }}
                                            isBorder={false}
                                            isReadOnly
                                            margin="0 0 26px 0"
                                            bgColor="white"
                                        />
                                    ))}
                            </Box>
                            {requestInfo.addresses && requestInfo.addresses.length ? (
                                <Text
                                    color="brand"
                                    fontWeight={700}
                                    fontSize="16px"
                                    lineHeight="24px"
                                    fontFamily={fonts.poppins}
                                >
                                    {requestInfo.addresses.length > 1 ? 'Addresses' : 'Address'}
                                </Text>
                            ) : null}
                            {requestInfo.addresses &&
                                requestInfo.addresses.map((address: string) => (
                                    <CustomInput
                                        key={address}
                                        placeholder="Address"
                                        label=""
                                        register={{ value: address }}
                                        isReadOnly
                                        bgColor="white"
                                    />
                                ))}
                            {!!requestInfo.generalNote && (
                                <>
                                    <Text
                                        color="brand"
                                        fontWeight={700}
                                        fontSize="18px"
                                        mt={5}
                                        lineHeight="18px"
                                        fontFamily={fonts.montserrat}
                                    >
                                        General Notes:
                                    </Text>
                                    <Text
                                        color="secondaryFontDarker"
                                        fontWeight={400}
                                        fontSize="16px"
                                        mt={2}
                                        lineHeight="26px"
                                        fontFamily={fonts.poppins}
                                    >
                                        {requestInfo.generalNote}
                                    </Text>
                                </>
                            )}

                            {/* <CustomDropdown
                            label="The Client is:"
                            register={{ value: requestInfo.clientTypeId, isReadOnly: true }}
                            options={clientTypes.map((elem, index) => ({
                                label: elem.name,
                                value: elem.id,
                                id: index,
                            }))}
                            errors=""
                            margin="0 0 26px 0"
                        /> */}
                        </Box>
                        <Box flex={1} pl="20px">
                            {requestInfo.typeId !== 4 ? (
                                <CustomDropdown
                                    label={`Is the ${requestInfo.requestTypeName} scheduled?`}
                                    options={getInspectionItems(requestInfo.requestTypeName)}
                                    register={{
                                        value: requestInfo.isShowingScheduled
                                            ? `Yes, I Have Scheduled The ${requestInfo.requestTypeName}`
                                            : `No, the Agent Will Schedule The ${requestInfo.requestTypeName}`,
                                        isReadOnly: true,
                                        pointerEvents: 'none',
                                    }}
                                    errors=""
                                    margin="0 0 26px 0"
                                    bgColor="white"
                                />
                            ) : (
                                <CustomDropdown
                                    label="Is access to The Property scheduled?"
                                    options={yesOrNo}
                                    register={{
                                        value: requestInfo.isShowingScheduled ? 'Yes' : 'No',
                                        isReadOnly: true,
                                        pointerEvents: 'none',
                                    }}
                                    errors=""
                                    margin="0 0 10px 0"
                                />
                            )}
                            {requestInfo.typeId === 1 ? (
                                <>
                                    <CustomDropdown
                                        label="I would like to offer a Bonus if a contract is ratified from the showing list within 48-hours after the showing:"
                                        options={yesOrNo}
                                        register={{
                                            pointerEvents: 'none',
                                            value: requestInfo.wouldLikeToOfferBonus ? 'Yes' : 'No',
                                            isReadOnly: true,
                                        }}
                                        errors=""
                                        margin="0 0 26px 0"
                                        bgColor="white"
                                    />
                                    {requestInfo.wouldLikeToOfferBonus ? (
                                        <Flex>
                                            <CustomDropdown
                                                register={{
                                                    value: requestInfo.bonusTypeId,
                                                    pointerEvents: 'none',
                                                    isReadOnly: true,
                                                }}
                                                options={bonusTypes.map((elem, index) => ({
                                                    label: elem.name,
                                                    value: elem.id,
                                                    id: index,
                                                }))}
                                                label="Bonus Type"
                                                errors=""
                                                margin="0 0 26px 0"
                                                width="100%"
                                                bgColor="white"
                                            />
                                            <Box w="20px" />
                                            <CustomInput
                                                label="Bonus"
                                                width="100%"
                                                register={{
                                                    value: requestInfo.amount?.toString(),
                                                    isReadOnly: true,
                                                }}
                                                bgColor="white"
                                            />
                                        </Flex>
                                    ) : null}
                                </>
                            ) : null}
                        </Box>
                    </Flex>
                    <Flex justifyContent="flex-end" marginTop="auto" mb={5}>
                        {requestInfo.typeId !== 1 ? (
                            <Flex flex={1} justify="space-between" align="center" mr="30px">
                                <Text fontSize="13px" lineHeight="19px" color="secondaryFontDarker">
                                    Potential earnings:
                                </Text>
                                <Text
                                    fontSize="19px"
                                    lineHeight="28px"
                                    fontWeight={500}
                                    color="brand"
                                >
                                    ${requestInfo.totalPrice}
                                </Text>
                            </Flex>
                        ) : null}

                        <CustomButton
                            width="calc(50% - 20px)"
                            height="60px"
                            onClick={() => setToCancelWorkId(requestInfo.id)}
                            text="Cancel"
                            color="brand"
                            borderColor="secondaryFontDarker"
                            bgColor="transparent"
                        />
                    </Flex>
                </Flex>
            )}
        </ModalBody>
    </>
);
