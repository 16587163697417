import { createSlice } from '@reduxjs/toolkit';
import {
    changePassword,
    changePasswordSuccess,
    changePasswordError,
    sendPinCode,
    sendPinCodeSuccess,
    sendPinCodeError,
    setNewPasswordSuccess,
} from './actions';
import { PayloadError } from '../../../../common/types';

type InitialState = {
    errors: PayloadError[];
    changeSuccess: boolean;
    setNewPasswordSuccess: boolean;
    loading: boolean;
    loaded: boolean;
    pinSended: boolean;
    value: { userId: string; token: string };
    email: string;
};

export const initialState: InitialState = {
    errors: [],
    changeSuccess: false,
    setNewPasswordSuccess: false,
    pinSended: false,
    loading: false,
    loaded: false,
    email: '',
    value: {
        userId: '',
        token: '',
    },
};

const changePasswordSlice = createSlice({
    name: 'changePassword',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(changePassword, (state, action) => {
                state.email = action.payload.email;
                state.loading = true;
                state.loaded = false;
                state.errors = [];
            })
            .addCase(changePasswordSuccess, (state, action) => {
                state.loading = false;
                state.loaded = true;
                state.errors = [];
            })
            .addCase(changePasswordError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            .addCase(sendPinCode, state => {
                state.loading = true;
                state.loaded = false;
                state.errors = [];
            })
            .addCase(sendPinCodeSuccess, (state, action) => {
                state.value.userId = action.payload.value.userId;
                state.value.token = action.payload.value.token;
                state.pinSended = true;
                state.loading = false;
                state.loaded = true;
                state.errors = [];
            })
            .addCase(sendPinCodeError, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })
            .addCase(setNewPasswordSuccess, (state, action) => {
                state.setNewPasswordSuccess = true;
            });
    },
});

export default changePasswordSlice.reducer;
