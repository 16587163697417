import React from 'react';

type HomeIcon = {
    color?: string;
    height?: string;
    width?: string;
};

export const Home: React.FC<HomeIcon> = ({ color, height, width }) => (
    <svg
        width={width || '24'}
        height={height || '24'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 0.800049L12.3536 0.446495C12.2598 0.352727 12.1326 0.300049 12 0.300049C11.8674 0.300049 11.7402 0.352727 11.6465 0.446495L12 0.800049ZM2.40002 10.4L2.04647 10.0465L1.90002 10.1929V10.4H2.40002ZM21.6 10.4H22.1V10.1929L21.9536 10.0465L21.6 10.4ZM21.9536 10.0465L12.3536 0.446495L11.6465 1.1536L21.2465 10.7536L21.9536 10.0465ZM11.6465 0.446495L2.04647 10.0465L2.75358 10.7536L12.3536 1.1536L11.6465 0.446495ZM22.1 21.6V10.4H21.1V21.6H22.1ZM1.90002 10.4V21.6H2.90002V10.4H1.90002ZM4.00002 23.7H20V22.7H4.00002V23.7ZM21.1 21.6C21.1 22.2076 20.6075 22.7 20 22.7V23.7C21.1598 23.7 22.1 22.7598 22.1 21.6H21.1ZM1.90002 21.6C1.90002 22.7598 2.84023 23.7 4.00002 23.7V22.7C3.39251 22.7 2.90002 22.2076 2.90002 21.6H1.90002Z"
            fill={color || 'white'}
        />
    </svg>
);
