import React, { useEffect } from 'react';
import { Box, Flex, HStack, Text, Avatar, ModalBody, CloseButton } from '@chakra-ui/react';
import { ClockIcon, Calendar, IWillPayAgentForHostingIcon } from '../../../../../assets/icons';
import {
    CustomInput,
    CustomButton,
    Loader,
    OpenHouseInfoSection,
} from '../../../../../common/components';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { RequestInfoType } from '../../../types';
import dayjs from 'dayjs';
import { convertTimeIn12Hours, getAvatarPicture } from '../../../../../common/utils';
import { IdNameTypes, OpenHouseInfoType } from '../../../../../common/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../../store';
import {
    getOpenHouseHostTypes,
    getClientTypes,
    getBonusTypes,
    getOpenHouseAudiences,
    getOpenHouseTypes,
} from '../../../../../store/actions/directories.actions';

interface IOpenHouseSummaryProps {
    openHouseInfo: OpenHouseInfoType;
    requestInfo: RequestInfoType;
    setToCancelWorkId: (value: number) => void;
    loading: boolean;
    requestLoading?: boolean;
    onClose: () => void;
}

export const OpenHouseSummary: React.FC<IOpenHouseSummaryProps> = ({
    openHouseInfo,
    requestInfo,
    setToCancelWorkId,
    loading,
    requestLoading,
    onClose,
}) => {
    const dispatch = useDispatch();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    });

    const { openHouseHostTypes, clientTypes, bonusTypes, openHouseAudiences, openHouseTypes } =
        useSelector((state: RootStore) => state.directories);

    useEffect(() => {
        dispatch(getOpenHouseHostTypes());
        dispatch(getClientTypes());
        dispatch(getBonusTypes());
        dispatch(getOpenHouseAudiences());
        dispatch(getOpenHouseTypes());
    }, []);

    return (
        <>
            <ModalBody d="flex" flexDirection="column" w="100%" p="0">
                {loading || requestLoading ? (
                    <Loader centerHeight="100%" />
                ) : (
                    <Flex pl="70px">
                        <Box>
                            <Flex height="70px" align="center">
                                <Text
                                    fontSize="22px"
                                    lineHeight="18px"
                                    color="brand"
                                    fontWeight="bold"
                                >
                                    Requests
                                </Text>
                            </Flex>
                            <OpenHouseInfoSection
                                openHouseInfo={openHouseInfo}
                                optionalContainerProps={{ mr: 0, pr: '30px', borderRight: 'none' }}
                            />
                        </Box>
                        <Box flexShrink={0} bg="#D4E3EF" w="40%" pr="70px" pl="30px">
                            <Flex justifyContent="flex-end" height="70px" align="center">
                                <CloseButton color="brand" onClick={onClose} />
                            </Flex>
                            <HStack spacing={2}>
                                <Avatar
                                    name={openHouseInfo?.agentName}
                                    src={getAvatarPicture(openHouseInfo?.avatar)}
                                    w="54px"
                                    h="54px"
                                />
                                <Box>
                                    <Text
                                        color="brand"
                                        fontWeight={700}
                                        fontSize="16px"
                                        lineHeight="23px"
                                    >
                                        {openHouseInfo?.agentName}
                                    </Text>
                                    <Text
                                        color="secondaryFontDarker"
                                        fontSize="12px"
                                        lineHeight="18px"
                                    >
                                        MLS Id: {openHouseInfo?.agentMlsId}
                                    </Text>
                                </Box>
                            </HStack>
                            <Text
                                mt="10px"
                                fontSize="16px"
                                color="secondaryFontDarker"
                                lineHeight="23px"
                                fontWeight={500}
                                mb="25px"
                            >
                                {openHouseInfo?.agentOfficeName}
                            </Text>
                            {isLoaded ? (
                                <GoogleMap
                                    options={{ disableDefaultUI: true }}
                                    mapContainerStyle={{
                                        height: '275px',
                                        width: '100%',
                                        borderRadius: '20px',
                                    }}
                                    center={{
                                        lat: Number(openHouseInfo?.latitude),
                                        lng: Number(openHouseInfo?.longitude),
                                    }}
                                    zoom={10}
                                >
                                    <Marker
                                        position={{
                                            lat: Number(openHouseInfo?.latitude),
                                            lng: Number(openHouseInfo?.longitude),
                                        }}
                                    />
                                </GoogleMap>
                            ) : (
                                <></>
                            )}
                            <Text
                                color="brand"
                                fontWeight="semibold"
                                fontSize="13px"
                                lineHeight="19px"
                                mt="30px"
                                mb="15px"
                            >
                                Selected Open House Time
                            </Text>
                            <CustomInput
                                label="Date:"
                                placeholder="Date"
                                register={{
                                    value: dayjs(requestInfo.timeSlot.date).format('MM/DD/YY'),
                                }}
                                rightIcon={<Calendar />}
                                isReadOnly={true}
                                margin="0 0 26px 0"
                            />
                            <HStack mb="26px">
                                <CustomInput
                                    register={{
                                        value: convertTimeIn12Hours(
                                            requestInfo.timeSlot.startTime,
                                            requestInfo.timeZone,
                                        ),
                                    }}
                                    label="Start time"
                                    placeholder="From time"
                                    type="time-12-hours"
                                    rightIcon={<ClockIcon color="#888DAB" />}
                                    width="100%"
                                    isReadOnly={true}
                                />
                                <CustomInput
                                    register={{
                                        value: convertTimeIn12Hours(
                                            requestInfo.timeSlot.endTime,
                                            requestInfo.timeZone,
                                        ),
                                    }}
                                    label="End time"
                                    placeholder="End time"
                                    type="time-12-hours"
                                    width="100%"
                                    rightIcon={<ClockIcon color="#888DAB" />}
                                    isReadOnly={true}
                                />
                            </HStack>
                            {requestInfo.timeSlot.openHouseHostTypeId ? (
                                <CustomInput
                                    label="Who Will Host This House?"
                                    isReadOnly={true}
                                    register={{
                                        value:
                                            openHouseHostTypes.find(
                                                (elem: IdNameTypes) =>
                                                    elem.id ===
                                                    requestInfo.timeSlot.openHouseHostTypeId,
                                            )?.name || '',
                                    }}
                                    margin="0 0 26px 0"
                                />
                            ) : null}
                            {requestInfo.wouldLikeToOfferBonus ? (
                                <>
                                    <CustomInput
                                        label="I Would Like To Offer A Bonus If A Contract Is Ratified From The Open House Within 48-Hours After The Showing:"
                                        isReadOnly={true}
                                        register={{
                                            value: requestInfo.wouldLikeToOfferBonus ? 'Yes' : 'No',
                                        }}
                                        margin="0 0 26px 0"
                                    />
                                    <Flex>
                                        <CustomInput
                                            label="Bonus Type:"
                                            isReadOnly={true}
                                            register={{
                                                value: requestInfo.bonusTypeId
                                                    ? bonusTypes.find(
                                                          (elem: IdNameTypes) =>
                                                              elem.id === requestInfo.bonusTypeId,
                                                      )?.name
                                                    : '',
                                            }}
                                            margin="0 0 26px 0"
                                        />
                                        <Box w="20px" />
                                        <CustomInput
                                            label="Bonus:"
                                            isReadOnly={true}
                                            register={{
                                                value: requestInfo.amount?.toString() || '',
                                            }}
                                            margin="0 0 26px 0"
                                        />
                                    </Flex>
                                </>
                            ) : null}
                            {requestInfo.timeSlot.openHouseAudienceId ? (
                                <CustomInput
                                    label="Audience for Open House:"
                                    isReadOnly={true}
                                    register={{
                                        value:
                                            openHouseAudiences.find(
                                                (elem: IdNameTypes) =>
                                                    elem.id ===
                                                    requestInfo.timeSlot.openHouseAudienceId,
                                            )?.name || '',
                                    }}
                                    margin="0 0 26px 0"
                                />
                            ) : null}
                            {requestInfo.timeSlot.openHouseTypeId ? (
                                <CustomInput
                                    label="Type of Open House:"
                                    isReadOnly={true}
                                    register={{
                                        value:
                                            openHouseTypes.find(
                                                (elem: IdNameTypes) =>
                                                    elem.id ===
                                                    requestInfo.timeSlot.openHouseTypeId,
                                            )?.name || '',
                                    }}
                                    margin="0 0 26px 0"
                                />
                            ) : null}
                            {requestInfo.timeSlot.virtualOpenHouseLink ? (
                                <CustomInput
                                    label="Link To Virtual Open House:"
                                    isReadOnly={true}
                                    register={{ value: requestInfo.timeSlot.virtualOpenHouseLink }}
                                    margin="0 0 26px 0"
                                />
                            ) : null}
                            {String(requestInfo.totalPrice) === 'true' ? (
                                <Flex align="center" mb="85px">
                                    <IWillPayAgentForHostingIcon />
                                    <Text color="brand" fontWeight="300" fontSize="14px" ml="8px">
                                        I Will Pay Agent For Hosting This O/H
                                    </Text>
                                </Flex>
                            ) : null}
                            <CustomButton
                                text="Cancel"
                                bgColor="brand"
                                height="60px"
                                onClick={() => setToCancelWorkId(requestInfo.id)}
                            />
                        </Box>
                    </Flex>
                )}
            </ModalBody>
        </>
    );
};
