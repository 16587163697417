import React from 'react';
import { Text } from '@chakra-ui/react';
import { ICalendarButtonItemProps } from '../types';

export const CalendarButtonItem: React.FC<ICalendarButtonItemProps> = ({
    title,
    primaryColor,
    opacityColor,
    eventTypes,
    type,
    onClick,
    isAccessAllowed,
}) => {
    const isActive = eventTypes.includes(type);
    return (
        <Text
            color="brand"
            fontWeight={300}
            w="17%"
            fontSize="10px"
            borderLeftColor={primaryColor}
            borderLeftWidth="4px"
            borderRadius="2px"
            bg={isActive ? opacityColor : ''}
            onClick={() => onClick(type)}
            opacity={isActive ? 1 : 0.5}
            textAlign="center"
            as="button"
            disabled={!isAccessAllowed}
            cursor={isAccessAllowed ? 'pointer' : 'not-allowed'}
        >
            {title}
        </Text>
    );
};
