import { createAction } from '@reduxjs/toolkit';
import { FindJobPostsPayload, FindJobPostsResponseType, CreateJobPostPayload } from '../types';
import { PayloadError, IdNameTypes } from '../../../common/types';

// FIND JOB POSTS
export const findJobPostsRequest = createAction<FindJobPostsPayload>('FIND_JOB_POSTS_REQUEST');
export const findJobPostsSuccess = createAction<{
    jobListModels: FindJobPostsResponseType[];
    totalCount: number;
    pageNumber: number;
}>('FIND_JOB_POSTS_SUCCESS');
export const findJobPostsError = createAction<PayloadError[]>('FIND_JOB_POSTS_ERROR');

export const clearJobPostsList = createAction('CLEAR_JOB_POSTS_LIST');

// CREATE JOB POST
export const createJobPostRequest = createAction<CreateJobPostPayload>('CREATE_JOB_POST_REQUEST');
export const createJobPostSuccess = createAction('CREATE_JOB_POST_SUCCESS');
export const createJobPostError = createAction<PayloadError[]>('CREATE_JOB_POST_ERROR');

// SUBMIT JOB PROPOSAL
export const submitJobProposalRequest = createAction<{ id: number; isListings: boolean }>(
    'SUBMIT_JOB_PROPOSAL_REQUEST',
);
export const submitJobProposalSuccess = createAction('SUBMIT_JOB_PROPOSAL_SUCCESS');
export const submitJobProposalError = createAction<PayloadError[]>('SUBMIT_JOB_PROPOSAL_ERROR');

export const getReferralOpportunityAgenciesRequest = createAction<number>(
    'GET_REFERRAL_OPPORTUNITIES_AGENCIES_REQUEST',
);
export const getReferralOpportunityAgenciesSuccess = createAction<IdNameTypes[]>(
    'GET_REFERRAL_OPPORTUNITIES_AGENCIES_SUCCESS',
);
