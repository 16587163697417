import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ErrorMessages } from './messages';

const contactSchema = yup.object().shape({
    firstName: yup.string().required(ErrorMessages.REQUIRED),
    lastName: yup.string().required(ErrorMessages.REQUIRED),
    workEmail: yup.string().required(ErrorMessages.REQUIRED).email(ErrorMessages.EMAIL_INVALID),
    mobilePhone: yup
        .string()
        .required(ErrorMessages.REQUIRED)
        .test('phone-number', ErrorMessages.PHONE_NUMBER_INCOMPLETE, function (value?: string) {
            return value?.replace(/[-_]/g, '').length === 10;
        }),
    companyOrganizationName: yup.string().required(ErrorMessages.REQUIRED),
    reasonForYourInquiry: yup.string().required(ErrorMessages.REQUIRED),
    howCanWeHelp: yup.string(),
});

export const contactResolver = yupResolver(contactSchema);
