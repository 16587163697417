import { useEffect, useState } from 'react';

const useTimer = (diff: number) => {
    const [timer, setTimer] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const updateCountdown = () => {
            if (diff) {
                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                let hours = Math.floor(
                    (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
                );
                let minutes = Math.floor(
                    (diff % (1000 * 60 * 60)) / (1000 * 60),
                );
                let seconds = Math.floor((diff % (1000 * 60)) / 1000);
                const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

                if (numbersToAddZeroTo.includes(hours)) {
                    // @ts-ignore
                    hours = `0${hours}`;
                } else if (numbersToAddZeroTo.includes(minutes)) {
                    // @ts-ignore
                    minutes = `0${minutes}`;
                } else if (numbersToAddZeroTo.includes(seconds)) {
                    // @ts-ignore
                    seconds = `0${seconds}`;
                }

                setTimer({
                    days, hours, minutes, seconds,
                });
            }
        };
        const interval = setInterval(() => updateCountdown(), 1000);

        return () => clearInterval(interval);
    }, [diff]);

    return timer;
};

export default useTimer;

