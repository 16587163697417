import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { InfiniteScroll, Loader } from '../../../common/components/blocks';
import { ListingsType, OpenHouseDetailsModalOpen } from '../../../common/types';
import { ListingItem } from '../components';

interface IAgencyListingsTabProps {
    loading: boolean;
    items: ListingsType[];
    loadMoreLoading: boolean;
    onOpenModal: OpenHouseDetailsModalOpen;
    loadMoreItems: () => void;
}

export const AgencyListingsTab: React.FC<IAgencyListingsTabProps> = ({
    items,
    loadMoreLoading,
    loading,
    onOpenModal,
    loadMoreItems,
}) => {
    return (
        <Box>
            {loading ? (
                <Loader spinnerSize="md" centerHeight="calc(90vh - 180px)" />
            ) : (
                <InfiniteScroll
                    height="calc(90vh - 180px)"
                    boxProps={{ pt: '10px' }}
                    onReachBottom={loadMoreItems}
                >
                    {items.length ? (
                        <>
                            {items.map(elem => (
                                <ListingItem
                                    key={elem.id}
                                    {...elem}
                                    onOpenManage={value => onOpenModal(value, elem, true, true)}
                                    onOpenModal={value => onOpenModal(value, elem, false, false)}
                                />
                            ))}
                        </>
                    ) : (
                        <Center h="100%">
                            <Text color="brand" fontSize="16px">
                                There is no agency listings at this time
                            </Text>
                        </Center>
                    )}
                    {loadMoreLoading && <Loader spinnerSize="md" centerHeight="100px" />}
                </InfiniteScroll>
            )}
        </Box>
    );
};
