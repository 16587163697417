import React, { useState } from 'react';
import {
    Flex,
    Box,
    Text,
    Avatar,
    Button,
} from '@chakra-ui/react';
import { TransactionHistoryItem } from '../../../types';
import { colors } from '../../../../../../../common/constants';
import { months } from '../../../../../../../common/constants';
import { getAvatarPicture } from '../../../../../../../common/utils';
import { TransactionHistoryModal } from './TransactionHistoryModal';
import { formatAMPM } from '../../../../../../../common/utils/';
import { ToggleUserProfileModalPayload } from '../../../../../../../common/types';

interface ITransactionHistoryItem {
    item: TransactionHistoryItem;
    onOpenProfileImage: (payload: ToggleUserProfileModalPayload) => void;
}

export const TransactionHistoryItemComponent: React.FC<ITransactionHistoryItem> = ({
    item,
    onOpenProfileImage,
}) => {
    const dateObj = new Date(item.created)
    const month = months[dateObj.getMonth()]?.substring(0, 3);
    const date = dateObj.getDate()
    const year = dateObj.getFullYear()
    const dateString = `${month} ${date}, ${year}`
    const time = formatAMPM(dateObj)

    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const onCloseModal = () => setModalVisible(false)
    return (
        <>
            <Flex
                borderBottom="1px #D1D2DB solid"
                alignItems="center"
                py="10px"
            >
                <Flex alignItems="center" w="26%" color="secondaryFontDarker">
                    <Avatar
                        h="36px"
                        w="36px"
                        src={getAvatarPicture(item.relatedUserAvatar)}
                        mr="5px"
                    />
                    <Text color="brand">{item.relatedUserFullName}</Text>
                </Flex>
                <Flex w="30%" color="secondaryFontDarker">
                    {item.isIncome
                        ? <Text fontWeight={600} color={colors.green}>Incoming</Text>
                        : <Text fontWeight={600} color={colors.orange}>Outgoing</Text>
                    }
                    <Text fontWeight={600} ml="5px" color="brand">
                        {
                            item.requestTypeId === 1 ?
                                'Cancellation Fee' :
                                item.assistRequestTypeName
                        }
                    </Text>
                </Flex>
                <Box w="22%" color="secondaryFontDarker">
                    <Text>{dateString}</Text>
                </Box>
                <Box w="11%" color="secondaryFontDarker">
                    {item.status === 'failed'
                        ? <Text fontWeight={500} color="#F2C94C">
                            Failed
                        </Text>
                        : <Text
                            fontWeight={600}
                            color={item.isIncome ? colors.green : colors.orange}
                        >${item.amountOfMoney}</Text>}
                </Box>
                <Button
                    d="block"
                    padding="0px"
                    bg="transparent"
                    w="11%"
                    textAlign="right"
                    onClick={() => setModalVisible(true)}
                    variant='link'
                    _focus={{ boxShadow: "none", }}
                >
                    <Text fontSize="12px" color="brand" textDecoration="underline">Details</Text>
                </Button>
            </Flex>
            <TransactionHistoryModal
                id={item.id}
                isOpen={modalVisible}
                onClose={onCloseModal}
                item={item}
                dateString={dateString}
                time={time}
                onOpenProfileImage={onOpenProfileImage}
            />
        </>
    )
}
