import React from 'react';
import { Avatar, Box, Flex, Td, Text, Th } from '@chakra-ui/react';

export const renderTableHeader = (color: string, text: string, sortBy?: () => void) => (
    <Th maxW="200px" textTransform="capitalize" isNumeric>
        <Flex alignItems="center" textAlign="left">
            <Text fontSize="14px" color={color} fontWeight="300">
                {text}
            </Text>
        </Flex>
    </Th>
);

interface IRenderTableBody {
    color: string;
    size: string;
    weight: string;
    isIncome?: boolean;
    description?: boolean;
    text: string | number;
    icon?: string;
}

export const RenderTableBody: React.FC<IRenderTableBody> = ({
    color,
    size,
    weight,
    isIncome,
    description,
    text,
    icon,
}) => {
    return (
        <Td maxW="200px">
            <Flex align="center">
                {icon && <Avatar w="36px" h="36px" name={icon} src={icon} mr="15px" />}
                {description ? (
                    isIncome ? (
                        <>
                            <Text fontWeight={'bold'} color={'green'} fontSize={size}>
                                {text}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Text fontWeight={'bold'} color={'#FE805C'} fontSize={size}>
                                {text}
                            </Text>
                        </>
                    )
                ) : (
                    <Text fontWeight={weight} color={color} fontSize={size}>
                        {text}
                    </Text>
                )}
            </Flex>
        </Td>
    );
};
