import { createSlice } from '@reduxjs/toolkit';
import { PayloadError } from '../../../common/types';
import { ActivitiesResponseType, RequestAddresses, RequestDurations } from '../types';
import {
    addFeedbackRequest,
    addFeedbackSuccess,
    confirmWorkError,
    confirmWorkRequest,
    confirmWorkSuccess,
    getAgentActivityRequest,
    getAgentActivitySuccess,
    getCurrentActivityRequest,
    getCurrentActivitySuccess,
    getRequestAddressesRequest,
    getRequestAddressesSuccess,
    getRequestByIdRequest,
    getRequestByIdSuccess,
    getRequestByIdError,
    finishWorkRequest,
    finishWorkSuccess,
    finishWorkError,
    addFeedbackError,
    setDefaultRequest,
    getRequestDurationsSuccess,
    closePaymentStatusModal,
} from './actions';
import { RequestInfoType } from '../../Requests/types';
import { PaymentStatus } from '../../Settings/pages/Payments/types';

type InitialState = {
    activity: {
        activity: ActivitiesResponseType[];
        totalCount: number;
        loading: boolean;
        loadMoreLoading: boolean;
        errors: PayloadError[];
    };
    requestAddresses: {
        addresses: RequestAddresses[];
        userName: string;
        loading: boolean;
        errors: PayloadError[];
        pricePerDoor: null | number;
    };
    requestById: { request: RequestInfoType; loading: boolean; errors: PayloadError[] };
    confirmWork: {
        loading: boolean;
        errors: PayloadError[];
    };
    addFeedback: {
        loading: boolean;
        errors: PayloadError[];
    };
    finishWork: {
        loading: boolean;
        errors: PayloadError[];
    };
    requestDurations: RequestDurations;
    paymentStatus: {
        modalOpen: boolean;
        status: PaymentStatus;
    };
};

export const initialState: InitialState = {
    activity: {
        activity: [],
        totalCount: 0,
        loading: false,
        loadMoreLoading: false,
        errors: [],
    },
    requestAddresses: {
        addresses: [],
        userName: '',
        loading: false,
        errors: [],
        pricePerDoor: null,
    },
    requestById: {
        request: {
            iWillPayAgentForHosting: false,
            fromTime: '',
            currentLocation: '',
            actualDuration: 0,
            addresses: [''],
            amount: 0,
            bonusTypeId: 0,
            isWorkFinished: false,
            ownerId: 0,
            assistantId: 0,
            clientTypeId: 0,
            dateTime: '',
            duration: 0,
            generalNote: '',
            id: 0,
            isAccepted: false,
            isDateFirmOrFlexible: false,
            isShowingScheduled: false,
            privateNote: '',
            propertiesToShow: 0,
            states: [''],
            totalPrice: null,
            typeId: 0,
            wouldLikeToOfferBonus: false,
            expiryDate: '',
            timeSlot: {
                date: '',
                endTime: '',
                id: 0,
                listingId: 0,
                price: 0,
                requestId: 0,
                startTime: '',
                openHouseAudienceId: 0,
                openHouseHostTypeId: 0,
                openHouseTypeId: 0,
                clientTypeId: 0,
            },
            avatar: {
                id: 0,
                itemHash: '',
                itemExtension: '',
            },
            assistantAvatar: {
                id: 0,
                itemHash: '',
                itemExtension: '',
            },
            agencyName: '',
            ownerFullName: '',
            assistantFullName: '',
            createdAt: '',
            referralClient: {
                name: '',
                phone: '',
            },
            requestCategoryId: 0,
            requestTypeName: '',
            agencies: [],
            ownerEmail: '',
            assistantEmail: '',
            ownerPhoneNumber: '',
            assistantPhoneNumber: '',
        },
        loading: false,
        errors: [],
    },
    addFeedback: {
        loading: false,
        errors: [],
    },
    confirmWork: {
        loading: false,
        errors: [],
    },
    finishWork: {
        errors: [],
        loading: false,
    },
    requestDurations: {
        duration: 0,
        fee: 0,
        pricePerHour: 0,
    },
    paymentStatus: {
        modalOpen: false,
        status: 'success',
    },
};

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCurrentActivityRequest, (state, action) => {
                if (action.payload.pageNumber === 1) {
                    state.activity.loading = true;
                } else {
                    state.activity.loadMoreLoading = true;
                }
                state.activity.errors = [];
            })
            .addCase(getCurrentActivitySuccess, (state, action) => {
                state.activity.loading = false;
                state.activity.loadMoreLoading = false;
                state.activity.activity =
                    action.payload.pageNumber === 1
                        ? action.payload.activity
                        : [...state.activity.activity, ...action.payload.activity];
                state.activity.totalCount = action.payload.totalCount;
            })
            .addCase(getAgentActivityRequest, (state, action) => {
                if (action.payload.pageNumber === 1) {
                    state.activity.loading = true;
                } else {
                    state.activity.loadMoreLoading = true;
                }
                state.activity.errors = [];
            })
            .addCase(getAgentActivitySuccess, (state, action) => {
                state.activity.loading = false;
                state.activity.loadMoreLoading = false;
                state.activity.activity =
                    action.payload.pageNumber === 1
                        ? action.payload.activity
                        : [...state.activity.activity, ...action.payload.activity];
                state.activity.totalCount = action.payload.totalCount;
            })
            .addCase(confirmWorkRequest, state => {
                state.confirmWork.loading = true;
            })
            .addCase(confirmWorkSuccess, state => {
                state.confirmWork.loading = false;
                state.paymentStatus.modalOpen = true;
                state.paymentStatus.status = 'success';
            })
            .addCase(confirmWorkError, (state, action) => {
                state.confirmWork.loading = false;
                state.confirmWork.errors = action.payload;
                state.paymentStatus.modalOpen = true;
                state.paymentStatus.status = 'fail';
            })
            .addCase(closePaymentStatusModal, state => {
                state.paymentStatus.modalOpen = false;
                state.paymentStatus.status = null;
            })
            .addCase(getRequestAddressesRequest, state => {
                state.requestAddresses.loading = true;
                state.requestAddresses.errors = [];
            })
            .addCase(getRequestAddressesSuccess, (state, action) => {
                state.requestAddresses.loading = false;
                state.requestAddresses.addresses = action.payload.requestAddresses;
                state.requestAddresses.userName = action.payload.userName;
                state.requestAddresses.pricePerDoor = action.payload.pricePerDoor;
            })
            .addCase(addFeedbackRequest, state => {
                state.addFeedback.loading = true;
                state.addFeedback.errors = [];
            })
            .addCase(addFeedbackSuccess, state => {
                state.addFeedback.loading = false;
            })
            .addCase(addFeedbackError, (state, action) => {
                state.addFeedback.loading = false;
                state.addFeedback.errors = action.payload;
            })
            .addCase(getRequestByIdRequest, state => {
                state.requestById.loading = true;
                state.requestById.request = initialState.requestById.request;
                state.requestById.errors = [];
            })
            .addCase(getRequestByIdSuccess, (state, action) => {
                state.requestById.loading = false;
                state.requestById.request = action.payload.requestModel;
            })
            .addCase(getRequestByIdError, (state, action) => {
                state.requestById.loading = false;
            })
            .addCase(finishWorkRequest, state => {
                state.finishWork.errors = [];
                state.finishWork.loading = true;
            })
            .addCase(finishWorkSuccess, state => {
                state.finishWork.loading = false;
            })
            .addCase(finishWorkError, (state, action) => {
                state.finishWork.loading = false;
                state.finishWork.errors = action.payload;
            })
            .addCase(setDefaultRequest, state => {
                state.requestById = { ...initialState.requestById };
            })
            .addCase(getRequestDurationsSuccess, (state, action) => {
                state.requestDurations = action.payload;
            });
    },
});

export default activitySlice.reducer;
