import React from 'react';
import { Text, HStack, SimpleGrid } from '@chakra-ui/react';

import { SingleMenuSelect, MultipleMenuSelect, CustomButton } from '../../../blocks';

import { defaultRequestTypesToFilter } from '../../../../constants';

interface IMyActivitiesModalSearchProps {
    categoryIdSelected: number;
    setCategoryIdSelected: (value: number) => void;
    typeIdsSelected: number[];
    setTypeIdsSelected: (value: number[]) => void;
    getItems: (value?: number) => void;
}

export const MyActivitiesModalSearch: React.FC<IMyActivitiesModalSearchProps> = ({
    categoryIdSelected,
    setCategoryIdSelected,
    setTypeIdsSelected,
    typeIdsSelected,
    getItems,
}) => (
    <>
        <Text color="brand" fontSize="13px" lineHeight="19px" fontWeight="light" my="10px">
            Settings
        </Text>
        <SimpleGrid templateColumns="repeat(3, 1fr)" gap="18px">
            <SingleMenuSelect
                selectedItem={categoryIdSelected}
                onSelectItem={setCategoryIdSelected}
                menuValues={[
                    { value: 1, label: 'Find Assistance' },
                    { value: 2, label: 'Opportunities' },
                    { value: 3, label: 'Host An Open House' },
                ]}
            />
            <MultipleMenuSelect
                typeIdsSelected={typeIdsSelected}
                setTypeIdsSelected={setTypeIdsSelected}
                menuValues={defaultRequestTypesToFilter}
            />
            <CustomButton
                text="Search"
                // width="30%"
                height="50px"
                bgColor="brand"
                flexShrink={0}
                onClick={() => getItems(1)}
            />
        </SimpleGrid>
    </>
);
