import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';
import {
    getOpenHouseAudiences,
    getOpenHouseAudiencesSuccess,
    getOpenHouseAudiencesError,
} from '../../actions/directories.actions';
import { IResponseWithCustomValue, IdNameTypes } from '../../../common/types';

function* workerGetOpenHouseAudiences() {
    try {
        const result: IResponseWithCustomValue<{
            audiences: Array<IdNameTypes>;
        }> = yield call(Api.get, '/api/directories/open-house-audiences', {});

        if (result.success) {
            yield put(getOpenHouseAudiencesSuccess(result.value));
        } else {
            yield put(getOpenHouseAudiencesError(result.errors));
            console.log('ERROR', result);
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* watchGetOpenHouseAudiencesSaga() {
    yield takeEvery(getOpenHouseAudiences.type, workerGetOpenHouseAudiences);
}
