import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    useDisclosure,
    VStack,
    Spinner,
    Center,
} from '@chakra-ui/react';
import { GetCitiesResponse, IResponseWithCustomValue } from '../../../types';
import Api, { baseUrl } from '../../../api/api.base';
import { Typography } from '../Typography/Typography';
import { SearchIcon } from '../../../../assets/icons/SearchIcon';
import { fonts, useDebounce } from '../../../utils';

interface IProps {
    disabled?: boolean;
    typeIdsSelected: GetCitiesResponse[];
    setTypeIdsSelected: Dispatch<SetStateAction<GetCitiesResponse[]>>;
    stateIdValue: number;
    token: string;
}

export const SearchCitiesSelect: React.FC<IProps> = ({
    disabled,
    typeIdsSelected,
    setTypeIdsSelected,
    stateIdValue,
    token,
}) => {
    const [term, setTerm] = useState('');
    const [cities, setCities] = useState<Array<GetCitiesResponse>>([]);
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const debouncedTerm = useDebounce(term, 500);

    const checkIfValuesExist = useMemo(() => {
        if (typeIdsSelected.length > 0) {
            return typeIdsSelected.map(el => el.cityName).join(', ');
        }
        return 'Select';
    }, [typeIdsSelected]);

    const visibleItems = useMemo(() => {
        if (typeIdsSelected.length !== 0 && isOpen && cities.length === 0) {
            return [...typeIdsSelected];
        }
        if (cities.length !== 0 && isOpen && typeIdsSelected.length !== 0) {
            return [...cities];
        }
        if (cities.length !== 0 && isOpen) {
            return [...cities];
        }
        return [];
    }, [cities, typeIdsSelected, isOpen]);

    const onSelected = (checked: boolean, item: GetCitiesResponse) => {
        if (checked && typeIdsSelected.length !== 5) {
            setTypeIdsSelected(prev => [...prev, item]);
        } else {
            setTypeIdsSelected(prev => prev.filter(it => it.cityName !== item.cityName));
        }
    };

    useEffect(() => {
        const getCities = async (searchValue: string, stateId: number) => {
            try {
                setLoading(true);
                const result: IResponseWithCustomValue<GetCitiesResponse[]> = await Api.get(
                    `${baseUrl}/api/auth/get-city-info?City=${searchValue}&State=${stateId}`,
                    {},
                    token ? { Authorization: `Bearer ${token}` } : {},
                );
                if (result.success) {
                    setLoading(false);
                    setCities(result.value);
                }
            } catch (err) {
                console.log(err);
            }
        };

        if (debouncedTerm.length >= 3 && stateIdValue !== 0) {
            getCities(debouncedTerm, stateIdValue).catch(console.log);
        }
    }, [debouncedTerm, stateIdValue]);

    useEffect(() => {
        if (!isOpen) {
            setTerm('');
            setCities([]);
        }
    }, [isOpen]);

    return (
        <Popover placement="right" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <PopoverTrigger>
                <Flex
                    fontFamily={fonts.poppins}
                    bg="#F6F7FB"
                    height="50px"
                    width="100%"
                    align="center"
                    px="20px"
                    justifyContent="flex-start"
                    fontWeight="normal"
                    as={Button}
                    borderRadius="80px"
                    disabled={disabled}
                    borderWidth='1px'
                >
                    <Typography
                        size={16}
                        color="brand"
                        isTruncated
                        opacity={disabled ? 1 : checkIfValuesExist === 'Select' ? 0.5 : 1}
                    >
                        {checkIfValuesExist}
                    </Typography>
                </Flex>
            </PopoverTrigger>
            <PopoverContent borderRadius="20px">
                <PopoverHeader>
                    <Box>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <SearchIcon />
                            </InputLeftElement>
                            <Input
                                placeholder="Search"
                                value={term}
                                onChange={e => setTerm(e.target.value)}
                            />
                        </InputGroup>
                    </Box>
                </PopoverHeader>
                <PopoverBody>
                    <VStack align="flex-start" p="15px" height="200px" overflow="auto">
                        {loading ? (
                            <Center w="100%">
                                <Spinner color="brand" />
                            </Center>
                        ) : (
                            visibleItems.length !== 0 &&
                            visibleItems.map(elem => (
                                <Checkbox
                                    key={elem.cityName}
                                    isChecked={typeIdsSelected.some(
                                        it => it.cityName === elem.cityName,
                                    )}
                                    onChange={ev => {
                                        onSelected(ev.target.checked, elem);
                                    }}
                                >
                                    <Typography size={14} color="brand">
                                        {elem.cityName}
                                    </Typography>
                                </Checkbox>
                            ))
                        )}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
