import React from 'react';

interface IStarIcon {
    color: string;
}

export const StarIcon: React.FC<IStarIcon> = ({ color }) => (
    <svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5980_27725)">
            <path
                d="M15.653 7.28234C15.9679 6.97542 16.0791 6.52496 15.9432 6.10621C15.8071 5.68747 15.4524 5.38855 15.0167 5.32512L11.1426 4.7622C10.9776 4.73817 10.835 4.63469 10.7613 4.48507L9.02933 0.974937C8.83486 0.580543 8.44014 0.335449 8.00025 0.335449C7.56069 0.335449 7.16597 0.580543 6.9715 0.974937L5.23918 4.48539C5.16549 4.63501 5.0226 4.73849 4.8576 4.76252L0.983514 5.32544C0.548111 5.38855 0.193125 5.68779 0.056962 6.10653C-0.0788811 6.52528 0.0322923 6.97574 0.34723 7.28267L3.15027 10.0149C3.26978 10.1315 3.32456 10.2994 3.29637 10.4634L2.6351 14.3215C2.57647 14.6611 2.66553 14.9914 2.88532 15.2519C3.22685 15.6578 3.82308 15.7815 4.29981 15.531L7.76445 13.7093C7.90926 13.6333 8.09156 13.634 8.23606 13.7093L11.701 15.531C11.8695 15.6197 12.0493 15.6646 12.2348 15.6646C12.5734 15.6646 12.8944 15.514 13.1152 15.2519C13.3353 14.9914 13.424 14.6605 13.3654 14.3215L12.7038 10.4634C12.6756 10.2991 12.7304 10.1315 12.8499 10.0149L15.653 7.28234Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_5980_27725">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
