import React from 'react';
import { Flex, Heading, Text, Link, Button } from '@chakra-ui/react';
import { colors } from '../../constants';
import { fonts } from '../../utils';

export const FeaturesBanner: React.FC = () => (
    <Flex
        bg={colors.calendarBlue}
        direction="column"
        align="center"
        py="60px"
        fontFamily={fonts.montserrat}
    >
        <Heading
            fontFamily={fonts.montserrat}
            fontWeight={700}
            fontSize={{ base: '20px', lg: '32px' }}
            lineHeight={{ base: '30px', lg: '40px' }}
            color="white"
            textAlign="center"
            maxWidth={1180}
            margin="0 auto 28px"
        >
            Activate your free account today.
        </Heading>
        <Button
            fontWeight="700"
            fontSize={{ base: '14px', lg: '16px' }}
            width={209}
            height={{ base: '50px', lg: '60px' }}
            backgroundColor="#FFBF00"
            color={colors.brand}
            borderRadius="25px"
            _hover={{
                textDecoration: 'none',
            }}
            as={Link}
            href="/auth/signup/createaccount"
        >
            Sign Up
        </Button>
    </Flex>
);
